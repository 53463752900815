import { Injectable } from '@angular/core';
import { Cart, LineItem } from './../../shared/models';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable()

export class GlobalService {

    cartObs: BehaviorSubject<Cart> = new BehaviorSubject(new Cart());
    lineItemsObs: BehaviorSubject<LineItem[]> = new BehaviorSubject([]);
    newlineItemObs: BehaviorSubject<LineItem> = new BehaviorSubject(null);
    cartOpenCloseObs: BehaviorSubject<boolean> = new BehaviorSubject(true);

    discountCodes: string[] = [];

    constructor() {
        const cart = new Cart();
        this.cartObs.next(cart);

        const { uciDiscount, natChampDiscount, natDiscount, natTeamDiscount } = environment;

        if (uciDiscount && Array.isArray(uciDiscount)) {
            this.discountCodes = this.discountCodes.concat(uciDiscount);
        }

        if (natChampDiscount && Array.isArray(natChampDiscount)) {
            this.discountCodes = this.discountCodes.concat(natChampDiscount);
        }

        if (natDiscount && Array.isArray(natDiscount)) {
            this.discountCodes = this.discountCodes.concat(natDiscount);
        }

        if (natTeamDiscount && Array.isArray(natTeamDiscount)) {
            this.discountCodes = this.discountCodes.concat(natTeamDiscount);
        }
    }

    get cart() {
        return this.cartObs.getValue();
    }

    set cart(cart) {
        this.cartObs.next(cart);
    }

    get cartOpenClose() {
        return this.cartOpenCloseObs.getValue();
    }

    set cartOpenClose(value: boolean) {
        this.cartOpenCloseObs.next(value);
    }

    addItemToCart(lineItem: LineItem) {
        const lineItems = this.lineItemsObs.getValue();
        const sameVariant = lineItems.filter(item => item.variant.id === lineItem.variant.id);
        if (sameVariant.length) {
            sameVariant[0].quantity = lineItem.quantity;
            this.lineItemsObs.next(lineItems);
        } else {
            this.newlineItemObs.next(lineItem);
        }
    }

    increaseItemQuantity(lineItem: LineItem, lineItems?: LineItem[], quantity: number = 1) {
        if (!lineItems) { lineItems = this.lineItemsObs.getValue(); }
        const sameVariant = lineItems.filter(item => item.variant.id === lineItem.variant.id);
        if (sameVariant.length) {
            sameVariant[0].quantity += quantity;
            this.lineItemsObs.next(lineItems);
        } else {
            this.newlineItemObs.next(lineItem);
        }
    }

    decreaseItemQuantity(lineItem: LineItem, lineItems?: LineItem[], quantity: number = 1) {
        if (!lineItems) { lineItems = this.lineItemsObs.getValue(); }
        const sameVariant = lineItems.find(item => item.variant.id === lineItem.variant.id);
        if (sameVariant.quantity > 1) {
            sameVariant.quantity -= quantity;
            this.lineItemsObs.next(lineItems);
        } else {
            this.removeItem(sameVariant);
        }
    }

    removeItem(lineItem) {
        const lineItems = this.lineItemsObs.getValue();
        const index = lineItems.indexOf(lineItem);
        if (index !== -1) {
            lineItems.splice(index, 1);
            this.lineItemsObs.next(lineItems);
        }
    }

    removeItemViaRadio(lineItem) {
        const lineItems = this.lineItemsObs.getValue();
        for (let i = 0; i < lineItems.length; i++) {
            if (lineItems[i].variantId === lineItem.variantId) {
                lineItems.splice(i, 1);
                this.lineItemsObs.next(lineItems);
            }
        }
    }

    cartToggle() {
        const el = document.getElementById('cart-container');
        el.classList.toggle('close_me');
    }

    cartOpen() {
        const el = document.getElementById('cart-container');
        el.classList.remove('close_me');
    }
}
