import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { EventService, ProfileService } from '../../services';
import { CsvDownloadService, ShopifyService } from '../../shared/services';
import { SelectOption, EventQueueRow, Alert } from 'src/app/classes';
import { CookieService } from 'ngx-cookie-service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-events-queue',
  templateUrl: './events-queue.component.html',
  styles: []
})
export class EventsQueueComponent implements OnInit {
  tableColumns = [];
  tableData: EventQueueRow[] = [];
  resultsData = [];
  tableActions = [];
  selectActions = [];
  selectOptions = { event_status: null };
  actionMessages = { event_status: [] };
  disabledActions: object = {};
  disableTextWrap = [];
  utcDisplayDates = [];
  alert: Alert[];
  cartAlerts: Alert[] = [];
  eventForm: FormGroup;

  modalOpen = false;
  modalAction: any;
  modalRow: any;

  userRole: string;

  paginationLinks: any = {};
  paginationMeta: any = {};

  loading = false;

  constructor(
    private eventService: EventService,
    private profileService: ProfileService,
    private shopifyService: ShopifyService,
    private router: Router,
    private csvService: CsvDownloadService,
    private cookie: CookieService,
    private fb: FormBuilder,
  ) { }

  get f() { return this.eventForm.controls; }

  get eventId(): FormControl {
    return this.f.event_id as FormControl;
  }

  ngOnInit() {
    this.tableColumns = [
      {
        label: 'Alert',
        field: 'event_unread_notifications'
      },
      {
        label: 'Type',
        field: 'event_type'
      },
      {
        label: 'Event ID',
        field: 'event_id'
      },
      {
        label: 'Permit ID',
        field: 'event_permit_id'
      },
      {
        label: 'Listing Name',
        field: 'event_name'
      },
      {
        label: 'Start Date',
        field: 'event_start_date'
      },
      {
        label: 'Discipline',
        field: 'event_discipline_name'
      },
      // TODO: Uncomment once add-ons optimized & included again in event queue response (Permitting II)
      // {
      //   label: 'Add-Ons',
      //   field: 'event_add_ons'
      // },
      {
        label: 'Created',
        field: 'event_created_at'
      },
      {
        label: 'Status',
        field: 'event_status'
      }
    ];
    this.eventService.eventQueue$.subscribe(data => {
      this.resultsData = data;
    })

    this.eventForm = this.fb.group({
      event_id: [''],
    });

    this.subscribeToFormChanges();

    this.utcDisplayDates.push('event_start_date');

    this.disableTextWrap.push('id');

    this.loadPaymentAlerts();

    this.loadQueuePage(); // Defaults to page=1

    this.profileService.getCurrentUser().subscribe(() => {
      this.profileService.getUserPermissions().subscribe(permissions => {
        this.userRole = this.profileService.setUserRole(permissions);

        if (this.userRole === 'usac_admin') {
          this.tableActions = [
            {
              name: 'save',
              label: 'Save',
              color: 'dark'
            }
          ];
          this.selectActions = [ 'event_status' ];
          this.selectOptions = { event_status: this.eventService.queueStatusOptions };
        } else {
          this.selectOptions = { event_status: null };
        }
      });
    });

    this.alert = [ new Alert('info', null, 'No search results found.', 'search', null, null, null, false) ];

    this.disableActions();
  }

  subscribeToFormChanges() {
    this.eventId.valueChanges.subscribe(val => {
      if (val === '') {
        this.loadQueuePage();
        return;
      }
      this.eventService.getEventQueueById(val).subscribe(resp => {
        let tableData = [];
        if(resp.length === 0 && val !== '') {
          tableData = [];
        } else {
          tableData = resp.map(row => {
            if (row.event_discipline) {
              const findDiscipline = this.eventService.getDisciplineById(row.event_discipline);

              if (findDiscipline && findDiscipline.cd_value) {
                Object.assign(row, {
                  event_discipline_name: findDiscipline.cd_value
                });
              }
            }
            row.overview_link = `/event/${row.event_id}/overview`;
            return new EventQueueRow(row);
          });
        }
        this.eventService.eventQueue$.next(tableData);
      });
    });
  }

  loadPaymentAlerts() {
    // Persistent alert for incomplete required payments not yet purchased.
    this.shopifyService.getShopifyDraftOrders({ permitting: true, incomplete: true }).subscribe(drafts => {
        if (drafts.length > 0) {
          const p = this.plural(drafts);
          const rp = this.reversePlural(drafts);
          this.cartAlerts.push(
            new Alert(
              'warning',
              `Your recent order${p} require${rp} payment. Please review event${p}: ${this.unpaidList(drafts)}.`,
              'Payment Notice', 'shopping-cart', null, null, null, false
            )
          );
        }
      });
  }

  loadQueuePage(page = 1) {
    this.loading = true;
    this.eventService.getEventsQueue(page).subscribe(resp => {
      this.loading = false;
      const { data, links, meta } = resp;

      this.paginationLinks = links;
      this.paginationMeta = meta;

      this.tableData = data.map(row => {
        if (row.event_discipline) {
          const findDiscipline = this.eventService.getDisciplineById(row.event_discipline);

          if (findDiscipline && findDiscipline.cd_value) {
            Object.assign(row, {
              event_discipline_name: findDiscipline.cd_value
            });
          }
        }
        row.overview_link = `/event/${row.event_id}/overview`;
        return new EventQueueRow(row);
      });

      this.eventService.eventQueue$.next(this.tableData);
      this.resultsData = this.tableData;
    });
  }

  get tableDataResults() {
    return this.resultsData || [];
  }

  disableActions() {
    for (const key of Object.keys(this.disabledActions)) {
      if (this.selectOptions.event_status !== null) {
        this.resultsData.forEach(row => {
          if (!this.selectOptions.event_status.some(status => status.value === row.event_status )) {
            this.disabledActions[key].push(row.event_status);
          }
        });
      }
    }
  }

  onTableAction({ action, row, event }) {
    // console.log('onTableAction with ', action, row, event);

    if (row.change_requests && row.event_status === 'Approved') {
      this.modalAction = action;
      this.modalRow = row;
      this.openModal();
    } else {
      this.onSubmit(action, row, event);
    }
  }

  onSubmit(action, row, event) {
    if (action.name === 'save') {
      setTimeout(() => {
        const status = row.event_status;
        this.eventService.updateEventStatus(row.event_id, status).subscribe((resp) => {
          const event_id = row.event_id;
          const message = resp.message ? 'Status updated' : 'Error occurred';
          const textClass = resp.message ? 'text-success' : 'text-danger';
          this.actionMessages.event_status.push({ event_id, message, textClass });
        });
      }, 1);
      this.modalOpen = false;
    }
  }

  onTableSort({ sortBy, sortOrder, event }) {
    console.log('onTableSort with ', sortBy, sortOrder);
  }

  onSearchLocal({ term, data, event }) {
    this.resultsData = this.tableData;
    this.resultsData = data;
  }

  onSearchQuery({ term, event }) {
    // Logic to add term to API query
  }

  toAddNew() {
    this.router.navigate(['/event/listing']);
  }

  openModal() {
    this.modalOpen = true;
  }

  closeModal() {
    this.modalOpen = false;
  }

  csvExport() {
    this.eventService.getEventsQueueAll().subscribe(data => {
      console.log(data);
      this.csvService.downloadFile(data);
    });
  }

  unpaidList(drafts) {
    const list = [];
    drafts.forEach(draft => {
      const eventId = draft.event_id;
      if (!list.includes(this.listLink(eventId))) {
        list.push(this.listLink(eventId));
      }
    });
    return list.join(', ');
  }

  plural(drafts) {
    return drafts.length > 1 ? 's' : '';
  }

  reversePlural(drafts) {
    return drafts.length > 1 ? '' : 's';
  }

  listLink(eventId) {
    return `<a href="/event/${eventId}/overview#payments">${eventId}</a>`;
  }
}
