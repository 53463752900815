import {Directive, ElementRef, forwardRef, HostListener, Renderer2, Output, EventEmitter, Host} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';

@Directive({
  selector: '[appNumberInput]'
})
export class NumberInputDirective implements ControlValueAccessor {
  private element: HTMLInputElement;
  // TODO: Determine way to allow for min values other than 0, with proper validation behavior
  private min = 0;
  private max: number;
  private maxLength: number;
  private changes: string[] = [];

  constructor( private renderer: Renderer2, private elRef: ElementRef, private c: NgControl ) {
    this.element = elRef.nativeElement;
  }

  @HostListener('keydown', [ '$event' ])
  keydown( event ) {
    if (event.code === 'KeyE') {
      event.preventDefault();
    } else if (event.code === 'Minus' && event.target.value.length) {
      event.preventDefault();
    }
  }

  @HostListener('input', [ '$event.target.value' ])
  input( value ) {
    this.max = parseInt(this.element.max);
    this.maxLength = this.element.max.length;

    if (!!this.num(value)) {
      this.validate(value);
    } else {
      this.assign('0');
    }
  }
  writeValue( value: any ): void {
    const val = value.toString();
    this.max = parseInt(this.element.max);
    this.maxLength = this.element.max.length;

    if (!!this.num(val)) {
      this.validate(val);
    }
  }

  registerOnChange( fn: any ): void {}

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {}

  private num(value: string) {
    return parseInt(value);
  }

  private validate(value: string) {
    const num = this.num(value);
    const val = num.toString();
    if (val.length <= this.maxLength) {
      if (num < this.min) {
        this.assign(this.min.toString());
      } else if (num > this.max) {
        this.assign(this.max.toString());
      } else {
        this.assign(val);
      }
    } else {
      this.assign(val.substring(0, value.length - 1));
    }
  }

  private assign(value) {
    this.c.control.setValue(value);
    this.changes.push(value);
    // Trigger delayed focus event to prevent form changes from hanging
    setTimeout(() => this.element.focus(), 100);
  }
}
