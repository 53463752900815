

import { Injectable } from '@angular/core';

@Injectable()

export class ProductService {

    getCorrectId(id: string): string {
        if (id.substr(id.length - 1) !== '=') {
            id = id + '=';
        }
        return id;
    }

}
