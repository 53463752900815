import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

import { BaseService } from 'src/app/services/base-service';
import { tap, catchError } from 'rxjs/operators';
import { Upload } from 'src/app/classes';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService extends BaseService {

  serviceType: any = {
    EVENT_ANNONCEMENT: { name: 'event-annoncement', folder: 'event-annoncement', extensions: ['pdf', 'jpg', 'png'] },
    EVENT_LOGO: { name: 'event-logo', folder: 'event-logo', extensions: ['svg', 'jpg', 'png'] },
    COURSE_MAP: { name: 'coursemap', folder: 'coursemap', extensions: ['pdf', 'jpg', 'png'] },
    OCCURRENCE_REPORT: { name: 'occurrence-report', folder: 'occurrence-report', extensions: ['pdf', 'docx', 'doc'] },
    DISCIPLINE_REPORT: { name: 'discipline-report', folder: 'discipline-report', extensions: ['pdf', 'docx', 'doc'] }
  };

  constructor(protected http: HttpClient, protected cookie: CookieService) {
    super(http, cookie);
  }

  get options() {
    return {
      withCredentials: true,
      headers: new HttpHeaders({
        Authorization: this.cookie.get('user_token')
      })
    };
  }

  fileUpload(file: FormData, eventId?: string): Observable<any> {
    if (eventId) {
      file.append('event_id', eventId);
    }
    return this.http.post(this.url('uploads'), file, this.options);
  }

  getUploadsForEvent(eventId: number|string): Observable<Upload[]> {
    return this.http.get<Upload[]>(this.url(`event_permit_attachments?event_id=${eventId}`), this.options)
      .pipe(
        tap(_ => this.log('fetched profiles')),
        catchError(this.handleError<Upload[]>('getUploadsForEvent', [], { eventId }))
      );
  }
}
