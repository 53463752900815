import * as tslib_1 from "tslib";
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from 'src/app/services/base-service';
import { tap, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
var FileUploadService = /** @class */ (function (_super) {
    tslib_1.__extends(FileUploadService, _super);
    function FileUploadService(http, cookie) {
        var _this = _super.call(this, http, cookie) || this;
        _this.http = http;
        _this.cookie = cookie;
        _this.serviceType = {
            EVENT_ANNONCEMENT: { name: 'event-annoncement', folder: 'event-annoncement', extensions: ['pdf', 'jpg', 'png'] },
            EVENT_LOGO: { name: 'event-logo', folder: 'event-logo', extensions: ['svg', 'jpg', 'png'] },
            COURSE_MAP: { name: 'coursemap', folder: 'coursemap', extensions: ['pdf', 'jpg', 'png'] },
            OCCURRENCE_REPORT: { name: 'occurrence-report', folder: 'occurrence-report', extensions: ['pdf', 'docx', 'doc'] },
            DISCIPLINE_REPORT: { name: 'discipline-report', folder: 'discipline-report', extensions: ['pdf', 'docx', 'doc'] }
        };
        return _this;
    }
    Object.defineProperty(FileUploadService.prototype, "options", {
        get: function () {
            return {
                withCredentials: true,
                headers: new HttpHeaders({
                    Authorization: this.cookie.get('user_token')
                })
            };
        },
        enumerable: true,
        configurable: true
    });
    FileUploadService.prototype.fileUpload = function (file, eventId) {
        if (eventId) {
            file.append('event_id', eventId);
        }
        return this.http.post(this.url('uploads'), file, this.options);
    };
    FileUploadService.prototype.getUploadsForEvent = function (eventId) {
        var _this = this;
        return this.http.get(this.url("event_permit_attachments?event_id=" + eventId), this.options)
            .pipe(tap(function (_) { return _this.log('fetched profiles'); }), catchError(this.handleError('getUploadsForEvent', [], { eventId: eventId })));
    };
    FileUploadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileUploadService_Factory() { return new FileUploadService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: FileUploadService, providedIn: "root" });
    return FileUploadService;
}(BaseService));
export { FileUploadService };
