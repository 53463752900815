import * as tslib_1 from "tslib";
import { SelectOption } from '../classes';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from './base-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
var ClubService = /** @class */ (function (_super) {
    tslib_1.__extends(ClubService, _super);
    function ClubService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.legacy = true;
        _this.clubs = [];
        _this.clubsLoaded = false;
        return _this;
    }
    ClubService.prototype.searchClubs = function (criteria) {
        var _this = this;
        return this.http.get(this.url('basic_clubs_search_with_filters', criteria, this.nextGenApiUrl), {})
            .pipe(tap(function (_) { return _this.log('searched clubs with criteria'); }), catchError(this.handleError('searchClubs', [], {})));
    };
    ClubService.prototype.getClub = function (clubId) {
        var _this = this;
        return this.http.get(this.url('clubs/' + clubId, null, this.nextGenApiUrl))
            .pipe(tap(function (_) { return _this.log('fetched club'); }), catchError(this.handleError('getClub', null, { clubId: clubId })));
    };
    // Legacy endpoints
    ClubService.prototype.getLegacyClubs = function () {
        var _this = this;
        return this.http.get(this.url('clubs?limit=3000'))
            .pipe(tap(function (_) { return _this.log('fetched clubs'); }), catchError(this.handleError('getClubs', [])));
    };
    // TODO: Replace once club emails represented in Nextgen
    ClubService.prototype.getLegacyClub = function (name) {
        var _this = this;
        return this.http.get(this.url('clubs?club=' + name))
            .pipe(tap(function (_) { return _this.log('fetched clubs by name'); }), catchError(this.handleError('getClub', [], { name: name })));
    };
    ClubService.prototype.setClubListOptions = function () {
        var _this = this;
        this.searchClubs({ is_active: true }).subscribe(function (clubs) {
            if (clubs.length > 0) {
                _this.clubList = [];
                var clubsList = clubs.sort(function (a, b) { return a.club_name.trim().localeCompare(b.club_name.trim()); });
                clubsList.forEach(function (club) {
                    _this.clubList.push(new SelectOption(club.club_id.toString(), club.club_name));
                    _this.clubs.push(club);
                });
                _this.clubsLoaded = true;
            }
        });
    };
    ClubService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClubService_Factory() { return new ClubService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: ClubService, providedIn: "root" });
    return ClubService;
}(BaseService));
export { ClubService };
