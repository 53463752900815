<app-card [formGroup]="eventDate" [light]="false">
  <div class="card-body">
    <h4 class="card-title divider" >
    {{title}}
    <span
      *ngIf="cardIndex > 0 && !disabled"
      class="float-right cursor-pointer"
      (click)= "removeDate(cardIndex)"
    ><i class="icon icon-delete"></i></span>
    </h4>
    <br>
    <app-text-input formControlName="event_date_description"
      [type]="'text'"
      [name]="'event_date_description'"
      [label]="'Description'"
      [placeholder]="'e.g. Stage 1'"
      [errors]="f.event_date_description.errors"
      [formSubmitted]="submitted"
      [disabled]="disabled"
    ></app-text-input>
    <!-- commented out for MBR-2992 -->
    <!-- <app-multi-select
      formControlName="event_date_sub_disciplines"
      [label]="'Please select the sub-discipline(s) that most accurately represent your event'"
      [placeholder]="'Searchable multi-select'"
      [options]="subDisciplines"
      [name]="'event_date_sub_disciplines'"
      [formSubmitted]="submitted"
      [errors]="f.event_date_sub_disciplines.errors"
      [required]=true
      [disabled]="disabled"
    ></app-multi-select> -->
    <app-datepicker
      formControlName="event_date_start"
      [name]="'event_date_start'"
      [label]="'Date'"
      [formSubmitted]="submitted"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [markDisabled]="markDisabled"
      [errors]="f.event_date_start.errors"
      [required]=true
      [disabled]="disabled || dateDisabled"
      [iconClass]="'icon-information'"
      [tooltipContent]="'Event dates can only be changed by Event Services staff once a listing or permit is submitted. To change the dates of your event you will need to send a comment with the change details or reach out to eventservices@usacycling.org for support.'"
    ></app-datepicker>
    <h4>Location</h4>
    <app-text-input formControlName="event_date_street"
      [type]="'text'"
      [name]="'event_date_street'"
      [label]="'Street Address'"
      appSmartAddressSearch
      (addressChange)="setAddress($event)"
      [placeholder]="''"
      [formSubmitted]="submitted"
      [errors]="f.event_date_street.errors"
      [required]=true
      [disabled]="disabled"
    ></app-text-input>
    <div class="row">
        <div class="col-sm-12">
            <app-text-input formControlName="event_date_unit"
              [type]="'text'"
              [name]="'event_date_unit'"
              [label]="'Apt/Suite/Unit'"
              [placeholder]="''"
              [formSubmitted]="submitted"
              [errors]="f.event_date_unit.errors"
              [disabled]="disabled"
            ></app-text-input>
          </div>
      <div class="col-sm-12">
        <app-text-input formControlName="event_date_city"
          [type]="'text'"
          [name]="'event_date_city'"
          [label]="'City'"
          [placeholder]="''"
          [formSubmitted]="submitted"
          [errors]="f.event_date_city.errors"
          [required]=true
          [disabled]="disabled"
        ></app-text-input>
      </div>
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <app-select formControlName="event_date_state"
              [name]="'event_date_state'"
              [label]="'State'"
              [options]="stateOptions"
              [formSubmitted]="submitted"
              [errors]="f.event_date_state.errors"
              [required]="true"
              [disabled]="disabled"
            ></app-select>
          </div>
          <div class="col-sm-6">
            <app-text-input formControlName="event_date_postal_code"
              [type]="'text'"
              [name]="'event_date_postal_code'"
              [label]="'Zip'"
              [placeholder]="''"
              [formSubmitted]="submitted"
              [errors]="f.event_date_postal_code.errors"
              [required]=true
              [disabled]="disabled"
            ></app-text-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-card>
