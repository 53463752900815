<div class="container-fluid">
  <div class="row">
    <div class="main-container col-12 add-on-items">
      <h2>Optional Add-Ons</h2>
      <p class="small">
        The insurance products listed below are optional add-ons to meet the unique needs of your event.
        If your event does not need these products then you do not need to add, or answer any questions below.
        For questions or assistance reach out to <a href="mailto:eventservices@usacycling.org">eventservices@usacycling.org</a>.
      </p>

      <app-loader *ngIf="loading"></app-loader>

      <app-add-on-list *ngIf="!loading"
        [discipline]="discipline"
        [eventDates]="eventDates"
        [eventId]="eventId"
      ></app-add-on-list>
    </div>
  </div>
</div>
