<div class="container first">
  <div class="row">
    <div class="col-md-12">
      <h2>Additional Insured Certificates</h2>

      <!-- Temporary notice until issues behind PEM-66 & PEM-81 are resolved -->
      <h4 class="font-italic text-primary mb-3">
        Please copy <a href="mailto:eventservices@usacycling.org">eventservices@usacycling.org</a> with any entities
        you add below so we can ensure the information is transferred to the certificate center correctly.
      </h4>

      <h5 class="space-below-sm">USA Cycling can provide Additional Insured Certificates for Event Organizers when required. These certificates are automatically created for the Event Organizer, as well as any sponsoring clubs. Additional certificates listing other entities may be added for an additional fee.</h5>
      <h5 class="space-below-sm">Cost: $15.00 each</h5>
      <p class="space-below-sm">In addition, USA Cycling can provide several special endorsements if required as part of an agreement. The cost is $100 per special endorsement per certificate. These include:</p>
      <ul class="space-below-lg">
        <li>Additional Insured</li>
        <li>Waiver of Subrogation</li>
        <li>Primary and Non-Contributory</li>
        <li>Notice of Cancellation</li>
      </ul>
      <p class="space-below-sm">
        <strong>Please allow 3-4 weeks for special endorsements to be processed.</strong>
      </p>
      <p class="space-below-sm">Please contact your USAC Event Service Coordinator with any questions on these various options.</p>
      <p class="space-below-sm"><a target="_blank" href="https://usacycling.org/event-faqs">Have questions about additional insured certificates?</a></p>
      <h3 class="space-below-sm space-above-sm">Certificates</h3>
      <p class="small">All certificates must be saved prior to continuing.</p>

      <!-- Loader -->
      <app-loader *ngIf="loading"></app-loader>

      <div *ngIf="!loading">
      <!-- Included Certificate Organism -->
        <div *ngFor = "let certificate of insurance.includedCertificates; let i = index" style="margin-bottom: 10px;">
          <app-certificate
            *ngIf="draftOrder && additionalInsured && endorsementVariants.length"
            [includedCert]="true"
            [certificate]="certificate"
            [certificateId]="i"
            [eventId]="eventId"
            [reffIndex] = "certificate.reffIndex"
            (addToCart)="cart.syncCertificates(certificate.reffIndex, certificate.isEdit)"
            [namedInsuredOrganizerVariant]="namedInsuredOrganizer"
            [namedInsuredClubVariant]="namedInsuredClub"
            [endorsementVariants]="endorsementVariants"
            [draftOrder]="draftOrder"
            [pageSubmitted]="submitted"
            (validateForm)="checkValidForms($event)"
            (updateEndorsements)="onEndorsementUpdate($event)"
            (saveDeleteCert)="onCertificateSave($event)"
          ></app-certificate>
        </div>
      <!-- New Certificate Organism -->
        <div *ngFor = "let certificate of insurance.certificates; let i = index" style="margin-bottom: 10px;">
          <app-certificate
            *ngIf="draftOrder && additionalInsured && endorsementVariants.length"
            [certificate]="certificate"
            [certificateId] = "i"
            [eventId]="eventId"
            [reffIndex] = "certificate.reffIndex"
            [additionalInsuredVariant]="additionalInsured"
            [endorsementVariants]="endorsementVariants"
            [draftOrder]="draftOrder"
            [pageSubmitted]="submitted"
            (validateForm)="checkValidForms($event)"
            (updateEndorsements)="onEndorsementUpdate($event)"
            (saveDeleteCert)="onCertificateSave($event)"
            (addToCart)="cart.syncCertificates(certificate.reffIndex, certificate.isEdit)"
          ></app-certificate>
        </div>
        <div class="space-above-lg">
          <span>
            <app-button
            [type]="'btn-secondary'"
            (click)="onAddCert()">Add Certificate</app-button>
          </span>
          <span class="float-right">
              <app-button
              [type]="'btn-secondary'"
              [disabled]="saveDisabled"
              (click)="onSave()">Save & Continue</app-button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
