import { Component, OnInit, Input, ElementRef, HostListener } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import qs from 'qs';
import { CheckboxOption, SelectOption, Discipline } from '../../classes';
import { EventService, EventListingService, PermitService, LocationService } from '../../services/';
@Component({
  selector: 'app-event-queue-filter',
  templateUrl: './event-queue-filter.component.html',
  styles: []
})
export class EventQueueFilterComponent implements OnInit {
  filterForm: FormGroup;
  filterDefaults: object;
  submitted = false;
  isCollapsed = true;
  title: string;
  listingTypeOptions: SelectOption[] = [];
  checkboxOptions: CheckboxOption[] = [];
  cityOptions: string;
  stateOptions: SelectOption[] = [];
  statusOptions: SelectOption[] = [];
  disciplineOptions: SelectOption[] = [];
  regionOptions: SelectOption[] = [];
  postEventStatusOptions: SelectOption[] = [];
  calendarLabelOptions: SelectOption[] = [];
  queryParams: any;

  @Input() userRole: string;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (
      !this.elRef.nativeElement.firstChild.children[1].firstChild.contains(event.target)
      && event.target.getAttribute('aria-controls') !== 'filterCollapse'
      && !this.datepickerParent(event)
      && !this.multiselectCloseParent(event)
    ) {
      this.isCollapsed = true;
    }
  }

  constructor(
    private elRef: ElementRef,
    private formBuilder: FormBuilder,
    private eventService: EventService,
    public eventListing: EventListingService,
    public permitService: PermitService,
    private locationService: LocationService,
  ) { }

  // convenience getter for easy access to form fields
  get f() { return this.filterForm.controls; }

  ngOnInit() {
    this.title = 'Filters';

    this.listingTypeOptions = [
      new SelectOption(null, 'Select...'),
      new SelectOption('permit', 'Permit'),
      new SelectOption('listing', 'Listing')
    ];
    this.statusOptions = this.eventService.statusOptions;
    this.eventListing.getDisciplines().subscribe( data => {
      const disciplines: Discipline[] = this.eventListing.filterDisciplines(data);

      this.disciplineOptions = disciplines.map(discipline => {
        return new SelectOption(discipline.cd_id.toString(), discipline.cd_value);
      });

      if (this.queryParams.disciplines && this.queryParams.disciplines.length > 0) {
        const discipline_ids = this.queryParams.disciplines.map(discipline => discipline.value || discipline);

        this.f.event_discipline.setValue(this.disciplineOptions.filter(option => discipline_ids.includes(option.value)));
      }
    });

    this.permitService.getCalendarLabels().subscribe((labels) => {
      if (labels) {
        labels.forEach((label) => this.calendarLabelOptions.push(new SelectOption(label.id.toString(), label.name)));

        if (this.queryParams.calendar_label_ids && this.queryParams.calendar_label_ids.length > 0) {
          const calendarLabelIds = this.queryParams.calendar_label_ids.map(labelId => labelId.value || labelId);

          this.f.calendar_label_ids.setValue(this.calendarLabelOptions.filter(option => calendarLabelIds.includes(option.value)));
        }
      }
    });

    this.cityOptions = '';
    this.regionOptions = [
      new SelectOption('EAST', 'EAST'),
      new SelectOption('WEST', 'WEST'),
      new SelectOption('CENTRAL', 'CENTRAL')
    ];

    this.locationService.stateOptions$.subscribe((stateOptions: SelectOption[]) => {
      this.stateOptions = [
        new SelectOption(null, '...'),
        ...stateOptions
      ];
    });

    this.locationService.getStates().subscribe();

    this.postEventStatusOptions = [
      new SelectOption(null, 'Select...'),
      new SelectOption('form-due', 'Form Due'),
      new SelectOption('payment-due', 'Payment Due'),
      new SelectOption('payment-late', 'Payment Late'),
      new SelectOption('payment-complete', 'Payment Complete')
    ];

    this.queryParams = qs.parse(window.location.search.replace(/^\?/, ''));

    // console.log('filter query params', this.queryParams);

    let currentStatuses = [];

    if (this.queryParams.status && this.queryParams.status.length > 0) {
      currentStatuses = this.queryParams.status.map(status => status.value || status);
    }

    const currentFilters = {
      name: this.queryParams.name || '',
      listing_type: this.queryParams.listing_type || '',
      status: [ this.statusOptions.filter(option => currentStatuses.includes(option.value)) ],
      city: this.queryParams.city || '',
      states: this.queryParams.states || '',
      event_start_date: this.queryParams.event_start_date || null,
      event_end_date: this.queryParams.event_end_date || null,
      permitCreateStartDate: this.queryParams.permit_date_range && this.queryParams.permit_date_range.start_date ? this.queryParams.permit_date_range.start_date : '', /* tslint:disable-line: max-line-length */
      permitCreateEndDate: this.queryParams.permit_date_range && this.queryParams.permit_date_range.end_date ? this.queryParams.permit_date_range.end_date : '', /* tslint:disable-line: max-line-length */
      // event_discipline: this.queryParams.disciplines
        // && this.queryParams.disciplines.length > 0 ?
        // [ this.queryParams.disciplines.map(discipline => discipline.value || discipline) ] : [[]],
      regions: this.queryParams.regions && this.queryParams.regions.length > 0 ? [ this.queryParams.regions.map(region => region.value || region) ] : [[]], /* tslint:disable-line: max-line-length */
      // organizerFirst: '',
      // organizerLast: '',
      // event_organizer: '',
      // refereeFirst: '',
      // refereeLast: '',
      // refereeId: '',
      order_number: this.queryParams.order_number || '',
      post_event_status: this.queryParams.post_event_status || '',
      // hasNotifications: false,
      // delinquent: false
    };

    // console.log('current filters', currentFilters);

    this.filterDefaults = {
      name: '',
      listing_type: '',
      status: '',
      city: '',
      states: '',
      regions: '',
      event_start_date: null,
      event_end_date: null,
      permitCreateStartDate: '',
      permitCreateEndDate: '',
      event_discipline: '',
      organizerFirst: '',
      organizerLast: '',
      event_organizer: '',
      refereeFirst: '',
      refereeLast: '',
      refereeId: '',
      order_number: '',
      post_event_status: '',
      calendar_label_ids: '',
      hasNotifications: false,
      delinquent: false
    };

    this.filterForm = this.formBuilder.group(Object.assign({}, this.filterDefaults, currentFilters));

    // this.f.city.setValidators([Validators.required]);
  }

  debug(event: any): void {
    console.log('debug form', this.f, event);
  }

  datepickerParent(event) {
    const parent = event.target.parentElement;
    return parent && parent.getAttribute('class') && parent.getAttribute('class').includes('ngb-dp-day');
  }

  multiselectCloseParent(event) {
    const parent = event.target.parentElement;
    return parent && parent.getAttribute('class') && parent.getAttribute('class').includes('selected-item');
  }

  onSubmit() {
    this.submitted = true;

    // console.log('submitting form', this.f);

    // stop here if form is invalid
    if (this.filterForm.invalid) {
        return;
    }

    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.filterForm.value, null, 4));

    this.isCollapsed = true;

    const eventQueueParams = this.eventService.cleanQueueParams(this.filterForm.value);

    window.location.href = window.location.pathname + '?' + qs.stringify(eventQueueParams);
  }

  onReset() {
      this.submitted = false;
      window.location.href = window.location.pathname + '?';

      // this.filterForm.reset(this.filterDefaults);
      // this.onSubmit();
  }

  get isUsacAdmin(): boolean { return this.userRole === 'usac_admin'; }

}
