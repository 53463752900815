import { Cart } from './../../shared/models';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
var GlobalService = /** @class */ (function () {
    function GlobalService() {
        this.cartObs = new BehaviorSubject(new Cart());
        this.lineItemsObs = new BehaviorSubject([]);
        this.newlineItemObs = new BehaviorSubject(null);
        this.cartOpenCloseObs = new BehaviorSubject(true);
        this.discountCodes = [];
        var cart = new Cart();
        this.cartObs.next(cart);
        var uciDiscount = environment.uciDiscount, natChampDiscount = environment.natChampDiscount, natDiscount = environment.natDiscount, natTeamDiscount = environment.natTeamDiscount;
        if (uciDiscount && Array.isArray(uciDiscount)) {
            this.discountCodes = this.discountCodes.concat(uciDiscount);
        }
        if (natChampDiscount && Array.isArray(natChampDiscount)) {
            this.discountCodes = this.discountCodes.concat(natChampDiscount);
        }
        if (natDiscount && Array.isArray(natDiscount)) {
            this.discountCodes = this.discountCodes.concat(natDiscount);
        }
        if (natTeamDiscount && Array.isArray(natTeamDiscount)) {
            this.discountCodes = this.discountCodes.concat(natTeamDiscount);
        }
    }
    Object.defineProperty(GlobalService.prototype, "cart", {
        get: function () {
            return this.cartObs.getValue();
        },
        set: function (cart) {
            this.cartObs.next(cart);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "cartOpenClose", {
        get: function () {
            return this.cartOpenCloseObs.getValue();
        },
        set: function (value) {
            this.cartOpenCloseObs.next(value);
        },
        enumerable: true,
        configurable: true
    });
    GlobalService.prototype.addItemToCart = function (lineItem) {
        var lineItems = this.lineItemsObs.getValue();
        var sameVariant = lineItems.filter(function (item) { return item.variant.id === lineItem.variant.id; });
        if (sameVariant.length) {
            sameVariant[0].quantity = lineItem.quantity;
            this.lineItemsObs.next(lineItems);
        }
        else {
            this.newlineItemObs.next(lineItem);
        }
    };
    GlobalService.prototype.increaseItemQuantity = function (lineItem, lineItems, quantity) {
        if (quantity === void 0) { quantity = 1; }
        if (!lineItems) {
            lineItems = this.lineItemsObs.getValue();
        }
        var sameVariant = lineItems.filter(function (item) { return item.variant.id === lineItem.variant.id; });
        if (sameVariant.length) {
            sameVariant[0].quantity += quantity;
            this.lineItemsObs.next(lineItems);
        }
        else {
            this.newlineItemObs.next(lineItem);
        }
    };
    GlobalService.prototype.decreaseItemQuantity = function (lineItem, lineItems, quantity) {
        if (quantity === void 0) { quantity = 1; }
        if (!lineItems) {
            lineItems = this.lineItemsObs.getValue();
        }
        var sameVariant = lineItems.find(function (item) { return item.variant.id === lineItem.variant.id; });
        if (sameVariant.quantity > 1) {
            sameVariant.quantity -= quantity;
            this.lineItemsObs.next(lineItems);
        }
        else {
            this.removeItem(sameVariant);
        }
    };
    GlobalService.prototype.removeItem = function (lineItem) {
        var lineItems = this.lineItemsObs.getValue();
        var index = lineItems.indexOf(lineItem);
        if (index !== -1) {
            lineItems.splice(index, 1);
            this.lineItemsObs.next(lineItems);
        }
    };
    GlobalService.prototype.removeItemViaRadio = function (lineItem) {
        var lineItems = this.lineItemsObs.getValue();
        for (var i = 0; i < lineItems.length; i++) {
            if (lineItems[i].variantId === lineItem.variantId) {
                lineItems.splice(i, 1);
                this.lineItemsObs.next(lineItems);
            }
        }
    };
    GlobalService.prototype.cartToggle = function () {
        var el = document.getElementById('cart-container');
        el.classList.toggle('close_me');
    };
    GlobalService.prototype.cartOpen = function () {
        var el = document.getElementById('cart-container');
        el.classList.remove('close_me');
    };
    return GlobalService;
}());
export { GlobalService };
