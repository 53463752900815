import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EventDate, Referee, Event } from 'src/app/classes';

import { EventService} from 'src/app/services';

@Component({
  selector: 'app-pre-event-ref-assignment',
  templateUrl: './pre-event-ref-assignment.component.html',
  styles: []
})
export class PreEventRefAssignmentComponent implements OnChanges, OnInit {

  @Input() event: Event;
  @Input() eventDates: EventDate[];
  @Input() userRole: string;

  assignedRefs: Referee[] = [];
  isSeries = false;

  adminRoles = ['usac_admin', 'la_admin'];
  isAdmin: boolean;

  get loading(): boolean {
    return !this.userRole || !this.eventDates;
  }

  constructor(private eventService: EventService) { }

  ngOnInit(): void {
    this.setIsSeries();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userRole) {
      this.setAssignmentAccess();
    }
  }

  private setAssignmentAccess(): void {
    this.isAdmin = this.adminRoles.includes(this.userRole);
  }

  private setIsSeries(): void {
    this.isSeries = this.eventService.isSeries(this.event);
  }

  updateEventDate(id: string, referee: Referee) {
    if (this.isSeries) {
      const eventDate = this.eventDates.find(date => date.event_date_id.toString() === id);
      this.setChiefRef(eventDate, referee);
    } else {
      this.eventDates.forEach(eventDate => {
        this.setChiefRef(eventDate, referee);
      });
    }
  }

  setChiefRef(date, referee) {
    const data = Object.assign({}, date, {
      event_date_chief_ref: referee.usac_id,
      // To prevent overriding subdisciplines
      event_date_sub_disciplines: null
    });
    this.eventService.editEventDate(data, date.event_date_id).subscribe(() => {
      date.event_date_chief_ref = {
        profile_first_name: referee.first_name,
        profile_last_name: referee.last_name
      };
    });
  }

  onRefereeAdd($event) {
    this.updateEventDate($event.event_date_id, $event.chief_ref);
  }
}
