<h4>History</h4>

<!-- Loader -->
<app-loader *ngIf="loading" [small]="true" [align]="'left'"></app-loader>

<!-- Empty State -->
<div *ngIf="!loading && !entries.length" class="row">
  <p class="col-12">There is no change history for this event.</p>
</div>

<div *ngIf="!loading && entries.length" class="mt-3">
  <p *ngFor="let entry of entries">{{entry.time | date:'MMMM d, y, h:mm a'}} - <strong>{{entry.user}}</strong> {{entry.action}}</p>
</div>
