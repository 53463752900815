<h4>Documents</h4>

<!-- Loader -->
<app-loader *ngIf="loading" [small]="true" [align]="'left'"></app-loader>

<!-- Empty State -->
<div *ngIf="!loading && !eventDocs.length" class="row">
  <p class="col-12">There are no documents for this event.</p>
</div>

<div *ngIf="!loading && eventDocs.length" class="mt-3">
  <div>
    <div class="row add-on-header">
      <p class="col-md-5 col-lg-4">Document</p>
      <p class="col-md-4 col-lg-4">Date Created</p>
      <p class="col-md-3 col-lg-4">Link</p>
    </div>
    <ul class="add-on-list notification-list">
      <li *ngFor="let eventDoc of eventDocs">
        <div class="row">
          <p class="col-sm-4 col-md-5 col-lg-4 notification-type"><i class="icon-file"></i> {{eventDoc.file_name}}</p>
          <p class="col-sm-4 col-md-4 col-lg-4 notification-status">{{eventDoc.createdon | date}}</p>
          <app-button
          [type]="'btn-light btn-sm'"
          (click)="openDoc(eventDoc.uri)"
          style="flex:2;"
          class="col-sm-4 col-md-3 col-lg-4"
        ><i class="icon-download"></i>Download</app-button>
        </div>
      </li>
    </ul>
  </div>
</div>
