export class Certificate {
  id: number;
  name: string;
  relationship: string;
  custom_relationship: string;
  street: string;
  city: string;
  unit: string;
  state: string;
  zip: string;
  email: string;
  // TODO: Confirm if API field expects/returns string or number
  phone: string|number;
  insured_info: string;
  type: string;
  endorsement: number[];
  created_at: Date;
  event_id: number|string;
  profile_id: number;
  index: number;
  certificate_paid: boolean;

  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.relationship = data.relationship;
    this.custom_relationship = data.custom_relationship;
    this.street = data.street;
    this.city = data.city;
    this.unit = data.unit;
    this.state = data.state;
    this.zip = data.zip;
    this.email = data.email;
    this.phone = data.phone;
    this.insured_info = data.insured_info;
    this.type = data.type || 'additional';
    this.endorsement = data.endorsement;
    this.created_at = data.created_at;
    this.event_id = data.event_id;
    this.profile_id = data.profile_id;
    this.index = data.index;
    this.certificate_paid = data.certificate_paid;
  }
}
