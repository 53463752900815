export class CartItem {
    constructor(
        public title: string,
        public quantity: number,
        public price: number,
        public category: string,
        public reff: number
    ) {}
    getTotalPrice() {
        return this.quantity * this.price;
    }
}
