<div class="container first">
  <div class="d-flex align-items-center justify-content-between mb-3">
    <div>
      <h3>Reports</h3>
    </div>
    <div>
      <app-button [type]="'btn-secondary'" [routerLink]="'/queue'">Queue</app-button>
    </div>
  </div>
  <div class="row">
    <ngb-tabset class="tabs-primary col-12" [ngClass]="{'tabs-primary-vertical': tabOrientation === 'vertical'}"
      [justify]="'justified'" [activeId]="activeLevel1" [orientation]="tabOrientation"
      (window:resize)="onResize($event)" (tabChange)="clearError()"
    >
      <ngb-tab title="Event Organizer All Event Transaction Report"
        [id]="'Event Organizer All Event Transaction Report'">
        <ng-template ngbTabContent>
          <form [formGroup]="transactionReport" (ngSubmit)="onTransactionReportSubmit()">
            <div class="tab-content-container">
              <div class="row">
                <label class="col-12 no-margin"><strong>Transaction Date Range</strong></label>
                <div class="col-sm-6 col-md-4">
                  <app-datepicker
                    formControlName="event_start_date"
                    [placeholder]="'Start'">
                  </app-datepicker>
                </div>
                <div class="col-sm-6 col-md-4">
                  <app-datepicker
                    formControlName="event_end_date"
                    [placeholder]="'End'">
                  </app-datepicker>
                </div>
              </div>
              <div *ngIf="isUsacAdmin" class="row mt-4">
                <label class="col-12"><strong>Event Organizer</strong></label>
                <div class="col-sm-6 col-md-4">
                  <app-text-input
                    formControlName="organizer_first"
                    [type]="'text'"
                    [name]="'organizer_first'"
                    [id]="'organizer_first'"
                    [label]="'First Name'"
                    [placeholder]="'First Name'">
                  </app-text-input>
                </div>
                <div class="col-sm-6 col-md-4">
                  <app-text-input
                    formControlName="organizer_last"
                    [type]="'text'"
                    [name]="'organizer_last'"
                    [label]="'Last Name'"
                    [id]="'organizer_last'"
                    [placeholder]="'Last Name'">
                  </app-text-input>
                </div>
                <div class="col-sm-4">
                  <app-text-input
                    formControlName="organizer_comp_id"
                    [type]="'text'"
                    [name]="'organizer_comp_id'"
                    [id]="'organizer_comp_id'"
                    [label]="'Member ID'"
                    [placeholder]="'Member ID'">
                  </app-text-input>
                </div>
              </div>
              <div class="text-center mt-4">
                <button type="submit" class="btn btn-secondary" [disabled]="isLoadingTransactions">
                  <i class="icon icon-download"></i>
                  Download CSV
                </button>
                <div class="space-above-sm">
                  <p *ngIf="isLoadingTransactions">Generating Report ...</p>
                  <div *ngIf="csvService.displayError" class="report-alerts">
                    <app-alert [alerts]="alert"></app-alert>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ng-template>
      </ngb-tab>
      <ngb-tab *ngIf="isUsacAdmin" title="Official Assignment Report" [id]="'Official assignment report'">
        <ng-template ngbTabContent>
          <form [formGroup]="assignmentReport" (ngSubmit)="onAssignmentReportSubmit()">
            <div class="tab-content-container">
              <div class="row">
                <div class="col-sm-6 col-md-4">
                  <app-text-input
                    formControlName="states"
                    [type]="'text'"
                    [name]="'states'"
                    [id]="'states'"
                    [label]="'State'"
                  [placeholder]="'State'">
                  </app-text-input>
                </div>
                <div class="col-sm-6 col-md-4">
                  <app-text-input
                    formControlName="event_zip"
                    [type]="'text'"
                    [name]="'event_zip'"
                    [id]="'event_zip'"
                    [label]="'Event Zip'"
                  [placeholder]="'Event Zip'">
                  </app-text-input>
                </div>
                <div class="col-sm-6 col-md-4">
                  <app-text-input
                    formControlName="event_id"
                    [type]="'text'"
                    [name]="'event_id'"
                    [id]="'event_id'"
                    [label]="'Event ID'"
                  [placeholder]="'Event ID'">
                  </app-text-input>
                </div>
              </div>
              <div class="row mt-4">
                <label class="col-12"><strong>Official Assignment</strong></label>
                <div class="col-sm-6 col-md-4">
                  <app-text-input
                    formControlName="chief_ref_first"
                    [type]="'text'"
                    [name]="'chief_ref_first'"
                    [id]="'chief_ref_first'"
                    [label]="'First Name'"
                  [placeholder]="'First Name'">
                  </app-text-input>
                </div>
                <div class="col-sm-6 col-md-4">
                  <app-text-input
                    formControlName="chief_ref_last"
                    [type]="'text'"
                    [name]="'chief_ref_last'"
                    [id]="'chief_ref_last'"
                    [label]="'Last Name'"
                    [placeholder]="'Last Name'">
                  </app-text-input>
                </div>
                <div class="col-sm-6 col-md-4">
                  <app-text-input
                    formControlName="chief_ref_profile_id"
                    [type]="'text'"
                    [name]="'chief_ref_profile_id'"
                    [id]="'chief_ref_profile_id'"
                    [label]="'Member ID'"
                  [placeholder]="'Member ID'">
                  </app-text-input>
                </div>
              </div>
              <div class="row mt-4">
                <label class="col-12"><strong>Event Day</strong></label>
                <div class="col-sm-6 col-md-4">
                  <app-datepicker formControlName="event_start_date" [label]="'Start Date'" [placeholder]="'Start'">
                  </app-datepicker>
                </div>
                <div class="col-sm-6 col-md-4">
                  <app-datepicker formControlName="event_end_date" [label]="'End Date'" [placeholder]="'End'">
                  </app-datepicker>
                </div>
              </div>
              <div class="text-center mt-3">
                <button type="submit" class="btn btn-secondary" [disabled]="isLoadingAssignments">
                  <i class="icon icon-download"></i>
                  Download CSV
                </button>
                <div class="space-above-sm">
                  <p *ngIf="isLoadingTransactions">Generating Report ...</p>
                  <div *ngIf="csvService.displayError" class="report-alerts">
                    <app-alert [alerts]="alert"></app-alert>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
