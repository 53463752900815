/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../organisms/post-event-1-form/post-event-1-form.component.ngfactory";
import * as i3 from "../../organisms/post-event-1-form/post-event-1-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../shared/services/file-upload.service";
import * as i6 from "../../services/post-event.service";
import * as i7 from "@angular/router";
import * as i8 from "./post-event-part1.component";
import * as i9 from "../../services/events.service";
import * as i10 from "../../services/profile.service";
var styles_PostEventPart1Component = [];
var RenderType_PostEventPart1Component = i0.ɵcrt({ encapsulation: 2, styles: styles_PostEventPart1Component, data: {} });
export { RenderType_PostEventPart1Component as RenderType_PostEventPart1Component };
function View_PostEventPart1Component_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " - "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.eventDate.event_date_description; _ck(_v, 1, 0, currVal_0); }); }
function View_PostEventPart1Component_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PostEventPart1Component_3)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(3, null, [" ", " "])), i0.ɵppd(4, 3)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.eventDate.event_date_description; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 0), _co.eventDate.event_date_start, "fullDate", "UTC")); _ck(_v, 3, 0, currVal_1); }); }
function View_PostEventPart1Component_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-post-event-1-form", [], null, null, null, i2.View_PostEvent1FormComponent_0, i2.RenderType_PostEvent1FormComponent)), i0.ɵdid(1, 114688, null, 0, i3.PostEvent1FormComponent, [i4.FormBuilder, i5.FileUploadService, i6.PostEventService, i7.Router], { event: [0, "event"], isSeries: [1, "isSeries"], urlSegments: [2, "urlSegments"], userRole: [3, "userRole"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event; var currVal_1 = _co.isSeries; var currVal_2 = _co.urlSegments; var currVal_3 = _co.userRole; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_PostEventPart1Component_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-post-event-1-form", [], null, null, null, i2.View_PostEvent1FormComponent_0, i2.RenderType_PostEvent1FormComponent)), i0.ɵdid(1, 114688, null, 0, i3.PostEvent1FormComponent, [i4.FormBuilder, i5.FileUploadService, i6.PostEventService, i7.Router], { event: [0, "event"], eventDate: [1, "eventDate"], isSeries: [2, "isSeries"], urlSegments: [3, "urlSegments"], userRole: [4, "userRole"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event; var currVal_1 = _co.eventDate; var currVal_2 = _co.isSeries; var currVal_3 = _co.urlSegments; var currVal_4 = _co.userRole; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_PostEventPart1Component_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "main-container container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Post Event Form"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PostEventPart1Component_2)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "p", [["class", "space-below-lg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Part 1 of 3"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PostEventPart1Component_4)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PostEventPart1Component_5)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.eventDate; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.displayFormEvent(); _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.displayFormEventDate(); _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event.event_name; _ck(_v, 4, 0, currVal_0); }); }
export function View_PostEventPart1Component_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PostEventPart1Component_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.displayFormEventDate() || _co.displayFormEvent()); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PostEventPart1Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-post-event-part1", [], null, null, null, View_PostEventPart1Component_0, RenderType_PostEventPart1Component)), i0.ɵdid(1, 114688, null, 0, i8.PostEventPart1Component, [i7.ActivatedRoute, i7.Router, i9.EventService, i10.ProfileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PostEventPart1ComponentNgFactory = i0.ɵccf("app-post-event-part1", i8.PostEventPart1Component, View_PostEventPart1Component_Host_0, {}, {}, []);
export { PostEventPart1ComponentNgFactory as PostEventPart1ComponentNgFactory };
