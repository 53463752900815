import { Component, OnInit } from '@angular/core';

import {SelectOption } from '../../classes/select_option.class';

@Component({
  selector: 'app-copy-past-event',
  templateUrl: './copy-past-event.component.html',
  styleUrls: []
})
export class CopyPastEventComponent implements OnInit {

  modal1Open = false;
  pastEventsOptions: SelectOption[] = [];

  constructor() { }

  ngOnInit() {

    this.pastEventsOptions = [
      new SelectOption('event1', 'Event 1'),
      new SelectOption('event2', 'Event 2'),
      new SelectOption('event3', 'Event 3'),
    ];
  }
  toggleModal1(event) {
    this.modal1Open = !this.modal1Open;
  }

  openModal1(event) {
    this.modal1Open = true;
  }

  closeModal1(event) {
    this.modal1Open = false;
    // console.log(event);
  }

}
