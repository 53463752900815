<a *ngIf="link else button" href="{{link}}" class="btn {{type}}">
  <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
</a>

<ng-template #button>
  <button class="btn {{type}}" (click)="onClick()" [type]="mode" [disabled]="disabled">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
</ng-template>

<ng-template #buttonContent>
  <ng-content></ng-content>
</ng-template>