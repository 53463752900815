import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Country, Location, SelectOption } from '../classes';

import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root',
})
export class LocationService extends BaseService {
  public countries: Country[];
  public countryOptions: SelectOption[];

  public states: Location[];
  public stateOptions: SelectOption[];

  public countryOptions$: BehaviorSubject<SelectOption[]> = new BehaviorSubject([]);
  public stateOptions$: BehaviorSubject<SelectOption[]> = new BehaviorSubject([]);

  getShopifyCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(this.url('shopify/countries'), this.options)
      .pipe(
        tap((countries: Country[]) => {
          this.log('fetched country list');
          this.countryOptions = countries.map(country => new SelectOption(country.code, country.name));
          this.countryOptions$.next(this.countryOptions);
          this.countries = countries;
        }),
        catchError(this.handleError<Country[]>('getCountries', null, {}))
      );
  }

  getStates(): Observable<any> {
    return this.http.get<any>(this.url('locations'), this.options)
      .pipe(
        tap((resp: any) => {
          const states: Location[] = resp.states;
          this.log('fetched states');
          this.stateOptions = states.map(state => new SelectOption(state.code, state.name));
          this.stateOptions$.next(this.stateOptions);
          this.states = states;
        }),
        catchError(this.handleError<any>('getStates', null))
      );
  }
}
