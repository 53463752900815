export class Notification {
  id: string;
  time: Date;
  user: string|number;
  type: string;
  content: string;
  actionUrl: string;
  read: boolean;
  status: string;
  formControl: string;

  constructor(
    id: string, time?: Date, user?: string|number, type?: string,
    content?: string, actionUrl?: string, read?: boolean, status?: string
  ) {
    this.id = id;
    this.time = time;
    this.user = user;
    this.type = type;
    this.content = content;
    this.actionUrl = actionUrl;
    this.read = read;
    this.status = status;

    this.formControl = this.type + '-' + this.id;
  }
}
