import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { EventService, EventListingService, ProfileService } from '../../services';

import { Profile } from 'src/app/classes';

@Component({
  selector: 'app-event-listing-review',
  templateUrl: './event-listing-review.component.html',
  styleUrls: []
})
export class EventListingReviewComponent implements OnInit, OnDestroy {

  eventId: string;
  currentStep = 0;
  submitModalOpen = false;
  permitModalOpen = false;
  disableModalBtns = false;
  disableListingPermitBtns = false;
  denyRequest = false;
  validRdLicense = false;
  licenseNeeded = false;

  flushListingOnDestroy = false;

  get dataLoaded() { return this.eventListing.datesLoaded && this.eventListing.organizerLoaded; }

  constructor(
    public eventListing: EventListingService,
    public event: EventService,
    private profile: ProfileService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.eventId = this.route.snapshot.paramMap.get('eventId');
    this.eventListing.fetchEventData(this.eventId);

    if (this.eventListing.event_organizer) {
      this.profile.getProfile(this.eventListing.event_organizer).subscribe(profile => {
        if (profile) {
          this.checkRdLicense(profile);
        }
      });
    }
  }

    // Check user's profile for unexpired race director license
    checkRdLicense(profile: Profile) {
      this.validRdLicense = profile.licenses && profile.licenses.some(license => {
        return license.license_type === 20 && new Date(license.license_expiration_date) > new Date();
      });
      // TODO: @Brennan - As an improvement, can we also check the event organizer's other open draft orders for the license variant?
      this.licenseNeeded =
        (!this.eventListing.event_sponsoring_clubs.length
          && !this.validRdLicense
          && this.eventListing.isEventCompetitive
        );
      console.log('RD license needed? ', this.licenseNeeded);
    }

  toggleModal1(event) {
    this.submitModalOpen = !this.submitModalOpen;
  }

  openSubmitModal(event) {
    this.listOrPermit();
    if (this.eventListing.isVirtual) {
      this.exitToQueue(event);
    } else {
      this.submitModalOpen = true;
    }
  }

  closeSubmitModal(event) {
    this.submitModalOpen = false;
  }

  displayActions() {
    return this.eventListing.mode === 'create' || this.eventListing.event_status === 'listing-in-progress';
  }

  exitToQueue(event) {
    this.closeSubmitModal(event);
    this.router.navigate(['/queue']);
  }

  openPermitModal(event) {
    if (this.licenseNeeded) {
      this.listOrPermit();
      this.permitModalOpen = true;
    } else {
      this.listOrPermit(true);
    }
  }

  closePermitModal() {
    this.permitModalOpen = false;
  }

  permitModalContinueText() {
    return this.licenseNeeded ? 'Add License and Continue to Permit' : 'Continue to Permit';
  }

  listOrPermit(goToPermit = false) {
    this.disableListingPermitBtns = true;
    this.disableModalBtns = false;
    this.event.updateEventStatus(this.eventId, 'listing-in-review').subscribe(resp => {
      if (resp.message) {
        if (goToPermit) {
          this.toPermit();
        }
      } else {
        // TODO: Consider more explicit error handling
        this.disableListingPermitBtns = false;
      }
    });
  }

  toListing() {
    // Force listing reload before editing
    window.location.assign(`/event/${this.eventId}/listing`);
  }

  toPermit() {
    this.submitModalOpen = false;
    this.permitModalOpen = false;
    this.flushListingOnDestroy = true;
    setTimeout(() => {
      this.router.navigate([`/event/${this.eventId}/permit-application/part-1`]);
    }, 200);
  }

  /* commenting this feature for now, we may reuse this in future
  postChanges() {

  }

  denyChangeRequest() {
    this.denyRequest = !this.denyRequest;
  }

  sendReason() {

  }

  approveChanges() {
  } */

  ngOnDestroy() {
    if (this.eventListing.mode === 'update' && this.flushListingOnDestroy) {
      this.eventListing.flush();
    }
  }

}
