/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../atoms/loader/loader.component.ngfactory";
import * as i2 from "../../atoms/loader/loader.component";
import * as i3 from "../../molecules/ref-accordion/ref-accordion.component.ngfactory";
import * as i4 from "../../molecules/ref-accordion/ref-accordion.component";
import * as i5 from "../add-referee/add-referee.component.ngfactory";
import * as i6 from "../add-referee/add-referee.component";
import * as i7 from "@angular/forms";
import * as i8 from "../../services/profile.service";
import * as i9 from "@angular/common";
import * as i10 from "./pre-event-ref-assignment.component";
import * as i11 from "../../services/events.service";
var styles_PreEventRefAssignmentComponent = [];
var RenderType_PreEventRefAssignmentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PreEventRefAssignmentComponent, data: {} });
export { RenderType_PreEventRefAssignmentComponent as RenderType_PreEventRefAssignmentComponent };
function View_PreEventRefAssignmentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], { small: [0, "small"], align: [1, "align"] }, null)], function (_ck, _v) { var currVal_0 = true; var currVal_1 = "left"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_PreEventRefAssignmentComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["There are no available event dates for managing chief referees."]))], null, null); }
function View_PreEventRefAssignmentComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-ref-accordion", [], null, null, null, i3.View_RefAccordionComponent_0, i3.RenderType_RefAccordionComponent)), i0.ɵdid(2, 114688, null, 0, i4.RefAccordionComponent, [], { eventDate: [0, "eventDate"], isSeries: [1, "isSeries"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.isSeries; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_PreEventRefAssignmentComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "col-md-6 pl-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-add-referee", [], null, [[null, "addReferee"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addReferee" === en)) {
        var pd_0 = (_co.onRefereeAdd($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_AddRefereeComponent_0, i5.RenderType_AddRefereeComponent)), i0.ɵdid(2, 114688, null, 0, i6.AddRefereeComponent, [i7.FormBuilder, i8.ProfileService], { eventDates: [0, "eventDates"], isSeries: [1, "isSeries"] }, { addReferee: "addReferee" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.eventDates; var currVal_1 = _co.isSeries; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_PreEventRefAssignmentComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "row space-above-lg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i9.ɵNgClassImpl, i9.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i9.NgClass, [i9.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(4, { "ref-left col-md-6 pr-4": 0, "col-12": 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreEventRefAssignmentComponent_5)), i0.ɵdid(6, 278528, null, 0, i9.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreEventRefAssignmentComponent_6)), i0.ɵdid(8, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.isAdmin, !_co.isAdmin); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.eventDates; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.isAdmin; _ck(_v, 8, 0, currVal_2); }, null); }
function View_PreEventRefAssignmentComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreEventRefAssignmentComponent_3)), i0.ɵdid(2, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreEventRefAssignmentComponent_4)), i0.ɵdid(4, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.eventDates.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.eventDates.length; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PreEventRefAssignmentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Chief Referee Assignment"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreEventRefAssignmentComponent_1)), i0.ɵdid(3, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreEventRefAssignmentComponent_2)), i0.ɵdid(5, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.loading; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_PreEventRefAssignmentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-pre-event-ref-assignment", [], null, null, null, View_PreEventRefAssignmentComponent_0, RenderType_PreEventRefAssignmentComponent)), i0.ɵdid(1, 638976, null, 0, i10.PreEventRefAssignmentComponent, [i11.EventService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreEventRefAssignmentComponentNgFactory = i0.ɵccf("app-pre-event-ref-assignment", i10.PreEventRefAssignmentComponent, View_PreEventRefAssignmentComponent_Host_0, { event: "event", eventDates: "eventDates", userRole: "userRole" }, {}, []);
export { PreEventRefAssignmentComponentNgFactory as PreEventRefAssignmentComponentNgFactory };
