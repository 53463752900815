import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventService, ProfileService } from '../../services';
import { CsvDownloadService, ShopifyService } from '../../shared/services';
import { EventQueueRow, Alert } from 'src/app/classes';
import { CookieService } from 'ngx-cookie-service';
import { FormBuilder } from '@angular/forms';
var EventsQueueComponent = /** @class */ (function () {
    function EventsQueueComponent(eventService, profileService, shopifyService, router, csvService, cookie, fb) {
        this.eventService = eventService;
        this.profileService = profileService;
        this.shopifyService = shopifyService;
        this.router = router;
        this.csvService = csvService;
        this.cookie = cookie;
        this.fb = fb;
        this.tableColumns = [];
        this.tableData = [];
        this.resultsData = [];
        this.tableActions = [];
        this.selectActions = [];
        this.selectOptions = { event_status: null };
        this.actionMessages = { event_status: [] };
        this.disabledActions = {};
        this.disableTextWrap = [];
        this.utcDisplayDates = [];
        this.cartAlerts = [];
        this.modalOpen = false;
        this.paginationLinks = {};
        this.paginationMeta = {};
        this.loading = false;
    }
    Object.defineProperty(EventsQueueComponent.prototype, "f", {
        get: function () { return this.eventForm.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventsQueueComponent.prototype, "eventId", {
        get: function () {
            return this.f.event_id;
        },
        enumerable: true,
        configurable: true
    });
    EventsQueueComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tableColumns = [
            {
                label: 'Alert',
                field: 'event_unread_notifications'
            },
            {
                label: 'Type',
                field: 'event_type'
            },
            {
                label: 'Event ID',
                field: 'event_id'
            },
            {
                label: 'Permit ID',
                field: 'event_permit_id'
            },
            {
                label: 'Listing Name',
                field: 'event_name'
            },
            {
                label: 'Start Date',
                field: 'event_start_date'
            },
            {
                label: 'Discipline',
                field: 'event_discipline_name'
            },
            // TODO: Uncomment once add-ons optimized & included again in event queue response (Permitting II)
            // {
            //   label: 'Add-Ons',
            //   field: 'event_add_ons'
            // },
            {
                label: 'Created',
                field: 'event_created_at'
            },
            {
                label: 'Status',
                field: 'event_status'
            }
        ];
        this.eventService.eventQueue$.subscribe(function (data) {
            _this.resultsData = data;
        });
        this.eventForm = this.fb.group({
            event_id: [''],
        });
        this.subscribeToFormChanges();
        this.utcDisplayDates.push('event_start_date');
        this.disableTextWrap.push('id');
        this.loadPaymentAlerts();
        this.loadQueuePage(); // Defaults to page=1
        this.profileService.getCurrentUser().subscribe(function () {
            _this.profileService.getUserPermissions().subscribe(function (permissions) {
                _this.userRole = _this.profileService.setUserRole(permissions);
                if (_this.userRole === 'usac_admin') {
                    _this.tableActions = [
                        {
                            name: 'save',
                            label: 'Save',
                            color: 'dark'
                        }
                    ];
                    _this.selectActions = ['event_status'];
                    _this.selectOptions = { event_status: _this.eventService.queueStatusOptions };
                }
                else {
                    _this.selectOptions = { event_status: null };
                }
            });
        });
        this.alert = [new Alert('info', null, 'No search results found.', 'search', null, null, null, false)];
        this.disableActions();
    };
    EventsQueueComponent.prototype.subscribeToFormChanges = function () {
        var _this = this;
        this.eventId.valueChanges.subscribe(function (val) {
            if (val === '') {
                _this.loadQueuePage();
                return;
            }
            _this.eventService.getEventQueueById(val).subscribe(function (resp) {
                var tableData = [];
                if (resp.length === 0 && val !== '') {
                    tableData = [];
                }
                else {
                    tableData = resp.map(function (row) {
                        if (row.event_discipline) {
                            var findDiscipline = _this.eventService.getDisciplineById(row.event_discipline);
                            if (findDiscipline && findDiscipline.cd_value) {
                                Object.assign(row, {
                                    event_discipline_name: findDiscipline.cd_value
                                });
                            }
                        }
                        row.overview_link = "/event/" + row.event_id + "/overview";
                        return new EventQueueRow(row);
                    });
                }
                _this.eventService.eventQueue$.next(tableData);
            });
        });
    };
    EventsQueueComponent.prototype.loadPaymentAlerts = function () {
        var _this = this;
        // Persistent alert for incomplete required payments not yet purchased.
        this.shopifyService.getShopifyDraftOrders({ permitting: true, incomplete: true }).subscribe(function (drafts) {
            if (drafts.length > 0) {
                var p = _this.plural(drafts);
                var rp = _this.reversePlural(drafts);
                _this.cartAlerts.push(new Alert('warning', "Your recent order" + p + " require" + rp + " payment. Please review event" + p + ": " + _this.unpaidList(drafts) + ".", 'Payment Notice', 'shopping-cart', null, null, null, false));
            }
        });
    };
    EventsQueueComponent.prototype.loadQueuePage = function (page) {
        var _this = this;
        if (page === void 0) { page = 1; }
        this.loading = true;
        this.eventService.getEventsQueue(page).subscribe(function (resp) {
            _this.loading = false;
            var data = resp.data, links = resp.links, meta = resp.meta;
            _this.paginationLinks = links;
            _this.paginationMeta = meta;
            _this.tableData = data.map(function (row) {
                if (row.event_discipline) {
                    var findDiscipline = _this.eventService.getDisciplineById(row.event_discipline);
                    if (findDiscipline && findDiscipline.cd_value) {
                        Object.assign(row, {
                            event_discipline_name: findDiscipline.cd_value
                        });
                    }
                }
                row.overview_link = "/event/" + row.event_id + "/overview";
                return new EventQueueRow(row);
            });
            _this.eventService.eventQueue$.next(_this.tableData);
            _this.resultsData = _this.tableData;
        });
    };
    Object.defineProperty(EventsQueueComponent.prototype, "tableDataResults", {
        get: function () {
            return this.resultsData || [];
        },
        enumerable: true,
        configurable: true
    });
    EventsQueueComponent.prototype.disableActions = function () {
        var _this = this;
        var _loop_1 = function (key) {
            if (this_1.selectOptions.event_status !== null) {
                this_1.resultsData.forEach(function (row) {
                    if (!_this.selectOptions.event_status.some(function (status) { return status.value === row.event_status; })) {
                        _this.disabledActions[key].push(row.event_status);
                    }
                });
            }
        };
        var this_1 = this;
        for (var _i = 0, _a = Object.keys(this.disabledActions); _i < _a.length; _i++) {
            var key = _a[_i];
            _loop_1(key);
        }
    };
    EventsQueueComponent.prototype.onTableAction = function (_a) {
        // console.log('onTableAction with ', action, row, event);
        var action = _a.action, row = _a.row, event = _a.event;
        if (row.change_requests && row.event_status === 'Approved') {
            this.modalAction = action;
            this.modalRow = row;
            this.openModal();
        }
        else {
            this.onSubmit(action, row, event);
        }
    };
    EventsQueueComponent.prototype.onSubmit = function (action, row, event) {
        var _this = this;
        if (action.name === 'save') {
            setTimeout(function () {
                var status = row.event_status;
                _this.eventService.updateEventStatus(row.event_id, status).subscribe(function (resp) {
                    var event_id = row.event_id;
                    var message = resp.message ? 'Status updated' : 'Error occurred';
                    var textClass = resp.message ? 'text-success' : 'text-danger';
                    _this.actionMessages.event_status.push({ event_id: event_id, message: message, textClass: textClass });
                });
            }, 1);
            this.modalOpen = false;
        }
    };
    EventsQueueComponent.prototype.onTableSort = function (_a) {
        var sortBy = _a.sortBy, sortOrder = _a.sortOrder, event = _a.event;
        console.log('onTableSort with ', sortBy, sortOrder);
    };
    EventsQueueComponent.prototype.onSearchLocal = function (_a) {
        var term = _a.term, data = _a.data, event = _a.event;
        this.resultsData = this.tableData;
        this.resultsData = data;
    };
    EventsQueueComponent.prototype.onSearchQuery = function (_a) {
        var term = _a.term, event = _a.event;
        // Logic to add term to API query
    };
    EventsQueueComponent.prototype.toAddNew = function () {
        this.router.navigate(['/event/listing']);
    };
    EventsQueueComponent.prototype.openModal = function () {
        this.modalOpen = true;
    };
    EventsQueueComponent.prototype.closeModal = function () {
        this.modalOpen = false;
    };
    EventsQueueComponent.prototype.csvExport = function () {
        var _this = this;
        this.eventService.getEventsQueueAll().subscribe(function (data) {
            console.log(data);
            _this.csvService.downloadFile(data);
        });
    };
    EventsQueueComponent.prototype.unpaidList = function (drafts) {
        var _this = this;
        var list = [];
        drafts.forEach(function (draft) {
            var eventId = draft.event_id;
            if (!list.includes(_this.listLink(eventId))) {
                list.push(_this.listLink(eventId));
            }
        });
        return list.join(', ');
    };
    EventsQueueComponent.prototype.plural = function (drafts) {
        return drafts.length > 1 ? 's' : '';
    };
    EventsQueueComponent.prototype.reversePlural = function (drafts) {
        return drafts.length > 1 ? '' : 's';
    };
    EventsQueueComponent.prototype.listLink = function (eventId) {
        return "<a href=\"/event/" + eventId + "/overview#payments\">" + eventId + "</a>";
    };
    return EventsQueueComponent;
}());
export { EventsQueueComponent };
