/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./radio-switch-item.component";
var styles_RadioSwitchItemComponent = [];
var RenderType_RadioSwitchItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RadioSwitchItemComponent, data: {} });
export { RenderType_RadioSwitchItemComponent as RenderType_RadioSwitchItemComponent };
export function View_RadioSwitchItemComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "input", [["class", "custom-control-input"], ["type", "radio"]], [[8, "id", 0], [8, "name", 0], [8, "value", 0], [8, "checked", 0], [8, "disabled", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "custom-radio ", _co.inline, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.id, ""); var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.name, ""); var currVal_3 = i0.ɵinlineInterpolate(1, "", _co.value, ""); var currVal_4 = i0.ɵinlineInterpolate(1, "", _co.checked, ""); var currVal_5 = i0.ɵinlineInterpolate(1, "", _co.disabled, ""); _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = i0.ɵinlineInterpolate(1, "", _co.id, ""); _ck(_v, 2, 0, currVal_6); var currVal_7 = _co.label; _ck(_v, 3, 0, currVal_7); }); }
export function View_RadioSwitchItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-radio-switch-item", [], null, null, null, View_RadioSwitchItemComponent_0, RenderType_RadioSwitchItemComponent)), i0.ɵprd(5120, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.RadioSwitchItemComponent]), i0.ɵdid(2, 114688, null, 0, i2.RadioSwitchItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var RadioSwitchItemComponentNgFactory = i0.ɵccf("app-radio-switch-item", i2.RadioSwitchItemComponent, View_RadioSwitchItemComponent_Host_0, { id: "id", name: "name", value: "value", label: "label", disabled: "disabled", inline: "inline", checked: "checked" }, { change: "change", checkedChange: "checkedChange" }, []);
export { RadioSwitchItemComponentNgFactory as RadioSwitchItemComponentNgFactory };
