import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {
  Cart,
  FileUploadService,
  GlobalService,
  LineItem,
  MerchantAccountTag,
  Product,
  ShopifyService
} from '../../shared';

import { EventDayService, EventService } from '../../services';
import {
  Event,
  ShopifyDraftOrder,
  ShopifyDraftOrderStatus,
  ShopifyDiscount,
  ShopifyOrder,
  ShopifyProduct,
  ShopifyProductImage,
  Upload
} from 'src/app/classes';

@Component({
  selector: 'app-cart-shopify',
  templateUrl: './cart-shopify.component.html',
  styleUrls: ['./cart-shopify.component.css']
})

export class CartShopifyComponent implements OnInit {

  @Input() checkoutDisabled: boolean;

  tosModalOpen = false;
  tosCheckoutDisabled = true;
  agreementSigned: boolean;
  cartOpen: boolean;
  product: Product;
  lineItem: LineItem;
  cart: Cart;
  checkout: any;
  eventId: string;
  previousEventId: string;
  series: LineItem;
  freeSeries: LineItem;
  // TODO: Assign separate draft orders for nextgen & proxy sources ?
  draftOrder: ShopifyDraftOrder;
  orders: ShopifyOrder[] = [];
  products: ShopifyProduct[] = [];
  productImages: ShopifyProductImage[] = [];

  nextgenDraftOrderLoaded = false;

  // Wait half a second after changes stop to get draft order and orders
  getDraftOrderAndOrdersDelay = 500;
  getDraftOrderAndOrdersTimeout: any;

  // Wait for 5 seconds after changes stop to get draft order
  getDraftOrderDelay = 5000;
  getDraftOrderTimeout: any;
  maxRetries = 5;

  // Wait for 1 second after changes stop to update draft order
  updateDraftDelay = 1000;
  updateDraftTimeout: any;

  // Wait for 1 second after route events stop to fetch event docs
  eventDocsDelay = 1000;
  eventDocsTimeout: any;


  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private eventDayService: EventDayService,
    private eventService: EventService,
    private globalService: GlobalService,
    private router: Router,
    private shopifyService: ShopifyService,
    private uploadService: FileUploadService,
  ) { }

  ngOnInit() {
    this.globalService.cartOpenCloseObs.subscribe((cartOpenClose: boolean) => this.cartOpen = cartOpenClose);
    this.shopifyService.cartDraftOrder.subscribe((draftOrder: ShopifyDraftOrder) => {
      this.draftOrder = draftOrder;
      if (draftOrder) {
        this.nextgenDraftOrderLoaded = true;
      }
    });
    this.shopifyService.cartProducts.subscribe(products => this.products = products);

    this.router.events.subscribe(routerEvent => {
      if (this.router.url !== '/') {
        const matches = this.router.url && this.router.url.match(/(?:event\/)(\d*)(?=\/)/g);
        this.eventId = (matches && matches.length) ? matches[0].substring(6) : null;
        // Update eventId to clear draft order and load new one
        if (this.eventId !== this.previousEventId) {
          this.draftOrder = null;
          this.nextgenDraftOrderLoaded = false;
        }
        // Checkout only enabled on add-ons view
        if (this.eventId) {
          this.checkoutDisabled = this.router.url !== `/event/${this.eventId}/add-ons`;
          this.checkForSignedAgreement();
        }

        if (routerEvent instanceof NavigationEnd && !this.nextgenDraftOrderLoaded) {
          this.previousEventId = this.eventId;
          if (this.eventId) {
            this.eventService.event$.subscribe((event: Event) => {
              if (event && event.isPermit) {
                this.getOrdersAndDraftOrder();
              }
            });
          }
        }
      }
    });
  }

  private checkForSignedAgreement(): void {
    if (this.eventDocsTimeout) {
      clearTimeout(this.eventDocsTimeout);
    }

    this.eventDocsTimeout = setTimeout(() => {
      this.uploadService.getUploadsForEvent(this.eventId).subscribe((uploads: Upload[]) => {
        this.agreementSigned = uploads.some(upload => upload.file_name === 'pdf_agreement');
      });
    }, this.eventDocsDelay);
  }

  applyCalendarCode(tagsArray?: string[]): Promise<any> {
    return new Promise(
      (resolve, reject) => {
        const calendarCode = localStorage.getItem('calendarCode');

        if (calendarCode) {
          // Find permit fee line item & calculate total from price & quantity
          const permitFees = this.draftOrder.line_items.filter(item => item.sku.includes('PERMIT-FEE-'));
          if (permitFees.length) {
            permitFees.forEach(feeItem => {
              if (!feeItem.applied_discount) {
                // Create discount
                const discountAmnt = parseFloat(feeItem.price) * feeItem.quantity;
                const discount = new ShopifyDiscount({
                  description: 'Permit Fee Calendar Code',
                  value_type: 'percentage',
                  value: 100,
                  amount: discountAmnt,
                  title: calendarCode
                });
                // Append discount to line item
                Object.assign(feeItem, { applied_discount: discount });
              }
            });
            // Update draft order
            const draft = this.draftOrder;
            const draftTags = draft.tags ? draft.tags.split(', ') : null;
            const tags = tagsArray ? tagsArray : draftTags;
            this.shopifyService.updateDraftOrder(draft.id, draft.line_items, tags, draft.applied_discount, draft.note).subscribe(resp => {
              if (resp) {
                localStorage.removeItem('calendarCode');
                return resolve(resp);
              } else {
                return reject();
              }
            });
          } else {
            resolve({});
          }
        } else {
          return resolve({});
        }
      }
    );
  }

  getOrdersAndDraftOrder(): void {
    if (this.getDraftOrderAndOrdersTimeout) {
      clearTimeout(this.getDraftOrderAndOrdersTimeout);
    }

    this.getDraftOrderAndOrdersTimeout = setTimeout(() => {
      this.getDraftOrder();
      this.getOrders();
    }, this.getDraftOrderAndOrdersDelay);
  }

  getDraftOrder(attempt = 1): void {
    if (!this.nextgenDraftOrderLoaded) {
      this.shopifyService.getShopifyDraftOrders({ event_id: this.eventId }).subscribe(draftOrders => {
        // Check for initial event draft order, repeating every 5 seconds if no draft orders found.
        if (draftOrders.length) {
          const openDraftOrders = draftOrders.filter(draftOrder => draftOrder.status === ShopifyDraftOrderStatus.open);
          if (openDraftOrders.length) {
            // If found and open, assign draft order
            this.draftOrder = openDraftOrders[0];
            this.setCartDraftOrder(this.draftOrder);
            this.nextgenDraftOrderLoaded = true;
          } else {
            // If found but completed, create new draft order
            this.createDraftOrder();
          }
          this.shopifyService.getCartProducts();
        } else {
          if (this.getDraftOrderTimeout) {
            clearTimeout(this.getDraftOrderTimeout);
          }

          if (attempt <= this.maxRetries) {
            attempt++;
            this.getDraftOrderTimeout = setTimeout(() => this.getDraftOrder(attempt), this.getDraftOrderDelay);
          } else {
            this.createDraftOrder();
          }
        }
      });
    }
  }

  createDraftOrder(): void {
    this.draftOrder = new ShopifyDraftOrder({tags: `event_id-${this.eventId}`, line_items: []});
    this.setCartDraftOrder(this.draftOrder);
  }

  getOrders() {
    this.shopifyService.getShopifyOrders({ event_id: this.eventId }).subscribe(orders => {
      this.orders = orders;
      this.shopifyService.eventOrders.next(orders);
    });
  }

  setCartDraftOrder(draftOrder: ShopifyDraftOrder) {
    this.shopifyService.updateCartDraftOrder(draftOrder);
  }

  updateOrCreateDraftOrder() {
    const lineItems = [];
    // TODO: @Charlie - What other default tags should we assign here?
    const eventIdTag = `event_id-${this.eventId}`;

    const tags = [eventIdTag, MerchantAccountTag.Permitting];

    // const discountAmnt = '';
    // const discount = new ShopifyDiscount({
    //   description: 'Post Event Admin Discount',
    //   value_type: 'fixed_amount',
    //   value: discountAmnt,
    //   amount: discountAmnt,
    //   title: 'Post Event'
    // });
    const discount = null;
    const note = null;

    if (!!this.draftOrder) {
      const draftTags = this.draftOrder.tags ? this.draftOrder.tags.split(', ') : [];
      if (!draftTags.some(tag => tag === eventIdTag)) {
        draftTags.push(eventIdTag);
      }

      this.applyCalendarCode(draftTags).then(discountData => {
        if (discountData) {
          this.openCheckout(this.draftOrder);
        }
      });
    } else {
      this.shopifyService.createDraftOrder(lineItems, tags, discount, note).subscribe(resp => {
        if (resp) {
          this.applyCalendarCode().then(discountData => {
            if (discountData) {
              this.openCheckout(this.draftOrder);
            }
          });
        }
      });
    }
  }

  removeItem(lineItem) {
    this.shopifyService.removeFromDraftOrder(lineItem, this.draftOrder);
    this.delayedUpdate();
  }

  increaseQuantity(lineItem) {
    this.shopifyService.increaseItemQuantity(lineItem, 1, this.draftOrder);
    this.delayedUpdate();
  }

  decreaseQuantity(lineItem) {
    this.shopifyService.decreaseItemQuantity(lineItem, 1, this.draftOrder);
    this.delayedUpdate();
  }

  delayedUpdate() {
    if (this.updateDraftTimeout) {
      clearTimeout(this.updateDraftTimeout);
    }

    this.updateDraftTimeout = setTimeout(() => {
      // Delete draft order & create empty one if line items empty
      if (!this.lineItems.length) {
        this.shopifyService.deleteDraftOrder(this.draftId).subscribe(resp => {
          if (resp) {
            const draft = new ShopifyDraftOrder({tags: `event_id-${this.eventId}`, line_items: []});
            this.shopifyService.cartDraftOrder.next(draft);
          }
        });
      } else {
        this.shopifyService.updateDraftOrderItems(this.draftOrder);
      }
    }, this.updateDraftDelay);
  }

  handleCheckout(): void {
    if (this.agreementSigned) {
      this.handleCompleteCheckout();
    } else {
      this.openTosModal();
    }
  }

  openTosModal() {
    this.tosModalOpen = true;
  }
  closeTosModal() {
    this.tosModalOpen = false;
  }
  handleTosAccept(data: any) {
    // console.log ('data to save', data);
    this.postEventDays();
    this.tosCheckoutDisabled = false;
  }
  handleCompleteCheckout(data?: any) {
    this.loading.emit(true);
    this.closeTosModal();
    this.updateOrCreateDraftOrder();
  }

  saveCheckoutDataLocally() {
    const { draftId, lineItems } = this;

    localStorage.setItem('checkout', JSON.stringify({ draftId, lineItems }));
  }
  postEventDays() {
    if (this.eventId) { this.eventDayService.addEventDays(this.eventId).subscribe(); }
  }

  openCheckout(draftOrder: ShopifyDraftOrder) {
    this.saveCheckoutDataLocally();
    this.loading.emit(false);
    window.location.assign(draftOrder.invoice_url);
  }

  closeCart() {
    this.globalService.cartToggle();
  }

  get draftId() {
    return this.draftOrder.id;
  }

  get lineItems() {
    return this.draftOrder ? this.draftOrder.line_items : [];
  }

  get completeLineItems() {
    return (this.lineItems && this.lineItems.length) ? this.lineItems.filter(item => !!item.title) : [];
  }

  get cartUpdating(): boolean {
    return this.shopifyService.cartUpdating;
  }

  imageSrc(lineItem): string {
    const productImage = this.productImages.find(image => image.product_id === lineItem.product_id);
    return productImage ? productImage.src : '';
  }

  disableCartQuantity(lineItem): boolean {
    const product = this.products.find(p => p.id === lineItem.product_id);
    return (product && product.tags) ? product.tags.includes('disable-cart-quantity') : false;
  }

  disableCartRemoval(lineItem): boolean {
    const product = this.products.find(p => p.id === lineItem.product_id);
    return (product && product.tags) ? product.tags.includes('disable-cart-removal') : false;
  }
}
