import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ShopifyService, GlobalService } from '../../shared';
import { EventDayService } from 'src/app/services';
import {
  SelectOption, RadioOption, Alert, EventDay, ShopifyDraftOrder, ShopifyDraftOrderStatus, ShopifyProduct,
  ShopifyLineItemBase, ShopifyProductVariant, ShopifyProductImage, ShopifyOrder, EventDate
} from 'src/app/classes';
import { CustomDateFormatter } from 'src/app/providers/dateFormatter';

import { environment } from 'src/environments/environment';

// var observableFromPromise =  from(promiseSrc);

@Component ({
  selector: 'app-product',
  templateUrl: './product.component.html',
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class ProductComponent implements OnInit {

  @Input() product: ShopifyProduct;
  @Input() productImage: ShopifyProductImage;
  @Input() variants: ShopifyProductVariant[];
  @Input() draftOrder: ShopifyDraftOrder;
  @Input() discipline: number;
  @Input() eventDates: EventDate[];

  @Output() init = new EventEmitter<number>();
  @Output() load = new EventEmitter<number>();

  draftOrders: ShopifyDraftOrder[] = [];
  orders: ShopifyOrder[] = [];

  modal1Open = false;
  modal2Open = false;
  submitted = false;
  dataForm: any = null;
  dateFormCreated = false;
  addToCartDisabled = true;
  selectDatesDisabled = true;
  radioOptions: RadioOption[] = [];
  setupOptions: SelectOption[] = [];
  minDate: NgbDate;
  parsedEventDates: NgbDateStruct[] = [];
  markDisabled: (date: NgbDate) => boolean;
  selectedDates: string[] = [];
  cartAlerts: Alert[] = [];

  defaultVariant: ShopifyProductVariant;
  variantList: ShopifyProductVariant[] = [];

  defaultSku: string;
  autoSku: string;
  motoSku: string;
  ancillarySku: string;
  setupTeardownSku: string;
  practiceSku: string;
  kidsPermitSku: string;

  skus: any;

  loaded = false;

  form = new FormGroup({
    variant: new FormControl([]),
    quantity: new FormControl(0),
    radio: new FormControl(null),
    setupTeardownDays: new FormGroup({}),
    practiceDays: new FormGroup({})
  });

  constructor(
    private shopifyService: ShopifyService,
    private globalService: GlobalService,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    private dateParser: NgbDateParserFormatter,
    private eventDayService: EventDayService
  ) { }

  // convenience getters for easy access to form fields
  get f() { return this.form.controls; }
  get stdf() { return this.form.controls.setupTeardownDays; }
  get pdf() { return this.form.controls.practiceDays; }

  ngOnInit() {
    this.init.emit(1);
    this.subscribeToDraftsAndOrders();

    this.skus = environment.disciplineSkus[this.discipline];
    this.autoSku = this.skus.autoInsurance;
    this.motoSku = this.skus.motoInsurance;
    this.ancillarySku = this.skus.ancillaryInsurance;
    this.setupTeardownSku = this.skus.setupTeardownDays;
    this.practiceSku = this.skus.practiceDays;
    this.kidsPermitSku = this.skus.kidsPermit;

    // TODO: @Brennan - How is "this.variants" ever undefined here?
    //        - Related: https://usacycling.atlassian.net/browse/AIR-837
    this.variants.forEach(variant => {
      if (Object.values(this.skus).some(sku => sku === variant.sku)) {
        this.variantList.push(variant);
        if (!this.defaultVariant) {
          this.defaultVariant = variant;
          this.defaultSku = variant.sku;
        } else {
          return;
        }
      }
    });
    this.f.variant.setValue(this.defaultVariant);
    if (this.defaultSku === this.kidsPermitSku) { this.form.get('radio').setValue('no'); }
    this.loaded = true;
    this.load.emit(1);

    if (this.disableQuantity) {
      this.addToCartDisabled = false;
      this.form.controls.quantity.setValue(1);
    }

    this.radioOptions = [
      new RadioOption('null', null, 'Null'),
      new RadioOption('yes', 'yes', 'Yes'),
      new RadioOption('no', 'no', 'No')
    ];

    this.setupOptions = [
      new SelectOption('setup', 'Setup'),
      new SelectOption('teardown', 'Teardown')
    ];

    this.minDate = this.calendar.getToday();
    this.parsedEventDates = this.eventDates.map(date => this.dateParser.parse(moment(date.event_date_start).format('M/D/Y')));

    this.markDisabled = (date: NgbDate) => {
      let disabled = false;
      this.parsedEventDates.forEach(d => {
        if (date.day === d.day && date.month === d.month && date.year === d.year) {
          disabled = true;
        }
      });
      return disabled;
    };
  }

  subscribeToDraftsAndOrders() {
    this.shopifyService.eventDraftOrders.subscribe(draftOrders => {
      this.draftOrders = draftOrders.filter(draftOrder => draftOrder.status === ShopifyDraftOrderStatus.open);
    });
    this.shopifyService.eventOrders.subscribe(orders => this.orders = orders);
  }

  addToCart() {
    const lineItem: ShopifyLineItemBase = { variant_id: this.form.value.variant.id, quantity: this.form.value.quantity };
    this.shopifyService.addToDraftOrder(lineItem, this.draftOrder);
    this.shopifyService.updateDraftOrderItems(this.draftOrder);
  }

  removeFromCart() {
    const lineItem: ShopifyLineItemBase = { variant_id: this.form.value.variant.id, quantity: 1 };
    this.shopifyService.removeFromDraftOrder(lineItem, this.draftOrder);
    this.shopifyService.updateDraftOrderItems(this.draftOrder);
  }

  resetCartAlerts() {
    this.cartAlerts = [];
  }

  openModal(num) {
    switch (num) {
      case 1: this.modal1Open = true; break;
      case 2: this.modal2Open = true;
    }
  }

  closeModal(num) {
    switch (num) {
      case 1: this.modal1Open = false; break;
      case 2: this.modal2Open = false;
    }
  }

  onRadioChange() {
    if (this.defaultSku === this.ancillarySku) {
      if (this.form.value.radio === 'no') {
        this.onSubmit();
        this.openModal(1);
      } else if (this.form.value.radio === 'yes') {
        this.removeFromCart();
        this.openModal(2);
      }
    // Default radio behavior - 'yes' adds to cart
    } else {
      if (this.form.value.radio === 'yes') {
        this.onSubmit();
      } else if (this.form.value.radio === 'no') {
        this.removeFromCart();
      }
    }
  }

  maxQuantity() {
    return (this.defaultSku === this.setupTeardownSku || this.defaultSku === this.practiceSku ) ? 50 : 500;
  }

  createForm(quantity, previousValues = {}) {
    const formDefaults = {};
    this.dateFormCreated = false;
    this.selectedDates = [];

    if (this.defaultSku === this.setupTeardownSku) {

      for (let i = 1; i <= quantity; i++) {
        // TODO: Add custom validators to ensure setup dates before event & teardown dates after
        formDefaults[`date-${i}`] = [ previousValues[`date-${i}`] || null, Validators.required];
        formDefaults[`date-${i}-type`] = [ previousValues[`date-${i}-type`] || 'setup', Validators.required];
        this.selectedDates.push(`date-${i}`);
      }
      this.form.controls.setupTeardownDays = this.formBuilder.group(formDefaults);
      this.dateFormCreated = true;
      this.dataForm = this.stdf;
      this.checkFormValidity(this.stdf);
    } else if (this.defaultSku === this.practiceSku) {
      for (let i = 1; i <= quantity; i++) {
        // TODO: Add custom validator to ensure practice dates before event
        formDefaults[`date-${i}`] = [ previousValues[`date-${i}`] || null, Validators.required];
        this.selectedDates.push(`date-${i}`);
      }
      this.form.controls.practiceDays = this.formBuilder.group(formDefaults);
      this.dateFormCreated = true;
      this.dataForm = this.pdf;
      this.checkFormValidity(this.pdf);
    }
  }

  onSelectDates() {
    this.submitted = false;
    let currentFormValue = {};
    if (this.defaultSku === this.setupTeardownSku) {
      currentFormValue = this.stdf.value;
    } else if (this.defaultSku === this.practiceSku) {
      currentFormValue = this.pdf.value;
    }
    // TODO: Find better way to handle changes to number of dates selected
    this.createForm(this.product, 0);
    setTimeout(() => {this.createForm(this.form.value.quantity, currentFormValue); }, 1);
  }

  onQuantityChange() {
    this.selectDatesDisabled = this.form.value.quantity === 0 ? true : false;
    if (!this.setupPracticeSku) {
      this.addToCartDisabled = this.form.value.quantity === 0 ? true : false;
    }
  }

  checkFormValidity(form) {
    setTimeout(() => { this.addToCartDisabled = !form.valid; }, 5);
  }

  postData(form) {
    if (this.defaultSku === this.practiceSku || this.defaultSku === this.setupTeardownSku) {
      const f = form.controls;
      const days: EventDay[] = [];
      if (this.defaultSku === this.setupTeardownSku) {
        for (let i = 1; i <= (Object.keys(f).length / 2); i++ ) {
          days.push(new EventDay({ date: f[`date-${i}`].value, type: f[`date-${i}-type`].value }));
        }
        this.eventDayService.updateSelectedDays('setupTeardown', days);
      } else {
        for (const key of Object.keys(f)) {
          days.push(new EventDay({ date: f[key].value, type: 'practice' }));
        }
        this.eventDayService.updateSelectedDays('practice', days);
      }
    }
  }

  onSubmit() {
    this.submitted = true;
    console.log('Submitted', this.form);
    this.postData(this.dataForm);

    // const lineItem = new LineItem(this.form.value.variant, this.form.value.quantity, this.product);
    this.addToCart();
    if (this.hasDocs) {
      this.openModal(1);
    }

    this.addToCartDisabled = !this.disableQuantity;
    this.resetCartAlerts();
    this.cartAlerts.push(
      new Alert('success', `${this.form.value.quantity} item(s) added to cart.`, null, 'shopping-cart', null, null, 5000)
    );
    this.globalService.cartOpen();
  }

  get setupPracticeSku(): boolean {
    return this.defaultSku && this.defaultSku.includes('DAY');
  }

  get disableQuantity(): boolean {
    return this.product.tags.includes('disable-quantity');
  }

  get hasDocs(): boolean {
    return this.product.tags.includes('has-docs');
  }

  get hasRadioOptions(): boolean {
    return this.product.tags.includes('radio-options');
  }

  get requiresPrepurchaseInfo(): boolean {
    return this.product.tags.includes('requires-pre-purchase-info');
  }

  get disableRepurchase(): boolean {
    return this.product.tags.includes('one-time-purchase') && (
      this.inLineItems(this.draftOrder.line_items) ||
      this.orders.some(order => this.inLineItems(order.line_items)) ||
      this.draftOrders.some(draft => this.inLineItems(draft.line_items))
    );
  }

  inLineItems(lineItems: any[]): boolean {
    return lineItems.some(item => item.title === this.product.title);
  }
}
