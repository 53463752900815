import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';

import { SelectOption, Notification, Event } from '../../classes';
import { EventListingService, NotificationService } from '../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-overview-notifications',
  templateUrl: './overview-notifications.component.html',
  styles: []
})
export class OverviewNotificationsComponent implements OnInit {

  notifications: Notification[] = [];
  formItems: object = {};
  notificationForm: FormGroup;
  submitted = false;
  loading = true;
  disabled = true;
  denyRequest = false;
  submitModalOpen = false;
  statusOptions: SelectOption[] = [];

  @Input() event: Event;

  constructor(
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    public eventListing: EventListingService,
    private router: Router
  ) { }

  // convenience getter for easy access to form fields
  get f() { return this.notificationForm.controls; }

  ngOnInit() {
    this.notificationService.getNotificationsForEvent(this.event.event_id).subscribe(notifications => {
      this.notifications = this.notificationService.sortNotifications(notifications, this.event.event_id);
      if (this.notifications.length) {
        this.createForm();
      } else {
        this.loading = false;
      }
    });
  }

  createForm() {
    this.notifications.forEach( n => this.formItems['notification-' + n.formControl] = false);

    this.notificationForm = this.formBuilder.group(this.formItems);

    this.statusOptions = [
      new SelectOption('pending', 'Pending Approval'),
      new SelectOption('denied', 'Denied'),
      new SelectOption('approved', 'Approved')
    ];

    this.loading = false;
  }

  onChange() {
    let trueValues = 0;
    for (const key in this.f) {
      if (this.f.hasOwnProperty(key)) {
        trueValues += this.f[key].value ? 1 : 0;
      }
    }
    this.disabled = trueValues > 0 ? false : true;
  }

  onActionClick(actionUrl: string) {
    // this.router.navigate([`event/${this.event.event_id}/overview`], {fragment: actionUrl});
    window.location.replace(`event/${this.event.event_id}/overview#${actionUrl}`);
    window.location.reload();
  }

  onSubmit(action) {
    if (!this.disabled) {
      this.submitted = true;

      console.log(`submitting form for ${action}`, this.f);

      // stop here if form is invalid
      if (this.notificationForm.invalid) {
        return;
      }

      if (action === 'read') {
        this.notifications.forEach( n => {
          const control = 'notification-' + n.formControl;
          if (this.f[control].value === true) {
            this.notificationService.readNotification(n.type, n.id).subscribe(() => {
              n.read = true;
            });
          }
        });
      } else if (action === 'delete') {
        const toDelete = [];
        this.notifications.forEach( n => {
          const id = 'notification-' + n.id;
          if (this.f[id].value === true) {
            toDelete.push(n.id);
            console.log('Deleted ' + id);
          }
        });
        this.notifications = this.notifications.filter( n => !toDelete.includes(n.id));
      }

      // display form values on success
      // alert(`SUCCESS!! - ${action} :-)\n\n` + JSON.stringify(this.notificationForm.value, null, 4));

      this.notificationForm.reset();
      this.disabled = true;
    }
  }

  denyChangeRequest() {
    this.denyRequest = !this.denyRequest;
  }

  sendReason() {
    this.submitModalOpen = false;
  }

  viewRequest() {
    this.eventListing.mode = 'view';
  //  this.router.navigate(['/event/review']);
  //  this.submitModalOpen = true;
  }

  closeRequest() {
    this.submitModalOpen = false;
  }

  approveChanges() {
    this.submitModalOpen = false;
  }

  onSelect(event) {

  }

}
