/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../atoms/loader/loader.component.ngfactory";
import * as i2 from "../../atoms/loader/loader.component";
import * as i3 from "../../organisms/event-incentives-form/event-incentives-form.component.ngfactory";
import * as i4 from "../../organisms/event-incentives-form/event-incentives-form.component";
import * as i5 from "@angular/forms";
import * as i6 from "../../services/permit.service";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common";
import * as i9 from "./event-incentives.component";
import * as i10 from "../../services/events.service";
import * as i11 from "../../services/event-listing.service";
var styles_EventIncentivesComponent = [];
var RenderType_EventIncentivesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EventIncentivesComponent, data: {} });
export { RenderType_EventIncentivesComponent as RenderType_EventIncentivesComponent };
function View_EventIncentivesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_EventIncentivesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-event-incentives-form", [], null, null, null, i3.View_EventIncentivesFormComponent_0, i3.RenderType_EventIncentivesFormComponent)), i0.ɵdid(1, 245760, null, 0, i4.EventIncentivesFormComponent, [i5.FormBuilder, i6.PermitService, i7.Router], { event: [0, "event"], eventId: [1, "eventId"], calendarLabels: [2, "calendarLabels"], calendarLabelQuestions: [3, "calendarLabelQuestions"], selectedLabelIds: [4, "selectedLabelIds"], answers: [5, "answers"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event; var currVal_1 = _co.eventId; var currVal_2 = _co.calendarLabels; var currVal_3 = _co.calendarLabelQuestions; var currVal_4 = _co.selectedLabelIds; var currVal_5 = _co.calendarLabelAnswers; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_EventIncentivesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "main-container col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Event Calendar Labels"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventIncentivesComponent_1)), i0.ɵdid(6, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventIncentivesComponent_2)), i0.ɵdid(8, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loaded; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.loaded; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_EventIncentivesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-event-incentives", [], null, null, null, View_EventIncentivesComponent_0, RenderType_EventIncentivesComponent)), i0.ɵdid(1, 114688, null, 0, i9.EventIncentivesComponent, [i7.Router, i7.ActivatedRoute, i10.EventService, i11.EventListingService, i6.PermitService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventIncentivesComponentNgFactory = i0.ɵccf("app-event-incentives", i9.EventIncentivesComponent, View_EventIncentivesComponent_Host_0, {}, {}, []);
export { EventIncentivesComponentNgFactory as EventIncentivesComponentNgFactory };
