import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { EventService } from 'src/app/services';
import { Event } from 'src/app/classes';

@Component({
  selector: 'app-close-cancel-event',
  templateUrl: './close-cancel-event.component.html',
  styles: []
})
export class CloseCancelEventComponent implements OnInit {

  @Input() event: Event;
  @Input() mode: string;
  @Input() open = false;
  @Input() outstandingPaperwork = false;
  @Input() outstandingPayments = false;
  @Input() incompleteForms = false;

  @Output() exit = new EventEmitter<any>();

  listing: boolean;
  permit: boolean;
  closed = false;
  cancelled = false;
  timer = 5;
  sec = 's';
  disableBtns = false;
  statusUpdated = false;
  showError = false;

  closeEventForm: FormGroup;
  submitted = false;

  get f() { return this.closeEventForm.controls; }

  constructor(
    private eventService: EventService,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.displayText();
    if (this.closeEvent) { this.buildForm(); }
  }

  buildForm() {
    this.closeEventForm = this.formBuilder.group({
      event_closed_without_paperwork: [this.event.event_closed_without_paperwork || '', Validators.requiredTrue],
      event_closed_comments: [this.event.event_closed_comments || '', Validators.required]
    });
  }

  get closeEvent(): boolean {
    return this.mode === 'close';
  }

  get cancelEvent(): boolean {
    return this.mode === 'cancel';
  }

  get outstandingItems(): string {
    const items = [];
    if (this.outstandingPayments) { items.push('unpaid orders'); }
    if (this.incompleteForms) { items.push('incomplete post event forms'); }
    if (this.outstandingPaperwork) { items.push('post event paperwork that has not been marked as completed'); }
    switch (items.length) {
      case 3:
        items.splice(1, 0, ', ');
        items.splice(3, 0, ', and ');
        break;
      case 2:
        items.splice(1, 0, ', as well as ');
    }
    return items.join('');
  }

  get disableCloseBtn(): boolean {
    return this.closeEventForm.invalid;
  }

  openModal() {
    this.open = true;
  }

  exitCloseModal() {
    // Notify parent (event overview) of exit if status not changed to "closed", to set status form back to previous value.
    this.exit.emit({ updated: this.statusUpdated });
  }

  exitCancelModal() {
    this.open = false;
  }

  displayText() {
    this.listing = this.event.event_permit_id === null;
    this.permit = this.event.event_permit_id !== null;
  }

  onSubmit() {
    this.submitted = true;
    this.showError = false;

    // stop here if form is invalid
    if (this.closeEventForm.invalid) {
      return;
    }

    this.updateStatus('closed', this.closeEventForm.value);
  }

  updateStatus(status: string, closeFields = {}) {
    this.disableBtns = true;
    this.eventService.updateEventStatus(this.event.event_id, status, closeFields).subscribe((resp) => {
      if (resp.message) {
        this.statusUpdated = true;
        if (this.closeEvent) {
          this.closed = true;
          this.toQueue();
        } else if (this.cancelEvent) {
          this.cancelled = true;
          this.toQueue();
        }
      } else {
        this.showError = true;
        this.disableBtns = false;
      }
    });
  }

  toQueue() {
    this.countdown();
    setTimeout(() => this.router.navigate(['/queue']), 5000);
  }

  countdown() {
    setInterval(() => {
      this.timer = this.timer -= 1;
      this.sec = this.timer > 1 ? 's' : '';
    }, 1000);
  }

}
