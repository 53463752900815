/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../atoms/loader/loader.component.ngfactory";
import * as i2 from "../../atoms/loader/loader.component";
import * as i3 from "../../atoms/button/button.component.ngfactory";
import * as i4 from "../../atoms/button/button.component";
import * as i5 from "@angular/common";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "../post-event-summary/post-event-summary.component.ngfactory";
import * as i8 from "../post-event-summary/post-event-summary.component";
import * as i9 from "../../services/post-event.service";
import * as i10 from "../../shared/services/shopify.service";
import * as i11 from "../../services/event-listing.service";
import * as i12 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i13 from "../../molecules/modal/modal.component.ngfactory";
import * as i14 from "../../molecules/modal/modal.component";
import * as i15 from "../../atoms/modal-body/modal-body.component.ngfactory";
import * as i16 from "../../atoms/modal-body/modal-body.component";
import * as i17 from "../../atoms/modal-footer/modal-footer.component.ngfactory";
import * as i18 from "../../atoms/modal-footer/modal-footer.component";
import * as i19 from "./overview-post-event.component";
import * as i20 from "@angular/router";
import * as i21 from "../../services/events.service";
var styles_OverviewPostEventComponent = [];
var RenderType_OverviewPostEventComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OverviewPostEventComponent, data: {} });
export { RenderType_OverviewPostEventComponent as RenderType_OverviewPostEventComponent };
function View_OverviewPostEventComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], { small: [0, "small"], align: [1, "align"] }, null)], function (_ck, _v) { var currVal_0 = true; var currVal_1 = "left"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_OverviewPostEventComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "card light border-1 mt-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "row header-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h4", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Post event period has not started"])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "h5", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["Forms will be available on ", ""])), i0.ɵppd(7, 3)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v.parent, 0), _co.postEventStart, "longDate", "UTC")); _ck(_v, 6, 0, currVal_0); }); }
function View_OverviewPostEventComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["Due: ", ""])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent.parent, 1), _v.parent.parent.context.$implicit.totalDue)); _ck(_v, 1, 0, currVal_0); }); }
function View_OverviewPostEventComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Due: Pending Completion"]))], null, null); }
function View_OverviewPostEventComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-button", [["title", "Edit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toPostEventForm(_v.parent.parent.context.$implicit.url) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(1, 114688, null, 0, i4.ButtonComponent, [], { type: [0, "type"], mode: [1, "mode"], disabled: [2, "disabled"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 0, "i", [["class", "icon icon-edit"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn-link p-0"; var currVal_1 = "button"; var currVal_2 = _co.disableEdit(_v.parent.parent.context.$implicit.postEvent); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_OverviewPostEventComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-button", [["title", "Delete"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDeleteModal(_v.parent.parent.context.$implicit.postEvent) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(1, 114688, null, 0, i4.ButtonComponent, [], { type: [0, "type"], mode: [1, "mode"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 0, "i", [["class", "icon icon-trash text-primary"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "btn-link p-0"; var currVal_1 = "button"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_OverviewPostEventComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 23, "div", [["class", "row header-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h4", [["class", "col-12 col-sm-6 col-md-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵppd(3, 3), (_l()(), i0.ɵeld(4, 0, null, null, 4, "p", [["class", "col-12 col-sm-6 col-md-3"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverviewPostEventComponent_6)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverviewPostEventComponent_7)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 3, "p", [["class", "col-12 col-sm-6 col-md-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(11, null, ["", ""])), i0.ɵppd(12, 1), (_l()(), i0.ɵeld(13, 0, null, null, 10, "div", [["class", "col-12 col-sm-6 col-md-3 row justify-content-end align-items-center m-0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 2, "app-button", [["title", "Pay"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toCheckout(_v.parent.context.$implicit.draftOrder) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(15, 114688, null, 0, i4.ButtonComponent, [], { type: [0, "type"], mode: [1, "mode"], disabled: [2, "disabled"] }, null), (_l()(), i0.ɵeld(16, 0, null, 0, 0, "i", [["class", "icon icon-dollar"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverviewPostEventComponent_8)), i0.ɵdid(18, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverviewPostEventComponent_9)), i0.ɵdid(20, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(21, 0, null, null, 2, "button", [["class", "text-right btn icon-btn padding-right-zero"], ["ngbPanelToggle", ""], ["type", "button"]], [[8, "id", 0], [8, "disabled", 0], [2, "collapsed", null], [1, "aria-expanded", 0], [1, "aria-controls", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 22).accordion.toggle(i0.ɵnov(_v, 22).panel.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(22, 16384, null, 0, i6.NgbPanelToggle, [i6.NgbAccordion, [2, i6.NgbPanel]], { ngbPanelToggle: [0, "ngbPanelToggle"] }, null), (_l()(), i0.ɵeld(23, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.displayDue(_v.parent.context.$implicit.postEvent); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.formDue(_v.parent.context.$implicit.postEvent); _ck(_v, 8, 0, currVal_2); var currVal_5 = "btn-link p-0"; var currVal_6 = "button"; var currVal_7 = _co.disablePay(_v.parent.context.$implicit.postEvent); _ck(_v, 15, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.allowEdit(); _ck(_v, 18, 0, currVal_8); var currVal_9 = _co.allowDelete(_v.parent.context.$implicit.postEvent); _ck(_v, 20, 0, currVal_9); var currVal_15 = ""; _ck(_v, 22, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.text, "shortDate", "UTC")); _ck(_v, 2, 0, currVal_0); var currVal_3 = i0.ɵinlineInterpolate(1, "", _co.badgeClass(_v.parent.context.$implicit.postEvent), ""); _ck(_v, 10, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i0.ɵnov(_v.parent.parent.parent, 2), _co.status(_v.parent.context.$implicit.postEvent))); _ck(_v, 11, 0, currVal_4); var currVal_10 = i0.ɵinlineInterpolate(1, "post-event-", (_v.parent.context.index + 1), "-toggle"); var currVal_11 = i0.ɵnov(_v, 22).panel.disabled; var currVal_12 = !i0.ɵnov(_v, 22).panel.isOpen; var currVal_13 = i0.ɵnov(_v, 22).panel.isOpen; var currVal_14 = i0.ɵnov(_v, 22).panel.id; _ck(_v, 21, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_16 = _co.toggleIcon(_v.parent.context.index); _ck(_v, 23, 0, currVal_16); }); }
function View_OverviewPostEventComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-post-event-summary", [], null, null, null, i7.View_PostEventSummaryComponent_0, i7.RenderType_PostEventSummaryComponent)), i0.ɵdid(1, 114688, null, 0, i8.PostEventSummaryComponent, [i9.PostEventService, i10.ShopifyService, i11.EventListingService], { postEvent: [0, "postEvent"], event: [1, "event"], eventDate: [2, "eventDate"], eventDates: [3, "eventDates"], isSeries: [4, "isSeries"], draftOrder: [5, "draftOrder"], orders: [6, "orders"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.postEvent; var currVal_1 = _co.event; var currVal_2 = _v.parent.context.$implicit.eventDate; var currVal_3 = _co.eventDates; var currVal_4 = _co.isSeries; var currVal_5 = _v.parent.context.$implicit.draftOrder; var currVal_6 = _v.parent.context.$implicit.orders; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_OverviewPostEventComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "ngb-panel", [], null, null, null, null, null)), i0.ɵdid(1, 2113536, [[1, 4]], 3, i6.NgbPanel, [], { disabled: [0, "disabled"], id: [1, "id"] }, null), i0.ɵqud(603979776, 2, { titleTpls: 1 }), i0.ɵqud(603979776, 3, { headerTpls: 1 }), i0.ɵqud(603979776, 4, { contentTpls: 1 }), (_l()(), i0.ɵand(0, null, null, 1, null, View_OverviewPostEventComponent_5)), i0.ɵdid(6, 16384, [[3, 4]], 0, i6.NgbPanelHeader, [i0.TemplateRef], null, null), (_l()(), i0.ɵand(0, null, null, 1, null, View_OverviewPostEventComponent_10)), i0.ɵdid(8, 16384, [[4, 4]], 0, i6.NgbPanelContent, [i0.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hasPostEvent(_v.context.$implicit); var currVal_1 = i0.ɵinlineInterpolate(1, "post-event-", (_v.context.index + 1), ""); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_OverviewPostEventComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "post-event-summary"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "ngb-accordion", [["activeIds", "post-event-1"], ["class", "post-event-accordion accordion"], ["role", "tablist"]], [[1, "aria-multiselectable", 0]], null, null, i12.View_NgbAccordion_0, i12.RenderType_NgbAccordion)), i0.ɵdid(2, 2146304, null, 1, i6.NgbAccordion, [i6.NgbAccordionConfig], { activeIds: [0, "activeIds"], closeOtherPanels: [1, "closeOtherPanels"] }, null), i0.ɵqud(603979776, 1, { panels: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverviewPostEventComponent_4)), i0.ɵdid(5, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "post-event-1"; var currVal_2 = true; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.postEventPanels; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = !i0.ɵnov(_v, 2).closeOtherPanels; _ck(_v, 1, 0, currVal_0); }); }
function View_OverviewPostEventComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "spinner-border spinner-border-sm ml-1"]], null, null, null, null, null))], null, null); }
export function View_OverviewPostEventComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i5.DatePipe, [i0.LOCALE_ID]), i0.ɵpid(0, i5.CurrencyPipe, [i0.LOCALE_ID]), i0.ɵpid(0, i5.TitleCasePipe, []), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Post Event Form"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [["class", "mt-3 small font-weight-bold"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" USA Cycling has established a minimum insurance payment for all events. This minimum will apply to events with a rider count lower than the count required to meet the minimum total of $300 for the liability insurance. Anyone who has a rider count that totals out to more than $300 will simply pay the per rider fee upon checkout.\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverviewPostEventComponent_1)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverviewPostEventComponent_2)), i0.ɵdid(10, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverviewPostEventComponent_3)), i0.ɵdid(12, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 15, "app-modal", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeDeleteModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_ModalComponent_0, i13.RenderType_ModalComponent)), i0.ɵdid(14, 638976, null, 0, i14.ModalComponent, [i6.NgbModal], { id: [0, "id"], title: [1, "title"], isOpen: [2, "isOpen"] }, { close: "close" }), (_l()(), i0.ɵeld(15, 0, null, 0, 3, "app-modal-body", [], null, null, null, i15.View_ModalBodyComponent_0, i15.RenderType_ModalBodyComponent)), i0.ɵdid(16, 49152, null, 0, i16.ModalBodyComponent, [], null, null), (_l()(), i0.ɵeld(17, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Are you sure you want to delete this Post Event Form? This cannot be reversed and will remove any data already entered, as well as any open draft orders for the form. "])), (_l()(), i0.ɵeld(19, 0, null, 0, 9, "app-modal-footer", [], null, null, null, i17.View_ModalFooterComponent_0, i17.RenderType_ModalFooterComponent)), i0.ɵdid(20, 49152, null, 0, i18.ModalFooterComponent, [], { class: [0, "class"] }, null), (_l()(), i0.ɵeld(21, 0, null, 0, 2, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDeleteModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(22, 114688, null, 0, i4.ButtonComponent, [], { type: [0, "type"], disabled: [1, "disabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["No, Exit"])), (_l()(), i0.ɵeld(24, 0, null, 0, 4, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deletePostEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(25, 114688, null, 0, i4.ButtonComponent, [], { type: [0, "type"], disabled: [1, "disabled"] }, null), (_l()(), i0.ɵted(-1, 0, [" Yes, Delete "])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_OverviewPostEventComponent_11)), i0.ɵdid(28, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 8, 0, currVal_0); var currVal_1 = (!_co.inPostEvent && !_co.loading); _ck(_v, 10, 0, currVal_1); var currVal_2 = (_co.inPostEvent && !_co.loading); _ck(_v, 12, 0, currVal_2); var currVal_3 = "delete-modal"; var currVal_4 = "Delete Post Event Form"; var currVal_5 = _co.deleteModalOpen; _ck(_v, 14, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = "justify-content-between"; _ck(_v, 20, 0, currVal_6); var currVal_7 = "btn-light"; var currVal_8 = _co.deleting; _ck(_v, 22, 0, currVal_7, currVal_8); var currVal_9 = "btn-primary"; var currVal_10 = _co.deleting; _ck(_v, 25, 0, currVal_9, currVal_10); var currVal_11 = _co.deleting; _ck(_v, 28, 0, currVal_11); }, null); }
export function View_OverviewPostEventComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-overview-post-event", [], null, null, null, View_OverviewPostEventComponent_0, RenderType_OverviewPostEventComponent)), i0.ɵdid(1, 573440, null, 0, i19.OverviewPostEventComponent, [i20.Router, i21.EventService, i9.PostEventService], null, null)], null, null); }
var OverviewPostEventComponentNgFactory = i0.ɵccf("app-overview-post-event", i19.OverviewPostEventComponent, View_OverviewPostEventComponent_Host_0, { event: "event", eventDates: "eventDates", postEvents: "postEvents", draftOrders: "draftOrders", orders: "orders", userRole: "userRole", currentUser: "currentUser" }, { refreshPostEvents: "refreshPostEvents" }, []);
export { OverviewPostEventComponentNgFactory as OverviewPostEventComponentNgFactory };
