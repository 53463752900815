<h4>Permit Add-Ons</h4>

<!-- Loader -->
<app-loader *ngIf="!loaded" [small]="true" [align]="'left'"></app-loader>

<!-- Empty State -->
<div *ngIf="loaded && !addOns.length" class="row">
  <p class="col-12">Upon payment of add-ons you will see additional information here.</p>
</div>

<div *ngIf="loaded && addOns.length > 0" class="mt-3">
  <div class="row" style="align-items: baseline;">
    <h5 class="col-sm-5 col-md-4 col-lg-3 col-xl-2">Purchased</h5>
  </div>
  <div class="row add-on-header">
    <div class="col-md-5 col-lg-3">Product</div>
    <div class="col-md-1 col-lg-1 text-center">Quantity</div>
    <div class="col-md-3 col-lg-2 text-center">Date Purchased</div>
    <!-- <p *ngIf="allowManage" class="col-md-2 col-lg-4">Status</p> -->
    <!-- <p class="col-md-2 col-lg-4">Notes</p> -->
  </div>
  <ul class="add-on-list notification-list">
    <li *ngFor="let addOn of addOns">
      <div class="row">
        <div class="col-sm-2 col-md-2 col-lg-1">
          <img class="icon" [src]="addOn.src">
        </div>
        <div class="col-6 col-sm-3 col-md-3 col-lg-2 notification-type">{{addOn.title}}</div>
        <div class="col-2 col-sm-2 col-md-1 col-lg-1 text-center">{{addOn.quantity}}</div>
        <div class="col-4 col-sm-2 col-md-3 col-lg-2 text-center">{{addOn.date_purchased | date:'longDate':'UTC'}}</div>
        <div class="mt-1 col-sm col-md-2 col-lg-4 notification-status">
          <span *ngIf="addOn.status"
            class="badge badge-pill"
            [ngClass]="{'badge-success': addOn.status === 'Complete', 'badge-warning': addOn.status === 'Incomplete'}"
          >
            <p>{{addOn.status}}</p>
          </span>
          <div *ngIf="addOn.selected_dates.length > 0">
            <div *ngFor="let sd of addOn.selected_dates">
              <p *ngIf="addOn.title === 'Setup/Teardown Days'">
                <span *ngIf="sd.type === 'setup'"> Set Up | {{sd.date | date:'longDate':'UTC'}}</span>
                <span *ngIf="sd.type === 'teardown'"> Tear Down | {{sd.date | date:'longDate':'UTC'}}</span>
              </p>
              <p *ngIf="addOn.title === 'Practice Days'">
                <span *ngIf="sd.type === 'practice'"> Practice | {{sd.date | date:'longDate':'UTC'}}</span>
              </p>
            </div>
          </div>
        </div>
        <!-- NOTE: Removed until certificate link actually functional -->
        <!-- <app-button *ngIf="allowManage && (addOn.type === 'auto' || addOn.type === 'moto')"
          [type]="'btn-light btn-sm'"
          [link]=""
          style="flex:2;"
          class="col-sm-4 col-md-3 col-lg-2 mt-2"
        ><i class="icon-download"></i> Certificate</app-button> -->
      <app-add-vehicle-driver-form *ngIf="allowManage && (addOn.type === 'auto' || addOn.type === 'moto')"
        [quantity]="addOn.quantity"
        [type]="addOn.type"
        [permitId]="event.event_permit_id"
        [autoVehicles]="autoVehicles"
        [motoVehicles]="motoVehicles"
        [hasAuto]="hasAuto"
        [hasMoto]="hasMoto"
        [autoComplete]="autoComplete"
        [motoComplete]="motoComplete"
        [drivers]="drivers"
        (quantityChange)="onQuantityChange($event)"
      ></app-add-vehicle-driver-form>
      </div>
    </li>
  </ul>
</div>
<div *ngIf="loaded && allowManage" class="text-center m-3">
  <app-button
    [type]="'btn btn-light btn-md'"
    [mode]="'button'"
    (click)="toAddOns()"
  >
    <i class="icon icon-circle-plus"></i> Purchase Additional Add-Ons
  </app-button>
</div>
