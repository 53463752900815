<div class="quantity-select">
  <div class="value-button" id="decrease" (click)="decreaseValue()" value="Decrease Value">-</div>
  <input type="number"
    id="number"
    [(ngModel)]="value"
    name="{{name}}"
    [min]="min"
    [max]="max"
    [readOnly]="readOnly"
  />
  <div class="value-button" id="increase" (click)="increaseValue()" value="Increase Value">+</div>
</div>
