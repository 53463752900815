import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router} from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventListingService } from '../../services/event-listing.service';
import { PermitService } from '../../services/permit.service';
import { FileUploadService } from '../../shared/services/file-upload.service';
import { BaseService } from 'src/app/services/base-service';
import { Alert } from 'src/app/classes';

import {
  RadioOption,
  EventDate,
  CourseMap
} from '../../classes';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-event-course-map',
  templateUrl: './event-course-map.component.html',
  styles: []
})
export class EventCourseMapComponent implements OnInit {

  urlForm = false;
  uploadForm = false;
  courseMapForm: FormGroup;
  courseAlert: Alert[] = [];
  courseMapLabel = 'Choose file';

  @Input() eventId: string;
  @Input() eventDate: EventDate;
  @Input() submitted: boolean;
  @Input() index: number;
  @Input() courseMap: CourseMap;
  @Input() title: boolean;
  @Input() courseMapId: number;
  // commented out for MBR-2992
  // @Input() subDisciplines: Array<string>;

  @Output() formChange: EventEmitter<any> = new EventEmitter<any>();

  courseMapDisplayOptions: RadioOption[] = [];

  constructor(
    private formBuilder: FormBuilder,
    public eventListing: EventListingService,
    public permit: PermitService,
    private upload: FileUploadService,
    private http: HttpClient,
    private base: BaseService
  ) { }

  // convenience getter for easy access to form fields
  get f() { return this.courseMapForm.controls; }

  ngOnInit() {
    // commented out for MBR-2992
    this.courseMapForm = this.formBuilder.group({
     courseMapDisplay: [''],
     courseMapUrl: [''],
    //  courseSubDisciplines: ['']
    });

    this.courseMapDisplayOptions = [
      new RadioOption('url' + this.index + this.courseMapId, 'url', 'Enter URL'),
      new RadioOption('upload' + this.index + this.courseMapId, 'upload', 'Upload File')
    ];

    this.f.courseMapUrl.setValue(this.courseMap.courseUrl);
    this.f.courseMapDisplay.setValue(this.courseMap.courseDisplay);
    // commented out for MBR-2992
    // this.f.courseSubDisciplines.setValue(this.courseMap.courseSubdiscOptions);

    this.courseMapForm.valueChanges.subscribe(data => {
      console.log(data);
      this.courseMap.courseUrl = data.courseMapUrl;
      this.courseMap.courseDisplay = data.courseMapDisplay;
      // this.courseMap.courseSubdiscOptions = data.courseSubDisciplines;
      console.log(this.courseMap);
      this.switchForm(data.courseMapDisplay);
      this.formChange.emit({ dateId: this.eventDate.event_date_id});
    });

  }
  onSubmit() {

  }

  switchForm(tab) {
    if (tab === 'url') {
      this.uploadForm = false;
      this.urlForm = true;
    } else if (tab === 'upload') {
      this.urlForm = false;
      this.uploadForm = true;
    }
  }

  selectCoursemap(event) {
    this.courseAlert = [];
    if (event.target.files.length > 0) {
      const image = event.target.files[0];
      const fileExtn: string = image.name.split('.').pop();
      console.log(fileExtn);
      const formData: FormData = new FormData();
      formData.append('image', image);
      if (this.upload.serviceType.COURSE_MAP.extensions.indexOf(fileExtn.toLowerCase()) > -1) {
        this.upload.fileUpload(formData, this.eventId).subscribe(data => {
          console.log(data);
          this.courseAlert =  [
            new Alert('success', event.target.files[0].name + ' was successfully uploaded.')
          ];
          this.courseMapLabel = image.name;   /* tslint:disable-line:no-string-literal */
          this.f.courseMapUrl.setValue(data['url']);   /* tslint:disable-line:no-string-literal */
        }, err => {
          this.courseAlert =  [
            new Alert('warning', 'There was an error uploading your file: ' + err.message)
          ];
        });
      } else {
        this.courseAlert =  [
          new Alert('warning', 'Invalid file type. Supported file extns are PDF, JPG or PNG ')
        ];
      }
    }
  }

}
