<div *ngIf="!overlay" class="row">
  <div class="col-12 text-{{align}}">
    <div *ngIf="!small" class="loader" [ngClass]="{hasTopMargin: 'mt-4'}">
      <span class="spinner-border"></span>
      <p class="mt-1">
        <strong>{{text}}...</strong>
      </p>
    </div>

    <div *ngIf="small" class="loader loader-sm" [ngClass]="{hasTopMargin: 'mt-3'}">
        <span class="spinner-border"></span>
        <span class="ml-2">
          <strong *ngIf="text">{{text}}...</strong>
        </span>
    </div>
  </div>
</div>

<div *ngIf="overlay" class="loader loader-overlay mt-4 text-{{align}}">
  <span class="spinner-border"></span>
</div>
