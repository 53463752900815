import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ShopifyDraftOrder, ShopifyOrder, Event } from 'src/app/classes';

@Component({
  selector: 'app-overview-payments',
  templateUrl: './overview-payments.component.html',
  styles: []
})
export class OverviewPaymentsComponent implements OnInit, OnChanges {

  @Input() event: Event;
  @Input() userRole: string;
  @Input() draftOrders: ShopifyDraftOrder[];
  @Input() orders: ShopifyOrder[];

  loading = true;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.draftOrders && this.orders) {
      this.loading = false;
    }
  }

  onDraftOrderDelete(id: number) {
    const index = this.draftOrders.findIndex(draft => draft.id === id);
    this.draftOrders.splice(index, 1);
  }

}
