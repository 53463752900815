<div class="form-group text-area-form-group">
  <label for="{{name}}">
    {{label}}
    <span *ngIf="required" class="required">*</span>
  </label>
  <textarea
    class="form-control"
    [ngClass]="{ 'is-invalid': formSubmitted && errors }"
    [style.minHeight.px]="minHeight"
    name="{{name}}"
    id="{{id}}"
    [(ngModel)]="value"
    placeholder="{{placeholder}}"
    maxlength="{{maxLength}}"
  >
    {{currentValue}}
  </textarea>
  <div *ngIf="formSubmitted && errors" class="invalid-feedback">
      <div *ngIf="errors.required">Required</div>
      <div *ngIf="errors.maxlength">Too long ({{errors.maxlength.requiredLength}} character max)</div>
    </div>
</div>
