import { OnInit, EventEmitter } from '@angular/core';
var noop = function () { };
var ɵ0 = noop;
var QuantitySelectComponent = /** @class */ (function () {
    function QuantitySelectComponent() {
        this.min = 0;
        this.onTouchedCallback = noop;
        this.onChangeCallback = noop;
        this.change = new EventEmitter();
        this.valueChange = new EventEmitter();
    }
    Object.defineProperty(QuantitySelectComponent.prototype, "value", {
        get: function () {
            return this.currentValue;
        },
        set: function (val) {
            this.currentValue = val;
            this.change.emit(this.currentValue);
            this.valueChange.emit(this.currentValue);
            this.onChangeCallback(this.currentValue);
        },
        enumerable: true,
        configurable: true
    });
    QuantitySelectComponent.prototype.writeValue = function (value) {
        this.currentValue = value;
        this.onChangeCallback(value);
    };
    // From ControlValueAccessor interface
    QuantitySelectComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    // From ControlValueAccessor interface
    QuantitySelectComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    QuantitySelectComponent.prototype.ngOnInit = function () {
    };
    QuantitySelectComponent.prototype.decreaseValue = function () {
        if (this.currentValue > this.min) {
            this.currentValue--;
            this.value = this.currentValue;
            this.change.emit(this.currentValue);
        }
    };
    QuantitySelectComponent.prototype.increaseValue = function () {
        if (this.max) {
            if (this.currentValue < this.max) {
                this.increase();
            }
        }
        else {
            this.increase();
        }
    };
    QuantitySelectComponent.prototype.increase = function () {
        this.currentValue++;
        this.value = this.currentValue;
        this.change.emit(this.currentValue);
    };
    return QuantitySelectComponent;
}());
export { QuantitySelectComponent };
export { ɵ0 };
