import { Router, NavigationEnd } from '@angular/router';
var RouteHistoryService = /** @class */ (function () {
    function RouteHistoryService(router) {
        var _this = this;
        this.router = router;
        this.routeHistory = [];
        this.routeHistory.push(this.router.url);
        router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this.routeHistory.push(event.url);
            }
        });
    }
    Object.defineProperty(RouteHistoryService.prototype, "previousUrl", {
        get: function () {
            return this.routeHistory[this.routeHistory.length - 2];
        },
        enumerable: true,
        configurable: true
    });
    return RouteHistoryService;
}());
export { RouteHistoryService };
