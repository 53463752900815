import { OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CartService, CertificateService, EventService, InsuranceService, LocationService } from '../../services';
import { ShopifyService } from '../../shared';
import { Certificate, Event, ShopifyDraftOrder, ShopifyLineItemBase, ShopifyProductVariant } from '../../classes';
import { environment } from 'src/environments/environment';
var InsuredCertificatesComponent = /** @class */ (function () {
    function InsuredCertificatesComponent(shopifyService, eventService, insurance, locationService, cart, certificateService, router, route) {
        this.shopifyService = shopifyService;
        this.eventService = eventService;
        this.insurance = insurance;
        this.locationService = locationService;
        this.cart = cart;
        this.certificateService = certificateService;
        this.router = router;
        this.route = route;
        this.currentStep = 3;
        this.submitted = false;
        this.saveDisabled = true;
        this.validForms = [];
        this.skus = [];
        this.includedCertVariantSkus = [];
        this.endorsementVariantSkus = [];
        this.endorsementVariants = [];
        this.endorsementItems = [];
        this.form = new FormGroup({
            variant: new FormControl([]),
            quantity: new FormControl(1),
        });
    }
    Object.defineProperty(InsuredCertificatesComponent.prototype, "lineItems", {
        get: function () { return this.draftOrder.line_items; },
        enumerable: true,
        configurable: true
    });
    InsuredCertificatesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.eventId = this.route.snapshot.paramMap.get('eventId');
        if (this.eventId) {
            this.link = "event/" + this.eventId + "/add-ons";
            this.locationService.getStates().subscribe();
            // Subscribe to draft order in cart component
            this.certificateService.certificates = [];
            this.insurance.includedCertificates = [];
            this.insurance.certificates = [];
            this.eventService.getEventById(this.eventId).subscribe(function (event) {
                if (event) {
                    _this.event = new Event(event);
                    if (!_this.event.isPermit) {
                        _this.toOverview();
                    }
                    // Fetch products & prepare draft order update once certs initialized
                    _this.insurance.initComplete$.subscribe(function (complete) {
                        if (complete) {
                            setTimeout(function () {
                                _this.fetchProducts();
                                _this.setSaveContinue();
                            }, 100);
                        }
                    });
                    _this.shopifyService.cartDraftOrder.subscribe(function (draftOrder) {
                        _this.draftOrder = draftOrder;
                        _this.certificateService.getCertificates(_this.eventId).subscribe(function (certs) {
                            _this.setInitialCerts(certs);
                        });
                    });
                }
                else {
                    _this.toQueue();
                }
            });
        }
        else {
            this.toQueue();
        }
    };
    InsuredCertificatesComponent.prototype.setInitialCerts = function (certs) {
        var _this = this;
        // Add included certificates from listing if not already saved
        var eventOrganizerNeedingCert = !certs.some(function (cert) {
            return cert.type === 'named' && cert.profile_id === _this.event.event_organizer;
        }) ? this.event.event_organizer : null;
        var sponsoringClubsNeedingCert = this.event.event_sponsoring_clubs.filter(function (club) {
            return !certs.some(function (cert) { return cert.type === 'named' && cert.name === club.club_name.trim(); });
        });
        this.insurance.init(eventOrganizerNeedingCert, sponsoringClubsNeedingCert);
        // Add already saved certificates
        certs.forEach(function (cert) {
            _this.certificateService.addOrUpdateSelectedCert(null, cert);
            if (_this.draftOrder.tags.includes("certificate_id-" + cert.id)) {
                var ins = _this.insurance.createFromCertificate(cert);
                cert.type === 'named' ? _this.insurance.includedCertificates.push(ins) : _this.insurance.certificates.push(ins);
            }
        });
    };
    Object.defineProperty(InsuredCertificatesComponent.prototype, "noCerts", {
        get: function () {
            return !this.insurance.includedCertificates.length && !this.insurance.certificates.length;
        },
        enumerable: true,
        configurable: true
    });
    InsuredCertificatesComponent.prototype.setSaveContinue = function () {
        this.saveDisabled = !this.noCerts;
    };
    InsuredCertificatesComponent.prototype.onAddCert = function () {
        this.saveDisabled = true;
        this.insurance.addCert();
    };
    Object.defineProperty(InsuredCertificatesComponent.prototype, "certsToBeAdded", {
        get: function () {
            return this.certificateService.certificates.filter(function (cert) { return !cert.id; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InsuredCertificatesComponent.prototype, "existingCerts", {
        get: function () {
            var _this = this;
            return this.certificateService.certificates.filter(function (cert) { return !!cert.id && _this.certInDraftOrder(cert); });
        },
        enumerable: true,
        configurable: true
    });
    InsuredCertificatesComponent.prototype.certInDraftOrder = function (certificate) {
        return this.draftOrder && this.draftOrder.tags && this.draftOrder.tags.includes("certificate_id-" + certificate.id);
    };
    InsuredCertificatesComponent.prototype.onCertificateSave = function ($event) {
        var certs = this.insurance.includedCertificates.concat(this.insurance.certificates);
        this.saveDisabled = !(certs.length === this.certsToBeAdded.length + this.existingCerts.length);
    };
    InsuredCertificatesComponent.prototype.onEndorsementUpdate = function ($event) {
        var _this = this;
        var selected = $event.endorsements;
        var previous = $event.previous;
        this.endorsementItems.forEach(function (end) {
            var lineItem = { variant_id: end.variant_id, quantity: 1 };
            if ((selected.some(function (sel) { return sel.variant_id === end.variant_id; })) && (!previous.some(function (p) { return p.variant_id === end.variant_id; }))) {
                _this.shopifyService.increaseItemQuantity(lineItem, 1, _this.draftOrder);
            }
            else if ((previous.some(function (pre) { return pre.variant_id === end.variant_id; })) && (!selected.some(function (sel) { return sel.variant_id === end.variant_id; }))) {
                _this.shopifyService.decreaseItemQuantity(lineItem, 1, _this.draftOrder);
            }
        });
    };
    InsuredCertificatesComponent.prototype.fetchProducts = function () {
        var _this = this;
        var disc = this.event.event_discipline;
        if (disc) {
            this.namedInsuredOrganizerSku = environment.disciplineSkus[disc].namedInsuredOrganizer;
            this.namedInsuredClubSku = environment.disciplineSkus[disc].namedInsuredClub;
            this.additionalInsuredSku = environment.disciplineSkus[disc].additionalInsured;
            this.includedCertVariantSkus.push(this.namedInsuredOrganizerSku);
            this.includedCertVariantSkus.push(this.namedInsuredClubSku);
            this.endorsementAdditionalSku = environment.disciplineSkus[disc].endorsementAdditional;
            this.endorsementCancelSku = environment.disciplineSkus[disc].endorsementCancel;
            this.endorsementNonContribSku = environment.disciplineSkus[disc].endorsementNonContrib;
            this.endorsementWaiverSku = environment.disciplineSkus[disc].endorsementWaiver;
            this.endorsementVariantSkus.push(this.endorsementAdditionalSku);
            this.endorsementVariantSkus.push(this.endorsementCancelSku);
            this.endorsementVariantSkus.push(this.endorsementNonContribSku);
            this.endorsementVariantSkus.push(this.endorsementWaiverSku);
            this.includedCertVariantSkus.forEach(function (sku) { return _this.skus.push(sku); });
            this.skus.push(this.additionalInsuredSku);
            this.endorsementVariantSkus.forEach(function (sku) { return _this.skus.push(sku); });
        }
        this.shopifyService.getShopifyProductVariants({ skus: this.skus }).subscribe(function (variants) {
            _this.namedInsuredOrganizer = _this.findVariant(variants, _this.namedInsuredOrganizerSku);
            _this.namedInsuredClub = _this.findVariant(variants, _this.namedInsuredClubSku);
            _this.additionalInsured = _this.findVariant(variants, _this.additionalInsuredSku);
            _this.endorsementAdditional = _this.findVariant(variants, _this.endorsementAdditionalSku);
            _this.endorsementCancel = _this.findVariant(variants, _this.endorsementCancelSku);
            _this.endorsementNonContrib = _this.findVariant(variants, _this.endorsementNonContribSku);
            _this.endorsementWaiver = _this.findVariant(variants, _this.endorsementWaiverSku);
            _this.includedCertVariants = [
                _this.namedInsuredOrganizer,
                _this.namedInsuredClub
            ];
            _this.endorsementVariants = [
                _this.endorsementAdditional,
                _this.endorsementWaiver,
                _this.endorsementNonContrib,
                _this.endorsementCancel
            ];
            _this.endorsementItems = [
                { variant_id: _this.endorsementAdditional.id, quantity: 1 },
                { variant_id: _this.endorsementWaiver.id, quantity: 1 },
                { variant_id: _this.endorsementNonContrib.id, quantity: 1 },
                { variant_id: _this.endorsementCancel.id, quantity: 1 }
            ];
            // Only add generated certs that haven't already been saved
            _this.addIncludedCerts(_this.insurance.includedCertificates.filter(function (cert) { return !cert.certificateId; }));
        });
    };
    InsuredCertificatesComponent.prototype.findVariant = function (variants, sku) {
        return variants.find(function (variant) { return sku === variant.sku; });
    };
    InsuredCertificatesComponent.prototype.addIncludedCerts = function (certs) {
        var eoQuant = 0;
        var scQuant = 0;
        certs.forEach(function (cert) {
            switch (cert.type) {
                case 'Event Organizer':
                    eoQuant += 1;
                    break;
                case 'Sponsoring Club': scQuant += 1;
            }
        });
        if (eoQuant > 0) {
            this.shopifyService.increaseItemQuantity({ variant_id: this.namedInsuredOrganizer.id, quantity: 1 }, 1, this.draftOrder);
        }
        if (scQuant > 0) {
            this.shopifyService.increaseItemQuantity({ variant_id: this.namedInsuredClub.id, quantity: scQuant }, 1, this.draftOrder);
        }
    };
    Object.defineProperty(InsuredCertificatesComponent.prototype, "loading", {
        // clearCartCertificates() {
        //   this.lineItems.forEach(item => {
        //     if (this.includedCertVariants.some(variant => variant.id === item.variant_id)) {
        //       this.shopifyService.removeFromDraftOrder(item, this.draftOrder);
        //     }
        //   });
        //   this.lineItems.forEach(item => {
        //     if (this.additionalInsured.id === item.variant_id) {
        //       this.shopifyService.removeFromDraftOrder(item, this.draftOrder);
        //     }
        //   });
        // }
        // clearCartEndorsements() {
        //   this.lineItems.forEach(item => {
        //     if (this.endorsementVariants.some(variant => variant.id === item.variant_id)) {
        //       this.shopifyService.removeFromDraftOrder(item, this.draftOrder);
        //     }
        //   });
        // }
        get: function () {
            return !this.namedInsuredOrganizer || !this.namedInsuredClub
                || !this.additionalInsured || !this.endorsementVariants.length
                || !this.locationService.stateOptions;
        },
        enumerable: true,
        configurable: true
    });
    InsuredCertificatesComponent.prototype.redirectTemp = function () {
        this.router.navigate([this.link]);
    };
    InsuredCertificatesComponent.prototype.toAddOns = function () {
        this.router.navigate(["event/" + this.eventId + "/add-ons"]);
    };
    InsuredCertificatesComponent.prototype.toOverview = function () {
        this.router.navigate(["event/" + this.eventId + "/overview"]);
    };
    InsuredCertificatesComponent.prototype.toQueue = function () {
        this.router.navigate(['queue']);
    };
    InsuredCertificatesComponent.prototype.onSave = function () {
        this.submitted = true;
        if (!this.noCerts) {
            this.validForms = [];
        }
        else {
            this.toAddOns();
        }
    };
    InsuredCertificatesComponent.prototype.checkValidForms = function ($event) {
        var _this = this;
        this.validForms.push($event);
        if (this.validForms.length === this.insurance.includedCertificates.length + this.insurance.certificates.length) {
            if (this.validForms.includes(false)) {
                console.log('At least 1 form is invalid');
            }
            else {
                // No new or existing certs
                if (!this.certsToBeAdded.length && !this.existingCerts) {
                    setTimeout(function () { return _this.router.navigate(["event/" + _this.eventId + "/add-ons"]); }, 1000);
                    // Only existing certs
                }
                else if (!this.certsToBeAdded.length && this.existingCerts.length) {
                    this.updateExistingCerts();
                    // New certs
                }
                else if (this.certsToBeAdded.length) {
                    var totalCerts_1 = 0;
                    this.certsToBeAdded.forEach(function (certificate) {
                        _this.certificateService.addCertificate(_this.eventId, certificate).subscribe(function (cert) {
                            totalCerts_1 += 1;
                            // Add certificate ID tag to draft order
                            var certTag = "certificate_id-" + cert.id;
                            if (_this.draftOrder.tags) {
                                if (!_this.draftOrder.tags.includes(certTag)) {
                                    _this.draftOrder.tags += ", " + certTag;
                                }
                            }
                            else {
                                _this.draftOrder.tags = certTag;
                            }
                            if (totalCerts_1 === _this.certsToBeAdded.length) {
                                if (_this.existingCerts.length) {
                                    _this.updateExistingCerts();
                                }
                                else {
                                    _this.updateDraftOrder();
                                }
                            }
                        });
                    });
                }
            }
        }
    };
    InsuredCertificatesComponent.prototype.updateExistingCerts = function () {
        var _this = this;
        var totalExistingCerts = 0;
        this.existingCerts.forEach(function (existingCert) {
            _this.certificateService.updateCertificate(_this.eventId, existingCert).subscribe(function (resp) {
                if (resp) {
                    totalExistingCerts += 1;
                    if (totalExistingCerts === _this.existingCerts.length) {
                        _this.updateDraftOrder();
                    }
                }
            });
        });
    };
    InsuredCertificatesComponent.prototype.updateDraftOrder = function () {
        this.shopifyService.updateDraftOrderItems(this.draftOrder, "event/" + this.eventId + "/add-ons");
    };
    return InsuredCertificatesComponent;
}());
export { InsuredCertificatesComponent };
