var Event = /** @class */ (function () {
    function Event(data) {
        // Race & Ride disciplines, as they pertain to Smart Calendar (event incentives)
        this.raceDisciplines = [19, 20, 21, 23];
        this.rideDisciplines = [24, 25, 27];
        this.event_id = data.event_id;
        this.event_name = data.event_name;
        this.event_description = data.event_description;
        this.event_start_date = data.event_start_date;
        this.event_end_date = data.event_end_date;
        this.event_permit_id = data.event_permit_id;
        this.event_discipline = data.event_discipline;
        this.event_organizer = data.event_organizer;
        this.event_types = data.event_types;
        this.event_largest_purse = data.event_largest_purse;
        this.event_equal_pay = data.event_equal_pay;
        this.event_bike_reg = data.event_bike_reg;
        this.event_reg_url = data.event_reg_url;
        this.event_website = data.event_website;
        this.event_announcement = data.event_announcement;
        this.event_logo = data.event_logo;
        this.event_facebook_url = data.event_facebook_url;
        this.event_twitter_url = data.event_twitter_url;
        this.event_youtube_url = data.event_youtube_url;
        this.event_instagram_url = data.event_instagram_url;
        this.event_pre_reg = data.event_pre_reg;
        this.event_on_site_reg = data.event_on_site_reg;
        this.event_total_reg = data.event_total_reg;
        this.event_competitive = data.event_competitive;
        this.event_listing_owner = data.event_listing_owner;
        this.event_status = data.event_status;
        this.event_contacts = data.event_contacts;
        this.event_sponsoring_clubs = data.event_sponsoring_clubs;
        this.event_special_calendars = data.event_special_calendars;
        this.event_local_association = data.event_local_association;
        this.event_closed_comments = data.event_closed_comments;
        this.event_closed_without_paperwork = data.event_closed_without_paperwork;
        this.event_label_discount = data.event_label_discount;
        this.created_at = data.created_at;
        this.is_pended_outstanding_permit_fee = data.is_pended_outstanding_permit_fee;
    }
    Object.defineProperty(Event.prototype, "isPermit", {
        get: function () {
            return !!this.event_permit_id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Event.prototype, "isVirtual", {
        get: function () {
            if (this.event_discipline) {
                return this.event_discipline === 38;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Event.prototype, "isRace", {
        get: function () {
            if (this.event_discipline) {
                return this.raceDisciplines.includes(this.event_discipline);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Event.prototype, "isRide", {
        get: function () {
            if (this.event_discipline) {
                return this.rideDisciplines.includes(this.event_discipline);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Event.prototype, "isRaceOrRide", {
        get: function () {
            return this.isRace || this.isRide;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Event.prototype, "overviewLink", {
        get: function () {
            return "/event/" + this.event_id + "/overview";
        },
        enumerable: true,
        configurable: true
    });
    return Event;
}());
export { Event };
