
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ShopifyService, GlobalService, LineItem} from '../../shared';
import { EventDayService } from '../../services';
import { FormControl, FormGroup } from '@angular/forms';
import { ShopifyProduct, ShopifyDraftOrder, EventDate } from 'src/app/classes';

@Component({
  selector: 'app-add-on-list',
  templateUrl: './add-on-list.component.html'
})
export class AddOnListComponent implements OnInit {
  @Input() eventId: string;
  @Input() discipline: number;
  @Input() eventDates: EventDate[];

  loading = true;
  productsInitialized = 0;
  productsLoaded = 0;
  // products: Array<Product>;

  draftOrder: ShopifyDraftOrder;
  products: ShopifyProduct[];
  productImages = {};
  productVariants = {};

  form = new FormGroup({
    variant: new FormControl([]),
    quantity: new FormControl(1),
  });

  constructor(
    private router: Router,
    private shopifyService: ShopifyService,
    private globalService: GlobalService,
    private eventDayService: EventDayService
  ) {}

  ngOnInit() {
    this.eventDayService.setExistingDays(this.eventId);

    this.shopifyService.cartDraftOrder.subscribe(draftOrder => this.draftOrder = draftOrder);
    this.shopifyService.cartProducts.subscribe(products => {
      if (products.length) {
        // TODO: Determine better way of accessing products through collections instead of tags
        this.products = products.filter(product => product.tags && product.tags.includes('Add Ons'));
        const product_ids = [];
        this.products.forEach(product => product_ids.push(product.id));
        this.shopifyService.getShopifyProductImages({product_ids}).subscribe(images => {
          if (images.length) {
            images.forEach(image => this.productImages[image.product_id] = image);
          }
        });
        this.shopifyService.getShopifyProductVariants({product_ids}).subscribe(variants => {
          if (variants.length) {
            variants.forEach(variant => {
              if (!this.productVariants[variant.product_id]) {
                this.productVariants[variant.product_id] = [];
              }
              this.productVariants[variant.product_id].push(variant);
            });
          }
        });
      }
    });
  }

  addToCart(lineItem: LineItem) {
    this.globalService.addItemToCart(lineItem);
  }

  setProductsInitialized($event) {
    this.productsInitialized += 1;
  }

  setProductsLoaded($event) {
    this.productsLoaded += 1;
    this.loading = this.productsLoaded !== this.productsInitialized;
  }

  get productVariantsLength(): boolean {
    return !!Object.keys(this.productVariants).length;
  }

  toInsuredCerts() {
    this.router.navigate([`/event/${this.eventId}/insured-certificates`]);
  }
}
