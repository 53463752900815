
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

import { BaseService } from './base-service';
import { EventDay } from '../classes';

@Injectable({
  providedIn: 'root'
})
export class EventDayService extends BaseService {

  setupTeardownDays: EventDay[] = [];
  practiceDays: EventDay[] = [];
  existingDays: EventDay[] = [];
  eventDays: EventDay[] = [];

  constructor(
    protected http: HttpClient,
    protected cookie: CookieService
  ) {
    super(http, cookie);
  }

  getEventDaysForEvent(eventId: number|string): Observable<EventDay[]> {
    return this.http.get<EventDay[]>(this.url(`event/${eventId}/calendar`), this.options)
      .pipe(
        tap(_ => this.log('fetched event days')),
        catchError(this.handleError<EventDay[]>('getEventDaysForEvent', [], { eventId }))
      );
  }

  getEventDaysForEventByType(eventId: number|string, type: string): Observable<EventDay[]> {
    return this.http.get<EventDay[]>(this.url(`event/${eventId}/calendar/${type}`), this.options)
      .pipe(
        tap(_ => this.log('fetched event days by type')),
        catchError(this.handleError<EventDay[]>('getEventDaysForEventByType', [], { eventId, type }))
      );
  }

  getEventDay(eventId: number|string, eventDayId: number|string): Observable<EventDay> {
    return this.http.get<EventDay>(this.url(`event/${eventId}/calendar/${eventDayId}`), this.options)
      .pipe(
        tap(_ => this.log('fetched event day')),
        catchError(this.handleError<EventDay>('getEventDay', null, { eventId, eventDayId }))
      );
  }

  addEventDays(eventId: number|string): Observable<EventDay[]> {
    this.eventDays = this.existingDays.concat(this.setupTeardownDays, this.practiceDays);
    return this.http.post(this.url(`event/${eventId}/calendar`), {days: this.eventDays}, this.options)
      .pipe(
        tap((newEventDays: EventDay[]) => this.log(`added event days for event id: ${eventId}`)),
        catchError(this.handleError<EventDay[]>('addEventDay', [], { eventId, eventDays: this.eventDays }))
      );
  }

  clearEventDays(eventId: number|string): Observable<EventDay[]> {
    return this.http.post(this.url(`event/${eventId}/calendar`), {days: null}, this.options)
      .pipe(
        tap((clearedEventDays: EventDay[]) => this.log(`cleared event days for event id: ${eventId}`)),
        catchError(this.handleError<EventDay[]>('clearEventDays', [], { eventId }))
      );
  }

  // Use if delete route added. Otherwise, use clearEventDays()
  // deleteEventDay(eventId: number|string, eventDayId: number|string): Observable<any> {
  //   return this.http.delete(this.url(`event/${eventId}/calendar/${eventDayId}`), this.options)
  //     .pipe(
  //       tap(_ => this.log(`deleted event day id: ${eventDayId}`)),
  //       catchError(this.handleError('deleteEventDay'))
  //     );
  // }

  setExistingDays(eventId: string) {
    this.getEventDaysForEvent(eventId).subscribe(days => {
      this.existingDays = days;
    });
  }

  updateSelectedDays(collection: string, days: EventDay[]) {
    switch (collection) {
      case 'setupTeardown': this.setupTeardownDays = days; break;
      case 'practice': this.practiceDays = days;
    }
  }
}
