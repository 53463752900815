import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer-link-group',
  templateUrl: './footer-link-group.component.html',
  styles: []
})
export class FooterLinkGroupComponent implements OnInit {
  @Input() links: object[];
  constructor() { }

  ngOnInit() {
  }

}
