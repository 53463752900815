<div *ngIf="cartAlerts.length > 0">
  <app-alert [alerts]="cartAlerts"></app-alert>
</div>
<div class="container first">
  <div class="row">
    <div class="col-sm-12">
      <div class="search-filter-bar">
        <div class="row align-items-center justify-content-between">
          <h3 class="">Events Queue</h3>
          <div class="">
            <app-button [type]="'btn-primary'" (click)="toAddNew()">Add New Event</app-button>
            <app-button [type]="'btn-secondary'" [routerLink]="'/reports'">Reports</app-button>
          </div>
        </div>
        <div class="row align-items-center justify-content-between float-right">
            <app-event-queue-filter [userRole]="userRole" class="float-right"></app-event-queue-filter>
            <app-button [type]="'btn-secondary'" (click)="csvExport()">Export</app-button>
        </div>
      </div>
      <form [formGroup]="eventForm">
        <app-text-input
          formControlName="event_id"
          [id]="'event_id'"
          [name]="'event_id'"
          [placeholder]="'Enter Event ID or Permit ID'"
        ></app-text-input>
      </form>
    </div>
  </div>

  <!-- Loader -->
  <app-loader *ngIf="loading"></app-loader>

  <div class="row">
    <div class="col-sm-12">
        <app-table *ngIf="resultsData.length > 0 else noResults"
            [id]="'eventsQueueTable'"
            [columns]="tableColumns"
            [data]="resultsData"
            [actions]="tableActions"
            [actionMessages]="actionMessages"
            [disabledActions]="disabledActions"
            [selectActions]="selectActions"
            [selectOptions]="selectOptions"
            [linkProp]="'overview_link'"
            [autoSort]="true"
            [disableTextWrap]="disableTextWrap"
            [utcDisplayDates]="utcDisplayDates"
            (action)="onTableAction($event)"
            (sort)="onTableSort($event)"
          ></app-table>
    </div>
  </div>
  <div class="row mt-3" *ngIf="paginationMeta">
    <div class="col-12 text-center">
      <span *ngIf="paginationMeta.current_page > 1" class="float-left">
        <app-button [type]="'btn-secondary'" (click)="loadQueuePage(1)">&#x0226A; First</app-button>
        <app-button [type]="'btn-secondary'" (click)="loadQueuePage(paginationMeta.current_page - 1)">&lt; Prev</app-button>
      </span>

      <span *ngIf="paginationMeta.last_page > 1">
        Page {{paginationMeta.current_page}} of {{paginationMeta.last_page}}
      </span>

      <span *ngIf="paginationMeta.current_page < paginationMeta.last_page" class="float-right">
        <app-button [type]="'btn-secondary'" (click)="loadQueuePage(paginationMeta.current_page + 1)">Next &gt;</app-button>
        <app-button [type]="'btn-secondary'" (click)="loadQueuePage(paginationMeta.last_page)">Last &#x0226B;</app-button>
      </span>
    </div>
  </div>
  <ng-template #noResults>
    <div *ngIf="!loading" class="mt-2">
      <app-alert [alerts]="alert"></app-alert>
    </div>
  </ng-template>
  <app-modal
    [id]="'status-modal'"
    [title]="'Update Status'"
    [isOpen]="modalOpen"
    (close)="closeModal()"
  >
    <app-modal-body>
        <p>This permit has unapproved change requests. Would you like to accept all changes and change the status to Approved?</p>
      </app-modal-body>
      <app-modal-footer [class]="'justify-content-between'">
        <!-- TODO: Replace button link with route to change request view -->
        <app-button *ngIf="modalRow"
          [type]="'btn-light'"
          link="event/{{modalRow.id}}/overview#event-listing"
        >View Changes</app-button>
        <app-button
          [type]="'btn-secondary'"
          (click)="onSubmit(modalAction, modalRow, $event)"
        >Update Status</app-button>
      </app-modal-footer>
  </app-modal>
</div>
