import { OnInit, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FileUploadService, GlobalService, MerchantAccountTag, ShopifyService } from '../../shared';
import { EventDayService, EventService } from '../../services';
import { ShopifyDraftOrder, ShopifyDiscount } from 'src/app/classes';
var CartShopifyComponent = /** @class */ (function () {
    function CartShopifyComponent(eventDayService, eventService, globalService, router, shopifyService, uploadService) {
        this.eventDayService = eventDayService;
        this.eventService = eventService;
        this.globalService = globalService;
        this.router = router;
        this.shopifyService = shopifyService;
        this.uploadService = uploadService;
        this.tosModalOpen = false;
        this.tosCheckoutDisabled = true;
        this.orders = [];
        this.products = [];
        this.productImages = [];
        this.nextgenDraftOrderLoaded = false;
        // Wait half a second after changes stop to get draft order and orders
        this.getDraftOrderAndOrdersDelay = 500;
        // Wait for 5 seconds after changes stop to get draft order
        this.getDraftOrderDelay = 5000;
        this.maxRetries = 5;
        // Wait for 1 second after changes stop to update draft order
        this.updateDraftDelay = 1000;
        // Wait for 1 second after route events stop to fetch event docs
        this.eventDocsDelay = 1000;
        this.loading = new EventEmitter();
    }
    CartShopifyComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.globalService.cartOpenCloseObs.subscribe(function (cartOpenClose) { return _this.cartOpen = cartOpenClose; });
        this.shopifyService.cartDraftOrder.subscribe(function (draftOrder) {
            _this.draftOrder = draftOrder;
            if (draftOrder) {
                _this.nextgenDraftOrderLoaded = true;
            }
        });
        this.shopifyService.cartProducts.subscribe(function (products) { return _this.products = products; });
        this.router.events.subscribe(function (routerEvent) {
            if (_this.router.url !== '/') {
                var matches = _this.router.url && _this.router.url.match(/(?:event\/)(\d*)(?=\/)/g);
                _this.eventId = (matches && matches.length) ? matches[0].substring(6) : null;
                // Update eventId to clear draft order and load new one
                if (_this.eventId !== _this.previousEventId) {
                    _this.draftOrder = null;
                    _this.nextgenDraftOrderLoaded = false;
                }
                // Checkout only enabled on add-ons view
                if (_this.eventId) {
                    _this.checkoutDisabled = _this.router.url !== "/event/" + _this.eventId + "/add-ons";
                    _this.checkForSignedAgreement();
                }
                if (routerEvent instanceof NavigationEnd && !_this.nextgenDraftOrderLoaded) {
                    _this.previousEventId = _this.eventId;
                    if (_this.eventId) {
                        _this.eventService.event$.subscribe(function (event) {
                            if (event && event.isPermit) {
                                _this.getOrdersAndDraftOrder();
                            }
                        });
                    }
                }
            }
        });
    };
    CartShopifyComponent.prototype.checkForSignedAgreement = function () {
        var _this = this;
        if (this.eventDocsTimeout) {
            clearTimeout(this.eventDocsTimeout);
        }
        this.eventDocsTimeout = setTimeout(function () {
            _this.uploadService.getUploadsForEvent(_this.eventId).subscribe(function (uploads) {
                _this.agreementSigned = uploads.some(function (upload) { return upload.file_name === 'pdf_agreement'; });
            });
        }, this.eventDocsDelay);
    };
    CartShopifyComponent.prototype.applyCalendarCode = function (tagsArray) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var calendarCode = localStorage.getItem('calendarCode');
            if (calendarCode) {
                // Find permit fee line item & calculate total from price & quantity
                var permitFees = _this.draftOrder.line_items.filter(function (item) { return item.sku.includes('PERMIT-FEE-'); });
                if (permitFees.length) {
                    permitFees.forEach(function (feeItem) {
                        if (!feeItem.applied_discount) {
                            // Create discount
                            var discountAmnt = parseFloat(feeItem.price) * feeItem.quantity;
                            var discount = new ShopifyDiscount({
                                description: 'Permit Fee Calendar Code',
                                value_type: 'percentage',
                                value: 100,
                                amount: discountAmnt,
                                title: calendarCode
                            });
                            // Append discount to line item
                            Object.assign(feeItem, { applied_discount: discount });
                        }
                    });
                    // Update draft order
                    var draft = _this.draftOrder;
                    var draftTags = draft.tags ? draft.tags.split(', ') : null;
                    var tags = tagsArray ? tagsArray : draftTags;
                    _this.shopifyService.updateDraftOrder(draft.id, draft.line_items, tags, draft.applied_discount, draft.note).subscribe(function (resp) {
                        if (resp) {
                            localStorage.removeItem('calendarCode');
                            return resolve(resp);
                        }
                        else {
                            return reject();
                        }
                    });
                }
                else {
                    resolve({});
                }
            }
            else {
                return resolve({});
            }
        });
    };
    CartShopifyComponent.prototype.getOrdersAndDraftOrder = function () {
        var _this = this;
        if (this.getDraftOrderAndOrdersTimeout) {
            clearTimeout(this.getDraftOrderAndOrdersTimeout);
        }
        this.getDraftOrderAndOrdersTimeout = setTimeout(function () {
            _this.getDraftOrder();
            _this.getOrders();
        }, this.getDraftOrderAndOrdersDelay);
    };
    CartShopifyComponent.prototype.getDraftOrder = function (attempt) {
        var _this = this;
        if (attempt === void 0) { attempt = 1; }
        if (!this.nextgenDraftOrderLoaded) {
            this.shopifyService.getShopifyDraftOrders({ event_id: this.eventId }).subscribe(function (draftOrders) {
                // Check for initial event draft order, repeating every 5 seconds if no draft orders found.
                if (draftOrders.length) {
                    var openDraftOrders = draftOrders.filter(function (draftOrder) { return draftOrder.status === "open" /* open */; });
                    if (openDraftOrders.length) {
                        // If found and open, assign draft order
                        _this.draftOrder = openDraftOrders[0];
                        _this.setCartDraftOrder(_this.draftOrder);
                        _this.nextgenDraftOrderLoaded = true;
                    }
                    else {
                        // If found but completed, create new draft order
                        _this.createDraftOrder();
                    }
                    _this.shopifyService.getCartProducts();
                }
                else {
                    if (_this.getDraftOrderTimeout) {
                        clearTimeout(_this.getDraftOrderTimeout);
                    }
                    if (attempt <= _this.maxRetries) {
                        attempt++;
                        _this.getDraftOrderTimeout = setTimeout(function () { return _this.getDraftOrder(attempt); }, _this.getDraftOrderDelay);
                    }
                    else {
                        _this.createDraftOrder();
                    }
                }
            });
        }
    };
    CartShopifyComponent.prototype.createDraftOrder = function () {
        this.draftOrder = new ShopifyDraftOrder({ tags: "event_id-" + this.eventId, line_items: [] });
        this.setCartDraftOrder(this.draftOrder);
    };
    CartShopifyComponent.prototype.getOrders = function () {
        var _this = this;
        this.shopifyService.getShopifyOrders({ event_id: this.eventId }).subscribe(function (orders) {
            _this.orders = orders;
            _this.shopifyService.eventOrders.next(orders);
        });
    };
    CartShopifyComponent.prototype.setCartDraftOrder = function (draftOrder) {
        this.shopifyService.updateCartDraftOrder(draftOrder);
    };
    CartShopifyComponent.prototype.updateOrCreateDraftOrder = function () {
        var _this = this;
        var lineItems = [];
        // TODO: @Charlie - What other default tags should we assign here?
        var eventIdTag = "event_id-" + this.eventId;
        var tags = [eventIdTag, MerchantAccountTag.Permitting];
        // const discountAmnt = '';
        // const discount = new ShopifyDiscount({
        //   description: 'Post Event Admin Discount',
        //   value_type: 'fixed_amount',
        //   value: discountAmnt,
        //   amount: discountAmnt,
        //   title: 'Post Event'
        // });
        var discount = null;
        var note = null;
        if (!!this.draftOrder) {
            var draftTags = this.draftOrder.tags ? this.draftOrder.tags.split(', ') : [];
            if (!draftTags.some(function (tag) { return tag === eventIdTag; })) {
                draftTags.push(eventIdTag);
            }
            this.applyCalendarCode(draftTags).then(function (discountData) {
                if (discountData) {
                    _this.openCheckout(_this.draftOrder);
                }
            });
        }
        else {
            this.shopifyService.createDraftOrder(lineItems, tags, discount, note).subscribe(function (resp) {
                if (resp) {
                    _this.applyCalendarCode().then(function (discountData) {
                        if (discountData) {
                            _this.openCheckout(_this.draftOrder);
                        }
                    });
                }
            });
        }
    };
    CartShopifyComponent.prototype.removeItem = function (lineItem) {
        this.shopifyService.removeFromDraftOrder(lineItem, this.draftOrder);
        this.delayedUpdate();
    };
    CartShopifyComponent.prototype.increaseQuantity = function (lineItem) {
        this.shopifyService.increaseItemQuantity(lineItem, 1, this.draftOrder);
        this.delayedUpdate();
    };
    CartShopifyComponent.prototype.decreaseQuantity = function (lineItem) {
        this.shopifyService.decreaseItemQuantity(lineItem, 1, this.draftOrder);
        this.delayedUpdate();
    };
    CartShopifyComponent.prototype.delayedUpdate = function () {
        var _this = this;
        if (this.updateDraftTimeout) {
            clearTimeout(this.updateDraftTimeout);
        }
        this.updateDraftTimeout = setTimeout(function () {
            // Delete draft order & create empty one if line items empty
            if (!_this.lineItems.length) {
                _this.shopifyService.deleteDraftOrder(_this.draftId).subscribe(function (resp) {
                    if (resp) {
                        var draft = new ShopifyDraftOrder({ tags: "event_id-" + _this.eventId, line_items: [] });
                        _this.shopifyService.cartDraftOrder.next(draft);
                    }
                });
            }
            else {
                _this.shopifyService.updateDraftOrderItems(_this.draftOrder);
            }
        }, this.updateDraftDelay);
    };
    CartShopifyComponent.prototype.handleCheckout = function () {
        if (this.agreementSigned) {
            this.handleCompleteCheckout();
        }
        else {
            this.openTosModal();
        }
    };
    CartShopifyComponent.prototype.openTosModal = function () {
        this.tosModalOpen = true;
    };
    CartShopifyComponent.prototype.closeTosModal = function () {
        this.tosModalOpen = false;
    };
    CartShopifyComponent.prototype.handleTosAccept = function (data) {
        // console.log ('data to save', data);
        this.postEventDays();
        this.tosCheckoutDisabled = false;
    };
    CartShopifyComponent.prototype.handleCompleteCheckout = function (data) {
        this.loading.emit(true);
        this.closeTosModal();
        this.updateOrCreateDraftOrder();
    };
    CartShopifyComponent.prototype.saveCheckoutDataLocally = function () {
        var _a = this, draftId = _a.draftId, lineItems = _a.lineItems;
        localStorage.setItem('checkout', JSON.stringify({ draftId: draftId, lineItems: lineItems }));
    };
    CartShopifyComponent.prototype.postEventDays = function () {
        if (this.eventId) {
            this.eventDayService.addEventDays(this.eventId).subscribe();
        }
    };
    CartShopifyComponent.prototype.openCheckout = function (draftOrder) {
        this.saveCheckoutDataLocally();
        this.loading.emit(false);
        window.location.assign(draftOrder.invoice_url);
    };
    CartShopifyComponent.prototype.closeCart = function () {
        this.globalService.cartToggle();
    };
    Object.defineProperty(CartShopifyComponent.prototype, "draftId", {
        get: function () {
            return this.draftOrder.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CartShopifyComponent.prototype, "lineItems", {
        get: function () {
            return this.draftOrder ? this.draftOrder.line_items : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CartShopifyComponent.prototype, "completeLineItems", {
        get: function () {
            return (this.lineItems && this.lineItems.length) ? this.lineItems.filter(function (item) { return !!item.title; }) : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CartShopifyComponent.prototype, "cartUpdating", {
        get: function () {
            return this.shopifyService.cartUpdating;
        },
        enumerable: true,
        configurable: true
    });
    CartShopifyComponent.prototype.imageSrc = function (lineItem) {
        var productImage = this.productImages.find(function (image) { return image.product_id === lineItem.product_id; });
        return productImage ? productImage.src : '';
    };
    CartShopifyComponent.prototype.disableCartQuantity = function (lineItem) {
        var product = this.products.find(function (p) { return p.id === lineItem.product_id; });
        return (product && product.tags) ? product.tags.includes('disable-cart-quantity') : false;
    };
    CartShopifyComponent.prototype.disableCartRemoval = function (lineItem) {
        var product = this.products.find(function (p) { return p.id === lineItem.product_id; });
        return (product && product.tags) ? product.tags.includes('disable-cart-removal') : false;
    };
    return CartShopifyComponent;
}());
export { CartShopifyComponent };
