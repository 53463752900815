/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./quantity-select.component";
var styles_QuantitySelectComponent = [];
var RenderType_QuantitySelectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_QuantitySelectComponent, data: {} });
export { RenderType_QuantitySelectComponent as RenderType_QuantitySelectComponent };
export function View_QuantitySelectComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "quantity-select"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "value-button"], ["id", "decrease"], ["value", "Decrease Value"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.decreaseValue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["-"])), (_l()(), i0.ɵeld(3, 0, null, null, 6, "input", [["id", "number"], ["type", "number"]], [[8, "min", 0], [8, "max", 0], [8, "readOnly", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i0.ɵnov(_v, 5).onChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (i0.ɵnov(_v, 5).onChange($event.target.value) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i0.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.value = $event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(5, 16384, null, 0, i1.NumberValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i1.DefaultValueAccessor, i1.NumberValueAccessor]), i0.ɵdid(7, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(9, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [["class", "value-button"], ["id", "increase"], ["value", "Increase Value"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.increaseValue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["+"]))], function (_ck, _v) { var _co = _v.component; var currVal_10 = i0.ɵinlineInterpolate(1, "", _co.name, ""); var currVal_11 = _co.value; _ck(_v, 7, 0, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.min; var currVal_1 = _co.max; var currVal_2 = _co.readOnly; var currVal_3 = i0.ɵnov(_v, 9).ngClassUntouched; var currVal_4 = i0.ɵnov(_v, 9).ngClassTouched; var currVal_5 = i0.ɵnov(_v, 9).ngClassPristine; var currVal_6 = i0.ɵnov(_v, 9).ngClassDirty; var currVal_7 = i0.ɵnov(_v, 9).ngClassValid; var currVal_8 = i0.ɵnov(_v, 9).ngClassInvalid; var currVal_9 = i0.ɵnov(_v, 9).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_QuantitySelectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-quantity-select", [], null, null, null, View_QuantitySelectComponent_0, RenderType_QuantitySelectComponent)), i0.ɵprd(5120, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.QuantitySelectComponent]), i0.ɵdid(2, 114688, null, 0, i2.QuantitySelectComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var QuantitySelectComponentNgFactory = i0.ɵccf("app-quantity-select", i2.QuantitySelectComponent, View_QuantitySelectComponent_Host_0, { name: "name", formControlName: "formControlName", min: "min", max: "max", readOnly: "readOnly", value: "value" }, { change: "change", valueChange: "valueChange" }, []);
export { QuantitySelectComponentNgFactory as QuantitySelectComponentNgFactory };
