import { OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { EventListingService } from '../../services/event-listing.service';
import { PermitService } from '../../services/permit.service';
import { FileUploadService } from '../../shared/services/file-upload.service';
import { BaseService } from 'src/app/services/base-service';
import { Alert } from 'src/app/classes';
import { RadioOption, EventDate, CourseMap } from '../../classes';
var EventCourseMapComponent = /** @class */ (function () {
    function EventCourseMapComponent(formBuilder, eventListing, permit, upload, http, base) {
        this.formBuilder = formBuilder;
        this.eventListing = eventListing;
        this.permit = permit;
        this.upload = upload;
        this.http = http;
        this.base = base;
        this.urlForm = false;
        this.uploadForm = false;
        this.courseAlert = [];
        this.courseMapLabel = 'Choose file';
        // commented out for MBR-2992
        // @Input() subDisciplines: Array<string>;
        this.formChange = new EventEmitter();
        this.courseMapDisplayOptions = [];
    }
    Object.defineProperty(EventCourseMapComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.courseMapForm.controls; },
        enumerable: true,
        configurable: true
    });
    EventCourseMapComponent.prototype.ngOnInit = function () {
        var _this = this;
        // commented out for MBR-2992
        this.courseMapForm = this.formBuilder.group({
            courseMapDisplay: [''],
            courseMapUrl: [''],
        });
        this.courseMapDisplayOptions = [
            new RadioOption('url' + this.index + this.courseMapId, 'url', 'Enter URL'),
            new RadioOption('upload' + this.index + this.courseMapId, 'upload', 'Upload File')
        ];
        this.f.courseMapUrl.setValue(this.courseMap.courseUrl);
        this.f.courseMapDisplay.setValue(this.courseMap.courseDisplay);
        // commented out for MBR-2992
        // this.f.courseSubDisciplines.setValue(this.courseMap.courseSubdiscOptions);
        this.courseMapForm.valueChanges.subscribe(function (data) {
            console.log(data);
            _this.courseMap.courseUrl = data.courseMapUrl;
            _this.courseMap.courseDisplay = data.courseMapDisplay;
            // this.courseMap.courseSubdiscOptions = data.courseSubDisciplines;
            console.log(_this.courseMap);
            _this.switchForm(data.courseMapDisplay);
            _this.formChange.emit({ dateId: _this.eventDate.event_date_id });
        });
    };
    EventCourseMapComponent.prototype.onSubmit = function () {
    };
    EventCourseMapComponent.prototype.switchForm = function (tab) {
        if (tab === 'url') {
            this.uploadForm = false;
            this.urlForm = true;
        }
        else if (tab === 'upload') {
            this.urlForm = false;
            this.uploadForm = true;
        }
    };
    EventCourseMapComponent.prototype.selectCoursemap = function (event) {
        var _this = this;
        this.courseAlert = [];
        if (event.target.files.length > 0) {
            var image_1 = event.target.files[0];
            var fileExtn = image_1.name.split('.').pop();
            console.log(fileExtn);
            var formData = new FormData();
            formData.append('image', image_1);
            if (this.upload.serviceType.COURSE_MAP.extensions.indexOf(fileExtn.toLowerCase()) > -1) {
                this.upload.fileUpload(formData, this.eventId).subscribe(function (data) {
                    console.log(data);
                    _this.courseAlert = [
                        new Alert('success', event.target.files[0].name + ' was successfully uploaded.')
                    ];
                    _this.courseMapLabel = image_1.name; /* tslint:disable-line:no-string-literal */
                    _this.f.courseMapUrl.setValue(data['url']); /* tslint:disable-line:no-string-literal */
                }, function (err) {
                    _this.courseAlert = [
                        new Alert('warning', 'There was an error uploading your file: ' + err.message)
                    ];
                });
            }
            else {
                this.courseAlert = [
                    new Alert('warning', 'Invalid file type. Supported file extns are PDF, JPG or PNG ')
                ];
            }
        }
    };
    return EventCourseMapComponent;
}());
export { EventCourseMapComponent };
