/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../atoms/nav-link/nav-link.component.ngfactory";
import * as i2 from "../../atoms/nav-link/nav-link.component";
import * as i3 from "@angular/common";
import * as i4 from "./nav-link-group.component";
import * as i5 from "../../shared/services/global.service";
import * as i6 from "../../services/authentication.service";
var styles_NavLinkGroupComponent = [];
var RenderType_NavLinkGroupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NavLinkGroupComponent, data: {} });
export { RenderType_NavLinkGroupComponent as RenderType_NavLinkGroupComponent };
function View_NavLinkGroupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-nav-link", [["class", "mt-2 mt-lg-0 mr-5"]], null, null, null, i1.View_NavLinkComponent_0, i1.RenderType_NavLinkComponent)), i0.ɵdid(1, 114688, null, 0, i2.NavLinkComponent, [], { path: [0, "path"], name: [1, "name"] }, null)], function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.path, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_NavLinkGroupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "navbar-nav mr-auto mt-2 mt-lg-0"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavLinkGroupComponent_1)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "a", [["class", "text-decoration-none text-dark mt-3 mt-lg-0 mr-5"], ["href", "https://usacycling.org/events"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Events Search"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "a", [["class", "text-decoration-none text-dark mt-3 mt-lg-0 mr-5"], ["href", "https://www.usacycling.org/user-login"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["My Account"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", " ", " "])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "a", [["class", "text-decoration-none text-dark mt-3 mt-lg-0 mr-5"], ["style", "cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Sign Out"])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "a", [["class", "mt-3 mt-lg-0 cart-link text-decoration-none text-dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeCart() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "i", [["class", "icon icon-shopping-cart"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.links; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.firstName; var currVal_2 = _co.lastName; _ck(_v, 8, 0, currVal_1, currVal_2); }); }
export function View_NavLinkGroupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-nav-link-group", [], null, null, null, View_NavLinkGroupComponent_0, RenderType_NavLinkGroupComponent)), i0.ɵdid(1, 114688, null, 0, i4.NavLinkGroupComponent, [i5.GlobalService, i6.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavLinkGroupComponentNgFactory = i0.ɵccf("app-nav-link-group", i4.NavLinkGroupComponent, View_NavLinkGroupComponent_Host_0, { links: "links" }, {}, []);
export { NavLinkGroupComponentNgFactory as NavLinkGroupComponentNgFactory };
