import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DriverVehicleService, EventDayService } from '../../services';
import { environment } from 'src/environments/environment.prod';
import { Vehicle, DriverLicense, Event, Profile, ShopifyOrder } from 'src/app/classes';
var PreEventAddOnsComponent = /** @class */ (function () {
    function PreEventAddOnsComponent(router, driverVehicleService, eventDayService) {
        this.router = router;
        this.driverVehicleService = driverVehicleService;
        this.eventDayService = eventDayService;
        // loading = true;
        this.addOns = [];
        this.autoQuant = 0;
        this.motoQuant = 0;
        this.umbrellaQuant = 0;
        this.kidsPermitQuant = 0;
        this.ancillaryQuant = 0;
        this.practiceQuant = 0;
        this.setupTeardownQuant = 0;
        this.hasAuto = false;
        this.hasMoto = false;
        this.autoVehicles = [];
        this.motoVehicles = [];
        this.drivers = [];
        this.fetchingDriversAndVehicles = false;
        this.driversLoaded = false;
        this.vehiclesLoaded = false;
        this.addOnsLoaded = false;
        this.allowManage = false;
        this.eventDays = [];
        this.eventDaysLoaded = false;
        this.autoMotoSkus = [];
        // TODO: use this to display date details later
        // setupPracticeSkus: string[] = [];
        this.umbrellaSkus = [];
    }
    Object.defineProperty(PreEventAddOnsComponent.prototype, "isUsacAdmin", {
        get: function () { return this.userRole === 'usac_admin'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreEventAddOnsComponent.prototype, "isEventOrganizer", {
        get: function () { return this.currentUser && this.currentUser.profile_id === this.event.event_organizer; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreEventAddOnsComponent.prototype, "autoComplete", {
        get: function () { return this.hasAuto && this.autoAddOns.status === 'Complete'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreEventAddOnsComponent.prototype, "motoComplete", {
        get: function () { return this.hasMoto && this.motoAddOns.status === 'Complete'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreEventAddOnsComponent.prototype, "loaded", {
        get: function () {
            return this.addOnsLoaded && ((this.autoQuant > 0 || this.motoQuant > 0) ? (this.vehiclesLoaded && this.driversLoaded) : true);
        },
        enumerable: true,
        configurable: true
    });
    PreEventAddOnsComponent.prototype.ngOnInit = function () {
        this.assignSkus();
    };
    PreEventAddOnsComponent.prototype.ngOnChanges = function (changes) {
        this.allowManage = this.isUsacAdmin || this.isEventOrganizer;
        if (changes.orders) {
            if (!this.eventDaysLoaded) {
                this.getEventDays();
                // } else {
                //   this.searchAddOnLineItems();
            }
        }
    };
    PreEventAddOnsComponent.prototype.assignSkus = function () {
        var disc = this.event.event_discipline;
        // Auto & Moto Liability
        this.autoSku = environment.disciplineSkus[disc].autoInsurance;
        this.motoSku = environment.disciplineSkus[disc].motoInsurance;
        this.autoMotoSkus.push(this.autoSku);
        this.autoMotoSkus.push(this.motoSku);
        // Umbrella Insurance
        this.umbrellaSkus.push(environment.disciplineSkus[disc].umbrella99);
        this.umbrellaSkus.push(environment.disciplineSkus[disc].umbrella249);
        this.umbrellaSkus.push(environment.disciplineSkus[disc].umbrella999);
        this.umbrellaSkus.push(environment.disciplineSkus[disc].umbrella4999);
        this.umbrellaSkus.push(environment.disciplineSkus[disc].umbrella5000);
        this.umbrellaSkus.push(environment.disciplineSkus[disc].umbrella1000);
        // Ancillary Insurance
        this.ancillarySku = environment.disciplineSkus[disc].ancillaryInsurance;
        // Kids Permit
        this.kidsPermitSku = environment.disciplineSkus[disc].kidsPermit;
        // Setup/Teardown Days
        this.setupTeardownSku = environment.disciplineSkus[disc].setupTeardownDays;
        // Practice Days
        this.practiceSku = environment.disciplineSkus[disc].practiceDays;
        if (this.orders) {
            this.searchAddOnLineItems();
        }
    };
    PreEventAddOnsComponent.prototype.getEventDays = function () {
        var _this = this;
        this.eventDayService.getEventDaysForEvent(this.event.event_id).subscribe(function (days) {
            _this.eventDays = days;
            _this.eventDaysLoaded = true;
            _this.searchAddOnLineItems();
        });
    };
    PreEventAddOnsComponent.prototype.searchAddOnLineItems = function () {
        var _this = this;
        this.addOns = [];
        this.resetQuantities();
        this.orders.forEach(function (order) {
            order.line_items.forEach(function (item) {
                var datePurchased = order.created_at;
                // Auto & Moto Liability
                if (_this.autoMotoSkus.includes(item.sku)) {
                    if (item.sku === _this.autoSku) {
                        _this.autoQuant += item.quantity;
                        _this.autoAddOns = Object.assign({}, {
                            title: item.title,
                            src: '../assets/images/car.svg',
                            quantity: _this.autoQuant,
                            date_purchased: datePurchased,
                            type: 'auto',
                            selected_dates: []
                        });
                        _this.hasAuto = true;
                    }
                    else {
                        _this.motoQuant += item.quantity;
                        _this.motoAddOns = Object.assign({}, {
                            title: item.title,
                            src: '../assets/images/moto.svg',
                            quantity: _this.motoQuant,
                            date_purchased: datePurchased,
                            type: 'moto',
                            selected_dates: []
                        });
                        _this.hasMoto = true;
                    }
                    // Umbrella Insurance
                }
                else if (_this.umbrellaSkus.includes(item.sku)) {
                    _this.umbrellaQuant += item.quantity;
                    _this.umbrellaAddOns = Object.assign({}, {
                        title: item.title,
                        src: '../assets/images/umbrella.svg',
                        quantity: _this.umbrellaQuant,
                        date_purchased: datePurchased,
                        selected_dates: []
                    });
                    // Ancillary Insurance
                }
                else if (_this.ancillarySku === item.sku) {
                    _this.ancillaryQuant += item.quantity;
                    _this.ancillaryAddOns = Object.assign({}, {
                        title: item.title,
                        src: '../assets/images/ancillary.svg',
                        quantity: _this.ancillaryQuant,
                        date_purchased: datePurchased,
                        selected_dates: []
                    });
                    // Kids Permit
                }
                else if (_this.kidsPermitSku === item.sku) {
                    _this.kidsPermitQuant += item.quantity;
                    _this.kidsPermitAddOns = Object.assign({}, {
                        title: item.title,
                        src: '../assets/images/child.svg',
                        quantity: _this.kidsPermitQuant,
                        date_purchased: datePurchased,
                        selected_dates: []
                    });
                    // Practice Days
                }
                else if (_this.practiceSku === item.sku) {
                    _this.practiceQuant += item.quantity;
                    _this.practiceAddOns = Object.assign({}, {
                        title: item.title,
                        src: '../assets/images/calendar-day.svg',
                        quantity: _this.practiceQuant,
                        date_purchased: datePurchased,
                        selected_dates: _this.eventDays
                    });
                    // Setup/Teardown Days
                }
                else if (_this.setupTeardownSku === item.sku) {
                    _this.setupTeardownQuant += item.quantity;
                    _this.setupTeardownAddOns = Object.assign({}, {
                        title: item.title,
                        src: '../assets/images/calendar-day.svg',
                        quantity: _this.setupTeardownQuant,
                        date_purchased: datePurchased,
                        selected_dates: _this.eventDays
                    });
                }
            });
        });
        if (this.autoQuant > 0) {
            this.addOns.push(this.autoAddOns);
        }
        if (this.motoQuant > 0) {
            this.addOns.push(this.motoAddOns);
        }
        if (this.umbrellaQuant > 0) {
            this.addOns.push(this.umbrellaAddOns);
        }
        if (this.kidsPermitQuant > 0) {
            this.addOns.push(this.kidsPermitAddOns);
        }
        if (this.ancillaryQuant > 0) {
            this.addOns.push(this.ancillaryAddOns);
        }
        if (this.setupTeardownQuant > 0) {
            this.addOns.push(this.setupTeardownAddOns);
        }
        if (this.practiceQuant > 0) {
            this.addOns.push(this.practiceAddOns);
        }
        if ((this.autoQuant > 0 || this.motoQuant > 0) && !this.fetchingDriversAndVehicles) {
            this.fetchingDriversAndVehicles = true;
            this.driverVehicleService.getVehicles(this.event.event_permit_id).subscribe(function (vehicles) {
                _this.autoVehicles = vehicles.filter(function (vehicle) { return vehicle.vehicle_type === 'auto'; });
                _this.motoVehicles = vehicles.filter(function (vehicle) { return vehicle.vehicle_type === 'moto'; });
                _this.vehiclesLoaded = true;
            });
            this.driverVehicleService.getDrivers(this.event.event_permit_id).subscribe(function (drivers) {
                _this.drivers = drivers;
                _this.driversLoaded = true;
            });
        }
        this.addOnsLoaded = true;
    };
    PreEventAddOnsComponent.prototype.onQuantityChange = function (event) {
        switch (event.type) {
            case 'auto':
                this.autoAddOns.status = event.status;
                break;
            case 'moto': this.motoAddOns.status = event.status;
        }
    };
    PreEventAddOnsComponent.prototype.resetQuantities = function () {
        this.autoQuant = 0;
        this.motoQuant = 0;
        this.umbrellaQuant = 0;
        this.kidsPermitQuant = 0;
        this.ancillaryQuant = 0;
        this.practiceQuant = 0;
        this.setupTeardownQuant = 0;
    };
    PreEventAddOnsComponent.prototype.toAddOns = function () {
        this.router.navigate(["event/" + this.event.event_id + "/add-ons"]);
    };
    return PreEventAddOnsComponent;
}());
export { PreEventAddOnsComponent };
