import { Injectable } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import qs from 'qs';
import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected cookie: CookieService
  ) {
    super(http, cookie);
  }

  generateQrCode(text: string, options = {}): Observable<any> {
    const data = { text, options };
    return this.http.post<any>(this.url('qr/generate'), data, this.options)
      .pipe(
        tap(_ => this.log('generated QR code')),
        catchError(this.handleError<any>('generateQrCode', {}))
      );
  }
}
