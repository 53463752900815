import { Component, OnInit, OnChanges, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CalendarLabel,
  CalendarLabelAnswer,
  EventDate,
  SubDiscipline,
  PostEvent,
  ShopifyOrder,
  ShopifyDraftOrder,
  Event,
  CalendarLabelQuestion
} from 'src/app/classes';
import { EventService } from '../../services';

@Component({
  selector: 'app-post-event-form-table',
  templateUrl: './post-event-form-table.component.html',
  styles: []
})
export class PostEventFormTableComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() instructions: string;
  @Input() isSeries: boolean;
  @Input() event: Event;
  @Input() postEvent: PostEvent;
  @Input() eventDate: EventDate;
  @Input() eventDates: EventDate[];
  @Input() discipline: number;
  @Input() section: string;
  @Input() formGroup: any;
  // commented out for MBR-2992
  // @Input() subDisciplines: SubDiscipline[];
  @Input() groupedControls: any;
  @Input() minQuantity: number;
  @Input() maxQuantity: number;
  @Input() submitted: boolean;
  @Input() variants: any;
  @Input() variantTotals: any;
  @Input() excludeFromCharges: string[];
  @Input() userRole: string;
  @Input() riderSurchargeTotal = 0;
  @Input() licenseTotal = 0;
  @Input() membershipTotal = 0;
  @Input() draftOrder: ShopifyDraftOrder;
  @Input() orders: ShopifyOrder[];
  @Input() lateFee = true;
  @Input() minimumRiderSurchargeApplied: boolean;
  @Input() eventIncentivesDiscountAmt: number;
  @Input() lateFeePrice = 0;
  @Input() calendarLabels: CalendarLabel[];
  @Input() calendarLabelQuestions: CalendarLabelQuestion[];
  @Input() calendarLabelAnswers: CalendarLabelAnswer[];
  @Input() updating: boolean;


  @Output() feeFormChange = new EventEmitter<any>();
  @Output() formSubmit = new EventEmitter<any>();
  @Output() eventIncentiveDiscountQualification = new EventEmitter<boolean>();

  disciplineName: string;
  headers = {
    participants: [
      { text: 'Date', align: 'left' },
      // commented out for MBR-2992
      // { text: 'Sub-Discipline', align: 'left' },
      { text: 'Pre-Registered', align: 'center' },
      { text: 'On-Site Registrations', align: 'center' },
      { text: 'Total Registrations', align: 'left' }
    ],
    riders: [
      { text: 'Date', align: 'left' },
      // commented out for MBR-2992
      // { text: 'Sub-Discipline', align: 'left' },
      { text: 'Unique Riders', align: 'center' },
      { text: 'Cost', align: 'right' },
      { text: 'Total', align: 'right' }
    ],
    licensesMemberships: [
      { text: 'Item', align: 'left' },
      { text: 'Quantity', align: 'center' },
      { text: 'Cost', align: 'right' },
      { text: 'Total', align: 'right' }
    ],
    fees: [
      { text: 'Fees', align: 'left' },
      { text: '', align: 'left'},
      { text: 'To Pay', align: 'right' }
    ]
  };
  hideDate = false;
  feesFormRows = ['Rider Surcharge', 'One Day Licenses', 'Memberships', 'Late Fee', '', '', '', '', '', '', ''];
  totalsRowsAdmin = ['Order Discount', 'Note', 'Other Discounts', 'Subtotal', 'Tax', 'Amount Paid', 'Total Due'];
  totalsRowsNonAdmin = ['Order Discount', 'Other Discounts', 'Subtotal', 'Tax', 'Amount Paid', 'Total Due'];

  feeTotalsForm: FormGroup;
  requireNote = false;

  lateFeeAmt = 0;
  preDiscount = 0;
  subtotal = 0;
  totalDue = 0;

  get ftf() { return this.feeTotalsForm.controls; }

  constructor(
    private formBuilder: FormBuilder,
    private eventService: EventService
  ) { }

  ngOnInit() {
    this.disciplineName = this.discipline && this.eventService.getDisciplineName(this.discipline);
    this.hideDate = this.isSeries && (this.participants() || this.riders());
    if (this.fees()) {
      this.buildFeeTotalsForm();
      this.assignPostEventToForm();
      this.calculateTotalDue();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.fees()) {
      this.calculateTotalDue();
    }
  }

  assignPostEventToForm() {
    // Assign post event late fee, discount, & order note values
    Object.keys(this.ftf).forEach(key => {
      if (Object.keys(this.postEvent).includes(key)) {
        const val = key === 'post_event_discount' ? this.postEvent[key] || 0 : this.postEvent[key];
        this.ftf[key].setValue(val);
      }
    });
    // Assign discount & order note values from Shopify, if present
    if (this.draftOrder) {
      if (this.draftOrder.applied_discount) {
        this.ftf.post_event_discount.setValue(this.draftOrder.applied_discount.amount);
      }
      if (this.draftOrder.note) {
        this.ftf.post_event_order_note.setValue(this.draftOrder.note);
      }
    }

    this.ftf.post_event_late_fee.setValue(this.lateFee);
  }

  buildFeeTotalsForm() {
    this.feeTotalsForm = this.formBuilder.group({
      post_event_late_fee_dismissed: [false],
      post_event_late_fee: [null],
      post_event_discount: [0],
      post_event_order_note: ['']
    });
    /* Left line below commented-out in case note ever needs to be required again */
    // this.feeTotalsForm.get('post_event_discount').valueChanges.subscribe(value => this.onDiscountChange(value));
    this.feeTotalsForm.get('post_event_late_fee_dismissed').valueChanges.subscribe(value => this.onDismissChange(value));
    this.feeTotalsForm.valueChanges.subscribe(data => this.feeFormChange.emit(this.feeTotalsForm));
  }

  onDiscountQualificationChange($event: boolean): void {
    this.eventIncentiveDiscountQualification.emit($event);
  }

  /* Left lines below commented-out in case note ever needs to be required again */
  // onDiscountChange(value: string) {
  //   if (typeof value === 'string' && value.startsWith('0') && value.length > 1 && value[1] !== '.') {
  //     this.ftf.post_event_discount.setValue(value.substring(1));
  //   } else {
  //     const amount = parseFloat(value);
  //     this.requireNote = amount > 0;
  //     const validator = amount > 0 ? Validators.required : null;
  //     this.ftf.post_event_order_note.setValidators(validator);

  //     if (amount > this.preDiscount) {
  //       this.ftf.post_event_discount.setValue(this.preDiscount.toString());
  //     } else if (amount < 0) {
  //       this.ftf.post_event_discount.setValue('0');
  //     }

  //     // this.ftf.post_event_order_note.updateValueAndValidity();
  //     this.calculateTotalDue();
  //   }
  // }

  onDismissChange(value: boolean) {
    this.lateFeeAmt = value ? 0 : this.lateFeePrice;
    this.calculateTotalDue();
  }

  get totalPaid(): number {
    let total = 0;
    this.orders.forEach(order => total += parseFloat(order.total_price));
    return total;
  }

  parseIfString(amount: number|string) {
    return typeof amount === 'string' ? parseFloat(amount) : amount;
  }

  calculateTotalDue() {
    this.preDiscount = (
      this.parseIfString(this.riderSurchargeTotal) + this.parseIfString(this.licenseTotal)
      + this.parseIfString(this.membershipTotal) + this.parseIfString(this.lateFeeAmt)
    );
    const discount = this.feeTotalsForm ? parseFloat(this.ftf.post_event_discount.value) : 0;
    this.subtotal = discount ? this.preDiscount - discount : this.preDiscount;
    this.totalDue = this.total - this.totalPaid;
  }

  participants() {
    return this.section === 'participants';
  }

  riders() {
    return this.section === 'riders';
  }

  licensesMemberships() {
    return this.section === 'licensesMemberships';
  }

  fees() {
    return this.section === 'fees';
  }

  get isUsacAdmin() {
    return this.userRole === 'usac_admin';
  }

  get isRaceOrRide(): boolean {
    return this.event.isRaceOrRide;
  }

  get selectHeaders() {
    return this.hideDate ? this.headers[this.section].slice(1) : this.headers[this.section];
  }

  get totalsRows() {
    return this.isUsacAdmin ? this.totalsRowsAdmin : this.totalsRowsNonAdmin;
  }

  feesRow(index: number, label: string) {
    return this.feesFormRows[index] === label;
  }

  totalsRow(index: number, label: string) {
    return this.totalsRows[index - 4] === label;
  }

  feesRowValue(index: number) {
    switch (this.feesFormRows[index]) {
      case 'Rider Surcharge': return this.riderSurchargeTotal; break;
      case 'One Day Licenses': return this.licenseTotal; break;
      case 'Memberships': return this.membershipTotal; break;
      case 'Late Fee': return this.lateFeeAmt; break;
      default: return null;
    }
  }

  totalsRowValue(index: number) {
    switch (this.totalsRows[index - 4]) {
      case 'Subtotal': return this.subtotal; break;
      case 'Tax': return this.tax; break;
      case 'Amount Paid': return this.totalPaid; break;
      case 'Total Due': return this.totalDue; break;
      default: return null;
    }
  }

  get lineItemDiscounts() {
    const discounts = [];
    if (this.draftOrder) {
      this.draftOrder.line_items.forEach(item => {
        if (item.applied_discount) { discounts.push({
          description: this.discountDescription(item.applied_discount),
          amount: item.applied_discount.amount
        }); }
      });
    }
    return discounts;
  }

  discountDescription(discount): string {
    return discount.title ? `${discount.title} - ${discount.description}` : discount.description;
  }

  get displayDiscount() {
    return this.isUsacAdmin || this.ftf.post_event_discount.value > 0;
  }

  get tax() {
    return this.draftOrder ? this.draftOrder.total_tax : 0;
  }

  get total() {
    return this.draftOrder ? this.draftOrder.total_price : this.subtotal + this.tax;
  }

  col(header: any, title: string) {
    return header.text === title;
  }

  alignByTitle(title: string) {
    return `text-${this.headers[this.section].find(h => h.text === title).align}`;
  }

  alignByIndex(index: number) {
    return `text-${this.headers[this.section][index].align}`;
  }

  eventDateStart(name: string) {
    const date = this.isSeries ? this.eventDate : this.eventDateFromControl(name);
    return date.event_date_start;
  }

  riderCurrencyCol(name: string) {
    return name.includes('cost') || name.includes('fee');
  }

  // commented out for MBR-2992
  // subDisciplineName(name: string) {
  //   let subDisc: SubDiscipline;
  //   if (this.isSeries) {
  //     subDisc = this.subDisciplineFromControl(name, this.subDisciplines);
  //   } else {
  //     subDisc = this.subDisciplineFromControl(name, this.eventDateFromControl(name).event_date_sub_disciplines);
  //   }
  //   return subDisc.subdiscipline_name;
  // }

  eventDateFromControl(name: string) {
    const id = parseInt(name.split('_')[2]);
    return this.eventDates.find(eventDate => eventDate.event_date_id === id);
  }

  // commented out for MBR-2992
  // subDisciplineFromControl(name: string, subDisciplines: SubDiscipline[]) {
  //   const id = parseInt(name.split('_')[4]);
  //   return subDisciplines.find(subDisc => subDisc.subdiscipline_id === id);
  // }

  showParticipantInput(key: string) {
    return key.includes('registered');
  }

  licenseMembershipTitle(key: string): string {
    switch (key) {
      case 'post_event_one_day_on_site': return 'One Day License: On-Site';
      case 'post_event_one_day_online': return 'One Day License: Online Bike Reg';
      // TODO: Consider pulling title from Shopify membership products?
      // ^^ One day license titles should remain static, as the requested form titles do not exactly match the product titles
      case 'post_event_membership_premium': return 'Post Event Race Membership';
      case 'post_event_membership_collegiate': return 'Post Event Race Membership: Collegiate';
      case 'post_event_membership_junior': return 'Post Event Race Membership: Junior';
    }
  }

  chargable(key: string): boolean {
    return !this.excludeFromCharges.includes(key);
  }

  variantPrice(key: string) {
    return this.variants[key].price;
  }

  variantTotal(key: string) {
    return Object.keys(this.variantTotals).includes(key) ? this.variantTotals[key] : 0;
  }

  sortNull() {}

}
