import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { PermitService, EventListingService } from 'src/app/services';
import { RouteHistoryService } from 'src/app/shared';

import {
  CheckboxOption,
  RadioOption
} from '../../classes';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-permit-part1-form',
  templateUrl: './permit-part1-form.component.html',
  styleUrls: []
})
export class PermitPart1FormComponent implements OnInit, OnDestroy {
  permitPart1Form: FormGroup;
  submitted = false;
  contactName = '';
  contactEmail = '';
  contactPhone: number;
  medicalFacilities: any;
  medicalFacilitiesOptions: CheckboxOption[] = [];
  // commented out for MBR-2992
  // subDisciplineOptions = [];
  medicalStaff: FormArray;
  timingInfoOptions: RadioOption[] = [];
  communicationTypesOptions: CheckboxOption[] = [];
  label: string;
  timingOptions: CheckboxOption[] = [];
  stagingAreaOptions: RadioOption[] = [];
  // payOutOptions: RadioOption[] = [];
  submitModalOpen = false;
  showSaveExit = false;
  routerEvents: Subscription;
  navUrl = '';
  disableSaveAndExitCatch = false;

  @Input() eventId: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private routeHistory: RouteHistoryService,
    private cookie: CookieService,
    public permit: PermitService,
    public eventListing: EventListingService
  ) { }

  // convenience getter for easy access to form fields
  get f() { return this.permitPart1Form.controls; }

  ngOnInit() {
    // // this.eventListing.fetchEventData(this.eventId);
    // this.permit.getQuestionConfig(this.eventListing.event_discipline);
    this.permitPart1Form = this.formBuilder.group({
      // comment this code for legal issues
      // contactName: [ '', nameCharactersValidator() ],
      // contactEmail: [ '', emailValidator() ],
      // contactPhone: [ '', phoneNumberValidator()  ],
      // medicalFacilities: [ '' ],
      // medicalStaff: this.formBuilder.array([new FormControl()]),
      communicationTypes: [ '', Validators.required ],

      registrationProvider: [ '', Validators.required ],
      timingProvider: [ '', Validators.required ],
      timingInformation: [ '', Validators.required ],
      timings: [''],
      stagingArea: [ '', Validators.required ],
      // payOut: ['', Validators.required],
      maxPayOut: ['', Validators.required]
    });

    // tslint:disable-next-line: forin
    for (const option in this.permit.timingOptionsList) {
      this.timingOptions.push(new CheckboxOption(option, option, option, this.permit.timingOptionsList[option]));
    }

    // tslint:disable-next-line: forin
    // for (const option in this.permit.medicalFacilitiesOptionsList) {
    //   this.medicalFacilitiesOptions.push(new CheckboxOption(option, option, option, this.permit.medicalFacilitiesOptionsList[option]));
    //   // console.log(`${option}: ${medicalFacilitiesOptionsList[option]}`);
    // }

    // commented out for MBR-2992
    // this.subDisciplineOptions = this.eventListing.getSelectedSubDisciplines();

    // tslint:disable-next-line: forin
    for (const option in this.permit.communicationTypesOptionsList) {
      this.communicationTypesOptions.push(new CheckboxOption(option, option, option, this.permit.communicationTypesOptionsList[option]));
    }

    this.timingInfoOptions = [
      new RadioOption('timing-yes', 'yes', 'Yes'),
      new RadioOption('timing-no', 'no', 'No')
    ];

    this.stagingAreaOptions = [
      new RadioOption('staging-yes', 'yes', 'Yes'),
      new RadioOption('staging-no', 'no', 'No')
    ];

    // this.payOutOptions = [
    //   new RadioOption('payout-yes', 'yes', 'Yes'),
    //   new RadioOption('payout-no', 'no', 'No')
    // ];

    // this.f.contactName.setValue(this.permit.contactName);
    // this.f.contactEmail.setValue(this.permit.contactEmail);
    // this.f.contactPhone.setValue(this.permit.contactPhone);
    // this.f.medicalFacilities.setValue(this.permit.medicalFacilities);
    this.f.timingInformation.setValue(this.permit.timingInformation);
    this.f.timingProvider.setValue(this.permit.timingProvider);
    this.f.registrationProvider.setValue(this.permit.registrationProvider);
    this.f.communicationTypes.setValue(this.permit.communicationTypes);
    this.f.stagingArea.setValue(this.permit.stageArea);
    // this.f.payOut.setValue(this.permit.payOut);
    this.f.maxPayOut.setValue(this.permit.maxPayOut);
    this.f.timings.setValue(this.permit.timings);
    // if (this.permit.medicalStaffName.length > 0) {
    //   this.permitPart1Form.setControl('medicalStaff', this.formBuilder.array(this.permit.medicalStaffName));
    // }
    this.permitPart1Form.valueChanges.subscribe(data => {
      // this.permit.contactName = data.contactName;
      // this.permit.contactEmail = data.contactEmail;
      // this.permit.contactPhone = data.contactPhone;
      // this.permit.medicalFacilities = data.medicalFacilities;
      this.permit.timingProvider = data.timingProvider;
      this.permit.registrationProvider = data.registrationProvider;
      this.permit.maxPayOut = data.maxPayOut;
    });

    // this.permitPart1Form.controls.medicalStaff.valueChanges.subscribe(data => {
    //   this.permit.medicalStaffName = data;
    // });

    this.routerEvents = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const routeExceptions = [`/event/${this.eventId}/permit-application/part-1`, `/event/${this.eventId}/permit-application/part-2`];
        if (!routeExceptions.includes(event.url) && this.navUrl === '') {
          if (!this.disableSaveAndExitCatch) {
            this.router.navigateByUrl(this.router.url, {replaceUrl: true});
            this.openSaveExitModal(event.url);
          }
        }
      }
    });

    // console.log(this.permitPart1Form.value);
    this.setDisciplineValidators();
  }

  ngOnDestroy() {
    this.routerEvents.unsubscribe();
    this.closeSaveExitModal(false);
  }

  debug(event: any): void {
    console.log('debug form', this.f, event);
  }

  submitPermit(navUrl?: string): void {
    this.permit.createPermit(this.eventId, 'part1', navUrl);
  }

  onSubmit(event: any) {
    this.submitted = true;

    // console.log('submitting part 1', event, this.permitPart1Form);

    if (this.permitPart1Form.invalid) {
      console.log('medical info form is invalid!');
      return;
    }

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.submitPermit();
  }

  // addStaffName() {
  //     this.medicalStaff = this.permitPart1Form.get('medicalStaff') as FormArray;
  //     this.medicalStaff.push(new FormControl());
  // }

  // removeStaffName(Index: number) {
  // (this.permitPart1Form.get('medicalStaff') as FormArray).removeAt(Index);
  // }

  // updateFacilities($event: Array<string>) {
  //   this.permit.medicalFacilities = $event;

  // }
  // get Controls() {
  //   return (this.permitPart1Form.get('medicalStaff') as FormArray).controls;
  // }

  updateCommunicationTypes($event: Array<string>) {
    this.permit.communicationTypes =  $event;
  }

  updateTimingInfo($event: string) {
    this.permit.timingInformation = $event;
  }

  // updatePayOutInfo($event: string) {
  //   this.permit.payOut = $event;
  // }

  unrequireTiming() {
    return (!this.eventListing.isEventCompetitive || this.eventListing.isBmx || !this.eventListing.isGranFondo);
  }

  competitiveNotBmx() {
    return (this.eventListing.isEventCompetitive && !this.eventListing.isBmx);
  }

  requireStagingArea() {
    // TODO: Replace cd_id values with discipline names (once retrieving from the API instead of listing service).
    // Only for 'Grand Fondo' & 'Gravel' disciplines
    return (this.eventListing.event_discipline === '24' || this.eventListing.event_discipline === '25');
  }

  updateStagingArea($event: string) {
    this.permit.stageArea = $event;
  }

  updateTimings($event: Array<string>) {
    this.permit.timings = $event;
  }

  openSaveExitModal(url?: string) {
    if (url) { this.navUrl = url; }
    this.showSaveExit = true;
  }

  closeSaveExitModal(resetNavUrl: boolean) {
    this.showSaveExit = false;
    if (!!resetNavUrl) { this.navUrl = ''; }
  }

  toggleModal1(event) {
    this.submitModalOpen = !this.submitModalOpen;
  }

  closeSubmitModal(event) {
    this.submitModalOpen = false;
  }

  continueNavigation(url = this.navUrl) {
    this.closeSaveExitModal(false);
    setTimeout(() => {
      this.navUrl = url === '' ? `/event/${this.eventId}/permit-application/part-2` : this.navUrl;
      this.router.navigate([this.navUrl]);
    }, 50);
  }

  toOverview() {
    const previous = this.routeHistory.previousUrl;
    const url = `event/${this.eventId}/overview`;
    previous === '/' ? window.location.replace(url) : this.router.navigate([url]);
  }

  backNavText() {
    return this.cookie.check('event_id') ? 'Back to Event Overview' : 'Back to Event Queue';
  }

  postChanges() {
// post changes to API call
    this.submitModalOpen = false;
  }

  setDisciplineValidators() {
    const timingInfo = this.permitPart1Form.get('timingInformation');
    const provider  = this.permitPart1Form.get('timingProvider');
    const regprovider = this.permitPart1Form.get('registrationProvider');
    const area = this.permitPart1Form.get('stagingArea');
    // const payOut = this.permitPart1Form.get('payOut');
    const maxPayOut = this.permitPart1Form.get('maxPayOut');
    if (this.unrequireTiming()) {
      timingInfo.setValidators(null);
      provider.setValidators(null);
    }

    this.permitPart1Form.get('timingInformation').valueChanges.subscribe(info => {
      if (info === 'no') {
        provider.setValidators(null);
      } else if (info === 'yes') {
        provider.setValidators([Validators.required]);
      }
      provider.updateValueAndValidity();
    });

    if (!this.competitiveNotBmx()) {
      regprovider.setValidators(null);
      // payOut.setValidators(null);
      maxPayOut.setValidators(null);
    }
    if (!this.requireStagingArea()) {
      area.setValidators(null);
    }

    timingInfo.updateValueAndValidity();
    provider.updateValueAndValidity();
    regprovider.updateValueAndValidity();
    area.updateValueAndValidity();
  }

  saveAndExit() {
    this.navUrl = this.navUrl === '' ? '/queue' : this.navUrl;
    setTimeout(() => {
      this.disableSaveAndExitCatch = true;
      this.submitPermit(this.navUrl);
      this.closeSaveExitModal(false);
    }, 50);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      return false;
    }
    return true;
  }
}
