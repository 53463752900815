<ngb-accordion #acc="ngbAccordion" [activeIds]="isOpen">
  <ngb-panel class="certificate-header" id="ngb-panel-0">
    <ng-template ngbPanelHeader let-opened="opened">
      <div class="align-items-center">
        <div class="row">
          <div class="col-sm-6 col-lg-3 align-self-center">
            <h5 class="m-0 strong">{{title}}</h5>
          </div>
          <div class="col-sm-6 col-lg-3 align-self-center">
            <p class="m-0">Name: {{certificate.name}}</p>
          </div>
          <div class="col-sm-8 col-lg-4 align-self-center">
            <p class="m-0">
              <span *ngIf="certificate.hasEndorsement">Includes Endorsement: </span>
              <span>${{ formPrice | number }}</span>
            </p>
          </div>
          <div class="col-12 col-sm-4 col-lg-2 text-right">
            <button class="btn icon-btn" (click)="togglePanel()" [disabled]="!saved"><i class="icon icon-edit"></i></button>
            <button *ngIf="!includedCert && certificateId >= 0" class="btn icon-btn" (click)="removeFromCart()" ><i class="icon icon-trash"></i></button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <form [formGroup]="insuredPersonForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-sm">
            <app-text-input
              formControlName="name"
              [type]="'text'"
              [name]="'name'"
              id="name-{{certificateId}}"
              [label]="'Name'"
              [placeholder]=""
              [formSubmitted]="submitted"
              [errors]="f.name.errors"
              [required]=true
              [disabled]="includedCert"
            ></app-text-input>
          </div>
          <div class="col-sm">
            <app-select
              formControlName="relationship"
              [label]="'Relationship'"
              [options]="relationshipOptions"
              [name]="relationship"
              id="relationship-{{certificateId}}"
              [formSubmitted]="submitted"
              [errors]="f.relationship.errors"
              [required]=true
              [disabled]="includedCert"
              (change)="onRelationshipChange()"
            ></app-select>
          </div>
          <div class="col-md" *ngIf="displaycustom_relationship">
            <app-text-input
              formControlName="custom_relationship"
              [label]="'Other Relationship'"
              [name]="custom_relationship"
              [formSubmitted]="submitted"
              [errors]="f.custom_relationship.errors"
              [required]=true
            ></app-text-input>
          </div>
        </div>
        <h4>Location</h4>
        <app-text-input
          formControlName="street"
          [type]="'text'"
          [name]="'street'"
          id="street-{{certificateId}}"
          [label]="'Street Address'"
          appSmartAddressSearch
          (addressChange)="setAddress($event)"
          [placeholder]="''"
          [formSubmitted]="submitted"
          [errors]="f.street.errors"
          [required]=true
          [disabled]="disableAddress('street')"
        ></app-text-input>
        <div class="row">
          <div class="col-sm-12">
            <app-text-input formControlName="unit"
              [type]="'text'"
              [name]="'unit'"
              id="unit-{{certificateId}}"
              [label]="'Apt/Suite/Unit'"
              [placeholder]="''"
              [formSubmitted]="submitted"
              [errors] = "f.unit.errors"
              [disabled]="disableAddress('street')"
            ></app-text-input>
            <!-- Disable unit if street already present (sponsoring clubs only) -->
          </div>
          <div class="col-sm-6">
            <app-text-input
              formControlName="city"
              [type]="'text'"
              [name]="'city'"
              id="city-{{certificateId}}"
              [label]="'City'"
              [value]="'city'"
              [placeholder]="''"
              [formSubmitted]="submitted"
              [errors]="f.city.errors"
              [required]=true
              [disabled]="disableAddress('city')"
            ></app-text-input>
          </div>
          <div class="col-sm-2">
            <app-select formControlName="state"
              [name]="'state'"
              id="state-{{certificateId}}"
              [label]="'State'"
              [options]="stateOptions"
              [formSubmitted]="submitted"
              [errors]="f.state.errors"
              [required]="true"
              [disabled]="disableAddress('state')"
            ></app-select>
          </div>
          <div class="col-sm-4">
            <app-text-input
              formControlName="zip"
              [type]="'text'"
              [name]="'zip'"
              id="zip-{{certificateId}}"
              [label]="'Zip'"
              [placeholder]="''"
              [formSubmitted]="submitted"
              [errors]="f.zip.errors"
              [required]=true
              [disabled]="disableAddress('zip')"
            ></app-text-input>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <app-text-input
              formControlName="email"
              [type]="'email'"
              [name]="'email'"
              id="email-{{certificateId}}"
              [label]="'Contact Email'"
              [placeholder]=""
              [formSubmitted]="submitted"
              [errors]="f.email.errors"
              [disabled]="includedCert"
            ></app-text-input>
          </div>
          <div class="col-sm-6">
            <app-text-input
              formControlName="phone"
              [type]="'tel'"
              [name]="'phone'"
              id="phone-{{certificateId}}"
              [label]="'Contact Phone'"
              [placeholder]=""
              [formSubmitted]="submitted"
              [errors]="f.phone.errors"
              [disabled]="includedCert"
            ></app-text-input>
          </div>
        </div>
        <app-text-input
          formControlName="insured_info"
          [name]="'insured_info'"
          id="insured-info-{{certificateId}}"
          [label]="'Specific Verbiage'"
          [placeholder]="''"
          [formSubmitted]="submitted"
          [errors]="f.insured_info.errors"
        ></app-text-input>
        <div *ngIf="!isAdmin">
          <p>
            Endorsements 
            <span class="icon icon-information" [ngbTooltip]="tipContent">
              <ng-template #tipContent>An insurance endorsement is an amendment or addition to an existing insurance contract
                that changes the terms or scope of the original policy. You should only purchase this
                if it is required by a contract that you engage in with an entity to host an event, and
                that contract should be reviewed by our broker before you enter into the agreement.</ng-template>
            </span>
          </p>
          <p class="font-italic">For inquiries regarding specialized endorsements, please feel free to contact us at <a href="mailto:eventservices@usacycling.org">eventservices@usacycling.org.</a></p>
        </div>
        <app-multi-select *ngIf="isAdmin"
          formControlName="endorsement"
          [name]="'endorsement'"
          [label]="'Endorsements'"
          id="endorsements-{{certificateId}}"
          [options]="endorsementOptions"
          [formSubmitted]="submitted"
          [errors]="f.endorsement.errors"
          [icon]=true
          [iconClass]="'icon-information'"
          tooltipContent="
          An insurance endorsement is an amendment or addition to an existing insurance contract
          that changes the terms or scope of the original policy.  You should only purchase this
          if it is required by a contract that you engage in with an entity to host an event, and
          that contract should be reviewed by our broker before you enter into the agreement.
          "
        ></app-multi-select>
        <div class="row">
          <div class="col-sm-6 text-left">
            <app-button *ngIf="!includedCert" [mode]="'button'" [type]="'btn-light'" (click)="cancel()">Cancel</app-button>
          </div>
          <div class="col-sm-6 text-right" >
            <app-button  [type]="'btn-secondary'">Save</app-button>
          </div>
        </div>
      </form>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
