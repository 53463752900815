import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvDownloadService {

  private error = false;

  get displayError() { return this.error; }
  set displayError(val: boolean) { this.error = val; }

  constructor() { }

  downloadFile(data: any, filename?: string) {
    if (!Array.isArray(data) || data.length === 0) {
      console.error('Invalid data passed to CsvDownloadService! Expected array with length > 0, received:', data);
      this.displayError = true;
      return;
    }

    this.displayError = false;

    const header = Object.keys(data[0]);
    const csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], this.replacer)).join(','));

    csv.unshift(header.join(','));

    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    let downloadName = 'USACYCLING-Permitting.csv';

    if (filename) {
      downloadName = filename;
    }

    if (!/\.csv$/i.test(downloadName)) {
      downloadName += '.csv';
    }

    a.href = url;
    a.download = downloadName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  replacer(key, value) {
    if (value === null) {
      return 'NA';
    } else if (Array.isArray(value)) {
      if (key === 'event_types') {
        return value.map(v => v.et_name).join(' | ');
      } else {
        return `${value.length} (count)`;
      }
    } else if (typeof value === 'object') {
      return '[OBJECT]';
    } else if (!/[0-9a-z\s\-_\|]+/ig.test(value)) {
      return `"${value}"`;
    } else {
      return value;
    }
  }
}
