<div class="form-group checkbox-group">
  <label>
    {{label}}
    <span *ngIf="required" class="required">*</span>
    <span *ngIf="icon" class="icon {{iconClass}}" [ngbTooltip]="tipContent">
    <ng-template #tipContent>{{tooltipContent}}</ng-template></span>
  </label>
  <div class="form-control" [ngClass]="{ 'is-invalid': formSubmitted && errors }">
    <app-checkbox
      *ngFor="let option of options"
      [id]="option.id"
      [name]="option.name"
      [value]="option.value"
      [label]="option.label"
      [toggle]="option.toggle"
      [inline]="inline"
      [tooltip]="option.tooltip"
      [(checked)]="option.checked"
      (change)="onCheckChange($event)"
    ></app-checkbox>
  </div>
  <div *ngIf="formSubmitted && errors" class="invalid-feedback">
    <div *ngIf="errors.required">Required<br></div>
  </div>
</div>
