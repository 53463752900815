import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CheckboxOption } from '../../classes';
import { FormFieldErrors } from '../../classes';

const noop = () => {};

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxGroupComponent),
      multi: true
    }
  ]
})
export class CheckboxGroupComponent implements OnInit {
  currentValue: string[] = [];

  @Input() label: string;
  @Input() options: CheckboxOption[] = [];
  @Input() inline: boolean;
  @Input() required: boolean;
  @Input() formControlName: string;
  @Input() formSubmitted: boolean;
  @Input() errors: FormFieldErrors;
  @Input() icon: boolean;
  @Input() iconClass: string;
  @Input() tooltipContent: string;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input()
  get value() {
    return this.currentValue;
  }

  set value(val) {
    this.currentValue = val;
    this.change.emit(this.currentValue);
    this.valueChange.emit(this.currentValue);
    this.updateChecksFromValue();
    this.onChangeCallback(this.currentValue);
  }

  @Output()
  change: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

  @Output()
  valueChange: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

  writeValue(value: any) {
    this.currentValue = value;
    this.onChangeCallback(value);
    this.updateChecksFromValue();
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  constructor() { }

  ngOnInit() {
    this.updateChecksFromValue();
  }

  updateChecksFromValue() {
    for (const option of this.options) {
      option.checked = this.currentValue && this.currentValue.includes(option.value);
    }
  }

  onCheckChange(event: any) {
    if (event && event.target) {
      for (const option of this.options) {
        if (option.value === event.target.value) {
          option.checked = event.target.checked ? true : undefined;
        }
      }

      this.value = this.options.filter(opt => opt.checked).map(opt => opt.value);

      // this.onChangeCallback(this.value);
    }
  }
}
