import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ShopifyService, Product, LineItem, GlobalService, Cart } from '../../shared';
import { AuthenticationService } from '../../services';

@Component({
  selector: 'app-nav-link-group',
  templateUrl: './nav-link-group.component.html',
  styleUrls: []
})
export class NavLinkGroupComponent implements OnInit {
  @Input() links: object[];
  firstName = '';
  lastName = '';

  constructor(
    private globalService: GlobalService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    if (localStorage.getItem('loggedUser')) {
      const profile: any = JSON.parse(localStorage.getItem('loggedUser'));
      this.firstName = profile.first_name;
      this.lastName = profile.last_name;
    }
  }
  closeCart() {
    this.globalService.cartToggle();
  }
  logout() {
    this.authService.doLogout().subscribe((resp) => {
      if (resp) {
        window.location.assign(environment.adminUrl + '/login');
      }
    });
  }
}
