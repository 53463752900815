import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { Vehicle, DriverLicense } from '../../classes';
import { DriverVehicleService } from 'src/app/services';

@Component({
  selector: 'app-add-vehicle-driver-form',
  templateUrl: './add-vehicle-driver-form.component.html',
  styles: []
})
export class AddVehicleDriverFormComponent implements OnChanges {

  modalOpen = false;

  @Input() permitId: number;
  @Input() type: string;
  @Input() quantity: number;
  @Input() autoVehicles: Vehicle[];
  @Input() motoVehicles: Vehicle[];
  @Input() hasAuto: boolean;
  @Input() hasMoto: boolean;
  @Input() autoComplete: boolean;
  @Input() motoComplete: boolean;
  @Input() drivers: DriverLicense[];

  vehicles: Vehicle[] = [];
  vehicleQuantity = 0;
  driverQuantity = 0;

  @Output() quantityChange = new EventEmitter<any>();

  constructor(
    private driverVehicleService: DriverVehicleService
  ) { }

  ngOnChanges(): void {
    this.vehicles = this.type === 'auto' ? this.autoVehicles : this.motoVehicles;
  }

  openModal(): void {
    this.modalOpen = true;
  }

  closeModal(): void {
    this.modalOpen = false;
  }

  onSubmit(): void {
    if (this.autoMotoComplete) {
      this.driverVehicleService.submitDriversAndVehicles(this.permitId).subscribe(() => this.closeModal());
    }
  }

  onQuantityChange(event: any): void {
    if (event.model === 'vehicle') {
      this.vehicleQuantity = event.quantity;
    } else if (event.model === 'driver') {
      this.driverQuantity = event.quantity;
    }

    if (this.driverQuantity >= this.quantity && this.vehicleQuantity === this.quantity) {
      this.quantityChange.emit({type: this.type, status: 'Complete'});
    } else {
      this.quantityChange.emit({type: this.type, status: 'Incomplete'});
    }
  }

  get title(): string {
    const type = this.type === 'auto' ? 'Auto' : 'Motorcycle';
    return type + ' Liability Insurance - Add Vehicles and Drivers';
  }

  get autoMotoComplete(): boolean {
    if (this.hasAuto && this.hasMoto) {
      return this.autoComplete && this.motoComplete;
    }
    if (this.hasAuto) {
      return this.autoComplete;
    }
    if (this.hasMoto) {
      return this.motoComplete;
    }
    return false;
  }

}
