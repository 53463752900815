<app-button [type]="'btn btn-secondary d-none'" (click)="openModal1($event)">Copy past event</app-button>
<app-modal [id]="'copy-event'" [title]="'Copy from Past Event'"[isOpen]="modal1Open" (close)="closeModal1($event)">
  <app-modal-body>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    <br>
    <form>
      <div class="row">
        <div class="col-sm-12">
        <app-select [label]="'Search for event you would like to copy'" [name]="'copy-past-events'" [options]="pastEventsOptions"></app-select>
      </div>
      <br><br>
      <div class="col text-right">
        <app-button [type]="'btn-secondary'" (click)="closeModal1($event)">Copy Event</app-button>
      </div>
    </div>
    </form>
  </app-modal-body>
</app-modal>