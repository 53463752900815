import * as i0 from "@angular/core";
var CsvDownloadService = /** @class */ (function () {
    function CsvDownloadService() {
        this.error = false;
    }
    Object.defineProperty(CsvDownloadService.prototype, "displayError", {
        get: function () { return this.error; },
        set: function (val) { this.error = val; },
        enumerable: true,
        configurable: true
    });
    CsvDownloadService.prototype.downloadFile = function (data, filename) {
        var _this = this;
        if (!Array.isArray(data) || data.length === 0) {
            console.error('Invalid data passed to CsvDownloadService! Expected array with length > 0, received:', data);
            this.displayError = true;
            return;
        }
        this.displayError = false;
        var header = Object.keys(data[0]);
        var csv = data.map(function (row) { return header.map(function (fieldName) { return JSON.stringify(row[fieldName], _this.replacer); }).join(','); });
        csv.unshift(header.join(','));
        var csvArray = csv.join('\r\n');
        var a = document.createElement('a');
        var blob = new Blob([csvArray], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);
        var downloadName = 'USACYCLING-Permitting.csv';
        if (filename) {
            downloadName = filename;
        }
        if (!/\.csv$/i.test(downloadName)) {
            downloadName += '.csv';
        }
        a.href = url;
        a.download = downloadName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    };
    CsvDownloadService.prototype.replacer = function (key, value) {
        if (value === null) {
            return 'NA';
        }
        else if (Array.isArray(value)) {
            if (key === 'event_types') {
                return value.map(function (v) { return v.et_name; }).join(' | ');
            }
            else {
                return value.length + " (count)";
            }
        }
        else if (typeof value === 'object') {
            return '[OBJECT]';
        }
        else if (!/[0-9a-z\s\-_\|]+/ig.test(value)) {
            return "\"" + value + "\"";
        }
        else {
            return value;
        }
    };
    CsvDownloadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CsvDownloadService_Factory() { return new CsvDownloadService(); }, token: CsvDownloadService, providedIn: "root" });
    return CsvDownloadService;
}());
export { CsvDownloadService };
