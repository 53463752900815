<div class="form-group">
  <label for="{{name}}>">
      {{label}}
      <span *ngIf="required" class="required">*</span>
    </label>
  <select
    class="form-control custom-select"
    [ngClass]="{ 'is-invalid': formSubmitted && errors, 'blue-select': blue }"
    [ngStyle]="{'min-width.px': minWidth}"
    [disabled]="disabled"
    id="{{id}}"
    name="{{name}}"
    [(ngModel)]="value"
  >
    <option *ngFor="let option of options" value="{{option.value}}">{{option.label}}</option>
  </select>
  <div *ngIf="formSubmitted && errors" class="invalid-feedback">
    <div *ngIf="errors.required">Required</div>
  </div>
</div>
