import { Component, OnInit, Input, ContentChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';

import {
  RadioOption, Vehicle,
} from '../../classes';
import { yearValidator, vehicleYearValidator } from 'src/app/validators';
import { DriverVehicleService } from '../../services';

@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styles: []
})
export class AddVehicleComponent implements OnInit {

  addVehicleForm: FormGroup;
  insuranceRequirementsOptions: RadioOption[] = [];
  submitted = false;
  complete: number;
  incomplete: number;

  @Input() permitId: number;
  @Input() type: string;
  @Input() quantity: number;
  @Input() vehicles: Vehicle[] = [];

  @Output() quantityChange = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private driverVehicleService: DriverVehicleService
  ) { }

  // convenience getter for easy access to form fields
  get f() { return this.addVehicleForm.controls; }

  ngOnInit() {
    this.addVehicleForm = this.formBuilder.group({
      vehicle_model: [ '', Validators.required ],
      vehicle_make: [ '', Validators.required ],
      vehicle_year: [ '', [ Validators.required, yearValidator(), vehicleYearValidator() ] ],
      vehicle_vin: [ '', Validators.required ],
      vehicle_insurance: [ 'N', Validators.required],
      vehicle_type: [ this.type ],
      vehicle_state: [ 'pending' ]
    });
    this.insuranceRequirementsOptions = [
      new RadioOption('yes', 'Y', 'Yes'),
      new RadioOption('no', 'N', 'No')
    ];

    setTimeout(() => {
      this.updateQuantities();
      // console.log(`${this.type} vehicles: `, this.vehicles);
    }, 500);
  }

  updateQuantities() {
    this.complete = this.vehicles.length;
    this.incomplete = this.quantity - this.complete;
    this.quantityChange.emit({type: this.type, model: 'vehicle', quantity: this.vehicles.length});
  }

  addVehicle() {
    this.driverVehicleService.addVehicle(this.permitId, this.addVehicleForm.value).subscribe(vehicle => {
      this.vehicles.push(vehicle);
      this.updateQuantities();
    });

  }

  removeVehicle(index) {
    const vehicle = this.vehicles[index];

    this.driverVehicleService.deleteVehicle(this.permitId, vehicle.id).subscribe(() => {
      this.vehicles.splice(index, 1);
      this.updateQuantities();
    });

    // this.resetForm();
  }

  resetForm() {
    this.submitted = false;
    this.addVehicleForm.reset();
    this.f.vehicle_insurance.setValue('N');
    this.f.vehicle_state.setValue('pending');
    this.f.vehicle_type.setValue(this.type);
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.addVehicleForm.invalid) {
      return;
    }

    this.addVehicle();
    this.resetForm();
  }

}
