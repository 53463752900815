/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./logo.component";
var styles_LogoComponent = [];
var RenderType_LogoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LogoComponent, data: {} });
export { RenderType_LogoComponent as RenderType_LogoComponent };
export function View_LogoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["href", "https://usacycling.org"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["class", "logo"], ["src", "assets/images/usac-logo.svg"]], [[4, "width", "px"]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; _ck(_v, 1, 0, currVal_0); }); }
export function View_LogoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-logo", [], null, null, null, View_LogoComponent_0, RenderType_LogoComponent)), i0.ɵdid(1, 114688, null, 0, i1.LogoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoComponentNgFactory = i0.ɵccf("app-logo", i1.LogoComponent, View_LogoComponent_Host_0, { width: "width" }, {}, []);
export { LogoComponentNgFactory as LogoComponentNgFactory };
