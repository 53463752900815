import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { BaseService } from './base-service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends BaseService {
  cookieName = 'USACLOGIN';

  constructor(
    protected http: HttpClient,
    protected cookie: CookieService
  ) {
    super(http, cookie);
  }

  doLogin(redirectUrl: string): void {
    window.location.assign(environment.adminUrl + `/login?redirectUrl=${redirectUrl}`);
  }

  doLogout(): Observable<any> {
    return this.http.get<any>(this.url('logout'), this.options).pipe(
      tap((_) => {
        this.log('attempting api logout');
      }),
      catchError(this.handleError<any>('doLogout', {}))
    );
  }

  isAuthenticated(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const hasCookie = this.cookie.check(this.cookieName);

      observer.next(hasCookie);
      observer.complete();
    });
  }
}
