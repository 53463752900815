import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: []
})
export class NavbarComponent implements OnInit {

  links = [
    { path: 'queue', name: 'Events Management' },
  ];

  isNavbarCollapsed = false;

  // links = [
  //   { path: 'event/listing', name: 'Event Listing' },
  //   { path: 'event/review', name: 'Event Listing Review' },
  //   { path: 'permit-application/part-1', name: 'Permit Application Page1'},
  //   { path: 'permit-application/part-2', name: 'Permit Application Page2'},
  //   { path: 'event/insured-certificates', name: 'Insured Certificates'},
  //   { path: 'event/add-ons', name: 'Certificate Add Ons'},
  //   { path: 'queue', name: 'Events Queue' },
  // ];

  constructor() { }

  ngOnInit() {
  }
}
