import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

import { BaseService } from './base-service';
import { ProfileService } from './profile.service';
import { Notification } from '../classes';
import { isArray } from 'util';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected cookie: CookieService,
    protected profileService: ProfileService
  ) {
    super(http, cookie);
  }

  getNotificationsForEvent(eventId: number|string): Observable<object> {
    return this.http.get(this.url(`notification?event_id=${eventId}`), this.options)
      .pipe(
        tap(_ => this.log('fetched notifications')),
        catchError(this.handleError<object>('getNotificationsForEvent', [], { eventId }))
      );
  }

  readNotification(type: string, id: number|string): Observable<any> {
    return this.http.get(this.url(`notification/setRead?read_type=${type}&read_id=${id}`), this.options)
      .pipe(
        tap(() => this.log(`marked notification id: ${id} as read`)),
        catchError(this.handleError<any>('readNotification', null, { type, id }))
      );
  }

  sortNotifications(data: object, eventId: number|string) {
    const notifications: Notification[] = [];
    for (const key of Object.keys(data)) {
      if (Array.isArray(data[key])) {
        data[key].forEach(entry => {
          if (key === 'comments') {
            notifications.push(
              new Notification(
                entry.comment_id,
                entry.created_at,
                `${entry.profile_first_name} ${entry.profile_last_name}`,
                'comment',
                entry.comment_message,
                'comments',
                entry.read
              )
            );
          } else if (key === 'payments' && !entry.payment_cancelled) {
            const isUnpaidCheckout = entry.order_status_url === null;
            const isUnpaidDraftOrder = !entry.order_id;
            const paidStatus =  (isUnpaidCheckout || isUnpaidDraftOrder) ? 'unpaid' : 'paid';

            // TODO: Remove conditional & set only to profile name once API updated
            const user = entry.profile_full_name ? entry.profile_full_name : entry.profile_id;
            notifications.push(
              new Notification(
                entry.id,
                entry.sp_updated_at,
                user,
                'payment',
                `$${entry.total_price}`,
                'payments',
                entry.read,
                paidStatus
              )
            );
          }
          // TODO: Include logic for orders & change requests, once systems in place
          // else if (key === 'event_change_requests') {
          //   notifications.push(
          //     new Notification(
          //       entry.cr_id,
          //       entry.cr_date,
          //       entry.profile_id,
          //       'change-request',
          //       entry.cr_reason,
          //       '/event/overview#change-requests',
          //       entry.read
          //     )
          //   );
          // }
          // else if (key === 'permit_change_requests') {
          //   notifications.push(
          //     new Notification(
          //       entry.cr_id,
          //       entry.cr_date,
          //       entry.profile_id,
          //       'change-request',
          //       entry.cr_reason,
          //       '/event/overview#change-requests',
          //       entry.read
          //     )
          //   );
          // }
        });
      }
    }
    console.log(notifications);
    return notifications.reverse();
  }
}
