export class PostEventRiders {
  rider_id: number|string;
  post_event_id: number|string;
  event_date_id: number|string;
  subdiscipline_id: number|string;
  riders_unique: number|string;

  constructor(data) {
    Object.assign(this, data);
  }
}
