<div class="container">
  <div class="row">
    <div class="main-container col-12 text-center">
      <h3 class="mb-3">Your session token has expired. Please logout and login again to renew your session.</h3>

      <app-button
        [type]="'btn-primary btn-lg'"
        (click)="logout()"
      >Logout Now</app-button>
    </div>
  </div>
</div>
