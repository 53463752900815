import { Router, } from '@angular/router';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProfileService, EventService } from '../services';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../services/profile.service";
import * as i3 from "../services/events.service";
var EventPaymentGuard = /** @class */ (function () {
    function EventPaymentGuard(router, profileService, eventService) {
        this.router = router;
        this.profileService = profileService;
        this.eventService = eventService;
    }
    EventPaymentGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.profileService.getCurrentUser().pipe(switchMap(function () {
            var user = _this.profileService.currentUser;
            return _this.profileService.getUserPermissions().pipe(switchMap(function (permissions) {
                var userRole = _this.profileService.setUserRole(permissions);
                if (userRole === 'usac_admin') {
                    return of(true); // Allow access for admin users
                }
                else {
                    if (user.is_pended_outstanding_permit_fee) {
                        alert('Please contact eventservices@usacycling.org for further assistance.');
                        return of(false); // Deny access
                    }
                    else {
                        return of(true); // Allow access for not-pended users
                    }
                }
            }));
        }));
    };
    EventPaymentGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventPaymentGuard_Factory() { return new EventPaymentGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ProfileService), i0.ɵɵinject(i3.EventService)); }, token: EventPaymentGuard, providedIn: "root" });
    return EventPaymentGuard;
}());
export { EventPaymentGuard };
