<form [formGroup]="eventForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-12" id="general-information">
      <h3>General Information</h3>
    </div>
    <app-text-input
      class="col-12 col-sm-6 col-lg-3"
      formControlName="event_organizer_comp_id"
      [type]="'text'"
      [name]="'event_organizer_comp_id'"
      [label]="'Organizer Member ID'"
      [placeholder]="'12345'"
      [formSubmitted]="submitted"
      [errors]="f.event_organizer_comp_id.errors"
      [required]=true></app-text-input>
    <app-text-input
      class="col-12 col-sm-6 col-lg-3"
      formControlName="organizerName"
      [type]="'text'"
      [name]="'organizerName'"
      [label]="'Organizer Name'"
      [placeholder]="'Jane Doe'"
      [formSubmitted]="submitted"
      [errors]="f.organizerName.errors"
      [disabled]="true"
      [required]=true></app-text-input>
    <app-text-input
      class="col-12 col-sm-6 col-lg-3"
      formControlName="organizerEmail"
      [type]="'email'"
      [name]="'organizerEmail'"
      [label]="'Organizer Email'"
      [placeholder]="'jane@example.com'"
      [formSubmitted]="submitted"
      [icon]=true
      [iconClass]="'icon-information'"
      [tooltipContent]="'If you want to update your email address, please make that change in My Account.'"
      [errors]="f.organizerEmail.errors"
      [disabled]="true"
      [required]=true></app-text-input>
  </div>
  <h4>Additional Contacts</h4>
  <app-event-listing-contact [submitted]="submitted"></app-event-listing-contact>
  <div class="row">
    <app-multi-select
      class="col-12"
      formControlName="event_sponsoring_clubs"
      [label]="'Sponsoring Club or Entity (Select up to 5) - Can\'t find your club?'"
      [placeholder]="'Searchable multi-select'"
      [options]="clubs.clubList"
      [settings]="limitSelection"
      [name]="'multi-select-example'"
      [formSubmitted]="submitted"
      [icon]=true
      [iconClass]="'icon-information'"
      [tooltipContent]="'
       Club contacts can renew now through their personal USA Cycling account under
      the “Tools” tab and “Manage USA Cycling Clubs” or using a paper application.
      There is a renew link under the red expiration date.
      Questions about the renewal process can be directed to membership@usacycling.org.
      '">
    </app-multi-select>
    <div class="col-sm-4 align-self-center">
      <app-button
      [mode]="'button'"
      (click)="clearMultiSelect()"
      [type]="'btn-secondary'">Clear Selection</app-button>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-sm" id="event-details">
      <h3>Event Details</h3>
      <br>
      <div class="row">
        <div class="col-sm-6">
          <app-text-input
            formControlName="event_name"
            [type]="'text'"
            [name]="'event_name'"
            [label]="'Event Name'"
            [placeholder]="'Event Name'"
            [formSubmitted]="submitted"
            [errors]="f.event_name.errors"
            [required]=true>
          </app-text-input>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <app-datepicker
            formControlName="event_start_date"
            [name]="'event_start_date'"
            [label]="'Start Date'"
            [minDate]="today"
            [oneYearLimit]=true
            [formSubmitted]="submitted"
            [errors]="f.event_start_date.errors"
            [required]=true
            [disabled]="disableDateEdit"
            [iconClass]="'icon-information'"
            [tooltipContent]="'Event dates can only be changed by Event Services staff once a listing or permit is submitted. To change the dates of your event you will need to send a comment with the change details or reach out to eventservices@usacycling.org for support.'"
          ></app-datepicker>
        </div>
        <div class="col-sm-3">
          <app-datepicker
            formControlName="event_end_date"
            [name]="'event_end_date'"
            [label]="'End Date'"
            [minDate]="endDateMin"
            [oneYearLimit]=true
            [formSubmitted]="submitted"
            [errors]="f.event_end_date.errors"
            [required]=true
            [disabled]="disableDateEdit"
            [iconClass]="'icon-information'"
            [tooltipContent]="'Event dates can only be changed by Event Services staff once a listing or permit is submitted. To change the dates of your event you will need to send a comment with the change details or reach out to eventservices@usacycling.org for support.'"
          ></app-datepicker>
        </div>
      </div>
      <div class="row" *ngIf="createMode">
        <div class="col-sm-6">
          <app-select
            formControlName="event_discipline"
            [label]="'Event Discipline'"
            [options]="disciplineOptions"
            [name]="'event_discipline'"
            [formSubmitted]="submitted"
            [errors]="f.event_discipline.errors"
            (change)="onChangeDiscipline()"
            [required]=true></app-select>
        </div>
        <ngb-alert *ngIf="isVirtual"  class="small mx-3" [type]="'info'" [dismissible]="false">
          Virtual Race is only for an event calendar listing at this time. If you are looking to insure the virtual event,
          then you will permit your event under the Fun-Ride discipline. This will allow you to complete the permit process
          and prevent duplicate listings of your event on the event search calendar.
        </ngb-alert>
      </div>
      <div *ngIf="f.event_discipline.valid && createMode">
        <div *ngIf="f.event_discipline.value">
            <div class="row">
              <div class="col-sm-6">
                <app-checkbox-group
                *ngIf="typesOptions.length > 0"
                  formControlName="event_types"
                  [inline]="true"
                  [label]="'Are you running any of the following event types?'"
                  [options]="typesOptions"
                  [formSubmitted]="submitted"
                  (valueChange)="updateEventType($event)"
                ></app-checkbox-group>
              </div>
            </div>
        </div>

        <div *ngIf="calendarLoading" style="margin-top:-1rem; margin-bottom: 1rem;">
          <app-loader [small]="true" [align]="'left'"></app-loader>
        </div>

        <app-checkbox-group
          formControlName="event_special_calendars"
          *ngIf="calendarOptions && calendarOptions.length > 0"
          [inline]="true"
          [label]="'Is this event part of a special calendar?'"
          [options]="calendarOptions"
          [formSubmitted]="submitted"
          (valueChange)="updateCalendars($event)"
          ></app-checkbox-group>
        <div class="row">
          <div class="col-sm-6" *ngIf="calendarCodeState">
            <app-text-input
              formControlName="calendarCode"
              [type]="'text'"
              [name]="'calendarCode'"
              [label]="'Special Calendar Code'"
              [placeholder]="''"
              [formSubmitted]="submitted"
              [errors]="f.calendarCode.errors"
              [required]=true
              (change)="validateCalendarCode()"
              ></app-text-input>
              <p *ngIf="displayDiscountMsg" class="small mt-2-neg">Valid code. Discount will be applied at checkout.</p>
              <p *ngIf="displayFailedDiscountMsg" class="small mt-2-neg text-warning">Please enter valid code to receive discount.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="f.event_discipline.valid && eventDates">
    <div class="row" id="event-dates">
      <p class="col-12 small">
        Please add the event information for each day of your event in these cards.
        This information helps us populate each date on the event calendar for promotional purposes and calculates your permit fee if you are permitting your event. <br /><br />
        <strong>Helpful tip:</strong> if you fill out the first card and then add additional event cards thereafter, the information will copy over from the first card.
      </p>
      <div class="col-sm-6" *ngFor="let eventDate of eventListing.eventDates; let i = index">
        <app-event-date-card
          [cardIndex]="i"
          [title]="('Day/Event '+(i+1))"
          [submitted]="submitted"
          [eventListingDate]="eventDate"
          [subDisciplines]="subDisciplinesOptions"
          [startDate]="eventListing.event_start_date"
          [endDate]="eventListing.event_end_date"
          [eventDatesChanged]="eventDatesChanged"
          [disabled]="disableDateEdit"
          (dateChange)="handleEventDateChange()"
        ></app-event-date-card>
      </div>
    </div>
    <div *ngIf="showEventDateAddBtn">
      <app-button
        [type]="'btn-primary'"
        [link]="''"
        [mode]="'button'"
        (click)="addEventListingDate()"
      >Add Day/Event {{eventListing.eventDates.length + 1}} Details</app-button>
    </div>
    <br><br><br>
    <div class="row">
      <div class="col-md-8 col-lg-6">
        <h3>Registration</h3>
        <br>
        <div>
          <p>Where will participants Register?</p>
          </div>
          <div class="row mt-3 mb-3">
            <app-checkbox
              class="col-6"
              formControlName="bike_reg_check"
              [id]="'event_bike_reg-form-control'"
              [name]="'bike_reg_check'"
              [label]="'BikeReg (Recommended)'"
              [checked]="bikeRegCheckboxState"
              (change)="toggleBikeRegState($event)"
              [formSubmitted]="submitted"
              [required]=false
              ></app-checkbox>
            <app-checkbox
              class="col-6"
              formControlName="other_reg_check"
              [id]="'otherRegCheckbox-form-control'"
              [name]="'other_reg_check'"
              [label]="'Other'"
              [checked]="otherRegCheckboxState"
              (change)="toggleBikeRegState($event)"
              [formSubmitted]="submitted"
              [required]=false
              ></app-checkbox>
        </div>
            <app-text-input
              *ngIf="regState"
              formControlName="event_reg_url"
              [type]="'url'"
              [name]="'event_reg_url'"
              [label]="regUrlLabel"
              [placeholder]="regUrlPlaceholder"
              [formSubmitted]="submitted"
              [errors]="f.event_reg_url.errors"
              [required]=true
              ></app-text-input>
        </div>
      </div>
    </div>
    <br><br>
    <div class="row">
      <div class="col-sm-6" id="marketing">
        <h3>Marketing</h3>
        <br>
        <app-text-input
          formControlName="event_website"
          [type]="'url'"
          [name]="'event_website'"
          [label]="'Event Website'"
          [placeholder]="''"
          [formSubmitted]="submitted"
          [errors]="f.event_website.errors"
          [required]=true
          ></app-text-input>
        <br>
      <div>
        <p>Event Announcement <span class="icon icon-information" [ngbTooltip]="tipContent"><ng-template #tipContent>Please provide a document or web link with your event details. These include the schedule of events, registration link and prices, as well as event notes to provide participants with as much event information in one location as possible. Acceptable file types are PDF, JPG, Word and a URL.</ng-template></span></p>
        <app-radio-switch
            formControlName="event_announcement_switch"
            [name]="'Event Announcement'"
            [label]= "''"
            [options]="eventAnnouncementOptions"
            [formSubmitted]="submitted"
            >
        </app-radio-switch>
        <div *ngIf="announcementType === 'url'">
          <app-text-input
            formControlName="event_announcement"
            [type]="'url'"
            [name]="'event_announcement'"
            [label]="'Announcement URL'"
            [formSubmitted]="submitted"
            ></app-text-input>
        </div>

       <div *ngIf="announcementType === 'upload'">
        <app-alert [alerts]="announcementAlert"></app-alert>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Upload</span>
          </div>
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="event_announcement"
              (change)="selectAnnouncementFile($event)">
            <label class="custom-file-label" for="event_announcement">{{announcementLabel}}</label>
          </div>
        </div>
      </div>
      </div>
        <br>
        <p>Event Logo</p>
        <app-alert [alerts]="logoAlert"></app-alert>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Upload</span>
          </div>
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="event_logo"
              (change)="selectLogoFile($event)">
            <label class="custom-file-label" for="event_logo">{{logoLabel}}</label>
          </div>
        </div>
      </div>
    </div>
    <br><br>
    <div class="row">
      <div class="col-sm">
        <h3>Social Links</h3>
        <br>
        <div class="row">
          <div class="col-sm-3">
            <app-text-input
              formControlName="event_facebook_url"
              [type]="'text'"
              [name]="'event_facebook_url'"
              [label]="'Facebook'"
              [placeholder]="'facebook.com/yourpage'"
              [formSubmitted]="submitted"
              [errors]="f.event_facebook_url.errors"
              ></app-text-input>
          </div>
          <div class="col-sm-3">
            <app-text-input
              formControlName="event_twitter_url"
              [type]="'text'"
              [name]="'event_twitter_url'"
              [label]="'Twitter'"
              [placeholder]="'twitter.com/yourpage'"
              [formSubmitted]="submitted"
              [errors]="f.event_twitter_url.errors"
              ></app-text-input>
          </div>
          <div class="col-sm-3">
            <app-text-input
              formControlName="event_youtube_url"
              [type]="'text'"
              [name]="'event_youtube_url'"
              [label]="'YouTube'"
              [placeholder]="'youtube.com/user/yourpage'"
              [formSubmitted]="submitted"
              [errors]="f.event_youtube_url.errors"></app-text-input>
          </div>
          <div class="col-sm-3">
            <app-text-input
              formControlName="event_instagram_url"
              [type]="'text'"
              [name]="'event_instagram_url'"
              [label]="'Instagram'"
              [placeholder]="'instagram.com/yourpage'"
              [formSubmitted]="submitted"
              [errors]="f.event_instagram_url.errors"></app-text-input>
          </div>
        </div>
      </div>
    </div>
    <br><br>
    <div class="row">
      <div class="col-sm">
        <app-button [mode]="'button'" [type]="'btn-light'" (click)="returnNavigation()" [disabled]="submitting">Go Back</app-button>
      </div>
      <div class="col-sm text-right">
        <app-button
          *ngIf="createMode"
          [type]="'btn-secondary'"
          [mode]="'button'"
          [disabled]="!minRequiredFields() || submitting"
          (click)="openSaveExitModal()"
        >Save & Exit</app-button>
        <app-button [type]="'btn-primary'" *ngIf="displayReviewBtn()" [disabled]="!minRequiredFields() || submitting">
          Review Listing
          <span *ngIf="submitting" class="spinner-border spinner-border-sm ml-1"></span>
        </app-button>
      </div>
      <div class="col-sm text-right" *ngIf="!displayReviewBtn()">
        <app-button
          [mode]="'button'"
          [type]="'btn-secondary'"
          (click)="triggerUpdateListing()"
          [disabled]="submitting"
        >
          Update Listing
          <span *ngIf="submitting" class="spinner-border spinner-border-sm ml-1"></span>
        </app-button>
      </div>
    </div>
</form>

<app-modal
  [id]="'save-exit'"
  [title]="'Save & Exit Listing'"
  [isOpen]="showSaveExit"
  (close)="closeSaveExitModal(true)"
>
  <app-modal-body>
    <p>Would you like to save your current progress and return at a later time? Please note that your event will not be listed until this form is complete.</p>
    <div class="row">
      <div class="col-6 text-left">
        <app-button *ngIf="navUrl === ''"
          [type]="'btn-light'"
          (click)="closeSaveExitModal()"
        >Go Back</app-button>
        <app-button *ngIf="navUrl !== ''"
          [type]="'btn-light'"
          (click)="continueNavigation()"
        >Continue Without Saving</app-button>
      </div>
      <div class="col-6 text-right">
        <app-button
          [type]="'btn-secondary'"
          (click)="saveAndExit()"
        >Save & Exit</app-button>
      </div>
      <div *ngIf="submitted && eventForm.invalid" class="col-12 mt-2 invalid-feedback text-right">
        Form missing at least 1 required field
      </div>
    </div>
  </app-modal-body>
</app-modal>

<app-modal
  [id]="'dates-confirmation'"
  [title]="'Changes to Event Dates'"
  [isOpen]="showDateConfirmation"
  (close)="closeDateConfirmationModal()"
>
  <app-modal-body>
    <p>{{ dateConfirmationMessage }}</p>
    <div class="row">
      <div class="col-sm-6 text-left">
        <app-button *ngIf="navUrl === ''"
          [type]="'btn-light'"
          (click)="closeDateConfirmationModal()"
        >Go Back</app-button>
      </div>
      <div class="col-sm-6 text-right">
        <app-button
          [type]="'btn-secondary'"
          (click)="postOrUpdateEvent()"
        >OK, Save & Continue</app-button>
      </div>
    </div>
  </app-modal-body>
</app-modal>

<!-- commeting this as per new requirement may be we re-use in the future
<app-modal
  [id]="'update-listing'"
  [title]="'Submit Changes For Approval'"
  [isOpen]="submitModalOpen"
  (close)="closeSubmitModal($event)">
  <app-modal-body>
    <div class="row">
      <div class="col-sm-6 text-left">
        <p>Original</p>
      </div>
      <div class="col-sm-6 text-right">
        <p>Updated</p>
      </div>
    </div>
    <app-text-area
      [name]="'comment'"
      [label]="'Please describe what you would like to change?'"
      [(ngModel)]="eventListing.updateInfo"
      [required]=true
      ></app-text-area>
    <div class="row">
        <div class="col-sm">
          <p>Please include an updated Event Announcement that shows this update</p>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Upload</span>
            </div>
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="inputGroupFile01">
              <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 text-left">
          <app-button type="btn-light" (click)="closeSubmitModal($event)">Cancel</app-button>
        </div>
        <div class="col-sm-6 text-right">
          <app-button
            *ngIf="!(eventListing.updateInfo === undefined || eventListing.updateInfo === '')"
            type="btn-secondary"
            [mode]="'button'"
            (click)="postChanges()">Submit</app-button>
        </div>
      </div>
  </app-modal-body>
</app-modal>
-->
