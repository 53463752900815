import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => {};

@Component({
  selector: 'app-quantity-select',
  templateUrl: './quantity-select.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuantitySelectComponent),
      multi: true
    }
  ]
})
export class QuantitySelectComponent implements OnInit {
  currentValue: number;

  @Input() name: string;
  @Input() formControlName: string;
  @Input() min = 0;
  @Input() max: number;
  @Input() readOnly: false;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input()
  get value() {
    return this.currentValue;
  }

  set value(val) {
    this.currentValue = val;
    this.change.emit(this.currentValue);
    this.valueChange.emit(this.currentValue);
    this.onChangeCallback(this.currentValue);
  }

  @Output()
  change: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  valueChange: EventEmitter<number> = new EventEmitter<number>();

  writeValue(value: number) {
    this.currentValue = value;
    this.onChangeCallback(value);
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }


  constructor() { }
  ngOnInit() {

  }

  decreaseValue() {
      if (this.currentValue > this.min) {
        this.currentValue--;
        this.value = this.currentValue;
        this.change.emit(this.currentValue);
      }
  }

  increaseValue() {
    if (this.max) {
      if (this.currentValue < this.max) {
        this.increase();
      }
    } else {
      this.increase();
    }
  }

  increase() {
    this.currentValue++;
    this.value = this.currentValue;
    this.change.emit(this.currentValue);
  }

}
