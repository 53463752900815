import { OnInit, EventEmitter } from '@angular/core';
import { FormFieldErrors } from '../../classes';
var noop = function () { };
var ɵ0 = noop;
var SelectComponent = /** @class */ (function () {
    function SelectComponent() {
        this.blue = false;
        this.onTouchedCallback = noop;
        this.onChangeCallback = noop;
        this.change = new EventEmitter();
        this.valueChange = new EventEmitter();
    }
    Object.defineProperty(SelectComponent.prototype, "value", {
        get: function () {
            return this.currentValue;
        },
        set: function (val) {
            this.currentValue = val;
            this.change.emit(this.currentValue);
            this.valueChange.emit(this.currentValue);
            this.onChangeCallback(this.currentValue);
        },
        enumerable: true,
        configurable: true
    });
    SelectComponent.prototype.writeValue = function (value) {
        this.currentValue = value;
        this.onChangeCallback(value);
    };
    // From ControlValueAccessor interface
    SelectComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    // From ControlValueAccessor interface
    SelectComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    SelectComponent.prototype.ngOnInit = function () {
    };
    return SelectComponent;
}());
export { SelectComponent };
export { ɵ0 };
