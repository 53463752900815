import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShopifyService, GlobalService } from '../../shared';
import { EventDayService } from '../../services';
import { FormControl, FormGroup } from '@angular/forms';
var AddOnListComponent = /** @class */ (function () {
    function AddOnListComponent(router, shopifyService, globalService, eventDayService) {
        this.router = router;
        this.shopifyService = shopifyService;
        this.globalService = globalService;
        this.eventDayService = eventDayService;
        this.loading = true;
        this.productsInitialized = 0;
        this.productsLoaded = 0;
        this.productImages = {};
        this.productVariants = {};
        this.form = new FormGroup({
            variant: new FormControl([]),
            quantity: new FormControl(1),
        });
    }
    AddOnListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.eventDayService.setExistingDays(this.eventId);
        this.shopifyService.cartDraftOrder.subscribe(function (draftOrder) { return _this.draftOrder = draftOrder; });
        this.shopifyService.cartProducts.subscribe(function (products) {
            if (products.length) {
                // TODO: Determine better way of accessing products through collections instead of tags
                _this.products = products.filter(function (product) { return product.tags && product.tags.includes('Add Ons'); });
                var product_ids_1 = [];
                _this.products.forEach(function (product) { return product_ids_1.push(product.id); });
                _this.shopifyService.getShopifyProductImages({ product_ids: product_ids_1 }).subscribe(function (images) {
                    if (images.length) {
                        images.forEach(function (image) { return _this.productImages[image.product_id] = image; });
                    }
                });
                _this.shopifyService.getShopifyProductVariants({ product_ids: product_ids_1 }).subscribe(function (variants) {
                    if (variants.length) {
                        variants.forEach(function (variant) {
                            if (!_this.productVariants[variant.product_id]) {
                                _this.productVariants[variant.product_id] = [];
                            }
                            _this.productVariants[variant.product_id].push(variant);
                        });
                    }
                });
            }
        });
    };
    AddOnListComponent.prototype.addToCart = function (lineItem) {
        this.globalService.addItemToCart(lineItem);
    };
    AddOnListComponent.prototype.setProductsInitialized = function ($event) {
        this.productsInitialized += 1;
    };
    AddOnListComponent.prototype.setProductsLoaded = function ($event) {
        this.productsLoaded += 1;
        this.loading = this.productsLoaded !== this.productsInitialized;
    };
    Object.defineProperty(AddOnListComponent.prototype, "productVariantsLength", {
        get: function () {
            return !!Object.keys(this.productVariants).length;
        },
        enumerable: true,
        configurable: true
    });
    AddOnListComponent.prototype.toInsuredCerts = function () {
        this.router.navigate(["/event/" + this.eventId + "/insured-certificates"]);
    };
    return AddOnListComponent;
}());
export { AddOnListComponent };
