export const enum ShopifyDraftOrderStatus {
  open = 'open',
  invoice_sent = 'invoice_sent',
  completed = 'completed',
}

export interface ShopifyDraftOrderFilters {
  event_id?: number|string;
  post_event_id?: number|string;
  incomplete?: boolean;
  permitting?: boolean;
}

export class ShopifyDraftOrder {
  id: number;
  event_id?: number;

  token?: string;
  admin_graphql_api_id?: string;
  order_id?: string;

  total_tax = 0.0;
  total_price = 0.0;
  subtotal_price = 0.0;

  name: string;

  tags?: string;
  email?: string;
  currency?: string;
  invoice_url?: string;
  note?: string;

  tax_exempt = false;
  taxes_included = false;

  line_items?: any[];
  shipping_line?: any;
  note_attributes?: any;
  billing_address?: any;
  shipping_address?: any;
  applied_discount?: any;
  tax_lines?: any;
  tax_exemptions?: any;

  status: ShopifyDraftOrderStatus = ShopifyDraftOrderStatus.open;

  completed_at?: Date|string;
  invoice_sent_at?: Date|string;

  created_at: Date|string;
  updated_at: Date|string;

  constructor(data) {
    Object.assign(this, data);
  }
}
