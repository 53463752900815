import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { SelectOption } from '../../classes';
import { FormFieldErrors } from '../../classes';

const noop = () => {};

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent implements OnInit {
  currentValue: string;

  @Input() id: string;
  @Input() name: string;
  @Input() label: string;
  @Input() options: SelectOption[];
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() blue = false;
  @Input() minWidth: number;
  @Input() formControlName: string;
  @Input() formSubmitted: boolean;
  @Input() errors: FormFieldErrors;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input()
  get value() {
    return this.currentValue;
  }

  set value(val) {
    this.currentValue = val;
    this.change.emit(this.currentValue);
    this.valueChange.emit(this.currentValue);
    this.onChangeCallback(this.currentValue);
  }

  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  writeValue(value: any) {
    this.currentValue = value;
    this.onChangeCallback(value);
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  constructor() { }

  ngOnInit() {
  }
}
