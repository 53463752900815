import * as tslib_1 from "tslib";
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from './base-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
var ProfileService = /** @class */ (function (_super) {
    tslib_1.__extends(ProfileService, _super);
    function ProfileService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.profiles = [];
        _this.drivers = [];
        _this.referees = [];
        return _this;
    }
    ProfileService.prototype.getProfile = function (id) {
        var _this = this;
        return this.http.get(this.url('profile/' + id), this.options)
            .pipe(tap(function (_) { return _this.log('fetched profile'); }), catchError(this.handleError('getProfile', null, { id: id })));
    };
    ProfileService.prototype.getMemberByCompId = function (compId) {
        var _this = this;
        return this.http.get(this.url('profile/member/' + compId), this.options)
            .pipe(tap(function (_) { return _this.log('fetched member'); }), catchError(this.handleError('getMemberByCompId', null, { compId: compId })));
    };
    ProfileService.prototype.ghostResetProfile = function () {
        var _this = this;
        return this.http.get(this.url('admin/ghost/reset'), this.options).pipe(tap(function () {
            _this.log('ghost resetting profile');
        }), catchError(this.handleError('ghostResetProfile', null, {})));
    };
    ProfileService.prototype.searchDrivers = function (compId, name) {
        var _this = this;
        var queryParams = "?comp_id=" + compId + "&name=" + name;
        return this.http.get(this.url('searchdrivers' + queryParams), this.options)
            .pipe(tap(function (_) { return _this.log('fetched driver(s)'); }), catchError(this.handleError('searchDrivers', [], { compId: compId, name: name })));
    };
    ProfileService.prototype.searchReferees = function (compId, name) {
        var _this = this;
        var params = [];
        if (compId && compId !== '') {
            params.push("compid=" + compId);
        }
        if (name && name !== '') {
            params.push("name=" + name);
        }
        var queryParams = '?' + params.join('&');
        return this.http.get(this.url('chiefref' + queryParams), this.options)
            .pipe(tap(function (_) { return _this.log('fetched referee(s)'); }), catchError(this.handleError('searchReferees', [], { compId: compId, name: name })));
    };
    ProfileService.prototype.getCurrentUser = function () {
        var _this = this;
        return this.http.get(this.url('profile/self'), this.options)
            .pipe(tap(function (_) {
            _this.log('fetched current user');
            _this.currentUser = _;
        }), catchError(this.handleError('getCurrentUser', null, {})));
    };
    ProfileService.prototype.getUserPermissions = function () {
        var _this = this;
        var profileId = this.currentUser && this.currentUser.profile_id;
        return this.http.get(this.url('membership/permissions/' + profileId), this.options)
            .pipe(tap(function (_) {
            _this.log('fetched user permissions');
        }), catchError(this.handleError('getUserPermissions', null, { profileId: profileId })));
    };
    ProfileService.prototype.setUserRole = function (permissions) {
        if (permissions) {
            if (permissions.is_usac_admin) {
                return 'usac_admin';
            }
            else if (permissions.is_la_admin) {
                return 'la_admin';
            }
            else if (permissions.is_race_director) {
                return 'race_director';
            }
            else if (permissions.is_official) {
                return 'official';
            }
            else {
                return 'none';
            }
        }
    };
    ProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: ProfileService, providedIn: "root" });
    return ProfileService;
}(BaseService));
export { ProfileService };
