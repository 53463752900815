<div [formGroup]="eventContact" class="row" >
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col-sm-6 col-md-3">
          <app-text-input
            formControlName="contact_first_name"
            [type]="'text'"
            [name]="'contact_first_name'"
            [label]="'Contact First Name'"
            [placeholder]="'Jane'"
            [formSubmitted]="eventListing.validateContacts"
            [errors]="f.contact_first_name.errors"
            [readonly]="(eventListing.contacts.length > 3 )"></app-text-input>
          </div>
        <div class="col-sm-6 col-md-3">
          <app-text-input
            formControlName="contact_last_name"
            [type]="'text'"
            [name]="'contact_last_name'"
            [label]="'Contact Last Name'"
            [placeholder]="'Doe'"
            [formSubmitted]="eventListing.validateContacts"
            [errors]="f.contact_last_name.errors"
            [readonly]="(eventListing.contacts.length > 3 )"></app-text-input>
        </div>
        <div class="col-sm-6 col-md-3">
          <app-text-input
            formControlName="contact_email_1"
            [type]="'email'"
            [name]="'contact_email_1'"
            [label]="'Event Contact Email'"
            [placeholder]="'jane@example.com'"
            [formSubmitted]="eventListing.validateContacts"
            [errors]="f.contact_email_1.errors"
            [readonly]="(eventListing.contacts.length > 3 )"></app-text-input>
          </div>
        <div class="col-sm-6 col-md-3">
          <app-text-input
            formControlName="contact_phone_1"
            [type]="'tel'"
            [name]="'contact_phone_1'"
            [label]="'Event Contact Phone'"
            [placeholder]="'(555) 555-5555'"
            [formSubmitted]="eventListing.validateContacts"
            [errors]="f.contact_phone_1.errors"
            [readonly]="(eventListing.contacts.length > 3 )"
            ></app-text-input>
        </div>
      </div>
    </div>
    <div class="col-sm-3 align-self-center">
      <app-button *ngIf="(eventListing.contacts.length < 4 )"
        [type]="'btn-secondary w-100'"
        [mode] = "'button'"
        (click)="composeContact()" >Add Additional Contact</app-button>
    </div>
</div>
<div class="container-fluid text-primary small"
  *ngIf="(eventListing.contacts.length > 3 )" >
  You can't add more than three contacts
</div>
<div class="my-3" *ngIf="(eventListing.contacts.length > 0)"  >
  <div class="container-fluid" *ngFor="let contact of eventListing.contacts; let i = index" >
    <div class="row my-1" *ngIf="i < (eventListing.contacts.length - 1 )" >
      <div class="col-10"  >
        <div class="row align-items-center justify-content-start mt-1">
          <div class="col-sm-6 col-md-4 text-break" >{{contact.contact_first_name}} {{contact.contact_last_name}}</div>
          <div class="col-sm-6 col-md-5 text-break" >{{contact.contact_email_1}}</div>
          <div class="col-sm-6 col-md-3" >{{contact.contact_phone_1}}</div>
        </div>
      </div>
      <div class="col-2 align-self-center">
          <span (click)="removeContact(i)" class="align-middle ml-2 cursor-pointer"><i class="icon icon-delete"></i></span>
          <!-- <span (click)="editContact(i)" class="align-middle ml-2 cursor-pointer"><i class="icon icon-edit"></i></span> -->
      </div>
    </div>
  </div>
</div>
