import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CalendarLabel, CalendarLabelAnswer, EventDate, PostEvent, ShopifyOrder, ShopifyDraftOrder, Event, CalendarLabelQuestion } from 'src/app/classes';
import { EventService } from '../../services';
var PostEventFormTableComponent = /** @class */ (function () {
    function PostEventFormTableComponent(formBuilder, eventService) {
        this.formBuilder = formBuilder;
        this.eventService = eventService;
        this.riderSurchargeTotal = 0;
        this.licenseTotal = 0;
        this.membershipTotal = 0;
        this.lateFee = true;
        this.lateFeePrice = 0;
        this.feeFormChange = new EventEmitter();
        this.formSubmit = new EventEmitter();
        this.eventIncentiveDiscountQualification = new EventEmitter();
        this.headers = {
            participants: [
                { text: 'Date', align: 'left' },
                // commented out for MBR-2992
                // { text: 'Sub-Discipline', align: 'left' },
                { text: 'Pre-Registered', align: 'center' },
                { text: 'On-Site Registrations', align: 'center' },
                { text: 'Total Registrations', align: 'left' }
            ],
            riders: [
                { text: 'Date', align: 'left' },
                // commented out for MBR-2992
                // { text: 'Sub-Discipline', align: 'left' },
                { text: 'Unique Riders', align: 'center' },
                { text: 'Cost', align: 'right' },
                { text: 'Total', align: 'right' }
            ],
            licensesMemberships: [
                { text: 'Item', align: 'left' },
                { text: 'Quantity', align: 'center' },
                { text: 'Cost', align: 'right' },
                { text: 'Total', align: 'right' }
            ],
            fees: [
                { text: 'Fees', align: 'left' },
                { text: '', align: 'left' },
                { text: 'To Pay', align: 'right' }
            ]
        };
        this.hideDate = false;
        this.feesFormRows = ['Rider Surcharge', 'One Day Licenses', 'Memberships', 'Late Fee', '', '', '', '', '', '', ''];
        this.totalsRowsAdmin = ['Order Discount', 'Note', 'Other Discounts', 'Subtotal', 'Tax', 'Amount Paid', 'Total Due'];
        this.totalsRowsNonAdmin = ['Order Discount', 'Other Discounts', 'Subtotal', 'Tax', 'Amount Paid', 'Total Due'];
        this.requireNote = false;
        this.lateFeeAmt = 0;
        this.preDiscount = 0;
        this.subtotal = 0;
        this.totalDue = 0;
    }
    Object.defineProperty(PostEventFormTableComponent.prototype, "ftf", {
        get: function () { return this.feeTotalsForm.controls; },
        enumerable: true,
        configurable: true
    });
    PostEventFormTableComponent.prototype.ngOnInit = function () {
        this.disciplineName = this.discipline && this.eventService.getDisciplineName(this.discipline);
        this.hideDate = this.isSeries && (this.participants() || this.riders());
        if (this.fees()) {
            this.buildFeeTotalsForm();
            this.assignPostEventToForm();
            this.calculateTotalDue();
        }
    };
    PostEventFormTableComponent.prototype.ngOnChanges = function (changes) {
        if (this.fees()) {
            this.calculateTotalDue();
        }
    };
    PostEventFormTableComponent.prototype.assignPostEventToForm = function () {
        var _this = this;
        // Assign post event late fee, discount, & order note values
        Object.keys(this.ftf).forEach(function (key) {
            if (Object.keys(_this.postEvent).includes(key)) {
                var val = key === 'post_event_discount' ? _this.postEvent[key] || 0 : _this.postEvent[key];
                _this.ftf[key].setValue(val);
            }
        });
        // Assign discount & order note values from Shopify, if present
        if (this.draftOrder) {
            if (this.draftOrder.applied_discount) {
                this.ftf.post_event_discount.setValue(this.draftOrder.applied_discount.amount);
            }
            if (this.draftOrder.note) {
                this.ftf.post_event_order_note.setValue(this.draftOrder.note);
            }
        }
        this.ftf.post_event_late_fee.setValue(this.lateFee);
    };
    PostEventFormTableComponent.prototype.buildFeeTotalsForm = function () {
        var _this = this;
        this.feeTotalsForm = this.formBuilder.group({
            post_event_late_fee_dismissed: [false],
            post_event_late_fee: [null],
            post_event_discount: [0],
            post_event_order_note: ['']
        });
        /* Left line below commented-out in case note ever needs to be required again */
        // this.feeTotalsForm.get('post_event_discount').valueChanges.subscribe(value => this.onDiscountChange(value));
        this.feeTotalsForm.get('post_event_late_fee_dismissed').valueChanges.subscribe(function (value) { return _this.onDismissChange(value); });
        this.feeTotalsForm.valueChanges.subscribe(function (data) { return _this.feeFormChange.emit(_this.feeTotalsForm); });
    };
    PostEventFormTableComponent.prototype.onDiscountQualificationChange = function ($event) {
        this.eventIncentiveDiscountQualification.emit($event);
    };
    /* Left lines below commented-out in case note ever needs to be required again */
    // onDiscountChange(value: string) {
    //   if (typeof value === 'string' && value.startsWith('0') && value.length > 1 && value[1] !== '.') {
    //     this.ftf.post_event_discount.setValue(value.substring(1));
    //   } else {
    //     const amount = parseFloat(value);
    //     this.requireNote = amount > 0;
    //     const validator = amount > 0 ? Validators.required : null;
    //     this.ftf.post_event_order_note.setValidators(validator);
    //     if (amount > this.preDiscount) {
    //       this.ftf.post_event_discount.setValue(this.preDiscount.toString());
    //     } else if (amount < 0) {
    //       this.ftf.post_event_discount.setValue('0');
    //     }
    //     // this.ftf.post_event_order_note.updateValueAndValidity();
    //     this.calculateTotalDue();
    //   }
    // }
    PostEventFormTableComponent.prototype.onDismissChange = function (value) {
        this.lateFeeAmt = value ? 0 : this.lateFeePrice;
        this.calculateTotalDue();
    };
    Object.defineProperty(PostEventFormTableComponent.prototype, "totalPaid", {
        get: function () {
            var total = 0;
            this.orders.forEach(function (order) { return total += parseFloat(order.total_price); });
            return total;
        },
        enumerable: true,
        configurable: true
    });
    PostEventFormTableComponent.prototype.parseIfString = function (amount) {
        return typeof amount === 'string' ? parseFloat(amount) : amount;
    };
    PostEventFormTableComponent.prototype.calculateTotalDue = function () {
        this.preDiscount = (this.parseIfString(this.riderSurchargeTotal) + this.parseIfString(this.licenseTotal)
            + this.parseIfString(this.membershipTotal) + this.parseIfString(this.lateFeeAmt));
        var discount = this.feeTotalsForm ? parseFloat(this.ftf.post_event_discount.value) : 0;
        this.subtotal = discount ? this.preDiscount - discount : this.preDiscount;
        this.totalDue = this.total - this.totalPaid;
    };
    PostEventFormTableComponent.prototype.participants = function () {
        return this.section === 'participants';
    };
    PostEventFormTableComponent.prototype.riders = function () {
        return this.section === 'riders';
    };
    PostEventFormTableComponent.prototype.licensesMemberships = function () {
        return this.section === 'licensesMemberships';
    };
    PostEventFormTableComponent.prototype.fees = function () {
        return this.section === 'fees';
    };
    Object.defineProperty(PostEventFormTableComponent.prototype, "isUsacAdmin", {
        get: function () {
            return this.userRole === 'usac_admin';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventFormTableComponent.prototype, "isRaceOrRide", {
        get: function () {
            return this.event.isRaceOrRide;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventFormTableComponent.prototype, "selectHeaders", {
        get: function () {
            return this.hideDate ? this.headers[this.section].slice(1) : this.headers[this.section];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventFormTableComponent.prototype, "totalsRows", {
        get: function () {
            return this.isUsacAdmin ? this.totalsRowsAdmin : this.totalsRowsNonAdmin;
        },
        enumerable: true,
        configurable: true
    });
    PostEventFormTableComponent.prototype.feesRow = function (index, label) {
        return this.feesFormRows[index] === label;
    };
    PostEventFormTableComponent.prototype.totalsRow = function (index, label) {
        return this.totalsRows[index - 4] === label;
    };
    PostEventFormTableComponent.prototype.feesRowValue = function (index) {
        switch (this.feesFormRows[index]) {
            case 'Rider Surcharge':
                return this.riderSurchargeTotal;
                break;
            case 'One Day Licenses':
                return this.licenseTotal;
                break;
            case 'Memberships':
                return this.membershipTotal;
                break;
            case 'Late Fee':
                return this.lateFeeAmt;
                break;
            default: return null;
        }
    };
    PostEventFormTableComponent.prototype.totalsRowValue = function (index) {
        switch (this.totalsRows[index - 4]) {
            case 'Subtotal':
                return this.subtotal;
                break;
            case 'Tax':
                return this.tax;
                break;
            case 'Amount Paid':
                return this.totalPaid;
                break;
            case 'Total Due':
                return this.totalDue;
                break;
            default: return null;
        }
    };
    Object.defineProperty(PostEventFormTableComponent.prototype, "lineItemDiscounts", {
        get: function () {
            var _this = this;
            var discounts = [];
            if (this.draftOrder) {
                this.draftOrder.line_items.forEach(function (item) {
                    if (item.applied_discount) {
                        discounts.push({
                            description: _this.discountDescription(item.applied_discount),
                            amount: item.applied_discount.amount
                        });
                    }
                });
            }
            return discounts;
        },
        enumerable: true,
        configurable: true
    });
    PostEventFormTableComponent.prototype.discountDescription = function (discount) {
        return discount.title ? discount.title + " - " + discount.description : discount.description;
    };
    Object.defineProperty(PostEventFormTableComponent.prototype, "displayDiscount", {
        get: function () {
            return this.isUsacAdmin || this.ftf.post_event_discount.value > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventFormTableComponent.prototype, "tax", {
        get: function () {
            return this.draftOrder ? this.draftOrder.total_tax : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventFormTableComponent.prototype, "total", {
        get: function () {
            return this.draftOrder ? this.draftOrder.total_price : this.subtotal + this.tax;
        },
        enumerable: true,
        configurable: true
    });
    PostEventFormTableComponent.prototype.col = function (header, title) {
        return header.text === title;
    };
    PostEventFormTableComponent.prototype.alignByTitle = function (title) {
        return "text-" + this.headers[this.section].find(function (h) { return h.text === title; }).align;
    };
    PostEventFormTableComponent.prototype.alignByIndex = function (index) {
        return "text-" + this.headers[this.section][index].align;
    };
    PostEventFormTableComponent.prototype.eventDateStart = function (name) {
        var date = this.isSeries ? this.eventDate : this.eventDateFromControl(name);
        return date.event_date_start;
    };
    PostEventFormTableComponent.prototype.riderCurrencyCol = function (name) {
        return name.includes('cost') || name.includes('fee');
    };
    // commented out for MBR-2992
    // subDisciplineName(name: string) {
    //   let subDisc: SubDiscipline;
    //   if (this.isSeries) {
    //     subDisc = this.subDisciplineFromControl(name, this.subDisciplines);
    //   } else {
    //     subDisc = this.subDisciplineFromControl(name, this.eventDateFromControl(name).event_date_sub_disciplines);
    //   }
    //   return subDisc.subdiscipline_name;
    // }
    PostEventFormTableComponent.prototype.eventDateFromControl = function (name) {
        var id = parseInt(name.split('_')[2]);
        return this.eventDates.find(function (eventDate) { return eventDate.event_date_id === id; });
    };
    // commented out for MBR-2992
    // subDisciplineFromControl(name: string, subDisciplines: SubDiscipline[]) {
    //   const id = parseInt(name.split('_')[4]);
    //   return subDisciplines.find(subDisc => subDisc.subdiscipline_id === id);
    // }
    PostEventFormTableComponent.prototype.showParticipantInput = function (key) {
        return key.includes('registered');
    };
    PostEventFormTableComponent.prototype.licenseMembershipTitle = function (key) {
        switch (key) {
            case 'post_event_one_day_on_site': return 'One Day License: On-Site';
            case 'post_event_one_day_online': return 'One Day License: Online Bike Reg';
            // TODO: Consider pulling title from Shopify membership products?
            // ^^ One day license titles should remain static, as the requested form titles do not exactly match the product titles
            case 'post_event_membership_premium': return 'Post Event Race Membership';
            case 'post_event_membership_collegiate': return 'Post Event Race Membership: Collegiate';
            case 'post_event_membership_junior': return 'Post Event Race Membership: Junior';
        }
    };
    PostEventFormTableComponent.prototype.chargable = function (key) {
        return !this.excludeFromCharges.includes(key);
    };
    PostEventFormTableComponent.prototype.variantPrice = function (key) {
        return this.variants[key].price;
    };
    PostEventFormTableComponent.prototype.variantTotal = function (key) {
        return Object.keys(this.variantTotals).includes(key) ? this.variantTotals[key] : 0;
    };
    PostEventFormTableComponent.prototype.sortNull = function () { };
    return PostEventFormTableComponent;
}());
export { PostEventFormTableComponent };
