import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService, EventListingService, PermitService } from 'src/app/services';
import { Event } from 'src/app/classes';
var PermitApplicationPart2Component = /** @class */ (function () {
    function PermitApplicationPart2Component(router, route, eventService, eventListing, permitService) {
        this.router = router;
        this.route = route;
        this.eventService = eventService;
        this.eventListing = eventListing;
        this.permitService = permitService;
        this.currentStep = 1;
        this.reset = false;
        this.dataLoaded = false;
    }
    PermitApplicationPart2Component.prototype.ngOnInit = function () {
        var _this = this;
        this.permitService.reset();
        this.eventListing.reset();
        this.reset = true;
        this.eventId = this.route.snapshot.paramMap.get('eventId');
        this.eventService.getEventById(this.eventId).subscribe(function (event) {
            if (event) {
                _this.event = new Event(event);
                _this.eventListing.fetchEventData(_this.eventId);
                if (_this.event.isPermit) {
                    _this.permitService.permitId = event.event_permit_id;
                    _this.permitService.getQuestionConfig(event.event_discipline);
                    // Determines whether to proceed through listing application steps or return to overview after submit
                    _this.permitService.mode = _this.inProgress ? 'create' : 'update';
                    _this.loadAnswers();
                }
                else {
                    _this.toOverview();
                }
            }
            else {
                _this.toQueue();
            }
        });
    };
    PermitApplicationPart2Component.prototype.loadAnswers = function () {
        var _this = this;
        this.permitService.getPermitInfo(this.permitService.permitId).subscribe(function (data) {
            if (data === null || data === undefined) {
                console.log('events are not found , due to the api has down');
            }
            _this.permitService.answers = data.answers;
            console.log(_this.permitService.answers);
            _this.permitService.fetchFromAnswers();
            _this.dataLoaded = true;
        }, function (error) {
            console.log(error);
        });
    };
    Object.defineProperty(PermitApplicationPart2Component.prototype, "loaded", {
        get: function () {
            return this.reset && this.eventListing.datesLoaded && this.eventListing.organizerLoaded
                && this.eventListing.event_discipline && this.permitService.availableQuestionsList && this.dataLoaded;
        },
        enumerable: true,
        configurable: true
    });
    PermitApplicationPart2Component.prototype.inProgress = function () {
        return this.event.event_status === 'permit-in-progress';
    };
    PermitApplicationPart2Component.prototype.toOverview = function () {
        this.router.navigate(["event/" + this.eventId + "/overview"], { fragment: 'permit-application' });
    };
    PermitApplicationPart2Component.prototype.toQueue = function () {
        this.router.navigate(['queue']);
    };
    return PermitApplicationPart2Component;
}());
export { PermitApplicationPart2Component };
