import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationStart } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { CustomDateFormatter } from 'src/app/providers/dateFormatter';
import {
  ClubService,
  EventListingService,
  EventService,
  PermitService,
  ProfileService
} from '../../services';
import { ShopifyService, Product, GlobalService, LineItem } from '../../shared';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

import { urlValidator } from 'src/app/validators';
import { Alert, EventDate, ShopifyProductVariant, ShopifyLineItemBase, ShopifyDraftOrder, SpecialCalendar } from 'src/app/classes';

import {
  CheckboxOption,
  RadioOption,
  SelectOption,
  Discipline,
  EventType,
  Profile
} from '../../classes';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-event-listing-form',
  templateUrl: './event-listing-form.component.html',
  styleUrls: [],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class EventListingFormComponent implements OnInit, OnDestroy {

  @Input() eventId: string;

  submitting = false;
  // Wait for 1 second after changes stop to search for organizer
  searchOrganizerDelay = 1000;
  searchOrganizerTimeout: any;

  eventForm: FormGroup;
  formData: any;
  submitted = false;
  user: Profile;
  userRole: string;
  regState = false;
  bikeRegCheckboxState = true;
  otherRegCheckboxState = false;
  submitModalOpen = false;
  disciplineOptions: SelectOption[] = [
    new SelectOption(null, 'Select...')
  ];
  roadOptions: CheckboxOption[] = [];
  cyclocrossOptions: CheckboxOption[] = [];
  trackOptions: CheckboxOption[] = [];
  mtbOptions: CheckboxOption[] = [];
  bmxAndCxOptions: CheckboxOption[] = [];
  bmxFreeStyleOptions: CheckboxOption[] = [];
  granFondoOptions: CheckboxOption[] = [];
  gravelOptions: CheckboxOption[] = [];
  typesOptions: CheckboxOption[] = [];
  nonCompetitiveOptions: CheckboxOption[] = [];
  trainingRidesOptions: CheckboxOption[] = [];

  calendarOptions: CheckboxOption[] = [];
  specialCalendars: SpecialCalendar[] = [];
  calendarLoading = false;

  disciplines: Discipline[] = [];
  eventTypes: EventType[] = [];
  announcementAlert: Alert[] = [];
  logoAlert: Alert[] = [];
  calendarCodeState = false;
  displayDiscountMsg = false;
  displayFailedDiscountMsg = false;
  setEventDates: string[] = [];
  today: NgbDate;
  endDateMin: object;
  eventDatesChanged = false;
  regUrlLabel: string;
  regUrlPlaceholder: string;
  eventAnnouncementOptions: RadioOption[] = [];
  announcementType = 'url';

  startDateTimestamp: number;
  endDateTimestamp: number;

  initialStartDate: Date;
  initialEndDate: Date;
  initialEventDates: EventDate[] = [];

  announcementLabel = 'Choose file';
  logoLabel = 'Choose file';

  // commented out for MBR-2992
  // public subDisciplinesOptions: Array<SelectOption>;
  eventListingCardsLimit = 30;
  limitSelection: any = { limitSelection: 5 };

  showSaveExit = false;
  routerEvents: Subscription;
  navUrl = '';
  queueRoute = '/queue';
  skipNavCatch = false;
  showDateConfirmation = false;
  dateConfirmationMessage: string;

  lineItem: LineItem;

  permitFeeSku: string;
  permitFeeSeriesSku: string;
  permitFeeSeries10PlusSku: string;
  permitSkus: string[] = [];

  permitProductId: string;
  permit: Product;
  permitFee: ShopifyProductVariant;
  permitFeeSeries: ShopifyProductVariant;
  permitFeeSeries10Plus: ShopifyProductVariant;

  draftOrder: ShopifyDraftOrder;
  lineItems: LineItem[];
  previousFeeDays: number;

  permitStatuses = [
    'permit-in-progress', 'permit-in-review'
  ];

  constructor(
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private shopifyService: ShopifyService,
    public eventListing: EventListingService,
    public event: EventService,
    public clubs: ClubService,
    private router: Router,
    public upload: FileUploadService,
    private calendar: NgbCalendar,
    private parser: NgbDateParserFormatter,
    private permitService: PermitService,
    public profile: ProfileService,
  ) { }

  // convenience getter for easy access to form fields
  get f() { return this.eventForm.controls; }

  ngOnInit() {
    this.user = this.profile.currentUser;

    // Subscribe to draft order in cart component
    this.shopifyService.cartDraftOrder.subscribe(draftOrder => this.draftOrder = draftOrder);

    this.today = this.calendar.getToday();

    this.eventForm = this.formBuilder.group({
      event_organizer: [ this.eventListing.event_organizer, Validators.required ],
      event_organizer_comp_id: [ this.eventListing.event_organizer_comp_id ], // TODO: Should this be required?
      organizerName: [ this.eventListing.organizerName, Validators.required ],
      organizerEmail: [ this.eventListing.organizerEmail, Validators.required ],
      event_sponsoring_clubs: [''],
      event_name: [ '', Validators.required ],
      event_start_date: [{ year: 2019, month: 1, day: 1 }, Validators.required],
      event_end_date: [{ year: 2019, month: 12, day: 31 }, Validators.required],
      event_discipline: ['', Validators.required],
      event_competitive: [''],
      event_types: [''],
      event_special_calendars: [''],
      calendarCode: ['', Validators.required],
      event_bike_reg: [''],
      bike_reg_check: [''],
      // Only used for checkbox functionality - no equivalent API field
      other_reg_check: [''],
      // tslint:disable-next-line: max-line-length
      event_reg_url: ['', [Validators.required, urlValidator()]],
      event_website: ['', [Validators.required, urlValidator()]],
      event_facebook_url: ['', urlValidator()],
      event_twitter_url: ['', urlValidator()],
      event_youtube_url: ['', urlValidator()],
      event_instagram_url: ['', urlValidator()],
      event_announcement_switch: ['url'],
      event_announcement: [''],
      event_logo: ['']
    });

    this.eventAnnouncementOptions = [
      new RadioOption('url', 'url', 'Enter URL'),
      new RadioOption('upload', 'upload', 'Upload File')
    ];

    const disc = parseInt(this.eventListing.event_discipline);
    if (disc && !this.eventListing.isVirtual) {
      this.permitFeeSku = environment.disciplineSkus[disc].permitFee;
      this.permitFeeSeriesSku = environment.disciplineSkus[disc].permitFeeSeries;
      this.permitFeeSeries10PlusSku = environment.disciplineSkus[disc].permitFeeSeries10Plus;
      this.permitSkus.push(this.permitFeeSku);
      this.permitSkus.push(this.permitFeeSeriesSku);
      this.permitSkus.push(this.permitFeeSeries10PlusSku);
    }

    this.setDateCards();

    this.eventListing.getEventTypes().subscribe( data => {
      this.roadOptions = [
        new CheckboxOption('stage-control', 'stage', data[0].et_id.toString(), data[0].et_name),
        new CheckboxOption('omnium-control', 'omnium', data[1].et_id.toString(), data[1].et_name),
        // new CheckboxOption('series', 'series', data[3].et_id.toString(), data[3].et_name)
      ];
      this.cyclocrossOptions = [
        // new CheckboxOption('series', 'series', data[3].et_id.toString(), data[3].et_name)

      ];
      this.mtbOptions = [
        new CheckboxOption('stage-control', 'stage', data[0].et_id.toString(), data[0].et_name),
        // new CheckboxOption('series', 'series', data[3].et_id.toString(), data[3].et_name)
      ];
      this.cyclocrossOptions = [
        // new CheckboxOption('series', 'series', data[3].et_id.toString(), data[3].et_name)

      ];
      this.trackOptions = [
        new CheckboxOption('omnium-control', 'omnium', data[1].et_id.toString(), data[1].et_name),
        // new CheckboxOption('series', 'series', data[3].et_id.toString(), data[3].et_name)
      ];
      this.cyclocrossOptions = [
        // new CheckboxOption('series', 'series', data[3].et_id.toString(), data[3].et_name)

      ];
      this.bmxAndCxOptions = [
        new CheckboxOption('training-race', 'training-race', data[2].et_id.toString(), data[2].et_name),
        // new CheckboxOption('series', 'series', data[3].et_id.toString(), data[3].et_name)
      ];
      this.bmxFreeStyleOptions = [
        // new CheckboxOption('series', 'series', data[3].et_id.toString(), data[3].et_name)
      ];
      this.nonCompetitiveOptions = [
        // new CheckboxOption('series', 'series', data[3].et_id.toString(), data[3].et_name)
      ];
      this.trainingRidesOptions = [
        new CheckboxOption('training-series', 'training-series', data[4].et_id.toString(), data[4].et_name)
      ];
    });

    this.eventListing.getDisciplines().subscribe( data => {
      this.disciplines = this.eventListing.filterDisciplines(data);
      this.disciplines.forEach(discipline => {
        this.disciplineOptions.push( new SelectOption(discipline.cd_id.toString(), discipline.cd_value));
      });
      this.onChangeDiscipline();
    });

    this.f.event_name.setValue(this.eventListing.event_name);

    if (this.updateMode) {
      this.announcementLabel = this.eventListing.event_announcement;
      this.logoLabel = this.eventListing.event_logo;
      // Set initial date values to check for later changes
      this.initialStartDate = this.eventListing.event_start_date;
      this.initialEndDate = this.eventListing.event_end_date;
      this.event.getEventDates(this.eventListing.event_id).subscribe(dates => this.initialEventDates = this.event.sortEventDates(dates));
    }

    this.profile.getCurrentUser().subscribe(user => {
      this.profile.getUserPermissions().subscribe(permissions => {
        this.userRole = this.profile.setUserRole(permissions);
      });

      // Set organizer info from current user if not already set
      if (!this.eventListing.event_organizer) {
        this.f.event_organizer.setValue(user.profile_id);
        this.f.event_organizer_comp_id.setValue(user.profile_comp_id);
        this.f.organizerName.setValue(user.profile_first_name + ' ' + user.profile_last_name);
        this.f.organizerEmail.setValue(user.profile_email);
      }
    });

    this.f.event_discipline.setValue(this.eventListing.event_discipline || null);
    if (typeof this.eventListing.event_start_date === 'string') {
      this.eventListing.event_start_date = new Date(this.eventListing.event_start_date);
    }
    if (this.eventListing.event_start_date instanceof Date) {
      const date = this.eventListing.event_start_date;
      this.f.event_start_date.setValue(`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`);
    }
    if (typeof this.eventListing.event_end_date === 'string') {
      this.eventListing.event_end_date = new Date(this.eventListing.event_end_date);
    }
    if (this.eventListing.event_end_date instanceof Date) {
      const date = this.eventListing.event_end_date;
      this.f.event_end_date.setValue(`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`);
    }
    this.f.event_types.setValue(this.eventListing.event_types);
    this.f.event_sponsoring_clubs.setValue(this.setSponsoringClubValues());
    // Mark clubs control as pristine after setting intial value
    setTimeout(() => this.f.event_sponsoring_clubs.markAsPristine(), 1000);
    this.f.event_special_calendars.setValue(this.eventListing.event_special_calendars);
    this.f.calendarCode.setValue(this.eventListing.calendarCode);
    this.f.event_bike_reg.setValue(this.eventListing.event_bike_reg);
    this.setRegCheckValues();
    this.f.event_reg_url.setValue(this.eventListing.event_reg_url);
    this.f.event_website.setValue(this.eventListing.event_website);
    this.f.event_facebook_url.setValue(this.eventListing.event_facebook_url);
    this.f.event_twitter_url.setValue(this.eventListing.event_twitter_url);
    this.f.event_youtube_url.setValue(this.eventListing.event_youtube_url);
    this.f.event_instagram_url.setValue(this.eventListing.event_instagram_url);

    if (this.eventListing.event_announcement) {
      this.announcementType = this.eventListing.event_announcement.includes('laravel-api') ? 'upload' : 'url';
    }
    this.f.event_announcement_switch.setValue(this.announcementType);
    this.f.event_announcement.setValue(this.eventListing.event_announcement);

    this.f.event_logo.setValue(this.eventListing.event_logo);

    this.eventForm.valueChanges.subscribe(data => {
      this.searchOrganizer(data.event_organizer_comp_id);
      this.eventListing.event_organizer = data.event_organizer;
      this.eventListing.event_organizer_comp_id = data.event_organizer_comp_id;
      this.eventListing.organizerName = data.organizerName;
      this.eventListing.organizerEmail = data.organizerEmail;
      this.eventListing.event_name = data.event_name;
      this.eventListing.event_start_date = data.event_start_date;
      this.eventListing.event_end_date = data.event_end_date;
      this.eventListing.event_discipline = data.event_discipline;
      this.eventListing.event_competitive = data.event_competitive;
      this.eventListing.event_types = data.event_types;
      this.eventListing.event_sponsoring_clubs = data.event_sponsoring_clubs;
      this.eventListing.event_special_calendars = data.event_special_calendars;
      this.eventListing.calendarCode = data.calendarCode;
      this.eventListing.event_bike_reg = data.event_bike_reg;
      this.eventListing.event_reg_url = data.event_reg_url;
      this.eventListing.event_website = data.event_website;
      this.eventListing.event_facebook_url = data.event_facebook_url;
      this.eventListing.event_twitter_url = data.event_twitter_url;
      this.eventListing.event_youtube_url = data.event_youtube_url;
      this.eventListing.event_instagram_url = data.event_instagram_url;
      this.eventListing.event_announcement = data.event_announcement;
      this.announcementType = data.event_announcement_switch;
      this.eventListing.event_logo = data.event_logo;
      this.requireCalendarCode();
      this.setEndDateLimits();
      this.setDateCards();
    });

    this.f.event_discipline.valueChanges.subscribe(() => {
      this.onChangeSetTypesOptions();
      this.onChangeSetCalendarOptions();
    });

    this.routerEvents = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && this.minRequiredFields() && !this.skipNavCatch) {
        if (event.url !== '/event/review' && this.navUrl === '') {
          this.router.navigateByUrl(this.router.url, {replaceUrl: true});
          this.openSaveExitModal(event.url);
        }
      }
    });

    if (this.updateMode) {
      this.shopifyService.getProducts()
      .then(products => {
        products.forEach(product => {
          const permitId = this.shopifyService.setProductIdFromVariantSku(product, this.permitFeeSku);
          if (permitId) { this.permitProductId = permitId; }
        });
      });

      // Search orders & draft orders
      const ordersAndDrafts = [];
      const filters = { event_id: this.eventId, incomplete: true };
      this.shopifyService.getShopifyDraftOrders(filters).subscribe(drafts => {
        drafts.forEach(draft => ordersAndDrafts.push(draft));
        this.shopifyService.getShopifyOrders(filters).subscribe(orders => {
          orders.forEach(order => ordersAndDrafts.push(order));
          this.searchPermitFees(ordersAndDrafts);
        });
      });
    }

  }

  ngOnDestroy() {
    this.routerEvents.unsubscribe();
    this.closeSaveExitModal();
  }

  onChangeSetTypesOptions() {
    switch (this.f.event_discipline.value) {
      case '19': this.typesOptions = this.roadOptions; break;
      case '20': this.typesOptions = this.trackOptions; break;
      case '21': this.typesOptions = this.mtbOptions; break;
      case '22': this.typesOptions = this.bmxAndCxOptions; break;
      case '23': this.typesOptions = this.cyclocrossOptions; break;
      case '24': this.typesOptions = []; break;
      case '25':
      case '26':
      case '28': this.typesOptions = this.nonCompetitiveOptions; break;
      case '27': this.typesOptions = []; break;
      case '29': this.typesOptions = this.trainingRidesOptions; break;
      case '30': this.typesOptions = this.bmxFreeStyleOptions; break;
      default: this.typesOptions = [];
    }
  }

  onChangeSetCalendarOptions() {
    if (this.f.event_discipline.value) {
      this.calendarOptions = [];
      this.calendarLoading = true;
      this.event.getSpecialCalendars(this.f.event_discipline.value).subscribe(calendars => {
        this.specialCalendars = calendars;
        calendars.forEach(cal => {
          const val = cal.sc_name.toLowerCase().split(' ').join('-');
          this.calendarOptions.push(
            new CheckboxOption(cal.sc_id.toString(), cal.sc_name, cal.sc_id.toString(), cal.sc_name)
          );
        });
        this.calendarLoading = false;
      });
    }
  }

  requireCalendarCode() {
    if (this.eventListing.event_special_calendars === undefined
      || this.eventListing.event_special_calendars.length === 0 ) {
      this.calendarCodeState = false;
      return;
    }
    const codeCalendars = ['UCI', 'National Championship', 'National Calendar', 'National Team'];
    const codeCalendarIds = [];
    this.specialCalendars.filter(cal => codeCalendars.includes(cal.sc_name)).forEach(cal => {
      codeCalendarIds.push(cal.sc_id.toString());
    });

    this.calendarCodeState = this.eventListing.event_special_calendars.some(calId => codeCalendarIds.includes(calId));
  }

  searchOrganizer(organizerId) {
    if (organizerId && organizerId !== this.eventListing.event_organizer_comp_id) {
      if (this.searchOrganizerTimeout) {
        clearTimeout(this.searchOrganizerTimeout);
      }

      this.searchOrganizerTimeout = setTimeout(() => {
        this.profile.getMemberByCompId(this.f.event_organizer_comp_id.value).subscribe(member => {
          const name = member ? `${member.profile_first_name} ${member.profile_last_name}` : '';
          const email = member ? member.profile_email : '';
          if (member) { this.f.event_organizer.setValue(member.profile_id); }
          this.f.organizerName.setValue(name);
          this.f.organizerEmail.setValue(email);
        });
      }, this.searchOrganizerDelay);
    }
  }

  setSponsoringClubValues() {
    if (this.updateMode) {
      const clubs = [];
      this.eventListing.event_sponsoring_clubs.forEach(club => {
        clubs.push(new SelectOption(club.club_id, club.club_name));
      });
      return clubs;
    } else {
      return this.eventListing.event_sponsoring_clubs;
    }
  }

  setRegCheckValues() {
    if (this.eventListing.event_bike_reg && this.eventListing.event_bike_reg !== '') {
      this.f.bike_reg_check.setValue(this.eventListing.event_bike_reg === 'Y');
      this.f.other_reg_check.setValue(this.eventListing.event_bike_reg === 'N');
      this.regState = true;
      this.setRegLabelAndPlaceholder(this.eventListing.event_bike_reg);
      this.setRegValidationStatus();
    }
  }

  setEndDateLimits() {
    const start = this.f.event_start_date.value;
    const end = this.f.event_end_date.value;
    this.startDateTimestamp = new Date(start).getTime() / 1000;
    this.endDateTimestamp = new Date(end).getTime() / 1000;

    this.endDateMin = this.parser.parse(start);

    if (this.startDateTimestamp > this.endDateTimestamp) { this.f.event_end_date.setValue(start); }

    this.eventListing.diffDays = ((this.endDateTimestamp - this.startDateTimestamp) / (24 * 60 * 60)) + 1;

    const eventDateQuantity = this.eventListing.eventDates.length;
    if (eventDateQuantity && eventDateQuantity > this.eventListing.diffDays) {
      for (let i = eventDateQuantity - 1; i >= this.eventListing.diffDays; i--) {
        this.eventListing.removeEventDate(i);
      }
    }
    if (!this.eventListing.eventDates.length) { this.addEventListingDate(); }
  }

  validateCalendarCode() {
    setTimeout(() => {
      const calendarCode = this.f.calendarCode.value;

      // TODO: @Charlie, @Brennan - Should we use localStorage or add a column to the Event table?
      if (this.globalService.discountCodes.includes(calendarCode)) {
        this.displayDiscountMsg = true;
        this.displayFailedDiscountMsg = false;
        localStorage.setItem('calendarCode', calendarCode);
      } else {
        this.displayFailedDiscountMsg = true;
        this.displayDiscountMsg = false;
        localStorage.removeItem('calendarCode');
      }
    }, 5);
  }

  debug(event: any): void {
    console.log('debug form', this.f, event);
  }

  onSubmit() {
    this.postOrUpdateEvent();
  }

  onReset() {
    this.submitted = false;
    this.eventForm.reset();
  }

  addEventListingDate() {
    this.eventListing.createEventDate();
  }

  onChangeDiscipline() {
    // commented out for MBR-2992
    // this.subDisciplinesOptions = [];
    // this.disciplines.forEach( disc => {
    //   if (disc.cd_id === parseInt(this.f.event_discipline.value)) {
    //     disc.sub_disciplines.forEach( sub => {
    //       this.subDisciplinesOptions.push(new SelectOption(sub.subdiscipline_id.toString(), sub.subdiscipline_name));
    //     });
    //   }
    // });
    const selected = this.disciplineOptions.find(option => option.value === this.f.event_discipline.value);
    if (selected) {
      const competitive = this.eventListing.isEventCompetitive ? 'Y' : 'N';
      this.f.event_competitive.setValue(competitive);
    }
  }

  updateEventType($event: Array<string>) {
    this.eventListing.event_types = $event;
  }
  updateCalendars($event: Array<string>) {
    this.eventListing.event_special_calendars = $event;
  }

  clearMultiSelect() {
    this.f.event_sponsoring_clubs.reset([]);
  }

  setRegValidationStatus() {
    const event_bike_reg = this.eventForm.get('event_bike_reg');
    const event_reg_url = this.eventForm.get('event_reg_url');
    const calendarCode = this.eventForm.get('calendarCode');

    if (this.regState === true) {
      event_reg_url.setValidators([Validators.required, urlValidator()]);
    } else {
      event_reg_url.setValidators(null);
    }

    if (this.calendarCodeState === false) {
      calendarCode.setValidators(null);
    } else {
      calendarCode.setValidators([Validators.required]);
    }

    event_bike_reg.updateValueAndValidity();
    event_reg_url.updateValueAndValidity();
    calendarCode.updateValueAndValidity();
  }

  triggerUpdateListing() {
    if (
      this.isPermit
      && this.previousFeeDays > 0
      && this.eventListing.eventDates.length !== this.previousFeeDays
      && this.permitStatuses.includes(this.eventListing.event_status)
    ) {
      this.openFeeConfirmationModal();
    } else if (this.inPostEvent && (this.startOrEndDateChanged || this.eventDatesChanged)) {
      this.openDateConfirmationModal();
    } else {
      this.postOrUpdateEvent();
    }
  }

  assignFormData() {
    const event_id = this.eventListing.event_id;
    const event_contacts = this.eventListing.contacts.filter(contact => contact.isValid);
    const calendars = this.eventListing.event_special_calendars;
    const event_special_calendars = this.eventId ? calendars.map(cal => cal.calendar_id) : calendars;

    this.formData = Object.assign({}, this.eventForm.value, { event_id, event_contacts, event_special_calendars });
  }

  postOrUpdateEvent() {
    this.closeDateConfirmationModal();
    this.submitted = true;
    this.setRegValidationStatus();

    let eventCardsState = true;
    this.eventListing.eventDates.forEach((eventDate) => {
      if (!eventDate.isValid) {
        eventCardsState = false;
      }
    });

    // stop here if form is invalid
    if (this.eventForm.invalid || !eventCardsState) {
      this.handleValidationFeedback(eventCardsState);
      return;
    }

    this.submitting = true;
    this.skipNavCatch = true;
    this.assignFormData();

    // Update if event exists
    if (this.eventId) {
      this.event.updateEvent(this.formData, this.eventId).subscribe(event => {
        if (event) {
          if (this.isPermit) {
            this.checkDatesChanged();
          } else {
            this.postOrUpdateEventDates(this.eventId);
          }
        }
      });
    } else {
      // Post new event
      this.event.postEvent(this.formData).subscribe(event => {
        if (event) {
          this.event.updateEventStatus(event.event_id, 'listing-in-progress').subscribe(resp => {
            if (resp.message) {
              this.postOrUpdateEventDates(event.event_id);
            } else {
              this.submitting = false;
            }
          });
        } else {
          this.submitting = false;
        }
      });
    }
  }

  private handleValidationFeedback(eventCardsState: boolean): void {
    let fragment: string;
    if (this.eventForm.invalid) {
      const formControlSections = this.eventListing.formControlSections;
      const invalidControls = Object.entries(this.f).filter(([key, control]: [string, AbstractControl]) => control.invalid);
      for (let i = 0; i < formControlSections.length; i++) {
        if (i === 2) {
          fragment = !eventCardsState ? 'event-dates' : '';
        } else {
          fragment = this.searchInvalidControls(i, formControlSections[i], invalidControls);
        }

        if (fragment) {
          break;
        }
      }
    } else if (!eventCardsState) {
      fragment = 'event-dates';
    }

    if (fragment) {
      document.getElementById(fragment).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

  private searchInvalidControls(sectionIndex: number, formControlSection: string[], invalidControls: [string, AbstractControl][]): string {
    const sections = [ 'general-information', 'event-details', 'event-dates', 'marketing' ];
    for (const controlName of formControlSection) {
      if (invalidControls.some(control => control[0] === controlName)) {
        return sections[sectionIndex];
      }
    }
    return '';
  }

  postOrUpdateEventDates(eventId: number|string) {
    let count = 0;

    // Create new event dates & update existing ones
    this.eventListing.eventDates.forEach(date => {
      if (!date.event_date_id) {
        this.event.postEventDate(date, eventId).subscribe(resp => {
          if (resp) {
            count += 1;
            if (count === this.eventListing.eventDates.length) {
              this.deleteRemovedEventDates(eventId);
              this.toReview(eventId);
            }
          } else {
            this.submitting = false;
          }
        });
      } else {
        const eventDate = Object.assign({}, date, {event_date_chief_ref: null});
        this.event.editEventDate(eventDate, date.event_date_id).subscribe(resp => {
          if (resp) {
            count += 1;
            if (count === this.eventListing.eventDates.length) {
              this.deleteRemovedEventDates(eventId);
            }
          } else {
            this.submitting = false;
          }
        });
      }
    });
  }

  toReview(eventId: number|string) {
    this.router.navigate([`/event/${eventId}/review`]);
  }

  updateEventStatusAndDates(eventId) {
    const statuses = ['permit-approved', 'in-post-event'];

    if (statuses.includes(this.eventListing.event_status)) {
      this.event.updateEventStatus(eventId, 'permit-in-review').subscribe(resp => {
        if (resp.message) {
          this.postOrUpdateEventDates(eventId);
        } else {
          this.submitting = false;
        }
      });
    } else {
      this.postOrUpdateEventDates(eventId);
    }
  }

  clearCourseMapAnswer() {
    // Clear course map upload permit answer, if applicable per discipline
    this.permitService.deleteCourseMaps(this.eventListing.event_permit_id).subscribe();
  }

  searchPermitFees(orders) {
    let feeDays = 0;
    orders.forEach(order => {
      order.line_items.forEach(item => {
        if (this.permitSkus.includes(item.sku)) {
          feeDays += item.quantity;
        }
      });
    });
    this.previousFeeDays = feeDays;
  }


  deleteRemovedEventDates(eventId: number|string) {
    const currentEventDateIds = [];
    const eventDatesToRemove = [];
    this.eventListing.eventDates.forEach(date => {
      if (!!date.event_date_id) {
        currentEventDateIds.push(date.event_date_id);
      }
    });

    this.eventListing.initialEventDateIds.forEach(id => {
      if (!currentEventDateIds.includes(id)) {
        eventDatesToRemove.push(id);
      }
    });

    if (eventDatesToRemove.length) {
      let count = 0;
      eventDatesToRemove.forEach(id => {
        this.event.deleteEventDate(id).subscribe(resp => {
          if (resp) {
            count += 1;
            if (count === eventDatesToRemove.length) {
              this.updateFeesOrNavigate(eventId);
            }
          } else {
            this.submitting = false;
          }
        });
      });
    } else {
      this.updateFeesOrNavigate(eventId);
    }
  }

  updateFeesOrNavigate(eventId: number|string) {
    if (this.eventListing.event_permit_id) {
      this.updatePermitFees();
    } else {
      if (this.createMode || this.eventListing.event_status === 'listing-in-progress') {
        this.toReview(eventId);
      } else {
        this.continueNavigation(this.overviewRoute);
      }
    }
  }

  updatePermitFees() {
    const previous = this.previousFeeDays;
    const current = this.eventListing.eventDates.length;

    if (current > previous) {
      const addedDays = current - previous;

      const skus = [this.permitFeeSku, this.permitFeeSeriesSku, this.permitFeeSeries10PlusSku];

      this.shopifyService.getShopifyProductVariants({skus}).subscribe(variants => {
        this.permitFee = this.findVariant(variants, this.permitFeeSku);
        this.permitFeeSeries = this.findVariant(variants, this.permitFeeSeriesSku);
        this.permitFeeSeries10Plus = this.findVariant(variants, this.permitFeeSeries10PlusSku);

        if (this.isSeries) {
          if (!!this.eventListing.event_start_date && !!this.eventListing.event_end_date) {
            if (current <= 10 ) {
              const seriesItem: ShopifyLineItemBase = { variant_id: this.permitFeeSeries.id, quantity: addedDays };
              this.shopifyService.addToDraftOrder(seriesItem, this.draftOrder);
            } else if (current > 10 && previous <= 10) {
              const seriesItem: ShopifyLineItemBase = { variant_id: this.permitFeeSeries.id, quantity: addedDays };
              const seriesPlusItem: ShopifyLineItemBase = { variant_id: this.permitFeeSeries10Plus.id, quantity: 1 };
              this.shopifyService.addToDraftOrder(seriesItem, this.draftOrder);
              this.shopifyService.addToDraftOrder(seriesPlusItem, this.draftOrder);
            }
          }
        } else {
          const feeItem: ShopifyLineItemBase = { variant_id: this.permitFee.id, quantity: addedDays };
          this.shopifyService.addToDraftOrder(feeItem, this.draftOrder);
        }
        this.shopifyService.updateDraftOrderItems(this.draftOrder, `/event/${this.eventId}/add-ons`);
      });
    } else {
      this.continueNavigation(this.overviewRoute);
    }
  }

  findVariant(variants: ShopifyProductVariant[], sku: string) {
    return variants.find(variant => sku === variant.sku);
  }

  openSaveExitModal(url?: string) {
    if (url) { this.navUrl = url; }
    this.showSaveExit = true;
  }

  closeSaveExitModal(resetNavUrl = false) {
    this.showSaveExit = false;
    setTimeout(() => {
      if (!!resetNavUrl) {
        this.navUrl = '';
      }
    }, 1000);
  }

  toggleModal1(event) {
    this.submitModalOpen = !this.submitModalOpen;
  }

  closeSubmitModal(event) {
    this.submitModalOpen = false;
  }

  openFeeConfirmationModal() {
    this.dateConfirmationMessage = `You have updated the number of event dates for your listing.
      If additional permit fees apply, they will be added to your cart, and you will be redirected
      to the final checkout page.`;
    this.showDateConfirmation = true;
  }

  openDateConfirmationModal(): void {
    this.dateConfirmationMessage = `You have updated the dates for this permit. Because the
      post event period has started, please be aware that date changes may affect or even remove
      existing post event forms if they have not yet been completed and paid.`;
    this.showDateConfirmation = true;
  }

  closeDateConfirmationModal(): void {
    this.showDateConfirmation = false;
  }

  continueNavigation(url = this.queueRoute) {
    this.closeSaveExitModal();
    setTimeout(() => {
      this.navUrl = this.navUrl === '' ? url : this.navUrl;
      this.router.navigate([this.navUrl]);
    }, 50);
  }

  returnNavigation() {
    let url: string;
    url = this.createMode ? this.queueRoute : this.overviewRoute;
    this.router.navigate([url]);
  }

  postChanges() {
    this.submitModalOpen = false;
  }

  selectAnnouncementFile(event) {
    this.announcementAlert = [];
    if (event.target.files.length > 0) {
      const image = event.target.files[0];
      const fileExtn: string = image.name.split('.').pop();
      const formData: FormData = new FormData();
      formData.append('image', image);
      if (this.upload.serviceType.EVENT_ANNONCEMENT.extensions.indexOf(fileExtn.toLowerCase()) > -1) {
        this.upload.fileUpload(formData, this.eventId).subscribe(data => {
          this.announcementAlert =  [
            new Alert('success', image.name + ' was successfully uploaded.')
          ];
          this.announcementLabel = image.name;
          this.f.event_announcement.setValue(data['url']);   /* tslint:disable-line:no-string-literal */
        }, err => {
          this.announcementAlert =  [
            new Alert('warning', 'There was an error uploading your file: ' + err.message)
          ];
        });
      } else {
        this.announcementAlert =  [
          new Alert('warning', 'Invalid file type. Supported file extns are PDF, JPG or PNG ')
        ];
      }
    }
  }

  selectLogoFile(event) {
    this.logoAlert = [];
    if (event.target.files.length > 0) {
      const image = event.target.files[0];
      const fileExtn: string = image.name.split('.').pop();
      if (this.upload.serviceType.EVENT_LOGO.extensions.indexOf(fileExtn.toLowerCase()) > -1) {
        const formData: FormData = new FormData();
        formData.append('image', image);
        this.upload.fileUpload(formData, this.eventId).subscribe(data => {
          this.logoAlert =  [
            new Alert('success', image.name + ' was successfully uploaded.')
          ];
          this.logoLabel = image.name;
          this.f.event_logo.setValue(data['url']);   /* tslint:disable-line:no-string-literal */
         // tslint:disable-next-line: align
        }, err => {
          this.logoAlert =  [
            new Alert('warning', 'There was an error uploading your file: ' + err.message)
          ];
        });
      } else {
        this.logoAlert =  [
          new Alert('warning', 'Invalid file type. Supported file extns are SVG, JPG or PNG ')
        ];
      }
    }
  }
  toggleBikeRegState(event: any) {
    if (event && event.target) {
      if (event.target.checked) {
        const idKey = 'id';
        if (event.target[idKey] === 'event_bike_reg-form-control') {
          this.bikeRegCheckboxState = true;
          this.otherRegCheckboxState = false;
          this.regState = true;
          this.f.event_bike_reg.setValue('Y');
        } else if (event.target[idKey] === 'otherRegCheckbox-form-control') {
          this.bikeRegCheckboxState = false;
          this.otherRegCheckboxState = true;
          this.regState = true;
          this.f.event_bike_reg.setValue('N');
        }
        this.setRegLabelAndPlaceholder(this.f.event_bike_reg.value);
      } else {
        this.bikeRegCheckboxState = false;
        this.otherRegCheckboxState = false;
        this.regState = false;
        this.f.event_bike_reg.setValue(null);
        this.f.event_reg_url.setValue(null);
      }
      this.setRegValidationStatus();
    }
  }

  setRegLabelAndPlaceholder(bikeReg: string) {
    if (bikeReg === 'Y') {
      this.regUrlLabel = 'BikeReg URL';
      this.regUrlPlaceholder = 'bikereg.com/yourpage';
    } else {
      this.regUrlLabel = 'Registration URL';
      this.regUrlPlaceholder = '';
    }
  }

  minRequiredFields() {
    const form = this.eventForm.value;
    return !!(form.event_name && form.event_discipline && form.event_start_date && form.event_end_date && form.event_website);
  }

  displayReviewBtn() {
    return this.createMode || this.eventListing.event_status === 'listing-in-progress';
  }

  saveAndExit() {
    if (this.updateMode) {
      this.postOrUpdateEvent();
    } else {
      if (this.minRequiredFields()) {
        const event_contacts = this.eventListing.contacts.filter(contact => contact.isValid);
        const data = Object.assign({}, this.eventForm.value, { event_contacts });

        this.event.postEvent(data).subscribe(event => {
          let dates = 0;
          const validDates = this.eventListing.eventDates.filter(date => date.isValid);
          const totalDates = validDates.length;

          if (totalDates > 0) {
            validDates.forEach(date => {
              this.event.postEventDate(date, event.event_id).subscribe(ed => {
                if (Object.keys(ed).includes('event_date_id')) { dates += 1; }
                if (dates === totalDates) {
                  // Set event status to 'Listing in Progress'
                  this.event.updateEventStatus(event.event_id, 'listing-in-progress').subscribe(resp => {
                    if (resp.message) {
                      this.continueNavigation();
                    } else {
                      // TODO: Consider more explicit error handling
                    }
                  });
                }
              });
            });
          } else {
            // Set event status to 'Listing in Progress'
            this.event.updateEventStatus(event.event_id, 'listing-in-progress').subscribe(resp => {
              if (resp.message) {
                this.continueNavigation();
              } else {
                // TODO: Consider more explicit error handling
              }
            });
          }
        });
      }
    }
  }

  setDateCards() {
    if (this.createMode && !this.eventListing.eventDates.length) {
      this.eventListing.eventDates.push(new EventDate());
    }
  }

  // TODO: Update logic in Permitting II (will know if editing by event_id in the listing form route)
  get existingEvent(): boolean {
    return !!this.eventListing.event_id;
  }

  get overviewRoute(): string {
    const eventId = this.eventListing.event_id;
    return eventId ? `/event/${eventId}/overview` : this.queueRoute;
  }

  get isPermit(): boolean {
    return !!this.eventListing.event_permit_id;
  }

  get isSeries(): boolean {
    return this.eventListing.event_types.includes('4');
  }

  get isVirtual(): boolean {
    return this.eventListing.event_discipline === '38';
  }

  get createMode(): boolean {
    return this.eventListing.mode === 'create';
  }

  get updateMode(): boolean {
    return this.eventListing.mode === 'update';
  }

  get isUsacAdmin(): boolean {
    return this.userRole === 'usac_admin';
  }

  get disableDateEdit(): boolean {
    return this.existingEvent && !this.isUsacAdmin;
  }

  get showEventDateAddBtn(): boolean {
    return (
      this.eventListing.eventDates.length < 30 &&
      (this.eventListing.event_start_date !== this.eventListing.event_end_date) &&
      (this.eventListing.eventDates.length < this.eventListing.diffDays) &&
      !this.disableDateEdit
    );
  }

  get eventDates(): boolean {
    return this.eventListing.eventDates.length > 0;
  }

  get eventStarted(): boolean {
    if (this.initialEventDates.length) {
      const startDate = moment(this.initialEventDates[0].event_date_start).startOf('day');
      const today = moment().startOf('day');
      return startDate <= today;
    } else {
      return false;
    }
  }

  get inPostEvent(): boolean {
    return this.eventListing.event_status === 'in-post-event';
  }

  eventDateStarted(eventDate: EventDate): boolean {
    if (this.isPermit) {
      const startDate = moment(eventDate.event_date_start).startOf('day');
      const today = moment().startOf('day');
      return startDate <= today;
    } else {
      return false;
    }
  }

  get startOrEndDateChanged(): boolean {
    return (
      !this.datesMatch(this.initialStartDate, this.f.event_start_date.value)
      || !this.datesMatch(this.initialEndDate, this.f.event_end_date.value)
    );
  }

  // Triggers available dates calculation inside event date cards
  handleEventDateChange(): void {
    this.eventDatesChanged = true;
    setTimeout(() => this.eventDatesChanged = false, 500);
  }

  get datesChanged(): boolean {
    if (this.eventListing.eventDates.some(date => !date.event_date_id) ||
      this.eventListing.eventDates.length < this.initialEventDates.length
    ) {
      return true;
    }
    let changedDates = 0;
    this.initialEventDates.forEach(initDate => {
      this.eventListing.eventDates.forEach(date => {
        if (
          initDate.event_date_id === date.event_date_id &&
          (
            !this.datesMatch(new Date(initDate.event_date_start), date.event_date_start) ||
            !this.subDisciplinesMatch(initDate, date)
          )
        ) {
          changedDates += 1;
        }
      });
    });

    return changedDates > 0;
  }

  checkDatesChanged() {
    // Check for new or removed event dates
    if (this.eventDatesChanged) {
      this.clearCourseMapAnswer();
      this.updateEventStatusAndDates(this.eventId);
    // Check if start or end date changed
    } else if (this.startOrEndDateChanged) {
      this.updateEventStatusAndDates(this.eventId);
    } else {
      this.postOrUpdateEventDates(this.eventId);
    }
  }

  datesMatch(initialDate: Date, currentDateString: string): boolean {
    return moment(initialDate).format('M/D/Y') === currentDateString;
  }

  subDisciplinesMatch(initialEventDate: EventDate, currentEventDate: EventDate): boolean {
    const initialSubdiscs: number[] = [];
    const currentSubdiscs: number[] = [];
    initialEventDate.event_date_sub_disciplines.forEach(subdisc => initialSubdiscs.push(subdisc.subdiscipline_id));
    currentEventDate.event_date_sub_disciplines.forEach(subdisc => currentSubdiscs.push(parseInt(subdisc.value)));
    return JSON.stringify(initialSubdiscs.sort()) === JSON.stringify(currentSubdiscs.sort());
  }
}
