import { OnChanges, EventEmitter } from '@angular/core';
import { DriverVehicleService } from 'src/app/services';
var AddVehicleDriverFormComponent = /** @class */ (function () {
    function AddVehicleDriverFormComponent(driverVehicleService) {
        this.driverVehicleService = driverVehicleService;
        this.modalOpen = false;
        this.vehicles = [];
        this.vehicleQuantity = 0;
        this.driverQuantity = 0;
        this.quantityChange = new EventEmitter();
    }
    AddVehicleDriverFormComponent.prototype.ngOnChanges = function () {
        this.vehicles = this.type === 'auto' ? this.autoVehicles : this.motoVehicles;
    };
    AddVehicleDriverFormComponent.prototype.openModal = function () {
        this.modalOpen = true;
    };
    AddVehicleDriverFormComponent.prototype.closeModal = function () {
        this.modalOpen = false;
    };
    AddVehicleDriverFormComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.autoMotoComplete) {
            this.driverVehicleService.submitDriversAndVehicles(this.permitId).subscribe(function () { return _this.closeModal(); });
        }
    };
    AddVehicleDriverFormComponent.prototype.onQuantityChange = function (event) {
        if (event.model === 'vehicle') {
            this.vehicleQuantity = event.quantity;
        }
        else if (event.model === 'driver') {
            this.driverQuantity = event.quantity;
        }
        if (this.driverQuantity >= this.quantity && this.vehicleQuantity === this.quantity) {
            this.quantityChange.emit({ type: this.type, status: 'Complete' });
        }
        else {
            this.quantityChange.emit({ type: this.type, status: 'Incomplete' });
        }
    };
    Object.defineProperty(AddVehicleDriverFormComponent.prototype, "title", {
        get: function () {
            var type = this.type === 'auto' ? 'Auto' : 'Motorcycle';
            return type + ' Liability Insurance - Add Vehicles and Drivers';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddVehicleDriverFormComponent.prototype, "autoMotoComplete", {
        get: function () {
            if (this.hasAuto && this.hasMoto) {
                return this.autoComplete && this.motoComplete;
            }
            if (this.hasAuto) {
                return this.autoComplete;
            }
            if (this.hasMoto) {
                return this.motoComplete;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    return AddVehicleDriverFormComponent;
}());
export { AddVehicleDriverFormComponent };
