import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommentService, ProfileService } from '../../services';
import { Comment, Event } from '../../classes';
var OverviewCommentsComponent = /** @class */ (function () {
    function OverviewCommentsComponent(formBuilder, commentService, profileService) {
        this.formBuilder = formBuilder;
        this.commentService = commentService;
        this.profileService = profileService;
        this.submitted = false;
        this.loading = true;
        this.comments = [];
        this.recipientOptions = [];
    }
    Object.defineProperty(OverviewCommentsComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.commentForm.controls; },
        enumerable: true,
        configurable: true
    });
    OverviewCommentsComponent.prototype.ngOnInit = function () {
        this.commentForm = this.formBuilder.group({
            message: ['', Validators.required],
            scope: ['all', Validators.required]
        });
    };
    OverviewCommentsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.userRole && changes.userRole.currentValue !== '') {
            this.setRecipientOptions();
            this.loadComments();
        }
    };
    OverviewCommentsComponent.prototype.loadComments = function () {
        var _this = this;
        this.commentService.getCommentsForEvent(this.event.event_id).subscribe(function (comments) {
            // TODO: Remove client side scope filtering once implemented by the API
            _this.comments = comments.filter(function (comment) {
                switch (_this.userRole) {
                    case 'usac_admin':
                        return true;
                    case 'la_admin':
                        return ['all', 'la_usac_admin'].includes(comment.scope);
                    default:
                        return ['all', 'rd_usac_admin'].includes(comment.scope);
                }
            });
            _this.loading = false;
        });
    };
    OverviewCommentsComponent.prototype.setRecipientOptions = function () {
        switch (this.userRole) {
            case 'usac_admin':
                this.recipientOptions = this.commentService.usacAdminRecipients;
                break;
            case 'la_admin':
                this.recipientOptions = this.commentService.laAdminRecipients;
                break;
            default:
                this.recipientOptions = this.commentService.raceDirectorRecipients;
        }
    };
    Object.defineProperty(OverviewCommentsComponent.prototype, "showRecipients", {
        get: function () {
            return this.userRole === 'usac_admin' || this.userRole === 'la_admin' || this.isEventOrganizer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OverviewCommentsComponent.prototype, "isEventOrganizer", {
        get: function () {
            var currentUser = this.profileService.currentUser;
            return currentUser && currentUser.profile_id === this.event.event_organizer;
        },
        enumerable: true,
        configurable: true
    });
    OverviewCommentsComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        // console.log('submitting form', this.f);
        // stop here if form is invalid
        if (this.commentForm.invalid) {
            return;
        }
        this.commentService.addCommentForEvent(this.event.event_id, new Comment(this.commentForm.value.message, this.commentForm.value.scope)).subscribe(function () { return _this.loadComments(); });
        this.submitted = false;
        this.commentForm.reset();
        this.commentForm.controls.scope.setValue('all');
    };
    return OverviewCommentsComponent;
}());
export { OverviewCommentsComponent };
