<form [formGroup]="permitPart2Form" (ngSubmit)="onSubmit()">
  <div class="space-below-lg">
    <h3>Course Information And Approvals</h3>
  </div>
  <div class="course-map-section space-below-lg" *ngIf = "showCourseMapUpload()">
    <h4>Course Map Upload <span class="icon icon-information" [ngbTooltip]="tipContent"><ng-template #tipContent>Please provide a link or PDF file of your course map. This will allow riders to access this information through the USA Cycling Event Calendar, as well as help with approval during the permit process.</ng-template></span></h4>
    <div class="row">
        <div class="col-lg-6 mb-4" *ngFor="let eventDate of eventListing.eventDates; let pos=index">
          <div class="container-fluid" *ngFor="let courseMapItem of permit.courseMaps[pos]; let courseMapIndex = index" style="margin-top: -1px;">
            <app-event-course-map
              [submitted]="submitted"
              [eventId]="eventId"
              [eventDate]="eventDate"
              [index]="pos"
              [title]="(courseMapIndex == 0)"
              [courseMap]="courseMapItem"
              [courseMapId]="courseMapIndex"
              (formChange)="onCourseMapChange($event)"
            ></app-event-course-map>
          </div>
          <!-- commented out for MBR-2992 -->
          <!-- <div class="mt-2 space-below-md px-4">
            <app-button
                *ngIf = "permit.getSubDisciplines(pos).length < eventListing.getSelectedSubDisciplines().length"
                [type]="'btn-secondary'"
                [mode] = "'button'"
                id="add-{{eventDate.event_date_id}}"
                [disabled]="disableAddBtn(eventDate.event_date_id)"
                (click)= "permit.addAnotherCourseMap(pos)">Add Another Course Map</app-button>
          </div> -->
        </div>
    </div>
  </div>
  <div class="row space-below-sm">
    <div class="col-sm-10">
      <app-radio-group
      formControlName="localPermitApproval"
      [name]="'localPermitApproval'"
      [label]="'Have you secured all local permits and approvals to hold this event?'"
      [options]="localPermitApprovalOptions"
      [formSubmitted]="submitted"
      (valueChange)="updateApprovalInfo($event)"
      [errors]="f.localPermitApproval.errors"
      [required]=true></app-radio-group>
    </div>
  </div>
  <div class="row" *ngIf="courseMarkingOptions" >
    <div class="col-sm-10" *ngIf="(courseMarkingOptions.length > 0)">
      <app-checkbox-group
        formControlName="courseMarking"
        [inline]="false"
        [label]="'What type of course marking and equipment are being used (check all that apply)'"
        [options]="courseMarkingOptions"
        [formSubmitted]="submitted"
        [errors]="f.courseMarking.errors"
        (valueChange)="updateCourses($event)"
        [required]=true
      ></app-checkbox-group>
    </div>
  </div>
  <!-- <div class="row space-below-sm">
    <div class="col-sm-10">
      <app-radio-group
      formControlName="policeSecurity"
      [name]="'policeSecurity'"
      [label]="'Will you be utilizing police services at the event?'"
      [options]="securityOptions"
      [formSubmitted]="submitted"
      (valueChange)="updateSecurityInfo($event)"
      [errors]="f.policeSecurity.errors"
      [required]=true></app-radio-group>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
    <p>List name/contact/agency for security/police services</p>
    </div>
  </div>
  <div class="space-below-sm text-primary small" *ngIf="(permit.securityContacts.length >= 5)" >
    You can't add more than five contacts
  </div>
  <div class="row space-below-sm">
    <div class="col-sm-12">
        <div formArrayName="securityContacts">
          <div [formGroupName]="i"  *ngFor="let contact of Controls; let i = index">
              <div class ="row align-items-center justify-content-start">
                <div class="col-sm-9">
                  <div class="row align-items-center justify-content-start">
                    <div class="col-sm-4">
                        <app-text-input
                        formControlName="securityContactName"
                        [type]="'text'"
                        [id] = "'securityContactName' + i"
                        [name]="'securityContactName'"
                        [label]="'Contact Name'"
                        [placeholder]="''"
                        [formSubmitted]="submitted"
                        [errors]="contact.controls.securityContactName.errors"
                        ></app-text-input>
                      </div>
                      <div class="col-sm-4">
                        <app-text-input
                        formControlName="securityContactEmail"
                        [type]="'email'"
                        [id]= "'securityContactEmail' + i"
                        [name]="'securityContactEmail'"
                        [label]="'Contact Email'"
                        [placeholder]="''"
                        [formSubmitted]="submitted"
                        [errors]="contact.controls.securityContactEmail.errors"
                        ></app-text-input>
                      </div>
                      <div class="col-sm-4">
                        <app-text-input
                          formControlName="securityContactPhone"
                          [type]="'tel'"
                          [id]= "'securityContactPhone' + i"
                          [name]="'securityContactPhone'"
                          [label]="'Contact Phone'"
                          [placeholder]="''"
                          [formSubmitted]="submitted"
                          [errors]="contact.controls.securityContactPhone.errors"
                          ></app-text-input>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3" *ngIf = "permit.securityContacts.length > 1">
                      <span class="cursor-pointer" (click)="removeContact(i)"><i class = "icon icon-delete"></i></span>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="space-below-sm" *ngIf="(permit.securityContacts.length < 5)">
        <app-button
          [type]="'btn-secondary'"
          [mode] = "'button'"
          (click)= "addSecurityContact()">Add Additional Contact</app-button>
    </div> -->
    <div class="row">
      <div class="col-sm-6">
        <app-button routerLink="/event/{{eventId}}/permit-application/part-1" [mode] = "'button'" [type]="'btn-light'" [link]="''">Go Back</app-button>
      </div>
      <div class="col-sm-6 text-right" *ngIf="this.permit.mode === 'create'">
        <app-button
        [type]="'btn-secondary'"
        >Save & Continue</app-button>
      </div>
      <div class="col-sm-6 text-right" *ngIf="this.permit.mode === 'update'">
          <app-button
          [type]="'btn-secondary'"
          >Update & Continue</app-button>
      </div>
    </div>
</form>
