<!-- Loader -->
<app-loader *ngIf="loading"></app-loader>

<div *ngIf="postEvent && !loading">
  <!-- Event Participants -->
  <!-- <div class="space-below-xl">
    <app-post-event-form-table
      [isSeries]="isSeries"
      [title]="'Number of Event Participants'"
      [section]="'participants'"
      [formGroup]="participantForm"
      [groupedControls]="groupedParticipantControls"
      [eventDate]="eventDate"
      [eventDates]="eventDates"
      [discipline]="event.event_discipline"
      [maxQuantity]="maxQuantity"
      [submitted]="submitted"
    ></app-post-event-form-table>
  </div> -->

  <!-- Rider Feees -->
  <div class="space-below-xl" id="rider">
    <app-post-event-form-table
      [isSeries]="isSeries"
      [title]="'Rider Fees'"
      [instructions]="riderInstructions"
      [section]="'riders'"
      [formGroup]="riderForm"
      [groupedControls]="groupedRiderControls"
      [eventDate]="eventDate"
      [eventDates]="eventDates"
      [discipline]="event.event_discipline"
      [variants]="riderSurchargeVariants"
      [minQuantity]="minQuantity"
      [maxQuantity]="maxQuantity"
      [submitted]="submitted"
    ></app-post-event-form-table>
  </div>

  <!-- Licenses & Memberships -->
  <div class="space-below-xl" id="licenseMembership">
    <app-post-event-form-table
      [title]="'Licenses & Memberships'"
      [section]="'licensesMemberships'"
      [formGroup]="licenseMembershipForm"
      [discipline]="event.event_discipline"
      [variants]="licenseMembershipVariants"
      [variantTotals]="variantTotals"
      [excludeFromCharges]="excludeFromCharges"
      [maxQuantity]="maxQuantity"
      [submitted]="submitted"
    ></app-post-event-form-table>

    <form [formGroup]="paperworkForm" class="form-sm mt-3" id="paperwork">
      <app-checkbox *ngIf="isUsacAdmin"
        formControlName="post_event_paperwork_license_memberships"
        [id]="'post_event_paperwork_license_memberships'"
        [name]="'post_event_paperwork_license_memberships'"
        [label]="'On-site licenses & memberships paperwork received'"
        [checked]="true"
        [formSubmitted]="submitted"
      ></app-checkbox>
    </form>
  </div>

  <!-- Post Event Fees -->
  <div class="space-below-xl" id="feeTotals">
    <app-post-event-form-table *ngIf="userRole"
      [title]="'Post Event Fees'"
      [section]="'fees'"
      [event]="event"
      [postEvent]="postEvent"
      [discipline]="event.event_discipline"
      [userRole]="userRole"
      [riderSurchargeTotal]="riderSurchargeTotal"
      [licenseTotal]="licenseTotal"
      [membershipTotal]="membershipTotal"
      [lateFee]="lateFee"
      [minimumRiderSurchargeApplied]="minimumRiderSurchargeApplied"
      [eventIncentivesDiscountAmt]="eventIncentivesDiscountAmt"
      [lateFeePrice]="lateFeePrice"
      [draftOrder]="draftOrder"
      [orders]="orders"
      [calendarLabels]="calendarLabels"
      [calendarLabelQuestions]="calendarLabelQuestions"
      [calendarLabelAnswers]="calendarLabelAnswers"
      [updating]="updating"
      [submitted]="submitted"
      (feeFormChange)="updateFeeTotalsForm($event)"
      (eventIncentiveDiscountQualification)="onDiscountQualificationChange($event)"
      ></app-post-event-form-table>
  </div>

  <div class="row">
    <div class="col-sm">
      <app-button
        [mode]="'button'"
        [type]="'btn-light'"
        (click)="toPart1()"
        [disabled]="disableBtns"
      >Go Back</app-button>
    </div>
    <div class="col-sm text-right">
      <app-button
        [mode]="'button'"
        [type]="'btn-secondary'"
        (click)="onSubmit('pay-later')"
        [disabled]="disableBtns"
      >{{payLaterBtnText}}</app-button>
      <app-button
        [mode]="'button'"
        [type]="'btn-primary'"
        (click)="onSubmit('pay-now')"
        [disabled]="disableBtns"
      >{{payNowBtnText}}</app-button>
    </div>
  </div>
</div>

<app-alert *ngIf="displayAlert" [alerts]="alerts"></app-alert>
