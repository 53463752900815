import { Component, OnInit, Input } from '@angular/core';
import { Upload, Event } from 'src/app/classes';
import { FileUploadService } from 'src/app/shared/services';

@Component({
  selector: 'app-event-docs',
  templateUrl: './event-docs.component.html',
  styles: []
})
export class EventDocsComponent implements OnInit {

  @Input() event: Event;

  eventDocs: Upload[] = [];
  loading = true;

  constructor(private uploadService: FileUploadService) { }

  ngOnInit() {
    this.uploadService.getUploadsForEvent(this.event.event_id).subscribe(uploads => {
      this.eventDocs = uploads.reverse();
      this.loading = false;
    });
  }

  openDoc(url: string) {
    window.open(url, '_blank');
  }

}
