import * as tslib_1 from "tslib";
import GraphQLJSClient from 'graphql-js-client';
import typeBundle from './types';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BaseService } from '../../services/base-service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
var ShopifyService = /** @class */ (function (_super) {
    tslib_1.__extends(ShopifyService, _super);
    function ShopifyService(http, cookie, router) {
        var _this = _super.call(this, http, cookie) || this;
        _this.http = http;
        _this.cookie = cookie;
        _this.router = router;
        _this.cartDraftOrder = new BehaviorSubject(null);
        _this.cartProducts = new BehaviorSubject([]);
        // cartProductImages: BehaviorSubject<ShopifyProductImage[]> = new BehaviorSubject([]);
        _this.eventDraftOrders = new BehaviorSubject([]);
        _this.eventOrders = new BehaviorSubject([]);
        _this.cartUpdating = false;
        // TODO: Update with support for Shopify Admin API proxy usage (via NextGen Laravel API proxy, etc)
        _this.client = new GraphQLJSClient(typeBundle, {
            url: environment.url,
            fetcherOptions: {
                headers: {
                    'X-Shopify-Storefront-Access-Token': environment.shopifyaccesstoken
                }
            }
        });
        return _this;
    }
    ShopifyService.prototype.createDraftOrder = function (lineItems, tags, applied_discount, note, customer) {
        var _this = this;
        if (tags === void 0) { tags = []; }
        if (applied_discount === void 0) { applied_discount = null; }
        if (note === void 0) { note = null; }
        if (customer === void 0) { customer = null; }
        var body = {
            method: 'post',
            path: '/draft_orders',
            body: {
                draft_order: {
                    customer: customer,
                    line_items: lineItems.map(function (item) {
                        var variant_id = item.variant_id;
                        var quantity = item.quantity;
                        if (!/^[0-9]+$/.test(variant_id)) {
                            variant_id = atob(variant_id).replace(/[^0-9]+/g, '');
                        }
                        return {
                            quantity: quantity,
                            variant_id: variant_id
                        };
                    }),
                    tags: tags.join(','),
                    applied_discount: applied_discount,
                    note: note
                }
            }
        };
        // console.log('Draft order proxy body', body);
        return this.http.post(this.url('shopify/proxy'), body, this.options)
            .pipe(tap(function (_) { return _this.log('created shopify draft_order'); }), catchError(this.handleError('createDraftOrder', {}, { lineItems: lineItems })));
    };
    ShopifyService.prototype.updateDraftOrder = function (draftOrderId, lineItems, tags, applied_discount, note) {
        var _this = this;
        if (tags === void 0) { tags = []; }
        if (applied_discount === void 0) { applied_discount = null; }
        if (note === void 0) { note = null; }
        var body = {
            method: 'put',
            path: "/draft_orders/" + draftOrderId,
            body: {
                draft_order: {
                    id: draftOrderId,
                    line_items: lineItems.map(function (item) {
                        var variant_id = item.variant_id;
                        var quantity = item.quantity;
                        var lineItemDiscount = item.applied_discount || null;
                        if (!/^[0-9]+$/.test(variant_id)) {
                            variant_id = atob(variant_id).replace(/[^0-9]+/g, '');
                        }
                        if (lineItemDiscount) {
                            return {
                                quantity: quantity,
                                variant_id: variant_id,
                                applied_discount: lineItemDiscount
                            };
                        }
                        else {
                            return {
                                quantity: quantity,
                                variant_id: variant_id
                            };
                        }
                    }),
                    tags: tags.join(','),
                    applied_discount: applied_discount,
                    note: note
                }
            }
        };
        // console.log('Draft order proxy body', body);
        return this.http.post(this.url('shopify/proxy'), body, this.options)
            .pipe(tap(function (_) { return _this.log('updated shopify draft_order'); }), catchError(this.handleError('updateDraftOrder', {}, { draftOrderId: draftOrderId, lineItems: lineItems })));
    };
    // TODO: Update logic once Permitting II complete (delete draft orders directly from Nextgen)
    ShopifyService.prototype.deleteDraftOrder = function (draftOrderId) {
        var _this = this;
        var body = {
            method: 'delete',
            path: "/draft_orders/" + draftOrderId
        };
        return this.http.post(this.url('shopify/proxy'), body, this.options)
            .pipe(tap(function (_) { return _this.log('deleted shopify draft order'); }), catchError(this.handleError('deleteDraftOrder', null, { draftOrderId: draftOrderId })));
    };
    ShopifyService.prototype.getShopifyDraftOrders = function (filters) {
        var _this = this;
        return this.http.get(this.url('shopify/draft_orders', filters), this.options)
            .pipe(tap(function (draftOrders) {
            _this.log('fetched draft orders');
            _this.eventDraftOrders.next(draftOrders);
        }), catchError(this.handleError('getShopifyDraftOrders', [], { filters: filters })));
    };
    ShopifyService.prototype.getShopifyOrders = function (filters) {
        var _this = this;
        return this.http.get(this.url('shopify/orders', filters), this.options)
            .pipe(tap(function (_) { return _this.log('fetched orders'); }), catchError(this.handleError('getShopifyOrders', [], { filters: filters })));
    };
    ShopifyService.prototype.getShopifyOrderTransactions = function (filters) {
        var _this = this;
        return this.http.get(this.url('shopify/order_transactions', filters), this.options)
            .pipe(tap(function (_) { return _this.log('fetched shopify order transactions'); }), catchError(this.handleError('getShopifyOrderTransactions', null, { filters: filters })));
    };
    ShopifyService.prototype.getShopifyProductImages = function (filters) {
        var _this = this;
        return this.http.get(this.url('shopify/product_images', filters), this.options)
            .pipe(tap(function (_) { return _this.log('fetched shopify product images'); }), catchError(this.handleError('getShopifyProductImages', null, { filters: filters })));
    };
    ShopifyService.prototype.getShopifyProducts = function (filters) {
        var _this = this;
        return this.http.get(this.url('shopify/products', filters), this.options)
            .pipe(tap(function (_) { return _this.log('fetched Products'); }), catchError(this.handleError('getShopifyProducts', [], { filters: filters })));
    };
    ShopifyService.prototype.getShopifyProductVariants = function (filters) {
        var _this = this;
        return this.http.get(this.url('shopify/product_variants', filters), this.options)
            .pipe(tap(function (_) { return _this.log('fetched variants'); }), catchError(this.handleError('getShopifyVariants', [], { filters: filters })));
    };
    ShopifyService.prototype.getCurrentShop = function () {
        var client = this.client;
        var query = client.query(function (root) {
            root.add('shop', function (shop) {
                shop.add('name');
            });
        });
        return client.send(query);
    };
    // TODO: Lookup via Laravel API + Shopify proxy (could be real-time proxy to direct Shopify products, or loaded by list in nextgen DB)
    ShopifyService.prototype.getProductById = function (id) {
        var client = this.client;
        var query = client.query(function (root) {
            root.add('node', { args: { id: id }, alias: 'product' }, function (node) {
                node.add('id');
                node.addInlineFragmentOn('Product', function (product) {
                    product.add('title');
                    product.add('createdAt');
                    product.add('descriptionHtml');
                    product.add('productType');
                    product.add('publishedAt');
                    product.add('tags');
                    product.add('updatedAt');
                    product.add('vendor');
                    // Product.addConnection('metafields', { args: { namespace: 'test', first: 250 } }, (metafields) => {
                    //   metafields.add('content');
                    // })
                    product.addConnection('images', { args: { first: 250 } }, function (images) {
                        images.add('src');
                        images.add('id');
                        images.add('altText');
                    });
                    product.addConnection('variants', { args: { first: 250 } }, function (variants) {
                        variants.add('id');
                        variants.add('product');
                        variants.add('title');
                        variants.add('price');
                        variants.add('sku');
                        variants.add('image', function (image) {
                            image.add('src');
                            image.add('id');
                            image.add('altText');
                        });
                    });
                });
            });
        });
        return client.send(query).then(function (_a) {
            var model = _a.model, data = _a.data;
            return client.fetchAllPages(model.product, { pageSize: 250 });
        });
    };
    // TODO: Lookup via Laravel API + Shopify proxy (could be real-time proxy to direct Shopify products, or loaded by list in nextgen DB)
    ShopifyService.prototype.getProducts = function () {
        var _this = this;
        var query = this.client.query(function (root) {
            root.add('shop', function (shop) {
                shop.addConnection('products', { args: { first: 250 } }, function (products) {
                    products.add('id');
                    products.add('title');
                    products.add('tags');
                    products.addConnection('images', { args: { first: 250 } }, function (images) {
                        images.add('src');
                        images.add('id');
                        images.add('altText');
                    });
                    products.addConnection('collections', { args: { first: 250 } }, function (collections) {
                        collections.add('title');
                    });
                    products.addConnection('variants', { args: { first: 250 } }, function (variants) {
                        variants.add('title');
                        variants.add('sku');
                    });
                });
            });
        });
        return this.client.send(query).then(function (_a) {
            var model = _a.model, data = _a.data;
            return _this.client.fetchAllPages(model.shop.products, { pageSize: 20 });
        });
    };
    ShopifyService.prototype.setProductIdFromSku = function (product, sku, variantIndex) {
        if (variantIndex === void 0) { variantIndex = 0; }
        var id;
        id = product.variants[variantIndex].sku === sku ? product.id : null;
        return id;
    };
    ShopifyService.prototype.setProductIdFromVariantSku = function (product, sku) {
        var id;
        id = product.variants.find(function (variant) { return variant.sku === sku; }) ? product.id : null;
        return id;
    };
    // TODO: Can the sku => variant_id lookup be automated on the API side when this is talking to Shopify?
    ShopifyService.prototype.getVariantBySku = function (product, sku) {
        return product.variants.find(function (variant) { return variant.sku === sku; }) || product.variants[0];
    };
    ShopifyService.prototype.applyTags = function (product) {
        if (product.tags) {
            product.hasDocs = product.tags.map(function (tag) { return tag.value; }).includes('has-docs');
            product.radioOptions = product.tags.map(function (tag) { return tag.value; }).includes('radio-options');
            product.requiresPrepurchaseInfo = product.tags.map(function (tag) { return tag.value; }).includes('requires-prepurchase-info');
            product.disableQuantity = product.tags.map(function (tag) { return tag.value; }).includes('disable-quantity');
            product.disableCartQuantity = product.tags.map(function (tag) { return tag.value; }).includes('disable-cart-quantity');
            product.disableCartRemoval = product.tags.map(function (tag) { return tag.value; }).includes('disable-cart-removal');
            product.oneTimePurchase = product.tags.map(function (tag) { return tag.value; }).includes('one-time-purchase');
        }
    };
    // TODO: Create draft order from line items (use invoice url for checkout flow)
    ShopifyService.prototype.createCheckout = function (lineItems) {
        var lineItemsForCheckout = lineItems.map(function (item) { return ({ variantId: item.variantId, quantity: item.quantity }); });
        // console.debug('Creating Shopify Checkout with line items:', {
        //   lineItems,
        //   lineItemsForCheckout
        // });
        var input = this.client.variable('input', 'CheckoutCreateInput!');
        var mutation = this.client.mutation('myMutation', [input], function (root) {
            root.add('checkoutCreate', { args: { input: input } }, function (checkoutCreate) {
                checkoutCreate.add('userErrors', function (userErrors) {
                    userErrors.add('message'),
                        userErrors.add('field');
                });
                checkoutCreate.add('checkout', function (checkout) {
                    checkout.add('id'),
                        checkout.add('webUrl'),
                        checkout.addConnection('lineItems', { args: { first: 250 } }, function (lineItemsConnection) {
                            lineItemsConnection.add('variant', function (variant) {
                                variant.add('title');
                            }),
                                lineItemsConnection.add('quantity');
                        });
                });
            });
        });
        var customAttributes = [
            {
                key: 'source',
                value: 'permitting'
            },
            {
                key: 'return_url',
                value: document.location.origin + '/thank-you'
            }
        ];
        return this.client.send(mutation, { input: { lineItems: lineItemsForCheckout, customAttributes: customAttributes } });
    };
    // TODO: Apply discount via nextgen laravel api request (shopfiy admin req proxy?)
    //        - Apply discount(s) to draft order(s) instead of checkout (draft order should already exist)
    ShopifyService.prototype.applyDiscount = function (inputCode, checkoutId) {
        var discountCode = inputCode;
        var input = this.client.variable('discountCode', 'String!');
        var id = this.client.variable('checkoutId', 'ID!');
        // console.log(this.client);
        var mutation = this.client.mutation('myMutation', [id, input], function (root) {
            root.add('checkoutDiscountCodeApplyV2', { args: { checkoutId: id, discountCode: input } }, function (checkoutDiscountCodeApplyV2) {
                checkoutDiscountCodeApplyV2.add('userErrors', function (userErrors) {
                    userErrors.add('message'),
                        userErrors.add('field');
                    // console.log('inside discount return', checkoutId, discountCode);
                });
                checkoutDiscountCodeApplyV2.add('checkout', function (checkout) {
                    checkout.add('id');
                    // console.log('inside discount return ADD', checkoutId, discountCode);
                });
            });
        });
        return this.client.send(mutation, { checkoutId: checkoutId, discountCode: discountCode });
    };
    // TODO: Proxy lookup via laravel API request / proxy
    ShopifyService.prototype.fetchCheckout = function (checkoutId) {
        var id = this.client.variable('checkoutId', 'ID!');
        var query = this.client.query(function (root) {
            root.add('node', { args: { id: checkoutId }, alias: 'checkout' }, function (node) {
                node.add('id');
                node.addInlineFragmentOn('Checkout', function (Checkout) {
                    Checkout.add('webUrl'),
                        Checkout.add('subtotalPrice'),
                        Checkout.add('completedAt'),
                        Checkout.add('email'),
                        Checkout.add('totalTax'),
                        Checkout.add('totalPrice'),
                        Checkout.addConnection('lineItems', { args: { first: 250 } }, function (lineItems) {
                            lineItems.add('variant', function (variant) {
                                variant.add('title'),
                                    variant.add('image', function (image) { return image.add('src'); }),
                                    variant.add('price');
                            }),
                                lineItems.add('quantity');
                        });
                });
            });
        });
        return this.client.send(query, { checkoutId: checkoutId });
    };
    // TODO: Create draft order first (blank/empty)? Or create with line items + variants when ready?
    ShopifyService.prototype.addVariantsToCheckout = function (cartId, lineItemsInput) {
        var checkoutId = this.client.variable('checkoutId', 'ID!');
        var lineItemsForCheckout = lineItemsInput.map(function (item) { return ({ id: item.id, variantId: item.variantId, quantity: item.quantity }); });
        var lineItems = this.client.variable('lineItems', '[CheckoutLineItemInput!]!');
        var mutation = this.client.mutation('myMutation', [checkoutId, lineItems], function (root) {
            root.add('checkoutLineItemsAdd', { args: { checkoutId: checkoutId, lineItems: lineItems } }, function (checkoutLineItemsAdd) {
                checkoutLineItemsAdd.add('userErrors', function (userErrors) {
                    userErrors.add('message'),
                        userErrors.add('field');
                });
                checkoutLineItemsAdd.add('checkout', function (checkout) {
                    checkout.add('webUrl'),
                        checkout.add('subtotalPrice'),
                        checkout.add('totalTax'),
                        checkout.add('totalPrice'),
                        checkout.addConnection('lineItems', { args: { first: 250 } }, function (lineItemsConnection) {
                            lineItemsConnection.add('variant', function (variant) {
                                variant.add('title'),
                                    variant.add('image', function (image) { return image.add('src'); }),
                                    variant.add('price');
                            }),
                                lineItemsConnection.add('quantity');
                        });
                });
            });
        });
        console.log('line items', lineItemsForCheckout);
        return this.client.send(mutation, { checkoutId: cartId, lineItems: lineItemsForCheckout });
    };
    // TODO: Ability to remove line item (variants) from draft order (instead of checkout)
    ShopifyService.prototype.removeLineItem = function (cartId, lineItemId) {
        var checkoutId = this.client.variable('checkoutId', 'ID!');
        var lineItemIds = this.client.variable('lineItemIds', '[ID!]!');
        var mutation = this.client.mutation('myMutation', [checkoutId, lineItemIds], function (root) {
            root.add('checkoutLineItemsRemove', { args: { checkoutId: checkoutId, lineItemIds: lineItemIds } }, function (checkoutLineItemsRemove) {
                checkoutLineItemsRemove.add('userErrors', function (userErrors) {
                    userErrors.add('message'),
                        userErrors.add('field');
                }),
                    checkoutLineItemsRemove.add('checkout', function (checkout) {
                        checkout.add('webUrl'),
                            checkout.add('subtotalPrice'),
                            checkout.add('totalTax'),
                            checkout.add('totalPrice'),
                            checkout.addConnection('lineItems', { args: { first: 250 } }, function (lineItemsConnection) {
                                lineItemsConnection.add('variant', function (variant) {
                                    variant.add('title'),
                                        variant.add('image', function (image) { return image.add('src'); }),
                                        variant.add('price');
                                }),
                                    lineItemsConnection.add('quantity');
                            });
                    });
            });
        });
        return this.client.send(mutation, { checkoutId: cartId, lineItemIds: [lineItemId] });
    };
    // TODO: Ability to update line item (variants) from draft order (instead of checkout)
    ShopifyService.prototype.updateLineItem = function (cartId, lineItemsInput) {
        var lineItemsForCheckout = lineItemsInput.map(function (item) { return ({ id: item.id, variantId: item.variantId, quantity: item.quantity }); });
        var checkoutId = this.client.variable('checkoutId', 'ID!');
        var lineItems = this.client.variable('lineItems', '[CheckoutLineItemUpdateInput!]!');
        var mutation = this.client.mutation('myMutation', [checkoutId, lineItems], function (root) {
            root.add('checkoutLineItemsUpdate', { args: { checkoutId: checkoutId, lineItems: lineItems } }, function (checkoutLineItemsUpdate) {
                checkoutLineItemsUpdate.add('userErrors', function (userErrors) {
                    userErrors.add('message'),
                        userErrors.add('field');
                });
                checkoutLineItemsUpdate.add('checkout', function (checkout) {
                    checkout.add('webUrl'),
                        checkout.add('subtotalPrice'),
                        checkout.add('totalTax'),
                        checkout.add('totalPrice'),
                        checkout.addConnection('lineItems', { args: { first: 250 } }, function (lineItemsConnection) {
                            lineItemsConnection.add('variant', function (variant) {
                                variant.add('title'),
                                    variant.add('image', function (image) { return image.add('src'); }),
                                    variant.add('price');
                            }),
                                lineItemsConnection.add('quantity');
                        });
                });
            });
        });
        return this.client.send(mutation, { checkoutId: cartId, lineItems: lineItemsForCheckout });
    };
    ShopifyService.prototype.updateCartDraftOrder = function (draftOrder) {
        this.cartDraftOrder.next(draftOrder);
        // this.getShopifyProductImages(draftOrder);
    };
    ShopifyService.prototype.getCartProducts = function () {
        var _this = this;
        this.getShopifyProducts().subscribe(function (products) { return _this.cartProducts.next(products); });
    };
    // Images are currently hidden on the cart, per CSS class
    // getProductImages(draftOrder: ShopifyDraftOrder) {
    //   const product_ids = [];
    //   draftOrder.line_items.forEach(item => {
    //     product_ids.push(item.product_id);
    //   });
    //   this.getShopifyProductImages({product_ids}).subscribe(images => this.productImages.next(images));
    // }
    ShopifyService.prototype.updateDraftOrderItems = function (draftOrder, navigateUrl) {
        var _this = this;
        this.cartUpdating = true;
        var tags = draftOrder.tags.split(', ');
        var action;
        if (draftOrder.id) {
            // Updating existing draft order from Shopify
            action = this.updateDraftOrder(draftOrder.id, draftOrder.line_items, tags, draftOrder.applied_discount, draftOrder.note);
        }
        else {
            // Create new Shopify draft order
            action = this.createDraftOrder(draftOrder.line_items, tags, draftOrder.applied_discount, draftOrder.note);
        }
        action.subscribe(function (resp) {
            if (resp) {
                _this.cartUpdating = false;
                // TODO: Update cart with current draft order
                _this.updateCartDraftOrder(resp.draft_order);
                if (navigateUrl) {
                    setTimeout(function () { return _this.router.navigate([navigateUrl]); }, 500);
                }
            }
        });
    };
    ShopifyService.prototype.addToDraftOrder = function (lineItem, draftOrder) {
        draftOrder.line_items.push(lineItem);
    };
    ShopifyService.prototype.removeFromDraftOrder = function (lineItem, draftOrder) {
        var index = draftOrder.line_items.findIndex(function (item) { return item.variant_id === lineItem.variant_id; });
        if (index > -1) {
            draftOrder.line_items.splice(index, 1);
        }
    };
    ShopifyService.prototype.increaseItemQuantity = function (lineItem, quantity, draftOrder) {
        if (quantity === void 0) { quantity = 1; }
        var item = draftOrder.line_items.find(function (li) { return li.variant_id === lineItem.variant_id; });
        if (item) {
            item.quantity += quantity;
        }
        else {
            this.addToDraftOrder(lineItem, draftOrder);
        }
    };
    ShopifyService.prototype.decreaseItemQuantity = function (lineItem, quantity, draftOrder) {
        if (quantity === void 0) { quantity = 1; }
        var item = draftOrder.line_items.find(function (li) { return li.variant_id === lineItem.variant_id; });
        if (item.quantity > 1 && item.quantity > quantity) {
            item.quantity -= quantity;
        }
        else {
            this.removeFromDraftOrder(lineItem, draftOrder);
        }
    };
    return ShopifyService;
}(BaseService));
export { ShopifyService };
