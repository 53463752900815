export class Referee {
  usac_id: number;
  first_name: string;
  last_name: string;
  age: number;
  city: string;
  state: string;

  constructor(
    first_name: string,
    last_name: string,
    usac_id?: number,
    age?: number,
    city?: string,
    state?: string
  ) {
    this.usac_id = usac_id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.age = age;
    this.city = city;
    this.state = state;
  }
}
