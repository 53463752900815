import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PermitService, EventListingService } from '../../services';
import { Event, RadioOption } from '../../classes';
var PermitPart2Component = /** @class */ (function () {
    function PermitPart2Component(formBuilder, permit, eventListing) {
        this.formBuilder = formBuilder;
        this.permit = permit;
        this.eventListing = eventListing;
        this.submitted = false;
        //  securityContacts: FormArray;
        //  securityOptions: RadioOption[] = [];
        this.localPermitApprovalOptions = [];
        this.disabledAddBtns = {};
    }
    Object.defineProperty(PermitPart2Component.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.permitPart2Form.controls; },
        enumerable: true,
        configurable: true
    });
    PermitPart2Component.prototype.ngOnInit = function () {
        var courseMapAnswer = this.permit.answers ? this.permit.answers.find(function (answer) { return answer.qid === 11; }) : null;
        if (!courseMapAnswer || !courseMapAnswer.description.length) {
            this.permit.generateCourseMaps(this.eventListing.eventDates.length);
        }
        this.permitPart2Form = this.formBuilder.group({
            courseMarking: ['', Validators.required],
            //  policeSecurity: [ '', Validators.required ]
            localPermitApproval: ['', Validators.required]
        });
        this.onChangeDiscipline();
        this.setDisciplineValidators();
        this.f.courseMarking.setValue(this.permit.courseMarking);
        this.f.localPermitApproval.setValue(this.permit.localPermitApproval);
        //  this.f.policeSecurity.setValue(this.permit.policeSecurity);
        // if (this.permit.securityContacts.length > 0) {
        //   this.permitPart2Form.setControl('securityContacts', this.setSecurityContacts());
        // } else {
        //   this.permitPart2Form.setControl('securityContacts', this.formBuilder.array([this.initContacts()]));
        // }
        // this.permitPart2Form.valueChanges.subscribe( data => {
        //   this.permit.policeSecurity = data.policeSecurity;
        //   this.permit.courseMarking = data.courseMarking;
        // });
        // if (this.f.securityContacts !== undefined) {
        //   this.permitPart2Form.get('securityContacts').valueChanges.subscribe(data => {
        //     this.permit.securityContacts = data;
        //   });
        // }
        // this.securityOptions = [
        //   new RadioOption('security-yes', 'yes', 'Yes'),
        //   new RadioOption('security-no', 'no', 'No')
        // ];
        this.localPermitApprovalOptions = [
            new RadioOption('local-approval-yes', 'yes', 'Yes'),
            new RadioOption('local-approval-no', 'no', 'No')
        ];
    };
    PermitPart2Component.prototype.debug = function (event) {
        console.log('debug form', this.f, event);
    };
    // initContacts() {
    //   return this.formBuilder.group({
    //       securityContactName: [ '',  nameCharactersValidator()  ],
    //       securityContactEmail: [ '',  emailValidator() ],
    //       securityContactPhone: [ '', phoneNumberValidator() ]
    //   });
    // }
    // addSecurityContact() {
    //   this.securityContacts = this.permitPart2Form.get('securityContacts') as FormArray;
    //   this.securityContacts.push(this.initContacts());
    // }
    // setSecurityContacts(): FormArray {
    //     const control = new FormArray([]);
    //     if (this.permit.securityContacts.length > 0 ) {
    //       this.permit.securityContacts.forEach(contact => {
    //         control.push(this.formBuilder.group({
    //           securityContactName: contact.securityContactName,
    //           securityContactEmail: contact.securityContactEmail,
    //           securityContactPhone: contact.securityContactPhone
    //         }));
    //       });
    //     }
    //     return control;
    // }
    // get Controls() {
    //   return (this.permitPart2Form.get('securityContacts') as FormArray).controls;
    // }
    // removeContact(index: number) {
    // (this.permitPart2Form.get('securityContacts') as FormArray).removeAt(index);
    // }
    PermitPart2Component.prototype.onSubmit = function () {
        this.submitted = true;
        // console.log('submitting course info form', this.permitPart2Form);
        if (this.permitPart2Form.invalid) {
            console.log('course form data is invalid!');
            return;
        }
        this.permit.createPermit(this.eventId, 'part2', null, this.event.isRaceOrRide);
        // console.log(this.permitPart2Form.value);
    };
    // updatePermit() {
    //   this.submitted = true;
    //   console.log('submitting course info form', this.permitPart2Form);
    //   if (this.permitPart2Form.invalid) {
    //     console.log('course form data is invalid!');
    //     return;
    //   }
    //   this.permit.savePermit().subscribe( (response) => {
    //     console.log(response);
    //     // tslint:disable-next-line: no-string-literal
    //     if (response['status'] === true) {
    //       // TODO: add dynamic id
    //       this.route.navigate(['/event/19/overview']);
    //     }
    //   }, (error) => {
    //     console.log(error);
    //   }, () => {
    //     console.log('completed');
    //   });
    //   console.log(this.permitPart2Form.value);
    // }
    PermitPart2Component.prototype.showCourseMapUpload = function () {
        // TODO: Replace cd_id values with discipline names (once retrieving from the API instead of listing service).
        return (this.eventListing.event_discipline === '19' // Road Racing
            || this.eventListing.event_discipline === '21' // Mountain Bike Racing
            || this.eventListing.event_discipline === '24' // Gran Fondo
            || this.eventListing.event_discipline === '27' // Fun Rides
        );
    };
    PermitPart2Component.prototype.updateCourses = function ($event) {
        this.permit.courseMarking = $event;
    };
    PermitPart2Component.prototype.competitiveNotBmx = function () {
        return (this.eventListing.isEventCompetitive && !this.eventListing.isBmx);
    };
    PermitPart2Component.prototype.onChangeDiscipline = function () {
        //  console.log(this.eventListing.subDisciplines[`${this.f.event_discipline.value}`]);
        this.courseMarkingOptions = this.permit.checkBoxOptionsWithArray(this.permit.courseMarkingOptions["" + this.eventListing.event_discipline]); /* tslint:disable:max-line-length */
    };
    // updateSecurityInfo($event: string) {
    //   this.permit.policeSecurity = $event;
    // }
    // commented out for MBR-2992
    // onCourseMapChange(event: any) {
    //   this.disabledAddBtns[event.dateId] = !event.selectedSubDisc.length;
    // }
    PermitPart2Component.prototype.disableAddBtn = function (id) {
        return this.disabledAddBtns[id];
    };
    PermitPart2Component.prototype.updateApprovalInfo = function ($event) {
        this.permit.localPermitApproval = $event;
    };
    PermitPart2Component.prototype.setDisciplineValidators = function () {
        var courseMarking = this.permitPart2Form.get('courseMarking');
        if (!this.competitiveNotBmx() || !this.courseMarkingOptions || this.courseMarkingOptions.length === 0) {
            courseMarking.setValidators(null);
        }
        courseMarking.updateValueAndValidity();
    };
    return PermitPart2Component;
}());
export { PermitPart2Component };
