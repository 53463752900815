import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { CustomDateFormatter } from 'src/app/providers/dateFormatter';
import { EventDate, SelectOption } from '../../classes';
import { EventListingService, LocationService } from '../../services';
import { dateRangeValidator, noWhitespaceValidator, zipCodeValidator } from '../../validators';

@Component({
  selector: 'app-event-date-card',
  templateUrl: './event-date-card.component.html',
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class EventDateCardComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() eventListingDate: EventDate;
  @Input() submitted: boolean;
  @Input() subDisciplines: Array<SelectOption>;
  @Input() cardIndex: number;
  @Input() startDate: any;
  @Input() endDate: any;
  @Input() eventDatesChanged: boolean;
  @Input() disabled: boolean;
  @Input() dateDisabled: boolean;

  @Output() dateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  eventDates: NgbDateStruct[] = [];
  markDisabled: (date: NgbDate) => boolean;
  stateOptions: SelectOption[] = [ new SelectOption('', 'Select...'), ...this.locationService.stateOptions ];

  eventDate: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private eventListing: EventListingService,
    private locationService: LocationService,
    private dateParser: NgbDateParserFormatter
  ) { }

  get f() { return this.eventDate.controls; }

  ngOnInit() {
    this.buildForm();
    this.setFormValues();
    this.updateAvailableDates();
    this.subscribeToForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.startDate || changes.endDate || (changes.eventDatesChanged && changes.eventDatesChanged.currentValue)) {
      this.updateAvailableDates();
    }
    // commented out for MBR-2992
    // if (this.eventDate && this.f.event_date_sub_disciplines.value && changes.subDisciplines && changes.subDisciplines.currentValue !== '') {
    //   this.f.event_date_sub_disciplines.value.forEach(subDisc => {
    //     if (!changes.subDisciplines.currentValue.find(sd => sd.value === subDisc.value)) {
    //       this.f.event_date_sub_disciplines.setValue('');
    //     }
    //   });
    // }
  }

  buildForm(): void {
    // commented out for MBR-2992
    this.eventDate = this.formBuilder.group({
      event_date_description: [''],
      // event_date_sub_disciplines: ['', Validators.required],
      event_date_start: [{ year: 2019, month: 1, day: 1 }, dateRangeValidator(this.startDate, this.endDate)],
      event_date_street: ['', [Validators.required, noWhitespaceValidator()]],
      event_date_unit: [''],
      event_date_city: ['', [Validators.required, noWhitespaceValidator()]],
      event_date_state: ['', [Validators.required, noWhitespaceValidator()]],
      event_date_postal_code: ['', [Validators.required, zipCodeValidator()]],
    });
  }

  setFormValues(): void {
    this.f.event_date_description.setValue(this.eventListingDate.event_date_description);
    // commented out for MBR-2992
    // this.f.event_date_sub_disciplines.setValue(this.setSubdisciplineValues());
    this.f.event_date_street.setValue(this.eventListingDate.event_date_street);
    this.f.event_date_unit.setValue(this.eventListingDate.event_date_unit);
    this.f.event_date_city.setValue(this.eventListingDate.event_date_city);
    this.f.event_date_state.setValue(this.eventListingDate.event_date_state || '');
    this.f.event_date_postal_code.setValue(this.eventListingDate.event_date_postal_code);
    this.f.event_date_start.setValue(this.setStartDateValue());

    // if (typeof this.eventListingDate.event_date_start === 'string') {
    //   this.eventListingDate.event_date_start = new Date(this.eventListingDate.event_date_start);
    // }
    // if (this.eventListingDate.event_date_start instanceof Date) {
    //   this.f.event_date_start.setValue({
    //     year: this.eventListingDate.event_date_start.getFullYear(),
    //     month: this.eventListingDate.event_date_start.getMonth() + 1,
    //     day: this.eventListingDate.event_date_start.getDate()
    //   });
    // }

    // this.eventDate.controls.event_date_start.valueChanges.subscribe((startDate) => {
    //   if (startDate.year !== undefined) {
    //     this.eventListingDate.event_date_start.setFullYear(startDate.year);
    //   }
    //   if (startDate.month !== undefined) {
    //     this.eventListingDate.event_date_start.setMonth(startDate.month - 1);
    //   }
    //   if (startDate.day !== undefined) {
    //     this.eventListingDate.event_date_start.setDate(startDate.day);
    //   }
    // });
  }

  subscribeToForm(): void {
    this.eventDate.valueChanges.subscribe(data => {
      this.eventListingDate.event_date_description = data.event_date_description;
      this.eventListingDate.event_date_start = data.event_date_start;
      // commented out for MBR-2992
      // this.eventListingDate.event_date_sub_disciplines = data.event_date_sub_disciplines;
      this.eventListingDate.event_date_street = data.event_date_street;
      this.eventListingDate.event_date_unit = data.event_date_unit;
      this.eventListingDate.event_date_city = data.event_date_city;
      this.eventListingDate.event_date_state = data.event_date_state;
      this.eventListingDate.event_date_postal_code = data.event_date_postal_code;
      this.setDateValidity();
    });

    this.f.event_date_start.valueChanges.subscribe((val) => {
      if (val) {
        this.dateChange.emit(true);
      }
    });
  }

  private setDateValidity(): void {
    this.eventListingDate.isValid = this.eventDate.valid;
  }

  setStartDateValue() {
    const start = this.eventListingDate.event_date_start;
    return (this.eventListing.mode === 'update' && start !== '') ? new Date(start).toLocaleDateString() : start;
  }

  // commented out for MBR-2992
  // setSubdisciplineValues() {
  //   return this.eventListingDate.event_date_sub_disciplines;
  // }

  updateAvailableDates() {
    if (this.eventDate) {
      this.f.event_date_start.setValidators(dateRangeValidator(this.startDate, this.endDate));
      this.f.event_date_start.updateValueAndValidity({emitEvent: false});
      this.setDateValidity();

      const start = moment(this.startDate).format('M/D/Y');
      const end = moment(this.endDate).format('M/D/Y');
      this.minDate = this.dateParser.parse(start);
      this.maxDate = this.dateParser.parse(end);

      this.eventDates = [];
      if (this.eventListing.eventDates.length > 0) {
        this.eventListing.eventDates.forEach(date => {
          if (date.event_date_start !== '') {
            this.eventDates.push(this.dateParser.parse(date.event_date_start));
          }
        });
      }
      this.markDisabled = (date: NgbDate) => {
        let disabled = false;
        const currentDate = this.dateParser.parse(this.f.event_date_start.value);
        this.eventDates.forEach(d => {
          if (
            (!currentDate || !(d.day === currentDate.day && d.month === currentDate.month && d.year === currentDate.year))
            && (date.day === d.day && date.month === d.month && date.year === d.year)
          ) {
            disabled = true;
          }
        });
        return disabled;
      };
    }
  }

  setAddress(addrObj: any) {
    if (Object.keys(addrObj).includes('formatted_address')) {
      this.f.event_date_street.setValue(this.streetAddress(addrObj));
      this.f.event_date_city.setValue(addrObj.locality);
      this.f.event_date_state.setValue(addrObj.admin_area_l1);
      this.f.event_date_postal_code.setValue(addrObj.postal_code);
    }
  }

  streetAddress(addrObj: any) {
    return !!addrObj.street_number ? addrObj.street_number + ' ' + addrObj.route : addrObj.route;
  }

  removeDate(index: any) {
    this.dateChange.emit(true);
    this.eventListing.removeEventDate(index);
  }
}
