<!-- <div *ngIf="eventDate">
	<app-card [classes]="classesList">
		<div class="card-body">
			<h5 class="card-title">Date {{index+1}}</h5>
			<div class="review-section">
				<div *ngIf="editMode">
					<div
						*ngIf="(eventDate.event_date_description !== originalEventDate.event_date_description); then updateEventDateDescription else newEventDateDescription">
					</div>
					<ng-template #newEventDateDescription>
						<p class="card-text"><strong>Description: </strong> {{eventDate.event_date_description}}</p>
					</ng-template>
					<ng-template #updateEventDateDescription>
						<p class="card-text text-danger">
							<del><strong>Description: </strong> {{originalEventDate.event_date_description}}</del>
						</p>
						<p class="card-text text-success">
							<strong>Description: </strong> {{eventDate.event_date_description}}</p>
					</ng-template>
				</div>
				<p class="card-text" *ngIf="!editMode && eventDate.event_date_description"><strong>Optional
						Name: </strong>{{eventDate.event_date_description}}</p>
				<p class="card-text" *ngIf="editMode">
					<strong>Subdiscipline(s):</strong>
					<span *ngFor="let sub of eventDate.event_date_sub_disciplines; let i = index">
						<span *ngIf="i > 0">,</span>
						<span class="{{getSubdisciplineState(sub)}}"> {{sub.label || sub.subdiscipline_name}}</span></span>
				</p>
				<p class="card-text" *ngIf="!editMode">
					<strong>Subdiscipline(s):</strong>
					<span *ngFor="let sub of eventDate.event_date_sub_disciplines; let i = index">
						<span *ngIf="i > 0">,</span>
						{{sub.label || sub.subdiscipline_name}}</span>
				</p>
				<div *ngIf="editMode">
					<div *ngIf="hasUpdateStartDate(); then updateStartDate else newStartDate"></div>
					<ng-template #newStartDate>
						<p class="card-text"><strong>Start Date :</strong>
							{{eventDate.event_date_start | date:'fullDate'}}</p>
					</ng-template>
					<ng-template #updateStartDate>
						<p class="card-text"><del class="text-danger"><strong>Start Date :</strong>
								{{originalEventDate.event_date_start | date:'fullDate'}}</del></p>
						<p class="text-success"><strong>Start Date :</strong>
							{{eventDate.event_date_start | date:'fullDate'}}</p>
					</ng-template>
				</div>
				<p *ngIf="!editMode && eventDate.event_date_start"><strong>Start Date: </strong>
					{{eventDate.event_date_start | date:'fullDate'}}</p>
				<div *ngIf="editMode">
					<div *ngIf="hasUpdateAddress(); then updateAddressInfo else newAddressInfo"></div>
					<ng-template #newAddressInfo>
						<p class="card-text"><strong>Address: </strong>
							{{eventDate.event_date_street}}<span *ngIf="eventDate.event_date_unit !== ''">{{eventDate.event_date_unit}}</span>,
							{{eventDate.event_date_city}}, {{eventDate.event_date_state}}, {{eventDate.event_date_postal_code}}</p>
					</ng-template>
					<ng-template #updateAddressInfo>
						<p class="card-text">
							<del class="small text-danger">
								<strong>Address: </strong>
								{{originalEventDate.event_date_street}}<span *ngIf="originalEventDate.event_date_unit !== ''">{{originalEventDate.event_date_unit}}</span>,
								{{originalEventDate.event_date_city}}, {{originalEventDate.event_date_state}}, {{originalEventDate.event_date_postal_code}}
							</del>
						</p>
						<p>
							<span class="text-success"><strong>Address: </strong>
								{{eventDate.event_date_street}}<span *ngIf="eventDate.event_date_unit !== ''">{{eventDate.event_date_unit}}</span>,
								{{eventDate.event_date_city}}, {{eventDate.event_date_state}}, {{eventDate.event_date_postal_code}}
							</span>
						</p>
					</ng-template>
				</div>
				<p class="card-text" *ngIf="!editMode"><strong>Address: </strong>
					{{eventDate.event_date_street}}<span *ngIf="eventDate.event_date_unit !== ''">{{eventDate.event_date_unit}}</span>,
					{{eventDate.event_date_city}}, {{eventDate.event_date_state}}, {{eventDate.event_date_postal_code}}</p>
			</div>
		</div>
	</app-card>
</div> -->

<div *ngIf="eventDate">
	<app-card [classes]="classesList">
		<div class="card-body">
			<h5 class="card-title">Date {{index+1}}</h5>
			<div class="review-section">
				<p class="card-text" *ngIf="eventDate.event_date_description"><strong>
					Description: </strong>{{eventDate.event_date_description}}</p>
					<!--  commented out for MBR-2992 -->
				<!-- <p class="card-text">
					<strong>Subdiscipline(s):</strong>
					<span *ngFor="let sub of eventDate.event_date_sub_disciplines; let i = index">
						<span *ngIf="i > 0">,</span>
						{{sub.label || sub.subdiscipline_name}}</span>
				</p> -->
				<p *ngIf="eventDate.event_date_start"><strong>Start Date: </strong>
					{{eventDate.event_date_start | date:'fullDate':'UTC'}}</p>
				<p class="card-text"><strong>Address: </strong>
					{{eventDate.event_date_street}}<span
						*ngIf="eventDate.event_date_unit !== ''">{{eventDate.event_date_unit}}</span>,
					{{eventDate.event_date_city}}, {{eventDate.event_date_state}}, {{eventDate.event_date_postal_code}}
				</p>
			</div>
		</div>
	</app-card>
</div>