import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { EventService } from './events.service';
import { ProfileService } from './profile.service';
import {
  Contact,
  EventDate,
  EventType,
  SelectOption,
  Discipline
} from '../classes';

@Injectable({
  providedIn: 'root'
})
export class EventListingService {

  public contacts: Array<Contact> = [];
  public eventDates: Array<EventDate>;

  public event_id: number|string;
  public event_status: string;
  public event_permit_id: number|string;
  public event_organizer: number;
  public event_organizer_comp_id: number;
  public organizerName: string;
  public organizerEmail: string;
  public event_name: string;
  public event_discipline: string;
  public event_competitive: string;
  public event_start_date: Date;
  public event_end_date: Date;
  //
  public event_types: Array<any>;
  public event_special_calendars: Array<any>;
  public calendarCode: string;
  public event_reg_url: string;
  public event_bike_reg: string;
  public event_website: string;
  public event_facebook_url: string;
  public event_twitter_url: string;
  public event_youtube_url: string;
  public event_instagram_url: string;
  public event_sponsoring_clubs: Array<any>;
  public validateContacts = false;
  public contactsLimit = 3;
  public isRaceDirectorLicenseValid = false;
  public mode = 'create';
  public eventId: string;
  public updateInfo: string;
  public reasonInfo: string;
  public event: object;
  public selectedSubdisc: Array<string>;
  public event_logo: string;
  public event_announcement: string;
  public diffDays: number;
  public initialEventDateIds: number[] = [];

  // TODO: Set disciplines based on name values, as IDs may change
  public disciplines = [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 38];
  public competitiveDisciplines = ['19', '20', '21', '22', '23', '30'];
  public bmxDisciplines = ['22', '30'];

  public datesLoaded = false;
  public organizerLoaded = false;

  public eventFormData: object;

  public formControlSections = [
    [
      'event_organizer',
      'event_organizer_comp_id',
      'organizerName',
      'organizerEmail',
      'event_sponsoring_clubs',
    ],
    [
      'event_name',
      'event_start_date',
      'event_end_date',
      'event_discipline',
      'event_competitive',
      'event_types',
      'event_special_calendars',
      'calendarCode',
    ],
    // "event-date" section, handled differently due to nested forms
    null,
    [
      'event_bike_reg',
      'bike_reg_check',
      'other_reg_check',
      'event_reg_url',
      'event_website',
      'event_facebook_url',
      'event_twitter_url',
      'event_youtube_url',
      'event_instagram_url',
      'event_announcement_switch',
      'event_announcement',
      'event_logo'
    ]
  ];

  constructor(
    private http: HttpClient,
    private router: Router,
    private eventService: EventService,
    private profileService: ProfileService
  ) {
    this.setInitialValues();
  }

  setInitialValues(type?: string) {
    this.eventDates = type === 'reset' ? [] : [new EventDate()];
    this.event_types = [];
    this.event_sponsoring_clubs = [];
    this.event_special_calendars = [];
    this.event_start_date = new Date();
    this.event_end_date = new Date();
    this.selectedSubdisc = [];
    this.eventFormData = {};
  }

  addContcat(contact: Contact) {
    this.contacts.push(contact);
    if (this.contacts.length > this.contactsLimit) {
      this.validateContacts = true;
    }
  }
  removeContact(index: number) {
    this.contacts.splice(index, 1);
  }
  createEventDate() {
    let eventDate: any;
    eventDate = new EventDate();
    if (this.eventDates.length > 0) {
      const previousDate = this.eventDates[(this.eventDates.length - 1)];
      Object.assign(eventDate, previousDate);
      eventDate.event_date_start = '';
      eventDate.event_date_id = null;
      if (eventDate.hasOwnProperty('dateCard') === true) {
        delete eventDate.dateCard;
      }
      if (this.mode === 'update') {
        eventDate.event_date_sub_disciplines = previousDate.event_date_sub_disciplines;
      }
    }
    this.eventDates.push(eventDate);
  }
  removeEventDate(index: number) {
    this.eventDates.splice(index, 1);
  }
  optionsWithArray(options: Array<string>) {
    let selectOptions: Array<SelectOption>;
    selectOptions = [];
    if (options === undefined || options === null) {
      return selectOptions;
    }
    options.forEach((option) => {
      let index = option.toLowerCase();
      index = index.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, key) => {
        if (+match === 0) { return ''; }
        return key === 0 ? match.toLowerCase() : match.toUpperCase();
      });
      selectOptions.push(new SelectOption(index, option));
    });

    return selectOptions;
  }
  reset() {
    // flush the data here.
    this.contacts = [];
    this.eventDates = undefined;

    this.event_id = undefined;
    this.event_status = undefined;
    this.event_permit_id = undefined;
    this.event_organizer = undefined;
    this.event_organizer_comp_id = undefined;
    this.organizerName = undefined;
    this.organizerEmail = undefined;
    this.event_name = undefined;
    this.event_discipline = undefined;
    this.event_competitive = undefined;
    this.event_start_date = undefined;
    this.event_end_date = undefined;

    this.event_types = undefined;
    this.event_special_calendars = undefined;
    this.calendarCode = undefined;
    this.event_reg_url = undefined;
    this.event_bike_reg = undefined;
    this.event_website = undefined;
    this.event_facebook_url = undefined;
    this.event_twitter_url = undefined;
    this.event_youtube_url = undefined;
    this.event_instagram_url = undefined;
    this.event_sponsoring_clubs = undefined;
    this.validateContacts = false;
    this.contactsLimit = 3;
    this.isRaceDirectorLicenseValid = false;
    this.mode = 'create';
    this.eventId = undefined;
    this.updateInfo = undefined;
    this.reasonInfo = undefined;
    this.event = undefined;
    this.selectedSubdisc = undefined;
    this.event_logo = undefined;
    this.event_announcement = undefined;
    this.diffDays = undefined;
    this.initialEventDateIds = [];

    this.datesLoaded = false;
    this.organizerLoaded = false;

    this.setInitialValues('reset');
  }
  isUpdateReady() {
    return !(this.updateInfo === undefined || this.updateInfo === '');
  }

  getEventTypes() {
    return this.http.get<EventType[]>(environment.nextGenApiUrl + 'event/types');
  }

  getDisciplines() {
    return this.http.get<Discipline[]>(environment.nextGenApiUrl + 'event/disciplines');
  }

  filterDisciplines(discs) {
    return discs.filter(disc => this.disciplines.includes(disc.cd_id));
  }

  get isEventCompetitive(): boolean {
    if (this.event_discipline) {
      return this.competitiveDisciplines.includes(this.event_discipline.toString());
    }
    return false;
  }

  get isBmx(): boolean {
    if (this.event_discipline) {
      return this.bmxDisciplines.includes(this.event_discipline.toString());
    }
    return false;
  }

  get isGranFondo(): boolean {
    if (this.event_discipline) {
      return this.event_discipline === '24';
    }
    return false;
  }

  get isVirtual(): boolean {
    if (this.event_discipline) {
      return this.event_discipline === '38';
    }
    return false;
  }

  sortedEventDates() {
    return this.eventService.sortEventDates(this.eventDates);
  }

  fetchEventData(id?: number|string) {
    this.eventService.getEventById(id).subscribe(data => {
      if (!data) {
        // If event doesn't exist, redirect to event queue
        this.router.navigate(['queue']);
      } else {
        Object.assign(this, data);

        this.profileService.getProfile(data.event_organizer).subscribe(profile => {
          if (profile) {
            this.organizerName = profile.profile_first_name + ' ' + profile.profile_last_name;
            this.organizerEmail = profile.profile_email;
            this.event_organizer_comp_id = profile.profile_comp_id;
          }
          this.organizerLoaded = true;
        });

        // Contacts
        this.contacts = data.event_contacts;
        data.event_contacts.forEach(contact => contact.isValid = true);


        // Event Dates
        this.eventService.getEventDates(data.event_id).subscribe(dates => {
          const alteredDates = [];
          dates.forEach(date => {
            // commented out for MBR-2992
            // const subDiscs = [];
            // date.event_date_sub_disciplines.forEach(subDisc => {
            //   subDiscs.push(new SelectOption(subDisc.subdiscipline_id.toString(), subDisc.subdiscipline_name));
            // });
            this.initialEventDateIds.push(date.event_date_id);
            // commented out for MBR-2992
            // date.event_date_sub_disciplines = subDiscs;
            alteredDates.push(Object.assign({}, date));
          });
          this.eventDates = this.eventService.sortEventDates(alteredDates);
          this.datesLoaded = true;
        });

        this.event_discipline = data.event_discipline.toString();

        const types = [];
        data.event_types.forEach(type => types.push(type.et_id.toString()));
        this.event_types = types;

        const calendars = [];
        data.event_special_calendars.forEach(calendar => calendars.push(calendar));
        this.event_special_calendars = calendars;

        this.event_start_date = new Date(this.event_start_date);
        this.event_end_date = new Date(this.event_end_date);
      }
    });
  }

  setEventFormData(data: any) {
    const event_contacts = this.contacts.filter(contact => contact.isValid);
    const event_special_calendars = this.event_id ? this.event_special_calendars.map(cal => cal.calendar_id) : data.event_special_calendars;  // tslint:disable-line: max-line-length
    this.eventFormData = Object.assign({}, data, { event_contacts, event_special_calendars });
  }

  getDisciplineName(disciplines, value) {
    let name = '';
    disciplines.forEach( disc => {
      if (disc.cd_id === value || disc.cd_id.toString() === value) {
        name = disc.cd_value;
      }
    });
    return name;
  }

  setTypeNames(types, value) {
    const arr = [];
    types.forEach( type => {
      switch (typeof value[0]) {
        case 'string':
          value.forEach( val => {
            if (type.et_id === val || type.et_id.toString() === val) {
              arr.push(type.et_name);
            }
          });
          break;
        case 'object':
          value.forEach( val => {
            if (type.et_id === val.et_id || type.et_id.toString() === val.et_id) {
              arr.push(type.et_name);
            }
          });
      }
    });
    return arr.length ? arr.join(', ') : 'None';
  }

  flush() {
    this.mode = 'create';
  }

  getSelectedSubDisciplines() {
    this.eventDates.forEach((eventDate) => {
    //  console.log(eventDate);
      eventDate.event_date_sub_disciplines.forEach(subdisc => {
        const name = subdisc.label ? subdisc.label : subdisc.subdiscipline_name;
        if (!this.getExistingSubDisc(name)) {
          this.selectedSubdisc.push(name);
        }
      });
    });
    return this.selectedSubdisc;
  }

  getExistingSubDisc(subdisc: string) {
    return  this.selectedSubdisc.includes(subdisc);
  }

  getEvents() {
    return this.http.get<Event[]>(environment.nextGenApiUrl + 'event');
  }
}
