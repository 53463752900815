import { SelectOption } from '.';

export class EventQueueRow {
  event_id: number;
  event_name: string;
  event_type: string;
  event_status: string;
  event_permit_id: number;
  event_discipline: number;
  event_discipline_name?: string;
  event_add_ons: number;
  event_start_date: Date;
  event_created_at: Date;
  event_unread_notifications: string;
  overview_link: string;

  constructor(data) {
    this.event_id = data.event_id;
    this.event_name = data.event_name;
    this.event_type = data.event_permit_id ? 'Permit' : 'Listing';
    this.event_status = data.event_status ? data.event_status.split('-').map(this.titleCaseWord).join(' ') : 'In Progress';
    this.event_permit_id = data.event_permit_id;
    // TODO: Lookup name?
    this.event_discipline = data.event_discipline;
    this.event_discipline_name = data.event_discipline_name || 'N/A';
    this.event_add_ons = data.event_add_ons;
    this.event_start_date = new Date(Date.parse(data.event_start_date));
    this.event_created_at = new Date(Date.parse(data.event_created_at));
    this.event_unread_notifications = data.event_unread_notifications;
    this.overview_link = data.overview_link;
  }

  titleCaseWord(word: string): string {
    if (!word || word.length <= 1) { return word; }
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
}
