import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styles: []
})
export class LoaderComponent implements OnInit {

  @Input() small = false;
  @Input() overlay = false;
  @Input() align = 'center';
  @Input() text = 'Loading';
  @Input() hasTopMargin = true;

  constructor() { }

  ngOnInit() {
  }

}
