import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { BaseService } from './base-service';
import { Comment, SelectOption } from '../classes';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
var CommentService = /** @class */ (function (_super) {
    tslib_1.__extends(CommentService, _super);
    function CommentService(http, cookie) {
        var _this = _super.call(this, http, cookie) || this;
        _this.http = http;
        _this.cookie = cookie;
        _this.usacAdminRecipients = [];
        _this.laAdminRecipients = [];
        _this.raceDirectorRecipients = [];
        _this.usacAdminRecipients = [
            new SelectOption('all', 'All'),
            new SelectOption('usac_admin', 'USAC Admin Only'),
            new SelectOption('la_usac_admin', 'LA + USAC Admin'),
            new SelectOption('rd_usac_admin', 'Race Director + USAC Admin'),
        ];
        _this.laAdminRecipients = [
            new SelectOption('all', 'All'),
            new SelectOption('la_usac_admin', 'LA + USAC Admin')
        ];
        _this.raceDirectorRecipients = [
            new SelectOption('all', 'All'),
            new SelectOption('rd_usac_admin', 'Race Director + USAC Admin')
        ];
        return _this;
    }
    CommentService.prototype.getCommentsForEvent = function (eventId) {
        var _this = this;
        return this.http.get(this.url("comment/event/" + eventId), this.options)
            .pipe(tap(function (_) { return _this.log('fetched comments'); }), catchError(this.handleError('getCommentsForEvent', [])));
    };
    CommentService.prototype.addCommentForEvent = function (eventId, comment) {
        var _this = this;
        return this.http.post(this.url("comment/event/" + eventId), comment, this.options)
            .pipe(tap(function (newComment) { return _this.log("added comment id=" + newComment.comment_id); }), catchError(this.handleError('addCommentForEvent', new Comment('', ''), { eventId: eventId, comment: comment })));
    };
    CommentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommentService_Factory() { return new CommentService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: CommentService, providedIn: "root" });
    return CommentService;
}(BaseService));
export { CommentService };
