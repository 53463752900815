/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../atoms/loader/loader.component.ngfactory";
import * as i2 from "../../atoms/loader/loader.component";
import * as i3 from "../../molecules/insurance-accordion/insurance-accordion.component.ngfactory";
import * as i4 from "../../molecules/insurance-accordion/insurance-accordion.component";
import * as i5 from "../../atoms/button/button.component.ngfactory";
import * as i6 from "../../atoms/button/button.component";
import * as i7 from "@angular/common";
import * as i8 from "../../molecules/modal/modal.component.ngfactory";
import * as i9 from "../../molecules/modal/modal.component";
import * as i10 from "@ng-bootstrap/ng-bootstrap";
import * as i11 from "../../atoms/modal-body/modal-body.component.ngfactory";
import * as i12 from "../../atoms/modal-body/modal-body.component";
import * as i13 from "./pre-event-insurance.component";
import * as i14 from "../../services/certificate.service";
import * as i15 from "../../services/events.service";
import * as i16 from "../../services/profile.service";
import * as i17 from "@angular/router";
var styles_PreEventInsuranceComponent = [];
var RenderType_PreEventInsuranceComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PreEventInsuranceComponent, data: {} });
export { RenderType_PreEventInsuranceComponent as RenderType_PreEventInsuranceComponent };
function View_PreEventInsuranceComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], { small: [0, "small"], align: [1, "align"] }, null)], function (_ck, _v) { var currVal_0 = true; var currVal_1 = "left"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_PreEventInsuranceComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Upon payment of insured persons the certificate information will populate here."]))], null, null); }
function View_PreEventInsuranceComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-insurance-accordion", [], null, [[null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("delete" === en)) {
        var pd_0 = (_co.handleDelete($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_InsuranceAccordionComponent_0, i3.RenderType_InsuranceAccordionComponent)), i0.ɵdid(2, 49152, null, 0, i4.InsuranceAccordionComponent, [], { certificate: [0, "certificate"], isUsacAdmin: [1, "isUsacAdmin"] }, { delete: "delete" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.isUsacAdmin; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_PreEventInsuranceComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToCerts() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i0.ɵdid(1, 114688, null, 0, i6.ButtonComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 0, "i", [["class", "icon icon-circle-plus"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, [" Purchase Additional Certificates "]))], function (_ck, _v) { var currVal_0 = "btn btn-light btn-md"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PreEventInsuranceComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "spinner-border spinner-border-sm ml-1"]], null, null, null, null, null))], null, null); }
function View_PreEventInsuranceComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitInsuranceChanges() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i0.ɵdid(2, 114688, null, 0, i6.ButtonComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵted(-1, 0, [" Submit Changes to Insurance "])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_PreEventInsuranceComponent_7)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn btn-secondary btn-md"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.insuranceSubmitting; _ck(_v, 5, 0, currVal_1); }, null); }
function View_PreEventInsuranceComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "mt-2 text-center small ", _co.insuranceMessageClass, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.insuranceMessage; _ck(_v, 1, 0, currVal_1); }); }
function View_PreEventInsuranceComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreEventInsuranceComponent_4)), i0.ɵdid(2, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreEventInsuranceComponent_5)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreEventInsuranceComponent_6)), i0.ɵdid(7, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreEventInsuranceComponent_8)), i0.ɵdid(9, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.certificates; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.allowAdd; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.allowInsuranceSubmit; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.insuranceMessage; _ck(_v, 9, 0, currVal_3); }, null); }
function View_PreEventInsuranceComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "spinner-border spinner-border-sm ml-1"]], null, null, null, null, null))], null, null); }
function View_PreEventInsuranceComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "mt-2 text-right text-danger small"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Failed to delete insured certificate. Please try again or contact IT support. "]))], null, null); }
export function View_PreEventInsuranceComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Insured Certificates"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreEventInsuranceComponent_1)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreEventInsuranceComponent_2)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreEventInsuranceComponent_3)), i0.ɵdid(7, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 16, "app-modal", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeDeleteModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ModalComponent_0, i8.RenderType_ModalComponent)), i0.ɵdid(9, 638976, null, 0, i9.ModalComponent, [i10.NgbModal], { id: [0, "id"], title: [1, "title"], isOpen: [2, "isOpen"] }, { close: "close" }), (_l()(), i0.ɵeld(10, 0, null, 0, 14, "app-modal-body", [], null, null, null, i11.View_ModalBodyComponent_0, i11.RenderType_ModalBodyComponent)), i0.ɵdid(11, 49152, null, 0, i12.ModalBodyComponent, [], null, null), (_l()(), i0.ɵeld(12, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Are you sure you want to delete this Insured Certificate? After you've completed your changes, please click \"Submit Changes to Insurance\" to notify the insurance company with an updated list of insured persons. "])), (_l()(), i0.ɵeld(14, 0, null, 0, 8, "div", [["class", "d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 2, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDeleteModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i0.ɵdid(16, 114688, null, 0, i6.ButtonComponent, [], { type: [0, "type"], disabled: [1, "disabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["No, Cancel"])), (_l()(), i0.ɵeld(18, 0, null, null, 4, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteCertificate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i0.ɵdid(19, 114688, null, 0, i6.ButtonComponent, [], { type: [0, "type"], disabled: [1, "disabled"] }, null), (_l()(), i0.ɵted(-1, 0, [" Yes, Delete "])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_PreEventInsuranceComponent_9)), i0.ɵdid(22, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_PreEventInsuranceComponent_10)), i0.ɵdid(24, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.loading && !_co.paidCertificates.length); _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.loading; _ck(_v, 7, 0, currVal_2); var currVal_3 = "delete-certificate"; var currVal_4 = "Delete Insured Certificate"; var currVal_5 = _co.deleteModalOpen; _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = "btn-light"; var currVal_7 = _co.deleting; _ck(_v, 16, 0, currVal_6, currVal_7); var currVal_8 = "btn-primary"; var currVal_9 = _co.deleting; _ck(_v, 19, 0, currVal_8, currVal_9); var currVal_10 = _co.deleting; _ck(_v, 22, 0, currVal_10); var currVal_11 = _co.showDeleteError; _ck(_v, 24, 0, currVal_11); }, null); }
export function View_PreEventInsuranceComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-pre-event-insurance", [], null, null, null, View_PreEventInsuranceComponent_0, RenderType_PreEventInsuranceComponent)), i0.ɵdid(1, 114688, null, 0, i13.PreEventInsuranceComponent, [i14.CertificateService, i15.EventService, i16.ProfileService, i17.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreEventInsuranceComponentNgFactory = i0.ɵccf("app-pre-event-insurance", i13.PreEventInsuranceComponent, View_PreEventInsuranceComponent_Host_0, { event: "event", userRole: "userRole" }, {}, []);
export { PreEventInsuranceComponentNgFactory as PreEventInsuranceComponentNgFactory };
