<ngb-alert *ngFor="let alert of alerts" [type]="alert.type" [dismissible]="alert.dismissible" (close)="close(alert)">
  <div>
    <i *ngIf="alert.icon" class="icon icon-{{alert.icon}}"></i>
    <span *ngIf="alert.title" class="alert-title">{{alert.title}}</span>
  </div>
  <span class="alert-message mr-2" [innerHTML]="alert.message"></span>
  <app-button *ngIf="alert.link"
    [type]="btnClass(alert)"
    [mode]="'button'"
    (click)="toLink(alert.link)"
  >{{alert.linkText | titlecase}}</app-button>
</ngb-alert>
