import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { EventService } from './events.service';
import { ProfileService } from './profile.service';
import { EventDate, SelectOption } from '../classes';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "./events.service";
import * as i4 from "./profile.service";
var EventListingService = /** @class */ (function () {
    function EventListingService(http, router, eventService, profileService) {
        this.http = http;
        this.router = router;
        this.eventService = eventService;
        this.profileService = profileService;
        this.contacts = [];
        this.validateContacts = false;
        this.contactsLimit = 3;
        this.isRaceDirectorLicenseValid = false;
        this.mode = 'create';
        this.initialEventDateIds = [];
        // TODO: Set disciplines based on name values, as IDs may change
        this.disciplines = [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 38];
        this.competitiveDisciplines = ['19', '20', '21', '22', '23', '30'];
        this.bmxDisciplines = ['22', '30'];
        this.datesLoaded = false;
        this.organizerLoaded = false;
        this.formControlSections = [
            [
                'event_organizer',
                'event_organizer_comp_id',
                'organizerName',
                'organizerEmail',
                'event_sponsoring_clubs',
            ],
            [
                'event_name',
                'event_start_date',
                'event_end_date',
                'event_discipline',
                'event_competitive',
                'event_types',
                'event_special_calendars',
                'calendarCode',
            ],
            // "event-date" section, handled differently due to nested forms
            null,
            [
                'event_bike_reg',
                'bike_reg_check',
                'other_reg_check',
                'event_reg_url',
                'event_website',
                'event_facebook_url',
                'event_twitter_url',
                'event_youtube_url',
                'event_instagram_url',
                'event_announcement_switch',
                'event_announcement',
                'event_logo'
            ]
        ];
        this.setInitialValues();
    }
    EventListingService.prototype.setInitialValues = function (type) {
        this.eventDates = type === 'reset' ? [] : [new EventDate()];
        this.event_types = [];
        this.event_sponsoring_clubs = [];
        this.event_special_calendars = [];
        this.event_start_date = new Date();
        this.event_end_date = new Date();
        this.selectedSubdisc = [];
        this.eventFormData = {};
    };
    EventListingService.prototype.addContcat = function (contact) {
        this.contacts.push(contact);
        if (this.contacts.length > this.contactsLimit) {
            this.validateContacts = true;
        }
    };
    EventListingService.prototype.removeContact = function (index) {
        this.contacts.splice(index, 1);
    };
    EventListingService.prototype.createEventDate = function () {
        var eventDate;
        eventDate = new EventDate();
        if (this.eventDates.length > 0) {
            var previousDate = this.eventDates[(this.eventDates.length - 1)];
            Object.assign(eventDate, previousDate);
            eventDate.event_date_start = '';
            eventDate.event_date_id = null;
            if (eventDate.hasOwnProperty('dateCard') === true) {
                delete eventDate.dateCard;
            }
            if (this.mode === 'update') {
                eventDate.event_date_sub_disciplines = previousDate.event_date_sub_disciplines;
            }
        }
        this.eventDates.push(eventDate);
    };
    EventListingService.prototype.removeEventDate = function (index) {
        this.eventDates.splice(index, 1);
    };
    EventListingService.prototype.optionsWithArray = function (options) {
        var selectOptions;
        selectOptions = [];
        if (options === undefined || options === null) {
            return selectOptions;
        }
        options.forEach(function (option) {
            var index = option.toLowerCase();
            index = index.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, key) {
                if (+match === 0) {
                    return '';
                }
                return key === 0 ? match.toLowerCase() : match.toUpperCase();
            });
            selectOptions.push(new SelectOption(index, option));
        });
        return selectOptions;
    };
    EventListingService.prototype.reset = function () {
        // flush the data here.
        this.contacts = [];
        this.eventDates = undefined;
        this.event_id = undefined;
        this.event_status = undefined;
        this.event_permit_id = undefined;
        this.event_organizer = undefined;
        this.event_organizer_comp_id = undefined;
        this.organizerName = undefined;
        this.organizerEmail = undefined;
        this.event_name = undefined;
        this.event_discipline = undefined;
        this.event_competitive = undefined;
        this.event_start_date = undefined;
        this.event_end_date = undefined;
        this.event_types = undefined;
        this.event_special_calendars = undefined;
        this.calendarCode = undefined;
        this.event_reg_url = undefined;
        this.event_bike_reg = undefined;
        this.event_website = undefined;
        this.event_facebook_url = undefined;
        this.event_twitter_url = undefined;
        this.event_youtube_url = undefined;
        this.event_instagram_url = undefined;
        this.event_sponsoring_clubs = undefined;
        this.validateContacts = false;
        this.contactsLimit = 3;
        this.isRaceDirectorLicenseValid = false;
        this.mode = 'create';
        this.eventId = undefined;
        this.updateInfo = undefined;
        this.reasonInfo = undefined;
        this.event = undefined;
        this.selectedSubdisc = undefined;
        this.event_logo = undefined;
        this.event_announcement = undefined;
        this.diffDays = undefined;
        this.initialEventDateIds = [];
        this.datesLoaded = false;
        this.organizerLoaded = false;
        this.setInitialValues('reset');
    };
    EventListingService.prototype.isUpdateReady = function () {
        return !(this.updateInfo === undefined || this.updateInfo === '');
    };
    EventListingService.prototype.getEventTypes = function () {
        return this.http.get(environment.nextGenApiUrl + 'event/types');
    };
    EventListingService.prototype.getDisciplines = function () {
        return this.http.get(environment.nextGenApiUrl + 'event/disciplines');
    };
    EventListingService.prototype.filterDisciplines = function (discs) {
        var _this = this;
        return discs.filter(function (disc) { return _this.disciplines.includes(disc.cd_id); });
    };
    Object.defineProperty(EventListingService.prototype, "isEventCompetitive", {
        get: function () {
            if (this.event_discipline) {
                return this.competitiveDisciplines.includes(this.event_discipline.toString());
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListingService.prototype, "isBmx", {
        get: function () {
            if (this.event_discipline) {
                return this.bmxDisciplines.includes(this.event_discipline.toString());
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListingService.prototype, "isGranFondo", {
        get: function () {
            if (this.event_discipline) {
                return this.event_discipline === '24';
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListingService.prototype, "isVirtual", {
        get: function () {
            if (this.event_discipline) {
                return this.event_discipline === '38';
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    EventListingService.prototype.sortedEventDates = function () {
        return this.eventService.sortEventDates(this.eventDates);
    };
    EventListingService.prototype.fetchEventData = function (id) {
        var _this = this;
        this.eventService.getEventById(id).subscribe(function (data) {
            if (!data) {
                // If event doesn't exist, redirect to event queue
                _this.router.navigate(['queue']);
            }
            else {
                Object.assign(_this, data);
                _this.profileService.getProfile(data.event_organizer).subscribe(function (profile) {
                    if (profile) {
                        _this.organizerName = profile.profile_first_name + ' ' + profile.profile_last_name;
                        _this.organizerEmail = profile.profile_email;
                        _this.event_organizer_comp_id = profile.profile_comp_id;
                    }
                    _this.organizerLoaded = true;
                });
                // Contacts
                _this.contacts = data.event_contacts;
                data.event_contacts.forEach(function (contact) { return contact.isValid = true; });
                // Event Dates
                _this.eventService.getEventDates(data.event_id).subscribe(function (dates) {
                    var alteredDates = [];
                    dates.forEach(function (date) {
                        // commented out for MBR-2992
                        // const subDiscs = [];
                        // date.event_date_sub_disciplines.forEach(subDisc => {
                        //   subDiscs.push(new SelectOption(subDisc.subdiscipline_id.toString(), subDisc.subdiscipline_name));
                        // });
                        _this.initialEventDateIds.push(date.event_date_id);
                        // commented out for MBR-2992
                        // date.event_date_sub_disciplines = subDiscs;
                        alteredDates.push(Object.assign({}, date));
                    });
                    _this.eventDates = _this.eventService.sortEventDates(alteredDates);
                    _this.datesLoaded = true;
                });
                _this.event_discipline = data.event_discipline.toString();
                var types_1 = [];
                data.event_types.forEach(function (type) { return types_1.push(type.et_id.toString()); });
                _this.event_types = types_1;
                var calendars_1 = [];
                data.event_special_calendars.forEach(function (calendar) { return calendars_1.push(calendar); });
                _this.event_special_calendars = calendars_1;
                _this.event_start_date = new Date(_this.event_start_date);
                _this.event_end_date = new Date(_this.event_end_date);
            }
        });
    };
    EventListingService.prototype.setEventFormData = function (data) {
        var event_contacts = this.contacts.filter(function (contact) { return contact.isValid; });
        var event_special_calendars = this.event_id ? this.event_special_calendars.map(function (cal) { return cal.calendar_id; }) : data.event_special_calendars; // tslint:disable-line: max-line-length
        this.eventFormData = Object.assign({}, data, { event_contacts: event_contacts, event_special_calendars: event_special_calendars });
    };
    EventListingService.prototype.getDisciplineName = function (disciplines, value) {
        var name = '';
        disciplines.forEach(function (disc) {
            if (disc.cd_id === value || disc.cd_id.toString() === value) {
                name = disc.cd_value;
            }
        });
        return name;
    };
    EventListingService.prototype.setTypeNames = function (types, value) {
        var arr = [];
        types.forEach(function (type) {
            switch (typeof value[0]) {
                case 'string':
                    value.forEach(function (val) {
                        if (type.et_id === val || type.et_id.toString() === val) {
                            arr.push(type.et_name);
                        }
                    });
                    break;
                case 'object':
                    value.forEach(function (val) {
                        if (type.et_id === val.et_id || type.et_id.toString() === val.et_id) {
                            arr.push(type.et_name);
                        }
                    });
            }
        });
        return arr.length ? arr.join(', ') : 'None';
    };
    EventListingService.prototype.flush = function () {
        this.mode = 'create';
    };
    EventListingService.prototype.getSelectedSubDisciplines = function () {
        var _this = this;
        this.eventDates.forEach(function (eventDate) {
            //  console.log(eventDate);
            eventDate.event_date_sub_disciplines.forEach(function (subdisc) {
                var name = subdisc.label ? subdisc.label : subdisc.subdiscipline_name;
                if (!_this.getExistingSubDisc(name)) {
                    _this.selectedSubdisc.push(name);
                }
            });
        });
        return this.selectedSubdisc;
    };
    EventListingService.prototype.getExistingSubDisc = function (subdisc) {
        return this.selectedSubdisc.includes(subdisc);
    };
    EventListingService.prototype.getEvents = function () {
        return this.http.get(environment.nextGenApiUrl + 'event');
    };
    EventListingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventListingService_Factory() { return new EventListingService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.EventService), i0.ɵɵinject(i4.ProfileService)); }, token: EventListingService, providedIn: "root" });
    return EventListingService;
}());
export { EventListingService };
