export class Comment {
  comment_id: number;
  commenter: string;
  date: string;
  message: string;
  scope: string;

  constructor(message: string, scope: string) {
    this.message = message;
    this.scope = scope;
  }
}
