<div class="form-row align-items-baseline">
  <h4>Drivers</h4>
</div>
<!-- Added Driver -->
<div *ngFor="let driver of drivers; let i = index;" class="row driver-row">
  <div class="col-5 col-md-5" >{{driver.first_name}} {{driver.last_name}}</div>
  <div class="col-5 col-md-5 driver-status">
    <span class="badge badge-pill" [ngClass]="{'badge-success': valid(driver), 'badge-danger': invalid(driver), 'badge-warning': unknown(driver)}">
        <p *ngIf="valid(driver)">Valid MVR</p>
        <p *ngIf="invalid(driver)">Invalid MVR</p>
        <p *ngIf="unknown(driver)">Unknown MVR</p>
      </span>
  </div>
  <div class="col-2 col-sm-2 align-self-center">
      <span (click)="removeDriver(i)" class="cursor-pointer"><i class="icon icon-delete"></i></span>
  </div>
</div>
<br>
<!-- Driver Search -->
<h5 class="space-below-sm">Add a driver</h5>
<form [formGroup]="searchDriverForm">
  <div class="form-row" (click)="hideAddDriverForm()">
    <div class="col-sm-6">
      <app-text-input
        formControlName="comp_id"
        [type]="'text'"
        [name]="'comp_id'"
        [label]="'Driver\'s Member ID'"
        [placeholder]="'12345'"
        [formSubmitted]="searchDriverSubmitted"
        [readonly]=""></app-text-input>
      </div>
    <div class="col-sm-6">
      <app-text-input
        formControlName="name"
        [type]="'text'"
        [name]="'name'"
        [label]="'Driver Name'"
        [placeholder]="'Jane Doe'"
        [formSubmitted]="searchDriverSubmitted"
        [readonly]=""></app-text-input>
    </div>
  </div>
  <div class="row space-below-lg">
    <div class="col-sm-6">
      <app-button
        [type]="'btn-secondary w-100'"
        [mode] = "'button'"
        (click)="searchDriver()"
        [disabled]="disableSearchBtn"
      >Search Driver</app-button>
    </div>
    <div class="col-sm-6">
      <app-button
        [type]="'btn-link'"
        [mode]="'button'"
        (click) = "addManually()"
        >or Add Manually</app-button>
    </div>
  </div>
</form>
<!-- Driver Search Result -->
<p *ngIf="searching">Searching...</p>
<div *ngIf="displaySearchResults" class="driver-search">
  <div *ngIf="result else noResults">
    <h5>Is this your driver?</h5>
    <div class="row driver-result">
      <div class="col-md-7" >
        <p>{{result.first_name}} {{result.last_name}}</p>
        <p>Age: {{result.age}}</p>
        <p *ngIf="result.city && result.state">{{result.city}}<span *ngIf="result.state">, {{result.state}}</span></p>
      </div>
      <div class="col-md-5 driver-status align-self-start">
        <!-- TODO: Change to display license status once licenses are validated -->
        <!-- <span  *ngIf="result.valid_license" class="badge badge-pill badge-success">
          <p>Valid License</p> -->
        <span *ngIf="valid(result)" class="badge badge-pill badge-success">
          <p>Valid MVR</p>
        </span>
      </div>
    </div>
    <app-alert *ngIf="invalid(result) || unknown(result)" [alerts]="alerts"></app-alert>
    <div class="row mb-2" *ngIf="valid(result)">
      <div class="col-sm-6">
        <app-button
          [type]="'btn-primary w-100'"
          [mode] = "'button'"
          (click)="saveDriver(result)">Save Driver</app-button>
      </div>
    </div>
  </div>
  <ng-template #noResults>
    <h4>Exact match not found.</h4>
  </ng-template>
</div>


<!-- Add Driver Manually -->
<br>
<form [formGroup]="addDriverForm">
  <div *ngIf="displayAddDriverForm" class="form-row">
    <div class="col-sm-6">
      <app-text-input
        formControlName="first_name"
        [type]="'text'"
        [name]="'first_name'"
        [label]="'First Name'"
        [placeholder]="'Jane'"
        [formSubmitted]="addDriverSubmitted"
        [errors]="adf.first_name.errors"
        [required]="'true'"></app-text-input>
      </div>
    <div class="col-sm-6">
      <app-text-input
        formControlName="last_name"
        [type]="'text'"
        [name]="'last_name'"
        [label]="'Last Name'"
        [placeholder]="'Doe'"
        [formSubmitted]="addDriverSubmitted"
        [errors]="adf.last_name.errors"
        [required]="'true'"></app-text-input>
    </div>
    <div class="col-sm-12">
      <app-text-input
        formControlName="license_number"
        [type]="'text'"
        [name]="'license_number'"
        [label]="'License Number'"
        [placeholder]="'CO-123456'"
        [icon]=true
        [iconClass]="'icon-information'"
        [tooltipContent]="'Please prefix license number with the state abbreviation.'"
        [formSubmitted]="addDriverSubmitted"
        [errors]="adf.license_number.errors"
        ></app-text-input>
    </div>
    <app-alert [alerts]="alerts"></app-alert>
    <div class="col-sm-6">
      <app-button
        [type]="'btn-primary w-100'"
        [mode] = "'button'"
        (click)="saveDriver()">Save Driver</app-button>
    </div>
  </div>
</form>
