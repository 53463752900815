import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService, ProfileService } from 'src/app/services';
import { Event } from 'src/app/classes';
var PostEventPart1Component = /** @class */ (function () {
    function PostEventPart1Component(route, router, eventService, profileService) {
        this.route = route;
        this.router = router;
        this.eventService = eventService;
        this.profileService = profileService;
    }
    PostEventPart1Component.prototype.ngOnInit = function () {
        var _this = this;
        this.urlSegments = this.route.snapshot.url.map(function (segment) { return segment.path; });
        this.eventId = this.route.snapshot.paramMap.get('eventId');
        this.eventDateId = this.route.snapshot.paramMap.get('dateId');
        this.eventService.getEventById(this.eventId).subscribe(function (event) {
            // Event doesn't exist
            if (!event) {
                _this.toEventQueue();
            }
            else {
                _this.event = new Event(event);
                // Event isn't permitted
                if (!_this.event.isPermit) {
                    _this.toOverview();
                }
                else {
                    if (_this.eventDateId) {
                        if (_this.isSeries) {
                            _this.eventService.getEventDate(_this.eventDateId).subscribe(function (eventDate) {
                                if (eventDate && eventDate.event_date_event_id.toString() === _this.eventId) {
                                    _this.eventDate = eventDate;
                                    // Event date doesn't belong to the event
                                }
                                else {
                                    _this.toOverviewPostEvent();
                                }
                            });
                            // Has an event date param, but isn't a series
                        }
                        else {
                            _this.toOverviewPostEvent();
                        }
                        // Is a series, but doesn't have an event date param
                    }
                    else if (_this.isSeries) {
                        _this.toOverviewPostEvent();
                    }
                }
            }
        });
        if (!!this.profileService.currentUser) {
            this.setUser();
        }
        else {
            this.profileService.getCurrentUser().subscribe(function () {
                _this.setUser();
            });
        }
    };
    PostEventPart1Component.prototype.setUser = function () {
        var _this = this;
        this.currentUser = this.profileService.currentUser;
        this.profileService.getUserPermissions().subscribe(function (permissions) {
            _this.userRole = _this.profileService.setUserRole(permissions);
        });
    };
    Object.defineProperty(PostEventPart1Component.prototype, "isSeries", {
        get: function () {
            return this.eventService.isSeries(this.event);
        },
        enumerable: true,
        configurable: true
    });
    PostEventPart1Component.prototype.toOverview = function () {
        this.router.navigate(["event/" + this.eventId + "/overview"]);
    };
    PostEventPart1Component.prototype.toOverviewPostEvent = function () {
        this.router.navigate(["event/" + this.eventId + "/overview"], { fragment: 'post-event' });
    };
    PostEventPart1Component.prototype.toEventQueue = function () {
        this.router.navigate(['event/queue']);
    };
    PostEventPart1Component.prototype.displayFormEvent = function () {
        return !this.eventDateId && !!this.event && !!this.userRole;
    };
    PostEventPart1Component.prototype.displayFormEventDate = function () {
        return !!this.event && !!this.eventDate && !!this.userRole;
    };
    return PostEventPart1Component;
}());
export { PostEventPart1Component };
