import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { InsuranceCertificate } from '../classes/insurance-certificate';
import { ClubService } from './clubs.service';
import { ProfileService } from './profile.service';
import { Profile, Certificate, Club } from '../classes';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService {

  public insuranceArrayIndex = 0;
  public includedCertificates: Array<InsuranceCertificate>;
  public certificates: Array<InsuranceCertificate>;
  public initComplete$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private clubService: ClubService, private profileService: ProfileService) {
    this.certificates = [];
    this.includedCertificates = [];
  }

  init(organizerId: number, sponsoringClubs: Array<any>) {
    this.includedCertificates = [];
    const initQuantity = (organizerId ? 1 : 0) + (sponsoringClubs ? sponsoringClubs.length : 0);
    let currentQuantity = 0;

    if (organizerId) {
      this.profileService.getProfile(organizerId).subscribe(organizer => {
        this.addIncludedCert('Event Organizer', null, organizer);
        currentQuantity += 1;
        this.checkInitComplete(initQuantity, currentQuantity);
      });
    }
    if (sponsoringClubs !== undefined && sponsoringClubs.length > 0) {
      sponsoringClubs.forEach(club => {
        this.clubService.getClub(club.club_id).subscribe((club: Club) => {
          if (club) { this.addIncludedCert('Sponsoring Club', club); }
          currentQuantity += 1;
          this.checkInitComplete(initQuantity, currentQuantity);
        });
      });
    }
    this.checkInitComplete(initQuantity, currentQuantity);
  }

  private checkInitComplete(initQuantity: number, currentQuantity: number): void {
    if (currentQuantity === initQuantity) {
      this.initComplete$.next(true);
    }
  }

  addIncludedCert(type: string, club?: Club, organizer?: Profile) {
    const cert = new InsuranceCertificate();

    cert.type = type;
    cert.isIncluded = true;
    cert.price = 0;
    if (club) {
      const p = club.club_phone;
      cert.name = club.club_name;
      cert.clubId = club.club_id;
      cert.street = club.club_address1;
      cert.unit = club.club_address2;
      cert.city = club.city;
      cert.state = club.club_state;
      cert.zip = club.club_zip;
      cert.email = club.club_email;
      cert.phone = p && p.length === 10 ? `(${p.substring(0, 3)}) ${p.substring(3, 6)}-${p.substring(6)}` : p;
    }
    if (organizer) {
      cert.name = organizer.profile_first_name + ' ' + organizer.profile_last_name;
      cert.email = organizer.profile_email;
      const address = Array.isArray(organizer.address) && organizer.address.find(addr => addr.address_primary === 'Y');
      if (address) {
        cert.street = address.address_street;
        cert.city = address.address_city;
        cert.state = address.address_state;
        cert.zip = address.address_postal_code;
      }
    }

    this.includedCertificates.push(cert);
  }
  addCert() {
    const cert = new InsuranceCertificate();

    this.certificates.push(cert);
  }
  removeCertificate(index: number) {
    this.certificates.splice(index, 1);
  }

  createFromCertificate(certificate: Certificate) {
    const cert = new InsuranceCertificate();

    const p = certificate.phone ? certificate.phone.toString() : '';

    cert.certificateId = certificate.id;
    cert.relationship = certificate.relationship;
    if (cert.relationship === 'organizer') { cert.type = 'Event Organizer'; }
    if (cert.relationship === 'sponsoring_club') { cert.type = 'Sponsoring Club'; }
    cert.isIncluded = certificate.type === 'named';
    if (cert.isIncluded) { cert.price = 0; }
    cert.name = certificate.name;
    cert.street = certificate.street;
    cert.unit = certificate.unit;
    cert.city = certificate.city;
    cert.state = certificate.state;
    cert.zip = certificate.zip;
    cert.email = certificate.email;
    cert.phone = p.length === 10 ? `(${p.substring(0, 3)}) ${p.substring(3, 6)}-${p.substring(6)}` : p;
    cert.specificVerbiage = certificate.insured_info;
    cert.endorsement = certificate.endorsement;
    cert.customRelationship = certificate.custom_relationship;

    return cert;
  }
}
