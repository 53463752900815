import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormFieldErrors } from '../../classes';


const noop = () => {};

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true
    }
  ]
})
export class TextInputComponent implements OnInit {
  currentValue = '';

  @Input() type: string;
  @Input() name: string;
  @Input() id: number;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() formControlName: string;
  @Input() formSubmitted: boolean;
  @Input() errors: FormFieldErrors;
  @Input() readonly: boolean;
  @Input() prepend: boolean;
  @Input() prependContent: string;
  @Input() append: boolean;
  @Input() appendContent: string;
  @Input() icon: boolean;
  @Input() iconClass: string;
  @Input() tooltipContent: string;
  @Input() maxlength: number;
  @Input() min: number;
  @Input() max: number;
  @Input() patternErrorMessage: string;


  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input()
  get value() {
    return this.currentValue;
  }

  set value(val) {
    this.currentValue = val;
    this.change.emit(this.currentValue);
    this.valueChange.emit(this.currentValue);
    this.onChangeCallback(this.currentValue);
  }

  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  writeValue(value: any) {
    this.currentValue = value;
    this.onChangeCallback(value);
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  constructor() {}

  ngOnInit() {
  }

  onChange(event: any) {
    // console.log('input changed', event);
    // this.onChangeCallback(event.target.value);
  }
}
