import { Component, OnInit } from '@angular/core';

import { ProfileService } from '../../services';
import { CheckoutData } from '../../classes';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: []
})
export class ThankYouComponent implements OnInit {
  checkout: CheckoutData;
  first_name: string;
  last_name: string;

  constructor(
    private profileService: ProfileService
  ) { }

  ngOnInit() {
    try {
      this.checkout = JSON.parse(localStorage.getItem('checkout'));
    } catch (err) {
      console.warn(err);
    }

    this.profileService.getCurrentUser().subscribe(user => {
      this.first_name = user.profile_first_name;
      this.last_name = user.profile_last_name;
    });
  }

  getLineItems() {
    return this.checkout ? this.checkout.lineItems : [];
  }

  checkoutHasProduct(sku: string) {
    return this.checkout && this.checkout.lineItems.some(item => item.sku && item.sku.includes(sku));
  }

  checkoutHasAutoMoto() {
    return this.checkoutHasProduct('INS-AUTO') || this.checkoutHasProduct('INS-MOTO');
  }

  // TODO: @Charlie - Should we still be checking localStorage for the checkout,
  // or instead utilize the order / draft order ID as a query param on the return url from Shopify?
  checkoutDataString() {
    if (this.checkout) {
      return JSON.stringify(this.checkout, null, 2);
    } else {
      return 'No checkout data';
    }
  }
}
