import { Component, OnInit } from '@angular/core';
import { ClubService, EventListingService, LocationService } from '../../services';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-event-listing',
  templateUrl: './event-listing.component.html',
  styleUrls: []
})
export class EventListingComponent implements OnInit {
  currentStep = 0;
  eventId: string;
  newLoaded = false;

  get dataLoaded(): boolean {
    return this.eventListing.datesLoaded && this.eventListing.organizerLoaded
      && this.clubService.clubsLoaded && !!this.locationService.stateOptions;
  }

  constructor(
    private clubService: ClubService,
    public eventListing: EventListingService,
    private locationService: LocationService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.eventId = this.route.snapshot.paramMap.get('eventId');
    this.eventListing.mode = this.eventId ? 'update' : 'create';
    if (this.eventListing.mode === 'update') {
      this.eventListing.fetchEventData(this.eventId);
    } else {
      this.eventListing.reset();
      this.newLoaded = true;
    }

    this.clubService.setClubListOptions();
    this.locationService.getStates().subscribe();
  }

}
