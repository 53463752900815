import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MerchantAccountTag, ShopifyService } from '../../shared';
import { Event, ShopifyOrder, ShopifyDraftOrder, ShopifyOrderTransaction, ShopifyDiscount } from 'src/app/classes';
import { EventDayService } from 'src/app/services';

@Component({
  selector: 'app-payment-accordion',
  templateUrl: './payment-accordion.component.html',
  styles: []
})
export class PaymentAccordionComponent implements OnInit {

  @Input() event: Event;
  @Input() draftOrder: ShopifyDraftOrder;
  @Input() order: ShopifyOrder;
  @Input() userRole: string;

  @Output() deleteDraftOrder: EventEmitter<number> = new EventEmitter<number>();

  open = false;
  toggleIcon = 'icon-chevron-down';
  modalOpen = false;
  tosModalOpen = false;
  eventId: number;

  orderTransactions: ShopifyOrderTransaction[] = [];

  get refunds(): ShopifyOrderTransaction[] { return this.orderTransactions.filter(transaction => transaction.kind === 'refund'); }

  constructor(
    private eventDayService: EventDayService,
    private shopifyService: ShopifyService
  ) { }

  ngOnInit() {
    if (this.order) {
      console.log(`Order #${this.order.id}`, this.order);
      this.shopifyService.getShopifyOrderTransactions({ order_id: this.order.id }).subscribe(transactions => {
        this.orderTransactions = transactions;
      });
    }
  }

  get isUsacAdmin(): boolean { return this.userRole === 'usac_admin'; }

  get paidOrFullRefund(): boolean {
    return this.order && (
      this.order.financial_status === 'paid' ||
      this.order.financial_status === 'refunded' ||
      this.order.financial_status === 'authorized'
    );
  }

  get pendingOrPartial(): boolean {
    return this.order && (
      this.order.financial_status === 'pending' ||
      this.order.financial_status === 'partially_paid' ||
      this.order.financial_status === 'partially_refunded'
    );
  }

  get voided(): boolean { return this.order && this.order.financial_status === 'voided'; }

  get refund(): boolean {
    return this.refunds.length > 0;
  }

  get paidStatus(): string {
    if (this.order) {
      return this.order.financial_status;
    } else {
      return 'Payment Due';
    }
  }

  get subtotalPrice() {
    return this.order ? this.order.subtotal_price : this.draftOrder.subtotal_price;
  }

  get tax() {
    return this.order ? this.order.total_tax : this.draftOrder.total_tax;
  }

  get totalPrice() {
    return this.order ? this.order.total_price : this.draftOrder.total_price;
  }

  get lineItems() {
    return this.order ? this.order.line_items : this.draftOrder.line_items;
  }

  get title(): string { return this.order ? `Order ${this.order.name}` : `Pending ${this.draftOrder.name}`; }

  get displayDate(): Date {
    const date = this.order ? this.order.processed_at : this.draftOrder.created_at;
    return typeof date === 'string' ? new Date(date.replace(/-/g, '/')) : date;
  }

  get paidByCard(): boolean { return this.order.gateway === 'orbital'; }

  get paymentMethod(): string { return this.paidByCard ? 'Credit/Debit' : this.order.gateway; }

  get cardDetails(): string {
    if (this.orderTransactions.length) {
      const paymentDetails = this.orderTransactions[0].payment_details;
      return paymentDetails.credit_card_company + ' - ' + paymentDetails.credit_card_number.match(/\d{4}/)[0];
    } else {
      return 'N/A';
    }
  }

  get totalRefunded() {
    let total = 0;
    this.refunds.forEach(refund => {
      total += refund.amount;
    });
    return total;
  }

  get processedAt() {
    return this.order.processed_at;
  }

  get totalDiscount() {
    if (this.order) {
      return this.order.total_discounts;
    } else {
      const orderDiscount = this.draftOrder.applied_discount ? parseFloat(this.draftOrder.applied_discount.amount) : 0;
      let lineItemDiscounts = 0;
      this.draftOrder.line_items.forEach(item => {
        if (item.applied_discount) {
          lineItemDiscounts += parseFloat(item.applied_discount.amount);
        }
      });
      return orderDiscount + lineItemDiscounts;
    }
  }

  get hasDiscounts(): boolean {
    if (this.order) {
      return !!this.order.discount_applications.length;
    } else {
      return !!this.draftOrder.applied_discount || this.draftOrder.line_items.some(item => !!item.applied_discount);
    }
  }

  get appliedDiscounts(): string[] {
    const discounts = [];
    if (this.order) {
      this.order.discount_applications.forEach(discount => {
        if (discount.description) {
          discounts.push(this.discountDescription(discount));
        } else if (discount.code) {
          discounts.push(discount.code);
        }
      });
    } else {
      this.draftOrder.line_items.forEach(item => {
        if (item.applied_discount) { discounts.push(this.discountDescription(item.applied_discount)); }
      });
      if (this.draftOrder.applied_discount) { discounts.push(this.discountDescription(this.draftOrder.applied_discount)); }
    }
    return discounts;
  }

  discountDescription(discount): string {
    return discount.title ? `${discount.title} - ${discount.description}` : discount.description;
  }

  get note() {
    return this.order ? this.order.note : this.draftOrder.note;
  }

  displayTitle(item) {
    return item.variant_title ? item.variant_title : item.title;
  }

  priceToInt(item) {
    return parseFloat(item.price);
  }

  onPanelChange(event) {
    this.open = event.nextState;
    this.toggleIcon = event.nextState ? 'icon-chevron-up' : 'icon-chevron-down';
  }

  openCheckout() {
    this.eventId = this.event.event_id;
    // if event is permit in progress open tos agreement modal before sending them to checkout
    if (this.event.event_status === 'permit-in-progress') {
    this.openTosModal();
    } else {
      window.open(this.draftOrder.invoice_url);
    }
  }

  openTosModal() {
    this.tosModalOpen = true;
  }

  closeTosModal() {
    this.tosModalOpen = false;
  }

  handleTosAccept(data: any) {
    // console.log ('data to save', data);
    this.postEventDays();
    // this.tosCheckoutDisabled = false;
  }

  postEventDays() {
    if (this.eventId) { this.eventDayService.addEventDays(this.eventId).subscribe(); }
  }

  handleCompleteCheckout(data?: any) {
    this.closeTosModal();
    this.updateOrCreateDraftOrder();
  }

  updateOrCreateDraftOrder() {
    const lineItems = [];
    // TODO: @Charlie - What other default tags should we assign here?
    const eventIdTag = `event_id-${this.eventId}`;

    const tags = [eventIdTag, MerchantAccountTag.Permitting];

    // const discountAmnt = '';
    // const discount = new ShopifyDiscount({
    //   description: 'Post Event Admin Discount',
    //   value_type: 'fixed_amount',
    //   value: discountAmnt,
    //   amount: discountAmnt,
    //   title: 'Post Event'
    // });
    const discount = null;
    const note = null;

    if (!!this.draftOrder) {
      const draftTags = this.draftOrder.tags ? this.draftOrder.tags.split(', ') : [];
      if (!draftTags.some(tag => tag === eventIdTag)) {
        draftTags.push(eventIdTag);
      }

      this.applyCalendarCode(draftTags).then(discountData => {
        if (discountData) {
          window.open(this.draftOrder.invoice_url);
        }
      });
    } else {
      this.shopifyService.createDraftOrder(lineItems, tags, discount, note).subscribe(resp => {
        if (resp) {
          this.applyCalendarCode().then(discountData => {
            if (discountData) {
              window.open(this.draftOrder.invoice_url);
            }
          });
        }
      });
    }
  }

  applyCalendarCode(tagsArray?: string[]): Promise<any> {
    return new Promise(
      (resolve, reject) => {
        const calendarCode = localStorage.getItem('calendarCode');

        if (calendarCode) {
          // Find permit fee line item & calculate total from price & quantity
          const permitFees = this.draftOrder.line_items.filter(item => item.sku.includes('PERMIT-FEE-'));
          if (permitFees.length) {
            permitFees.forEach(feeItem => {
              if (!feeItem.applied_discount) {
                // Create discount
                const discountAmnt = parseFloat(feeItem.price) * feeItem.quantity;
                const discount = new ShopifyDiscount({
                  description: 'Permit Fee Calendar Code',
                  value_type: 'percentage',
                  value: 100,
                  amount: discountAmnt,
                  title: calendarCode
                });
                // Append discount to line item
                Object.assign(feeItem, { applied_discount: discount });
              }
            });
            // Update draft order
            const draft = this.draftOrder;
            const draftTags = draft.tags ? draft.tags.split(', ') : null;
            const tags = tagsArray ? tagsArray : draftTags;
            this.shopifyService.updateDraftOrder(draft.id, draft.line_items, tags, draft.applied_discount, draft.note).subscribe(resp => {
              if (resp) {
                localStorage.removeItem('calendarCode');
                return resolve(resp);
              } else {
                return reject();
              }
            });
          } else {
            resolve({});
          }
        } else {
          return resolve({});
        }
      }
    );
  }

  openOrder() {
    window.open(this.order.summary_url);
  }

  openModal() {
    this.modalOpen = true;
  }

  closeModal() {
    this.modalOpen = false;
  }

  cancelPayment() {
    this.shopifyService.deleteDraftOrder(this.draftOrder.id).subscribe(resp => {
      if (resp) {
        this.closeModal();
        setTimeout(() => this.deleteDraftOrder.emit(this.draftOrder.id), 100);
      }
    });
  }

}
