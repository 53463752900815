import { Routes } from '@angular/router';
import { AddOnsComponent, EventListingComponent, EventListingReviewComponent, EventOverviewComponent, EventsQueueComponent, InsuredCertificatesComponent, PermitApplicationPart1Component, PermitApplicationPart2Component, PostEventPart1Component, PostEventPart2Component, PostEventPart3Component, EventIncentivesComponent, ThankYouComponent, ReportsComponent, UnauthorizedComponent } from './ecosystems';
import { AuthGuardService } from './shared';
import { EventPaymentGuard } from './guard/event-payment.guard';
var ɵ0 = { title: 'Event Listing' }, ɵ1 = { title: 'Event Overview' }, ɵ2 = { title: 'Edit Event Listing' }, ɵ3 = { title: 'Event Review' }, ɵ4 = { title: 'Event Queue' }, ɵ5 = { title: 'Event Permit App - Part 1' }, ɵ6 = { title: 'Event Permit App - Part 2' }, ɵ7 = { title: 'Event Calendar Labels' }, ɵ8 = { title: 'Event Insured Certs' }, ɵ9 = { title: 'Event Add Ons' }, ɵ10 = { title: 'Event Post Event - Part 1' }, ɵ11 = { title: 'Event Post Event - Part 3' }, ɵ12 = { title: 'Event Post Event - Part 2' }, ɵ13 = { title: 'EventDate Post Event - Part 1' }, ɵ14 = { title: 'EventDate Post Event - Part 3' }, ɵ15 = { title: 'EventDate Post Event - Part 2' }, ɵ16 = { title: 'Reports' }, ɵ17 = { title: 'Thank You' }, ɵ18 = { title: 'Unauthorized' };
var routes = [
    {
        path: '',
        redirectTo: '/event/listing',
        pathMatch: 'full'
    },
    // Create new listing
    {
        path: 'event/listing',
        data: ɵ0,
        component: EventListingComponent,
        canActivate: [AuthGuardService, EventPaymentGuard]
    },
    // View listing
    {
        path: 'event/overview/:eventId',
        redirectTo: 'event/:eventId/overview'
    },
    {
        path: 'event/:eventId',
        redirectTo: 'event/:eventId/overview'
    },
    {
        path: 'event/:eventId/overview',
        data: ɵ1,
        component: EventOverviewComponent,
        canActivate: [AuthGuardService]
    },
    // Edit exisiting listing
    {
        path: 'event/:eventId/listing',
        data: ɵ2,
        component: EventListingComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'event/:eventId/review',
        data: ɵ3,
        component: EventListingReviewComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'queue',
        data: ɵ4,
        component: EventsQueueComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'event/:eventId/permit-application/part-1',
        data: ɵ5,
        component: PermitApplicationPart1Component,
        canActivate: [AuthGuardService]
    },
    {
        path: 'event/:eventId/permit-application/part-2',
        data: ɵ6,
        component: PermitApplicationPart2Component,
        canActivate: [AuthGuardService]
    },
    {
        path: 'event/:eventId/event-incentives',
        data: ɵ7,
        component: EventIncentivesComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'event/:eventId/insured-certificates',
        data: ɵ8,
        component: InsuredCertificatesComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'event/:eventId/add-ons',
        data: ɵ9,
        component: AddOnsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'event/:eventId/post-event/part-1',
        data: ɵ10,
        component: PostEventPart1Component,
        canActivate: [AuthGuardService]
    },
    {
        path: 'event/:eventId/post-event/part-3',
        data: ɵ11,
        component: PostEventPart2Component,
        canActivate: [AuthGuardService]
    },
    {
        path: 'event/:eventId/post-event/part-2',
        data: ɵ12,
        component: PostEventPart3Component,
        canActivate: [AuthGuardService]
    },
    {
        path: 'event/:eventId/date/:dateId/post-event/part-1',
        data: ɵ13,
        component: PostEventPart1Component,
        canActivate: [AuthGuardService]
    },
    {
        path: 'event/:eventId/date/:dateId/post-event/part-3',
        data: ɵ14,
        component: PostEventPart2Component,
        canActivate: [AuthGuardService]
    },
    {
        path: 'event/:eventId/date/:dateId/post-event/part-2',
        data: ɵ15,
        component: PostEventPart3Component,
        canActivate: [AuthGuardService]
    },
    {
        path: 'reports',
        data: ɵ16,
        component: ReportsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'thank-you',
        data: ɵ17,
        pathMatch: 'full',
        component: ThankYouComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'unauthorized',
        data: ɵ18,
        pathMatch: 'full',
        component: UnauthorizedComponent
    },
    // TODO: Consider adding 404 page
    { path: '**', redirectTo: '/queue' },
    // Backwards compatibility redirects for obsolete routes
    { path: 'event/queue', redirectTo: '/queue' },
    { path: 'event/review', redirectTo: '/queue' },
    { path: 'permitapplication/part1', redirectTo: '/queue' },
    { path: 'permitapplication/part2', redirectTo: '/queue' },
    { path: 'event/insuredcertificates', redirectTo: '/queue' },
    { path: 'event/add-ons', redirectTo: '/queue' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18 };
