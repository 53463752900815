import { OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService, EventListingService, ProfileService } from '../../services';
var EventListingReviewComponent = /** @class */ (function () {
    function EventListingReviewComponent(eventListing, event, profile, router, route) {
        this.eventListing = eventListing;
        this.event = event;
        this.profile = profile;
        this.router = router;
        this.route = route;
        this.currentStep = 0;
        this.submitModalOpen = false;
        this.permitModalOpen = false;
        this.disableModalBtns = false;
        this.disableListingPermitBtns = false;
        this.denyRequest = false;
        this.validRdLicense = false;
        this.licenseNeeded = false;
        this.flushListingOnDestroy = false;
    }
    Object.defineProperty(EventListingReviewComponent.prototype, "dataLoaded", {
        get: function () { return this.eventListing.datesLoaded && this.eventListing.organizerLoaded; },
        enumerable: true,
        configurable: true
    });
    EventListingReviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.eventId = this.route.snapshot.paramMap.get('eventId');
        this.eventListing.fetchEventData(this.eventId);
        if (this.eventListing.event_organizer) {
            this.profile.getProfile(this.eventListing.event_organizer).subscribe(function (profile) {
                if (profile) {
                    _this.checkRdLicense(profile);
                }
            });
        }
    };
    // Check user's profile for unexpired race director license
    EventListingReviewComponent.prototype.checkRdLicense = function (profile) {
        this.validRdLicense = profile.licenses && profile.licenses.some(function (license) {
            return license.license_type === 20 && new Date(license.license_expiration_date) > new Date();
        });
        // TODO: @Brennan - As an improvement, can we also check the event organizer's other open draft orders for the license variant?
        this.licenseNeeded =
            (!this.eventListing.event_sponsoring_clubs.length
                && !this.validRdLicense
                && this.eventListing.isEventCompetitive);
        console.log('RD license needed? ', this.licenseNeeded);
    };
    EventListingReviewComponent.prototype.toggleModal1 = function (event) {
        this.submitModalOpen = !this.submitModalOpen;
    };
    EventListingReviewComponent.prototype.openSubmitModal = function (event) {
        this.listOrPermit();
        if (this.eventListing.isVirtual) {
            this.exitToQueue(event);
        }
        else {
            this.submitModalOpen = true;
        }
    };
    EventListingReviewComponent.prototype.closeSubmitModal = function (event) {
        this.submitModalOpen = false;
    };
    EventListingReviewComponent.prototype.displayActions = function () {
        return this.eventListing.mode === 'create' || this.eventListing.event_status === 'listing-in-progress';
    };
    EventListingReviewComponent.prototype.exitToQueue = function (event) {
        this.closeSubmitModal(event);
        this.router.navigate(['/queue']);
    };
    EventListingReviewComponent.prototype.openPermitModal = function (event) {
        if (this.licenseNeeded) {
            this.listOrPermit();
            this.permitModalOpen = true;
        }
        else {
            this.listOrPermit(true);
        }
    };
    EventListingReviewComponent.prototype.closePermitModal = function () {
        this.permitModalOpen = false;
    };
    EventListingReviewComponent.prototype.permitModalContinueText = function () {
        return this.licenseNeeded ? 'Add License and Continue to Permit' : 'Continue to Permit';
    };
    EventListingReviewComponent.prototype.listOrPermit = function (goToPermit) {
        var _this = this;
        if (goToPermit === void 0) { goToPermit = false; }
        this.disableListingPermitBtns = true;
        this.disableModalBtns = false;
        this.event.updateEventStatus(this.eventId, 'listing-in-review').subscribe(function (resp) {
            if (resp.message) {
                if (goToPermit) {
                    _this.toPermit();
                }
            }
            else {
                // TODO: Consider more explicit error handling
                _this.disableListingPermitBtns = false;
            }
        });
    };
    EventListingReviewComponent.prototype.toListing = function () {
        // Force listing reload before editing
        window.location.assign("/event/" + this.eventId + "/listing");
    };
    EventListingReviewComponent.prototype.toPermit = function () {
        var _this = this;
        this.submitModalOpen = false;
        this.permitModalOpen = false;
        this.flushListingOnDestroy = true;
        setTimeout(function () {
            _this.router.navigate(["/event/" + _this.eventId + "/permit-application/part-1"]);
        }, 200);
    };
    /* commenting this feature for now, we may reuse this in future
    postChanges() {
  
    }
  
    denyChangeRequest() {
      this.denyRequest = !this.denyRequest;
    }
  
    sendReason() {
  
    }
  
    approveChanges() {
    } */
    EventListingReviewComponent.prototype.ngOnDestroy = function () {
        if (this.eventListing.mode === 'update' && this.flushListingOnDestroy) {
            this.eventListing.flush();
        }
    };
    return EventListingReviewComponent;
}());
export { EventListingReviewComponent };
