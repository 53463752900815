<form [formGroup]="courseMapForm" class="container-fluid">
  <div class="border p-3">
    <div class="row">
      <div class="col">
          <h5 *ngIf="title" >Day {{index+1}} - Stage {{index+1}}</h5>
          <app-radio-switch
            formControlName="courseMapDisplay"
            [name]="'Course Map Display'"
            [label]= "''"
            [options]="courseMapDisplayOptions"
            [formSubmitted]="submitted"
            >
          </app-radio-switch>
          <div *ngIf="urlForm">
            <app-text-input
              formControlName="courseMapUrl"
              [type]="'text'"
              [name]="'courseMapUrl'"
              [label]="'Course Map URL'"
              [placeholder]="''"
              [formSubmitted]="submitted"
              ></app-text-input>
          </div>
          <div *ngIf="uploadForm">
            <p>Upload Course Map <span class="icon icon-information" [ngbTooltip]="tipContent"><ng-template #tipContent>Please provide a link or PDF file of your course map. This will allow riders to access this information through the USA Cycling Event Calendar, as well as help with approval during the permit process.</ng-template></span></p>
            <app-alert [alerts]="courseAlert"></app-alert>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Upload</span>
                </div>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="inputGroupFile01"  (change)="selectCoursemap($event)">
                  <label class="custom-file-label" for="inputGroupFile01">{{courseMapLabel}}</label>
                </div>
            </div>
          </div>
          <!-- commented out for MBR-2992 -->
          <!-- <app-multi-select class="checkbox-multiselect"
            formControlName="courseSubDisciplines"
            [label]="'Select Subdiscipline'"
            [placeholder]="'Select...'"
            [options]="courseMap.getAvailaleSubDisciplines(eventListing.getSelectedSubDisciplines())"
            [name]="'multi-select-example'"
            [formSubmitted]="submitted">
          </app-multi-select> -->
      </div>
      <div class="col-sm-3" *ngIf = "permit.courseMaps[index].length > 1">
          <span class="float-right cursor-pointer" (click)="permit.removeSubCourseMap(index,courseMapId)"><i class = "icon icon-delete"></i></span>
      </div>
    </div>
  </div>
</form>
