import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Profile, PermissionSet, Driver, DriverLicense, Vehicle } from '../classes';
import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root'
})
// TODO: Merge with existing ProfileService & rename
export class DriverVehicleService extends BaseService {

  public currentUser: Profile;
  public permissions: PermissionSet;


  getDrivers(permitId: number|string): Observable<DriverLicense[]> {
    return this.http.get<DriverLicense[]>(this.url(`permit/${permitId}/addons/drivers`), this.options)
      .pipe(
        tap(_ => this.log('fetched drivers')),
        catchError(this.handleError<DriverLicense[]>('getDrivers', [], { permitId }))
      );
  }

  addDriver(permitId: number|string, driver: DriverLicense): Observable<DriverLicense> {
    return this.http.post(this.url(`permit/${permitId}/addons/drivers`), driver, this.options)
      .pipe(
        tap((newDriver: DriverLicense) => this.log(`added driver id: ${newDriver.id}`)),
        catchError(this.handleError<DriverLicense>('addDriver', new DriverLicense({}), { permitId, driver }))
      );
  }

  deleteDriver(permitId: number|string, driverId: number|string): Observable<any> {
    return this.http.delete(this.url(`permit/${permitId}/addons/drivers/${driverId}`), this.options)
      .pipe(
        tap(_ => this.log('deleted driver')),
        catchError(this.handleError<any>('deleteDriver', null, { permitId, driverId }))
      );
  }

  searchDrivers(compId: number|string, name: string): Observable<any> {
    const params = [];
    if (compId && compId !== '') { params.push(`comp_id=${compId}`); }
    if (name && name !== '') { params.push(`name=${name}`); }
    const queryParams = '?' + params.join('&');
    return this.http.get(this.url('/license/searchdrivers' + queryParams), this.options)
      .pipe(
        tap(_ => this.log('fetched driver(s)')),
        catchError(this.handleError<any>('searchDrivers', [], { compId, name }))
      );
  }

  getVehicles(permitId: number|string): Observable<Vehicle[]> {
    return this.http.get<Vehicle[]>(this.url(`permit/${permitId}/addons/vehicles`), this.options)
      .pipe(
        tap(_ => this.log('fetched vehicles')),
        catchError(this.handleError<Vehicle[]>('getVehicles', [], { permitId }))
      );
  }

  addVehicle(permitId: number|string, vehicle: Vehicle): Observable<Vehicle> {
    return this.http.post(this.url(`permit/${permitId}/addons/vehicles`), vehicle, this.options)
      .pipe(
        tap((newVehicle: Vehicle) => this.log(`added vehicle id: ${newVehicle.id}`)),
        catchError(this.handleError<Vehicle>('addVehicle', new Vehicle({}), { permitId, vehicle }))
      );
  }

  deleteVehicle(permitId: number|string, vehicleId: number|string): Observable<any> {
    return this.http.delete(this.url(`permit/${permitId}/addons/vehicles/${vehicleId}`), this.options)
      .pipe(
        tap(_ => this.log('deleted vehicle')),
        catchError(this.handleError<any>('deleteVehicle', null, { permitId, vehicleId }))
      );
  }

  submitDriversAndVehicles(permitId: number|string): Observable<any> {
    return this.http.post(this.url(`permit/${permitId}/addons/driver_vehicle_submit`), {}, this.options)
      .pipe(
        tap(_ => this.log('submitted completed drivers & vehicle information')),
        catchError(this.handleError<any>('addDriver', null, { permitId }))
      );
  }

}
