<app-modal [id]="'tos-modal'" [title]="'Accept Terms and Conditions'" [isOpen]="tosModalOpen" (close)="onClose($event)">
  <app-modal-body>
    <div id="tos-content" (scroll)="onScroll($event)">
      <h4>USA Cycling Permit Agreement</h4>
      <p>
        The undersigned, as the Race Director/Event Promoter and as agent of the Sponsoring Clubs and Race Director/Event Promotor (collectively “Organizers”), having completed a USA Cycling Permit Application, hereby agrees:
      </p>
        <ol>
          <li><strong>Event Administration.</strong> Organizers shall follow and ensure that the Event complies with all USAC and/or USAC’s Local Association rules including USAC’s competitive rules, regulations, policies, decisions, and procedures. Organizers shall collect, as trustee on behalf of USAC, all required membership fees, one-day membership fees, release forms, and race results, and shall deliver such items to USAC within the time period set forth in the Permit Application.</li>
          <li>
            <strong>Representations and Warranties of Organizer.</strong> Organizer represents and warrants that (a) Organizer has the full right, power, and authority to enter into this Permit Agreement and to perform its obligations hereunder, (b) the execution of this Permit Agreement by the individual whose signature is set forth at the end of this Permit Agreement has been duly authorized by necessary action on the part of Organizer, (c) the information provided in the Permit Application is true and correct to the best of Organizer’s knowledge, (d) Organizer has, or will have by the date of the Event, obtained all licenses, authorizations, approvals, consents, or permits required by applicable laws (including the rules and regulations of all authorities having jurisdiction over public health matters) (collectively “Authorization”) to conduct the Event.
            <br /><br />
            <strong>Revocation of Event Permit.</strong> In USAC’s sole discretion, USAC may revoke an Event Permit issued to Organizer at any time and for any reason.  Furthermore, if any Authorization to conduct the Event is revoked before the Event date, the USAC Event Permit, and all associated insurance, shall be deemed revoked unless such Authorization is reinstated before the date of the Event.
          </li>
          <li><strong>Permit Packet.</strong> Organizers acknowledge that they have received and read all materials in the Permit Packet.</li>
          <li><strong>Safety Precautions.</strong> Organizers acknowledge that they have received and reviewed USAC’s Event and Medical Plan Checklists. It is expressly understood and acknowledged by Organizers that the Event and Medical Plan Checklists are intended to assist Organizers in the orderly administration of the Event and are not intended to guarantee the safety of participants. Organizers acknowledge that safety considerations may or may not require that additional precautions or measures be taken and Organizers agree to take such measures to promote and conduct the safest event possible.</li>
          <li><strong>Participant Liability Waivers.</strong> Organizer shall require that all participants and volunteers sign the USAC Assumption of Risk, Release of Liability, Covenant Not to Sue and Indemnity Agreement (“Waiver”). A valid Waiver is critically important to the Organizers, volunteers, USAC Officials and USAC. Organizers shall maintain all paper Waivers in a secure location for at least ten years.</li>
          <li><strong>Legal Claims and Event Records.</strong> Organizers shall cooperate in good faith with USAC and its agents in the event of any personal injury claims or other legal actions arising out of the Event.</li>
          <li><strong>No Warranties or Agency.</strong> USAC makes no warranties, express or implied, to the Organizers, or other event organizers, sponsors, participants, volunteers, spectators, or any other person or entity related in any way to the Event. Under no circumstances shall the Organizers act as the agent of USAC. There is no intent to create an agency, partnership, or joint venture relationship between USAC and the Organizers of the Event.</li>
          <li><strong>Drug Testing.</strong> Organizers understand that the Event may be selected for drug testing by USAC, USADA or the UCI. Organizers shall cooperate with USADA and/or the UCI and their agents with regard to any and all anti-doping activities.</li>
          <li><strong>USAC Name, Logo and Trademarks.</strong> Organizers shall be permitted to use USAC’s trademarks (name and logo), for the limited purpose of advertising and promoting the Event. Any use of USAC’s trademarks shall cease immediately after the Event.</li>
          <li><strong>Permit Transfer.</strong> Organizers do not have the right to transfer the Event permit to any other person or entity.</li>
          <li><strong>Sales and Use Tax.</strong> Organizers agree to collect and remit all sales and/or use taxes arising from taxable transactions, including but not limited to taxable transactions processed through the USAC online event registration program.</li>
          <li><strong>Indemnification.</strong> Organizers agree to defend, to hold harmless and to indemnify USAC and its agents, officials and Local Associations against any and all losses, costs, claims, legal fees and liabilities which are connected with or arise directly or indirectly out of (a) the preparation for or conduct of the Event or (b) breach of this any provision of this agreement.</li>
          <li><strong>Refunds.</strong> By signing this form, Organizers agree that Organizers are responsible for all rider surcharges and event fees due to USAC and that no refunds will be issued.</li>
        </ol>
    </div>
    <p *ngIf="checkboxDisabled" class="color-orange">
      *Please read entire terms and conditions before accepting
    </p>
    <form [formGroup]="tosAcceptForm" (ngSubmit)="onSubmit()">
      <div class="row space-above-lg">
        <div class="col-sm-6">
          <app-text-input formControlName="firstName"
            [type]="'text'"
            [name]="'firstName'"
            [label]="'First Name'"
            [placeholder]=""
            [formSubmitted]="submitted"
            [errors] = "f.firstName.errors"
            [disabled]="signNameDisabled || hasAgreed"
            [required]=true></app-text-input>
        </div>
        <div class="col-sm-6">
          <app-text-input formControlName="lastName"
            [type]="'text'"
            [name]="'lastName'"
            [label]="'Last Name'"
            [placeholder]=""
            [formSubmitted]="submitted"
            [errors] = "f.lastName.errors"
            [disabled]="signNameDisabled || hasAgreed"
            [required]=true></app-text-input>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <app-checkbox
            class="col-12"
            formControlName="acceptTos"
            [id]="'acceptTos'"
            [name]="'acceptTos'"
            [label]="'I have read and understood the Terms and Conditions'"
            [toggle]="false"
            [formSubmitted]="submitted"
            [errors] = "f.acceptTos.errors"
            [required]=true
            [disabled]="checkboxDisabled || hasAgreed"
            (change)="onCheckboxChange($event)"
          ></app-checkbox>
        </div>
      </div>
      <p *ngIf="isMobile" class="small mt-2-neg">Upon acceptance, you may download the Terms and Conditions agreement document using the link below.</p>
      <app-button *ngIf="isMobile" [type]="'btn-secondary btn-sm'" (click)="downloadTos()" [disabled]="!pdfLink">Download Agreement</app-button>
    </form>
  </app-modal-body>
  <app-modal-footer>
    <app-button [type]="'btn-primary'" (click)="onCompleteCheckout($event)" [disabled]="checkoutDisabled">
      Complete Order
      <span *ngIf="submitting" class="spinner-border spinner-border-sm ml-1"></span>
    </app-button>
  </app-modal-footer>
</app-modal>
