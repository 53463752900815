import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { BaseService } from './base-service';
import { ProfileService } from './profile.service';
import { Notification } from '../classes';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
import * as i3 from "./profile.service";
var NotificationService = /** @class */ (function (_super) {
    tslib_1.__extends(NotificationService, _super);
    function NotificationService(http, cookie, profileService) {
        var _this = _super.call(this, http, cookie) || this;
        _this.http = http;
        _this.cookie = cookie;
        _this.profileService = profileService;
        return _this;
    }
    NotificationService.prototype.getNotificationsForEvent = function (eventId) {
        var _this = this;
        return this.http.get(this.url("notification?event_id=" + eventId), this.options)
            .pipe(tap(function (_) { return _this.log('fetched notifications'); }), catchError(this.handleError('getNotificationsForEvent', [], { eventId: eventId })));
    };
    NotificationService.prototype.readNotification = function (type, id) {
        var _this = this;
        return this.http.get(this.url("notification/setRead?read_type=" + type + "&read_id=" + id), this.options)
            .pipe(tap(function () { return _this.log("marked notification id: " + id + " as read"); }), catchError(this.handleError('readNotification', null, { type: type, id: id })));
    };
    NotificationService.prototype.sortNotifications = function (data, eventId) {
        var notifications = [];
        var _loop_1 = function (key) {
            if (Array.isArray(data[key])) {
                data[key].forEach(function (entry) {
                    if (key === 'comments') {
                        notifications.push(new Notification(entry.comment_id, entry.created_at, entry.profile_first_name + " " + entry.profile_last_name, 'comment', entry.comment_message, 'comments', entry.read));
                    }
                    else if (key === 'payments' && !entry.payment_cancelled) {
                        var isUnpaidCheckout = entry.order_status_url === null;
                        var isUnpaidDraftOrder = !entry.order_id;
                        var paidStatus = (isUnpaidCheckout || isUnpaidDraftOrder) ? 'unpaid' : 'paid';
                        // TODO: Remove conditional & set only to profile name once API updated
                        var user = entry.profile_full_name ? entry.profile_full_name : entry.profile_id;
                        notifications.push(new Notification(entry.id, entry.sp_updated_at, user, 'payment', "$" + entry.total_price, 'payments', entry.read, paidStatus));
                    }
                    // TODO: Include logic for orders & change requests, once systems in place
                    // else if (key === 'event_change_requests') {
                    //   notifications.push(
                    //     new Notification(
                    //       entry.cr_id,
                    //       entry.cr_date,
                    //       entry.profile_id,
                    //       'change-request',
                    //       entry.cr_reason,
                    //       '/event/overview#change-requests',
                    //       entry.read
                    //     )
                    //   );
                    // }
                    // else if (key === 'permit_change_requests') {
                    //   notifications.push(
                    //     new Notification(
                    //       entry.cr_id,
                    //       entry.cr_date,
                    //       entry.profile_id,
                    //       'change-request',
                    //       entry.cr_reason,
                    //       '/event/overview#change-requests',
                    //       entry.read
                    //     )
                    //   );
                    // }
                });
            }
        };
        for (var _i = 0, _a = Object.keys(data); _i < _a.length; _i++) {
            var key = _a[_i];
            _loop_1(key);
        }
        console.log(notifications);
        return notifications.reverse();
    };
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService), i0.ɵɵinject(i3.ProfileService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}(BaseService));
export { NotificationService };
