export class InsuranceCertificate {
    public name: string;
    public relationship: string;
    public customRelationship: string;
    public street: string;
    public unit: string;
    public city: string;
    public state: string;
    public zip: string;
    public email: string;
    public phone: string;
    public specificVerbiage: string;
    public isIncluded: boolean;
    public hasEndorsement: boolean;
    public endorsement: any[];
    public price = 15;
    public addToCart = false;
    public type: string;
    public isEdit: boolean;
    public reffIndex = 0;
    public clubId: string|number;
    public certificateId: number;
    constructor() {}

    streetAndUnit() {
        return this.unit ? `${this.street} ${this.unit}` : this.street;
    }
}
