import { OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationStart } from '@angular/router';
import { PermitService, EventListingService } from 'src/app/services';
import { RouteHistoryService } from 'src/app/shared';
import { CheckboxOption, RadioOption } from '../../classes';
import { CookieService } from 'ngx-cookie-service';
var PermitPart1FormComponent = /** @class */ (function () {
    function PermitPart1FormComponent(formBuilder, router, routeHistory, cookie, permit, eventListing) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.routeHistory = routeHistory;
        this.cookie = cookie;
        this.permit = permit;
        this.eventListing = eventListing;
        this.submitted = false;
        this.contactName = '';
        this.contactEmail = '';
        this.medicalFacilitiesOptions = [];
        this.timingInfoOptions = [];
        this.communicationTypesOptions = [];
        this.timingOptions = [];
        this.stagingAreaOptions = [];
        // payOutOptions: RadioOption[] = [];
        this.submitModalOpen = false;
        this.showSaveExit = false;
        this.navUrl = '';
        this.disableSaveAndExitCatch = false;
    }
    Object.defineProperty(PermitPart1FormComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.permitPart1Form.controls; },
        enumerable: true,
        configurable: true
    });
    PermitPart1FormComponent.prototype.ngOnInit = function () {
        var _this = this;
        // // this.eventListing.fetchEventData(this.eventId);
        // this.permit.getQuestionConfig(this.eventListing.event_discipline);
        this.permitPart1Form = this.formBuilder.group({
            // comment this code for legal issues
            // contactName: [ '', nameCharactersValidator() ],
            // contactEmail: [ '', emailValidator() ],
            // contactPhone: [ '', phoneNumberValidator()  ],
            // medicalFacilities: [ '' ],
            // medicalStaff: this.formBuilder.array([new FormControl()]),
            communicationTypes: ['', Validators.required],
            registrationProvider: ['', Validators.required],
            timingProvider: ['', Validators.required],
            timingInformation: ['', Validators.required],
            timings: [''],
            stagingArea: ['', Validators.required],
            // payOut: ['', Validators.required],
            maxPayOut: ['', Validators.required]
        });
        // tslint:disable-next-line: forin
        for (var option in this.permit.timingOptionsList) {
            this.timingOptions.push(new CheckboxOption(option, option, option, this.permit.timingOptionsList[option]));
        }
        // tslint:disable-next-line: forin
        // for (const option in this.permit.medicalFacilitiesOptionsList) {
        //   this.medicalFacilitiesOptions.push(new CheckboxOption(option, option, option, this.permit.medicalFacilitiesOptionsList[option]));
        //   // console.log(`${option}: ${medicalFacilitiesOptionsList[option]}`);
        // }
        // commented out for MBR-2992
        // this.subDisciplineOptions = this.eventListing.getSelectedSubDisciplines();
        // tslint:disable-next-line: forin
        for (var option in this.permit.communicationTypesOptionsList) {
            this.communicationTypesOptions.push(new CheckboxOption(option, option, option, this.permit.communicationTypesOptionsList[option]));
        }
        this.timingInfoOptions = [
            new RadioOption('timing-yes', 'yes', 'Yes'),
            new RadioOption('timing-no', 'no', 'No')
        ];
        this.stagingAreaOptions = [
            new RadioOption('staging-yes', 'yes', 'Yes'),
            new RadioOption('staging-no', 'no', 'No')
        ];
        // this.payOutOptions = [
        //   new RadioOption('payout-yes', 'yes', 'Yes'),
        //   new RadioOption('payout-no', 'no', 'No')
        // ];
        // this.f.contactName.setValue(this.permit.contactName);
        // this.f.contactEmail.setValue(this.permit.contactEmail);
        // this.f.contactPhone.setValue(this.permit.contactPhone);
        // this.f.medicalFacilities.setValue(this.permit.medicalFacilities);
        this.f.timingInformation.setValue(this.permit.timingInformation);
        this.f.timingProvider.setValue(this.permit.timingProvider);
        this.f.registrationProvider.setValue(this.permit.registrationProvider);
        this.f.communicationTypes.setValue(this.permit.communicationTypes);
        this.f.stagingArea.setValue(this.permit.stageArea);
        // this.f.payOut.setValue(this.permit.payOut);
        this.f.maxPayOut.setValue(this.permit.maxPayOut);
        this.f.timings.setValue(this.permit.timings);
        // if (this.permit.medicalStaffName.length > 0) {
        //   this.permitPart1Form.setControl('medicalStaff', this.formBuilder.array(this.permit.medicalStaffName));
        // }
        this.permitPart1Form.valueChanges.subscribe(function (data) {
            // this.permit.contactName = data.contactName;
            // this.permit.contactEmail = data.contactEmail;
            // this.permit.contactPhone = data.contactPhone;
            // this.permit.medicalFacilities = data.medicalFacilities;
            _this.permit.timingProvider = data.timingProvider;
            _this.permit.registrationProvider = data.registrationProvider;
            _this.permit.maxPayOut = data.maxPayOut;
        });
        // this.permitPart1Form.controls.medicalStaff.valueChanges.subscribe(data => {
        //   this.permit.medicalStaffName = data;
        // });
        this.routerEvents = this.router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                var routeExceptions = ["/event/" + _this.eventId + "/permit-application/part-1", "/event/" + _this.eventId + "/permit-application/part-2"];
                if (!routeExceptions.includes(event.url) && _this.navUrl === '') {
                    if (!_this.disableSaveAndExitCatch) {
                        _this.router.navigateByUrl(_this.router.url, { replaceUrl: true });
                        _this.openSaveExitModal(event.url);
                    }
                }
            }
        });
        // console.log(this.permitPart1Form.value);
        this.setDisciplineValidators();
    };
    PermitPart1FormComponent.prototype.ngOnDestroy = function () {
        this.routerEvents.unsubscribe();
        this.closeSaveExitModal(false);
    };
    PermitPart1FormComponent.prototype.debug = function (event) {
        console.log('debug form', this.f, event);
    };
    PermitPart1FormComponent.prototype.submitPermit = function (navUrl) {
        this.permit.createPermit(this.eventId, 'part1', navUrl);
    };
    PermitPart1FormComponent.prototype.onSubmit = function (event) {
        this.submitted = true;
        // console.log('submitting part 1', event, this.permitPart1Form);
        if (this.permitPart1Form.invalid) {
            console.log('medical info form is invalid!');
            return;
        }
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.submitPermit();
    };
    // addStaffName() {
    //     this.medicalStaff = this.permitPart1Form.get('medicalStaff') as FormArray;
    //     this.medicalStaff.push(new FormControl());
    // }
    // removeStaffName(Index: number) {
    // (this.permitPart1Form.get('medicalStaff') as FormArray).removeAt(Index);
    // }
    // updateFacilities($event: Array<string>) {
    //   this.permit.medicalFacilities = $event;
    // }
    // get Controls() {
    //   return (this.permitPart1Form.get('medicalStaff') as FormArray).controls;
    // }
    PermitPart1FormComponent.prototype.updateCommunicationTypes = function ($event) {
        this.permit.communicationTypes = $event;
    };
    PermitPart1FormComponent.prototype.updateTimingInfo = function ($event) {
        this.permit.timingInformation = $event;
    };
    // updatePayOutInfo($event: string) {
    //   this.permit.payOut = $event;
    // }
    PermitPart1FormComponent.prototype.unrequireTiming = function () {
        return (!this.eventListing.isEventCompetitive || this.eventListing.isBmx || !this.eventListing.isGranFondo);
    };
    PermitPart1FormComponent.prototype.competitiveNotBmx = function () {
        return (this.eventListing.isEventCompetitive && !this.eventListing.isBmx);
    };
    PermitPart1FormComponent.prototype.requireStagingArea = function () {
        // TODO: Replace cd_id values with discipline names (once retrieving from the API instead of listing service).
        // Only for 'Grand Fondo' & 'Gravel' disciplines
        return (this.eventListing.event_discipline === '24' || this.eventListing.event_discipline === '25');
    };
    PermitPart1FormComponent.prototype.updateStagingArea = function ($event) {
        this.permit.stageArea = $event;
    };
    PermitPart1FormComponent.prototype.updateTimings = function ($event) {
        this.permit.timings = $event;
    };
    PermitPart1FormComponent.prototype.openSaveExitModal = function (url) {
        if (url) {
            this.navUrl = url;
        }
        this.showSaveExit = true;
    };
    PermitPart1FormComponent.prototype.closeSaveExitModal = function (resetNavUrl) {
        this.showSaveExit = false;
        if (!!resetNavUrl) {
            this.navUrl = '';
        }
    };
    PermitPart1FormComponent.prototype.toggleModal1 = function (event) {
        this.submitModalOpen = !this.submitModalOpen;
    };
    PermitPart1FormComponent.prototype.closeSubmitModal = function (event) {
        this.submitModalOpen = false;
    };
    PermitPart1FormComponent.prototype.continueNavigation = function (url) {
        var _this = this;
        if (url === void 0) { url = this.navUrl; }
        this.closeSaveExitModal(false);
        setTimeout(function () {
            _this.navUrl = url === '' ? "/event/" + _this.eventId + "/permit-application/part-2" : _this.navUrl;
            _this.router.navigate([_this.navUrl]);
        }, 50);
    };
    PermitPart1FormComponent.prototype.toOverview = function () {
        var previous = this.routeHistory.previousUrl;
        var url = "event/" + this.eventId + "/overview";
        previous === '/' ? window.location.replace(url) : this.router.navigate([url]);
    };
    PermitPart1FormComponent.prototype.backNavText = function () {
        return this.cookie.check('event_id') ? 'Back to Event Overview' : 'Back to Event Queue';
    };
    PermitPart1FormComponent.prototype.postChanges = function () {
        // post changes to API call
        this.submitModalOpen = false;
    };
    PermitPart1FormComponent.prototype.setDisciplineValidators = function () {
        var timingInfo = this.permitPart1Form.get('timingInformation');
        var provider = this.permitPart1Form.get('timingProvider');
        var regprovider = this.permitPart1Form.get('registrationProvider');
        var area = this.permitPart1Form.get('stagingArea');
        // const payOut = this.permitPart1Form.get('payOut');
        var maxPayOut = this.permitPart1Form.get('maxPayOut');
        if (this.unrequireTiming()) {
            timingInfo.setValidators(null);
            provider.setValidators(null);
        }
        this.permitPart1Form.get('timingInformation').valueChanges.subscribe(function (info) {
            if (info === 'no') {
                provider.setValidators(null);
            }
            else if (info === 'yes') {
                provider.setValidators([Validators.required]);
            }
            provider.updateValueAndValidity();
        });
        if (!this.competitiveNotBmx()) {
            regprovider.setValidators(null);
            // payOut.setValidators(null);
            maxPayOut.setValidators(null);
        }
        if (!this.requireStagingArea()) {
            area.setValidators(null);
        }
        timingInfo.updateValueAndValidity();
        provider.updateValueAndValidity();
        regprovider.updateValueAndValidity();
        area.updateValueAndValidity();
    };
    PermitPart1FormComponent.prototype.saveAndExit = function () {
        var _this = this;
        this.navUrl = this.navUrl === '' ? '/queue' : this.navUrl;
        setTimeout(function () {
            _this.disableSaveAndExitCatch = true;
            _this.submitPermit(_this.navUrl);
            _this.closeSaveExitModal(false);
        }, 50);
    };
    PermitPart1FormComponent.prototype.numberOnly = function (event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
            return false;
        }
        return true;
    };
    return PermitPart1FormComponent;
}());
export { PermitPart1FormComponent };
