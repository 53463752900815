<h4>Comments</h4>

<!-- Loader -->
<app-loader *ngIf="loading || !userRole" [small]="true" [align]="'left'"></app-loader>

<div *ngIf="!loading && userRole">
  <div *ngIf="comments.length" class="card light border-0 mt-3">
    <div class="card-body mt-3">
      <div *ngFor="let comment of comments.reverse()" class="mb-4">
        <div class="mb-1">
          <span class="mr-4"><strong>{{comment.commenter}}</strong></span>
          <span class="comment-date">{{comment.date | date: "MMMM d, y, h:mm:ss a"}}</span>
        </div>
        {{comment.message}}
      </div>
    </div>
  </div>
  <div class="card light border-0 mt-3">
    <div class="card-body">
      <form [formGroup]="commentForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <app-text-input
            [ngClass]="{'col-lg-7': showRecipients, 'col-lg-10': !showRecipients}"
            [formControlName]="'message'"
            [name]="'message'"
            [label]="'New Comment'"
            [placeholder]="'Add a comment...'"
            [formSubmitted]="submitted"
            [errors]="f.message.errors"
            [required]=true
          ></app-text-input>
          <app-select *ngIf="showRecipients"
            class="col-sm-6 col-lg-3"
            [formControlName]="'scope'"
            [name]="'scope'"
            [label]="'Visible By'"
            [options]="recipientOptions"
            [formSubmitted]="submitted"
          >
          <app-text-input *ngIf="!showRecipients"
            class="hidden"
            [formControlName]="'scope'"
            [name]="'scope'"
          ></app-text-input>
          </app-select>
          <app-button
            class="col-5 col-sm-3 col-md-2"
            [ngClass]="{'btn-inline-lg': !showRecipients, 'btn-inline-sm': showRecipients}"
            [type]="'btn-secondary btn-block'"
          >Post</app-button>
        </div>
      </form>
    </div>
  </div>
</div>
