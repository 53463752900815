import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileUploadService } from 'src/app/shared';
import { PostEventService } from 'src/app/services';
import { RadioOption, Event, EventDate, PostEvent } from '../../classes';
var PostEvent3FormComponent = /** @class */ (function () {
    function PostEvent3FormComponent(formBuilder, upload, postEventService, router) {
        this.formBuilder = formBuilder;
        this.upload = upload;
        this.postEventService = postEventService;
        this.router = router;
        this.submitted = false;
        this.ssQ1Options = [];
        this.ssQ2Options = [];
        this.ssQ3Options = [];
        this.ssQ4Options = [];
        this.ssQ5Options = [];
        this.ssQ6Options = [];
        this.ssQ7Options = [];
        this.ssQ8Options = [];
        this.ssq1show = false;
        this.ssq2show = false;
        this.ssq3show = false;
        this.ssq4show = false;
        this.ssq5show = false;
        this.ssq6show = false;
        this.ssq7show = false;
        this.ssq8show = false;
        this.sufficientStaffOptions = [];
        this.sufficientFacilitiesOptions = [];
        this.sufficientTimeOptions = [];
        this.licenseCheckOptions = [];
        this.startListOptions = [];
        this.eventFlyerOptions = [];
        this.courseChangesOptions = [];
        this.podiumOptions = [];
        this.brpOptions = [];
        this.neutralSupportOptions = [];
        this.licensedMechanicsOptions = [];
        this.timingCompanyOptions = [];
        this.medicalSupportOptions = [];
        this.equalPayOptions = [];
        this.prizeDistributionOptions = [];
        this.postingAreaOptions = [];
        this.protestPeriodOptions = [];
        this.disciplineRiderOptions = [];
        this.occurrenceReports = [];
        this.occurrenceReportAlert = [];
        this.displayOccurrenceReportUpload = false;
        this.disableOccurrencePaperwork = false;
        this.disciplineReports = [];
        this.disciplineReportAlert = [];
        this.displayDisciplineReportUpload = false;
        this.disableDisciplinePaperwork = false;
        this.loading = true;
    }
    Object.defineProperty(PostEvent3FormComponent.prototype, "eventId", {
        get: function () { return (this.event.event_id); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent3FormComponent.prototype, "eventDateId", {
        get: function () { return (this.eventDate && this.eventDate.event_date_id) || null; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent3FormComponent.prototype, "postEventId", {
        get: function () { return (this.postEvent && this.postEvent.post_event_id); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent3FormComponent.prototype, "formMode", {
        get: function () { return this.postEvent ? 'update' : 'create'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent3FormComponent.prototype, "isUsacAdmin", {
        get: function () { return this.userRole === 'usac_admin'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent3FormComponent.prototype, "f", {
        get: function () { return this.postEventForm.controls; },
        enumerable: true,
        configurable: true
    });
    PostEvent3FormComponent.prototype.ngOnInit = function () {
        this.buildForm();
        this.subscribeToForm();
        this.fetchPostEvent();
    };
    PostEvent3FormComponent.prototype.buildForm = function () {
        this.postEventForm = this.formBuilder.group({
            post_event_event_id: [this.event.event_id],
            post_event_permit_id: [this.event.event_permit_id],
            post_event_event_date_id: [this.eventDateId],
            ss_q1: ['', Validators.required],
            ss_q2: ['', Validators.required],
            ss_q3: ['', Validators.required],
            ss_q4: ['', Validators.required],
            ss_q5: ['', Validators.required],
            ss_q6: ['', Validators.required],
            ss_q7: ['', Validators.required],
            ss_q8: ['', Validators.required],
            ss_q1_text: [''],
            ss_q2_text: [''],
            ss_q3_text: [''],
            ss_q4_text: [''],
            ss_q5_text: [''],
            ss_q6_text: [''],
            ss_q7_text: [''],
            ss_q8_text: [''],
        });
        this.ssQ1Options = [
            new RadioOption('ss-q1-yes', 'Y', 'Yes'),
            new RadioOption('ss-q1-no', 'N', 'No'),
            new RadioOption('ss-q1-idk', 'I', "I don't know"),
        ];
        this.ssQ2Options = [
            new RadioOption('ss-q2-yes', 'Y', 'Yes'),
            new RadioOption('ss-q2-no', 'N', 'No'),
            new RadioOption('ss-q2-idk', 'I', "I don't know"),
        ];
        this.ssQ3Options = [
            new RadioOption('ss-q3-yes', 'Y', 'Yes'),
            new RadioOption('ss-q3-no', 'N', 'No'),
            new RadioOption('ss-q3-idk', 'I', "I don't know"),
        ];
        this.ssQ4Options = [
            new RadioOption('ss-q4-yes', 'Y', 'Yes'),
            new RadioOption('ss-q4-no', 'N', 'No'),
            new RadioOption('ss-q4-idk', 'I', "I don't know"),
        ];
        this.ssQ5Options = [
            new RadioOption('ss-q5-yes', 'Y', 'Yes'),
            new RadioOption('ss-q5-no', 'N', 'No'),
            new RadioOption('ss-q5-idk', 'I', "I don't know"),
        ];
        this.ssQ6Options = [
            new RadioOption('ss-q6-yes', 'Y', 'Yes'),
            new RadioOption('ss-q6-no', 'N', 'No'),
            new RadioOption('ss-q6-idk', 'I', "I don't know"),
        ];
        this.ssQ7Options = [
            new RadioOption('ss-q7-yes', 'Y', 'Yes'),
            new RadioOption('ss-q7-no', 'N', 'No'),
            new RadioOption('ss-q7-idk', 'I', "I don't know"),
        ];
        this.ssQ8Options = [
            new RadioOption('ss-q8-yes', 'Y', 'Yes'),
            new RadioOption('ss-q8-no', 'N', 'No'),
            new RadioOption('ss-q8-idk', 'I', "I don't know"),
        ];
    };
    PostEvent3FormComponent.prototype.shouldShow = function (q) {
        return ["N", "I"].includes(q);
    };
    PostEvent3FormComponent.prototype.subscribeToForm = function () {
        var _this = this;
        this.postEventForm.get('ss_q1').valueChanges.subscribe(function (value) {
            _this.ssq1show = _this.shouldShow(value);
        });
        this.postEventForm.get('ss_q1').valueChanges.subscribe(function (value) {
            _this.ssq1show = _this.shouldShow(value);
        });
        this.postEventForm.get('ss_q2').valueChanges.subscribe(function (value) {
            _this.ssq2show = _this.shouldShow(value);
        });
        this.postEventForm.get('ss_q3').valueChanges.subscribe(function (value) {
            _this.ssq3show = _this.shouldShow(value);
        });
        this.postEventForm.get('ss_q4').valueChanges.subscribe(function (value) {
            _this.ssq4show = _this.shouldShow(value);
        });
        this.postEventForm.get('ss_q5').valueChanges.subscribe(function (value) {
            _this.ssq5show = _this.shouldShow(value);
        });
        this.postEventForm.get('ss_q6').valueChanges.subscribe(function (value) {
            _this.ssq6show = _this.shouldShow(value);
        });
        this.postEventForm.get('ss_q7').valueChanges.subscribe(function (value) {
            _this.ssq7show = _this.shouldShow(value);
        });
        this.postEventForm.get('ss_q8').valueChanges.subscribe(function (value) {
            _this.ssq8show = _this.shouldShow(value);
        });
    };
    PostEvent3FormComponent.prototype.fetchPostEvent = function () {
        var _this = this;
        if (this.isSeries) {
            this.postEventService.getPostEventByEventDate(this.eventId, this.eventDateId).subscribe(function (postEvent) {
                _this.postEvent = postEvent;
                _this.assignPostEventToForm();
                _this.loading = false;
            });
        }
        else {
            this.postEventService.getPostEventsByEvent(this.eventId).subscribe(function (postEvents) {
                _this.postEvent = postEvents && postEvents.length && postEvents[0];
                _this.assignPostEventToForm();
                _this.loading = false;
            });
        }
    };
    PostEvent3FormComponent.prototype.assignPostEventToForm = function () {
        var _this = this;
        if (this.postEvent) {
            Object.keys(this.postEvent).forEach(function (key) {
                if (Object.keys(_this.f).includes(key)) {
                    _this.f[key].setValue(_this.booleanToYesNo(_this.postEvent[key]));
                }
            });
            console.log(this.f);
        }
    };
    PostEvent3FormComponent.prototype.setPaperworkDisableStates = function () {
        this.disableOccurrencePaperwork = this.postEvent.post_event_number_of_occurrences === 0 || this.occurrenceReports.length > 0;
        this.disableDisciplinePaperwork = this.disciplineReports.length > 0;
    };
    PostEvent3FormComponent.prototype.onSubmit = function (event) {
        var _this = this;
        // console.log(`Submitted (${this.postEventForm.status}): `, this.f);
        this.submitted = true;
        // Stop here if form is invalid
        console.log(this.postEventForm.invalid);
        if (this.postEventForm.invalid) {
            this.handleValidationFeedback();
            return;
        }
        var pef = this.postEventForm.value;
        var data = new PostEvent(pef);
        Object.keys(pef).forEach(function (key) {
            var value = pef[key];
            data[key] = value;
        });
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
        if (this.formMode === 'update') {
            this.postEventService.updatePostEventSafeSport(this.postEventId, data).subscribe(function (resp) {
                if (resp) {
                    _this.redirect(event);
                }
            });
        }
    };
    PostEvent3FormComponent.prototype.handleValidationFeedback = function () {
        var fragment = '';
        var formControlSections = this.postEventService.part2FormControlSections;
        var invalidControls = Object.entries(this.f).filter(function (_a) {
            var key = _a[0], control = _a[1];
            return control.invalid;
        });
        for (var i = 0; i < formControlSections.length; i++) {
            fragment = this.searchInvalidControls(i, formControlSections[i], invalidControls);
            if (fragment) {
                break;
            }
        }
        if (fragment) {
            document.getElementById(fragment).scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        }
    };
    PostEvent3FormComponent.prototype.searchInvalidControls = function (sectionIndex, formControlSection, invalidControls) {
        var sections = ['registration', 'conduct', 'technical', 'medical', 'disciplinary', 'prizes'];
        var _loop_1 = function (controlName) {
            if (invalidControls.some(function (control) { return control[0] === controlName; })) {
                return { value: sections[sectionIndex] };
            }
        };
        for (var _i = 0, formControlSection_1 = formControlSection; _i < formControlSection_1.length; _i++) {
            var controlName = formControlSection_1[_i];
            var state_1 = _loop_1(controlName);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        return '';
    };
    PostEvent3FormComponent.prototype.yesOrNo = function (value) {
        return value === 'Y' || value === 'N';
    };
    PostEvent3FormComponent.prototype.yesNoBoolean = function (value) {
        return value === 'Y';
    };
    PostEvent3FormComponent.prototype.booleanToYesNo = function (value) {
        if (typeof (value) === 'boolean') {
            return value ? 'Y' : 'N';
        }
        else {
            return value;
        }
    };
    PostEvent3FormComponent.prototype.label = function (key) {
        return this.postEventService.part1Labels[key];
    };
    PostEvent3FormComponent.prototype.redirect = function (event) {
        if (event === 'save-exit') {
            this.toOverview();
        }
        else if (event === 'save-continue') {
            this.toPart3();
        }
        else if (event === 'save-continue-ss') {
            this.toPart2();
        }
    };
    Object.defineProperty(PostEvent3FormComponent.prototype, "part1Url", {
        get: function () {
            var base = this.urlSegments.slice(0, -1);
            base.push('part-1');
            return base.join('/');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent3FormComponent.prototype, "part2Url", {
        get: function () {
            var base = this.urlSegments.slice(0, -1);
            base.push('part-2');
            return base.join('/');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent3FormComponent.prototype, "part3Url", {
        get: function () {
            var base = this.urlSegments.slice(0, -1);
            base.push('part-3');
            return base.join('/');
        },
        enumerable: true,
        configurable: true
    });
    PostEvent3FormComponent.prototype.toOverview = function () {
        this.router.navigate(["event/" + this.event.event_id + "/overview"], { fragment: 'post-event' });
    };
    PostEvent3FormComponent.prototype.toPart1 = function () {
        this.router.navigate([this.part1Url]);
    };
    PostEvent3FormComponent.prototype.toPart2 = function () {
        this.router.navigate([this.part2Url]);
    };
    PostEvent3FormComponent.prototype.toPart3 = function () {
        this.router.navigate([this.part3Url]);
    };
    return PostEvent3FormComponent;
}());
export { PostEvent3FormComponent };
