<form [formGroup]="permitPart1Form" (ngSubmit)="onSubmit($event)">
  <!-- comment medical information questions for legal issues-->
  <!-- <h3 class="space-below-sm">Medical Information</h3>
  <p>List name and contact for person in charge of medical operations for your event.</p>
  <div class="form-row space-below-sm">
    <div class="col-sm-4">
      <app-text-input
      formControlName="contactName"
      [type]="'text'"
      [name]="'contactName'"
      [label]="'Contact Name'"
      [placeholder]="'Jane Doe'"
      [formSubmitted]="submitted"
      [errors]="f.contactName.errors"
      ></app-text-input>
    </div>
    <div class="col-sm-4">
      <app-text-input
      formControlName="contactEmail"
      [type]="'email'"
      [name]="'contactEmail'"
      [label]="'Contact Email'"
      [placeholder]="'example@gmail.com'"
      [formSubmitted]="submitted"
      [errors]="f.contactEmail.errors"
      ></app-text-input>
    </div>
    <div class="col-sm-4">
      <app-text-input
      formControlName="contactPhone"
      [type]="'tel'"
      [name]="'contactPhone'"
      [label]="'Contact Phone'"
      [placeholder]="'(555) 555-5555'"
      [formSubmitted]="submitted"
      [errors]="f.contactPhone.errors"
      ></app-text-input>
    </div>
  </div>
  <div class="row space-below-sm">
    <div class="col-sm-12">
      <div formArrayName="medicalStaff" *ngFor="let staff of Controls; let i = index">
        <div class="row align-items-center justify-content-start">
          <div class="col-sm-9">
            <app-text-input
            [formControlName]="i"
            [type]="'text'"
            [id]="i"
            [name]="i"
            [label]="'Medical Provider'"
            [placeholder]="'Medical Provider'"
          ></app-text-input>
          </div>
          <div class="col-sm-3" *ngIf="f.medicalStaff.length > 1">
            <span class="cursor-pointer" (click)="removeStaffName(i)"><i class="icon icon-delete"></i></span>
          </div>
        </div>
      </div>
      <div>
        <app-button
        [type]="'btn-secondary'"
        [mode]="'button'"
        (click)="addStaffName()"
        >Add Additional</app-button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <app-checkbox-group
      formControlName="medicalFacilities"
      [inline]="false"
      [label]="'Describe medical facilities and staff (Click all that apply)'"
      [options]="medicalFacilitiesOptions"
      [formSubmitted]="submitted"
      [errors]="f.medicalFacilities.errors"
      (valueChange)="updateFacilities($event)"
      [required]=true></app-checkbox-group>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <app-checkbox-group-multiselect
      formControlName="medicalFacilities"
      [initValue]="permit.medicalFacilities"
      [inline]="false"
      [label]="'Describe medical facilities and staff (Click all that apply)'"
      [multiSelectLabel]="'For which sub-disciplines?'"
      [checkboxOptions]="medicalFacilitiesOptions"
      [multiSelectOptions]="subDisciplineOptions"
      [multiSelectPlaceholder]="'Select...'"
      [formSubmitted]="submitted"
      [multiSelectErrors]=""
      (valueChange)="updateFacilities($event)"
      [multiSelectRequired]=true
      ></app-checkbox-group-multiselect>
    </div>
  </div> -->
  <h3 *ngIf="competitiveNotBmx()" class="space-below-sm">Event Information</h3>

  <div class="row space-below-sm"
    *ngIf="competitiveNotBmx() || eventListing.isGranFondo">
    <div class="col-sm-10">
      <app-radio-group
        formControlName="timingInformation"
        [name]="'timingInformation'"
        [label]= "'Will you have a timing/results company?'"
        [options]="timingInfoOptions"
        [formSubmitted]="submitted"
        (valueChange)="updateTimingInfo($event)"
        [errors]="f.timingInformation.errors"
        [required]="!unrequireTiming()"
        ></app-radio-group>
    </div>
    <div class="container-fluid">
      <app-text-input
      *ngIf="(f.timingInformation.value === 'yes')"
      formControlName="timingProvider"
      [type]="'text'"
      [name]="'timingProvider'"
      [label]="'Provider'"
      [placeholder]="''"
      [formSubmitted]="submitted"
      [errors]="f.timingProvider.errors"
      [required]="true"
      ></app-text-input>
    </div>
  </div>

  <div class="row space-below-sm" *ngIf="requireStagingArea() && (f.timingInformation.value === 'yes')">
      <div class="col-sm">
        <app-checkbox-group
          formControlName="timings"
          [inline]="false"
          [label]="'Will you be timing?'"
          [options]="timingOptions"
          (valueChange)= "updateTimings($event)"
        ></app-checkbox-group>
      </div>
  </div>
  <div class="row space-below-sm"
       *ngIf ="competitiveNotBmx()">
    <div class="col-sm">
      <app-text-input
      formControlName="registrationProvider"
      [type]="'text'"
      [name]="'registrationProvider'"
      [label]="'Who will handle registration, Collection of signed Waivers and license verification?'"
      [placeholder]="''"
      [formSubmitted]="submitted"
      [errors]="f.registrationProvider.errors"
      [required]=true></app-text-input>
    </div>
  </div>
  <!-- Only for Grand Fondo & Gravel disciplines -->
  <div class="row space-below-sm"
  *ngIf="requireStagingArea()">
    <div class="col-sm-10">
      <app-radio-group
        formControlName="stagingArea"
        [name]="'stagingArea'"
        [label]= "'Will your event have a staging area for USA Cycling Members?'"
        [options]="stagingAreaOptions"
        [formSubmitted]="submitted"
        [errors]="f.stagingArea.errors"
        [required]=true
        (valueChange)="updateStagingArea($event)">
      </app-radio-group>
    </div>
  </div>
  <!-- <div class="row space-below-sm"
    *ngIf="competitiveNotBmx()">
    <div class="col-sm-10">
      <app-radio-group
        formControlName="payOut"
        [name]="'payOut'"
        [label]= "'Does your event have equal payout for men and women?'"
        [options]="payOutOptions"
        [formSubmitted]="submitted"
        (valueChange)="updatePayOutInfo($event)"
        [errors]="f.payOut.errors"
        [required]=true
        ></app-radio-group>
    </div>
  </div> -->
  <div class="row space-below-sm"
    *ngIf="competitiveNotBmx()">
    <div class="col-sm">
          <app-text-input
          formControlName="maxPayOut"
          [type]="'text'"
          [name]="'maxPayOut'"
          [label]="'What is your maximum payout for one category at your event? (Please list GC payout or series payout if your event is a stage race or series)'"
          [placeholder]="'Amount'"
          [prepend]=true
          [prependContent]="'$'"
          [maxlength]="'10'"
          (keypress)="numberOnly($event)"
          [formSubmitted]="submitted"
          [errors]="f.maxPayOut.errors"
          [required]=true></app-text-input>
    </div>
  </div>
  <h3 class="space-below-lg">Communications</h3>
  <div class="row space-below-lg">
    <div class="col-sm-12">
      <app-checkbox-group
        formControlName="communicationTypes"
        [inline]="false"
        [label]="'What communication types are you utilizing for your event? (Check all that apply)'"
        [options]="communicationTypesOptions"
        [formSubmitted]="submitted"
        [errors]="f.communicationTypes.errors"
        (valueChange)= "updateCommunicationTypes($event)"
        [required]=true
      ></app-checkbox-group>
    </div>
  </div>
  <div class="row">
    <div class="col-sm">
      <app-button [mode]="'button'" [type]="'btn-light'" (click)="toOverview()">{{backNavText()}}</app-button>
    </div>
    <div class="col-sm text-right">
      <app-button
        [type]="'btn-secondary'"
        [mode]="'button'"
        (click)="openSaveExitModal()"
      >Save & Exit</app-button>
      <app-button
        [type]="'btn-primary'"
        (click)="onSubmit($event)"
      >Continue</app-button>
    </div>
  </div>
</form>

<app-modal
  [id]="'save-exit'"
  [title]="'Save & Exit Permit'"
  [isOpen]="showSaveExit"
  (close)="closeSaveExitModal(true)"
>
  <app-modal-body>
    <p>Would you like to save your current permit progress and return at a later time? Please note that your event will not be listed and permitting cannot be approved until this form is complete.</p>
    <div class="row">
      <div class="col-sm-6 text-left">
        <app-button *ngIf="navUrl === ''"
          [type]="'btn-light'"
          (click)="closeSaveExitModal(true)"
        >Go Back</app-button>
        <app-button *ngIf="navUrl !== ''"
          [type]="'btn-light'"
          (click)="continueNavigation()"
        >Continue Without Saving</app-button>
      </div>
      <div class="col-sm-6 text-right">
        <app-button
          [type]="'btn-secondary'"
          (click)="saveAndExit()"
        >Save & Exit</app-button>
      </div>
    </div>
  </app-modal-body>
</app-modal>
