/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./bottom-footer.component";
var styles_BottomFooterComponent = [];
var RenderType_BottomFooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BottomFooterComponent, data: {} });
export { RenderType_BottomFooterComponent as RenderType_BottomFooterComponent };
export function View_BottomFooterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "bottom-footer container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "row text-center align-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "text-lg-left col-md-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["src", "/assets/images/UCI.jpg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "col-md-8 text-center mb-3 mt-3 mb-lg-0 mt-lg-03 footer-text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Recognized by the United States Olympic & Paralympic Committee and the Union Cycliste Internationale, USA Cycling is the official governing body for all disciplines of competitive cycling in the United States, including BMX, cyclocross, mountain bike, road and track."])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "a", [["href", "https://www.usacycling.org/privacy"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["View Children's Online Privacy Protection Policy"])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "text-lg-right col-md-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "img", [["src", "/assets/images/USOPC_Cycling.png"]], null, null, null, null, null))], null, null); }
export function View_BottomFooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-bottom-footer", [], null, null, null, View_BottomFooterComponent_0, RenderType_BottomFooterComponent)), i0.ɵdid(1, 114688, null, 0, i1.BottomFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BottomFooterComponentNgFactory = i0.ɵccf("app-bottom-footer", i1.BottomFooterComponent, View_BottomFooterComponent_Host_0, {}, {}, []);
export { BottomFooterComponentNgFactory as BottomFooterComponentNgFactory };
