import { OnInit, EventEmitter } from '@angular/core';
import { FormFieldErrors } from '../../classes';
var noop = function () { };
var ɵ0 = noop;
var TextInputComponent = /** @class */ (function () {
    function TextInputComponent() {
        this.currentValue = '';
        this.onTouchedCallback = noop;
        this.onChangeCallback = noop;
        this.change = new EventEmitter();
        this.valueChange = new EventEmitter();
    }
    Object.defineProperty(TextInputComponent.prototype, "value", {
        get: function () {
            return this.currentValue;
        },
        set: function (val) {
            this.currentValue = val;
            this.change.emit(this.currentValue);
            this.valueChange.emit(this.currentValue);
            this.onChangeCallback(this.currentValue);
        },
        enumerable: true,
        configurable: true
    });
    TextInputComponent.prototype.writeValue = function (value) {
        this.currentValue = value;
        this.onChangeCallback(value);
    };
    // From ControlValueAccessor interface
    TextInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    // From ControlValueAccessor interface
    TextInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    TextInputComponent.prototype.ngOnInit = function () {
    };
    TextInputComponent.prototype.onChange = function (event) {
        // console.log('input changed', event);
        // this.onChangeCallback(event.target.value);
    };
    return TextInputComponent;
}());
export { TextInputComponent };
export { ɵ0 };
