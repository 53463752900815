import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService, PermitService, ProfileService } from 'src/app/services';

import { CalendarLabel, CalendarLabelAnswer, CalendarLabelQuestion, Event, EventDate, Profile } from 'src/app/classes';

@Component({
  selector: 'app-post-event-part2',
  templateUrl: './post-event-part2.component.html',
  styles: []
})
export class PostEventPart2Component implements OnInit {
  eventId: string;
  event: Event;
  eventDateId: string;
  eventDate: EventDate;
  eventDates: EventDate[];
  disciplineName: string;
  calendarLabels: CalendarLabel[];
  calendarLabelQuestions: CalendarLabelQuestion[];
  calendarLabelAnswers: CalendarLabelAnswer[];

  currentUser: Profile;
  userRole: string;
  eventOrganizer: Profile;

  urlSegments: string[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eventService: EventService,
    private permitService: PermitService,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
    this.urlSegments = this.route.snapshot.url.map(segment => segment.path);

    this.eventId = this.route.snapshot.paramMap.get('eventId');
    this.eventDateId = this.route.snapshot.paramMap.get('dateId');
    this.eventService.getEventById(this.eventId).subscribe(event => {
      // Event doesn't exist
      if (!event) {
        this.toEventQueue();
      } else {
        this.event = new Event(event);

        //  Event isn't permitted
        if (!this.event.isPermit) {
          this.toOverview();
        } else {
          this.disciplineName = this.eventService.getDisciplineName(event.event_discipline);
          if (this.eventDateId) {
            if (this.isSeries) {
              this.eventService.getEventDate(this.eventDateId).subscribe(eventDate => {
                if (eventDate && eventDate.event_date_event_id.toString() === this.eventId) {
                  this.eventDate = eventDate;
                // Event date doesn't belong to the event
                } else {
                  this.toOverviewPostEvent();
                }
              });
            // Has an event date param, but isn't a series
            } else {
              this.toOverviewPostEvent();
            }
          // Is a series, but doesn't have an event date param
          } else if (this.isSeries) {
            this.toOverviewPostEvent();
          // Isn't a series and doesn't have an event date param
          } else {
            this.eventService.getEventDates(this.event.event_id).subscribe(dates => {
              this.eventDates = this.eventService.sortEventDates(dates);
            });
          }
          if (!!this.profileService.currentUser) {
            this.getUserAndOrganizer();
          } else {
            this.profileService.getCurrentUser().subscribe(() => {
              this.getUserAndOrganizer();
            });
          }
          this.permitService.getCalendarLabelsForEvent(this.eventId).subscribe(labels => this.calendarLabels = labels);
          this.permitService.getCalendarQuestions(this.event.event_discipline).subscribe(questions => {
            this.calendarLabelQuestions = questions;
            this.permitService.getCalendarLabelAnswersForEvent(this.eventId).subscribe(answers => this.calendarLabelAnswers = answers);
          });
        }
      }
    });
  }

  getUserAndOrganizer() {
    this.setUserRole();
    this.getEventOrganizer();
  }

  setUserRole() {
    this.currentUser = this.profileService.currentUser;
    this.profileService.getUserPermissions().subscribe(permissions => {
      this.userRole = this.profileService.setUserRole(permissions);
    });
  }

  getEventOrganizer() {
    this.profileService.getProfile(this.event.event_organizer).subscribe(profile => this.eventOrganizer = profile);
  }

  get isSeries() {
    return this.eventService.isSeries(this.event);
  }

  toOverview() {
    this.router.navigate([`event/${this.eventId}/overview`]);
  }

  toOverviewPostEvent() {
    this.router.navigate([`event/${this.eventId}/overview`], {fragment: 'post-event'});
  }

  toEventQueue() {
    this.router.navigate(['event/queue']);
  }

  displayFormEvent() {
    return !this.eventDateId && !!this.event && !!this.eventDates && !!this.userRole;
  }

  displayFormEventDate() {
    return !!this.event && !!this.eventDate && !!this.userRole;
  }
}
