<div class="filter-drawer">
  <button type="button" class="btn btn-outline filter-button" (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed" aria-controls="filterCollapse"> Filter
    <!-- Filter -->
  </button>
  <div id="filterCollapse" [ngbCollapse]="isCollapsed">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title divider" >
        {{title}}
        </h4>
        <br>
        <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
          <app-text-input formControlName="name"
            [type]="'text'"
            [name]="'name'"
            [label]="'Event Name'"
            [placeholder]="'Event Name...'"
            [formSubmitted]="submitted"
            [errors] = "f.name.errors"
            [required]=false
          ></app-text-input>
          <app-select formControlName="listing_type"
            [label]="'Listing Type'"
            [options]="listingTypeOptions"
            [name]="'listing_type'"
            [formSubmitted]="submitted"
            [errors] = "f.listing_type.errors"
            [required]=false></app-select>
          <app-multi-select formControlName="status"
            [label]="'Status'"
            [placeholder]="'Select...'"
            [options]="statusOptions"
            [name]="'status'"
            [formSubmitted]="submitted"
            [errors] = "f.status.errors"
            [required]=false></app-multi-select>
          <div class="row">
            <div class="col-sm-8">
              <app-text-input formControlName="city"
                [type]="'text'"
                [name]="'city'"
                [label]="'City'"
                [placeholder]="'Event City...'"
                [formSubmitted]="submitted"
                [errors] = "f.city.errors"
                [required]=false></app-text-input>
            </div>
            <div class="col-sm-4">
              <app-select formControlName="states"
                [name]="'states'"
                [label]="'State'"
                [options]="stateOptions"
                [formSubmitted]="submitted"
                [errors] = "f.states.errors"
                [required]=false></app-select>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <app-multi-select formControlName="regions"
                [label]="'ESC Regions'"
                [placeholder]="'Select...'"
                [options]="regionOptions"
                [name]="'regions'"
                [formSubmitted]="submitted"
                [errors] = "f.regions.errors"
                [required]=false></app-multi-select>
            </div>
          </div>

          <label for="">Date Range</label>
          <div class="row">
            <div class="col-sm-6">
              <app-datepicker
                formControlName="event_start_date"
                [label]="'Start'"
                [placeholder]="'Start'"
              ></app-datepicker>
            </div>
            <div class="col-sm-6">
              <app-datepicker
                formControlName="event_end_date"
                [label]="'End'"
                [placeholder]="'End'"
              ></app-datepicker>
            </div>
          </div>
          <label for="">Permit Creation Date Range</label>
          <div class="row">
            <div class="col-sm-6">
              <app-datepicker
                formControlName="permitCreateStartDate"
                [label]="'Start'"
                [placeholder]="'Start'"
              ></app-datepicker>
            </div>
            <div class="col-sm-6">
              <app-datepicker
                formControlName="permitCreateEndDate"
                [label]="'End'"
                [placeholder]="'End'"
                ></app-datepicker>
            </div>
          </div>
          <app-multi-select formControlName="event_discipline"
            [label]="'Discipline'"
            [placeholder]="'Select...'"
            [options]="disciplineOptions"
            [name]="'event_discipline'"
            [formSubmitted]="submitted"
            [errors] = "f.event_discipline.errors"
            [required]=false
          ></app-multi-select>
          <app-select formControlName="post_event_status"
            [name]="'post_event_status'"
            [label]="'Has Post Event in Status'"
            [options]="postEventStatusOptions"
            [formSubmitted]="submitted"
            [errors] = "f.post_event_status.errors"
            [required]=false
          ></app-select>
          <app-multi-select *ngIf="calendarLabelOptions.length" formControlName="calendar_label_ids"
            [name]="'calendar_label_ids'"
            [label]="'Event Label'"
            [placeholder]="'Select...'"
            [options]="calendarLabelOptions"
            [formSubmitted]="submitted"
            [errors] = "f.calendar_label_ids.errors"
            [required]=false
          ></app-multi-select>

          <!-- TODO: Enable these filters once documented & tested against events queue API -->
          <!-- <div *ngIf="userRole === 'usac_admin' || userRole === 'la_admin'">
            <label for="">Event Organizer/Owner</label>
            <div class="row">
              <div class="col-sm-4 col-md-6">
                <app-text-input formControlName="organizerFirst"
                  [type]="'text'"
                  [name]="'organizerFirst'"
                  [label]="'First'"
                  [placeholder]="'First'"
                  [formSubmitted]="submitted"
                  [errors] = "f.organizerFirst.errors"
                  [required]=false
                ></app-text-input>
              </div>
              <div class="col-sm-4 col-md-6">
                <app-text-input formControlName="organizerLast"
                  [type]="'text'"
                  [name]="'organizerLast'"
                  [label]="'Last'"
                  [placeholder]="'Last'"
                  [formSubmitted]="submitted"
                  [errors] = "f.organizerLast.errors"
                  [required]=false
                ></app-text-input>
              </div>
              <div class="col-sm-4 col-md-6">
                <app-text-input formControlName="event_organizer"
                  [type]="'text'"
                  [name]="'event_organizer'"
                  [label]="'USAC ID'"
                  [placeholder]="'ID'"
                  [formSubmitted]="submitted"
                  [errors] = "f.event_organizer.errors"
                  [required]=false
                ></app-text-input>
              </div>
            </div>
            <label for="">Chief Referee</label>
            <div class="row">
              <div class="col-sm-4 col-md-6">
                <app-text-input formControlName="refereeFirst"
                  [type]="'text'"
                  [name]="'refereeFirst'"
                  [label]="'First'"
                  [placeholder]="'First'"
                  [formSubmitted]="submitted"
                  [errors] = "f.refereeFirst.errors"
                  [required]=false
                ></app-text-input>
              </div>
              <div class="col-sm-4 col-md-6">
                <app-text-input formControlName="refereeLast"
                  [type]="'text'"
                  [name]="'refereeLast'"
                  [label]="'Last'"
                  [placeholder]="'Last'"
                  [formSubmitted]="submitted"
                  [errors] = "f.refereeLast.errors"
                  [required]=false
                ></app-text-input>
              </div>
              <div class="col-sm-4 col-md-6">
                <app-text-input formControlName="refereeId"
                  [type]="'text'"
                  [name]="'refereeId'"
                  [label]="'USAC ID'"
                  [placeholder]="'ID'"
                  [formSubmitted]="submitted"
                  [errors] = "f.refereeId.errors"
                  [required]=false
                ></app-text-input>
              </div>
            </div>
          </div> -->

          <!-- TODO: Enable these filters once documented & tested against events queue API -->
          <!-- <div class="row space-below-lg">
            <div class="col-sm-12">
              <app-checkbox
                class="col-12"
                formControlName="hasNotifications"
                [id]="'hasNotifications'"
                [name]="'hasNotifications'"
                [label]="'Has Notifications?'"
                [toggle]="false"
                [formSubmitted]="submitted"
                [errors] = "f.hasNotifications.errors"
                [required]=false
              ></app-checkbox>
              <app-checkbox *ngIf="userRole === 'usac_admin'"
                class="col-12"
                formControlName="delinquent"
                [id]="'delinquent'"
                [name]="'delinquent'"
                [label]="'Delinquent Events'"
                [toggle]="false"
                [formSubmitted]="submitted"
                [errors] = "f.delinquent.errors"
                [required]=false
              ></app-checkbox>
            </div>
          </div> -->
          <!-- <div class="col-12"> -->
            <app-text-input *ngIf="isUsacAdmin"
                formControlName="order_number"
                [type]="'text'"
                [name]="'order_number'"
                [label]="'Order Number'"
                [placeholder]="'1234'"
                [formSubmitted]="submitted"
                [errors] = "f.order_number.errors"
                [required]=false
              ></app-text-input>
          <!-- </div> -->
          <div class="row">
            <div class="col-sm-6">
              <app-button
                [type]="'btn-light'"
                [mode]="'button'"
                (click)="onReset()"
              >Reset</app-button>
            </div>
            <div class="col-sm-6 text-right">
              <app-button
              [type]="'btn-secondary'"
              >Apply</app-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

</div>
