<app-loader *ngIf="loading" [small]="true"></app-loader>

<!-- <div class="row justify-content-md-center space-above-lg bg-gray-1" *ngIf="event || eventListing.mode !== 'update'">
    <div class="col-sm-12">
        <div class="row">
            <div class="col-sm">
                <h3>General Information</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="review-section">
                    <h5>Organizer</h5>
                    <p>Member ID - {{eventListing.event_organizer}}</p>
                    <p>Name - {{eventListing.organizerName}}</p>
                </div>
                <div class="review-section">
                    <h5>Contact(s)</h5>
                    <div *ngFor="let contact of eventListing.contacts; let i = index ;">
                        <div *ngIf="i < eventListing.contactsLimit"
                            [class.text-success]="eventListing.mode === 'update' && !isExistsActualContactProp('id')">
                            <div *ngIf="contact.id" >
                                <p><span *ngIf="eventListing.contacts.length > 1">{{i+1}}.</span> Member ID: {{contact.id}}</p>
                            </div>
                            <div
                                *ngIf="(getActualContact(contact.id) && isExistsActualContactProp('name') && eventListing.mode === 'update' && contact.name !== actualContact.name); then updateContactName else newContactName">
                            </div>
                            <ng-template #newContactName>
                                <p> Name: {{contact.name}}</p>
                            </ng-template>
                            <ng-template #updateContactName>
                                <p><del class="text-danger">Name: {{actualContact.name}}</del></p>
                                <p class="text-success">Name: {{contact.name}}</p>
                            </ng-template>
                            <div
                                *ngIf="(isExistsActualContactProp('email') && eventListing.mode === 'update' && contact.email !== actualContact.email); then updateContactEmail else newContactEmail">
                            </div>
                            <ng-template #newContactEmail>
                                <p> Email: {{contact.email}}</p>
                            </ng-template>
                            <ng-template #updateContactEmail>
                                <p><del class="text-danger">Email: {{actualContact.email}}</del></p>
                                <p class="text-success">Email: {{contact.email}}</p>
                            </ng-template>
                            <div
                                *ngIf="(isExistsActualContactProp('mobile') && eventListing.mode === 'update' && contact.mobile !== actualContact.mobile); then updateContactMobile else newContactMobile">
                            </div>
                            <ng-template #newContactMobile>
                                <p> Phone: {{contact.mobile}}</p>
                            </ng-template>
                            <ng-template #updateContactMobile>
                                <p><del class="text-danger">Phone: {{actualContact.mobile}}</del></p>
                                <p class="text-success">Phone: {{contact.mobile}}</p>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="review-section">
                    <h5>Sponsoring Club or Entity</h5>
                    <div *ngFor="let club of eventListing.event_sponsoring_clubs; let i = index">
                        <p class="{{ getSponsoringClubState(club.value) }}">{{i+1}}. {{club.label}}</p>
                    </div>
                </div>
                <div class="review-section" *ngIf="eventListing.event_reg_url">
                    <h5>Registration Link</h5>
                    <div
                        *ngIf="(eventListing.mode === 'update' && eventListing.event_reg_url !== event.event_reg_url); then updateRegUrl else newRegUrl">
                    </div>
                    <ng-template #newRegUrl>
                        <p>{{eventListing.event_reg_url}}</p>
                    </ng-template>
                    <ng-template #updateRegUrl>
                        <p><del class="text-danger">{{event.event_reg_url}}</del></p>
                        <p class="text-success">{{eventListing.event_reg_url}}</p>
                    </ng-template>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="review-section" *ngIf="eventListing.event_website">
                    <h5>Website</h5>
                    <div
                        *ngIf="(eventListing.mode === 'update' && eventListing.event_website !== event.event_website); then updateEventWebsite else newEventWebsite ">
                    </div>
                    <ng-template #newEventWebsite>
                        <a href="{{eventListing.event_website}}" target="_blank">{{eventListing.event_website}}</a>
                    </ng-template>
                    <ng-template #updateEventWebsite>
                        <p><a href="{{event.event_website}}" target="_blank"><del
                                    class="small text-danger">{{event.event_website}}</del></a></p>
                        <p class="text-success"><a href="{{eventListing.event_website}}" target="_blank">
                                {{eventListing.event_website}}</a></p>
                    </ng-template>
                </div>
                <div class="review-section">
                    <h5>Logo</h5>
                    <img style="max-height:100px;" class="listing-logo space-below-sm"
                        src="/assets/images/usac-logo.png">
                </div>
                <div class="review-section">
                    <h5>Social Media</h5>
                    <div *ngIf="eventListing.event_facebook_url">
                        <div
                            *ngIf="(eventListing.mode === 'update' && eventListing.event_facebook_url !== event.event_facebook_url); then updatefacebookUrl else newfacebookUrl">
                        </div>
                        <ng-template #newfacebookUrl>
                            <p>Facebook - <a href="{{eventListing.event_facebook_url}}"
                                    target="_blank">{{eventListing.event_facebook_url}}</a></p>
                        </ng-template>
                        <ng-template #updatefacebookUrl>
                            <p>
                                <a href="{{event.event_facebook_url}}" target="_blank">
                                    <del class="small text-danger">Facebook - {{event.event_facebook_url}}</del>
                                </a>
                            </p>
                            <p class="text-success">
                                Facebook -<a href="{{eventListing.event_facebook_url}}"
                                    target="_blank">{{eventListing.event_facebook_url}}</a></p>
                        </ng-template>
                    </div>
                    <div *ngIf="eventListing.event_twitter_url">
                        <div
                            *ngIf="(eventListing.mode === 'update' && eventListing.event_twitter_url !== event.event_twitter_url); then updateTwitterUrl else newTwitterUrl">
                        </div>
                        <ng-template #newTwitterUrl>
                            <p>Twitter - <a href="{{eventListing.event_twitter_url}}"
                                    target="_blank">{{eventListing.event_twitter_url}}</a></p>
                        </ng-template>
                        <ng-template #updateTwitterUrl>
                            <p>
                                <a href="{{event.event_twitter_url}}" target="_blank"><del class="small text-danger">Twitter -
                                        {{event.event_twitter_url}}</del></a> </p>
                            <p class="text-success">Twitter - <a href="{{eventListing.event_twitter_url}}"
                                    target="_blank">{{eventListing.event_twitter_url}}</a></p>
                        </ng-template>
                    </div>
                    <div *ngIf="eventListing.event_youtube_url">
                        <div
                            *ngIf="(eventListing.mode === 'update' && eventListing.event_youtube_url !== event.event_youtube_url); then updateYoutubeUrl else newYoutubeUrl ">
                        </div>
                        <ng-template #newYoutubeUrl>
                            <p>Youtube - <a href="{{eventListing.event_youtube_url}}"
                                    target="_blank">{{eventListing.event_youtube_url}}</a></p>
                        </ng-template>
                        <ng-template #updateYoutubeUrl>
                            <p>
                                <a href="{{event.event_youtube_url}}" target="_blank"><del class="small text-danger">Youtube -
                                        {{event.event_youtube_url}}</del></a> </p>
                            <p class="text-success">Youtube - <a href="{{eventListing.event_youtube_url}}"
                                    target="_blank">{{eventListing.event_youtube_url}}</a></p>
                        </ng-template>
                    </div>
                    <div *ngIf="eventListing.event_instagram_url">
                        <div
                            *ngIf="(eventListing.mode === 'update' && eventListing.event_instagram_url !== event.event_instagram_url); then updateInstagramUrl else newInstagramUrl ">
                        </div>
                        <ng-template #newInstagramUrl>
                            <p>Instagram - <a href="{{eventListing.event_instagram_url}}"
                                    target="_blank">{{eventListing.event_instagram_url}}</a>
                            </p>
                        </ng-template>
                        <ng-template #updateInstagramUrl>
                            <p>
                                <a href="{{event.event_instagram_url}}" target="_blank"><del
                                        class="small text-danger">Instagram - {{event.event_instagram_url}}</del></a> </p>
                            <p class="text-success">Instagram - <a href="{{eventListing.event_instagram_url}}"
                                    target="_blank">{{eventListing.event_instagram_url}}</a></p>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div class="row space-above-lg">
            <div class="col-sm">
                <h3>Event Information</h3>
            </div>
        </div>
        <div class="row space-below-lg">
            <div class="col-sm-12">
                <div class="review-section">
                    <div class="row">
                        <div class="col-sm-6">
                            <h5>Event Name</h5>
                            <div *ngIf="eventListing.event_name">
                                <div
                                    *ngIf="(eventListing.mode === 'update' && eventListing.event_name !== event.event_name) then updateEventName else  newEventName ">
                                </div>
                                <ng-template #newEventName>
                                    <p>{{eventListing.event_name}}</p>
                                </ng-template>
                                <ng-template #updateEventName>
                                    <p><del class="small text-danger">{{event.event_name}}</del></p>
                                    <p class="text-success">{{eventListing.event_name}}</p>

                                </ng-template>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <h5>Event Discipline</h5>
                            <div *ngIf="eventListing.event_discipline">
                                <div
                                    *ngIf="(eventListing.mode === 'update' && eventListing.event_discipline !== event.event_discipline); then updateDiscipline  else newDiscipline ">
                                </div>
                                <ng-template #newDiscipline>
                                    <p>{{disciplineName}}</p>
                                </ng-template>
                                <ng-template #updateDiscipline>
                                    <p>
                                        <del class="small text-danger">{{disciplineName}}</del></p>
                                    <p class="text-success">{{disciplineName}}</p>

                                </ng-template>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="review-section">
                    <div class="row">
                        <div class="col-sm-6">
                            <h5>Start Date</h5>
                            <div *ngIf="eventListing.event_start_date">
                                <div *ngIf="hasUpdatedStartDate() then updateStartDate else newStartDate">
                                </div>
                                <ng-template #newStartDate>
                                    <p>{{eventListing.event_start_date | date: 'fullDate'}}</p>
                                </ng-template>
                                <ng-template #updateStartDate>
                                    <p>
                                        <del class="text-danger">{{event.event_start_date | date: 'fullDate'}}</del></p>
                                    <p class="text-success">{{eventListing.event_start_date | date: 'fullDate'}}</p>
                                </ng-template>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <h5>End Date</h5>
                            <div *ngIf="eventListing.event_end_date">
                                <div *ngIf="hasUpdatedEndDate(); then updateEndDate else newEndDate">
                                </div>
                                <ng-template #newEndDate>
                                    <p>{{eventListing.event_end_date | date: 'fullDate'}}</p>
                                </ng-template>
                                <ng-template #updateEndDate>
                                    <p>
                                        <del class="text-danger">{{event.event_end_date | date: 'fullDate'}}</del></p>
                                    <p class="text-success">{{eventListing.event_end_date | date: 'fullDate'}}</p>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="review-section">
                    <div class="row">
                        <div class="col-sm-6">
                            <h5>Event Type</h5>
                            <p>{{eventTypeNames}}</p>
                        </div>
                        <div class="col-sm-6">
                            <h5>Special Calendars</h5>
                            <p>Special Calendar: {{eventListing.event_special_calendars.join(',')}}</p>
                            <p>Calendar Code: {{eventListing.calendarCode}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                <h5>Event Dates</h5>
            </div>
        </div>
        <div class="row justify-content-left no-gutters">
            <div *ngFor="let eventDate of eventListing.eventDates; let i = index">
                <div class="col-sm">
                    <app-event-dates-review [index]="i" [eventDate]="eventDate"
                        [originalEventDate]="getActualEventDate(eventDate.event_date_id)" [mode]="eventListing.mode">
                    </app-event-dates-review>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="row justify-content-md-center space-above-lg bg-gray-1" *ngIf="!loading">
    <div class="col-sm-12">
        <div class="row">
            <div class="col-sm">
                <h3>General Information</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="review-section">
                    <h5>Organizer</h5>
                    <p>Member ID - {{eventListing.event_organizer}}</p>
                    <p>Name - {{eventListing.organizerName}}</p>
                </div>
                <div class="review-section">
                    <h5>Contact(s)</h5>
                    <div *ngFor="let contact of validContacts; let i = index ;">
                        <p>{{i+1}}. {{contact.contact_first_name}} {{contact.contact_last_name}}</p>
                        <p *ngIf="contact.contact_email_1"> Email: {{contact.contact_email_1}}</p>
                        <p *ngIf="contact.contact_phone_1"> Phone: {{contact.contact_phone_1}}</p>
                    </div>
                </div>
                <div class="review-section">
                    <h5>Sponsoring Club or Entity</h5>
                    <div *ngFor="let club of eventListing.event_sponsoring_clubs; let i = index">
                        <p >{{i+1}}. {{club.label || club.club_name}}</p>
                    </div>
                    <p *ngIf="!eventListing.event_sponsoring_clubs || eventListing.event_sponsoring_clubs.length === 0">None</p>
                </div>
                <div class="review-section" *ngIf="eventListing.event_reg_url">
                    <h5>Registration Link</h5>
                    <a class="text-break" href="{{regUrl}}" target="_blank">{{eventListing.event_reg_url}}</a>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="review-section" *ngIf="eventListing.event_website">
                    <h5>Website</h5>
                    <a class="text-break" href="{{websiteUrl}}" target="_blank">{{eventListing.event_website}}</a>
                </div>
                <div class="review-section" *ngIf="eventListing.event_announcement">
                    <h5>Announcement</h5>
                    <a class="text-break" href="{{announcementUrl}}" target="_blank">Event Announcement</a>
                </div>
                <div class="review-section review-logo">
                    <h5>Logo</h5>
                    <!-- <img style="max-height:100px;" class="listing-logo space-below-sm"
                        src="{{eventListing.event_logo}}"> -->
                    <figure class="figure" *ngIf="eventListing.event_logo">
                        <img src="{{eventListing.event_logo}}" class="figure-img img-fluid rounded" alt="Event Logo">
                    </figure>
                </div>
                <div class="review-section text-break">
                    <h5>Social Media</h5>
                    <div *ngIf="eventListing.event_facebook_url">
                        <p>Facebook - <a href="{{facebookUrl}}"
                                target="_blank">{{eventListing.event_facebook_url}}</a></p>
                    </div>
                    <div *ngIf="eventListing.event_twitter_url">
                        <p>Twitter - <a href="{{twitterUrl}}"
                                target="_blank">{{eventListing.event_twitter_url}}</a></p>
                    </div>
                    <div *ngIf="eventListing.event_youtube_url">
                        <p>YouTube - <a href="{{youtubeUrl}}"
                                target="_blank">{{eventListing.event_youtube_url}}</a></p>
                    </div>
                    <div *ngIf="eventListing.event_instagram_url">
                        <p>Instagram - <a href="{{instagramUrl}}"
                                target="_blank">{{eventListing.event_instagram_url}}</a>
                        </p>
                    </div>
                </div>
                <div *ngIf="qrUri" class="review-section text-break">
                  <h5>One Day Registration</h5>
                  <div>
                    <p class="one-day-link"><a href="{{oneDayRegistrationUrl}}" target="_blank">{{oneDayRegistrationUrl}}</a></p>
                    <img class="qr-image" src="{{qrUri}}" alt="One day registration QR code">
                    <div class="qr-instructions">
                      Download and add this QR code to event fliers, or place it on your
                      registration table for easy mobile access to the one-day registration page
                    </div>
                  </div>
              </div>
            </div>
        </div>
        <div class="row space-above-lg">
            <div class="col-sm">
                <h3>Event Information</h3>
            </div>
        </div>
        <div class="row space-below-lg">
            <div class="col-sm-12">
                <div class="review-section">
                    <div class="row">
                        <div class="col-sm-6">
                            <h5>Event Name</h5>
                            <div *ngIf="eventListing.event_name">
                                <p>{{eventListing.event_name}}</p>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <h5>Event Discipline</h5>
                            <div *ngIf="eventListing.event_discipline">
                                <p>{{disciplineName}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="review-section">
                    <div class="row">
                        <div class="col-sm-6">
                            <h5>Start Date</h5>
                            <div *ngIf="eventListing.event_start_date">
                                <!-- <div *ngIf="hasUpdatedStartDate() then updateStartDate else newStartDate">
                                </div> -->
                                <p>{{eventListing.event_start_date | date: 'fullDate':'UTC'}}</p>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <h5>End Date</h5>
                            <div *ngIf="eventListing.event_end_date">
                                <!-- <div *ngIf="hasUpdatedEndDate(); then updateEndDate else newEndDate">
                                </div> -->
                                <p>{{eventListing.event_end_date | date: 'fullDate':'UTC'}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="review-section">
                    <div class="row">
                        <div class="col-sm-6">
                            <h5>Event Type <span class="icon icon-information" placement="right" [ngbTooltip]="tipContent">
                            <ng-template #tipContent>Event Type is used to classify an event as either being a Stage Race or Omnium race as defined below.  Leave the check boxes blank if your event is not one of these event types and it will show up as "None" in the event listing.</ng-template></span></h5>
                            <p>{{eventTypeNames}}</p>
                        </div>
                        <div class="col-sm-6">
                            <h5>Special Calendar</h5>
                            <p *ngIf="eventListing.event_special_calendars.length > 0">Calendar(s): {{specialCalendarText}}</p>
                            <p *ngIf="eventListing.event_special_calendars.length === 0">None</p>
                            <p *ngIf="eventListing.calendarCode && eventListing.calendarCode !== ''">Calendar Code: {{eventListing.calendarCode}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                <h5>Event Dates</h5>
            </div>
        </div>
        <div class="row justify-content-left no-gutters">
            <div *ngFor="let eventDate of eventListing.sortedEventDates(); let i = index" class="col-lg-6">
                <div class="col">
                    <app-event-dates-review [index]="i" [eventDate]="eventDate"
                        [originalEventDate]="getActualEventDate(eventDate.event_date_id)" [mode]="eventListing.mode">
                    </app-event-dates-review>
                </div>
            </div>
        </div>
    </div>
</div>
