<div class="bottom-footer container">
  <div class="row text-center align-items-center">
    <div class="text-lg-left col-md-2">
      <img src="/assets/images/UCI.jpg">
    </div>
    <div class="col-md-8 text-center mb-3 mt-3 mb-lg-0 mt-lg-03 footer-text">
      <p>Recognized by the United States Olympic & Paralympic Committee and the Union Cycliste Internationale,
        USA Cycling is the official governing body for all disciplines of competitive cycling in the United States,
        including BMX, cyclocross, mountain bike, road and track.</p>
      <a href="https://www.usacycling.org/privacy" target="_blank">View Children's Online Privacy Protection Policy</a>
    </div>
    <div class="text-lg-right col-md-2">
      <img src="/assets/images/USOPC_Cycling.png">
    </div>
  </div>
</div>
