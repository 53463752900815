export const environment = {
  production: true,
  env: 'production',
  apiUrl: 'https://api.usacycling.org/',
  nextGenApiUrl: 'https://laravel-api.usacycling.org/api/v1/',
  publicUrl: 'https://permit.usacycling.org',
  auth : {
    clientId: 'USACWEBSITE',
    clientSecret: 'hjVKhk27mp026hjdnjBe5uEt3SwQXB4JOzQ2CSpjZD8S3BOcFRXdS2LNPg0FQ06u',
    urls : {
      tokenUrl : 'oauth/v2/token'
    },
    loginUrl : 'https://myaccount.usacycling.org/login',
    logoutUrl: 'https://laravel-api.usacycling.org/logout',
  },

  // External Links
  adminUrl: 'https://myaccount.usacycling.org',
  membershipUrl: 'https://membership.usacycling.org',
  checkoutUrl: 'https://checkout.usacycling.org',

  // Shopify
  url: 'https://usa-cycling.myshopify.com/api/2024-04/graphql',
  shopifyaccesstoken: 'abd5afa5696f76a48076500d558aff1e',

  // Airbrake
  airbrake: {
    id: 265328,
    key: '1b3a72612a24134e0e44aa3225ec0369'
  },

  // Google Tag Manager ID
  gtmId: 'GTM-5NWW86G',

  // Product SKUs
  disciplineSkus: {
    // Road Racing
    19: {
      permitFee: 'PERMIT-FEE-019',
      permitFeeSeries: 'PERMIT-FEE-0212',
      permitFeeSeries10Plus: 'PERMIT-FEE-039',
      permitLateFee: 'PERMIT-FEE-LATE-019',
      namedInsuredOrganizer: 'ADDL-INS-CERT-12',
      namedInsuredClub: 'ADDL-INS-CERT-23',
      additionalInsured: 'ADDL-INS-CERT-01',

      // Endorsements - use string array?
      endorsementAdditional: 'END-ADDL-INS-CERT-01',
      endorsementCancel: 'END-NOT-CANCEL-01',
      endorsementNonContrib: 'END-PRIM-NON-CONTRIB-01',
      endorsementWaiver: 'END-WOS-01',

      autoInsurance: 'INS-AUTO-01',
      motoInsurance: 'INS-MOTO-10',
      ancillaryInsurance: 'INS-ANC-01-RD',

      // Umbrella Insurance - use string array?
      umbrella99: 'INS-UMB-0509',
      umbrella249: 'INS-UMB-0409',
      umbrella999: 'INS-UMB-0310',
      umbrella4999: 'INS-UMB-0209',
      umbrella5000: 'INS-UMB-0109',
      umbrella10000: 'INS-UMB-0609',

      practiceDays: 'PRAC-DAY-019',
      setupTeardownDays: 'SETUP-TEAR-DAY-0109',
      kidsPermit: 'PERMIT-KIDS-10',

      // Post-Event
      oneDayOnSiteLic: 'PE-1DAY-01',
      oneDayOnlineLic: 'PE-BR1DAY-01',
      minimumRiderSurcharge: 'MIN-RIDER-SUR-01',
      riderSurchargeRoad: 'RIDER-SUR-ROAD-RACING',
      riderSurchargeRoadCriterium: 'RIDER-SUR-0113',
      riderSurchargeRoadHillClimb: 'RIDER-SUR-0114',
      riderSurchargeRoadOther: 'RIDER-SUR-0115',
      riderSurchargeRoadCircuitRace: 'RIDER-SUR-0116',
      riderSurchargeRoadTimeTrial: 'RIDER-SUR-0117'
    },
    // Track Racing
    20: {
      permitFee: 'PERMIT-FEE-0110',
      permitFeeSeries: 'PERMIT-FEE-0210',
      permitFeeSeries10Plus: 'PERMIT-FEE-0310',
      permitLateFee: 'PERMIT-FEE-LATE-0110',
      namedInsuredClub: 'ADDL-INS-CERT-25',
      namedInsuredOrganizer: 'ADDL-INS-CERT-14',
      additionalInsured: 'ADDL-INS-CERT-03',

      // Endorsements - use string array?
      endorsementAdditional: 'END-ADDL-INS-CERT-03',
      endorsementCancel: 'END-NOT-CANCEL-03',
      endorsementNonContrib: 'END-PRIM-NON-CONTRIB-03',
      endorsementWaiver: 'END-WOS-03',

      autoInsurance: 'INS-AUTO-03',
      motoInsurance: 'INS-MOTO-11',
      ancillaryInsurance: 'INS-ANC-03-TK',

      // Umbrella Insurance - use string array?
      umbrella99: 'INS-UMB-0510',
      umbrella249: 'INS-UMB-0410',
      umbrella999: 'INS-UMB-0311',
      umbrella4999: 'INS-UMB-0210',
      umbrella5000: 'INS-UMB-0110',
      umbrella10000: 'INS-UMB-0610',

      practiceDays: 'PRAC-DAY-0110',
      setupTeardownDays: 'SETUP-TEAR-DAY-0110',
      kidsPermit: 'PERMIT-KIDS-11',

      // Post-Event
      oneDayOnSiteLic: 'PE-1DAY-03',
      oneDayOnlineLic: 'PE-BR1DAY-03',
      minimumRiderSurcharge: 'MIN-RIDER-SUR-03',
      riderSurchargeTrack: 'RIDER-SUR-TRACK-RACING',
      riderSurchargeTrackDernyRace: 'RIDER-SUR-0118',
      riderSurchargeTrackElimination: 'RIDER-SUR-0119',
      riderSurchargeTrackHandicap: 'RIDER-SUR-0120',
      riderSurchargeTrackInternationalOmnium: 'RIDER-SUR-0121',
      riderSurchargeTrackItalian: 'RIDER-SUR-0122',
      riderSurchargeTrackKeirin: 'RIDER-SUR-0123',
      riderSurchargeTrackLongestLap: 'RIDER-SUR-0124',
      riderSurchargeTrackMadison: 'RIDER-SUR-0125',
      riderSurchargeTrackMatchSprint: 'RIDER-SUR-0126',
      riderSurchargeTrackMissNOut: 'RIDER-SUR-0127',
      riderSurchargeTrackMotorpacedScratch: 'RIDER-SUR-0128',
      riderSurchargeTrackOmnium: 'RIDER-SUR-0129',
      riderSurchargeTrackPointALap: 'RIDER-SUR-0130',
      riderSurchargeTrackPointsRace: 'RIDER-SUR-0131',
      riderSurchargeTrackPursuit: 'RIDER-SUR-0132',
      riderSurchargeTrackScratchRace: 'RIDER-SUR-0133',
      riderSurchargeTrackSnowball: 'RIDER-SUR-0134',
      riderSurchargeTrackTeamPursuit: 'RIDER-SUR-0135',
      riderSurchargeTrackTeamSprint: 'RIDER-SUR-0136',
      riderSurchargeTrackTempo: 'RIDER-SUR-0137',
      riderSurchargeTrackTimeTrial: 'RIDER-SUR-0138',
      riderSurchargeTrackUnknownDistance: 'RIDER-SUR-0139',
      riderSurchargeTrackWinNOut: 'RIDER-SUR-0140'
    },
    // Mountain Bike Racing
    21: {
      permitFee: 'PERMIT-FEE-018',
      permitFeeSeries: 'PERMIT-FEE-029',
      permitFeeSeries10Plus: 'PERMIT-FEE-038',
      permitLateFee: 'PERMIT-FEE-LATE-018',
      namedInsuredClub: 'ADDL-INS-CERT-24',
      namedInsuredOrganizer: 'ADDL-INS-CERT-13',
      additionalInsured: 'ADDL-INS-CERT-02',

      // Endorsements - use string array?
      endorsementAdditional: 'END-ADDL-INS-CERT-02',
      endorsementCancel: 'END-NOT-CANCEL-02',
      endorsementNonContrib: 'END-PRIM-NON-CONTRIB-02',
      endorsementWaiver: 'END-WOS-02',

      autoInsurance: 'INS-AUTO-02',
      motoInsurance: 'INS-MOTO-09',
      ancillaryInsurance: 'INS-ANC-02-MB',

      // Umbrella Insurance - use string array?
      umbrella99: 'INS-UMB-0508',
      umbrella249: 'INS-UMB-0408',
      umbrella999: 'INS-UMB-0309',
      umbrella4999: 'INS-UMB-0208',
      umbrella5000: 'INS-UMB-0108',
      umbrella10000: 'INS-UMB-0608',

      practiceDays: 'PRAC-DAY-018',
      setupTeardownDays: 'SETUP-TEAR-DAY-0108',
      kidsPermit: 'PERMIT-KIDS-09',

      // Post-Event
      oneDayOnSiteLic: 'PE-1DAY-02',
      oneDayOnlineLic: 'PE-BR1DAY-02',
      minimumRiderSurcharge: 'MIN-RIDER-SUR-02',
      riderSurchargeMountain: 'RIDER-SUR-MOUNTAIN-BIKE-RACING',
      riderSurchargeMountain4Cross: 'RIDER-SUR-0103',
      riderSurchargeMountainCrossCountry: 'RIDER-SUR-0104',
      riderSurchargeMountainDownhill: 'RIDER-SUR-0105',
      riderSurchargeMountainDualSlalom: 'RIDER-SUR-0106',
      riderSurchargeMountainEliminator: 'RIDER-SUR-0107',
      riderSurchargeMountainEnduro: 'RIDER-SUR-0108',
      riderSurchargeMountainHillClimb: 'RIDER-SUR-0109',
      riderSurchargeMountainOther: 'RIDER-SUR-0110',
      riderSurchargeMountainTimeTrial: 'RIDER-SUR-0111',
      riderSurchargeMountainTrials: 'RIDER-SUR-0112'
    },
    // BMX Racing
    22: {
      permitFee: 'PERMIT-FEE-011',
      permitFeeSeries: 'PERMIT-FEE-022',
      permitFeeSeries10Plus: 'PERMIT-FEE-031',
      permitLateFee: 'PERMIT-FEE-LATE-011',
      namedInsuredClub: 'ADDL-INS-CERT-28',
      namedInsuredOrganizer: 'ADDL-INS-CERT-16',
      additionalInsured: 'ADDL-INS-CERT-120',

      // Endorsements - use string array?
      endorsementAdditional: 'END-ADDL-INS-CERT-12',
      endorsementCancel: 'END-NOT-CANCEL-12',
      endorsementNonContrib: 'END-PRIM-NON-CONTRIB-12',
      endorsementWaiver: 'END-WOS-12',

      autoInsurance: 'INS-AUTO-12',
      motoInsurance: 'INS-MOTO-02',
      ancillaryInsurance: 'INS-ANC-12-BR',

      // Umbrella Insurance - use string array?
      umbrella99: 'INS-UMB-0501',
      umbrella249: 'INS-UMB-0401',
      umbrella999: 'INS-UMB-0301',
      umbrella4999: 'INS-UMB-0201',
      umbrella5000: 'INS-UMB-0101',
      umbrella10000: 'INS-UMB-0601',

      practiceDays: 'PRAC-DAY-011',
      setupTeardownDays: 'SETUP-TEAR-DAY-0101',
      kidsPermit: 'PERMIT-KIDS-02',

      // Post-Event
      oneDayOnSiteLic: 'PE-1DAY-12',
      oneDayOnlineLic: 'PE-BR1DAY-12',
      minimumRiderSurcharge: 'MIN-RIDER-SUR-12',
      riderSurchargeBMX: 'RIDER-SUR-BMX-RACING',
      riderSurcharge: 'RIDER-SUR-0101'
    },
    // Cyclocross Racing
    23: {
      permitFee: 'PERMIT-FEE-014',
      permitFeeSeries: 'PERMIT-FEE-025',
      permitFeeSeries10Plus: 'PERMIT-FEE-034',
      permitLateFee: 'PERMIT-FEE-LATE-014',
      namedInsuredClub: 'ADDL-INS-CERT-26',
      namedInsuredOrganizer: 'ADDL-INS-CERT-15',
      additionalInsured: 'ADDL-INS-CERT-04',

      // Endorsements - use string array?
      endorsementAdditional: 'END-ADDL-INS-CERT-04',
      endorsementCancel: 'END-NOT-CANCEL-04',
      endorsementNonContrib: 'END-PRIM-NON-CONTRIB-04',
      endorsementWaiver: 'END-WOS-04',

      autoInsurance: 'INS-AUTO-04',
      motoInsurance: 'INS-MOTO-05',
      ancillaryInsurance: 'INS-ANC-04-CX',

      // Umbrella Insurance - use string array?
      umbrella99: 'INS-UMB-0504',
      umbrella249: 'INS-UMB-0404',
      umbrella999: 'INS-UMB-0304',
      umbrella4999: 'INS-UMB-0204',
      umbrella5000: 'INS-UMB-0104',
      umbrella10000: 'INS-UMB-0604',

      practiceDays: 'PRAC-DAY-014',
      setupTeardownDays: 'SETUP-TEAR-DAY-0104',
      kidsPermit: 'PERMIT-KIDS-05',

      // Post-Event
      oneDayOnSiteLic: 'PE-1DAY-04',
      oneDayOnlineLic: 'PE-BR1DAY-04',
      minimumRiderSurcharge: 'MIN-RIDER-SUR-04',
      riderSurchargeCyclocross: 'RIDER-SUR-CYCLOCROSS-RACING',
      riderSurcharge: 'RIDER-SUR-0102'
    },
    // Gran Fondo
    24: {
      permitFee: 'PERMIT-FEE-016',
      permitFeeSeries: 'PERMIT-FEE-027',
      permitFeeSeries10Plus: 'PERMIT-FEE-036',
      permitLateFee: 'PERMIT-FEE-LATE-016',
      namedInsuredClub: 'ADDL-INS-CERT-29',
      namedInsuredOrganizer: 'ADDL-INS-CERT-18',
      additionalInsured: 'ADDL-INS-CERT-07',

      // Endorsements - use string array?
      endorsementAdditional: 'END-ADDL-INS-CERT-07',
      endorsementCancel: 'END-NOT-CANCEL-07',
      endorsementNonContrib: 'END-PRIM-NON-CONTRIB-07',
      endorsementWaiver: 'END-WOS-07',

      autoInsurance: 'INS-AUTO-07',
      motoInsurance: 'INS-MOTO-07',
      ancillaryInsurance: 'INS-ANC-07-GF',

      // Umbrella Insurance - use string array?
      umbrella99: 'INS-UMB-0506',
      umbrella249: 'INS-UMB-0406',
      umbrella999: 'INS-UMB-0307',
      umbrella4999: 'INS-UMB-0206',
      umbrella5000: 'INS-UMB-0106',
      umbrella10000: 'INS-UMB-0606',

      practiceDays: 'PRAC-DAY-016',
      setupTeardownDays: 'SETUP-TEAR-DAY-0106',
      kidsPermit: 'PERMIT-KIDS-07',

      // Post-Event
      oneDayOnSiteLic: 'PE-1DAY-07',
      oneDayOnlineLic: 'PE-BR1DAY-07',
      minimumRiderSurcharge: 'MIN-RIDER-SUR-07',
      riderSurchargeGranFondo: 'RIDER-SUR-GRAN-FONDO',
      riderSurcharge: 'RIDER-SUR-0149'
    },
    // Gravel
    25: {
      permitFee: 'PERMIT-FEE-017',
      permitFeeSeries: 'PERMIT-FEE-028',
      permitFeeSeries10Plus: 'PERMIT-FEE-037',
      permitLateFee: 'PERMIT-FEE-LATE-017',
      namedInsuredClub: 'ADDL-INS-CERT-020',
      namedInsuredOrganizer: 'ADDL-INS-CERT-17',
      additionalInsured: 'ADDL-INS-CERT-06',

      // Endorsements - use string array?
      endorsementAdditional: 'END-ADDL-INS-CERT-06',
      endorsementCancel: 'END-NOT-CANCEL-06',
      endorsementNonContrib: 'END-PRIM-NON-CONTRIB-06',
      endorsementWaiver: 'END-WOS-06',

      autoInsurance: 'INS-AUTO-06',
      motoInsurance: 'INS-MOTO-08',
      ancillaryInsurance: 'INS-ANC-06-GV',

      // Umbrella Insurance - use string array?
      umbrella99: 'INS-UMB-0507',
      umbrella249: 'INS-UMB-0407',
      umbrella999: 'INS-UMB-0308',
      umbrella4999: 'INS-UMB-0207',
      umbrella5000: 'INS-UMB-0107',
      umbrella10000: 'INS-UMB-0607',

      practiceDays: 'PRAC-DAY-017',
      setupTeardownDays: 'SETUP-TEAR-DAY-0107',
      kidsPermit: 'PERMIT-KIDS-08',

      // Post-Event
      oneDayOnSiteLic: 'PE-1DAY-06',
      oneDayOnlineLic: 'PE-BR1DAY-06',
      minimumRiderSurcharge: 'MIN-RIDER-SUR-06',
      riderSurchargeGravel: 'RIDER-SUR-GRAVEL',
      riderSurcharge: 'RIDER-SUR-0141'
    },
    // Camp
    26: {
      permitFee: 'PERMIT-FEE-012',
      permitFeeSeries: 'PERMIT-FEE-023',
      permitFeeSeries10Plus: 'PERMIT-FEE-032',
      permitLateFee: 'PERMIT-FEE-LATE-012',
      namedInsuredClub: 'ADDL-INS-CERT-32',
      namedInsuredOrganizer: 'ADDL-INS-CERT-21',
      additionalInsured: 'ADDL-INS-CERT-10',

      // Endorsements - use string array?
      endorsementAdditional: 'END-ADDL-INS-CERT-10',
      endorsementCancel: 'END-NOT-CANCEL-10',
      endorsementNonContrib: 'END-PRIM-NON-CONTRIB-10',
      endorsementWaiver: 'END-WOS-10',

      autoInsurance: 'INS-AUTO-10',
      motoInsurance: 'INS-MOTO-03',
      ancillaryInsurance: 'INS-ANC-10-CA',

      // Umbrella Insurance - use string array?
      umbrella99: 'INS-UMB-0502',
      umbrella249: 'INS-UMB-0402',
      umbrella999: 'INS-UMB-0302',
      umbrella4999: 'INS-UMB-0202',
      umbrella5000: 'INS-UMB-0102',
      umbrella10000: 'INS-UMB-0602',

      practiceDays: 'PRAC-DAY-012',
      setupTeardownDays: 'SETUP-TEAR-DAY-0102',
      kidsPermit: 'PERMIT-KIDS-03',

      // Post-Event
      oneDayOnSiteLic: 'PE-1DAY-10',
      oneDayOnlineLic: 'PE-BR1DAY-10',
      minimumRiderSurcharge: 'MIN-RIDER-SUR-10',
      riderSurchargeCamp: 'RIDER-SUR-CAMP',
      riderSurchargeCampBmxFree: 'RIDER-SUR-0142',
      riderSurchargeCampBmxRace: 'RIDER-SUR-0143',
      riderSurchargeCampCx: 'RIDER-SUR-0144',
      riderSurchargeCampGravel: 'RIDER-SUR-0145',
      riderSurchargeCampMtn: 'RIDER-SUR-0146',
      riderSurchargeCampRoad: 'RIDER-SUR-0147',
      riderSurchargeCampTrack: 'RIDER-SUR-0148',
    },
    // Fun Rides
    27: {
      permitFee: 'PERMIT-FEE-015',
      permitFeeSeries: 'PERMIT-FEE-026',
      permitFeeSeries10Plus: 'PERMIT-FEE-035',
      permitLateFee: 'PERMIT-FEE-LATE-015',
      namedInsuredClub: 'ADDL-INS-CERT-30',
      namedInsuredOrganizer: 'ADDL-INS-CERT-19',
      additionalInsured: 'ADDL-INS-CERT-08',

      // Endorsements - use string array?
      endorsementAdditional: 'END-ADDL-INS-CERT-08',
      endorsementCancel: 'END-NOT-CANCEL-08',
      endorsementNonContrib: 'END-PRIM-NON-CONTRIB-08',
      endorsementWaiver: 'END-WOS-08',

      autoInsurance: 'INS-AUTO-08',
      motoInsurance: 'INS-MOTO-06',
      ancillaryInsurance: 'INS-ANC-08-FR',

      // Umbrella Insurance - use string array?
      umbrella99: 'INS-UMB-0505',
      umbrella249: 'INS-UMB-0405',
      umbrella999: 'INS-UMB-0305',
      umbrella4999: 'INS-UMB-0205',
      umbrella5000: 'INS-UMB-0105',
      umbrella10000: 'INS-UMB-0605',

      practiceDays: 'PRAC-DAY-015',
      setupTeardownDays: 'SETUP-TEAR-DAY-0105',
      kidsPermit: 'PERMIT-KIDS-06',

      // Post-Event
      oneDayOnSiteLic: 'PE-1DAY-08',
      oneDayOnlineLic: 'PE-BR1DAY-08',
      minimumRiderSurcharge: 'MIN-RIDER-SUR-08',
      riderSurchargeFunRides: 'RIDER-SUR-FUN-RIDES',
      riderSurchargeFunBmxFreestyle: 'RIDER-SUR-0400',
      riderSurchargeFunBmxRace: 'RIDER-SUR-0401',
      riderSurchargeFunCxRace: 'RIDER-SUR-0402',
      riderSurchargeFunGravelGrinder: 'RIDER-SUR-0403',
      riderSurchargeFunMtb: 'RIDER-SUR-0404',
      riderSurchargeFunRoad: 'RIDER-SUR-0405',
      riderSurchargeFunTrack: 'RIDER-SUR-0406'
    },
    // Clinic
    28: {
      permitFee: 'PERMIT-FEE-013',
      permitFeeSeries: 'PERMIT-FEE-024',
      permitFeeSeries10Plus: 'PERMIT-FEE-033',
      permitLateFee: 'PERMIT-FEE-LATE-013',
      namedInsuredClub: 'ADDL-INS-CERT-33',
      namedInsuredOrganizer: 'ADDL-INS-CERT-22',
      additionalInsured: 'ADDL-INS-CERT-11',

      // Endorsements - use string array?
      endorsementAdditional: 'END-ADDL-INS-CERT-11',
      endorsementCancel: 'END-NOT-CANCEL-11',
      endorsementNonContrib: 'END-PRIM-NON-CONTRIB-11',
      endorsementWaiver: 'END-WOS-11',

      autoInsurance: 'INS-AUTO-11',
      motoInsurance: 'INS-MOTO-04',
      ancillaryInsurance: 'INS-ANC-11-CL',

      // Umbrella Insurance - use string array?
      umbrella99: 'INS-UMB-0503',
      umbrella249: 'INS-UMB-0403',
      umbrella999: 'INS-UMB-0303',
      umbrella4999: 'INS-UMB-0203',
      umbrella5000: 'INS-UMB-0103',
      umbrella10000: 'INS-UMB-0603',

      practiceDays: 'PRAC-DAY-013',
      setupTeardownDays: 'SETUP-TEAR-DAY-0103',
      kidsPermit: 'PERMIT-KIDS-04',

      // Post-Event
      oneDayOnSiteLic: 'PE-1DAY-11',
      oneDayOnlineLic: 'PE-BR1DAY-11',
      minimumRiderSurcharge: 'MIN-RIDER-SUR-11',
      riderSurchargeClinic: 'RIDER-SUR-CLINIC',
      riderSurchargeClinicBmxFreestyle: 'RIDER-SUR-0300',
      riderSurchargeClinicBmxRace: 'RIDER-SUR-0301',
      riderSurchargeClinicCxRace: 'RIDER-SUR-0302',
      riderSurchargeClinicGravelGrinder: 'RIDER-SUR-0303',
      riderSurchargeClinicMtb: 'RIDER-SUR-0304',
      riderSurchargeClinicRoad: 'RIDER-SUR-0305',
      riderSurchargeClinicTrack: 'RIDER-SUR-0306'
    },
    // Training Rides
    29: {
      permitFee: 'PERMIT-FEE-0111',
      permitFeeSeries: 'PERMIT-FEE-0211',
      permitFeeSeries10Plus: 'PERMIT-FEE-0311',
      permitLateFee: 'PERMIT-FEE-LATE-0111',
      namedInsuredClub: 'ADDL-INS-CERT-31',
      namedInsuredOrganizer: 'ADDL-INS-CERT-20',
      additionalInsured: 'ADDL-INS-CERT-09',

      // Endorsements - use string array?
      endorsementAdditional: 'END-ADDL-INS-CERT-09',
      endorsementCancel: 'END-NOT-CANCEL-09',
      endorsementNonContrib: 'END-PRIM-NON-CONTRIB-09',
      endorsementWaiver: 'END-WOS-09',

      autoInsurance: 'INS-AUTO-09',
      motoInsurance: 'INS-MOTO-12',
      ancillaryInsurance: 'INS-ANC-09-TR',

      // Umbrella Insurance - use string array?
      umbrella99: 'INS-UMB-0511',
      umbrella249: 'INS-UMB-0411',
      umbrella999: 'INS-UMB-0312',
      umbrella4999: 'INS-UMB-0211',
      umbrella5000: 'INS-UMB-0111',
      umbrella10000: 'INS-UMB-0611',

      practiceDays: 'PRAC-DAY-0111',
      setupTeardownDays: 'SETUP-TEAR-DAY-0111',
      kidsPermit: 'PERMIT-KIDS-12',

      // Post-Event
      oneDayOnSiteLic: 'PE-1DAY-09',
      oneDayOnlineLic: 'PE-BR1DAY-09',
      minimumRiderSurcharge: 'MIN-RIDER-SUR-09',
      riderSurchargeTraining: 'RIDER-SUR-TRAINING-RIDES',
      riderSurchargeTrainingBmxFreestyle: 'RIDER-SUR-0407',
      riderSurchargeTrainingBmxRace: 'RIDER-SUR-0408',
      riderSurchargeTrainingCxRace: 'RIDER-SUR-0409',
      riderSurchargeTrainingGravelGrinder: 'RIDER-SUR-0410',
      riderSurchargeTrainingMtb: 'RIDER-SUR-0411',
      riderSurchargeTrainingRoad: 'RIDER-SUR-0412',
      riderSurchargeTrainingTrack: 'RIDER-SUR-0413'
    },
    // BMX Freestyle
    30: {
      permitFee: 'PERMIT-FEE-010',
      permitFeeSeries: 'PERMIT-FEE-021',
      permitFeeSeries10Plus: 'PERMIT-FEE-030',
      permitLateFee: 'PERMIT-FEE-LATE-010',
      namedInsuredClub: 'ADDL-INS-CERT-27',
      namedInsuredOrganizer: 'ADDL-INS-CERT-010',
      additionalInsured: 'ADDL-INS-CERT-05',

      // Endorsements - use string array?
      endorsementAdditional: 'END-ADDL-INS-CERT-05',
      endorsementCancel: 'END-NOT-CANCEL-05',
      endorsementNonContrib: 'END-PRIM-NON-CONTRIB-05',
      endorsementWaiver: 'END-WOS-05',

      autoInsurance: 'INS-AUTO-05',
      motoInsurance: 'INS-MOTO-01',
      ancillaryInsurance: 'INS-ANC-05-BF',

      // Umbrella Insurance - use string array?
      umbrella99: 'INS-UMB-0500',
      umbrella249: 'INS-UMB-0400',
      umbrella999: 'INS-UMB-0300',
      umbrella4999: 'INS-UMB-0200',
      umbrella5000: 'INS-UMB-0100',
      umbrella10000: 'INS-UMB-0600',

      practiceDays: 'PRAC-DAY-010',
      setupTeardownDays: 'SETUP-TEAR-DAY-0100',
      kidsPermit: 'PERMIT-KIDS-01',

      // Post-Event
      oneDayOnSiteLic: 'PE-1DAY-05',
      oneDayOnlineLic: 'PE-BR1DAY-05',
      minimumRiderSurcharge: 'MIN-RIDER-SUR-05',
      riderSurchargeBmxFreestyle: 'RIDER-SUR-BMX-FREESTYLE',
      riderSurcharge: 'RIDER-SUR-0100'
    }
  },

  permitDefaultSku: 'PERMIT-FEE-0212',
  // permitSku2: 'PERMIT-FEE-02',
  certificateDefaultSku: 'ADDL-INS-CERT-01',
  endorsementDefaultSku: 'END-ADDL-INS-CERT-12',
  autoDefaultSku: 'INS-AUTO-05',
  motoDefaultSku: 'INS-MOTO-01',
  ancillaryDefaultSku: 'INS-ANC-05-BF',
  setupTeardownDefaultSku: 'SETUP-TEAR-DAY-0100',
  practiceDefaultSku: 'PRAC-DAY-010',
  kidsPermitDefaultSku: 'PERMIT-KIDS-01',

  // Race Director License
  licenseDefaultSku: 'LIC-RD-01',

  // Post-Event Memberships
  postEventMembershipStandard: 'PEM-STD',
  postEventMembershipPremium: 'PEM-RACE',
  postEventMembershipCollegiate: 'PEM-RACE-COL',
  postEventMembershipJunior: 'PEM-RACE-JUN',

  // Special Calendar Discount Codes
  uciDiscount: ['UCI-PERM-DISC100'],
  natChampDiscount: ['20NatChamps'],
  natDiscount: ['20PRT', '20PROCX', '20ATC', '20PROXCT', '20PROGRT'],
  natTeamDiscount: ['NatTeamCamp20']
};
