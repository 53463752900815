import { OnInit, EventEmitter } from '@angular/core';
import { FormFieldErrors } from '../../classes';
var noop = function () { };
var ɵ0 = noop;
var RadioSwitchComponent = /** @class */ (function () {
    function RadioSwitchComponent() {
        this.options = [];
        this.onTouchedCallback = noop;
        this.onChangeCallback = noop;
        this.change = new EventEmitter();
        this.valueChange = new EventEmitter();
    }
    Object.defineProperty(RadioSwitchComponent.prototype, "value", {
        get: function () {
            return this.currentValue;
        },
        set: function (val) {
            this.currentValue = val;
            this.change.emit(this.currentValue);
            this.valueChange.emit(this.currentValue);
            this.onChangeCallback(this.currentValue);
        },
        enumerable: true,
        configurable: true
    });
    RadioSwitchComponent.prototype.writeValue = function (value) {
        this.currentValue = value;
        this.onChangeCallback(value);
        for (var _i = 0, _a = this.options; _i < _a.length; _i++) {
            var option = _a[_i];
            if (this.currentValue === option.value) {
                option.checked = true;
            }
        }
    };
    // From ControlValueAccessor interface
    RadioSwitchComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    // From ControlValueAccessor interface
    RadioSwitchComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    RadioSwitchComponent.prototype.ngOnInit = function () {
        for (var _i = 0, _a = this.options; _i < _a.length; _i++) {
            var option = _a[_i];
            if (this.value === option.value) {
                option.checked = true;
            }
        }
    };
    RadioSwitchComponent.prototype.onRadioChange = function (event) {
        if (event && event.target) {
            for (var _i = 0, _a = this.options; _i < _a.length; _i++) {
                var option = _a[_i];
                if (option.value === event.target.value) {
                    option.checked = event.target.checked ? true : undefined;
                }
            }
            this.value = event.target.value;
        }
    };
    return RadioSwitchComponent;
}());
export { RadioSwitchComponent };
export { ɵ0 };
