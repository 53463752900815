/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./datepicker.component";
import * as i5 from "../../providers/dateFormatter";
import * as i6 from "../../providers/dateAdapter";
var styles_DatepickerComponent = [];
var RenderType_DatepickerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DatepickerComponent, data: {} });
export { RenderType_DatepickerComponent as RenderType_DatepickerComponent };
function View_DatepickerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "required"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["*"]))], null, null); }
function View_DatepickerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltipContent; _ck(_v, 0, 0, currVal_0); }); }
function View_DatepickerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 2, "span", [], [[8, "className", 0]], null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.NgbTooltip, [i0.ElementRef, i0.Renderer2, i0.Injector, i0.ComponentFactoryResolver, i0.ViewContainerRef, i1.NgbTooltipConfig, i0.NgZone, i2.DOCUMENT, i0.ChangeDetectorRef, i0.ApplicationRef], { ngbTooltip: [0, "ngbTooltip"] }, null), (_l()(), i0.ɵand(0, [["tipContent", 2]], null, 0, null, View_DatepickerComponent_3)), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.tooltipContent; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "icon ", _co.iconClass, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_DatepickerComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Required"]))], null, null); }
function View_DatepickerComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Date must be within range"]))], null, null); }
function View_DatepickerComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "invalid-feedback"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DatepickerComponent_5)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DatepickerComponent_6)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors.required; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.errors.dateRange; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_DatepickerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 23, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DatepickerComponent_1)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DatepickerComponent_2)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 14, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 16777216, null, null, 11, "input", [["class", "form-control"], ["ngbDatepicker", ""], ["onkeydown", "return false"]], [[8, "placeholder", 0], [8, "disabled", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 12).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 12)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 12)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i0.ɵnov(_v, 14).manualDateChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("change" === en)) {
        var pd_5 = (i0.ɵnov(_v, 14).manualDateChange($event.target.value, true) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i0.ɵnov(_v, 14).onBlur() !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.value = $event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(10, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(11, { "is-invalid": 0 }), i0.ɵdid(12, 16384, null, 0, i3.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(512, null, i1.ɵu, i1.ɵu, [i1.NgbCalendar, i1.NgbDatepickerI18n]), i0.ɵdid(14, 671744, [["d", 4]], 0, i1.NgbInputDatepicker, [i1.NgbDateParserFormatter, i0.ElementRef, i0.ViewContainerRef, i0.Renderer2, i0.ComponentFactoryResolver, i0.NgZone, i1.ɵu, i1.NgbCalendar, i1.NgbDateAdapter, i2.DOCUMENT, i0.ChangeDetectorRef], { markDisabled: [0, "markDisabled"], minDate: [1, "minDate"], maxDate: [2, "maxDate"], disabled: [3, "disabled"] }, null), i0.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i1.NgbInputDatepicker]), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i3.DefaultValueAccessor, i1.NgbInputDatepicker]), i0.ɵdid(17, 671744, null, 0, i3.NgModel, [[8, null], [6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { name: [0, "name"], isDisabled: [1, "isDisabled"], model: [2, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(19, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i0.ɵeld(20, 0, null, null, 1, "div", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 0, "button", [["class", "btn btn-outline-secondary calendar"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DatepickerComponent_4)), i0.ɵdid(23, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.required; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.iconClass; _ck(_v, 6, 0, currVal_3); var currVal_13 = "form-control"; var currVal_14 = _ck(_v, 11, 0, (_co.formSubmitted && _co.errors)); _ck(_v, 10, 0, currVal_13, currVal_14); var currVal_15 = _co.markDisabled; var currVal_16 = _co.min; var currVal_17 = _co.max; var currVal_18 = _co.disabled; _ck(_v, 14, 0, currVal_15, currVal_16, currVal_17, currVal_18); var currVal_19 = i0.ɵinlineInterpolate(1, "", _co.name, ""); var currVal_20 = _co.disabled; var currVal_21 = _co.value; _ck(_v, 17, 0, currVal_19, currVal_20, currVal_21); var currVal_23 = (_co.formSubmitted && _co.errors); _ck(_v, 23, 0, currVal_23); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.name, ">"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.label; _ck(_v, 2, 0, currVal_1); var currVal_4 = i0.ɵinlineInterpolate(1, "", _co.placeholder, ""); var currVal_5 = i0.ɵnov(_v, 14).disabled; var currVal_6 = i0.ɵnov(_v, 19).ngClassUntouched; var currVal_7 = i0.ɵnov(_v, 19).ngClassTouched; var currVal_8 = i0.ɵnov(_v, 19).ngClassPristine; var currVal_9 = i0.ɵnov(_v, 19).ngClassDirty; var currVal_10 = i0.ɵnov(_v, 19).ngClassValid; var currVal_11 = i0.ɵnov(_v, 19).ngClassInvalid; var currVal_12 = i0.ɵnov(_v, 19).ngClassPending; _ck(_v, 8, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_22 = _co.disabled; _ck(_v, 21, 0, currVal_22); }); }
export function View_DatepickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-datepicker", [], null, null, null, View_DatepickerComponent_0, RenderType_DatepickerComponent)), i0.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DatepickerComponent]), i0.ɵprd(4608, null, i1.NgbDateParserFormatter, i5.CustomDateFormatter, []), i0.ɵprd(4608, null, i1.NgbDateAdapter, i6.CustomDateAdapter, []), i0.ɵdid(4, 638976, null, 0, i4.DatepickerComponent, [i1.NgbCalendar], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var DatepickerComponentNgFactory = i0.ɵccf("app-datepicker", i4.DatepickerComponent, View_DatepickerComponent_Host_0, { name: "name", label: "label", placeholder: "placeholder", required: "required", formControlName: "formControlName", formSubmitted: "formSubmitted", errors: "errors", minDate: "minDate", maxDate: "maxDate", markDisabled: "markDisabled", oneYearLimit: "oneYearLimit", disabled: "disabled", iconClass: "iconClass", tooltipContent: "tooltipContent", value: "value" }, { select: "select", change: "change", valueChange: "valueChange" }, []);
export { DatepickerComponentNgFactory as DatepickerComponentNgFactory };
