<div class="form-group custom-control custom-radio {{inlineStyle}}">
  <input type="radio"
    class="custom-control-input"
    id="{{id}}"
    name="{{name}}"
    value="{{value}}"
    checked="{{checked}}"
    disabled="{{disabled}}"
  />
  <label class="custom-control-label" for="{{id}}">{{label}}</label>
</div>