/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../atoms/loader/loader.component.ngfactory";
import * as i2 from "../../atoms/loader/loader.component";
import * as i3 from "../../molecules/payment-accordion/payment-accordion.component.ngfactory";
import * as i4 from "../../molecules/payment-accordion/payment-accordion.component";
import * as i5 from "../../services/event_day.service";
import * as i6 from "../../shared/services/shopify.service";
import * as i7 from "@angular/common";
import * as i8 from "./overview-payments.component";
var styles_OverviewPaymentsComponent = [];
var RenderType_OverviewPaymentsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OverviewPaymentsComponent, data: {} });
export { RenderType_OverviewPaymentsComponent as RenderType_OverviewPaymentsComponent };
function View_OverviewPaymentsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], { small: [0, "small"], align: [1, "align"] }, null)], function (_ck, _v) { var currVal_0 = true; var currVal_1 = "left"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_OverviewPaymentsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["There are no payments for this event."]))], null, null); }
function View_OverviewPaymentsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-payment-accordion", [], null, [[null, "deleteDraftOrder"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deleteDraftOrder" === en)) {
        var pd_0 = (_co.onDraftOrderDelete($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_PaymentAccordionComponent_0, i3.RenderType_PaymentAccordionComponent)), i0.ɵdid(2, 114688, null, 0, i4.PaymentAccordionComponent, [i5.EventDayService, i6.ShopifyService], { event: [0, "event"], draftOrder: [1, "draftOrder"], userRole: [2, "userRole"] }, { deleteDraftOrder: "deleteDraftOrder" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.userRole; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_OverviewPaymentsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-payment-accordion", [], null, null, null, i3.View_PaymentAccordionComponent_0, i3.RenderType_PaymentAccordionComponent)), i0.ɵdid(2, 114688, null, 0, i4.PaymentAccordionComponent, [i5.EventDayService, i6.ShopifyService], { event: [0, "event"], order: [1, "order"], userRole: [2, "userRole"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.userRole; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_OverviewPaymentsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [["class", "mt-3 small"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Payment statuses may take approximately 5 minutes to update after payments are made."])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverviewPaymentsComponent_4)), i0.ɵdid(5, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverviewPaymentsComponent_5)), i0.ɵdid(7, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.draftOrders; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.orders; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_OverviewPaymentsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Payments"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverviewPaymentsComponent_1)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverviewPaymentsComponent_2)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverviewPaymentsComponent_3)), i0.ɵdid(7, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 3, 0, currVal_0); var currVal_1 = ((!_co.loading && !_co.draftOrders.length) && !_co.orders.length); _ck(_v, 5, 0, currVal_1); var currVal_2 = (!_co.loading && (_co.draftOrders.length || _co.orders.length)); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_OverviewPaymentsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-overview-payments", [], null, null, null, View_OverviewPaymentsComponent_0, RenderType_OverviewPaymentsComponent)), i0.ɵdid(1, 638976, null, 0, i8.OverviewPaymentsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OverviewPaymentsComponentNgFactory = i0.ɵccf("app-overview-payments", i8.OverviewPaymentsComponent, View_OverviewPaymentsComponent_Host_0, { event: "event", userRole: "userRole", draftOrders: "draftOrders", orders: "orders" }, {}, []);
export { OverviewPaymentsComponentNgFactory as OverviewPaymentsComponentNgFactory };
