import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import qs from 'qs';
import { Event, EventDate, SelectOption } from '../classes';
import { BaseService } from './base-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
var EventService = /** @class */ (function (_super) {
    tslib_1.__extends(EventService, _super);
    function EventService(http, cookie) {
        var _this = _super.call(this, http, cookie) || this;
        _this.http = http;
        _this.cookie = cookie;
        _this.eventTypes = [];
        _this.disciplines = [];
        _this.event$ = new BehaviorSubject(null);
        _this.eventQueue$ = new BehaviorSubject(null);
        _this._load();
        _this.allowedQueueParams = [
            'no_paginate',
            'page',
            'city',
            'states',
            'disciplines',
            'name',
            'event_start_date',
            'event_end_date',
            'listing_type',
            'status',
            'regions',
            'permit_date_range',
            'order_number',
            'post_event_status',
            'calendar_label_ids'
        ];
        _this.statusOptions = [
            new SelectOption('listing-in-progress', 'Listing In Progress'),
            new SelectOption('listing-in-review', 'Listing In Review'),
            new SelectOption('listing-approved', 'Listing Approved'),
            new SelectOption('permit-in-progress', 'Permit In Progress'),
            new SelectOption('permit-in-review', 'Permit In Review'),
            new SelectOption('permit-approved', 'Permit Approved'),
            new SelectOption('in-post-event', 'In Post Event'),
            new SelectOption('closed', 'Closed'),
            new SelectOption('unknown', 'Unknown')
        ];
        _this.queueStatusOptions = [
            new SelectOption('listing-in-progress', 'Listing In Progress'),
            new SelectOption('listing-in-review', 'Listing In Review'),
            new SelectOption('listing-approved', 'Listing Approved'),
            new SelectOption('permit-in-progress', 'Permit In Progress'),
            new SelectOption('permit-in-review', 'Permit In Review'),
            new SelectOption('permit-approved', 'Permit Approved'),
            new SelectOption('in-post-event', 'In Post Event'),
        ];
        _this.listingStatusOptions = [
            new SelectOption('listing-in-progress', 'Listing In Progress'),
            new SelectOption('listing-in-review', 'Listing In Review'),
            new SelectOption('listing-approved', 'Listing Approved'),
            new SelectOption('closed', 'Closed'),
            new SelectOption('unknown', 'Unknown')
        ];
        return _this;
    }
    EventService.prototype.getEventTypes = function () {
        var _this = this;
        return this.http.get(this.url('event/types'))
            .pipe(tap(function (_) { return _this.log('fetched event types'); }), catchError(this.handleError('getEventTypes', [])));
    };
    EventService.prototype.getDisciplines = function () {
        var _this = this;
        return this.http.get(this.url('event/disciplines'))
            .pipe(tap(function (_) { return _this.log('fetched event disciplines'); }), catchError(this.handleError('getDisciplines', [])));
    };
    EventService.prototype.getSpecialCalendars = function (disciplineId) {
        var _this = this;
        return this.http.get(this.url("event/special_calendars/" + disciplineId))
            .pipe(tap(function (_) { return _this.log('fetched special calendar options'); }), catchError(this.handleError('getSpecialCalendars', [])));
    };
    EventService.prototype.cleanQueueParams = function (params) {
        var _this = this;
        var eventQueueParams = {};
        if (params.event_discipline && typeof params.event_discipline === 'string' && params.event_discipline.trim() !== '') {
            Object.assign(params, {
                disciplines: [params.event_discipline]
            });
        }
        if (params.event_discipline && Array.isArray(params.event_discipline)) {
            Object.assign(params, {
                disciplines: params.event_discipline.map(function (discipline) { return discipline.value || discipline; })
            });
        }
        if (params.regions && Array.isArray(params.regions)) {
            Object.assign(params, {
                regions: params.regions.map(function (region) { return region.value || region; })
            });
        }
        if (params.calendar_label_ids && Array.isArray(params.calendar_label_ids)) {
            Object.assign(params, {
                calendar_label_ids: params.calendar_label_ids.map(function (labelId) { return labelId.value || labelId; })
            });
        }
        if (params.permitCreateStartDate || params.permitCreateEndDate) {
            var permitDateRange = {};
            if (params.permitCreateStartDate.trim() !== '') {
                Object.assign(permitDateRange, {
                    start_date: params.permitCreateStartDate
                });
            }
            if (params.permitCreateEndDate.trim() !== '') {
                Object.assign(permitDateRange, {
                    end_date: params.permitCreateEndDate
                });
            }
            if (Object.keys(permitDateRange).length > 0) {
                Object.assign(params, {
                    permit_date_range: permitDateRange
                });
            }
        }
        Object.keys(params).forEach(function (paramKey) {
            var paramValue = params[paramKey];
            var paramValid = (typeof paramValue !== 'undefined' && paramValue !== null && (typeof paramValue !== 'string' || paramValue.trim() !== '')); /* tslint:disable-line: max-line-length */
            if (_this.allowedQueueParams.includes(paramKey) && paramValid) {
                console.log('include in api query', paramKey, paramValue);
                eventQueueParams[paramKey] = paramValue;
            }
            else {
                console.log('EXCLUDE in api query', paramKey, paramValue);
            }
        });
        return eventQueueParams;
    };
    EventService.prototype.getEventsQueue = function (page) {
        var _this = this;
        if (page === void 0) { page = 1; }
        var queryParams = qs.parse(window.location.search.replace(/^\?/, ''));
        Object.assign(queryParams, { page: page });
        console.log('parse query', queryParams);
        var eventQueueParams = this.cleanQueueParams(queryParams);
        var eventQueueQueryString = qs.stringify(eventQueueParams, {
            addQueryPrefix: true,
            arrayFormat: 'brackets'
        });
        return this.http.get(this.url('events/queue' + eventQueueQueryString), this.options)
            .pipe(tap(function (_) { return _this.log('fetched events queue'); }), catchError(this.handleError('getEventsQueue', { data: [] }, { eventQueueParams: eventQueueParams })));
    };
    EventService.prototype.getEventQueueById = function (id) {
        var _this = this;
        return this.http.get(this.url("events/queue/search/" + id), this.options)
            .pipe(tap(function (_) { return _this.log('fetched event queue by id'); }), catchError(this.handleError('getEventQueueById', null, { id: id })));
    };
    EventService.prototype.getEventsQueueAll = function () {
        var _this = this;
        var queryParams = qs.parse(window.location.search.replace(/^\?/, ''));
        Object.assign(queryParams, { no_paginate: '1' });
        console.log('parse query', queryParams);
        var eventQueueParams = this.cleanQueueParams(queryParams);
        var eventQueueQueryString = qs.stringify(eventQueueParams, {
            addQueryPrefix: true,
            arrayFormat: 'brackets'
        });
        return this.http.get(this.url('events/queue' + eventQueueQueryString), this.options)
            .pipe(tap(function (_) { return _this.log('fetched events queue (all)'); }), catchError(this.handleError('getEventsQueueAll', [], { eventQueueParams: eventQueueParams })));
    };
    EventService.prototype.getEvents = function () {
        var _this = this;
        return this.http.get(this.url('event'))
            .pipe(tap(function (_) { return _this.log('fetched events'); }), catchError(this.handleError('getEvents', [])));
    };
    EventService.prototype.setEventList = function (data) {
        var _this = this;
        if (Object.keys(data).length > 0) {
            this.eventList = [];
            Object.values(data).forEach(function (event) {
                _this.eventList.push(new Event(event));
            });
        }
    };
    EventService.prototype.getEventById = function (id) {
        var _this = this;
        return this.http.get(this.url('event/' + id))
            .pipe(tap(function (event) {
            _this.event$.next(new Event(event));
            _this.log('fetched event by id');
            _this.cookie.delete('event_id', '/');
            _this.cookie.set('event_id', event.event_id.toString(), null, '/');
        }), catchError(this.handleError('getEventById', null, { id: id })));
    };
    EventService.prototype.setEvent = function (data) {
        if (Object.keys(data).length > 0) {
            this.event = new Event(data[0]);
            // console.log('Event: ', this.event);
        }
    };
    EventService.prototype.postEvent = function (listing) {
        var _this = this;
        var event_sponsoring_clubs = 'event_sponsoring_clubs';
        var clubs = this.filterMultiSelectValues(listing[event_sponsoring_clubs]);
        var event_start_date = new Date(listing['event_start_date']); // tslint:disable-line: no-string-literal
        var event_end_date = new Date(listing['event_end_date']); // tslint:disable-line: no-string-literal
        var updatedListing = Object.assign({}, listing, {
            event_sponsoring_clubs: clubs,
            event_start_date: event_start_date,
            event_end_date: event_end_date
        });
        return this.http.post(this.url('event'), updatedListing, this.options)
            .pipe(tap(function (newEvent) {
            _this.log("created event id: " + newEvent.event_id);
            _this.cookie.delete('event_id', '/');
            _this.cookie.set('event_id', newEvent.event_id.toString(), null, '/');
        }), catchError(this.handleError('postEvent', null, { listing: listing, updatedListing: updatedListing })));
    };
    EventService.prototype.updateEvent = function (listing, eventId) {
        var _this = this;
        var event_sponsoring_clubs = 'event_sponsoring_clubs';
        var clubs = this.filterMultiSelectValues(listing[event_sponsoring_clubs]);
        var event_start_date = new Date(listing['event_start_date']); // tslint:disable-line: no-string-literal
        var event_end_date = new Date(listing['event_end_date']); // tslint:disable-line: no-string-literal
        var updatedListing = Object.assign({}, listing, {
            event_sponsoring_clubs: clubs,
            event_start_date: event_start_date,
            event_end_date: event_end_date
        });
        return this.http.put(this.url("event/" + eventId), updatedListing, this.options)
            .pipe(tap(function (resp) {
            _this.log("updated event id: " + resp.event_id);
        }), catchError(this.handleError('updateEvent', null, { eventId: eventId, listing: listing, updatedListing: updatedListing })));
    };
    EventService.prototype.updateEventDiscounts = function (eventId, discountBody) {
        var _this = this;
        if (discountBody === void 0) { discountBody = {}; }
        return this.http.put(this.url("event/" + eventId + "/discounts"), discountBody, this.options)
            .pipe(tap(function () { return _this.log('updated event discounts'); }), catchError(this.handleError('updateEventDiscounts', null, { eventId: eventId, discountBody: discountBody })));
    };
    EventService.prototype.togglePendRaceDirector = function (eventId) {
        var _this = this;
        return this.http.post(this.url("event/" + eventId + "/pend-rd"), [], this.options)
            .pipe(tap(function () { return _this.log('toggle pend race director'); }), catchError(this.handleError('togglePendRaceDirector', null, { eventId: eventId })));
    };
    EventService.prototype.getEventDates = function (eventId) {
        var _this = this;
        return this.http.get(this.url("eventdate/event/" + eventId), this.options)
            .pipe(tap(function (_) { return _this.log('fetched event dates'); }), catchError(this.handleError('getEventDates', [], { eventId: eventId })));
    };
    EventService.prototype.getEventDate = function (eventDateId) {
        var _this = this;
        return this.http.get(this.url("eventdate/" + eventDateId), this.options)
            .pipe(tap(function (_) { return _this.log('fetched event date'); }), catchError(this.handleError('getEventDate')));
    };
    EventService.prototype.postEventDate = function (date, eventId) {
        var _this = this;
        var event_date_id = 'event_date_id';
        var event_date_start = new Date(date.event_date_start);
        // commented out for MBR-2992
        // const subDisciplines = this.filterMultiSelectValues(date.event_date_sub_disciplines);
        var dateWithId = Object.assign({}, date, {
            event_date_event_id: eventId,
            event_date_start: event_date_start,
        });
        return this.http.post(this.url('eventdate'), dateWithId, this.options)
            .pipe(tap(function (_) { return _this.log("created event date id: " + _[event_date_id]); }), catchError(this.handleError('postEventDate', null, { eventId: eventId, date: date, dateWithId: dateWithId })));
    };
    // TODO: Ensure this is submitting correctly - we need API documentation
    EventService.prototype.editEventDate = function (date, id) {
        var _this = this;
        // const subDiscs = date.event_date_sub_disciplines;
        // const subDisciplines = subDiscs !== null ? this.filterMultiSelectValues(date.event_date_sub_disciplines) : subDiscs;
        var event_date_start = new Date(date.event_date_start);
        var dateWithId = Object.assign({}, date, {
            // commented out for MBR-2992
            // event_date_sub_disciplines: subDisciplines,
            event_date_start: event_date_start
        });
        return this.http.put(this.url("eventdate/" + id), dateWithId, this.options)
            .pipe(tap(function (_) { return _this.log("Updated event date id: " + id); }), catchError(this.handleError('editEventDate', new EventDate(), { id: id, date: date, dateWithId: dateWithId })));
    };
    EventService.prototype.deleteEventDate = function (id) {
        var _this = this;
        return this.http.delete(this.url("eventdate/delete/" + id), this.options)
            .pipe(tap(function (_) { return _this.log("Deleted event date id: " + id); }), catchError(this.handleError('deleteEventDate', null, { id: id })));
    };
    EventService.prototype.sortEventDates = function (eventDates) {
        return eventDates.sort(this.compareEventDates);
    };
    EventService.prototype.compareEventDates = function (a, b) {
        var startA = a.event_date_start;
        var startB = b.event_date_start;
        var comparison = 0;
        if (startA > startB) {
            comparison = 1;
        }
        else if (startA < startB) {
            comparison = -1;
        }
        return comparison;
    };
    EventService.prototype.isSeries = function (event) {
        return event.event_types.some(function (type) { return type.et_name.toLowerCase() === 'series'; });
    };
    EventService.prototype.searchEvents = function (criteria) {
        var _this = this;
        return this.http.get(this.url('event'), { params: criteria })
            .pipe(tap(function (_) { return _this.log('searched events with criteria'); }), catchError(this.handleError('searchEvents', [])));
    };
    EventService.prototype.updateEventStatus = function (eventId, event_status, closeFields) {
        var _this = this;
        if (closeFields === void 0) { closeFields = {}; }
        var data = Object.assign({}, { event_status: event_status }, closeFields);
        return this.http.put(this.url("event/status/" + eventId), data, this.options)
            .pipe(tap(function (_) { return _this.log("Updated event id: " + eventId + " status"); }), catchError(this.handleError('updateEventStatus', {}, { eventId: eventId, event_status: event_status, data: data })));
    };
    EventService.prototype.submitInsuranceChanges = function (eventId, changes) {
        var _this = this;
        return this.http.post(this.url("event/" + eventId + "/insurance_changes"), { changes: changes }, this.options)
            .pipe(tap(function (_) { return _this.log("Submitted changes to insurance"); }), catchError(this.handleError('submitInsuranceChanges', null, { eventId: eventId, changes: changes })));
    };
    EventService.prototype.getEventTypeById = function (eventTypeId) {
        return this.eventTypes.find(function (et) { return et.et_id.toString() === eventTypeId.toString(); });
    };
    EventService.prototype.getDisciplineById = function (disciplineId) {
        return this.disciplines.find(function (disc) { return disc.cd_id.toString() === disciplineId.toString(); });
    };
    EventService.prototype.getDisciplineName = function (value) {
        var name = '';
        this.disciplines.forEach(function (disc) {
            if (disc.cd_id === value || disc.cd_id.toString() === value) {
                name = disc.cd_value;
            }
        });
        return name;
    };
    EventService.prototype._loadDisciplines = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.disciplines && _this.disciplines.length > 0) {
                return resolve(_this.disciplines);
            }
            else {
                _this.getDisciplines().subscribe(function (disciplines) {
                    _this.disciplines = disciplines;
                    return resolve(disciplines);
                }, reject);
            }
        });
    };
    EventService.prototype._loadEventTypes = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.eventTypes && _this.eventTypes.length > 0) {
                return resolve(_this.eventTypes);
            }
            else {
                _this.getEventTypes().subscribe(function (eventTypes) {
                    _this.eventTypes = eventTypes;
                    return resolve(eventTypes);
                }, reject);
            }
        });
    };
    EventService.prototype._load = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadDisciplines()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadEventTypes()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EventService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventService_Factory() { return new EventService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: EventService, providedIn: "root" });
    return EventService;
}(BaseService));
export { EventService };
