<!-- <ul class="nav steps-list">
  <li class="nav-item" *ngFor="let item of items; let i = index" [attr.data-index]="i">
    <a class="nav-link">
      <span class="fa fa-circle-o"></span>
      <span>{{item}}</span>
    </a>
  </li>
</ul> -->

<div class="progressbar">
  <ul class="steps-list">
    <div *ngFor="let step of steps; let i = index">
      <li *ngIf="currentStep > i" class="done" [attr.data-index]="i">
        <a class="step-label" (click)="stepClick(currentStep, i, $event)">
          <span>{{step}}</span>
        </a>
      </li>
      <li *ngIf="currentStep == i" class="active" [attr.data-index]="i">
        <a class="step-label" (click)="stepClick(currentStep, i, $event)">
          <span>{{step}}</span>
        </a>
      </li>
      <li *ngIf="currentStep < i" [attr.data-index]="i">
        <a class="step-label" (click)="stepClick(currentStep, i, $event)">
          <span>{{step}}</span>
        </a>
      </li>
    </div>
  </ul>
  <div class="behind">
    <div class="progress">
      <div class="progress-bar" role="progressbar" [style.width.%]="percentage" attr.aria-valuenow="{{percentage}}" aria-valuemin="0" aria-valuemax="100">
      </div>
    </div>
  </div>
</div>
<!--
todo - need to set an active and complete class on current and past steps resepctively, need to tie step to x% of current progress, icon font not working as css content include, need to pull in custom icons -->
