import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Event } from '../../classes';
import { CertificateService, EventService, ProfileService } from '../../services';
var PreEventInsuranceComponent = /** @class */ (function () {
    function PreEventInsuranceComponent(certificateService, eventService, profileService, router) {
        this.certificateService = certificateService;
        this.eventService = eventService;
        this.profileService = profileService;
        this.router = router;
        this.loading = true;
        this.purchaseAdditionalCert = false;
        this.deleteModalOpen = false;
        this.deleting = false;
        this.showDeleteError = false;
        this.insuranceSubmitting = false;
        this.insuranceMessage = '';
        this.insuranceMessageClass = 'text-success';
    }
    PreEventInsuranceComponent.prototype.ngOnInit = function () {
        this.getCertificates();
    };
    PreEventInsuranceComponent.prototype.getCertificates = function () {
        var _this = this;
        this.loading = true;
        this.certificateService.getCertificates(this.event.event_id).subscribe(function (certs) {
            _this.paidCertificates = certs.filter(function (cert) { return !!cert.certificate_paid; });
            _this.certificates = certs.filter(function (cert) { return !!cert.certificate_paid || cert.type === 'named'; });
            _this.loading = false;
        });
    };
    PreEventInsuranceComponent.prototype.deleteCertificate = function () {
        var _this = this;
        this.deleting = true;
        this.showDeleteError = false;
        this.certificateService.deleteCertificate(this.event.event_id, this.certificateIdToDelete).subscribe(function (resp) {
            if (resp) {
                _this.getCertificates();
                _this.certificateIdToDelete = null;
                _this.closeDeleteModal();
            }
            else {
                _this.showDeleteError = true;
            }
            _this.deleting = false;
        });
    };
    PreEventInsuranceComponent.prototype.handleDelete = function (certificateId) {
        this.certificateIdToDelete = certificateId;
        this.openDeleteModal();
    };
    PreEventInsuranceComponent.prototype.openDeleteModal = function () {
        this.deleteModalOpen = true;
    };
    PreEventInsuranceComponent.prototype.closeDeleteModal = function () {
        this.deleteModalOpen = false;
    };
    PreEventInsuranceComponent.prototype.submitInsuranceChanges = function () {
        var _this = this;
        this.insuranceSubmitting = true;
        this.insuranceMessage = '';
        var changes = ['insured-certificate'];
        this.eventService.submitInsuranceChanges(this.event.event_id, changes).subscribe(function (resp) {
            if (resp) {
                _this.insuranceMessageClass = 'text-success';
                _this.insuranceMessage = 'Certificate changes submitted to insurance.';
            }
            else {
                _this.insuranceMessageClass = 'text-danger';
                _this.insuranceMessage = 'Failed to submit certificate changes to insurance. Please try again or contact IT support.';
            }
            _this.insuranceSubmitting = false;
        });
    };
    PreEventInsuranceComponent.prototype.navigateToCerts = function () {
        this.router.navigate(["event/" + this.event.event_id + "/insured-certificates"]);
    };
    Object.defineProperty(PreEventInsuranceComponent.prototype, "isUsacAdmin", {
        get: function () {
            return this.userRole === 'usac_admin';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreEventInsuranceComponent.prototype, "isEventOrganizer", {
        get: function () {
            var currentUser = this.profileService.currentUser;
            return currentUser && currentUser.profile_id === this.event.event_organizer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreEventInsuranceComponent.prototype, "isListingOwner", {
        get: function () {
            var currentUser = this.profileService.currentUser;
            return currentUser && currentUser.profile_id === this.event.event_listing_owner;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreEventInsuranceComponent.prototype, "allowAdd", {
        get: function () {
            return this.isUsacAdmin || this.isListingOwner || this.isEventOrganizer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreEventInsuranceComponent.prototype, "allowInsuranceSubmit", {
        get: function () {
            return this.isUsacAdmin && this.event.event_status === 'permit-approved';
        },
        enumerable: true,
        configurable: true
    });
    return PreEventInsuranceComponent;
}());
export { PreEventInsuranceComponent };
