import GraphQLJSClient from 'graphql-js-client';
import typeBundle from './types';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from './../../shared';
import { BaseService } from '../../services/base-service';

import {
  ShopifyProductVariant,
  ShopifyProductVariantFilters,
  ShopifyDraftOrder,
  ShopifyDraftOrderFilters,
  ShopifyOrder,
  ShopifyOrderFilters,
  ShopifyProduct,
  ShopifyProductFilters,
  ShopifyDiscount,
  ShopifyOrderTransaction,
  ShopifyOrderTransactionFilters,
  ShopifyLineItemBase,
  ShopifyProductImageFilters,
  ShopifyProductImage
} from '../../classes';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class ShopifyService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected cookie: CookieService,
    protected router: Router
  ) {
    super(http, cookie);
  }

  cartDraftOrder: BehaviorSubject<ShopifyDraftOrder> = new BehaviorSubject(null);
  cartProducts: BehaviorSubject<ShopifyProduct[]> = new BehaviorSubject([]);
  // cartProductImages: BehaviorSubject<ShopifyProductImage[]> = new BehaviorSubject([]);
  eventDraftOrders: BehaviorSubject<ShopifyDraftOrder[]> = new BehaviorSubject([]);
  eventOrders: BehaviorSubject<ShopifyOrder[]> = new BehaviorSubject([]);

  cartUpdating = false;

  // TODO: Update with support for Shopify Admin API proxy usage (via NextGen Laravel API proxy, etc)
  client = new GraphQLJSClient(typeBundle, {
    url: environment.url,
    fetcherOptions: {
      headers: {
        'X-Shopify-Storefront-Access-Token': environment.shopifyaccesstoken
      }
    }
  });

  createDraftOrder(
    lineItems: any,
    tags: string[] = [],
    applied_discount: ShopifyDiscount = null,
    note: string = null,
    customer: {} = null
  ): Observable<any> {
    const body = {
      method: 'post',
      path: '/draft_orders',
      body: {
        draft_order: {
          customer,
          line_items: lineItems.map(item => {
            let variant_id = item.variant_id;
            const quantity = item.quantity;

            if (!/^[0-9]+$/.test(variant_id)) {
              variant_id = atob(variant_id).replace(/[^0-9]+/g, '');
            }

            return {
              quantity,
              variant_id
            };
          }),
          tags: tags.join(','),
          applied_discount,
          note
        }
      }
    };

    // console.log('Draft order proxy body', body);

    return this.http.post<any>(this.url('shopify/proxy'), body, this.options)
      .pipe(
        tap(_ => this.log('created shopify draft_order')),
        catchError(this.handleError<any>('createDraftOrder', {}, { lineItems }))
      );
  }

  updateDraftOrder(
    draftOrderId: number|string, lineItems: any, tags: string[] = [],
    applied_discount: ShopifyDiscount = null, note: string = null
  ): Observable<any> {
    const body = {
      method: 'put',
      path: `/draft_orders/${draftOrderId}`,
      body: {
        draft_order: {
          id: draftOrderId,
          line_items: lineItems.map(item => {
            let variant_id = item.variant_id;
            const quantity = item.quantity;
            const lineItemDiscount = item.applied_discount || null;

            if (!/^[0-9]+$/.test(variant_id)) {
              variant_id = atob(variant_id).replace(/[^0-9]+/g, '');
            }

            if (lineItemDiscount) {
              return {
                quantity,
                variant_id,
                applied_discount: lineItemDiscount
              };
            } else {
              return {
                quantity,
                variant_id
              };
            }
          }),
          tags: tags.join(','),
          applied_discount,
          note
        }
      }
    };

    // console.log('Draft order proxy body', body);

    return this.http.post<any>(this.url('shopify/proxy'), body, this.options)
      .pipe(
        tap(_ => this.log('updated shopify draft_order')),
        catchError(this.handleError<any>('updateDraftOrder', {}, { draftOrderId, lineItems }))
      );
  }

  // TODO: Update logic once Permitting II complete (delete draft orders directly from Nextgen)
  deleteDraftOrder(draftOrderId: number|string): Observable<any> {
    const body = {
      method: 'delete',
      path: `/draft_orders/${draftOrderId}`
    };

    return this.http.post<any>(this.url('shopify/proxy'), body, this.options)
      .pipe(
        tap(_ => this.log('deleted shopify draft order')),
        catchError(this.handleError<ShopifyOrder[]>('deleteDraftOrder', null, { draftOrderId })
      )
    );
  }

  getShopifyDraftOrders(filters?: ShopifyDraftOrderFilters): Observable<ShopifyDraftOrder[]> {
    return this.http.get<ShopifyDraftOrder[]>(this.url('shopify/draft_orders', filters), this.options)
      .pipe(
        tap((draftOrders) => {
          this.log('fetched draft orders');
          this.eventDraftOrders.next(draftOrders);
        }),
        catchError(this.handleError<ShopifyDraftOrder[]>('getShopifyDraftOrders', [], { filters })
      )
    );
  }

  getShopifyOrders(filters?: ShopifyOrderFilters): Observable<ShopifyOrder[]> {
    return this.http.get<ShopifyOrder[]>(this.url('shopify/orders', filters), this.options)
      .pipe(
        tap(_ => this.log('fetched orders')),
        catchError(this.handleError<ShopifyOrder[]>('getShopifyOrders', [], { filters })
      )
    );
  }

  getShopifyOrderTransactions(filters?: ShopifyOrderTransactionFilters): Observable<ShopifyOrderTransaction[]> {
    return this.http.get<ShopifyOrderTransaction[]>(this.url('shopify/order_transactions', filters), this.options)
      .pipe(
        tap(_ => this.log('fetched shopify order transactions')),
        catchError(this.handleError<ShopifyOrderTransaction[]>('getShopifyOrderTransactions', null, { filters })
      )
    );
  }

  getShopifyProductImages(filters?: ShopifyProductImageFilters): Observable<ShopifyProductImage[]> {
    return this.http.get<ShopifyProductImage[]>(this.url('shopify/product_images', filters), this.options)
      .pipe(
        tap(_ => this.log('fetched shopify product images')),
        catchError(this.handleError<ShopifyProductImage[]>('getShopifyProductImages', null, { filters })
      )
    );
  }

  getShopifyProducts(filters?: ShopifyProductFilters): Observable<ShopifyProduct[]> {
    return this.http.get<ShopifyProduct[]>(this.url('shopify/products', filters), this.options)
      .pipe(
        tap(_ => this.log('fetched Products')),
        catchError(this.handleError<ShopifyProduct[]>('getShopifyProducts', [], { filters })
      )
    );
  }

  getShopifyProductVariants(filters?: ShopifyProductVariantFilters): Observable<ShopifyProductVariant[]> {
    return this.http.get<ShopifyProductVariant[]>(this.url('shopify/product_variants', filters), this.options)
      .pipe(
        tap(_ => this.log('fetched variants')),
        catchError(this.handleError<ShopifyProductVariant[]>('getShopifyVariants', [], { filters })
      )
    );
  }

  getCurrentShop(): Promise<any> {
    const client = this.client;
    const query = client.query((root) => {
      root.add('shop', (shop) => {
        shop.add('name');
      });
    });
    return client.send(query);
  }

  // TODO: Lookup via Laravel API + Shopify proxy (could be real-time proxy to direct Shopify products, or loaded by list in nextgen DB)
  getProductById(id): Promise<Product> {
    const client = this.client;
    const query = client.query((root) => {
      root.add('node', { args: { id }, alias: 'product' }, (node) => {
        node.add('id');
        node.addInlineFragmentOn('Product', (product) => {
          product.add('title');
          product.add('createdAt');
          product.add('descriptionHtml');
          product.add('productType');
          product.add('publishedAt');
          product.add('tags');
          product.add('updatedAt');
          product.add('vendor');
          // Product.addConnection('metafields', { args: { namespace: 'test', first: 250 } }, (metafields) => {
          //   metafields.add('content');
          // })
          product.addConnection('images', { args: { first: 250 } }, (images) => {
            images.add('src');
            images.add('id');
            images.add('altText');
          });
          product.addConnection('variants', { args: { first: 250 } }, (variants) => {
            variants.add('id');
            variants.add('product');
            variants.add('title');
            variants.add('price');
            variants.add('sku');
            variants.add('image', (image) => {
              image.add('src');
              image.add('id');
              image.add('altText');
            });
          });
        });
      });
    });

    return client.send(query).then(({ model, data }) => {
      return client.fetchAllPages(model.product, { pageSize: 250 });
    });

  }

  // TODO: Lookup via Laravel API + Shopify proxy (could be real-time proxy to direct Shopify products, or loaded by list in nextgen DB)
  getProducts(): Promise<Product[]> {
    const query = this.client.query((root) => {
      root.add('shop', (shop) => {
        shop.addConnection('products', { args: { first: 250 } }, (products) => {
          products.add('id');
          products.add('title');
          products.add('tags');
          products.addConnection('images', { args: { first: 250 } }, (images) => {
            images.add('src');
            images.add('id');
            images.add('altText');
          });
          products.addConnection('collections', { args: { first: 250 } }, (collections) => {
            collections.add('title');
          });
          products.addConnection('variants', { args: { first: 250 } }, (variants) => {
            variants.add('title');
            variants.add('sku');
          });
        });
      });
    });
    return this.client.send(query).then(({ model, data }) => {
      return this.client.fetchAllPages(model.shop.products, { pageSize: 20 });
    });
  }

  setProductIdFromSku(product: Product, sku: string, variantIndex: number = 0) {
    let id: string;
    id = product.variants[variantIndex].sku === sku ? product.id : null;
    return id;
  }

  setProductIdFromVariantSku(product: Product, sku: string) {
    let id: string;
    id = product.variants.find(variant => variant.sku === sku) ? product.id : null;
    return id;
  }

  // TODO: Can the sku => variant_id lookup be automated on the API side when this is talking to Shopify?
  getVariantBySku(product: Product, sku: string) {
    return product.variants.find(variant => variant.sku === sku) || product.variants[0];
  }

  applyTags(product: Product) {
    if (product.tags) {
      product.hasDocs = product.tags.map(tag => tag.value).includes('has-docs');
      product.radioOptions = product.tags.map(tag => tag.value).includes('radio-options');
      product.requiresPrepurchaseInfo = product.tags.map(tag => tag.value).includes('requires-prepurchase-info');
      product.disableQuantity = product.tags.map(tag => tag.value).includes('disable-quantity');
      product.disableCartQuantity = product.tags.map(tag => tag.value).includes('disable-cart-quantity');
      product.disableCartRemoval = product.tags.map(tag => tag.value).includes('disable-cart-removal');
      product.oneTimePurchase = product.tags.map(tag => tag.value).includes('one-time-purchase');
    }
  }

  // TODO: Create draft order from line items (use invoice url for checkout flow)
  createCheckout(lineItems): Promise<any> {
    const lineItemsForCheckout = lineItems.map(item => ({ variantId: item.variantId, quantity: item.quantity }));

    // console.debug('Creating Shopify Checkout with line items:', {
    //   lineItems,
    //   lineItemsForCheckout
    // });

    const input = this.client.variable('input', 'CheckoutCreateInput!');
    const mutation = this.client.mutation('myMutation', [input], (root) => {
      root.add('checkoutCreate', { args: { input } }, (checkoutCreate) => {
        checkoutCreate.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field');
        });
        checkoutCreate.add('checkout', (checkout) => {
          checkout.add('id'),
          checkout.add('webUrl'),
            checkout.addConnection('lineItems', { args: { first: 250 } }, (lineItemsConnection) => {
              lineItemsConnection.add('variant', (variant) => {
                variant.add('title');
              }),
              lineItemsConnection.add('quantity');
            }
            );
        });
      });
    });

    const customAttributes = [
      {
        key: 'source',
        value: 'permitting'
      },
      {
        key: 'return_url',
        value: document.location.origin + '/thank-you'
      }
    ];

    return this.client.send(mutation, { input: { lineItems: lineItemsForCheckout, customAttributes } });
  }

  // TODO: Apply discount via nextgen laravel api request (shopfiy admin req proxy?)
  //        - Apply discount(s) to draft order(s) instead of checkout (draft order should already exist)
  applyDiscount(inputCode, checkoutId): Promise<any> {
    const discountCode = inputCode;
    const input = this.client.variable('discountCode', 'String!');
    const id = this.client.variable('checkoutId', 'ID!');
    // console.log(this.client);
    const mutation = this.client.mutation('myMutation', [id, input], (root) => {
      root.add('checkoutDiscountCodeApplyV2', { args: {checkoutId: id, discountCode: input} }, ( checkoutDiscountCodeApplyV2 ) => {
        checkoutDiscountCodeApplyV2.add('userErrors', (userErrors) => {
          userErrors.add('message'),
          userErrors.add('field');
          // console.log('inside discount return', checkoutId, discountCode);
        });
        checkoutDiscountCodeApplyV2.add('checkout', ( checkout ) => {
          checkout.add('id');
          // console.log('inside discount return ADD', checkoutId, discountCode);
        });
      });
    });
    return this.client.send(mutation, { checkoutId, discountCode });
  }

  // TODO: Proxy lookup via laravel API request / proxy
  fetchCheckout(checkoutId): Promise<any> {
    const id = this.client.variable('checkoutId', 'ID!');
    const query = this.client.query((root) => {
      root.add('node', { args: { id: checkoutId }, alias: 'checkout' }, (node) => {
        node.add('id');
        node.addInlineFragmentOn('Checkout', (Checkout) => {
          Checkout.add('webUrl'),
          Checkout.add('subtotalPrice'),
          Checkout.add('completedAt'),
          Checkout.add('email'),
            Checkout.add('totalTax'),
            Checkout.add('totalPrice'),
            Checkout.addConnection('lineItems', { args: { first: 250 } }, (lineItems) => {
              lineItems.add('variant', (variant) => {
                variant.add('title'),
                variant.add('image', (image) => image.add('src')),
                variant.add('price');
              }),
                lineItems.add('quantity');
            });
        });
      });
    });
    return this.client.send(query, { checkoutId });
  }

  // TODO: Create draft order first (blank/empty)? Or create with line items + variants when ready?
  addVariantsToCheckout(cartId, lineItemsInput): Promise<any> {

    const checkoutId = this.client.variable('checkoutId', 'ID!');
    const lineItemsForCheckout = lineItemsInput.map(item => ({ id: item.id, variantId: item.variantId, quantity: item.quantity }));
    const lineItems = this.client.variable('lineItems', '[CheckoutLineItemInput!]!');

    const mutation = this.client.mutation('myMutation', [checkoutId, lineItems], (root) => {
      root.add('checkoutLineItemsAdd', { args: { checkoutId, lineItems } }, (checkoutLineItemsAdd) => {

        checkoutLineItemsAdd.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field');
        });

        checkoutLineItemsAdd.add('checkout', (checkout) => {
          checkout.add('webUrl'),
            checkout.add('subtotalPrice'),
            checkout.add('totalTax'),
            checkout.add('totalPrice'),
            checkout.addConnection('lineItems', { args: { first: 250 } }, (lineItemsConnection) => {
              lineItemsConnection.add('variant', (variant) => {
                variant.add('title'),
                  variant.add('image', (image) => image.add('src')),
                  variant.add('price');
              }),
              lineItemsConnection.add('quantity');
            });
        });
      });
    });
    console.log('line items', lineItemsForCheckout);
    return this.client.send(mutation, { checkoutId: cartId, lineItems: lineItemsForCheckout });
  }

  // TODO: Ability to remove line item (variants) from draft order (instead of checkout)
  removeLineItem(cartId, lineItemId): Promise<any> {

    const checkoutId = this.client.variable('checkoutId', 'ID!');
    const lineItemIds = this.client.variable('lineItemIds', '[ID!]!');

    const mutation = this.client.mutation('myMutation', [checkoutId, lineItemIds], (root) => {
      root.add('checkoutLineItemsRemove', { args: { checkoutId, lineItemIds } }, (checkoutLineItemsRemove) => {

        checkoutLineItemsRemove.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field');
        }),

          checkoutLineItemsRemove.add('checkout', (checkout) => {
            checkout.add('webUrl'),
              checkout.add('subtotalPrice'),
              checkout.add('totalTax'),
              checkout.add('totalPrice'),
              checkout.addConnection('lineItems', { args: { first: 250 } }, (lineItemsConnection) => {
                lineItemsConnection.add('variant', (variant) => {
                  variant.add('title'),
                    variant.add('image', (image) => image.add('src')),
                    variant.add('price');
                }),
                lineItemsConnection.add('quantity');
              });
          });
      });
    });

    return this.client.send(mutation, { checkoutId: cartId, lineItemIds: [lineItemId] });

  }

  // TODO: Ability to update line item (variants) from draft order (instead of checkout)
  updateLineItem(cartId, lineItemsInput): Promise<any> {

    const lineItemsForCheckout = lineItemsInput.map(item => ({ id: item.id, variantId: item.variantId, quantity: item.quantity }));

    const checkoutId = this.client.variable('checkoutId', 'ID!');
    const lineItems = this.client.variable('lineItems', '[CheckoutLineItemUpdateInput!]!');
    const mutation = this.client.mutation('myMutation', [checkoutId, lineItems], (root) => {
      root.add('checkoutLineItemsUpdate', { args: { checkoutId, lineItems } }, (checkoutLineItemsUpdate) => {

        checkoutLineItemsUpdate.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field');
        });
        checkoutLineItemsUpdate.add('checkout', (checkout) => {
          checkout.add('webUrl'),
            checkout.add('subtotalPrice'),
            checkout.add('totalTax'),
            checkout.add('totalPrice'),
            checkout.addConnection('lineItems', { args: { first: 250 } }, (lineItemsConnection) => {
              lineItemsConnection.add('variant', (variant) => {
                variant.add('title'),
                  variant.add('image', (image) => image.add('src')),
                  variant.add('price');
              }),
              lineItemsConnection.add('quantity');
            });
        });
      });
    });
    return this.client.send(mutation, { checkoutId: cartId, lineItems: lineItemsForCheckout });
  }

  updateCartDraftOrder(draftOrder: ShopifyDraftOrder) {
    this.cartDraftOrder.next(draftOrder);
    // this.getShopifyProductImages(draftOrder);
  }

  getCartProducts() {
    this.getShopifyProducts().subscribe(products => this.cartProducts.next(products));
  }

  // Images are currently hidden on the cart, per CSS class
  // getProductImages(draftOrder: ShopifyDraftOrder) {
  //   const product_ids = [];
  //   draftOrder.line_items.forEach(item => {
  //     product_ids.push(item.product_id);
  //   });
  //   this.getShopifyProductImages({product_ids}).subscribe(images => this.productImages.next(images));
  // }

  updateDraftOrderItems(draftOrder: ShopifyDraftOrder, navigateUrl?: string) {
    this.cartUpdating = true;
    const tags = draftOrder.tags.split(', ');
    let action: Observable<any>;
    if (draftOrder.id) {
      // Updating existing draft order from Shopify
      action = this.updateDraftOrder(
        draftOrder.id, draftOrder.line_items, tags, draftOrder.applied_discount, draftOrder.note
      );
    } else {
      // Create new Shopify draft order
      action = this.createDraftOrder(
        draftOrder.line_items, tags, draftOrder.applied_discount, draftOrder.note
      );
    }
    action.subscribe(resp => {
      if (resp) {
        this.cartUpdating = false;
        // TODO: Update cart with current draft order
        this.updateCartDraftOrder(resp.draft_order);
        if (navigateUrl) {
          setTimeout(() => this.router.navigate([navigateUrl]), 500);
        }
      }
    });
  }

  addToDraftOrder(lineItem: ShopifyLineItemBase, draftOrder: ShopifyDraftOrder) {
    draftOrder.line_items.push(lineItem);
  }

  removeFromDraftOrder(lineItem: ShopifyLineItemBase, draftOrder: ShopifyDraftOrder) {
    const index = draftOrder.line_items.findIndex(item => item.variant_id === lineItem.variant_id);
    if (index > -1) {
      draftOrder.line_items.splice(index, 1);
    }
  }

  increaseItemQuantity(lineItem: ShopifyLineItemBase, quantity = 1, draftOrder: ShopifyDraftOrder) {
    const item = draftOrder.line_items.find(li => li.variant_id === lineItem.variant_id);
    if (item) {
      item.quantity += quantity;
    } else {
      this.addToDraftOrder(lineItem, draftOrder);
    }
  }

  decreaseItemQuantity(lineItem: ShopifyLineItemBase, quantity = 1, draftOrder: ShopifyDraftOrder) {
    const item = draftOrder.line_items.find(li => li.variant_id === lineItem.variant_id);
    if (item.quantity > 1 && item.quantity > quantity) {
      item.quantity -= quantity;
    } else {
      this.removeFromDraftOrder(lineItem, draftOrder);
    }
  }

}
