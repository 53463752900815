import { Component, OnInit, Input } from '@angular/core';

import { ActivityService } from '../../services/activity.service';
import { Activity, Event } from 'src/app/classes';
@Component({
  selector: 'app-overview-history',
  templateUrl: './overview-history.component.html',
  styles: []
})
export class OverviewHistoryComponent implements OnInit {

  entries: Activity[] = [];
  loading = true;

  @Input() event: Event;

  constructor(private activities: ActivityService) { }

  ngOnInit() {
    this.activities.getEventHistory(this.event.event_id).subscribe(data => {
      this.entries = data.reverse();
      this.loading = false;
    });
  }

}
