<div class="form-group custom-control {{typeClass}} {{inlineStyle}}">
  <input type="checkbox"
    class="form-control custom-control-input"
    [ngClass]="{ 'is-invalid': formSubmitted && errors, 'disabled': disabled }"
    id="{{id}}"
    name="{{name}}"
    value="{{value}}"
    [(checked)]="checked"
    [disabled]="disabled"
    (change)="onCheckChange($event)"
  />
  <label class="custom-control-label" for="{{id}}">
    {{label}}
    <span *ngIf="required" class="required">*</span>
    <span *ngIf="tooltip" class="icon icon-information" [ngbTooltip]="tipContent">
    <ng-template #tipContent>{{tooltip}}</ng-template></span>
  </label>
  <div *ngIf="formSubmitted && errors" class="invalid-feedback">
    <div *ngIf="errors.required">Required</div>
  </div>
</div>
