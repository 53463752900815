import { OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SelectOption, Event } from '../../classes';
import { EventListingService, NotificationService } from '../../services';
import { Router } from '@angular/router';
var OverviewNotificationsComponent = /** @class */ (function () {
    function OverviewNotificationsComponent(notificationService, formBuilder, eventListing, router) {
        this.notificationService = notificationService;
        this.formBuilder = formBuilder;
        this.eventListing = eventListing;
        this.router = router;
        this.notifications = [];
        this.formItems = {};
        this.submitted = false;
        this.loading = true;
        this.disabled = true;
        this.denyRequest = false;
        this.submitModalOpen = false;
        this.statusOptions = [];
    }
    Object.defineProperty(OverviewNotificationsComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.notificationForm.controls; },
        enumerable: true,
        configurable: true
    });
    OverviewNotificationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.notificationService.getNotificationsForEvent(this.event.event_id).subscribe(function (notifications) {
            _this.notifications = _this.notificationService.sortNotifications(notifications, _this.event.event_id);
            if (_this.notifications.length) {
                _this.createForm();
            }
            else {
                _this.loading = false;
            }
        });
    };
    OverviewNotificationsComponent.prototype.createForm = function () {
        var _this = this;
        this.notifications.forEach(function (n) { return _this.formItems['notification-' + n.formControl] = false; });
        this.notificationForm = this.formBuilder.group(this.formItems);
        this.statusOptions = [
            new SelectOption('pending', 'Pending Approval'),
            new SelectOption('denied', 'Denied'),
            new SelectOption('approved', 'Approved')
        ];
        this.loading = false;
    };
    OverviewNotificationsComponent.prototype.onChange = function () {
        var trueValues = 0;
        for (var key in this.f) {
            if (this.f.hasOwnProperty(key)) {
                trueValues += this.f[key].value ? 1 : 0;
            }
        }
        this.disabled = trueValues > 0 ? false : true;
    };
    OverviewNotificationsComponent.prototype.onActionClick = function (actionUrl) {
        // this.router.navigate([`event/${this.event.event_id}/overview`], {fragment: actionUrl});
        window.location.replace("event/" + this.event.event_id + "/overview#" + actionUrl);
        window.location.reload();
    };
    OverviewNotificationsComponent.prototype.onSubmit = function (action) {
        var _this = this;
        if (!this.disabled) {
            this.submitted = true;
            console.log("submitting form for " + action, this.f);
            // stop here if form is invalid
            if (this.notificationForm.invalid) {
                return;
            }
            if (action === 'read') {
                this.notifications.forEach(function (n) {
                    var control = 'notification-' + n.formControl;
                    if (_this.f[control].value === true) {
                        _this.notificationService.readNotification(n.type, n.id).subscribe(function () {
                            n.read = true;
                        });
                    }
                });
            }
            else if (action === 'delete') {
                var toDelete_1 = [];
                this.notifications.forEach(function (n) {
                    var id = 'notification-' + n.id;
                    if (_this.f[id].value === true) {
                        toDelete_1.push(n.id);
                        console.log('Deleted ' + id);
                    }
                });
                this.notifications = this.notifications.filter(function (n) { return !toDelete_1.includes(n.id); });
            }
            // display form values on success
            // alert(`SUCCESS!! - ${action} :-)\n\n` + JSON.stringify(this.notificationForm.value, null, 4));
            this.notificationForm.reset();
            this.disabled = true;
        }
    };
    OverviewNotificationsComponent.prototype.denyChangeRequest = function () {
        this.denyRequest = !this.denyRequest;
    };
    OverviewNotificationsComponent.prototype.sendReason = function () {
        this.submitModalOpen = false;
    };
    OverviewNotificationsComponent.prototype.viewRequest = function () {
        this.eventListing.mode = 'view';
        //  this.router.navigate(['/event/review']);
        //  this.submitModalOpen = true;
    };
    OverviewNotificationsComponent.prototype.closeRequest = function () {
        this.submitModalOpen = false;
    };
    OverviewNotificationsComponent.prototype.approveChanges = function () {
        this.submitModalOpen = false;
    };
    OverviewNotificationsComponent.prototype.onSelect = function (event) {
    };
    return OverviewNotificationsComponent;
}());
export { OverviewNotificationsComponent };
