/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../atoms/loader/loader.component.ngfactory";
import * as i2 from "../../atoms/loader/loader.component";
import * as i3 from "../../organisms/add-on-list/add-on-list.component.ngfactory";
import * as i4 from "../../organisms/add-on-list/add-on-list.component";
import * as i5 from "@angular/router";
import * as i6 from "../../shared/services/shopify.service";
import * as i7 from "../../shared/services/global.service";
import * as i8 from "../../services/event_day.service";
import * as i9 from "@angular/common";
import * as i10 from "./add-ons.component";
import * as i11 from "../../services/events.service";
import * as i12 from "../../services/event-listing.service";
var styles_AddOnsComponent = [];
var RenderType_AddOnsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddOnsComponent, data: {} });
export { RenderType_AddOnsComponent as RenderType_AddOnsComponent };
function View_AddOnsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AddOnsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-add-on-list", [], null, null, null, i3.View_AddOnListComponent_0, i3.RenderType_AddOnListComponent)), i0.ɵdid(1, 114688, null, 0, i4.AddOnListComponent, [i5.Router, i6.ShopifyService, i7.GlobalService, i8.EventDayService], { eventId: [0, "eventId"], discipline: [1, "discipline"], eventDates: [2, "eventDates"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.eventId; var currVal_1 = _co.discipline; var currVal_2 = _co.eventDates; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AddOnsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 11, "div", [["class", "main-container col-12 add-on-items"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Optional Add-Ons"])), (_l()(), i0.ɵeld(5, 0, null, null, 4, "p", [["class", "small"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" The insurance products listed below are optional add-ons to meet the unique needs of your event. If your event does not need these products then you do not need to add, or answer any questions below. For questions or assistance reach out to "])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "a", [["href", "mailto:eventservices@usacycling.org"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["eventservices@usacycling.org"])), (_l()(), i0.ɵted(-1, null, [". "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddOnsComponent_1)), i0.ɵdid(11, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddOnsComponent_2)), i0.ɵdid(13, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 11, 0, currVal_0); var currVal_1 = !_co.loading; _ck(_v, 13, 0, currVal_1); }, null); }
export function View_AddOnsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-add-ons", [], null, null, null, View_AddOnsComponent_0, RenderType_AddOnsComponent)), i0.ɵdid(1, 114688, null, 0, i10.AddOnsComponent, [i11.EventService, i12.EventListingService, i5.Router, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddOnsComponentNgFactory = i0.ɵccf("app-add-ons", i10.AddOnsComponent, View_AddOnsComponent_Host_0, {}, {}, []);
export { AddOnsComponentNgFactory as AddOnsComponentNgFactory };
