<div class="form-group radio-group">
  <label>
    {{label}}
    <span *ngIf="required" class="required">*</span>
    <span *ngIf="icon" class="icon {{iconClass}}" [ngbTooltip]="tipContent">
      <ng-template #tipContent>{{content}}</ng-template></span>
  </label>
  <div class="form-control" [ngClass]="{ 'is-invalid': formSubmitted && errors }">
    <app-radio
      *ngFor="let option of options"
      [name]="name"
      [inline]="inline"
      [id]="option.id"
      [label]="option.label"
      [value]="option.value"
      [disabled]="option.disabled"
      [(checked)]="option.checked"
      (change)="onRadioChange($event)"
    ></app-radio>
  </div>
  <div *ngIf="formSubmitted && errors" class="invalid-feedback">
    <div *ngIf="errors.required">Required</div>
  </div>
</div>