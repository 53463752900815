export class CheckboxOption {
  id: string;
  name: string;
  value: string;
  label: string;
  checked: boolean;
  toggle: boolean;
  disabled: boolean;
  tooltip?: string;

  constructor(
    id: string, name: string, value: string, label: string, toggle?: boolean,
    disabled?: boolean, tooltip?: string
  ) {
    this.id = id;
    this.name = name;
    this.value = value;
    this.label = label;
    this.toggle = toggle;
    this.disabled = disabled;
    this.tooltip = tooltip;
  }

  check() {
    this.checked = true;
  }

  unCheck() {
    this.checked = undefined;
  }

  disable() {
    this.disabled = true;
  }

  enable() {
    this.disabled = undefined;
  }
}
