/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../atoms/button/button.component.ngfactory";
import * as i2 from "../../atoms/button/button.component";
import * as i3 from "../../molecules/modal/modal.component.ngfactory";
import * as i4 from "../../molecules/modal/modal.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../atoms/modal-body/modal-body.component.ngfactory";
import * as i7 from "../../atoms/modal-body/modal-body.component";
import * as i8 from "../add-driver/add-driver.component.ngfactory";
import * as i9 from "../add-driver/add-driver.component";
import * as i10 from "@angular/forms";
import * as i11 from "../../services/profile.service";
import * as i12 from "../../services/driver_vehicle.service";
import * as i13 from "../add-vehicle/add-vehicle.component.ngfactory";
import * as i14 from "../add-vehicle/add-vehicle.component";
import * as i15 from "@angular/common";
import * as i16 from "./add-vehicle-driver-form.component";
var styles_AddVehicleDriverFormComponent = [];
var RenderType_AddVehicleDriverFormComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddVehicleDriverFormComponent, data: {} });
export { RenderType_AddVehicleDriverFormComponent as RenderType_AddVehicleDriverFormComponent };
export function View_AddVehicleDriverFormComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-button", [["class", "col-sm-4 col-md-3 col-lg-2 text-center add-veh-mng-btn"], ["style", "flex:2;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(1, 114688, null, 0, i2.ButtonComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵted(-1, 0, ["Manage"])), (_l()(), i0.ɵeld(3, 0, null, null, 24, "app-modal", [["class", "auto-notificaton"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ModalComponent_0, i3.RenderType_ModalComponent)), i0.ɵdid(4, 638976, null, 0, i4.ModalComponent, [i5.NgbModal], { id: [0, "id"], title: [1, "title"], isOpen: [2, "isOpen"] }, { close: "close" }), (_l()(), i0.ɵeld(5, 0, null, 0, 22, "app-modal-body", [], null, null, null, i6.View_ModalBodyComponent_0, i6.RenderType_ModalBodyComponent)), i0.ɵdid(6, 49152, null, 0, i7.ModalBodyComponent, [], null, null), (_l()(), i0.ɵeld(7, 0, null, 0, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "col-lg-6 add-driver-component"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "app-add-driver", [], null, [[null, "quantityChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("quantityChange" === en)) {
        var pd_0 = (_co.onQuantityChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_AddDriverComponent_0, i8.RenderType_AddDriverComponent)), i0.ɵdid(10, 638976, null, 0, i9.AddDriverComponent, [i10.FormBuilder, i11.ProfileService, i12.DriverVehicleService], { drivers: [0, "drivers"], type: [1, "type"], permitId: [2, "permitId"] }, { quantityChange: "quantityChange" }), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [["class", "col-lg-6 add-vehicle-component"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "app-add-vehicle", [], null, [[null, "quantityChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("quantityChange" === en)) {
        var pd_0 = (_co.onQuantityChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_AddVehicleComponent_0, i13.RenderType_AddVehicleComponent)), i0.ɵdid(13, 114688, null, 0, i14.AddVehicleComponent, [i10.FormBuilder, i12.DriverVehicleService], { permitId: [0, "permitId"], type: [1, "type"], quantity: [2, "quantity"], vehicles: [3, "vehicles"] }, { quantityChange: "quantityChange" }), (_l()(), i0.ɵeld(14, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, 0, 4, "p", [["class", "px-2 small"]], null, null, null, null, null)), i0.ɵprd(512, null, i15.ɵNgClassImpl, i15.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(18, 278528, null, 0, i15.NgClass, [i15.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(19, { "text-warning": 0 }), (_l()(), i0.ɵted(-1, null, [" * Please ensure all driver and motor vehicle information (for both auto and motorcycle liability, if applicable) is complete prior to submitting to the insurance company.\n"])), (_l()(), i0.ɵeld(21, 0, null, 0, 6, "div", [["class", "d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 2, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(23, 114688, null, 0, i2.ButtonComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵted(-1, 0, ["Save & Exit"])), (_l()(), i0.ɵeld(25, 0, null, null, 2, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(26, 114688, null, 0, i2.ButtonComponent, [], { type: [0, "type"], disabled: [1, "disabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["Submit to Insurance"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn-sm btn-secondary"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "auto-notificaton"; var currVal_2 = _co.title; var currVal_3 = _co.modalOpen; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.drivers; var currVal_5 = _co.type; var currVal_6 = _co.permitId; _ck(_v, 10, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.permitId; var currVal_8 = _co.type; var currVal_9 = _co.quantity; var currVal_10 = _co.vehicles; _ck(_v, 13, 0, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = "px-2 small"; var currVal_12 = _ck(_v, 19, 0, !_co.autoMotoComplete); _ck(_v, 18, 0, currVal_11, currVal_12); var currVal_13 = "btn-light"; _ck(_v, 23, 0, currVal_13); var currVal_14 = "btn-secondary"; var currVal_15 = !_co.autoMotoComplete; _ck(_v, 26, 0, currVal_14, currVal_15); }, null); }
export function View_AddVehicleDriverFormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-add-vehicle-driver-form", [], null, null, null, View_AddVehicleDriverFormComponent_0, RenderType_AddVehicleDriverFormComponent)), i0.ɵdid(1, 573440, null, 0, i16.AddVehicleDriverFormComponent, [i12.DriverVehicleService], null, null)], null, null); }
var AddVehicleDriverFormComponentNgFactory = i0.ɵccf("app-add-vehicle-driver-form", i16.AddVehicleDriverFormComponent, View_AddVehicleDriverFormComponent_Host_0, { permitId: "permitId", type: "type", quantity: "quantity", autoVehicles: "autoVehicles", motoVehicles: "motoVehicles", hasAuto: "hasAuto", hasMoto: "hasMoto", autoComplete: "autoComplete", motoComplete: "motoComplete", drivers: "drivers" }, { quantityChange: "quantityChange" }, []);
export { AddVehicleDriverFormComponentNgFactory as AddVehicleDriverFormComponentNgFactory };
