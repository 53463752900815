import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PermitService, EventListingService } from '../../services';


import { CheckboxOption, Event, RadioOption } from '../../classes';


@Component({
  selector: 'app-permit-part2-form',
  templateUrl: './permit-part2-form.component.html',
  styles: []
})
export class PermitPart2Component implements OnInit {

  @Input() event: Event;
  @Input() eventId: string;

  permitPart2Form: FormGroup;
  submitted = false;
  courseMarkingOptions: Array<CheckboxOption>;
//  securityContacts: FormArray;
//  securityOptions: RadioOption[] = [];
  localPermitApprovalOptions: RadioOption[] = [];
  disabledAddBtns = {};

  constructor(
    private formBuilder: FormBuilder,
    public permit: PermitService,
    public eventListing: EventListingService
  ) { }

  // convenience getter for easy access to form fields
  get f() { return this.permitPart2Form.controls; }

  ngOnInit() {
    const courseMapAnswer = this.permit.answers ? this.permit.answers.find(answer => answer.qid === 11) : null;
    if (!courseMapAnswer || !courseMapAnswer.description.length) {
      this.permit.generateCourseMaps(this.eventListing.eventDates.length);
    }
    this.permitPart2Form = this.formBuilder.group({
      courseMarking: [ '', Validators.required ],
    //  policeSecurity: [ '', Validators.required ]
    localPermitApproval: ['', Validators.required]
  });

    this.onChangeDiscipline();
    this.setDisciplineValidators();

    this.f.courseMarking.setValue(this.permit.courseMarking);
    this.f.localPermitApproval.setValue(this.permit.localPermitApproval);
  //  this.f.policeSecurity.setValue(this.permit.policeSecurity);

    // if (this.permit.securityContacts.length > 0) {
    //   this.permitPart2Form.setControl('securityContacts', this.setSecurityContacts());
    // } else {
    //   this.permitPart2Form.setControl('securityContacts', this.formBuilder.array([this.initContacts()]));
    // }

    // this.permitPart2Form.valueChanges.subscribe( data => {
    //   this.permit.policeSecurity = data.policeSecurity;
    //   this.permit.courseMarking = data.courseMarking;
    // });

    // if (this.f.securityContacts !== undefined) {
    //   this.permitPart2Form.get('securityContacts').valueChanges.subscribe(data => {
    //     this.permit.securityContacts = data;
    //   });
    // }

    // this.securityOptions = [
    //   new RadioOption('security-yes', 'yes', 'Yes'),
    //   new RadioOption('security-no', 'no', 'No')
    // ];

    this.localPermitApprovalOptions = [
      new RadioOption('local-approval-yes', 'yes', 'Yes'),
      new RadioOption('local-approval-no', 'no', 'No')
    ];

  }

  debug(event: any): void {
    console.log('debug form', this.f, event);
  }

  // initContacts() {
  //   return this.formBuilder.group({
  //       securityContactName: [ '',  nameCharactersValidator()  ],
  //       securityContactEmail: [ '',  emailValidator() ],
  //       securityContactPhone: [ '', phoneNumberValidator() ]
  //   });
  // }

  // addSecurityContact() {
  //   this.securityContacts = this.permitPart2Form.get('securityContacts') as FormArray;
  //   this.securityContacts.push(this.initContacts());
  // }

  // setSecurityContacts(): FormArray {
  //     const control = new FormArray([]);
  //     if (this.permit.securityContacts.length > 0 ) {
  //       this.permit.securityContacts.forEach(contact => {
  //         control.push(this.formBuilder.group({
  //           securityContactName: contact.securityContactName,
  //           securityContactEmail: contact.securityContactEmail,
  //           securityContactPhone: contact.securityContactPhone
  //         }));
  //       });
  //     }
  //     return control;
  // }

  // get Controls() {
  //   return (this.permitPart2Form.get('securityContacts') as FormArray).controls;
  // }

  // removeContact(index: number) {
  // (this.permitPart2Form.get('securityContacts') as FormArray).removeAt(index);
  // }

  onSubmit() {
    this.submitted = true;

    // console.log('submitting course info form', this.permitPart2Form);

    if (this.permitPart2Form.invalid) {
      console.log('course form data is invalid!');
      return;
    }

    this.permit.createPermit(this.eventId, 'part2', null, this.event.isRaceOrRide);
    // console.log(this.permitPart2Form.value);
  }

  // updatePermit() {
  //   this.submitted = true;

  //   console.log('submitting course info form', this.permitPart2Form);

  //   if (this.permitPart2Form.invalid) {
  //     console.log('course form data is invalid!');
  //     return;
  //   }

  //   this.permit.savePermit().subscribe( (response) => {
  //     console.log(response);
  //     // tslint:disable-next-line: no-string-literal
  //     if (response['status'] === true) {
  //       // TODO: add dynamic id
  //       this.route.navigate(['/event/19/overview']);
  //     }
  //   }, (error) => {
  //     console.log(error);
  //   }, () => {
  //     console.log('completed');
  //   });
  //   console.log(this.permitPart2Form.value);
  // }

  showCourseMapUpload() {
    // TODO: Replace cd_id values with discipline names (once retrieving from the API instead of listing service).
    return (this.eventListing.event_discipline === '19' // Road Racing
      || this.eventListing.event_discipline === '21'    // Mountain Bike Racing
      || this.eventListing.event_discipline === '24'    // Gran Fondo
      || this.eventListing.event_discipline === '27'    // Fun Rides
    );
  }

  updateCourses($event: Array<string>) {
    this.permit.courseMarking = $event;
  }

  competitiveNotBmx() {
    return (this.eventListing.isEventCompetitive && !this.eventListing.isBmx);
  }

  onChangeDiscipline() {
    //  console.log(this.eventListing.subDisciplines[`${this.f.event_discipline.value}`]);
    this.courseMarkingOptions = this.permit.checkBoxOptionsWithArray(this.permit.courseMarkingOptions[`${this.eventListing.event_discipline}`]); /* tslint:disable:max-line-length */
  }

  // updateSecurityInfo($event: string) {
  //   this.permit.policeSecurity = $event;
  // }

  // commented out for MBR-2992
  // onCourseMapChange(event: any) {
  //   this.disabledAddBtns[event.dateId] = !event.selectedSubDisc.length;
  // }

  disableAddBtn(id: number) {
    return this.disabledAddBtns[id];
  }

  updateApprovalInfo($event: string) {
    this.permit.localPermitApproval = $event;
  }

  setDisciplineValidators() {
      const courseMarking = this.permitPart2Form.get('courseMarking');
      if (!this.competitiveNotBmx() || !this.courseMarkingOptions || this.courseMarkingOptions.length === 0) {
        courseMarking.setValidators(null);
      }
      courseMarking.updateValueAndValidity();
    }
  }
