<!-- Loader -->
<app-loader *ngIf="loading"></app-loader>

<form *ngIf="!loading" [formGroup]="postEventForm">
  <h3 class="space-below-sm" id="registration">Safe Sport Event Checklist</h3>
  <div class="row">
    <div class="col">
      <app-radio-group
        formControlName="ss_q1"
        [inline]="true"
        [name]="'ss_q1'"
        [label]="label('ss_q1')"
        [options]="ssQ1Options"
        [formSubmitted]="submitted"
        [errors]="f.ss_q1.errors"
        [required]=true
      ></app-radio-group>
      <app-text-input *ngIf="ssq1show"
        formControlName="ss_q1_text"
        [type]="'text'"
        [name]="'ss_q1_text'"
        [label]="'Reason'"
        [placeholder]="''"
        [formSubmitted]="submitted"
        [errors]="f.ss_q1_text.errors"
      ></app-text-input>
      <app-radio-group
        formControlName="ss_q2"
        [inline]="true"
        [name]="'ss_q2'"
        [label]="label('ss_q2')"
        [options]="ssQ2Options"
        [formSubmitted]="submitted"
        [errors]="f.ss_q2.errors"
        [required]=true
      ></app-radio-group>
      <app-text-input *ngIf="ssq2show"
        formControlName="ss_q2_text"
        [type]="'text'"
        [name]="'ss_q2_text'"
        [label]="'Reason'"
        [placeholder]="''"
        [formSubmitted]="submitted"
        [errors]="f.ss_q2_text.errors"
      ></app-text-input>
      <app-radio-group
        formControlName="ss_q3"
        [inline]="true"
        [name]="'ss_q3'"
        [label]="label('ss_q3')"
        [options]="ssQ3Options"
        [formSubmitted]="submitted"
        [errors]="f.ss_q3.errors"
        [required]=true
      ></app-radio-group>
      <app-text-input *ngIf="ssq3show"
        formControlName="ss_q3_text"
        [type]="'text'"
        [name]="'ss_q3_text'"
        [label]="'Reason'"
        [placeholder]="''"
        [formSubmitted]="submitted"
        [errors]="f.ss_q3_text.errors"
      ></app-text-input>
      <app-radio-group
        formControlName="ss_q4"
        [inline]="true"
        [name]="'ss_q4'"
        [label]="label('ss_q4')"
        [options]="ssQ4Options"
        [formSubmitted]="submitted"
        [errors]="f.ss_q4.errors"
        [required]=true
      ></app-radio-group>
      <app-text-input *ngIf="ssq4show"
        formControlName="ss_q4_text"
        [type]="'text'"
        [name]="'ss_q4_text'"
        [label]="'Reason'"
        [placeholder]="''"
        [formSubmitted]="submitted"
        [errors]="f.ss_q4_text.errors"
      ></app-text-input>
      <app-radio-group
        formControlName="ss_q5"
        [inline]="true"
        [name]="'ss_q5'"
        [label]="label('ss_q5')"
        [options]="ssQ5Options"
        [formSubmitted]="submitted"
        [errors]="f.ss_q5.errors"
        [required]=true
      ></app-radio-group>
      <app-text-input *ngIf="ssq5show"
        formControlName="ss_q5_text"
        [type]="'text'"
        [name]="'ss_q5_text'"
        [label]="'Reason'"
        [placeholder]="''"
        [formSubmitted]="submitted"
        [errors]="f.ss_q5_text.errors"
      ></app-text-input>
      <app-radio-group
        formControlName="ss_q6"
        [inline]="true"
        [name]="'ss_q6'"
        [label]="label('ss_q6')"
        [options]="ssQ6Options"
        [formSubmitted]="submitted"
        [errors]="f.ss_q6.errors"
        [required]=true
      ></app-radio-group>
      <app-text-input *ngIf="ssq6show"
        formControlName="ss_q6_text"
        [type]="'text'"
        [name]="'ss_q6_text'"
        [label]="'Reason'"
        [placeholder]="''"
        [formSubmitted]="submitted"
        [errors]="f.ss_q6_text.errors"
      ></app-text-input>
      <app-radio-group
        formControlName="ss_q7"
        [inline]="true"
        [name]="'ss_q7'"
        [label]="label('ss_q7')"
        [options]="ssQ7Options"
        [formSubmitted]="submitted"
        [errors]="f.ss_q7.errors"
        [required]=true
      ></app-radio-group>
      <app-text-input *ngIf="ssq7show"
        formControlName="ss_q7_text"
        [type]="'text'"
        [name]="'ss_q7_text'"
        [label]="'Reason'"
        [placeholder]="''"
        [formSubmitted]="submitted"
        [errors]="f.ss_q7_text.errors"
      ></app-text-input>
      <app-radio-group
        formControlName="ss_q8"
        [inline]="true"
        [name]="'ss_q8'"
        [label]="label('ss_q8')"
        [options]="ssQ8Options"
        [formSubmitted]="submitted"
        [errors]="f.ss_q8.errors"
        [required]=true
      ></app-radio-group>
      <app-text-input *ngIf="ssq8show"
        formControlName="ss_q8_text"
        [type]="'text'"
        [name]="'ss_q8_text'"
        [label]="'Reason'"
        [placeholder]="''"
        [formSubmitted]="submitted"
        [errors]="f.ss_q8_text.errors"
      ></app-text-input>
     
    </div>
  </div>
  <br>

  <div class="row">
    <div class="col-sm">
      <app-button
        [mode]="'button'"
        [type]="'btn-light'"
        (click)="toPart1()"
        [disabled]="disableBtns"
      >Go Back</app-button>
      <app-button [mode]="'button'" [type]="'btn-secondary'" (click)="onSubmit('save-exit')">Save & Exit</app-button>
    </div>
    <div class="col-sm text-right">
      <app-button [type]="'btn-primary'" (click)="onSubmit('save-continue')">Save & Continue</app-button>
    </div>
  </div>
</form>
