import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AppShellService {

  public steps = [
    'Event Listing',
    'Application',
    'Incentives',
    'Insurance',
    'Add-ons',
    'Checkout'
  ];
  public currentStep: string;
  constructor(
    private authService: AuthenticationService
  ) { }

  setCurrentStep(currentStep: string) {
    this.currentStep = currentStep;
  }

  getCurrentStep() {
    return this.currentStep;
  }

  tempLogin() {
    this.authService.doLogin(window.location.href);
  }

}
