import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormFieldErrors } from '../../classes';

const noop = () => {};

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true
    }
  ]
})
export class TextAreaComponent implements OnInit {
  currentValue = '';

  @Input() name: string;
  @Input() id: number;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() required: boolean;
  @Input() formControlName: string;
  @Input() maxLength: string;
  @Input() formSubmitted: boolean;
  @Input() errors: FormFieldErrors;
  @Input() minHeight: number;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input()
  get value() {
    return this.currentValue;
  }

  set value(val) {
    this.currentValue = val;
    this.change.emit(this.currentValue);
    this.valueChange.emit(this.currentValue);
    this.onChangeCallback(this.currentValue);
  }

  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  writeValue(value: any) {
    this.currentValue = value;
    this.onChangeCallback(value);
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  constructor() { }

  ngOnInit() {
  }

  onChange(event: any) {
    // console.log('input changed', event);
    // this.onChangeCallback(event.target.value);
  }
}
