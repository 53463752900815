import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { BaseService } from './base-service';

import { Certificate } from '../classes';

@Injectable({
  providedIn: 'root'
})
export class CertificateService extends BaseService {

  certificates: Certificate[] = [];

  constructor(
    protected http: HttpClient,
    protected cookie: CookieService
  ) {
    super(http, cookie);
  }

  getCertificates(eventId: number|string): Observable<Certificate[]> {
    return this.http.get<Certificate[]>(this.url(`event/${eventId}/certificates`), this.options)
      .pipe(
        tap(_ => this.log('fetched certificates')),
        catchError(this.handleError<Certificate[]>('getCertificates', [], { eventId }))
      );
  }

  getCertificate(eventId: number|string, certificateId: number|string): Observable<Certificate> {
    return this.http.get<Certificate>(this.url(`event/${eventId}/certificates/${certificateId}`), this.options)
      .pipe(
        tap(_ => this.log('fetched certificate')),
        catchError(this.handleError<Certificate>('getCertificate', null, { eventId, certificateId }))
      );
  }

  addCertificate(eventId: number|string, certificate: Certificate): Observable<Certificate> {
    return this.http.post(this.url(`event/${eventId}/certificates`), certificate, this.options)
      .pipe(
        tap((newCertificate: Certificate) => this.log(`added certificate id: ${newCertificate.id}`)),
        catchError(this.handleError<Certificate>('addCertificate', new Certificate({}), { eventId, certificate }))
      );
  }

  updateCertificate(eventId: number|string, certificate: Certificate): Observable<any> {
    return this.http.put(this.url(`event/${eventId}/certificates/${certificate.id}`), certificate, this.options)
      .pipe(
        tap((updatedCertificate: Certificate) => this.log(`updated certificate id: ${updatedCertificate.id}`)),
        catchError(this.handleError<Certificate>('updateCertificate', new Certificate({}), { eventId, certificate }))
      );
  }

  deleteCertificate(eventId: number|string, certificateId: number|string): Observable<any> {
    return this.http.delete(this.url(`event/${eventId}/certificates/${certificateId}`), this.options)
      .pipe(
        tap(_ => this.log('deleted certificate')),
        catchError(this.handleError<any>('deleteCertificate', null, { eventId, certificateId }))
      );
  }

  addOrUpdateSelectedCert(index: number, certificate: Certificate) {
    const existingCertIndex = this.certificates.findIndex(cert => cert.index === index || (!!cert.id && cert.id === certificate.id));
    existingCertIndex !== -1 ? this.certificates[existingCertIndex] = certificate : this.certificates.push(certificate);
  }

  removeSelectedCert(index: number) {
    const certificate = this.certificates.find(cert => cert.index === index);
    if (certificate) {
      const i = this.certificates.indexOf(certificate);
      this.certificates.splice(i, 1);
    }
  }
}
