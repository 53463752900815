import { OnInit, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import qs from 'qs';
import { SelectOption } from '../../classes';
import { EventService, EventListingService, PermitService, LocationService } from '../../services/';
var EventQueueFilterComponent = /** @class */ (function () {
    function EventQueueFilterComponent(elRef, formBuilder, eventService, eventListing, permitService, locationService) {
        this.elRef = elRef;
        this.formBuilder = formBuilder;
        this.eventService = eventService;
        this.eventListing = eventListing;
        this.permitService = permitService;
        this.locationService = locationService;
        this.submitted = false;
        this.isCollapsed = true;
        this.listingTypeOptions = [];
        this.checkboxOptions = [];
        this.stateOptions = [];
        this.statusOptions = [];
        this.disciplineOptions = [];
        this.regionOptions = [];
        this.postEventStatusOptions = [];
        this.calendarLabelOptions = [];
    }
    EventQueueFilterComponent.prototype.clickout = function (event) {
        if (!this.elRef.nativeElement.firstChild.children[1].firstChild.contains(event.target)
            && event.target.getAttribute('aria-controls') !== 'filterCollapse'
            && !this.datepickerParent(event)
            && !this.multiselectCloseParent(event)) {
            this.isCollapsed = true;
        }
    };
    Object.defineProperty(EventQueueFilterComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.filterForm.controls; },
        enumerable: true,
        configurable: true
    });
    EventQueueFilterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.title = 'Filters';
        this.listingTypeOptions = [
            new SelectOption(null, 'Select...'),
            new SelectOption('permit', 'Permit'),
            new SelectOption('listing', 'Listing')
        ];
        this.statusOptions = this.eventService.statusOptions;
        this.eventListing.getDisciplines().subscribe(function (data) {
            var disciplines = _this.eventListing.filterDisciplines(data);
            _this.disciplineOptions = disciplines.map(function (discipline) {
                return new SelectOption(discipline.cd_id.toString(), discipline.cd_value);
            });
            if (_this.queryParams.disciplines && _this.queryParams.disciplines.length > 0) {
                var discipline_ids_1 = _this.queryParams.disciplines.map(function (discipline) { return discipline.value || discipline; });
                _this.f.event_discipline.setValue(_this.disciplineOptions.filter(function (option) { return discipline_ids_1.includes(option.value); }));
            }
        });
        this.permitService.getCalendarLabels().subscribe(function (labels) {
            if (labels) {
                labels.forEach(function (label) { return _this.calendarLabelOptions.push(new SelectOption(label.id.toString(), label.name)); });
                if (_this.queryParams.calendar_label_ids && _this.queryParams.calendar_label_ids.length > 0) {
                    var calendarLabelIds_1 = _this.queryParams.calendar_label_ids.map(function (labelId) { return labelId.value || labelId; });
                    _this.f.calendar_label_ids.setValue(_this.calendarLabelOptions.filter(function (option) { return calendarLabelIds_1.includes(option.value); }));
                }
            }
        });
        this.cityOptions = '';
        this.regionOptions = [
            new SelectOption('EAST', 'EAST'),
            new SelectOption('WEST', 'WEST'),
            new SelectOption('CENTRAL', 'CENTRAL')
        ];
        this.locationService.stateOptions$.subscribe(function (stateOptions) {
            _this.stateOptions = [
                new SelectOption(null, '...')
            ].concat(stateOptions);
        });
        this.locationService.getStates().subscribe();
        this.postEventStatusOptions = [
            new SelectOption(null, 'Select...'),
            new SelectOption('form-due', 'Form Due'),
            new SelectOption('payment-due', 'Payment Due'),
            new SelectOption('payment-late', 'Payment Late'),
            new SelectOption('payment-complete', 'Payment Complete')
        ];
        this.queryParams = qs.parse(window.location.search.replace(/^\?/, ''));
        // console.log('filter query params', this.queryParams);
        var currentStatuses = [];
        if (this.queryParams.status && this.queryParams.status.length > 0) {
            currentStatuses = this.queryParams.status.map(function (status) { return status.value || status; });
        }
        var currentFilters = {
            name: this.queryParams.name || '',
            listing_type: this.queryParams.listing_type || '',
            status: [this.statusOptions.filter(function (option) { return currentStatuses.includes(option.value); })],
            city: this.queryParams.city || '',
            states: this.queryParams.states || '',
            event_start_date: this.queryParams.event_start_date || null,
            event_end_date: this.queryParams.event_end_date || null,
            permitCreateStartDate: this.queryParams.permit_date_range && this.queryParams.permit_date_range.start_date ? this.queryParams.permit_date_range.start_date : '',
            permitCreateEndDate: this.queryParams.permit_date_range && this.queryParams.permit_date_range.end_date ? this.queryParams.permit_date_range.end_date : '',
            // event_discipline: this.queryParams.disciplines
            // && this.queryParams.disciplines.length > 0 ?
            // [ this.queryParams.disciplines.map(discipline => discipline.value || discipline) ] : [[]],
            regions: this.queryParams.regions && this.queryParams.regions.length > 0 ? [this.queryParams.regions.map(function (region) { return region.value || region; })] : [[]],
            // organizerFirst: '',
            // organizerLast: '',
            // event_organizer: '',
            // refereeFirst: '',
            // refereeLast: '',
            // refereeId: '',
            order_number: this.queryParams.order_number || '',
            post_event_status: this.queryParams.post_event_status || '',
        };
        // console.log('current filters', currentFilters);
        this.filterDefaults = {
            name: '',
            listing_type: '',
            status: '',
            city: '',
            states: '',
            regions: '',
            event_start_date: null,
            event_end_date: null,
            permitCreateStartDate: '',
            permitCreateEndDate: '',
            event_discipline: '',
            organizerFirst: '',
            organizerLast: '',
            event_organizer: '',
            refereeFirst: '',
            refereeLast: '',
            refereeId: '',
            order_number: '',
            post_event_status: '',
            calendar_label_ids: '',
            hasNotifications: false,
            delinquent: false
        };
        this.filterForm = this.formBuilder.group(Object.assign({}, this.filterDefaults, currentFilters));
        // this.f.city.setValidators([Validators.required]);
    };
    EventQueueFilterComponent.prototype.debug = function (event) {
        console.log('debug form', this.f, event);
    };
    EventQueueFilterComponent.prototype.datepickerParent = function (event) {
        var parent = event.target.parentElement;
        return parent && parent.getAttribute('class') && parent.getAttribute('class').includes('ngb-dp-day');
    };
    EventQueueFilterComponent.prototype.multiselectCloseParent = function (event) {
        var parent = event.target.parentElement;
        return parent && parent.getAttribute('class') && parent.getAttribute('class').includes('selected-item');
    };
    EventQueueFilterComponent.prototype.onSubmit = function () {
        this.submitted = true;
        // console.log('submitting form', this.f);
        // stop here if form is invalid
        if (this.filterForm.invalid) {
            return;
        }
        // display form values on success
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.filterForm.value, null, 4));
        this.isCollapsed = true;
        var eventQueueParams = this.eventService.cleanQueueParams(this.filterForm.value);
        window.location.href = window.location.pathname + '?' + qs.stringify(eventQueueParams);
    };
    EventQueueFilterComponent.prototype.onReset = function () {
        this.submitted = false;
        window.location.href = window.location.pathname + '?';
        // this.filterForm.reset(this.filterDefaults);
        // this.onSubmit();
    };
    Object.defineProperty(EventQueueFilterComponent.prototype, "isUsacAdmin", {
        get: function () { return this.userRole === 'usac_admin'; },
        enumerable: true,
        configurable: true
    });
    return EventQueueFilterComponent;
}());
export { EventQueueFilterComponent };
