import { Component, OnInit, Input } from '@angular/core';
import { PermitService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';

import { Event } from 'src/app/classes';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';

@Component({
  selector: 'app-pre-event-permit-application',
  templateUrl: './pre-event-permit-application.component.html',
  styles: []
})
export class PreEventPermitApplicationComponent implements OnInit {

  @Input() event: Event;
  @Input() userRole: string;
  @Input() retrievedAnswers: Array<any>;

  answers: Array<any> = [];
  constructor(
    private permit: PermitService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {

    if (this.retrievedAnswers !== undefined && this.retrievedAnswers !== null) {
      this.retrievedAnswers.forEach(answer => {
        this.answers[answer.qid] = answer;
      });
    }
  }

  answerArray(value: any[]) {
    const answers = [];
    value.forEach(answer => {
      if (answer !== null) { answers.push(answer); }
    });
    return answers.join(', ');
  }

  communicationTypesArray(value: any[]) {
    const answers = [];
    value.forEach(answer => {
      if (answer !== null) { answers.push(this.permit.communicationTypesOptionsList[answer]); }
    });
    return answers.join(', ');
  }

  timingOptionsArray(value: any[]) {
    const answers = [];
    value.forEach(answer => {
      if (answer !== null) { answers.push(this.permit.timingOptionsList[answer]); }
    });
    return answers.join(', ');
  }

  courseMarkingOptionsArray(value: any[]) {
    const answers = [];
    value.forEach(answer => {
      if (answer !== null) { answers.push(this.permit.courseMarkingOptionsList[answer]); }
    });
    return answers.join(', ');
  }

  isUsacAdmin() {
    return this.userRole === 'usac_admin';
  }
}
