/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../atoms/button/button.component.ngfactory";
import * as i2 from "../../atoms/button/button.component";
import * as i3 from "../../molecules/modal/modal.component.ngfactory";
import * as i4 from "../../molecules/modal/modal.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../atoms/modal-body/modal-body.component.ngfactory";
import * as i7 from "../../atoms/modal-body/modal-body.component";
import * as i8 from "@angular/forms";
import * as i9 from "../../atoms/select/select.component.ngfactory";
import * as i10 from "../../atoms/select/select.component";
import * as i11 from "./copy-past-event.component";
var styles_CopyPastEventComponent = [];
var RenderType_CopyPastEventComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CopyPastEventComponent, data: {} });
export { RenderType_CopyPastEventComponent as RenderType_CopyPastEventComponent };
export function View_CopyPastEventComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModal1($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(1, 114688, null, 0, i2.ButtonComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵted(-1, 0, ["Copy past event"])), (_l()(), i0.ɵeld(3, 0, null, null, 22, "app-modal", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeModal1($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ModalComponent_0, i3.RenderType_ModalComponent)), i0.ɵdid(4, 638976, null, 0, i4.ModalComponent, [i5.NgbModal], { id: [0, "id"], title: [1, "title"], isOpen: [2, "isOpen"] }, { close: "close" }), (_l()(), i0.ɵeld(5, 0, null, 0, 20, "app-modal-body", [], null, null, null, i6.View_ModalBodyComponent_0, i6.RenderType_ModalBodyComponent)), i0.ɵdid(6, 49152, null, 0, i7.ModalBodyComponent, [], null, null), (_l()(), i0.ɵeld(7, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."])), (_l()(), i0.ɵeld(9, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, 0, 15, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 12).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 16384, null, 0, i8.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(12, 4210688, null, 0, i8.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i8.ControlContainer, null, [i8.NgForm]), i0.ɵdid(14, 16384, null, 0, i8.NgControlStatusGroup, [[4, i8.ControlContainer]], null, null), (_l()(), i0.ɵeld(15, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 3, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 2, "app-select", [], null, null, null, i9.View_SelectComponent_0, i9.RenderType_SelectComponent)), i0.ɵprd(5120, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i10.SelectComponent]), i0.ɵdid(19, 114688, null, 0, i10.SelectComponent, [], { name: [0, "name"], label: [1, "label"], options: [2, "options"] }, null), (_l()(), i0.ɵeld(20, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 3, "div", [["class", "col text-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 2, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal1($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(24, 114688, null, 0, i2.ButtonComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵted(-1, 0, ["Copy Event"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn btn-secondary d-none"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "copy-event"; var currVal_2 = "Copy from Past Event"; var currVal_3 = _co.modal1Open; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_11 = "copy-past-events"; var currVal_12 = "Search for event you would like to copy"; var currVal_13 = _co.pastEventsOptions; _ck(_v, 19, 0, currVal_11, currVal_12, currVal_13); var currVal_14 = "btn-secondary"; _ck(_v, 24, 0, currVal_14); }, function (_ck, _v) { var currVal_4 = i0.ɵnov(_v, 14).ngClassUntouched; var currVal_5 = i0.ɵnov(_v, 14).ngClassTouched; var currVal_6 = i0.ɵnov(_v, 14).ngClassPristine; var currVal_7 = i0.ɵnov(_v, 14).ngClassDirty; var currVal_8 = i0.ɵnov(_v, 14).ngClassValid; var currVal_9 = i0.ɵnov(_v, 14).ngClassInvalid; var currVal_10 = i0.ɵnov(_v, 14).ngClassPending; _ck(_v, 10, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }); }
export function View_CopyPastEventComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-copy-past-event", [], null, null, null, View_CopyPastEventComponent_0, RenderType_CopyPastEventComponent)), i0.ɵdid(1, 114688, null, 0, i11.CopyPastEventComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CopyPastEventComponentNgFactory = i0.ɵccf("app-copy-past-event", i11.CopyPastEventComponent, View_CopyPastEventComponent_Host_0, {}, {}, []);
export { CopyPastEventComponentNgFactory as CopyPastEventComponentNgFactory };
