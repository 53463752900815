<div class="form-group">
  <label for="{{name}}>">
    {{label}}
    <span *ngIf="required" class="required">*</span>
    <span *ngIf="icon" class="icon {{iconClass}}" [ngbTooltip]="tooltipContent">
    <ng-template #tipContent>{{tooltipContent}}</ng-template></span>
  </label>
  <div class="input-group">
    <div *ngIf="prepend" class="input-group-prepend">
        <span class="input-group-text">{{prependContent}}</span>
    </div>
    <input *ngIf="type !== 'tel' && type !== 'number' && type !== 'currency'"
      type="{{type}}"
      class="form-control"
      [ngClass]="{ 'is-invalid': formSubmitted && errors, 'has-input-group-prepend': prepend, 'has-input-group-append': append, 'disabled': disabled }"
      name="{{name}}"
      id="{{id}}"
      [(ngModel)]="value"
      (change)="onChange($event)"
      placeholder="{{placeholder}}"
      readonly="{{(readonly ? 'readonly': '')}}"
      [disabled]="disabled"
      maxlength="{{maxlength}}"
    />
    <input *ngIf="type === 'number'"
      type="{{type}}"
      class="form-control"
      [ngClass]="{ 'is-invalid': formSubmitted && errors, 'has-input-group-prepend': prepend, 'has-input-group-append': append, 'disabled': disabled }"
      name="{{name}}"
      id="{{id}}"
      [(ngModel)]="value"
      (change)="onChange($event)"
      placeholder="{{placeholder}}"
      readonly="{{(readonly ? 'readonly': '')}}"
      [disabled]="disabled"
      min="{{min}}"
      max="{{max}}"
      appNumberInput
    />
    <input *ngIf="type === 'currency'"
      type="{{type}}"
      class="form-control"
      [ngClass]="{ 'is-invalid': formSubmitted && errors, 'has-input-group-prepend': prepend, 'has-input-group-append': append, 'disabled': disabled }"
      name="{{name}}"
      id="{{id}}"
      [(ngModel)]="value"
      (change)="onChange($event)"
      placeholder="{{placeholder}}"
      readonly="{{(readonly ? 'readonly': '')}}"
      [disabled]="disabled"
      min="{{min}}"
      max="{{max}}"
    />
    <input *ngIf="type === 'tel'"
      type="{{type}}"
      class="form-control"
      [ngClass]="{ 'is-invalid': formSubmitted && errors, 'has-input-group-prepend': prepend, 'has-input-group-append': append, 'disabled': disabled }"
      name="{{name}}"
      id="{{id}}"
      [(ngModel)]="value"
      (change)="onChange($event)"
      placeholder="{{placeholder}}"
      readonly="{{(readonly ? 'readonly': '')}}"
      [disabled]="disabled"
      appPhoneNumberFormatter
    />
    <div class="input-group-append" *ngIf="append">
      <span class="input-group-text">{{appendContent}}</span>
    </div>
  </div>
  <div *ngIf="formSubmitted && errors" class="invalid-feedback">
    <div *ngIf="errors.required || errors.noWhitespace">Required</div>
    <div *ngIf="errors.email">Please enter a valid email address</div>
    <div *ngIf="errors.nameCharacters">Please limit your name to valid characters (A-Z,a-z,.,',-)</div>
    <div *ngIf="errors.phoneNumber">Please enter a valid 10 digit US phone number, no country code necessary</div>
    <div *ngIf="errors.year">Please enter a valid year</div>
    <div *ngIf="errors.maxVehicleYear">Please enter a valid vehicle year</div>
    <div *ngIf="errors.min">Enter a value of at least {{min}}</div>
    <div *ngIf="errors.minlength">Not long enough ({{errors.minlength.requiredLength}} min)</div>
    <div *ngIf="errors.maxlength">Too long ({{errors.maxlength.requiredLength}} max)</div>
    <div *ngIf="errors.urlMatch">Please enter a valid URL</div>
    <div *ngIf="errors.zipCode">Please enter valid zip code (ex: 12345 or 12345-6789)</div>
    <div *ngIf="errors.pattern"><span *ngIf="patternErrorMessage">{{patternErrorMessage}}</span></div>
  </div>
</div>
