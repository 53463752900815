export class RadioOption {
  id: string;
  value: string|boolean;
  label: string;
  checked: boolean;
  disabled: boolean;

  constructor(id: string, value: string|boolean, label: string, disabled?: boolean) {
    this.id = id;
    this.value = value;
    this.label = label;
    this.disabled = disabled;
  }

  check() {
    this.checked = true;
  }

  unCheck() {
    this.checked = undefined;
  }

  disable() {
    this.disabled = true;
  }

  enable() {
    this.disabled = undefined;
  }
}
