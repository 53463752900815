import { Injectable } from '@angular/core';

import { InsuranceCertificate, CartItem, } from '../classes';
import { InsuranceService } from '../services/insurance.service';


@Injectable({
  providedIn: 'root'
})
export class CartService {

  public refIndex = 0;
  public itemsArrayIndex = 0;
  public items: Array<CartItem>;
  public total = 0;

  constructor(private insurance: InsuranceService) {
    this.items = [];
  }
  getItems(): Array<CartItem> {
    return Object.values(this.items);
  }
  syncCertificates(reffIndex: number, isEdit: boolean) {
    this.insurance.certificates.forEach((cert) => {
      if (cert.reffIndex === reffIndex ) {
        if (!isEdit) {
          this.items.push(new CartItem(cert.name, 1, cert.price, 'Certificate', cert.reffIndex));
        } else {
        //  let hasCartItem = false;
          this.items.forEach((item) => {
            if (item.reff === reffIndex) {
                item.title = cert.name,
                item.price = cert.price;
            //    hasCartItem = true;
            }
          });
        /*  if ( hasCartItem === false ) {
            this.items.push(new CartItem(cert.name, 1, cert.price, 'Certificate', cert.reffIndex));
          } */
        }
      }
    });
    this.calculateTotalAmount();
  }
  removeCartItem(reffIndex: number) {
    this.itemsArrayIndex = 0;
    this.items.forEach((item) => {
      if (item.reff === reffIndex) {
        this.items.splice(this.itemsArrayIndex, 1);
      }
      this.itemsArrayIndex++;
    });
    this.calculateTotalAmount();
  }

  addLicenseTocart(licenseref: number) {
    console.log(this.items);
    if (this.isCartItemExist('Race Director License')) {
      return false;
    }
    this.items.push(new CartItem('Race Director License', 1, 150, 'license', licenseref));
    console.log(this.items);
    this.calculateTotalAmount();
  }

  isCartItemExist(name: string): boolean {
    this.items.forEach((item) => {
      console.log(item.title, name, item.title === name );
      if (item.title === name) {
          return true;
      }
    });
    return false;
  }

  calculateTotalAmount() {
    let total = 0;
    Object.values(this.items).forEach((item) => {
      total += ( item.price * item.quantity );
    });
    this.total = total;
  }
}
