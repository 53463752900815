import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EventListingService, PostEventService } from 'src/app/services';
import * as moment from 'moment';
import { Event, EventDate, SubDiscipline, PostEventRiders, PostEvent, Profile, Alert, ShopifyDraftOrder, ShopifyOrder, ShopifyProductVariant, CalendarLabel, CalendarLabelAnswer, CalendarLabelQuestion } from '../../classes';
import { MerchantAccountTag, ShopifyService } from 'src/app/shared';
import { ShopifyDiscount } from 'src/app/classes';
var PostEvent2FormComponent = /** @class */ (function () {
    function PostEvent2FormComponent(formBuilder, postEventService, router, shopifyService, eventListing) {
        this.formBuilder = formBuilder;
        this.postEventService = postEventService;
        this.router = router;
        this.shopifyService = shopifyService;
        this.eventListing = eventListing;
        this.submitted = false;
        this.products = [];
        this.alerts = [];
        this.displayAlert = false;
        this.uniqueRiderTotal = 0;
        this.uniqueRiders = {};
        this.riderSurchargeTotal = 0;
        this.riderSurcharges = {};
        this.licenseTotal = 0;
        this.membershipTotal = 0;
        this.orders = [];
        this.lateFee = false;
        // 20¢ per rider fee
        this.eventIncentivesDiscountAmt = 0.20;
        this.loading = true;
        this.updating = false;
        this.disableBtns = false;
        // Wait for 2 seconds after changes stop to update draft order
        this.updateDraftDelay = 2000;
        // Product SKUs
        this.riderSurchargeSkus = {};
        this.memberPremiumSku = environment.postEventMembershipPremium;
        this.memberCollegiateSku = environment.postEventMembershipCollegiate;
        this.memberJuniorSku = environment.postEventMembershipJunior;
        this.skus = [];
        this.riderSurchargeVariants = {};
        this.licenseMembershipVariants = {};
        this.variantTotals = {};
        this.excludeFromCharges = [
            'post_event_one_day_online',
        ];
        // TODO: Determine max ceiling for registrations, riders, products, etc.
        this.minQuantity = 1;
        this.maxQuantity = 9999;
        // commented out for MBR-2992
        // subDisciplines: SubDiscipline[] = [];
        // tslint:disable-next-line: max-line-length
        this.riderInstructions = 'This section is where you will enter the unique riders for each sub-discipline you had, per day. For riders that have raced in more than one discipline per day, please ONLY count that rider for their first event of the day.';
        this.participantControls = {};
        this.riderControls = {};
        this.groupedParticipantControls = {};
        this.groupedRiderControls = {};
    }
    Object.defineProperty(PostEvent2FormComponent.prototype, "eventId", {
        get: function () { return (this.event.event_id); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent2FormComponent.prototype, "eventDateId", {
        get: function () { return (this.eventDate && this.eventDate.event_date_id) || null; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent2FormComponent.prototype, "postEventId", {
        get: function () { return this.postEvent.post_event_id; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent2FormComponent.prototype, "isSeriesFirstDay", {
        get: function () { return this.isSeries && this.eventDate.event_date_first; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent2FormComponent.prototype, "minimumRiderSurchargeApplicable", {
        get: function () { return !this.isSeries || this.isSeriesFirstDay; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent2FormComponent.prototype, "pf", {
        get: function () { return this.participantForm.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent2FormComponent.prototype, "rf", {
        get: function () { return this.riderForm.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent2FormComponent.prototype, "lmf", {
        get: function () { return this.licenseMembershipForm.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent2FormComponent.prototype, "pwf", {
        get: function () { return this.paperworkForm.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent2FormComponent.prototype, "ftf", {
        get: function () { return this.feeTotalsForm.controls; },
        enumerable: true,
        configurable: true
    });
    PostEvent2FormComponent.prototype.ngOnInit = function () {
        // commented out for MBR-2992
        // if (this.isSeries) {
        //   this.subDisciplines = this.eventDate.event_date_sub_disciplines.sort(this.compareSubDisciplines);
        // }
        this.minimumRiderSurchargeApplied = this.minimumRiderSurchargeApplicable;
        this.setAlerts();
        this.getSkus();
        this.loadProductVariantsAndBuildForms();
        this.lateFee = this.postEventService.checkLateFee(this.isSeries, this.eventDates, this.eventDate);
    };
    PostEvent2FormComponent.prototype.setAlerts = function () {
        this.alerts.push(new Alert('info', 'You must complete Part 1 of the Post Event form before preceding to Part 2.', 'Part 1 Incomplete', 'clipboard', 'Go Back', this.part1Url, null, false));
    };
    PostEvent2FormComponent.prototype.getSkus = function () {
        var _this = this;
        this.skus = [
            this.memberPremiumSku,
            this.memberCollegiateSku,
            this.memberJuniorSku,
        ];
        var skus = environment.disciplineSkus[this.event.event_discipline];
        Object.keys(skus).forEach(function (key) {
            if (key.includes('riderSurcharge')) {
                _this.riderSurchargeSkus[key] = skus[key];
                _this.skus.push(skus[key]);
            }
        });
        // Membership SKUs assigned earlier (not discipline-specific)
        this.oneDayOnSiteSku = skus.oneDayOnSiteLic;
        this.oneDayOnlineSku = skus.oneDayOnlineLic;
        this.lateFeeSku = skus.permitLateFee;
        this.minimumRiderSurchargeSku = skus.minimumRiderSurcharge;
        this.skus.push(this.oneDayOnSiteSku);
        this.skus.push(this.oneDayOnlineSku);
        this.skus.push(this.lateFeeSku);
        this.skus.push(this.minimumRiderSurchargeSku);
    };
    PostEvent2FormComponent.prototype.loadProductVariantsAndBuildForms = function () {
        var _this = this;
        this.eventListing.getDisciplines().subscribe(function (data) {
            var discs = _this.eventListing.filterDisciplines(data);
            _this.disciplineName = _this.eventListing.getDisciplineName(discs, _this.event.event_discipline);
        });
        this.shopifyService.getShopifyProductVariants({ skus: this.skus }).subscribe(function (variants) {
            _this.variants = variants;
            Object.keys(_this.riderSurchargeSkus).forEach(function (key) {
                _this.riderSurchargeVariants[key] = _this.findVariant(variants, _this.riderSurchargeSkus[key]);
            });
            _this.oneDayOnSiteVariant = _this.findVariant(variants, _this.oneDayOnSiteSku);
            _this.oneDayOnlineVariant = _this.findVariant(variants, _this.oneDayOnlineSku);
            _this.lateFeeVariant = _this.findVariant(variants, _this.lateFeeSku);
            _this.minimumRiderSurchargeVariant = _this.findVariant(variants, _this.minimumRiderSurchargeSku);
            _this.riderSurchargeTotal = _this.minimumRiderSurchargeApplied ? _this.minimumRiderSurchargePrice : 0;
            _this.memberPremiumVariant = _this.findVariant(variants, _this.memberPremiumSku);
            _this.memberCollegiateVariant = _this.findVariant(variants, _this.memberCollegiateSku);
            _this.memberJuniorVariant = _this.findVariant(variants, _this.memberJuniorSku);
            _this.licenseMembershipVariants = {
                post_event_one_day_on_site: _this.oneDayOnSiteVariant,
                post_event_one_day_online: _this.oneDayOnlineVariant,
                post_event_membership_premium: _this.memberPremiumVariant,
                post_event_membership_collegiate: _this.memberCollegiateVariant,
                post_event_membership_junior: _this.memberJuniorVariant
            };
            _this.buildFormsAndFetchPostEvent();
        });
    };
    PostEvent2FormComponent.prototype.findVariant = function (variants, sku) {
        return variants.find(function (variant) { return sku === variant.sku; });
    };
    Object.defineProperty(PostEvent2FormComponent.prototype, "lateFeePrice", {
        get: function () {
            if (this.lateFee) {
                return this.lateFeeVariant.price;
            }
            else {
                return 0;
            }
        },
        enumerable: true,
        configurable: true
    });
    PostEvent2FormComponent.prototype.getOrdersAndDraftOrderAndSubscribeToForms = function () {
        this.getDraftOrderAndSubscribeToForms();
        this.getOrders();
    };
    PostEvent2FormComponent.prototype.getDraftOrderAndSubscribeToForms = function () {
        var _this = this;
        this.shopifyService.getShopifyDraftOrders({ post_event_id: this.postEventId }).subscribe(function (draftOrders) {
            _this.draftOrders = draftOrders;
            // Only assign original draft order
            if (draftOrders.length) {
                _this.draftOrder = draftOrders[0];
            }
            _this.loading = false;
            _this.subscribeToFormsAndAssignPostEvent();
        });
    };
    PostEvent2FormComponent.prototype.getOrders = function () {
        var _this = this;
        this.shopifyService.getShopifyOrders({ post_event_id: this.postEventId }).subscribe(function (orders) {
            _this.orders = orders;
        });
    };
    PostEvent2FormComponent.prototype.buildFormsAndFetchPostEvent = function () {
        this.buildForms();
        this.fetchPostEvent();
    };
    PostEvent2FormComponent.prototype.buildForms = function () {
        this.buildParticipantAndRiderForms();
        this.buildLicenseMembershipForm();
        this.groupedParticipantControls = this.setControlGroups(this.pf);
        this.groupedRiderControls = this.setControlGroups(this.rf);
        this.paperworkForm = this.formBuilder.group({ post_event_paperwork_license_memberships: false });
        // console.log('Grouped participant controls:', this.groupedParticipantControls);
        // console.log('Grouped rider controls: ', this.groupedRiderControls);
    };
    PostEvent2FormComponent.prototype.buildParticipantAndRiderForms = function () {
        var _this = this;
        // commented out for MBR-2992
        // if (this.isSeries) {
        //   this.subDisciplines.forEach(subDisc => {
        //     this.buildEventDateSubDisciplineControl('participants', this.participantControls, this.eventDate, subDisc);
        //     this.buildEventDateSubDisciplineControl('riders', this.riderControls, this.eventDate, subDisc);
        //   });
        // } else {
        this.eventDates.forEach(function (date) {
            _this.buildEventDateDisciplineControl('participants', _this.participantControls, date, date.event_date_event_discipline);
            _this.buildEventDateDisciplineControl('riders', _this.riderControls, date, date.event_date_event_discipline);
        });
        // }
        this.participantForm = this.formBuilder.group(this.participantControls);
        // console.log('Event participants form generated: ', this.pf);
        this.riderForm = this.formBuilder.group(this.riderControls);
        // console.log('Rider fees form generated: ', this.rf);
    };
    PostEvent2FormComponent.prototype.buildEventDateDisciplineControl = function (type, controls, date, disc) {
        var _this = this;
        var prefix = "post_event_" + type + "_";
        var keyBase;
        if (date.event_date_sub_disciplines && date.event_date_sub_disciplines.length) {
            keyBase = "event_date_" + date.event_date_id + "_subdisc_" + date.event_date_sub_disciplines[0].subdiscipline_id + "_";
        }
        else {
            keyBase = "event_date_" + date.event_date_id + "_disc_" + disc + "_";
        }
        if (type === 'participants') {
            ['pre_registered', 'registered_on_site', 'total'].forEach(function (suffix) {
                controls[prefix + keyBase + suffix] = ['0', Validators.required];
            });
        }
        else {
            var validators_1 = [Validators.required];
            var startDate_1 = moment(date.event_date_start).startOf('day');
            var today_1 = moment().startOf('day');
            ['unique', 'cost', 'fee_total'].forEach(function (suffix) {
                var skuPartial = _this.disciplineName.toUpperCase().replace(/ /g, '-');
                var variant = _this.variants.find(function (v) { return v.sku === "RIDER-SUR-" + skuPartial; });
                var value = suffix === 'cost' && variant ? variant.price : '0';
                // Require minimum of 1 unique rider if event date has passed
                if (suffix === 'unique' && startDate_1.isBefore(today_1)) {
                    validators_1.push(Validators.min(1));
                }
                var name = prefix + keyBase + suffix;
                controls[name] = [value, validators_1];
            });
        }
    };
    PostEvent2FormComponent.prototype.buildLicenseMembershipForm = function () {
        var _this = this;
        this.licenseMembershipForm = this.formBuilder.group({
            post_event_one_day_on_site: [0, Validators.required],
            post_event_one_day_online: [0, Validators.required],
            post_event_membership_premium: [0, Validators.required],
            post_event_membership_collegiate: [0, Validators.required],
            post_event_membership_junior: [0, Validators.required]
        });
        // console.log('License/Membership form generated: ', this.lmf);
        Object.keys(this.lmf).forEach(function (key) { return _this.variantTotals[key] = 0; });
    };
    PostEvent2FormComponent.prototype.subscribeToFormsAndAssignPostEvent = function () {
        this.subscribeToParticipantForm();
        this.subscribeToRiderForm();
        this.subscribeToLicenseMembershipForm();
        this.assignPostEventToForms();
    };
    PostEvent2FormComponent.prototype.subscribeToParticipantForm = function () {
        var _this = this;
        Object.keys(this.groupedParticipantControls).forEach(function (group) {
            var controls = Object.keys(_this.groupedParticipantControls[group]);
            var preRegistered = controls.find(function (control) { return control.includes('pre_registered'); });
            var onSite = controls.find(function (control) { return control.includes('on_site'); });
            var total = controls.find(function (control) { return control.includes('total'); });
            // Update 'Total Registrations' on either 'Pre-Registered' or 'On-Site Registrations' changes
            _this.participantForm.get(preRegistered).valueChanges.subscribe(function (value) {
                var sum = value ? (parseInt(value) + parseInt(_this.pf[onSite].value)).toString() : '0';
                _this.pf[total].setValue(sum);
            });
            _this.participantForm.get(onSite).valueChanges.subscribe(function (value) {
                var sum = value ? (parseInt(value) + parseInt(_this.pf[preRegistered].value)).toString() : '0';
                _this.pf[total].setValue(sum);
            });
        });
    };
    PostEvent2FormComponent.prototype.subscribeToRiderForm = function () {
        var _this = this;
        Object.keys(this.groupedRiderControls).forEach(function (group) {
            var controls = Object.keys(_this.groupedRiderControls[group]);
            var uniqueRiders = controls.find(function (control) { return control.includes('unique') && !control.includes('fee'); });
            var cost = controls.find(function (control) { return control.includes('cost'); });
            var totalFees = controls.find(function (control) { return control.includes('fee_total'); });
            _this.uniqueRiders[group] = 0;
            _this.riderSurcharges[group] = 0;
            // Update 'Total' & riderSurchargeTotal on 'Riders' changes (using product price)
            _this.riderForm.get(uniqueRiders).valueChanges.subscribe(function (value) {
                var riders = parseInt(value);
                var total = riders * parseFloat(_this.rf[cost].value);
                var uniqueRiderTotal = 0;
                var surchargeTotal = 0;
                _this.uniqueRiders[group] = riders;
                _this.riderSurcharges[group] = total;
                _this.rf[totalFees].setValue(total.toString());
                Object.keys(_this.riderSurcharges).forEach(function (key) {
                    uniqueRiderTotal += _this.uniqueRiders[key];
                    surchargeTotal += _this.riderSurcharges[key];
                });
                _this.uniqueRiderTotal = uniqueRiderTotal;
                _this.applyMinimumRiderSurcharge(surchargeTotal);
                _this.applyEventIncentivesDiscount();
                _this.updatePostEventAndDraftOrder();
            });
        });
    };
    PostEvent2FormComponent.prototype.subscribeToLicenseMembershipForm = function () {
        var _this = this;
        this.licenseMembershipForm.valueChanges.subscribe(function (controls) {
            // Calcuate total license/Membership item costs (using product price)
            Object.keys(controls).forEach(function (control) {
                if (!_this.excludeFromCharges.includes(control)) {
                    _this.variantTotals[control] = (parseInt(controls[control]) * _this.variantPrice(_this.licenseMembershipVariants, control)) || 0;
                }
            });
            // Update 'One Day Licenses' & 'Memberships' totals
            _this.licenseTotal = _this.sumVariantTotals(_this.licenses);
            _this.membershipTotal = _this.sumVariantTotals(_this.memberships);
            _this.updatePostEventAndDraftOrder();
        });
    };
    PostEvent2FormComponent.prototype.updateFeeTotalsForm = function (feeTotalsForm) {
        this.feeTotalsForm = feeTotalsForm;
        this.updatePostEventAndDraftOrder();
    };
    PostEvent2FormComponent.prototype.fetchPostEvent = function () {
        var _this = this;
        if (this.isSeries) {
            this.postEventService.getPostEventByEventDate(this.eventId, this.eventDateId).subscribe(function (postEvent) {
                if (postEvent) {
                    // Check required field from part 1 to see if completed
                    if (postEvent.post_event_sufficient_staff === null) {
                        _this.displayAlert = true;
                    }
                    else {
                        _this.postEvent = postEvent;
                        _this.getOrdersAndDraftOrderAndSubscribeToForms();
                    }
                }
                else {
                    _this.displayAlert = true;
                }
            });
        }
        else {
            this.postEventService.getPostEventsByEvent(this.eventId).subscribe(function (postEvents) {
                _this.postEvent = postEvents && postEvents.length && postEvents[0];
                if (_this.postEvent) {
                    // Check required field from part 1 to see if completed
                    if (_this.postEvent.post_event_sufficient_staff === null) {
                        _this.displayAlert = true;
                    }
                    else {
                        _this.getOrdersAndDraftOrderAndSubscribeToForms();
                    }
                }
                else {
                    _this.displayAlert = true;
                }
            });
        }
    };
    PostEvent2FormComponent.prototype.assignPostEventToForms = function () {
        var _this = this;
        // Assign license/membership product quantities
        Object.keys(this.postEvent).forEach(function (key) {
            if (Object.keys(_this.lmf).includes(key)) {
                _this.lmf[key].setValue(_this.postEvent[key] || 0);
            }
        });
        // Assign participant & rider form values
        this.assignNestedPostEventData(this.postEvent.post_event_participants, 'participants', this.pf);
        this.assignNestedPostEventData(this.postEvent.post_event_riders, 'riders', this.rf);
        this.pwf.post_event_paperwork_license_memberships.setValue(this.postEvent.post_event_paperwork_license_memberships || false);
    };
    PostEvent2FormComponent.prototype.assignNestedPostEventData = function (source, type, formControls) {
        var _this = this;
        source.forEach(function (data) {
            var prefix;
            if (data.subdiscipline_id) {
                prefix = "post_event_" + type + "_event_date_" + data.event_date_id + "_subdisc_" + data.subdiscipline_id + "_";
            }
            else {
                prefix = "post_event_" + type + "_event_date_" + data.event_date_id + "_disc_" + _this.event.event_discipline + "_";
            }
            Object.keys(data).forEach(function (key) {
                var matches = type === 'participants' ? key.match(/(?:participants_)(.*)/g) : key.match(/(?:riders_)(.*)/g);
                if (matches) {
                    var control = type === 'participants' ? prefix + matches[0].substring(13) : prefix + matches[0].substring(7);
                    if (Object.keys(formControls).includes(control)) {
                        formControls[control].setValue(data[key]);
                    }
                }
            });
        });
    };
    // On form change, update post event & update/create draft order
    PostEvent2FormComponent.prototype.updatePostEventAndDraftOrder = function () {
        var _this = this;
        if (this.postEvent && !this.invalidForm) {
            if (this.updateDraftTimeout) {
                clearTimeout(this.updateDraftTimeout);
            }
            this.updating = true;
            this.updateDraftTimeout = setTimeout(function () {
                _this.postEventService.updatePostEventPart2(_this.postEventId, _this.postEventData).subscribe(function (resp) {
                    if (resp) {
                        _this.updateOrCreateDraftOrder();
                    }
                    // Delay to allow totals to calculate before displaying
                    setTimeout(function () { return _this.updating = false; }, 1200);
                });
            }, this.updateDraftDelay);
        }
    };
    Object.defineProperty(PostEvent2FormComponent.prototype, "invalidForm", {
        get: function () {
            return ((!this.participantForm || !this.riderForm || !this.licenseMembershipForm ||
                !this.paperworkForm || !this.feeTotalsForm) ||
                (this.participantForm.invalid || this.riderForm.invalid || this.licenseMembershipForm.invalid ||
                    this.paperworkForm.invalid));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent2FormComponent.prototype, "postEventData", {
        get: function () {
            var data = Object.assign({}, this.licenseMembershipForm.value, this.paperworkForm.value, this.feeTotalsForm.value, {
                post_event_id: this.postEventId,
                post_event_membership_standard: 0,
                post_event_participants: null,
                // post_event_participants: new PostEventParticipants(this.parseDynamicControlValues(this.pf, 'participants')),
                post_event_riders: new PostEventRiders(this.parseDynamicControlValues(this.rf, 'riders'))
            });
            return data;
        },
        enumerable: true,
        configurable: true
    });
    PostEvent2FormComponent.prototype.onSubmit = function (trigger) {
        var _this = this;
        this.trigger = trigger;
        this.disableBtns = true;
        this.submitted = true;
        // Stop here if form is invalid
        if (this.invalidForm || this.feeTotalsForm.invalid) {
            this.disableBtns = false;
            this.handleValidationFeedback();
            return;
        }
        this.postEventService.updatePostEventPart2(this.postEventId, this.postEventData).subscribe(function (resp) {
            if (resp) {
                if (_this.isOfficialNotOrganizerOrAdmin) {
                    if (_this.trigger === 'pay-now' && _this.postEvent.permit_post_event_status === 'form-due') {
                        // Overwrite trigger to 'pay-later' (official should not be sent to payment)
                        _this.trigger = 'pay-later';
                        _this.postEventService.updatePostEventStatus(_this.postEventId, 'payment-due').subscribe(function (response) {
                            if (response) {
                                _this.updateOrCreateDraftOrder(true);
                            }
                        });
                    }
                    else {
                        // Overwrite trigger to 'pay-later' (official should not be sent to payment)
                        _this.trigger = 'pay-later';
                        _this.updateOrCreateDraftOrder(true);
                    }
                }
                else if (_this.postEvent.permit_post_event_status === 'form-due') {
                    _this.postEventService.updatePostEventStatus(_this.postEventId, 'payment-due').subscribe(function (response) {
                        if (response) {
                            _this.updateOrCreateDraftOrder(true);
                        }
                    });
                }
                else {
                    _this.updateOrCreateDraftOrder(true);
                }
            }
        });
    };
    PostEvent2FormComponent.prototype.continue = function () {
        if (this.trigger === 'pay-later') {
            this.toOverview();
        }
        else if (this.trigger === 'pay-now') {
            this.toCheckout();
        }
    };
    PostEvent2FormComponent.prototype.parseDynamicControlValues = function (controls, prefix) {
        var _this = this;
        var values = [];
        Object.keys(controls).forEach(function (key) {
            var control = controls[key];
            var eventDateMatch = key.match(/(?:date_)(\d*)/g)[0];
            var disciplineMatch = key.match(/(?:disc_)(\d*)/g)[0];
            var suffix = key.split('_').slice(8).join('_');
            if (eventDateMatch && disciplineMatch) {
                var eventDateId_1 = eventDateMatch.substring(5);
                var disciplineId_1 = disciplineMatch.substring(5);
                var peId = 'post_event_id';
                var edId_1 = 'event_date_id';
                var discId_1 = 'discipline_id';
                var existingObj = values.find(function (value) { return value[edId_1] === eventDateId_1 && value[discId_1] === disciplineId_1; });
                if (existingObj) {
                    existingObj[prefix + '_' + suffix] = control.value;
                }
                else {
                    var obj = {};
                    obj[peId] = _this.postEventId;
                    obj[edId_1] = eventDateId_1;
                    obj[discId_1] = disciplineId_1;
                    obj[prefix + '_' + suffix] = control.value;
                    values.push(obj);
                }
            }
        });
        return values;
    };
    PostEvent2FormComponent.prototype.setControlGroups = function (controls) {
        var _this = this;
        var grouped = {};
        // commented out for MBR-2992
        // if (this.isSeries) {
        //   this.subDisciplines.forEach(subDisc => {
        //     const key = `event_date_${this.eventDateId}_subdisc_${subDisc.subdiscipline_id}`;
        //     grouped[key] = this.groupControls(controls, this.eventDate, subDisc);
        //   });
        // } else {
        this.eventDates.forEach(function (eventDate) {
            // commented out for MBR-2992
            // eventDate.event_date_sub_disciplines.forEach(subDisc => {
            var key;
            if (eventDate.event_date_sub_disciplines && eventDate.event_date_sub_disciplines.length) {
                key = "event_date_" + eventDate.event_date_id + "_subdisc_" + eventDate.event_date_sub_disciplines[0].subdiscipline_id;
                grouped[key] = _this.groupControls(controls, eventDate, eventDate.event_date_sub_disciplines[0].subdiscipline_id, true);
            }
            else {
                key = "event_date_" + eventDate.event_date_id + "_disc_" + eventDate.event_date_event_discipline;
                grouped[key] = _this.groupControls(controls, eventDate, eventDate.event_date_event_discipline, false);
            }
        });
        //   });
        // }
        return grouped;
    };
    PostEvent2FormComponent.prototype.groupControls = function (controls, eventDate, eventDiscipline, isSubDiscipline) {
        var filtered = {};
        Object.keys(controls).forEach(function (key) {
            if (isSubDiscipline && key.includes("event_date_" + eventDate.event_date_id + "_subdisc_" + eventDiscipline)) {
                filtered[key] = controls[key];
            }
            if (!isSubDiscipline && key.includes("event_date_" + eventDate.event_date_id + "_disc_" + eventDiscipline)) {
                filtered[key] = controls[key];
            }
        });
        return filtered;
    };
    PostEvent2FormComponent.prototype.handleValidationFeedback = function () {
        var fragment;
        var formGroups = {
            rider: this.riderForm,
            licenseMembership: this.licenseMembershipForm,
            paperwork: this.paperworkForm,
            feeTotals: this.feeTotalsForm
        };
        for (var _i = 0, _a = Object.entries(formGroups); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            if (value.invalid) {
                fragment = key;
                break;
            }
        }
        if (fragment) {
            document.getElementById(fragment).scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        }
    };
    Object.defineProperty(PostEvent2FormComponent.prototype, "isUsacAdmin", {
        get: function () {
            return this.userRole === 'usac_admin';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent2FormComponent.prototype, "isOfficial", {
        get: function () {
            var _this = this;
            // return this.userRole === 'official';
            // TODO: Confirm if 'is_official' permission is actually assigned to every official account
            if (this.isSeries) {
                return (this.eventDate && this.currentUser &&
                    this.eventDate.event_date_chief_ref && this.eventDate.event_date_chief_ref.profile_id === this.currentUser.profile_id);
            }
            else {
                return (this.eventDates && this.currentUser &&
                    this.eventDates.some(function (date) { return date.event_date_chief_ref && date.event_date_chief_ref.profile_id === _this.currentUser.profile_id; }));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent2FormComponent.prototype, "isEventOrganizer", {
        get: function () {
            return this.currentUser.profile_id === this.eventOrganizer.profile_id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent2FormComponent.prototype, "isOfficialNotOrganizerOrAdmin", {
        get: function () {
            return this.isOfficial && !this.isEventOrganizer && !this.isUsacAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent2FormComponent.prototype, "payLaterBtnText", {
        get: function () {
            return this.isOfficialNotOrganizerOrAdmin ? 'Save & Exit' : 'Save & Pay Later';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent2FormComponent.prototype, "payNowBtnText", {
        get: function () {
            return this.isOfficialNotOrganizerOrAdmin ? 'Submit Form' : 'Continue to Payment';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent2FormComponent.prototype, "openDraftOrder", {
        get: function () {
            return this.draftOrder && this.draftOrder.id && !this.draftOrder.order_id;
        },
        enumerable: true,
        configurable: true
    });
    PostEvent2FormComponent.prototype.eventDateFromControl = function (name) {
        var matches = name.match(/(?:date_)(\d*)(?=_subdisc)/g);
        if (matches.length) {
            var id_1 = parseInt(matches[0].substring(5));
            return this.eventDates.find(function (eventDate) { return eventDate.event_date_id === id_1; });
        }
        else {
            throw new Error("Unable to find ID match in string: " + name);
        }
    };
    Object.defineProperty(PostEvent2FormComponent.prototype, "licenses", {
        // commented out for MBR-2992
        // subDisciplineFromControl(name: string, subDisciplines: SubDiscipline[]) {
        //   const matches = name.match(/(?:subdisc_)(\d*)/g);
        //   if (matches.length) {
        //     const id = parseInt(matches[0].substring(8));
        //     return subDisciplines.find(subDisc => subDisc.subdiscipline_id === id);
        //   } else {
        //     throw new Error(`Unable to find ID match in string: ${name}`);
        //   }
        // }
        get: function () {
            var keys = [];
            Object.keys(this.variantTotals).forEach(function (key) { if (key.includes('one_day')) {
                keys.push(key);
            } });
            return keys;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent2FormComponent.prototype, "memberships", {
        get: function () {
            var keys = [];
            Object.keys(this.variantTotals).forEach(function (key) { if (key.includes('membership')) {
                keys.push(key);
            } });
            return keys;
        },
        enumerable: true,
        configurable: true
    });
    PostEvent2FormComponent.prototype.sumVariantTotals = function (variantList) {
        var _this = this;
        var total = 0;
        variantList.forEach(function (item) { return total += (_this.variantTotals[item] || 0); });
        return total;
    };
    PostEvent2FormComponent.prototype.variantPrice = function (variants, key) {
        return variants[key] ? parseFloat(variants[key].price) : 0;
    };
    PostEvent2FormComponent.prototype.parseIfString = function (amount) {
        return typeof amount === 'string' ? parseFloat(amount) : amount;
    };
    Object.defineProperty(PostEvent2FormComponent.prototype, "minimumRiderSurchargePrice", {
        get: function () {
            return this.minimumRiderSurchargeVariant ? parseFloat(this.minimumRiderSurchargeVariant.price) : null;
        },
        enumerable: true,
        configurable: true
    });
    PostEvent2FormComponent.prototype.applyMinimumRiderSurcharge = function (surchargeTotal) {
        this.minimumRiderSurchargeApplied = this.minimumRiderSurchargeApplicable && surchargeTotal <= this.minimumRiderSurchargePrice;
        this.riderSurchargeTotal = this.minimumRiderSurchargeApplied ? this.minimumRiderSurchargePrice : surchargeTotal;
    };
    PostEvent2FormComponent.prototype.onDiscountQualificationChange = function ($event) {
        this.qualifiesForEventIncentivesDiscount = $event;
        this.applyEventIncentivesDiscount();
    };
    PostEvent2FormComponent.prototype.applyEventIncentivesDiscount = function () {
        if (this.feeTotalsForm) {
            var applyDiscount = false;
            var discountAmnt = void 0;
            var surchargeTotal = this.parseIfString(this.riderSurchargeTotal);
            if (this.qualifiesForEventIncentivesDiscount) {
                discountAmnt = this.uniqueRiderTotal * this.eventIncentivesDiscountAmt;
                var postDiscountTotal = surchargeTotal - discountAmnt;
                // Apply discount if min surcharge not applicable or post-discount fees over threshold
                if (!this.minimumRiderSurchargeApplicable || postDiscountTotal >= this.minimumRiderSurchargePrice) {
                    applyDiscount = true;
                }
                // Charge min surcharge if post-discount fees below threshold (instead of higher non-discounted amount)
                if (this.minimumRiderSurchargeApplicable && postDiscountTotal <= this.minimumRiderSurchargePrice) {
                    this.minimumRiderSurchargeApplied = true;
                    this.riderSurchargeTotal = this.minimumRiderSurchargePrice;
                }
            }
            else {
                this.applyMinimumRiderSurcharge(surchargeTotal);
            }
            this.ftf.post_event_discount.setValue(applyDiscount ? discountAmnt : 0);
        }
    };
    PostEvent2FormComponent.prototype.updateOrCreateDraftOrder = function (continueAfter) {
        var _this = this;
        if (continueAfter === void 0) { continueAfter = false; }
        var lineItems = [];
        Object.keys(this.groupedRiderControls).forEach(function (key) {
            var disciplineMatch = key.match(/(?:disc_)(\d*)/g)[0];
            if (disciplineMatch) {
                var disciplineId = parseInt(disciplineMatch.substring(5));
                var quantity = parseInt(_this.rf['post_event_riders_' + key + '_unique'].value);
                var skuPartial_1 = _this.disciplineName.toUpperCase().replace(/ /g, '-');
                var surchargeVariant_1 = _this.variants.find(function (variant) { return variant.sku === "RIDER-SUR-" + skuPartial_1; });
                if (!_this.minimumRiderSurchargeApplied && quantity > 0 && !!surchargeVariant_1) {
                    var existingLineItem = lineItems.find(function (item) { return item.variant_id === surchargeVariant_1.id; });
                    if (existingLineItem) {
                        existingLineItem.quantity += quantity;
                    }
                    else {
                        _this.addLineItem(surchargeVariant_1, quantity, lineItems);
                    }
                }
            }
        });
        Object.keys(this.licenseMembershipVariants).forEach(function (key) {
            if (!_this.excludeFromCharges.includes(key)) {
                var lmVariant = _this.licenseMembershipVariants[key];
                var quantity = parseInt(_this.lmf[key].value);
                if (quantity > 0 && !!lmVariant) {
                    _this.addLineItem(lmVariant, quantity, lineItems);
                }
            }
        });
        if (this.minimumRiderSurchargeApplied) {
            this.addLineItem(this.minimumRiderSurchargeVariant, 1, lineItems);
        }
        if (this.ftf.post_event_late_fee.value && !this.ftf.post_event_late_fee_dismissed.value) {
            this.addLineItem(this.lateFeeVariant, 1, lineItems);
        }
        if (lineItems.length) {
            // TODO: @Brennan - Lets sync a central definition for "default data" on a draft order, to be the same everywhere
            var tags = ["event_id-" + this.eventId, "post_event_id-" + this.postEventId, MerchantAccountTag.Permitting];
            var discountAmnt = parseFloat(this.ftf.post_event_discount.value);
            var discount = new ShopifyDiscount({
                description: 'Post Event Incentive Discount',
                value_type: 'fixed_amount',
                value: discountAmnt,
                amount: discountAmnt,
                title: 'Post Event'
            });
            var note = this.ftf.post_event_order_note.value;
            // Update original draft order if still open
            if (this.openDraftOrder) {
                this.shopifyService.updateDraftOrder(this.draftOrder.id, lineItems, tags, discount, note).subscribe(function (resp) {
                    if (resp) {
                        _this.draftOrder = resp.draft_order;
                        if (continueAfter) {
                            _this.continue();
                        }
                    }
                });
                // Create new draft order if one doesn't already exist
            }
            else if (!this.draftOrder) {
                var customer = {};
                if (this.eventOrganizer && this.eventOrganizer.shopify_customer_id) {
                    customer = {
                        id: this.eventOrganizer.shopify_customer_id,
                    };
                }
                this.shopifyService.createDraftOrder(lineItems, tags, discount, note, customer).subscribe(function (resp) {
                    if (resp) {
                        _this.draftOrder = resp.draft_order;
                        if (continueAfter) {
                            _this.continue();
                        }
                    }
                });
            }
            else {
                if (continueAfter) {
                    this.continue();
                }
            }
        }
    };
    PostEvent2FormComponent.prototype.addLineItem = function (variant, quantity, lineItems) {
        lineItems.push({ variant_id: variant.id, quantity: quantity });
    };
    PostEvent2FormComponent.prototype.toOverview = function () {
        this.router.navigate(["event/" + this.event.event_id + "/overview"], { fragment: 'post-event' });
    };
    PostEvent2FormComponent.prototype.toCheckout = function () {
        window.location.assign(this.draftOrder.invoice_url);
    };
    Object.defineProperty(PostEvent2FormComponent.prototype, "part1Url", {
        get: function () {
            var base = this.urlSegments.slice(0, -1);
            base.push('part-1');
            return base.join('/');
        },
        enumerable: true,
        configurable: true
    });
    PostEvent2FormComponent.prototype.toPart1 = function () {
        this.router.navigate([this.part1Url]);
    };
    PostEvent2FormComponent.prototype.compareSubDisciplines = function (a, b) {
        var subDiscA = a.subdiscipline_name;
        var subDiscB = b.subdiscipline_name;
        var comparison = 0;
        if (subDiscA > subDiscB) {
            comparison = 1;
        }
        else if (subDiscA < subDiscB) {
            comparison = -1;
        }
        return comparison;
    };
    return PostEvent2FormComponent;
}());
export { PostEvent2FormComponent };
