import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { ProfileService, EventService } from '../services';


@Injectable({
  providedIn: 'root',
})
export class EventPaymentGuard implements CanActivate {
  constructor(private router: Router, private profileService: ProfileService, private eventService: EventService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.profileService.getCurrentUser().pipe(
      switchMap(() => {
        const user = this.profileService.currentUser;
        return this.profileService.getUserPermissions().pipe(
          switchMap((permissions) => {
            const userRole = this.profileService.setUserRole(permissions);

            if (userRole === 'usac_admin') {
              return of(true); // Allow access for admin users
            } else {
              if (user.is_pended_outstanding_permit_fee) {
                alert('Please contact eventservices@usacycling.org for further assistance.');
                return of(false); // Deny access
              }else{
                return of(true); // Allow access for not-pended users
              }
            }
          })
        );
      })
    );
  }
}
