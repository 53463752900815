<app-card *ngIf="isPostEvent && hasCalendarLabels" [classes]="'border-info mb-3'">
  <div class="card-body d-flex justify-content-between text-info py-2">
    <div class="my-auto mr-3">{{ labelMessage }}</div>
    <div class="my-auto">
      <app-button [type]="'btn-info'" (click)="openModal()">View Details</app-button>
    </div>
  </div>
</app-card>

<app-modal *ngIf="isPostEvent"
  [id]="'label-review-modal'"
  [title]="'Event Label Review'"
  [isOpen]="modalOpen"
  (close)="closeModal()"
>
  <app-modal-body class="event-incentives-review-modal">
    <ng-container *ngTemplateOutlet="reviewContent"></ng-container>
  </app-modal-body>
</app-modal>

<div *ngIf="!isPostEvent">
  <div class="d-flex justify-content-between mb-3">
    <h4>Event Calendar Labels</h4>
    <app-button [type]="'btn-secondary'" (click)="toIncentives()">Edit Labels</app-button>
  </div>
  <!-- Loader -->
  <div *ngIf="loading" class="ml-1">
    <app-loader [small]="true" [align]="'left'"></app-loader>
  </div>

  <div *ngIf="!loading && hasCalendarLabelAnswers">
    <ng-container *ngTemplateOutlet="reviewContent"></ng-container>
  </div>
</div>

<ng-template #reviewContent>
  <div class="event-incentives-review">
    <div *ngIf="calendarLabels.length">
      <h5>Applicable Event Calendar Labels:</h5>
      <img *ngFor="let label of calendarLabels"
        [src]="label.badge_image"
        [alt]="label.name"
        [title]="label.name"
        class="calendar-label-badge mr-3 mb-3"
      />
    </div>

    <!--change for PERM-210 -->
    <!-- <form *ngIf="isPostEvent" [formGroup]="labelDiscountForm">
      <app-checkbox
        [formControlName]="'event_label_discount'"
        [name]="'event_label_discount'"
        [id]="'event_label_discount'"
        [label]="'Qualifies for Discount'"
        [toggle]="true"
        [inline]="true"
      ></app-checkbox>
    </form> -->

    <div class="card card-body mt-0 mb-3">
      <div *ngFor="let question of calendarLabelQuestions" class="my-2">
        <app-checkbox *ngIf="answerByQuestion(question)"
          [id]="question.id"
          [name]="question.id"
          [label]="question.question"
          [checked]="answerByQuestion(question).answer"
          [disabled]="true"
          [tooltip]="question.tooltip"
        ></app-checkbox>
        <div class="answer-details small text-info">
          {{answerDetails(question)}}
        </div>
      </div>
    </div>
  </div>
</ng-template>
