<br>
<!-- <h6 class="application-header">Medical Information</h6>
<p class="question section-divider" *ngIf="answers[1]">{{answers[1].question}}</p>
<div class="row" *ngIf="answers[1].description" >
  <p class="col-sm-6 question">Contact Name</p>
  <p class="col-sm-6" *ngIf="answers[1].description.name">{{answers[1].description.name}}</p>
  <p class="col-sm-6 question">Contact Email</p>
  <p class="col-sm-6" *ngIf="answers[1].description.email">{{answers[1].description.email}}</p>
  <p class="col-sm-6 question">Contact Phone</p>
  <p class="col-sm-6" *ngIf="answers[1].description.phone">{{answers[1].description.phone}}</p>
</div>
<div class="row section-divider" *ngIf="answers[2]">
  <p class="col-sm-6 question" *ngIf="answers[2].question">{{answers[2].question}}</p>
  <p class="col-sm-6" *ngIf="answers[2].description">{{answerArray(answers[2].description)}}</p>
</div>
<div class="section-divider" *ngIf="answers[3]">
  <p class="question">{{answers[3].question}}</p>
  <div *ngIf="answers[3].description">
    <div class="row" *ngFor="let answer of answers[3].description | keyvalue">
      <p class="col-sm-6" >{{permit.medicalFacilitiesOptionsList[answer.key]}}:</p>
      <p class="col-sm-6" >{{answerArray(answer.value)}}</p>
    </div>
  </div>
</div> -->
<h6 class="application-header section-divider">Event Information</h6>
<div class="row" *ngIf="answers[7]">
  <p class="col-sm-6 question">{{answers[7].question}}</p>
  <p class="col-sm-6" *ngIf="answers[7].description.timingInformation">{{answers[7].description.timingInformation | titlecase}}</p>
  <p class="col-sm-6" *ngIf="answers[7].description.timingProvider && answers[7].description.timingInformation === 'yes'">Provider</p>
  <p class="col-sm-6" *ngIf="answers[7].description.timingProvider && answers[7].description.timingInformation === 'yes'">{{answers[7].description.timingProvider}}</p>
</div>
<div class="row section-divider" *ngIf="answers[8]">
  <p class="col-sm-6 question">{{answers[8].question}}</p>
  <p class="col-sm-6" *ngIf="answers[8].description">{{timingOptionsArray(answers[8].description)}}</p>
</div>
<div class="row section-divider" *ngIf="answers[9]">
  <p class="col-sm-6 question" *ngIf="answers[9].question">{{answers[9].question}}</p>
  <p class="col-sm-6 response" *ngIf="answers[9].answer">{{answers[9].answer}}</p>
</div>
<div class="row section-divider" *ngIf="answers[10]">
  <p class="col-sm-6 question" *ngIf="answers[10].question">{{answers[10].question}}</p>
  <p class="col-sm-6" *ngIf="answers[10].answer">{{answers[10].answer | titlecase}}</p>
</div>
<div class="row section-divider" *ngIf="answers[6]">
  <p class="col-sm-6 question" *ngIf="answers[6].question">{{answers[6].question}}</p>
  <p class="col-sm-6" *ngIf="answers[6].description">{{communicationTypesArray(answers[6].description)}}</p>
</div>
<div class="row section-divider" *ngIf="answers[14]">
    <p class="col-sm-6 question">{{answers[14].question}}</p>
    <p class="col-sm-6" *ngIf="answers[14].answer">{{answers[14].answer | titlecase}}</p>
</div>
<div class="row section-divider" *ngIf="answers[15]">
    <p class="col-sm-6 question">{{answers[15].question}}</p>
    <p class="col-sm-6" *ngIf="answers[15].answer">${{answers[15].answer | number}}</p>
</div>
<h6 class="application-header section-divider">Course Information And Approvals</h6>

<!-- TODO: Attach uploaded url's and show only one either course url or upload url-->
<div class="row" *ngIf="answers[11]">
  <div class="col-sm-12 question" >{{answers[11].question}}</div>
    <div class="col-sm-12" *ngIf="answers[11].description">
      <div *ngFor="let coursemaps of answers[11].description; let i = index">
        <div class="col-md-12 question my-3">Day {{i+1}} - Stage {{i+1}}</div>
          <div class="row mb-2" *ngFor="let answer of coursemaps">
          <span class="col-md-4" *ngIf="answer.courseDisplay"><strong>Course Display: </strong>{{answer.courseDisplay | titlecase}}</span>
          <span class="col-md-4" *ngIf="answer.courseSubdiscOptions.length > 0" ><strong>Subdisciplines: </strong>{{answerArray(answer.courseSubdiscOptions)}}</span>
          <span class="col-md-4" *ngIf="answer.courseUrl"><strong><a [href]="answer.courseUrl" target="_blank">Course Map</a></strong></span>
        </div>
      </div>
    </div>
</div>

<div class="row section-divider" *ngIf="answers[16]">
  <p class="col-sm question">{{answers[16].question}}</p>
  <p class="col-sm" *ngIf="answers[16].answer">{{answers[16].answer | titlecase}}</p>
</div>

<div class="row section-divider" *ngIf="answers[12]">
  <p class="col-sm question">{{answers[12].question}}</p>
  <p class="col-sm" *ngIf="answers[12].description">{{courseMarkingOptionsArray(answers[12].description)}}</p>
</div>
<!-- <div class="row section-divider" *ngIf="answers[4]">
  <p class="col-md-6 question">{{answers[4].question}}</p>
  <p class="col-md-6">{{answers[4].answer | titlecase}}</p>
</div>
<div class="row section-divider" *ngIf="answers[5]">
  <p class="col-sm-12 question">{{answers[5].question}}</p>
  <div class="col-sm-12" *ngIf="answers[5].description">
    <div class="row" *ngFor="let answer of answers[5].description; let i=index">
      <p class="col">{{i+1}}. Contact Name: {{answer.securityContactName}}</p>
      <p class="col">Contact Email: {{answer.securityContactEmail}}</p>
      <p class="col">Contact Phone: {{answer.securityContactPhone}}</p>
    </div>
  </div>
</div> -->


