import * as tslib_1 from "tslib";
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from './base-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
var PostEventService = /** @class */ (function (_super) {
    tslib_1.__extends(PostEventService, _super);
    function PostEventService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.part1Labels = {
            // Registration
            post_event_sufficient_staff: 'Was there sufficient staff?',
            post_event_sufficient_facilities: 'Were there sufficient facilities?',
            post_event_sufficient_time: 'Was there sufficient time allowed?',
            post_event_start_lists_adequate: 'Were the start lists adequate?',
            post_event_check_licenses: 'Did the event check licenses?',
            post_event_registration_comments: 'Additional Comments',
            // Event Conduct
            post_event_flyer_accurate: 'Did the event run as it was described on the event announcement?',
            post_event_course_changes_required: 'Were course changes required?',
            post_event_podium: 'Was there a podium?',
            post_event_beginner_racer_program_event: 'Was this event part of BRP?',
            post_event_conduct_comments: 'Additional Comments',
            // Race Technical Support
            post_event_neutral_support_services: 'Was there neutral support service?',
            post_event_licensed_mechanics: 'Were there licensed mechanics?',
            post_event_timing_company: 'Did the event use a timing company?',
            post_event_technical_support_comments: 'Additional Comments',
            // First Aid/Medical Support
            post_event_medical_support_verified: 'Were there sufficient medical support services?',
            post_event_medical_support_description: 'Describe Medical Facilities',
            post_event_number_of_occurrences: 'Number of Accidents/Occurrences',
            post_event_accident_occurrence_reports: 'Accident/Occurrence Reports',
            // Disciplinary Action
            post_event_rider_discipline: 'Rider Discipline (Select if any to report)',
            post_event_rider_discipline_reports: 'Rider Discipline Reports',
            // Prizes & Awards
            post_event_largest_individual_prize: 'Largest Individual Prize List',
            post_event_prize_equal_pay: 'Equal pay for Men and Women?',
            post_event_prize_distributed_15_min: 'Were the prizes distributed 15 minutes after the results?',
            post_event_posting_area_adequate: 'Was the posting area adequate?',
            post_event_protest_period_observed: 'Was the protest period observed?',
            post_event_prize_comments: 'Additional Comments',
            // Safe sport event checklist
            ss_q1: 'Did all Adult Participants receive direct written communication about the MAAPP and how/where to report concerns within 30 days of the event?',
            ss_q2: 'Was information about MAAPP posted in highly trafficked areas at the event?',
            ss_q3: 'Was information about how to report misconduct posted in highly trafficked areas at the event?',
            ss_q4: 'Were all Adult Participants with (a) regular contact or (b) authority over Minor Athletes checked for Safe Sport training compliance?',
            ss_q5: 'Were all Adult Participants who registered/signed up before the event checked against the Disciplinary Records prior to the event?',
            ss_q6: 'Were all Adult Participants who joined the day of the event checked against the Disciplinary Records?',
            ss_q7: 'Were restrooms and/or changing rooms identified through signage and monitored regularly and randomly?',
            ss_q8: 'Were all Adult Participants seeking access to a designated area checked against the Disciplinary Records before entering (e.g., was there a credentialing process)?',
        };
        _this.part1FormControlSections = [
            // registration
            [
                'post_event_sufficient_staff',
                'post_event_sufficient_facilities',
                'post_event_sufficient_time',
                'post_event_start_lists_adequate',
                'post_event_check_licenses',
                'post_event_registration_comments',
            ],
            // conduct
            [
                'post_event_flyer_accurate',
                'post_event_course_changes_required',
                'post_event_podium',
                'post_event_beginner_racer_program_event',
                'post_event_conduct_comments',
            ],
            // technical
            [
                'post_event_neutral_support_services',
                'post_event_licensed_mechanics',
                'post_event_timing_company',
                'post_event_technical_support_comments',
            ],
            // medical
            [
                'post_event_medical_support_verified',
                'post_event_medical_support_description',
                'post_event_number_of_occurrences',
                'post_event_accident_occurrence_reports',
            ],
            // disciplinary
            [
                'post_event_rider_discipline',
                'post_event_rider_discipline_reports',
            ],
            // prizes
            [
                'post_event_largest_individual_prize',
                'post_event_prize_equal_pay',
                'post_event_prize_distributed_15_min',
                'post_event_posting_area_adequate',
                'post_event_protest_period_observed',
                'post_event_prize_comments',
            ]
        ];
        _this.part2FormControlSections = [
            // Safe Sport Event Checklist
            [
                'ss_q1',
                'ss_q2',
                'ss_q3',
                'ss_q4',
                'ss_q5',
                'ss_q6',
                'ss_q7',
                'ss_q8',
            ]
        ];
        return _this;
    }
    // API Requests
    PostEventService.prototype.createPostEvent = function (data) {
        var _this = this;
        return this.http.post(this.url("post_event_form"), data, this.options)
            .pipe(tap(function (_) { return _this.log('created post event'); }), catchError(this.handleError('createPostEvent', null, { data: data })));
    };
    PostEventService.prototype.getPostEventById = function (postEventId) {
        var _this = this;
        return this.http.get(this.url("post_event_form/" + postEventId), this.options)
            .pipe(tap(function (postEvent) { return _this.log("fetched post event: " + postEvent.post_event_id); }), catchError(this.handleError('getPostEventById', null, { postEventId: postEventId })));
    };
    PostEventService.prototype.getPostEventsByEvent = function (eventId) {
        var _this = this;
        return this.http.get(this.url("post_event_form/event/" + eventId), this.options)
            .pipe(tap(function (_) { return _this.log('fetched post event(s)'); }), catchError(this.handleError('getPostEventsByEvent', [], { eventId: eventId })));
    };
    PostEventService.prototype.getPostEventByEventDate = function (eventId, eventDateId) {
        var _this = this;
        return this.http.get(this.url("post_event_form/event/" + eventId + "/date/" + eventDateId), this.options)
            .pipe(tap(function (postEvent) { return _this.log("fetched post event: " + postEvent.post_event_id); }), catchError(this.handleError('getPostEventByEventDate', null, { eventId: eventId, eventDateId: eventDateId })));
    };
    PostEventService.prototype.updatePostEvent = function (postEventId, data) {
        var _this = this;
        return this.http.put(this.url("post_event_form/" + postEventId), data, this.options)
            .pipe(tap(function (_) { return _this.log("updated post event: " + postEventId); }), catchError(this.handleError('updatePostEvent', null, { postEventId: postEventId, data: data })));
    };
    PostEventService.prototype.updatePostEventPart2 = function (postEventId, data) {
        var _this = this;
        return this.http.put(this.url("post_event_form_part_two/" + postEventId), data, this.options)
            .pipe(tap(function (_) { return _this.log("updated post event (part 2): " + postEventId); }), catchError(this.handleError('updatePostEventPart2', null, { postEventId: postEventId, data: data })));
    };
    PostEventService.prototype.updatePostEventSafeSport = function (postEventId, data) {
        var _this = this;
        return this.http.put(this.url("post_event_form_ss/" + postEventId), data, this.options)
            .pipe(tap(function (_) { return _this.log("updated post event (safe sport): " + postEventId); }), catchError(this.handleError('updatePostEventSafeSport', null, { postEventId: postEventId, data: data })));
    };
    PostEventService.prototype.updatePostEventStatus = function (postEventId, status) {
        var _this = this;
        return this.http.put(this.url("post_event_form/" + postEventId + "/status"), { permit_post_event_status: status }, this.options)
            .pipe(tap(function (_) { return _this.log("updated post event status: " + postEventId); }), catchError(this.handleError('updatePostEventStatus', null, { postEventId: postEventId, status: status })));
    };
    PostEventService.prototype.deletePostEvent = function (postEventId) {
        var _this = this;
        return this.http.delete(this.url("post_event_form/" + postEventId), this.options)
            .pipe(tap(function (_) { return _this.log("deleted post event: " + postEventId); }), catchError(this.handleError('deletePostEvent', null, { postEventId: postEventId })));
    };
    // Shared Helpers
    PostEventService.prototype.checkLateFee = function (isSeries, eventDates, eventDate) {
        if (!eventDate && (!eventDates || !eventDates.length)) {
            return false;
        }
        // If event date (series) or first event date (non-series) started 21 days ago or more, apply a late fee.
        var startDate = isSeries ?
            (eventDate && eventDate.event_date_start) : (eventDates && eventDates[0] && eventDates[0].event_date_start);
        var today = new Date().getTime();
        return (today - Date.parse(startDate)) / 24 / 60 / 60 / 1000 >= 21;
    };
    PostEventService.prototype.outstandingPaperwork = function (pe) {
        var occurrencePpwk = (pe.post_event_number_of_occurrences > 0 && (!pe.post_event_accident_occurrence_reports || !pe.post_event_accident_occurrence_reports.length) && !pe.post_event_paperwork_occurrence_reports);
        var disciplinePpwk = (!!pe.post_event_rider_discipline && (!pe.post_event_rider_discipline_reports || !pe.post_event_rider_discipline_reports.length) && !pe.post_event_paperwork_rider_discipline_reports);
        return occurrencePpwk || disciplinePpwk || !pe.post_event_paperwork_license_memberships;
    };
    PostEventService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PostEventService_Factory() { return new PostEventService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: PostEventService, providedIn: "root" });
    return PostEventService;
}(BaseService));
export { PostEventService };
