import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService, EventListingService, ProfileService, PermitService, DriverVehicleService, PostEventService, QrCodeService } from '../../services';
import { Alert, Event } from 'src/app/classes';
import { ShopifyService } from 'src/app/shared';
import { environment } from 'src/environments/environment';
var EventOverviewComponent = /** @class */ (function () {
    function EventOverviewComponent(route, router, formBuilder, eventService, eventListing, permit, profileService, shopifyService, driverVehicleService, postEventService, qrCodeService) {
        this.route = route;
        this.router = router;
        this.formBuilder = formBuilder;
        this.eventService = eventService;
        this.eventListing = eventListing;
        this.permit = permit;
        this.profileService = profileService;
        this.shopifyService = shopifyService;
        this.driverVehicleService = driverVehicleService;
        this.postEventService = postEventService;
        this.qrCodeService = qrCodeService;
        this.alerts = [];
        this.statusOptions = [];
        this.submitted = false;
        this.tabOrientation = 'horizontal';
        this.breakpoint = 768;
        this.statusText = 'Status';
        this.showStatusError = false;
        this.eventLoading = true;
        this.autoMotoSkus = [];
        this.autoQuant = 0;
        this.motoQuant = 0;
        this.autoTotal = null;
        this.motoTotal = null;
        this.driverTotal = null;
        this.outstandingPaperwork = false;
        this.incompleteForms = false;
        this.closeCancelModalOpen = false;
        this.postEvents = [];
    }
    Object.defineProperty(EventOverviewComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.statusForm.controls; },
        enumerable: true,
        configurable: true
    });
    EventOverviewComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.eventId = this.route.snapshot.paramMap.get('eventId');
                this.statusForm = this.formBuilder.group({
                    status: new FormControl()
                });
                this.eventService.getEventById(this.eventId).subscribe(function (event) {
                    if (event) {
                        _this.event = new Event(event);
                        if (_this.event.event_organizer) {
                            _this.profileService.getProfile(_this.event.event_organizer).subscribe(function (profile) {
                                if (profile) {
                                    _this.organizerName = profile.profile_first_name + ' ' + profile.profile_last_name;
                                    _this.organizerEmail = profile.profile_email;
                                    _this.organizerPhone = profile.profile_contact.contact_phone_1;
                                }
                            });
                        }
                        _this.eventListing.getDisciplines().subscribe(function (data) {
                            var discs = _this.eventListing.filterDisciplines(data);
                            _this.disciplineName = _this.eventListing.getDisciplineName(discs, _this.event.event_discipline);
                        });
                        _this.eventService.getEventDates(event.event_id).subscribe(function (eventDates) {
                            _this.eventDates = _this.eventService.sortEventDates(eventDates);
                        });
                        if (!_this.isVirtual) {
                            try {
                                var skus = environment.disciplineSkus[event.event_discipline];
                                _this.autoSku = skus.autoInsurance;
                                _this.motoSku = skus.motoInsurance;
                                _this.autoMotoSkus = [_this.autoSku, _this.motoSku];
                                _this.permit_id = event.event_permit_id;
                            }
                            catch (e) {
                                console.error(e);
                            }
                        }
                        _this.subscribeToOrdersAndDraftOrders();
                        if (_this.isPermit) {
                            _this.permitLoading = true;
                            _this.permit.getPermitInfo(_this.permit_id).subscribe(function (data) {
                                if (data === null || data === undefined) {
                                    console.log('events are not found , due to the api has down');
                                }
                                _this.permit.answers = data.answers;
                                _this.permitLoading = false;
                            }, function (error) {
                                console.log(error);
                            });
                            var pendingPermitStatuses = ['permit-in-progress', 'permit-in-review'];
                            if (!pendingPermitStatuses.includes(_this.event.event_status)) {
                                _this.qrCodeService.generateQrCode(environment.membershipUrl + "/one-day/" + _this.eventId).subscribe(function (resp) {
                                    _this.qrUri = resp.dataUri;
                                });
                            }
                            _this.permit.getCalendarLabelsForEvent(_this.eventId).subscribe(function (labels) { return _this.calendarLabels = labels; });
                            _this.permit.getCalendarQuestions(_this.event.event_discipline).subscribe(function (questions) {
                                _this.calendarLabelQuestions = questions;
                                _this.permit.getCalendarLabelAnswersForEvent(_this.eventId).subscribe(function (answers) { return _this.calendarLabelAnswers = answers; });
                            });
                        }
                        _this.statusForm = _this.formBuilder.group({
                            status: new FormControl(_this.event.event_status)
                        });
                        _this.setStatusText();
                        _this.f.status.setValue(_this.event.event_status);
                        _this.profileService.getCurrentUser().subscribe(function () {
                            _this.currentUser = _this.profileService.currentUser;
                            _this.profileService.getUserPermissions().subscribe(function (permissions) {
                                _this.userRole = _this.profileService.setUserRole(permissions);
                                if (!_this.isUsacAdmin) {
                                    _this.statusForm.controls.status.disable();
                                }
                                _this.statusOptions = _this.isVirtual ? _this.eventService.listingStatusOptions : _this.eventService.statusOptions;
                                // if (this.userRole === 'la_admin') {
                                //   const limitedOptions = this.statusOptions.slice(0, 2);
                                //   if (limitedOptions.some(option => option.value === this.event.status)) {
                                //     this.statusOptions = limitedOptions;
                                //   } else {
                                //     this.statusForm.controls.status.disable();
                                //   }
                                // }
                            });
                            if (_this.isPermit) {
                                _this.getPostEvents();
                            }
                        });
                    }
                    else {
                        _this.toQueue();
                    }
                });
                this.setTabOrientation(window.innerWidth);
                this.eventListing.mode = 'update';
                this.eventListing.fetchEventData(this.eventId);
                this.eventLoading = false;
                switch (this.route.snapshot.fragment) {
                    case 'notifications':
                        this.activeLevel1 = 'overview';
                        this.activeLevel2 = 'notifications';
                        break;
                    case 'comments':
                        this.activeLevel1 = 'overview';
                        this.activeLevel2 = 'comments';
                        break;
                    case 'payments':
                        this.activeLevel1 = 'overview';
                        this.activeLevel2 = 'payments';
                        break;
                    case 'documents':
                        this.activeLevel1 = 'overview';
                        this.activeLevel2 = 'documents';
                        break;
                    case 'history':
                        this.activeLevel1 = 'overview';
                        this.activeLevel2 = 'history';
                        break;
                    case 'event-listing':
                        this.activeLevel1 = 'pre-event';
                        this.activeLevel2 = 'event-listing';
                        break;
                    case 'permit-application':
                        this.activeLevel1 = 'pre-event';
                        this.activeLevel2 = 'permit-application';
                        break;
                    case 'event-incentives':
                        this.activeLevel1 = 'pre-event';
                        this.activeLevel2 = 'event-incentives';
                        break;
                    case 'insured-persons':
                        this.activeLevel1 = 'pre-event';
                        this.activeLevel2 = 'insured-persons';
                        break;
                    case 'permit-add-ons':
                        this.activeLevel1 = 'pre-event';
                        this.activeLevel2 = 'permit-add-ons';
                        break;
                    case 'referee-assignment':
                        this.activeLevel1 = 'pre-event';
                        this.activeLevel2 = 'referee-assignment';
                        break;
                    case 'post-event': this.activeLevel1 = 'post-event';
                }
                return [2 /*return*/];
            });
        });
    };
    EventOverviewComponent.prototype.debug = function (event) {
        console.log('debug form', this.f, event);
    };
    EventOverviewComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        // console.log('submitting form', this.f);
        // stop here if form is invalid
        if (this.statusForm.invalid) {
            return;
        }
        setTimeout(function () {
            var status = _this.f.status.value.toLocaleLowerCase().split(' ').join('-');
            if (status === 'closed') {
                if (_this.outstandingPaperwork || _this.outstandingPayments || _this.incompleteForms) {
                    _this.closeCancelModalOpen = true;
                }
                else {
                    _this.updateStatus(status);
                }
            }
            else {
                _this.updateStatus(status);
            }
        }, 1);
    };
    EventOverviewComponent.prototype.updateStatus = function (status) {
        var _this = this;
        this.showStatusError = false;
        this.eventService.updateEventStatus(this.eventId, status).subscribe(function (resp) {
            if (resp.message) {
                _this.eventService.getEventById(_this.eventId).subscribe(function (event) {
                    _this.event = new Event(event);
                    _this.eventListing.event_status = event.event_status;
                    _this.setStatusText();
                });
            }
            else {
                _this.showStatusError = true;
            }
        });
    };
    EventOverviewComponent.prototype.closeStatusModal = function ($event) {
        this.closeCancelModalOpen = false;
        // TODO: check for exit of 'closed' without updating status
        if (!$event.updated) {
            this.f.status.setValue(this.event.event_status);
        }
    };
    EventOverviewComponent.prototype.onResize = function (event) {
        this.setTabOrientation(event.target.innerWidth);
    };
    EventOverviewComponent.prototype.setTabOrientation = function (width) {
        this.tabOrientation = width >= this.breakpoint ? 'horizontal' : 'vertical';
    };
    EventOverviewComponent.prototype.setAlerts = function () {
        this.alerts = [];
        this.setInProgressAlert();
        this.setAutoMotoAlert();
    };
    EventOverviewComponent.prototype.setInProgressAlert = function () {
        switch (this.event.event_status) {
            case 'listing-in-progress':
                this.alerts.push(new Alert('info', 'Your event listing needs to be completed before submitting it for approval.', 'Incomplete Listing', 'edit', 'Continue', "/event/" + this.eventId + "/overview#event-listing", null, false));
                break;
            case 'permit-in-progress':
                this.alerts.push(new Alert('info', 'Your permit needs to be completed before submitting it for approval.', 'Incomplete Permit', 'edit', 'Continue', "/event/" + this.eventId + "/overview#permit-application", null, false));
        }
    };
    EventOverviewComponent.prototype.subscribeToOrdersAndDraftOrders = function () {
        var _this = this;
        this.shopifyService.eventDraftOrders.subscribe(function (draftOrders) {
            _this.draftOrders = draftOrders.filter(function (draftOrder) {
                return draftOrder.status === "open" /* open */ || draftOrder.status === "invoice_sent" /* invoice_sent */;
            });
        });
        this.shopifyService.eventOrders.subscribe(function (orders) {
            _this.orders = orders;
            if (_this.orders) {
                _this.setAlerts();
            }
        });
    };
    EventOverviewComponent.prototype.setAutoMotoAlert = function () {
        var _this = this;
        if (this.orders) {
            this.orders.forEach(function (order) {
                order.line_items.forEach(function (item) {
                    if (_this.autoMotoSkus.includes(item.sku)) {
                        if (item.sku === _this.autoSku) {
                            _this.autoQuant += item.quantity;
                        }
                        else {
                            _this.motoQuant += item.quantity;
                        }
                    }
                });
            });
            var stepsCompleted_1 = 0;
            this.driverVehicleService.getVehicles(this.permit_id).subscribe(function (vehicles) {
                _this.autoTotal = vehicles.filter(function (v) { return v.vehicle_type === 'auto'; }).length;
                _this.motoTotal = vehicles.filter(function (v) { return v.vehicle_type === 'moto'; }).length;
                stepsCompleted_1 += 1;
                if (stepsCompleted_1 === 2) {
                    _this.compareVehiclesToDrivers();
                }
            });
            this.driverVehicleService.getDrivers(this.permit_id).subscribe(function (drivers) {
                _this.driverTotal = drivers.length;
                stepsCompleted_1 += 1;
                if (stepsCompleted_1 === 2) {
                    _this.compareVehiclesToDrivers();
                }
            });
        }
    };
    EventOverviewComponent.prototype.compareVehiclesToDrivers = function () {
        if (this.autoQuant > this.autoTotal || this.motoQuant > this.motoTotal
            || this.driverTotal < this.autoTotal || this.driverTotal < this.motoTotal) {
            if (!this.alerts.some(function (alert) { return alert.title === 'Auto/Moto Insurance'; })) {
                this.alerts.push(new Alert('info', 'Please add details for your event\'s auto and motorcycle liability insurances.', 'Auto/Moto Insurance', 'user-add', 'Update', "/event/" + this.eventId + "/overview#permit-add-ons", null, true));
            }
        }
    };
    EventOverviewComponent.prototype.getPostEvents = function () {
        var _this = this;
        this.postEventService.getPostEventsByEvent(this.eventId).subscribe(function (postEvents) {
            _this.postEvents = postEvents;
            // Check for post event with outstanding paperwork
            _this.outstandingPaperwork = !!postEvents.find(function (pe) { return _this.postEventService.outstandingPaperwork(pe); });
            // Check for incomplete post events
            _this.incompleteForms = !!postEvents.find(function (pe) { return pe.permit_post_event_status === 'form-due'; });
        });
    };
    EventOverviewComponent.prototype.alertEventStatus = function () {
        var msg = 'The Race director has been pended';
        if (!this.event.is_pended_outstanding_permit_fee) {
            msg = 'The Race director has been unpended';
        }
        alert(msg);
    };
    EventOverviewComponent.prototype.toggleOutstandingPermitFee = function () {
        var _this = this;
        this.eventService.togglePendRaceDirector(this.eventId).subscribe(function (event) {
            _this.event.is_pended_outstanding_permit_fee = event.is_pended_outstanding_permit_fee;
            _this.alertEventStatus();
        });
    };
    EventOverviewComponent.prototype.toListing = function () {
        // Force listing reload before editing
        window.location.assign("/event/" + this.eventId + "/listing");
    };
    EventOverviewComponent.prototype.toQueue = function () {
        this.router.navigate(['/queue']);
    };
    Object.defineProperty(EventOverviewComponent.prototype, "isPermit", {
        get: function () {
            return !!this.permit_id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewComponent.prototype, "isVirtual", {
        get: function () {
            return this.event.isVirtual;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewComponent.prototype, "isRaceOrRide", {
        get: function () {
            return this.event.isRaceOrRide;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewComponent.prototype, "isAdmin", {
        get: function () {
            return this.isUsacAdmin || this.isLaAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewComponent.prototype, "isUsacAdmin", {
        get: function () {
            return this.userRole === 'usac_admin';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewComponent.prototype, "isLaAdmin", {
        get: function () {
            return this.userRole === 'la_admin';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewComponent.prototype, "isEventOrganizer", {
        get: function () {
            return this.currentUser && this.currentUser.profile_id === this.event.event_organizer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewComponent.prototype, "isListingOwner", {
        get: function () {
            var currentUser = this.profileService.currentUser;
            return currentUser && currentUser.profile_id === this.event.event_listing_owner;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewComponent.prototype, "isOfficial", {
        get: function () {
            var _this = this;
            return this.userRole === 'official' || (this.eventDates && this.currentUser &&
                this.eventDates.some(function (date) { return date.event_date_chief_ref && date.event_date_chief_ref.profile_id === _this.currentUser.profile_id; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewComponent.prototype, "showComments", {
        get: function () {
            return this.isAdmin || this.isEventOrganizer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewComponent.prototype, "showListingEditBtn", {
        get: function () {
            return !this.eventLoading && (this.isUsacAdmin || (this.event.event_status === 'listing-in-progress' && (this.isListingOwner || this.isEventOrganizer)));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewComponent.prototype, "showPermitEditBtn", {
        get: function () {
            return !this.permitLoading && (this.isUsacAdmin || (this.event.event_status === 'permit-in-progress' && (this.isListingOwner || this.isEventOrganizer)));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewComponent.prototype, "showPayments", {
        get: function () {
            return this.isPermit && (!this.userRole || this.isUsacAdmin || this.isEventOrganizer || this.isListingOwner);
        },
        enumerable: true,
        configurable: true
    });
    EventOverviewComponent.prototype.eventTypeText = function () {
        return this.isPermit ? 'Permit' : 'Listing';
    };
    EventOverviewComponent.prototype.laText = function () {
        return this.event.event_local_association ? this.event.event_local_association.association_name : 'N/A';
    };
    Object.defineProperty(EventOverviewComponent.prototype, "permitNumber", {
        get: function () {
            return this.event.event_permit_id ? this.event.event_permit_id : 'N/A';
        },
        enumerable: true,
        configurable: true
    });
    EventOverviewComponent.prototype.editBtnText = function () {
        return this.isPermit ? 'Edit Permit' : 'Permit Your Event';
    };
    EventOverviewComponent.prototype.setStatusText = function () {
        this.statusText = this.cancelled() ? 'Event Cancelled' : 'Status';
    };
    EventOverviewComponent.prototype.beforeEvent = function () {
        var start = new Date(this.event.event_start_date);
        var today = new Date();
        return start > today;
    };
    Object.defineProperty(EventOverviewComponent.prototype, "inPostEvent", {
        get: function () {
            return this.event.event_status === 'in-post-event';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewComponent.prototype, "outstandingPayments", {
        get: function () {
            return this.draftOrders && !!this.draftOrders.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewComponent.prototype, "showEventIncentiveTab", {
        get: function () {
            return this.isPermit && this.isRaceOrRide;
        },
        enumerable: true,
        configurable: true
    });
    EventOverviewComponent.prototype.cancelled = function () {
        return this.event.event_status === 'cancelled';
    };
    EventOverviewComponent.prototype.allowCancel = function () {
        return (!this.cancelled() && (this.isUsacAdmin ||
            ((this.isEventOrganizer || this.isListingOwner) && this.beforeEvent() && !this.inPostEvent)));
    };
    EventOverviewComponent.prototype.toPermit = function () {
        if (this.event.event_status === 'listing-in-progress') {
            this.toListing();
        }
        else {
            this.router.navigate(["/event/" + this.eventId + "/permit-application/part-1"]);
        }
    };
    return EventOverviewComponent;
}());
export { EventOverviewComponent };
