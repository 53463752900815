import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  CalendarLabel,
  CalendarLabelAnswer,
  EventIncentivesFormAnswer,
  CalendarLabelQuestion,
  CheckboxOption,
  CourseMap
} from '../classes';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { BaseService } from './base-service';
import { EventService } from './events.service';
import { CookieService } from 'ngx-cookie-service';
import { Subject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class PermitService extends BaseService {

  // public contactName: string;
  // public contactEmail: string;
  // public contactPhone: number;
  // public medicalStaffName: Array<string> = [];
  // public medicalFacilities: object = {};
  public timingInformation = '';
  public timingProvider: string;
  public registrationProvider: string;
  public communicationTypes: Array<string> = [];
  public courseMarking: Array<string> = [];
  // public policeSecurity: string;
  // public securityContacts: Array<any> = [];
  public timings: Array<string>;
  public courseMaps: Array<Array<CourseMap>> = [];
  public stageArea = '';
  // public payOut: string;
  public maxPayOut: number;
  public mode = 'create';
  public answers: Array<any>;
  public permitId: number;

  public status = 'In Progress';

  public localPermitApproval: string;

  // public medicalFacilitiesOptionsList = {
  //   ambulance: 'Ambulance',
  //   firstAidTent: 'First Aid Tent',
  //   DoctorOrMd: 'Doctor/MD',
  //   nurse: 'Nurse',
  //   athleticTrainer: 'Athletic Trainer',
  //   other: 'Other (elaborate)'
  // };

  public communicationTypesOptionsList = {
    twoWayRadio: 'Two-way Radio',
    cellPhone: 'Cell Phone',
    amateurRadioOperators: 'Amateur Radio Operators'
  };

  public timingOptionsList = {
    entireCourse: 'Entire Course',
    segments: 'Segments',
    noTiming: 'No Timing'
  };

  public courseMarkingOptionsList = {
    directionalSignage: 'Directional Signage',
    distanceMarkers: 'Distance Markers',
    roadMarkings: 'Road Markings',
    hazardousSectionWarnings: 'Hazardous Section Warnings',
    feedZoneMarkers: 'Feed Zone Markers',
    fencing: 'Fencing',
    barricades: 'Barricades',
    directionArrows: 'Directional Arrows',
    courseTape: 'Course Tape',
    courseDiscs: 'Course Discs',
    artificialBarricades: 'Artificial Barricades',
    artificialSandPits: 'Artificial Sand Pits',
    bikeWash: 'Bike Wash',
    aidStations: 'Aid Stations',
    gpsRouteMaps: 'GPS Route Maps'
  };

  public courseMarkingOptions = {
    19: ['Directional Signage', 'Distance Markers', 'Road Markings', 'Hazardous Section Warnings', 'Feed Zone Markers',
    'Fencing', 'Barricades' ],
    21: ['Direction Arrows', 'Course Tape', 'Hazardous Section Warnings', 'Fencing', 'Barricades'],
    22: [],
    23: ['Course Tape', 'Course Discs', 'Hazardous Section Warnings', 'Artificial Barricades', 'Artificial Sand Pits', 'Bike Wash'],
    20: [],
    24: ['Directional Signage', 'Distance Markers', 'Aid Stations', 'Fencing', 'Barricades', 'GPS Route Maps'],
    25: ['Directional Signage', 'Distance Markers', 'Aid Stations', 'Fencing', 'Barricades', 'GPS Route Maps'],
    26: [],
    27: [],
    28: [],
    29: [],
    30: []
  };
  public availableQuestionsList: Array<number> = [];

  constructor(
    protected http: HttpClient,
    protected cookie: CookieService,
    private route: Router,
    private eventService: EventService
  ) {
    super(http, cookie);
  }

  checkBoxOptionsWithArray(options: Array<string>) {
    let checkBoxOptions: Array<CheckboxOption>;
    checkBoxOptions = [];
    if (options === undefined || options === null) {
      return checkBoxOptions;
    }
    options.forEach((option) => {
      let index = option.toLowerCase();
      index = index.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, key) => {
        if (+match === 0) { return ''; }
        return key === 0 ? match.toLowerCase() : match.toUpperCase();
      });
      checkBoxOptions.push(new CheckboxOption(index, index, index, option));
    });
    return checkBoxOptions;
  }

  generateCourseMaps(length: number) {
    for (let loop = 0; loop < length; loop++) {
      this.courseMaps[loop] = [];
      this.courseMaps[loop][0] = new CourseMap();
    }
  }

  getSubDisciplines(index: number): Array<string> {
    let selectedDisciplines: Array<string>;
    selectedDisciplines = [];
    this.courseMaps[index].forEach(courseMap => {
    //  console.log(courseMap.courseSubdiscOptions);
      selectedDisciplines = selectedDisciplines.concat(courseMap.courseSubdiscOptions);
    //  console.log(selectedDisciplines);
    });
    this.courseMaps[index].forEach(courseMap => {
      courseMap.selectedSubDisciplines = selectedDisciplines;
    });
    return selectedDisciplines;
  }

  getCalendarLabels(disciplineId?: number|string): Observable<CalendarLabel[]> {
    const queryString = disciplineId ? `?discipline_ids[]=${disciplineId}` : '';
    return this.http.get<CalendarLabel[]>(this.url('calendar/labels' + queryString), this.options)
      .pipe(
        tap(_ => 'fetched event calendar labels'),
        catchError(this.handleError<CalendarLabel[]>('getCalendarLabels'))
      );
  }

  getCalendarLabelsForEvent(eventId: number|string): Observable<CalendarLabel[]> {
    return this.http.get<CalendarLabel[]>(this.url(`calendar/labels/${eventId}`), this.options)
      .pipe(
        tap(_ => 'fetched event calendar labels'),
        catchError(this.handleError<CalendarLabel[]>('getCalendarLabelsForEvent'))
      );
  }

  getCalendarLabelAnswersForEvent(eventId: number|string): Observable<CalendarLabelAnswer[]> {
    return this.http.get<CalendarLabelAnswer[]>(this.url(`calendar/labels/${eventId}/answers`), this.options)
      .pipe(
        tap(_ => 'fetched event calendar label answers'),
        catchError(this.handleError<CalendarLabelAnswer[]>('getCalendarLabelAnswersForEvent'))
      );
  }

  getCalendarQuestions(disciplineId?: number|string): Observable<CalendarLabelQuestion[]> {
    return this.http.get<CalendarLabelQuestion[]>(this.url(`calendar/questions?discipline_ids[]=${disciplineId}`), this.options)
      .pipe(
        tap(_ => 'fetched event calendar label questions'),
        catchError(this.handleError<CalendarLabelQuestion[]>('getCalendarQuestions'))
      );
  }

  postCalendarLabels(label_ids: number[]|string[], eventId: number|string): Observable<CalendarLabel[]> {
    return this.http.post<CalendarLabel[]>(this.url(`calendar/labels/${eventId}`), { label_ids }, this.options)
      .pipe(
        tap(_ => 'saved event calendar labels'),
        catchError(this.handleError<CalendarLabel[]>('postCalendarLabels'))
      );
  }

  postCalendarLabelAnswers(answers: EventIncentivesFormAnswer[], eventId: number|string): Observable<CalendarLabelAnswer[]> {
    return this.http.post<CalendarLabelAnswer[]>(this.url(`calendar/labels/${eventId}/answers`), { answers }, this.options)
      .pipe(
        tap(_ => 'saved event calendar labels'),
        catchError(this.handleError<CalendarLabelAnswer[]>('postCalendarLabelAnswers'))
      );
  }

  addAnotherCourseMap(index: number) {
    if ( this.courseMaps[index] === undefined ) {
      return;
    }
    const course = new CourseMap();
    const currentPos = this.courseMaps[index].length;
    this.courseMaps[index][currentPos] = course;
    return this.courseMaps[index][currentPos];
  }

  getQuestionConfig(disciplineId: number) {
    // tslint:disable-next-line: radix
    let discipline = '';
    switch (disciplineId) {
      case 19: discipline = 'road'; break;
      case 20: discipline = 'track'; break;
      case 21: discipline = 'mtb'; break;
      case 23: discipline = 'cx'; break;
      case 24: discipline = 'granfondo'; break;
      case 25: discipline = 'gravel'; break;
      case 27: discipline = 'funrides'; break;
      default : discipline = 'global';
    }
    this.http.get(environment.nextGenApiUrl + 'permit/questions/1', this.options).subscribe((questions: Array<any>) => {
      questions.forEach(question => {
        const disciplines = question.optedfor.split(',');
        if (disciplines.includes('global') || disciplines.includes(discipline)) {
          this.availableQuestionsList.push(question.pq_id);
        }
      }, () => {
        console.log(this.availableQuestionsList);
      });
    });
  }

  isEnabledQuestion(question: number) {
    if (this.availableQuestionsList.length === 0) { return true; }
    return this.availableQuestionsList.includes(question);
  }

  savePermit(id: number, saveAndExit?: boolean, navUrl?: string) {
    const answers = {
      // q1: { name: this.contactName, email : this.contactEmail, phone: this.contactPhone },
      // q2: this.medicalStaffName,
      // q3: this.medicalFacilities,
    //  q4: this.policeSecurity,
    //  q5: this.securityContacts,
      q6: this.communicationTypes,
      q7: {timingInformation:  this.timingInformation, timingProvider: this.timingProvider},
      q8: this.timings,
      q9: this.registrationProvider,
      q10: this.stageArea,
      q11: [],
      q12: this.courseMarking,
      // q14: this.payOut,
      q15: this.maxPayOut,
      q16: this.localPermitApproval,
    };
    this.courseMaps.forEach(courseMap => {
      answers.q11.push(Object.assign({}, courseMap));
    });
    const finalAnswers = {};
    Object.entries(answers).forEach(answer => {
      console.log(answer);
      // tslint:disable-next-line: radix
      const index = parseInt(answer[0].replace('q', ''));
      if (this.availableQuestionsList.includes(index)) {
        finalAnswers[answer[0]] = answer[1];
      }
    });
    if (this.mode === 'create') {
      return this.http.post(this.url('permit/answers'), {
        permit_id: id,
        answers: finalAnswers
      }, this.options);
    } else {
      return this.http.put(this.url('permit/answers'), {
        permit_id: id,
        answers: finalAnswers
      }, this.options);
    }

  }

  getPermitInfo(id: number) {
    return this.http.get(this.url('permit/answers/' + id), this.options);
  }

  fetchFromAnswers() {
    this.answers.forEach(answer => {
      switch (answer.qid) {
        // case 1:
        //   this.contactName = answer.description.name;
        //   this.contactEmail = answer.description.email;
        //   this.contactPhone = answer.description.phone; break;
        // case 2: this.medicalStaffName = (answer.answer === '' ? answer.description : answer.answer); break;
        // case 3: this.medicalFacilities = answer.description; break;
      //  case 4: this.policeSecurity = answer.answer === '' ? answer.description : answer.answer; break;
      //  case 5: this.securityContacts = answer.answer === '' ? answer.description : answer.answer; break;
        case 6: this.communicationTypes = answer.answer === '' ? answer.description : answer.answer; break;
        case 7:
        this.timingInformation = answer.description.timingInformation;
        this.timingProvider = answer.description.timingProvider; break;
        case 8: this.timings = answer.answer === '' ? answer.description : answer.answer; break;
        case 9: this.registrationProvider = answer.answer === '' ? answer.description : answer.answer; break;
        case 10: this.stageArea = answer.answer === '' ? answer.description : answer.answer; break;
        case 11:
          this.courseMaps = [];
          let courseMaps: Array<Array<string>>;
          courseMaps = answer.answer === '' ? answer.description : answer.answer;
          courseMaps.forEach((dayCourseMaps, index) => {
            this.courseMaps[index] = [];
            dayCourseMaps.forEach((courseMapInfo: any) => {
              let courseMap: CourseMap;
              courseMap = new CourseMap();
            //  console.log(courseMapInfo);
              courseMap.courseDisplay = 'url';
              courseMap.courseUrl = courseMapInfo.courseUrl;
              courseMap.courseSubdiscOptions = courseMapInfo.courseSubdiscOptions;
              this.courseMaps[index].push(courseMap);
            });
          });
          console.log(this.courseMaps);
          break;
        case 12: this.courseMarking = answer.answer === '' ? answer.description : answer.answer; break;
        // case 14: this.payOut = answer.answer === '' ? answer.description : answer.answer; break;
        case 15: this.maxPayOut = answer.answer === '' ? answer.description : answer.answer; break;
        case 16: this.localPermitApproval = answer.answer === '' ? answer.description : answer.answer; break;
      }
    });
    return true;
  }

  updateStatusAndNavigate(eventId: string, status: string, navUrl: string) {
    this.eventService.updateEventStatus(eventId, status).subscribe(resp => {
      if (resp.message) {
        this.route.navigate([navUrl]);
      } else {
        // TODO: Consider more explicit error handling
      }
    });
  }

  createPermit(eventId: string, part: string, navUrl?: string, isRaceOrRide?: boolean) {
    // Adding static in status
    const data = {status: this.status};
    const url = navUrl ? navUrl : `/event/${eventId}/permit-application/part-2`;
    // Create if permit not found
    if (!this.permitId) {
      this.http.post(this.url(`${eventId}/permit`), data, this.options
      ).subscribe(resp => {
        console.log(resp);
        if (Object.keys(resp).includes('permit_id')) {
          // tslint:disable-next-line: no-string-literal
          this.permitId = resp['permit_id'];
        }
      }, (error) => {
        console.log(error);

        if (error.status === 401) {
          // TODO: Handle unauthorized
          console.log('Unauthorized! Prompt user to login');
          window.location.href = '/unauthorized';
        }
      }, () => {
        console.log('completed');
        if (part === 'part1') {
          this.savePermit(this.permitId).subscribe(response => {
            // tslint:disable-next-line: no-string-literal
            if (response['status'] === true) {
              // TODO: Is check for listing 'mode' (to fix Permitting I issue) still necessary?
              if (this.mode === 'create') {
                this.updateStatusAndNavigate(eventId, 'permit-in-progress', url);
              } else if (this.mode === 'update') {
                this.route.navigate([url]);
              }
            }
          });
        }
      });
    } else {
      // Update if permit exists
      this.savePermit(this.permitId).subscribe(response => {
        const status = 'status';
        if (response[status] === true && this.mode === 'create' && part === 'part1') {
          // Set event status to 'Permit in Progress' (partially complete)
          this.updateStatusAndNavigate(eventId, 'permit-in-progress', url);
        } else if (response[status] === true && this.mode === 'create' && part === 'part2') {
          if (isRaceOrRide) {
            this.route.navigate([`/event/${eventId}/event-incentives`]);
          } else {
            this.route.navigate([`/event/${eventId}/insured-certificates`]);
          }
        } else if (response[status] === true && this.mode === 'update' && part === 'part1') {
          this.route.navigate([url]);
        } else if (response[status] === true && this.mode === 'update' && part === 'part2') {
          this.route.navigate([`/event/${eventId}/overview`]);
        }
      });
    }
  }

  deleteCourseMaps(permitId: number|string): Observable<any> {
    return this.http.delete(this.url(`permit/answers/${permitId}/courseMaps/delete`), this.options)
      .pipe(
        tap(_ => this.log(`Deleted course maps for permit id: ${permitId}`)),
        catchError(this.handleError<any>('deleteCourseMaps', {}, { permitId }))
      );
  }

  removeSubCourseMap(pos: number, index: number) {
    this.courseMaps[pos].splice(index, 1);
  }

  reset() {
    // this.contactName = undefined;
    // this.contactEmail = undefined;
    // this.contactPhone = undefined;
    // this.medicalStaffName = [];
    // this.medicalFacilities = {};
    this.timingInformation = undefined;
    this.timingProvider = undefined;
    this.registrationProvider = undefined;
    this.communicationTypes = [];
    this.courseMarking = [];
    //  this.policeSecurity = undefined;
    //  this.securityContacts = [];
    this.timings = undefined;
    this.courseMaps = [];
    this.stageArea = '';
    // this.payOut = undefined;
    this.maxPayOut = undefined;
    this.mode = 'create';
    this.answers = undefined;
    this.permitId = undefined;

    this.status = 'In Progress';

    this.localPermitApproval = undefined;
    this.availableQuestionsList = [];
  }
}
