import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService, EventListingService, PermitService } from 'src/app/services';
import { Event } from 'src/app/classes';

@Component({
  selector: 'app-permit-application-part2',
  templateUrl: './permit-application-part2.component.html',
  styleUrls: []
})
export class PermitApplicationPart2Component implements OnInit {
  currentStep = 1;
  eventId: string;
  event: Event;
  reset = false;
  dataLoaded = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventService,
    private eventListing: EventListingService,
    private permitService: PermitService
  ) { }

  ngOnInit() {
    this.permitService.reset();
    this.eventListing.reset();
    this.reset = true;

    this.eventId = this.route.snapshot.paramMap.get('eventId');
    this.eventService.getEventById(this.eventId).subscribe(event => {
      if (event) {
        this.event = new Event(event);
        this.eventListing.fetchEventData(this.eventId);
        if (this.event.isPermit) {
          this.permitService.permitId = event.event_permit_id;
          this.permitService.getQuestionConfig(event.event_discipline);
          // Determines whether to proceed through listing application steps or return to overview after submit
          this.permitService.mode = this.inProgress ? 'create' : 'update';
          this.loadAnswers();
        } else {
          this.toOverview();
        }
      } else {
        this.toQueue();
      }
    });
  }

  loadAnswers() {
    this.permitService.getPermitInfo(this.permitService.permitId).subscribe( (data: any) => {
      if (data === null || data === undefined) {
        console.log('events are not found , due to the api has down');
      }
      this.permitService.answers = data.answers;
      console.log(this.permitService.answers);
      this.permitService.fetchFromAnswers();
      this.dataLoaded = true;
    }, (error) => {
      console.log(error);
      }
    );
  }

  get loaded() {
    return this.reset && this.eventListing.datesLoaded && this.eventListing.organizerLoaded
      && this.eventListing.event_discipline && this.permitService.availableQuestionsList && this.dataLoaded;
  }

  inProgress() {
    return this.event.event_status === 'permit-in-progress';
  }

  toOverview() {
    this.router.navigate([`event/${this.eventId}/overview`], {fragment: 'permit-application'});
  }

  toQueue() {
    this.router.navigate(['queue']);
  }

}
