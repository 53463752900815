import { PostEventParticipants, PostEventRiders } from '.';

export class PostEvent {
  post_event_id: number;
  post_event_event_id: number;
  post_event_permit_id: number;
  post_event_event_date_id: number;
  permit_post_event_status: string;
  // Form Part 1
  post_event_sufficient_staff: boolean;
  post_event_sufficient_facilities: boolean;
  post_event_sufficient_time: boolean;
  post_event_start_lists_adequate: boolean;
  post_event_check_licenses: boolean;
  post_event_registration_comments: string;
  post_event_flyer_accurate: boolean;
  post_event_course_changes_required: boolean;
  post_event_podium: boolean;
  post_event_beginner_racer_program_event: boolean;
  post_event_conduct_comments: string;
  post_event_neutral_support_services: boolean;
  post_event_licensed_mechanics: boolean;
  post_event_timing_company: boolean;
  post_event_technical_support_comments: string;
  post_event_medical_support_verified: boolean;
  post_event_medical_support_description: string;
  post_event_number_of_occurrences: number;
  post_event_accident_occurrence_reports: any[];
  post_event_paperwork_occurrence_reports: boolean;
  post_event_rider_discipline: boolean;
  post_event_rider_discipline_reports: any[];
  post_event_paperwork_rider_discipline_reports: boolean;
  post_event_largest_individual_prize: string;
  post_event_prize_equal_pay: boolean;
  post_event_prize_distributed_15_min: boolean;
  post_event_posting_area_adequate: boolean;
  post_event_protest_period_observed: boolean;
  post_event_prize_comments: string;
  // Form Part 2
  ss_q1: boolean;
  ss_q1_text: boolean;
  ss_q2: boolean;
  ss_q2_text: boolean;
  ss_q3: boolean;
  ss_q3_text: boolean;
  ss_q4: boolean;
  ss_q4_text: boolean;
  ss_q5: boolean;
  ss_q5_text: boolean;
  ss_q6: boolean;
  ss_q6_text: boolean;
  ss_q7: boolean;
  ss_q7_text: boolean;
  ss_q8: boolean;
  ss_q8_text: boolean;
  // Form Part 3
  post_event_participants: PostEventParticipants[];
  post_event_riders: PostEventRiders[];
  post_event_one_day_on_site: number;
  post_event_one_day_online: number;
  post_event_membership_standard: number;
  post_event_membership_premium: number;
  post_event_membership_collegiate: number;
  post_event_membership_junior: number;
  post_event_paperwork_license_memberships: boolean;
  post_event_late_fee: boolean;
  post_event_late_fee_dismissed: boolean;
  post_event_discount: number;
  post_event_note: string;

  constructor(data) {
    Object.assign(this, data);
  }
}
