<div class="calendar-labels">
  <!-- Intro Copy -->
  <p *ngIf="isRace">
    USA Cycling is pleased to offer a new program to event organizers to help support your efforts
    in growing the sport of bike racing at the grassroots level. Below you will find certain options
    for different initiatives that we feel are critical to providing a more equitable and welcoming
    environment for all riders. Selecting certain items included in this list will generate special
    badging for your event that will be showcased on the USA Cycling event calendar. This will call
    attention to the good work that you are doing in key areas of the sport so riders can see and
    make choices based on the offerings provided.
  </p>
  <p *ngIf="isRide">
    USA Cycling is pleased to offer a new partnership program to event organizers to help support
    organizers in engaging USA Cycling members around the country. Below you will find certain options
    for different initiatives that we have identified in collaboration with several organizers to deliver
    event specific benefits to market to USA Cycling members. Selecting certain items on this list will
    generate special badging for your event on the USA Cycling event calendar and help us add your event
    to our member benefits page for advertising purposes to increase click rates on event links and improve
    registration volume. Selecting 4 or more items will help you lower your insurance rate per rider as an
    added bonus for your support.
  </p>

  <!-- Questions Form -->
  <form [formGroup]="questionsForm">
    <div *ngFor="let question of calendarLabelQuestions" class="question-form-group mt-4">
      <app-checkbox
        [formControlName]="controlName(question)"
        [id]="controlName(question)"
        [name]="controlName(question)"
        [label]="question.question"
        [tooltip]="question.tooltip"
        [formSubmitted]="questionsFormSubmitted"
        [errors]="qf[controlName(question)].errors"
      ></app-checkbox>
      <!-- Include text area when required -->
      <app-text-area *ngIf="displayTextbox(question)"
        [formControlName]="detailsControlName(question)"
        [name]="detailsControlName(question)"
        [id]="detailsControlName(question)"
        [label]="'Details'"
        [placeholder]="'Add details here...'"
        [maxLength]="'250'"
        [minHeight]="'56'"
        [required]="true"
        [formSubmitted]="questionsFormSubmitted"
        [errors]="qf[detailsControlName(question)].errors"
      ></app-text-area>
    </div>

    <!-- Label Badge Display -->
    <div *ngIf="selectedLabelIds.length" class="space-above-sm">
      <p class="text-secondary font-italic">
        Based on your selections, your event qualifies for these Event Calendar labels:
      </p>
      <img *ngFor="let labelId of selectedLabelIds"
        [src]="labelById(labelId)?.badge_image"
        [alt]="labelById(labelId)?.name"
        [title]="labelById(labelId)?.name"
        class="calendar-label-badge mr-3"
      />
    </div>

    <!-- Post-Questions Copy -->
    <p class="my-4">
      We understand that events are different, and are open to adapting some of these to your specific
      circumstances as long as any modifications are approved by USA Cycling staff. Different types of
      events and different disciplines have a unique set of incentives. In general, you must select and
      then deliver on four items out of the “menu” offered for your event in the USA Cycling permit system.
    </p>

    <div class="d-flex justify-content-between space-above-lg">
      <app-button
        [type]="'btn-light'"
        [disabled]="disableBtns"
        (click)="goBack()"
      >Go Back</app-button>
      <div>
        <app-button
          [type]="'btn-secondary'"
          [disabled]="disableBtns"
          (click)="openSaveExitModal()"
        >
          Save & Exit
          <span *ngIf="saveAndExitSubmitting" class="spinner-border spinner-border-sm ml-1"></span>
        </app-button>
        <app-button
          [type]="'btn-primary'"
          [disabled]="disableBtns"
          (click)="onSubmit()"
        >
          Continue
          <span *ngIf="submitting" class="spinner-border spinner-border-sm ml-1"></span>
        </app-button>
      </div>
    </div>
  </form>
</div>


<app-modal
  [id]="'save-exit'"
  [title]="'Save & Exit Permit'"
  [isOpen]="showSaveExit"
  (close)="closeSaveExitModal(true)"
>
  <app-modal-body>
    <p>Would you like to save your current permit progress and return at a later time? Please note that your event will not be listed and permitting cannot be approved until this form is complete.</p>
    <div class="row">
      <div class="col-sm-6 text-left">
        <app-button *ngIf="navUrl === ''"
          [type]="'btn-light'"
          [disabled]="disableBtns"
          (click)="closeSaveExitModal(true)"
        >Go Back</app-button>
        <app-button *ngIf="navUrl !== ''"
          [type]="'btn-light'"
          [disabled]="disableBtns"
          (click)="continueNavigation()"
        >Continue Without Saving</app-button>
      </div>
      <div class="col-sm-6 text-right">
        <app-button
          [type]="'btn-secondary'"
          [disabled]="disableBtns"
          (click)="saveAndExit()"
        >
          Save & Exit
          <span *ngIf="saveAndExitSubmitting" class="spinner-border spinner-border-sm ml-1"></span>
        </app-button>
      </div>
    </div>
  </app-modal-body>
</app-modal>
