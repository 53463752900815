import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { CartService, CertificateService, EventService, InsuranceService, LocationService } from '../../services';
import { ShopifyService, Product } from '../../shared';
import { Certificate, Club, Event, ShopifyDraftOrder, ShopifyLineItemBase, ShopifyProductVariant } from '../../classes';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-insured-certificates',
  templateUrl: './insured-certificates.component.html',
  styles: []
})

export class InsuredCertificatesComponent implements OnInit {
  currentStep = 3;
  eventId: string;
  event: Event;
  link: string;
  submitted = false;
  saveDisabled = true;
  validForms: boolean[] = [];
  products: Array<Product>;
  productInput: Product;

  namedInsuredOrganizerSku: string;
  namedInsuredClubSku: string;
  additionalInsuredSku: string;

  endorsementAdditionalSku: string;
  endorsementCancelSku: string;
  endorsementNonContribSku: string;
  endorsementWaiverSku: string;

  skus: string[] = [];
  includedCertVariantSkus: string[] = [];
  endorsementVariantSkus: string[] = [];

  // Certificate Variants
  namedInsuredOrganizer: ShopifyProductVariant;
  namedInsuredClub: ShopifyProductVariant;
  additionalInsured: ShopifyProductVariant;
  includedCertVariants: ShopifyProductVariant[];

  // Endorsement Variants
  endorsementAdditional: ShopifyProductVariant;
  endorsementCancel: ShopifyProductVariant;
  endorsementNonContrib: ShopifyProductVariant;
  endorsementWaiver: ShopifyProductVariant;
  endorsementVariants: ShopifyProductVariant[] = [];

  endorsementItems: ShopifyLineItemBase[] = [];
  lineItem: ShopifyLineItemBase;

  draftOrder: ShopifyDraftOrder;

  get lineItems(): ShopifyLineItemBase[] { return this.draftOrder.line_items; }

  form = new FormGroup({
    variant: new FormControl([]),
    quantity: new FormControl(1),
  });

  constructor(
    private shopifyService: ShopifyService,
    private eventService: EventService,
    public insurance: InsuranceService,
    private locationService: LocationService,
    public cart: CartService,
    public certificateService: CertificateService,
    public router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.eventId = this.route.snapshot.paramMap.get('eventId');
    if (this.eventId) {
      this.link = `event/${this.eventId}/add-ons`;

      this.locationService.getStates().subscribe();
      // Subscribe to draft order in cart component

      this.certificateService.certificates = [];
      this.insurance.includedCertificates = [];
      this.insurance.certificates = [];

      this.eventService.getEventById(this.eventId).subscribe(event => {
        if (event) {
          this.event = new Event(event);
          if (!this.event.isPermit) {
            this.toOverview();
          }

          // Fetch products & prepare draft order update once certs initialized
          this.insurance.initComplete$.subscribe((complete: boolean) => {
            if (complete) {
              setTimeout(() => {
                this.fetchProducts();
                this.setSaveContinue();
              }, 100);
            }
          });

          this.shopifyService.cartDraftOrder.subscribe(draftOrder => {
            this.draftOrder = draftOrder;

            this.certificateService.getCertificates(this.eventId).subscribe(certs => {
              this.setInitialCerts(certs);
            });
          });
        } else {
          this.toQueue();
        }
      });
    } else {
      this.toQueue();
    }
  }

  setInitialCerts(certs) {
    // Add included certificates from listing if not already saved
    const eventOrganizerNeedingCert = !certs.some((cert: Certificate) => {
      return cert.type === 'named' && cert.profile_id === this.event.event_organizer;
    }) ? this.event.event_organizer : null;

    const sponsoringClubsNeedingCert = this.event.event_sponsoring_clubs.filter(club => {
      return !certs.some((cert: Certificate) => cert.type === 'named' && cert.name === club.club_name.trim());
    });

    this.insurance.init(eventOrganizerNeedingCert, sponsoringClubsNeedingCert);

    // Add already saved certificates
    certs.forEach((cert: Certificate) => {
      this.certificateService.addOrUpdateSelectedCert(null, cert);

      if (this.draftOrder.tags.includes(`certificate_id-${cert.id}`)) {
        const ins = this.insurance.createFromCertificate(cert);
        cert.type === 'named' ? this.insurance.includedCertificates.push(ins) : this.insurance.certificates.push(ins);
      }
    });
  }

  get noCerts(): boolean {
    return !this.insurance.includedCertificates.length && !this.insurance.certificates.length;
  }

  setSaveContinue() {
    this.saveDisabled = !this.noCerts;
  }

  onAddCert() {
    this.saveDisabled = true;
    this.insurance.addCert();
  }

  get certsToBeAdded() {
    return this.certificateService.certificates.filter(cert => !cert.id);
  }

  get existingCerts() {
    return this.certificateService.certificates.filter(cert => !!cert.id && this.certInDraftOrder(cert));
  }

  certInDraftOrder(certificate) {
    return this.draftOrder && this.draftOrder.tags && this.draftOrder.tags.includes(`certificate_id-${certificate.id}`);
  }

  onCertificateSave($event) {
    const certs = this.insurance.includedCertificates.concat(this.insurance.certificates);
    this.saveDisabled = !(certs.length === this.certsToBeAdded.length + this.existingCerts.length);
  }

  onEndorsementUpdate($event) {
    const selected = $event.endorsements;
    const previous = $event.previous;
    this.endorsementItems.forEach(end => {
      const lineItem: ShopifyLineItemBase = { variant_id: end.variant_id, quantity: 1 };
      if ( (selected.some(sel => sel.variant_id === end.variant_id)) && (!previous.some(p => p.variant_id === end.variant_id)) ) {
        this.shopifyService.increaseItemQuantity(lineItem, 1, this.draftOrder);
      } else if ((previous.some(pre => pre.variant_id === end.variant_id)) && (!selected.some(sel => sel.variant_id === end.variant_id))) {
        this.shopifyService.decreaseItemQuantity(lineItem, 1, this.draftOrder);
      }
    });
  }

  fetchProducts() {
    const disc = this.event.event_discipline;
    if (disc) {
      this.namedInsuredOrganizerSku = environment.disciplineSkus[disc].namedInsuredOrganizer;
      this.namedInsuredClubSku = environment.disciplineSkus[disc].namedInsuredClub;
      this.additionalInsuredSku = environment.disciplineSkus[disc].additionalInsured;
      this.includedCertVariantSkus.push(this.namedInsuredOrganizerSku);
      this.includedCertVariantSkus.push(this.namedInsuredClubSku);

      this.endorsementAdditionalSku = environment.disciplineSkus[disc].endorsementAdditional;
      this.endorsementCancelSku = environment.disciplineSkus[disc].endorsementCancel;
      this.endorsementNonContribSku = environment.disciplineSkus[disc].endorsementNonContrib;
      this.endorsementWaiverSku = environment.disciplineSkus[disc].endorsementWaiver;
      this.endorsementVariantSkus.push(this.endorsementAdditionalSku);
      this.endorsementVariantSkus.push(this.endorsementCancelSku);
      this.endorsementVariantSkus.push(this.endorsementNonContribSku);
      this.endorsementVariantSkus.push(this.endorsementWaiverSku);

      this.includedCertVariantSkus.forEach(sku => this.skus.push(sku));
      this.skus.push(this.additionalInsuredSku);
      this.endorsementVariantSkus.forEach(sku => this.skus.push(sku));
    }


    this.shopifyService.getShopifyProductVariants({ skus: this.skus }).subscribe(variants => {
      this.namedInsuredOrganizer = this.findVariant(variants, this.namedInsuredOrganizerSku);
      this.namedInsuredClub = this.findVariant(variants, this.namedInsuredClubSku);
      this.additionalInsured = this.findVariant(variants, this.additionalInsuredSku);

      this.endorsementAdditional = this.findVariant(variants, this.endorsementAdditionalSku);
      this.endorsementCancel = this.findVariant(variants, this.endorsementCancelSku);
      this.endorsementNonContrib = this.findVariant(variants, this.endorsementNonContribSku);
      this.endorsementWaiver = this.findVariant(variants, this.endorsementWaiverSku);

      this.includedCertVariants = [
        this.namedInsuredOrganizer,
        this.namedInsuredClub
      ];

      this.endorsementVariants = [
        this.endorsementAdditional,
        this.endorsementWaiver,
        this.endorsementNonContrib,
        this.endorsementCancel
      ];

      this.endorsementItems = [
        { variant_id: this.endorsementAdditional.id, quantity: 1 },
        { variant_id: this.endorsementWaiver.id, quantity: 1 },
        { variant_id: this.endorsementNonContrib.id, quantity: 1 },
        { variant_id: this.endorsementCancel.id, quantity: 1 }
      ];

      // Only add generated certs that haven't already been saved
      this.addIncludedCerts(this.insurance.includedCertificates.filter(cert => !cert.certificateId));
    });
  }

  findVariant(variants: ShopifyProductVariant[], sku: string) {
    return variants.find(variant => sku === variant.sku);
  }

  addIncludedCerts(certs) {
    let eoQuant = 0;
    let scQuant = 0;
    certs.forEach(cert => {
      switch (cert.type) {
        case 'Event Organizer': eoQuant += 1; break;
        case 'Sponsoring Club': scQuant += 1;
      }
    });
    if (eoQuant > 0) {
      this.shopifyService.increaseItemQuantity({ variant_id: this.namedInsuredOrganizer.id, quantity: 1}, 1, this.draftOrder);
    }
    if (scQuant > 0) {
      this.shopifyService.increaseItemQuantity({ variant_id: this.namedInsuredClub.id, quantity: scQuant}, 1, this.draftOrder);
    }
  }

  // clearCartCertificates() {
  //   this.lineItems.forEach(item => {
  //     if (this.includedCertVariants.some(variant => variant.id === item.variant_id)) {
  //       this.shopifyService.removeFromDraftOrder(item, this.draftOrder);
  //     }
  //   });
  //   this.lineItems.forEach(item => {
  //     if (this.additionalInsured.id === item.variant_id) {
  //       this.shopifyService.removeFromDraftOrder(item, this.draftOrder);
  //     }
  //   });
  // }

  // clearCartEndorsements() {
  //   this.lineItems.forEach(item => {
  //     if (this.endorsementVariants.some(variant => variant.id === item.variant_id)) {
  //       this.shopifyService.removeFromDraftOrder(item, this.draftOrder);
  //     }
  //   });
  // }

  get loading(): boolean {
    return !this.namedInsuredOrganizer || !this.namedInsuredClub
      || !this.additionalInsured || !this.endorsementVariants.length
      || !this.locationService.stateOptions;
  }

  redirectTemp() {
    this.router.navigate([this.link]);
  }

  toAddOns() {
    this.router.navigate([`event/${this.eventId}/add-ons`]);
  }

  toOverview() {
    this.router.navigate([`event/${this.eventId}/overview`]);
  }

  toQueue() {
    this.router.navigate(['queue']);
  }

  onSave() {
    this.submitted = true;
    if (!this.noCerts) {
      this.validForms = [];
    } else {
      this.toAddOns();
    }
  }

  checkValidForms($event) {
    this.validForms.push($event);
    if (this.validForms.length === this.insurance.includedCertificates.length + this.insurance.certificates.length) {
      if (this.validForms.includes(false)) {
        console.log('At least 1 form is invalid');
      } else {
        // No new or existing certs
        if (!this.certsToBeAdded.length && !this.existingCerts) {
          setTimeout(() => this.router.navigate([`event/${this.eventId}/add-ons`]), 1000);
        // Only existing certs
        } else if (!this.certsToBeAdded.length && this.existingCerts.length) {
          this.updateExistingCerts();
        // New certs
        } else if (this.certsToBeAdded.length) {
          let totalCerts = 0;
          this.certsToBeAdded.forEach(certificate => {
            this.certificateService.addCertificate(this.eventId, certificate).subscribe(cert => {
              totalCerts += 1;
              // Add certificate ID tag to draft order
              const certTag = `certificate_id-${cert.id}`;
              if (this.draftOrder.tags) {
                if (!this.draftOrder.tags.includes(certTag)) { this.draftOrder.tags += `, ${certTag}`; }
              } else {
                this.draftOrder.tags = certTag;
              }
              if (totalCerts === this.certsToBeAdded.length) {
                if (this.existingCerts.length) {
                  this.updateExistingCerts();
                } else {
                  this.updateDraftOrder();
                }
              }
            });
          });
        }
      }
    }
  }

  updateExistingCerts() {
    let totalExistingCerts = 0;
    this.existingCerts.forEach(existingCert => {
      this.certificateService.updateCertificate(this.eventId, existingCert).subscribe(resp => {
        if (resp) {
          totalExistingCerts += 1;
          if (totalExistingCerts === this.existingCerts.length) {
            this.updateDraftOrder();
          }
        }
      });
    });
  }

  updateDraftOrder() {
    this.shopifyService.updateDraftOrderItems(this.draftOrder, `event/${this.eventId}/add-ons`);
  }
}
