
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService, ProfileService } from 'src/app/services';

import { Event, EventDate, Profile } from 'src/app/classes';

@Component({
  selector: 'app-post-event-part3',
  templateUrl: './post-event-part3.component.html',
  styles: []
})
export class PostEventPart3Component implements OnInit {

  eventId: string;
  event: Event;
  eventDateId: string;
  eventDate: EventDate;

  currentUser: Profile;
  userRole: string;

  urlSegments: string[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eventService: EventService,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
    this.urlSegments = this.route.snapshot.url.map(segment => segment.path);
    this.eventId = this.route.snapshot.paramMap.get('eventId');
    this.eventDateId = this.route.snapshot.paramMap.get('dateId');
    this.eventService.getEventById(this.eventId).subscribe(event => {
      // Event doesn't exist
      if (!event) {
        this.toEventQueue();
      } else {
        this.event = new Event(event);

        // Event isn't permitted
        if (!this.event.isPermit) {
          this.toOverview();
        } else {
          if (this.eventDateId) {
            if (this.isSeries) {
              this.eventService.getEventDate(this.eventDateId).subscribe(eventDate => {
                if (eventDate && eventDate.event_date_event_id.toString() === this.eventId) {
                  this.eventDate = eventDate;
                // Event date doesn't belong to the event
                } else {
                  this.toOverviewPostEvent();
                }
              });
            // Has an event date param, but isn't a series
            } else {
              this.toOverviewPostEvent();
            }
          // Is a series, but doesn't have an event date param
          } else if (this.isSeries) {
            this.toOverviewPostEvent();
          }
        }
      }
    });
    if (!!this.profileService.currentUser) {
      this.setUser();
    } else {
      this.profileService.getCurrentUser().subscribe(() => {
        this.setUser();
      });
    }
  }

  setUser() {
    this.currentUser = this.profileService.currentUser;
    this.profileService.getUserPermissions().subscribe(permissions => {
      this.userRole = this.profileService.setUserRole(permissions);
    });
  }

  get isSeries() {
    return this.eventService.isSeries(this.event);
  }

  toOverview() {
    this.router.navigate([`event/${this.eventId}/overview`]);
  }

  toOverviewPostEvent() {
    this.router.navigate([`event/${this.eventId}/overview`], {fragment: 'post-event'});
  }

  toEventQueue() {
    this.router.navigate(['event/queue']);
  }

  displayFormEvent() {
    return !this.eventDateId && !!this.event && !!this.userRole;
  }

  displayFormEventDate() {
    return !!this.event && !!this.eventDate && !!this.userRole;
  }
}
