<div class="container">
  <div class="row">
    <div class="main-container col-12">
      <h2>Permit Application</h2>
      <h4 class="space-below-lg">Part 1 of 2</h4>

        <!-- Loader -->
        <app-loader *ngIf="!loaded"></app-loader>

      <app-permit-part1-form *ngIf="loaded" [eventId]="eventId"></app-permit-part1-form>
    </div>
  </div>
</div>
