  <div class="switch-field" [ngClass]="{ 'is-invalid': formSubmitted && errors }">
    <app-radio-switch-item class="switch-item"
      *ngFor="let option of options"
      [name]="name"
      [inline]="inline"
      [id]="option.id"
      [label]="option.label"
      [value]="option.value"
      [disabled]="option.disabled"
      [(checked)]="option.checked"
      (change)="onRadioChange($event)"
    ></app-radio-switch-item>
</div>
<div *ngIf="formSubmitted && errors" class="invalid-feedback">
  <div *ngIf="errors.required">Required</div>
</div>
