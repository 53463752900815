import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Profile, PermissionSet, Driver, Referee } from '../classes';
import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root'
})
// TODO: Merge with existing ProfileService & rename
export class ProfileService extends BaseService {

  public currentUser: Profile;
  public permissions: PermissionSet;


  profiles: Profile[] = [];
  drivers: Driver[] = [];
  referees: Referee[] = [];

  getProfile(id: number): Observable<Profile> {
    return this.http.get<Profile>(this.url('profile/' + id), this.options)
      .pipe(
        tap(_ => this.log('fetched profile')),
        catchError(this.handleError<Profile>('getProfile', null, { id }))
      );
  }

  getMemberByCompId(compId: number): Observable<Profile> {
    return this.http.get<Profile>(this.url('profile/member/' + compId), this.options)
      .pipe(
        tap(_ => this.log('fetched member')),
        catchError(this.handleError<Profile>('getMemberByCompId', null, { compId }))
      );
  }

  ghostResetProfile(): Observable<Profile> {
    return this.http.get<Profile>(this.url('admin/ghost/reset'), this.options).pipe(
      tap(() => {
        this.log('ghost resetting profile');
      }),
      catchError(this.handleError<Profile>('ghostResetProfile', null, {}))
    );
  }

  searchDrivers(compId: number|string, name: string): Observable<any> {
    const queryParams = `?comp_id=${compId}&name=${name}`;
    return this.http.get(this.url('searchdrivers' + queryParams), this.options)
      .pipe(
        tap(_ => this.log('fetched driver(s)')),
        catchError(this.handleError<any>('searchDrivers', [], { compId, name }))
      );
  }

  searchReferees(compId: number|string, name: string): Observable<any> {
    const params = [];
    if (compId && compId !== '') { params.push(`compid=${compId}`); }
    if (name && name !== '') { params.push(`name=${name}`); }
    const queryParams = '?' + params.join('&');
    return this.http.get(this.url('chiefref' + queryParams), this.options)
      .pipe(
        tap(_ => this.log('fetched referee(s)')),
        catchError(this.handleError<any>('searchReferees', [], { compId, name }))
      );
  }

  getCurrentUser(): Observable<Profile> {
    return this.http.get<Profile>(this.url('profile/self'), this.options)
      .pipe(
        tap(_ => {
          this.log('fetched current user');
          this.currentUser = _;
        }),
        catchError(this.handleError<Profile>('getCurrentUser', null, {} ))
      );
  }

  getUserPermissions(): Observable<PermissionSet> {
    const profileId = this.currentUser && this.currentUser.profile_id;
    return this.http.get<PermissionSet>(this.url('membership/permissions/' + profileId), this.options)
      .pipe(
        tap(_ => {
          this.log('fetched user permissions');
        }),
        catchError(this.handleError<PermissionSet>('getUserPermissions', null, { profileId }))
      );
  }

  setUserRole(permissions: PermissionSet) {
    if (permissions) {
      if (permissions.is_usac_admin) {
        return 'usac_admin';
      } else if (permissions.is_la_admin) {
        return 'la_admin';
      } else if (permissions.is_race_director) {
        return 'race_director';
      } else if (permissions.is_official) {
        return 'official';
      } else {
        return 'none';
      }
    }
  }

}
