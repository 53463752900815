import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EventListingService, PostEventService } from 'src/app/services';
import { environment } from 'src/environments/environment';

import {
  PostEvent,
  SubDiscipline,
  Event,
  EventDate,
  PostEventRiders,
  ShopifyDraftOrder,
  ShopifyOrder,
  ShopifyProductVariant
} from 'src/app/classes';
import { ShopifyService } from 'src/app/shared';

@Component({
  selector: 'app-post-event-summary',
  templateUrl: './post-event-summary.component.html',
  styles: []
})
export class PostEventSummaryComponent implements OnInit {

  @Input() postEvent: PostEvent;
  @Input() event: Event;
  @Input() eventDate: EventDate;
  @Input() eventDates: EventDate[] = [];
  @Input() isSeries: boolean;
  @Input() draftOrder: ShopifyDraftOrder;
  @Input() orders: ShopifyOrder[] = [];
  // commented out for MBR-2992
  // subDisciplines: SubDiscipline[] = [];
  registrationKeys = [
    'post_event_sufficient_staff',
    'post_event_sufficient_facilities',
    'post_event_sufficient_time',
    'post_event_start_lists_adequate',
    'post_event_check_licenses'
  ];
  conductKeys = [
    'post_event_flyer_accurate',
    'post_event_course_changes_required',
    'post_event_podium',
    // 'post_event_beginner_racer_program_event'
  ];
  supportKeys = [
    'post_event_neutral_support_services',
    'post_event_licensed_mechanics',
    'post_event_timing_company'
  ];
  prizeKeys = [
    'post_event_largest_individual_prize',
    'post_event_prize_equal_pay',
    'post_event_prize_distributed_15_min',
    'post_event_posting_area_adequate',
    'post_event_protest_period_observed'
  ];
  safesportKeys = [
    'ss_q1',
    'ss_q1_text',
    'ss_q2',
    'ss_q2_text',
    'ss_q3',
    'ss_q3_text',
    'ss_q4',
    'ss_q4_text',
    'ss_q5',
    'ss_q5_text',
    'ss_q6',
    'ss_q6_text',
    'ss_q7',
    'ss_q7_text',
    'ss_q8',
    'ss_q8_text',
  ];
  licenseMembershipKeys = [
    'post_event_one_day_on_site',
    'post_event_one_day_online',
    'post_event_membership_standard',
    'post_event_membership_premium',
    'post_event_membership_collegiate',
    'post_event_membership_junior'
  ];
  excludeFromCharges: string[] = [
    'post_event_one_day_online',
  ];

  riderSurchargeTotals = {};
  licenseTotals = {};
  membershipTotals = {};
  lateFee = false;

  // Product SKUs
  skus: string[] = [];
  riderSurchargeSkus = {};
  oneDayOnSiteSku: string;
  oneDayOnlineSku: string;
  lateFeeSku: string;
  minimumRiderSurchargeSku: string;

  memberStandardSku = environment.postEventMembershipStandard;
  memberPremiumSku = environment.postEventMembershipPremium;
  memberCollegiateSku = environment.postEventMembershipCollegiate;
  memberJuniorSku = environment.postEventMembershipJunior;

  // Product Variants
  variants: ShopifyProductVariant[];
  riderSurchargeVariants = {};
  oneDayOnSiteVariant: ShopifyProductVariant;
  oneDayOnlineVariant: ShopifyProductVariant;
  lateFeeVariant: ShopifyProductVariant;
  minimumRiderSurchargeVariant: ShopifyProductVariant;

  memberStandardVariant: ShopifyProductVariant;
  memberPremiumVariant: ShopifyProductVariant;
  memberCollegiateVariant: ShopifyProductVariant;
  memberJuniorVariant: ShopifyProductVariant;

  licenseMembershipVariants = {};

  variantsLoaded = false;
  disciplineName: string;

  get eventId() { return this.event.event_id; }

  constructor(
    private postEventService: PostEventService,
    private shopifyService: ShopifyService,
    private eventListing: EventListingService
  ) { }

  ngOnInit() {
    // commented out for MBR-2992
    // if (this.isSeries) {
    //   this.subDisciplines = this.eventDate.event_date_sub_disciplines;
    // } else {
    //   this.eventDates.forEach(eventDate => {
    //     eventDate.event_date_sub_disciplines.forEach(subDisc => this.subDisciplines.push(subDisc));
    //   });
    // }
    this.getSkus();
    this.getVariants();
    this.lateFee = this.postEventService.checkLateFee(this.isSeries, this.eventDates, this.eventDate);
  }

  getSkus() {
    this.skus = [
      this.memberStandardSku,
      this.memberPremiumSku,
      this.memberCollegiateSku,
      this.memberJuniorSku
    ];
    const skus = environment.disciplineSkus[this.event.event_discipline];
    Object.keys(skus).forEach(key => {
      if (key.includes('riderSurcharge')) {
        this.riderSurchargeSkus[key] = skus[key];
        this.skus.push(skus[key]);
      }
    });
    // Membership SKUs assigned earlier (not discipline-specific)
    this.oneDayOnSiteSku = skus.oneDayOnSiteLic;
    this.oneDayOnlineSku = skus.oneDayOnlineLic;
    this.lateFeeSku = skus.permitLateFee;
    this.minimumRiderSurchargeSku = skus.minimumRiderSurcharge;
    this.skus.push(this.oneDayOnSiteSku);
    this.skus.push(this.oneDayOnlineSku);
    this.skus.push(this.lateFeeSku);
    this.skus.push(this.minimumRiderSurchargeSku);
  }

  getVariants() {
    this.eventListing.getDisciplines().subscribe(data => {
      const discs = this.eventListing.filterDisciplines(data);
      this.disciplineName = this.eventListing.getDisciplineName(discs, this.event.event_discipline);
    });
    this.shopifyService.getShopifyProductVariants({skus: this.skus}).subscribe(variants => {
      this.variants = variants;
      Object.keys(this.riderSurchargeSkus).forEach(key => {
        this.riderSurchargeVariants[key] = this.findVariant(variants, this.riderSurchargeSkus[key]);
      });

      this.oneDayOnSiteVariant = this.findVariant(variants, this.oneDayOnSiteSku);
      this.oneDayOnlineVariant = this.findVariant(variants, this.oneDayOnlineSku);
      this.lateFeeVariant = this.findVariant(variants, this.lateFeeSku);
      this.minimumRiderSurchargeVariant = this.findVariant(variants, this.minimumRiderSurchargeSku);

      this.memberStandardVariant = this.findVariant(variants, this.memberStandardSku);
      this.memberPremiumVariant = this.findVariant(variants, this.memberPremiumSku);
      this.memberCollegiateVariant = this.findVariant(variants, this.memberCollegiateSku);
      this.memberJuniorVariant = this.findVariant(variants, this.memberJuniorSku);

      this.licenseMembershipVariants = {
        post_event_one_day_on_site: this.oneDayOnSiteVariant,
        post_event_one_day_online: this.oneDayOnlineVariant,
        post_event_membership_standard: this.memberStandardVariant,
        post_event_membership_premium: this.memberPremiumVariant,
        post_event_membership_collegiate: this.memberCollegiateVariant,
        post_event_membership_junior: this.memberJuniorVariant
      };
      this.variantsLoaded = true;
    });
  }

  findVariant(variants: ShopifyProductVariant[], sku: string) {
    return variants.find(variant => sku === variant.sku);
  }

  label(key: string) {
    return this.postEventService.part1Labels[key];
  }

  value(key: string) {
    switch (this.postEvent[key]) {
      case 'Y':
      case true: return 'Yes';
      case 'N':
      case false: return 'No';
      case 'I': return 'I don\'t know';
      default: return this.postEvent[key];
    }
  }

  nullValue(key: string) {
    const val = this.postEvent[key];
    return val === null || (typeof(val) === 'object' && !val.length);
  }

  get displayLicenseMemberships(): boolean {
    return !this.licenseMembershipKeys.some(key => this.nullValue(key));
  }

  get displayFees(): boolean {
    return !this.nullValue('post_event_participants') && !this.nullValue('post_event_riders');
  }

  get displayPayBtn(): boolean {
    return (
      this.draftOrder &&
      this.postEvent.permit_post_event_status === 'payment-due' || this.postEvent.permit_post_event_status === 'payment-late'
    );
  }

  displayLicenseMembershipRow(key: string): boolean {
    if (key === 'post_event_membership_standard') {
      return this.postEvent[key] > 0;
    }
    return true;
  }

  // commented out for MBR-2992
  // subDisciplineName(id: number) {
  //   return this.subDisciplines.find(subDisc => subDisc.subdiscipline_id === id).subdiscipline_name;
  // }

  nonSeriesDate(id: number) {
    return this.eventDates.find(eventDate => eventDate.event_date_id === id).event_date_start;
  }

  chargable(key: string): boolean {
    return !this.excludeFromCharges.includes(key);
  }

  riderSurchargeCost(riders: PostEventRiders): number {
    const rsVariants = this.riderSurchargeVariants;
    const skuPartial = this.disciplineName.toUpperCase().replace(/ /g, '-');
    const rsKey = Object.keys(rsVariants).find(key => rsVariants[key].sku === `RIDER-SUR-${skuPartial}`);
    // TODO: @Brennan - Remove fallback price once variant subdiscipline_id working?
    return rsKey ? this.riderSurchargeVariants[rsKey].price : 5.05;
  }

  riderSurchargeTotal(riders: PostEventRiders): number {
    const ridersTotal: number = typeof(riders.riders_unique) === 'string' ? parseFloat(riders.riders_unique) : riders.riders_unique;
    const total = this.riderSurchargeCost(riders) * ridersTotal;

    this.riderSurchargeTotals[riders.subdiscipline_id] = total;

    return total;
  }

  licenseMembershipCost(key: string) {
    return this.licenseMembershipVariants[key].price;
  }

  licenseMembershipTotal(key: string): number {
    const quantity = this.postEvent[key];
    const total = this.licenseMembershipCost(key) * quantity;

    if (this.chargable(key)) {
      if (/one_day/.test(key)) {
        this.licenseTotals[key] = total;
      } else if (/membership/.test(key)) {
        this.membershipTotals[key] = total;
      }
    }

    return total;
  }

  licenseMembershipTitle(key: string): string {
    switch (key) {
      case 'post_event_one_day_on_site': return 'One Day License: On-Site';
      case 'post_event_one_day_online': return 'One Day License: Online Bike Reg';
      // TODO: Consider pulling title from Shopify membership products?
      // ^^ One day license titles should remain static, as the requested form titles do not exactly match the product titles
      case 'post_event_membership_standard': return 'Post Event Membership: Standard';
      case 'post_event_membership_premium': return 'Post Event Race Membership';
      case 'post_event_membership_collegiate': return 'Post Event Race Membership: Collegiate';
      case 'post_event_membership_junior': return 'Post Event Race Membership: Junior';
    }
  }

  groupTotals(group: object): number {
    let total = 0;
    Object.values(group).forEach((value: number) => total += value);
    return total;
  }

  get applyLateFee(): boolean {
    return (this.lateFee || !!this.postEvent.post_event_late_fee) && !this.postEvent.post_event_late_fee_dismissed;
  }

  get lateFeePrice(): number {
    return this.applyLateFee ? parseFloat(this.lateFeeVariant.price) : 0;
  }

  get isSeriesFirstDay(): boolean {
    return this.isSeries && this.eventDate.event_date_first;
  }

  get finalRiderSurchargeTotal(): number {
    const total = this.groupTotals(this.riderSurchargeTotals);
    const surchargeApplicable = !this.isSeries || this.isSeriesFirstDay;
    return (surchargeApplicable && total < this.minimumRiderSurchargePrice) ? this.minimumRiderSurchargePrice : total;
  }

  get minimumRiderSurchargePrice(): number {
    return this.minimumRiderSurchargeVariant ? parseInt(this.minimumRiderSurchargeVariant.price) : null;
  }

  get subtotal() {
    if (this.draftOrder) {
      return this.draftOrder.subtotal_price;
    } else {
      let subtotal = 0;
      this.orders.forEach(order => subtotal += parseFloat(order.subtotal_price));
      return subtotal;
    }
  }

  get tax() {
    if (this.draftOrder) {
      return this.draftOrder.total_tax;
    } else {
      let tax = 0;
      this.orders.forEach(order => tax += parseFloat(order.total_tax));
      return tax;
    }
  }

  get total() {
    if (this.draftOrder) {
      return this.draftOrder.total_price;
    } else {
      let total = 0;
      this.orders.forEach(order => total += parseFloat(order.total_price));
      return total;
    }
  }

  get totalPaid(): number {
    let totalPaid = 0;
    this.orders.forEach(order => totalPaid += parseFloat(order.total_price));
    return totalPaid;
  }

  get totalDue(): number {
    return this.total - this.totalPaid;
  }

  get hasDiscounts(): boolean {
    return (
      !!this.ordersWithDiscounts.length ||
      (
        this.draftOrder &&
        (!!this.draftOrder.applied_discount || this.draftOrder.line_items.some(item => !!item.applied_discount))
      )
    );
  }

  get ordersWithDiscounts(): ShopifyOrder[] {
    return this.orders ? this.orders.filter(order => order.discount_applications.length) : [];
  }

  get totalDiscount() {
    let amount = 0;
    this.ordersWithDiscounts.forEach(order => amount += parseFloat(order.total_discounts));
    if (this.draftOrder) {
      const draftOrderDiscount = this.draftOrder.applied_discount ? parseFloat(this.draftOrder.applied_discount.amount) : 0;
      amount += draftOrderDiscount;
      let lineItemDiscounts = 0;
      this.draftOrder.line_items.forEach(item => {
        if (item.applied_discount) {
          lineItemDiscounts += parseFloat(item.applied_discount.amount);
        }
        amount += lineItemDiscounts;
      });
    }
    return amount;
  }

  get appliedDiscounts(): string[] {
    const discounts = [];
    if (this.orders) {
      this.ordersWithDiscounts.forEach(order => {
        order.discount_applications.forEach(discount => {
          if (discount.description) {
            discounts.push(this.discountDescription(discount));
          } else if (discount.code) {
            discounts.push(discount.code);
          }
        });
      });
    }
    if (this.draftOrder) {
      this.draftOrder.line_items.forEach(item => {
        if (item.applied_discount) { discounts.push(this.discountDescription(item.applied_discount)); }
      });
      if (this.draftOrder.applied_discount) { discounts.push(this.discountDescription(this.draftOrder.applied_discount)); }
    }
    return discounts;
  }

  discountDescription(discount): string {
    return discount.title ? `${discount.title} - ${discount.description}` : discount.description;
  }

  toCheckout() {
    if (this.draftOrder) {
      window.open(this.draftOrder.invoice_url);
    }
  }

}
