import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { CommentService, ProfileService } from '../../services';
import { Comment, SelectOption, Event } from '../../classes';

@Component({
  selector: 'app-overview-comments',
  templateUrl: './overview-comments.component.html',
  styles: []
})
export class OverviewCommentsComponent implements OnInit, OnChanges {

  @Input() event: Event;
  @Input() userRole: string;

  commentForm: FormGroup;
  submitted = false;
  loading = true;

  comments: Array<Comment> = [];
  recipientOptions: Array<SelectOption> = [];

  constructor(
    private formBuilder: FormBuilder,
    private commentService: CommentService,
    private profileService: ProfileService
  ) { }

  // convenience getter for easy access to form fields
  get f() { return this.commentForm.controls; }

  ngOnInit() {
    this.commentForm = this.formBuilder.group({
      message: [ '', Validators.required ],
      scope: [ 'all', Validators.required ]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userRole && changes.userRole.currentValue !== '') {
      this.setRecipientOptions();
      this.loadComments();
    }
  }

  loadComments() {
    this.commentService.getCommentsForEvent(this.event.event_id).subscribe(comments => {
      // TODO: Remove client side scope filtering once implemented by the API
      this.comments = comments.filter(comment => {
        switch (this.userRole) {
          case 'usac_admin':
            return true;
          case 'la_admin':
            return [ 'all', 'la_usac_admin' ].includes(comment.scope);
          default:
            return [ 'all', 'rd_usac_admin' ].includes(comment.scope);
        }
      });
      this.loading = false;
    });
  }

  setRecipientOptions() {
    switch (this.userRole) {
      case 'usac_admin':
        this.recipientOptions = this.commentService.usacAdminRecipients;
        break;
      case 'la_admin':
        this.recipientOptions = this.commentService.laAdminRecipients;
        break;
      default:
        this.recipientOptions = this.commentService.raceDirectorRecipients;
    }
  }

  get showRecipients() {
    return this.userRole === 'usac_admin' || this.userRole === 'la_admin' || this.isEventOrganizer;
  }

  get isEventOrganizer() {
    const currentUser = this.profileService.currentUser;
    return currentUser && currentUser.profile_id === this.event.event_organizer;
  }

  onSubmit() {
    this.submitted = true;

    // console.log('submitting form', this.f);

    // stop here if form is invalid
    if (this.commentForm.invalid) {
      return;
    }

    this.commentService.addCommentForEvent(
      this.event.event_id,
      new Comment(
        this.commentForm.value.message, this.commentForm.value.scope
      )
    ).subscribe(() => this.loadComments());

    this.submitted = false;
    this.commentForm.reset();
    this.commentForm.controls.scope.setValue('all');
  }

}
