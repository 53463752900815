import { Component, OnInit, Input, OnChanges, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Alert, Toast, Driver, Profile, Address, DriverLicense } from '../../classes';

import { ProfileService, DriverVehicleService } from '../../services';

@Component({
  selector: 'app-add-driver',
  templateUrl: './add-driver.component.html',
  styles: []
})
export class AddDriverComponent implements OnInit, OnChanges {

    @Input() drivers: DriverLicense[];
    @Input() type: string;
    @Input() permitId: number;
    @Input() submitted: boolean;

    alerts: Alert[] = [];
    searchDriverForm: FormGroup;
    addDriverForm: FormGroup;
    searchDriverSubmitted = false;
    addDriverSubmitted = false;
    displaySearchResults  = false;
    displayAddDriverForm = false;
    searching = false;
    disableSearchBtn = true;

    result: Driver;
    addedDrivers: Driver[] = [];

    @Output() quantityChange = new EventEmitter<any>();

    constructor(
      private formBuilder: FormBuilder,
      private profileService: ProfileService,
      private driverVehicleService: DriverVehicleService
    ) { }

    get sdf() { return this.searchDriverForm.controls; }
    get adf() { return this.addDriverForm.controls; }

    ngOnInit() {
      this.searchDriverForm = this.formBuilder.group({
        comp_id: [ '' ],
        name: [ '' ]
      });
      this.addDriverForm = this.formBuilder.group({
        first_name: [ '', Validators.required ],
        last_name: [ '', Validators.required ],
        license_number: [ '' ],
        profile_id: [ null ],
        mvr_status: [ null ]
      });

      this.alerts = [
        new Alert('warning', 'This driver does not have a valid MVR.  Your auto insurance will not be issued until MVR is validated.',
        'Warning', 'warning', null, null, null, false),
      ];

      this.searchDriverForm.valueChanges.subscribe(data => {
        this.disableSearchBtn = (data.comp_id === '' && data.name === '');
      });

      // setTimeout(() => {
      //   this.updateQuantity();
      //   // console.log(`${this.type} drivers: `, this.drivers);
      // }, 500);
    }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes.drivers) {
        this.updateQuantity();
      }
    }

    showAddDriverForm() {
      this.displayAddDriverForm = true;
    }

    hideAddDriverForm() {
      this.displayAddDriverForm = false;
    }

    showSearchResults() {
      this.displaySearchResults = true;
    }

    hideSearchResults() {
      this.displaySearchResults = false;
    }

    searchDriver() {
      this.searchDriverSubmitted = true;
      this.hideAddDriverForm();

      // stop here if form is invalid
      if (this.searchDriverForm.invalid) {
        return;
      }

      this.hideSearchResults();
      this.searching = true;

      this.driverVehicleService.searchDrivers(this.sdf.comp_id.value, this.sdf.name.value).subscribe(results => {
        this.searching = false;

        if (!Array.isArray(results) || !results.length) {
          this.result = null;
          this.showSearchResults();
          return;
        }

        const result = results[0];

        // TODO: Determine which statuses are valid/invalid
        const licenseNumber = result.license_number || null;
        // TODO: Uncomment once licenses are validated
        // const validLicense = new Date(result.license_expiration_date) > new Date();
        const age = Math.floor((Date.now() - Date.parse(result.birthdate)) / 31536000000);
        const profileId = result.profile_id || null;

        this.result = new Driver(
          result.first_name,
          result.last_name,
          licenseNumber,
          // TODO: Uncomment once licenses are validated
          // validLicense,
          result.mvr_status,
          result.mvr_expire,
          result.profile_comp_id,
          age,
          result.address_city,
          result.address_state,
          profileId
        );

        this.showSearchResults();
      });
    }

    addManually() {
      this.showAddDriverForm();
      this.hideSearchResults();
      this.searchDriverSubmitted = false;
      this.searchDriverForm.reset();
    }

    saveDriver(result?: Driver) {
      if (result) {
        const driver = {
          first_name: result.first_name,
          last_name: result.last_name,
          license_number: result.license_number,
          profile_id: result.profile_id,
          mvr_status: result.mvr_status,
          mvr_expire: result.mvr_expire
        };
        this.addDriver(driver);
      } else {
        this.addDriverSubmitted = true;
        // stop here if form is invalid
        if (this.addDriverForm.invalid) {
          return;
        }

        if (this.adf.first_name.value.trim() && this.adf.last_name.value.trim()) {
          this.addDriver(this.addDriverForm.value);
        }
      }
    }

    addDriver(driver: any) {
      this.driverVehicleService.addDriver(this.permitId, driver).subscribe(newDriver => {
        this.drivers.push(newDriver);
        this.updateQuantity();

        this.hideSearchResults();
        this.addDriverSubmitted = false;
        this.addDriverForm.reset();
        this.adf.profile_id.setValue(null);
        this.adf.mvr_status.setValue(null);
        this.searchDriverSubmitted = false;
        this.searchDriverForm.reset();
      });
    }

    removeDriver(index) {
      const driver = this.drivers[index];

      this.driverVehicleService.deleteDriver(this.permitId, driver.id).subscribe(() => {
        this.drivers.splice(index, 1);
        this.updateQuantity();
      });
    }

    updateQuantity() {
      this.quantityChange.emit({type: this.type, model: 'driver', quantity: this.drivers.length});
    }

    valid(driver) {
      return driver.mvr_status === 'pass' && !driver.expired;
    }
    invalid(driver) {
      return driver.mvr_status === 'fail' || driver.expired;
    }
    unknown(driver) {
      return driver.mvr_status === null || driver.mvr_expire === null;
    }
}
