<div class="form-group">
  <label for="{{name}}>">
      {{label}}
      <span *ngIf="required" class="required">*</span>
      <span *ngIf="icon" class="icon {{iconClass}}" [ngbTooltip]="tipContent">
      <ng-template #tipContent>{{tooltipContent}}</ng-template></span>
  </label>
<div>
    <ng-multiselect-dropdown
      class="form-control multiselect"
      [ngClass]="{ 'is-invalid' : formSubmitted && errors, 'disabled' : disabled }"
      id="{{id}}"
      name="{{name}}"
      [placeholder]="placeholder"
      [data]="options"
      [settings]="dropDownSettings"
      [(ngModel)]="value"
      [disabled]="disabled"
      (onFilterChange)="filterChange.emit($event)"
      (onDropDownClose)="dropDownClose.emit($event)"
      (onSelect)="select.emit($event)"
      (onDeSelect)="deSelect.emit($event)"
      (onSelectAll)="selectAll.emit($event)"
      (onDeSelectAll)="deSelectAll.emit($event)"
    >
    </ng-multiselect-dropdown>
    <div *ngIf="formSubmitted && errors" class="invalid-feedback">
      <div *ngIf="errors.required">Required</div>
    </div>
  </div>
</div>