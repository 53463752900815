import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: []
})
export class StepsComponent implements OnInit {
  percentage: number;

  @Input() steps: Array<string> = [];
  @Input() currentStep: number;
  @Output() click = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    if (!this.currentStep) {
      this.currentStep = 0;
    }

    this.percentComplete(this.steps, this.currentStep);
  }

  stepClick(currentStep: number, stepIndex: number, event: any): void {
    // console.log(currentStep, stepIndex, event);
    if (event) {
      event.stopPropagation();
    }

    this.click.emit({
      currentStep,
      stepIndex,
      event
    });
  }

  percentComplete(steps, current) {
    if (current === 0) {
      return this.percentage = 0;
    } else if ( current < steps.length) {
      return this.percentage = (current / (steps.length) * 100);
    } else {
      return this.percentage = 100;
    }
  }
}
