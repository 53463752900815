import { OnInit, EventEmitter } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { EventService } from 'src/app/services';
import { Event } from 'src/app/classes';
var CloseCancelEventComponent = /** @class */ (function () {
    function CloseCancelEventComponent(eventService, router, formBuilder) {
        this.eventService = eventService;
        this.router = router;
        this.formBuilder = formBuilder;
        this.open = false;
        this.outstandingPaperwork = false;
        this.outstandingPayments = false;
        this.incompleteForms = false;
        this.exit = new EventEmitter();
        this.closed = false;
        this.cancelled = false;
        this.timer = 5;
        this.sec = 's';
        this.disableBtns = false;
        this.statusUpdated = false;
        this.showError = false;
        this.submitted = false;
    }
    Object.defineProperty(CloseCancelEventComponent.prototype, "f", {
        get: function () { return this.closeEventForm.controls; },
        enumerable: true,
        configurable: true
    });
    CloseCancelEventComponent.prototype.ngOnInit = function () {
        this.displayText();
        if (this.closeEvent) {
            this.buildForm();
        }
    };
    CloseCancelEventComponent.prototype.buildForm = function () {
        this.closeEventForm = this.formBuilder.group({
            event_closed_without_paperwork: [this.event.event_closed_without_paperwork || '', Validators.requiredTrue],
            event_closed_comments: [this.event.event_closed_comments || '', Validators.required]
        });
    };
    Object.defineProperty(CloseCancelEventComponent.prototype, "closeEvent", {
        get: function () {
            return this.mode === 'close';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CloseCancelEventComponent.prototype, "cancelEvent", {
        get: function () {
            return this.mode === 'cancel';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CloseCancelEventComponent.prototype, "outstandingItems", {
        get: function () {
            var items = [];
            if (this.outstandingPayments) {
                items.push('unpaid orders');
            }
            if (this.incompleteForms) {
                items.push('incomplete post event forms');
            }
            if (this.outstandingPaperwork) {
                items.push('post event paperwork that has not been marked as completed');
            }
            switch (items.length) {
                case 3:
                    items.splice(1, 0, ', ');
                    items.splice(3, 0, ', and ');
                    break;
                case 2:
                    items.splice(1, 0, ', as well as ');
            }
            return items.join('');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CloseCancelEventComponent.prototype, "disableCloseBtn", {
        get: function () {
            return this.closeEventForm.invalid;
        },
        enumerable: true,
        configurable: true
    });
    CloseCancelEventComponent.prototype.openModal = function () {
        this.open = true;
    };
    CloseCancelEventComponent.prototype.exitCloseModal = function () {
        // Notify parent (event overview) of exit if status not changed to "closed", to set status form back to previous value.
        this.exit.emit({ updated: this.statusUpdated });
    };
    CloseCancelEventComponent.prototype.exitCancelModal = function () {
        this.open = false;
    };
    CloseCancelEventComponent.prototype.displayText = function () {
        this.listing = this.event.event_permit_id === null;
        this.permit = this.event.event_permit_id !== null;
    };
    CloseCancelEventComponent.prototype.onSubmit = function () {
        this.submitted = true;
        this.showError = false;
        // stop here if form is invalid
        if (this.closeEventForm.invalid) {
            return;
        }
        this.updateStatus('closed', this.closeEventForm.value);
    };
    CloseCancelEventComponent.prototype.updateStatus = function (status, closeFields) {
        var _this = this;
        if (closeFields === void 0) { closeFields = {}; }
        this.disableBtns = true;
        this.eventService.updateEventStatus(this.event.event_id, status, closeFields).subscribe(function (resp) {
            if (resp.message) {
                _this.statusUpdated = true;
                if (_this.closeEvent) {
                    _this.closed = true;
                    _this.toQueue();
                }
                else if (_this.cancelEvent) {
                    _this.cancelled = true;
                    _this.toQueue();
                }
            }
            else {
                _this.showError = true;
                _this.disableBtns = false;
            }
        });
    };
    CloseCancelEventComponent.prototype.toQueue = function () {
        var _this = this;
        this.countdown();
        setTimeout(function () { return _this.router.navigate(['/queue']); }, 5000);
    };
    CloseCancelEventComponent.prototype.countdown = function () {
        var _this = this;
        setInterval(function () {
            _this.timer = _this.timer -= 1;
            _this.sec = _this.timer > 1 ? 's' : '';
        }, 1000);
    };
    return CloseCancelEventComponent;
}());
export { CloseCancelEventComponent };
