export class Driver {
  usac_id: number;
  first_name: string;
  last_name: string;
  license_number: string;
  // TODO: Uncomment once licenses are validated
  // valid_license: boolean;
  mvr_status: string;
  mvr_expire: string;
  age: number;
  city: string;
  state: string;
  profile_id: number;

  constructor(
    first_name: string,
    last_name: string,
    license_number: string,
    // TODO: Uncomment once licenses are validated
    // valid_license: boolean,
    mvr_status: string,
    mvr_expire: string,
    usac_id?: number,
    age?: number,
    city?: string,
    state?: string,
    profile_id?: number
  ) {
    this.usac_id = usac_id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.license_number = license_number;
  // TODO: Uncomment once licenses are validated
    // this.valid_license = valid_license;
    this.mvr_status = mvr_status;
    this.mvr_expire = mvr_expire;
    this.age = age;
    this.city = city;
    this.state = state;
    this.profile_id = profile_id;
  }

  get expired(): boolean {
    const expiration = this.mvr_expire ? Date.parse(this.mvr_expire) : null;
    return expiration ? Date.now() > expiration : true;
  }
}
