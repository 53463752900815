import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Certificate, Event } from '../../classes';
import { CertificateService, EventService, ProfileService } from '../../services';

@Component({
  selector: 'app-pre-event-insurance',
  templateUrl: './pre-event-insurance.component.html',
  styles: []
})
export class PreEventInsuranceComponent implements OnInit {

  @Input() event: Event;
  @Input() userRole: string;

  loading = true;

  purchaseAdditionalCert = false;
  certificates: Certificate[];
  paidCertificates: Certificate[];

  deleteModalOpen = false;
  deleting = false;
  showDeleteError = false;
  certificateIdToDelete: number;
  insuranceSubmitting = false;
  insuranceMessage = '';
  insuranceMessageClass = 'text-success';

  constructor(
    private certificateService: CertificateService,
    private eventService: EventService,
    private profileService: ProfileService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getCertificates();
  }

  private getCertificates(): void {
    this.loading = true;
    this.certificateService.getCertificates(this.event.event_id).subscribe(certs => {
      this.paidCertificates = certs.filter(cert => !!cert.certificate_paid);
      this.certificates = certs.filter(cert => !!cert.certificate_paid || cert.type === 'named');
      this.loading = false;
    });
  }

  deleteCertificate(): void {
    this.deleting = true;
    this.showDeleteError = false;
    this.certificateService.deleteCertificate(this.event.event_id, this.certificateIdToDelete).subscribe((resp) => {
      if (resp) {
        this.getCertificates();
        this.certificateIdToDelete = null;
        this.closeDeleteModal();
      } else {
        this.showDeleteError = true;
      }
      this.deleting = false;
    });
  }

  handleDelete(certificateId: number): void {
    this.certificateIdToDelete = certificateId;
    this.openDeleteModal();
  }

  private openDeleteModal(): void {
    this.deleteModalOpen = true;
  }

  closeDeleteModal(): void {
    this.deleteModalOpen = false;
  }

  submitInsuranceChanges(): void {
    this.insuranceSubmitting = true;
    this.insuranceMessage = '';
    const changes = ['insured-certificate'];
    this.eventService.submitInsuranceChanges(this.event.event_id, changes).subscribe((resp) => {
      if (resp) {
        this.insuranceMessageClass = 'text-success';
        this.insuranceMessage = 'Certificate changes submitted to insurance.';
      } else {
        this.insuranceMessageClass = 'text-danger';
        this.insuranceMessage = 'Failed to submit certificate changes to insurance. Please try again or contact IT support.';
      }
      this.insuranceSubmitting = false;
    });
  }

  navigateToCerts() {
    this.router.navigate([`event/${this.event.event_id}/insured-certificates`]);
  }

  get isUsacAdmin(): boolean {
    return this.userRole === 'usac_admin';
  }

  get isEventOrganizer(): boolean {
    const currentUser = this.profileService.currentUser;
    return currentUser && currentUser.profile_id === this.event.event_organizer;
  }

  get isListingOwner(): boolean {
    const currentUser = this.profileService.currentUser;
    return currentUser && currentUser.profile_id === this.event.event_listing_owner;
  }

  get allowAdd(): boolean {
    return this.isUsacAdmin || this.isListingOwner || this.isEventOrganizer;
  }

  get allowInsuranceSubmit(): boolean {
    return this.isUsacAdmin && this.event.event_status === 'permit-approved';
  }

}

