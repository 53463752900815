import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService, EventListingService, PermitService } from 'src/app/services';
import { Event } from 'src/app/classes';
var EventIncentivesComponent = /** @class */ (function () {
    function EventIncentivesComponent(router, route, eventService, eventListing, permitService) {
        this.router = router;
        this.route = route;
        this.eventService = eventService;
        this.eventListing = eventListing;
        this.permitService = permitService;
        this.currentStep = 2;
        this.calendarLabels = [];
        this.calendarLabelQuestions = [];
        this.reset = false;
        this.calendarLabelsLoaded = false;
        this.calendarLabelAnswersLoaded = false;
        this.calendarLabelAnswers = [];
        this.selectedLabelIds = [];
    }
    EventIncentivesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.permitService.reset();
        this.eventListing.reset();
        this.reset = true;
        this.eventId = this.route.snapshot.paramMap.get('eventId');
        this.eventService.getEventById(this.eventId).subscribe(function (event) {
            if (event) {
                _this.event = new Event(event);
                if (_this.event.isRaceOrRide) {
                    _this.eventListing.fetchEventData(_this.eventId);
                    _this.permitService.permitId = event.event_permit_id;
                    _this.permitService.getCalendarLabels(_this.event.event_discipline).subscribe(function (labels) { return _this.calendarLabels = labels; });
                    _this.permitService.getCalendarQuestions(_this.event.event_discipline).subscribe(function (questions) {
                        _this.calendarLabelQuestions = questions;
                        _this.permitService.getCalendarLabelsForEvent(_this.eventId).subscribe(function (labels) {
                            _this.selectedLabelIds = labels.map(function (label) { return label.id; });
                            _this.calendarLabelsLoaded = true;
                        });
                        _this.permitService.getCalendarLabelAnswersForEvent(_this.eventId).subscribe(function (answers) {
                            _this.setCalendarLabelAnswers(answers);
                            _this.calendarLabelAnswersLoaded = true;
                        });
                    });
                }
                else {
                    _this.toInsuredCerts();
                }
            }
            else {
                _this.toQueue();
            }
        });
    };
    Object.defineProperty(EventIncentivesComponent.prototype, "loaded", {
        get: function () {
            return this.reset && this.eventListing.event_discipline && !!this.calendarLabels.length
                && this.calendarLabelsLoaded && this.calendarLabelAnswersLoaded;
        },
        enumerable: true,
        configurable: true
    });
    EventIncentivesComponent.prototype.toQueue = function () {
        this.router.navigate(['queue']);
    };
    EventIncentivesComponent.prototype.toInsuredCerts = function () {
        this.router.navigate(["/event/" + this.event.event_id + "/insured-certificates"]);
    };
    EventIncentivesComponent.prototype.setCalendarLabelAnswers = function (answers) {
        this.calendarLabelAnswers = answers;
    };
    return EventIncentivesComponent;
}());
export { EventIncentivesComponent };
