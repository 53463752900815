export class Contact {
    public contact_first_name: string;
    public contact_last_name: string;
    public contact_email_1: string;
    public contact_phone_1: string;
    public isValid = false;

    constructor(contact_first_name?: string, contact_last_name?: string, contact_email_1?: string, contact_phone_1: string = '') {
        this.contact_first_name = contact_first_name;
        this.contact_last_name = contact_last_name;
        this.contact_email_1 = contact_email_1;
        this.contact_phone_1 = contact_phone_1;
    }
}
