<div class="form-group">
  <label for="{{name}}>">
    {{label}}
    <span *ngIf="required" class="required">*</span>
    <span *ngIf="iconClass" class="icon {{iconClass}}" [ngbTooltip]="tooltipContent">
      <ng-template #tipContent>{{tooltipContent}}</ng-template>
    </span>
  </label>
  <div class="input-group">
    <input
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && errors}"
      placeholder="{{placeholder}}"
      onkeydown="return false"
      name="{{name}}"
      [(ngModel)]="value"
      ngbDatepicker #d="ngbDatepicker"
      [minDate]="min"
      [maxDate]="max"
      [markDisabled]="markDisabled"
      [disabled]="disabled"
    >
    <div class="input-group-append">
      <button class="btn btn-outline-secondary calendar"
        (click)="d.toggle()"
        type="button"
        [disabled]="disabled"
      ></button>
    </div>
  </div>
  <div *ngIf="formSubmitted && errors" class="invalid-feedback">
    <div *ngIf="errors.required">Required</div>
    <div *ngIf="errors.dateRange">Date must be within range</div>
  </div>
</div>
