/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../button/button.component.ngfactory";
import * as i2 from "../button/button.component";
import * as i3 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@angular/common";
import * as i6 from "./alert.component";
import * as i7 from "@angular/router";
var styles_AlertComponent = [];
var RenderType_AlertComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertComponent, data: {} });
export { RenderType_AlertComponent as RenderType_AlertComponent };
function View_AlertComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "icon icon-", _v.parent.context.$implicit.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_AlertComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "alert-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_0); }); }
function View_AlertComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toLink(_v.parent.context.$implicit.link) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(1, 114688, null, 0, i2.ButtonComponent, [], { type: [0, "type"], mode: [1, "mode"] }, null), (_l()(), i0.ɵted(2, 0, ["", ""])), i0.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.btnClass(_v.parent.context.$implicit); var currVal_1 = "button"; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.linkText)); _ck(_v, 2, 0, currVal_2); }); }
function View_AlertComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "ngb-alert", [["class", "alert"], ["role", "alert"]], [[2, "alert-dismissible", null]], [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.close(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_NgbAlert_0, i3.RenderType_NgbAlert)), i0.ɵdid(1, 638976, null, 0, i4.NgbAlert, [i4.NgbAlertConfig, i0.Renderer2, i0.ElementRef], { dismissible: [0, "dismissible"], type: [1, "type"] }, { close: "close" }), (_l()(), i0.ɵeld(2, 0, null, 0, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertComponent_2)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertComponent_3)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, 0, 0, "span", [["class", "alert-message mr-2"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AlertComponent_4)), i0.ɵdid(9, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.dismissible; var currVal_2 = _v.context.$implicit.type; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.icon; _ck(_v, 4, 0, currVal_3); var currVal_4 = _v.context.$implicit.title; _ck(_v, 6, 0, currVal_4); var currVal_6 = _v.context.$implicit.link; _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).dismissible; _ck(_v, 0, 0, currVal_0); var currVal_5 = _v.context.$implicit.message; _ck(_v, 7, 0, currVal_5); }); }
export function View_AlertComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i5.TitleCasePipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertComponent_1)), i0.ɵdid(2, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alerts; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AlertComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i0.ɵdid(1, 638976, null, 0, i6.AlertComponent, [i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertComponentNgFactory = i0.ɵccf("app-alert", i6.AlertComponent, View_AlertComponent_Host_0, { alerts: "alerts" }, {}, []);
export { AlertComponentNgFactory as AlertComponentNgFactory };
