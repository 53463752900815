<h3>Post Event Form</h3>

<p class="mt-3 small font-weight-bold">
  USA Cycling has established a minimum insurance payment for all events. This minimum will apply
  to events with a rider count lower than the count required to meet the minimum total of $300 for
  the liability insurance. Anyone who has a rider count that totals out to more than $300 will simply
  pay the per rider fee upon checkout.
</p>

<!-- Loader -->
<app-loader *ngIf="loading" [small]="true" [align]="'left'"></app-loader>

<!-- Post event period has not started -->
<div *ngIf="!inPostEvent && !loading" class="card light border-1 mt-3">
  <div class="card-header">
    <div class="row header-row">
      <h4 class="col-12">Post event period has not started</h4>
      <h5 class="col-12">Forms will be available on {{postEventStart | date:'longDate':'UTC'}}</h5>
    </div>
  </div>
</div>

<!-- Post event period has started -->
<div *ngIf="inPostEvent && !loading" class="post-event-summary">

  <ngb-accordion [closeOthers]="true" activeIds="post-event-1" class="post-event-accordion">

    <ngb-panel *ngFor="let panel of postEventPanels; let i = index;" id="post-event-{{i + 1}}" [disabled]="!hasPostEvent(panel)">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="row header-row">
          <h4 class="col-12 col-sm-6 col-md-3">{{panel.text | date:'shortDate':'UTC'}}</h4>
          <p class="col-12 col-sm-6 col-md-3">
            <span *ngIf="displayDue(panel.postEvent)">Due: {{panel.totalDue | currency}}</span>
            <span *ngIf="formDue(panel.postEvent)">Due: Pending Completion</span>
          </p>
          <p class="col-12 col-sm-6 col-md-3">
            <span class="{{badgeClass(panel.postEvent)}}">{{status(panel.postEvent) | titlecase}}</span>
          </p>
          <div class="col-12 col-sm-6 col-md-3 row justify-content-end align-items-center m-0">
            <!-- TODO: Hide payment button if post event status is 'form-due', complete toCheckout() -->
            <app-button
              [mode]="'button'"
              [type]="'btn-link p-0'"
              (click)="toCheckout(panel.draftOrder)"
              [disabled]="disablePay(panel.postEvent)"
              title="Pay"
            >
              <i class="icon icon-dollar"></i>
            </app-button>
            <app-button *ngIf="allowEdit()"
              [mode]="'button'"
              [type]="'btn-link p-0'"
              (click)="toPostEventForm(panel.url)"
              [disabled]="disableEdit(panel.postEvent)"
              title="Edit"
            >
              <i class="icon icon-edit"></i>
            </app-button>
            <app-button *ngIf="allowDelete(panel.postEvent)"
              [mode]="'button'"
              [type]="'btn-link p-0'"
              (click)="openDeleteModal(panel.postEvent)"
              title="Delete"
            >
              <i class="icon icon-trash text-primary"></i>
            </app-button>
            <button ngbPanelToggle id="post-event-{{i + 1}}-toggle" class="text-right btn icon-btn padding-right-zero">
              <i [class]="toggleIcon(i)"></i>
            </button>
          </div>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <app-post-event-summary
          [postEvent]="panel.postEvent"
          [event]="event"
          [eventDate]="panel.eventDate"
          [eventDates]="eventDates"
          [draftOrder]="panel.draftOrder"
          [orders]="panel.orders"
          [isSeries]="isSeries"
        ></app-post-event-summary>
      </ng-template>
    </ngb-panel>

  </ngb-accordion>
</div>

<!-- Post Event delete confirmation modal -->
<app-modal
  [id]="'delete-modal'"
  [title]="'Delete Post Event Form'"
  [isOpen]="deleteModalOpen"
  (close)="closeDeleteModal()"
>
<app-modal-body>
    <p>
      Are you sure you want to delete this Post Event Form? This cannot be reversed and
      will remove any data already entered, as well as any open draft orders for the form.
    </p>
  </app-modal-body>
  <app-modal-footer [class]="'justify-content-between'">
    <app-button
      [type]="'btn-light'"
      (click)="closeDeleteModal()"
      [disabled]="deleting"
    >No, Exit</app-button>
    <app-button
      [type]="'btn-primary'"
      (click)="deletePostEvent()"
      [disabled]="deleting"
    >
      Yes, Delete
      <span *ngIf="deleting" class="spinner-border spinner-border-sm ml-1"></span>
    </app-button>
  </app-modal-footer>
</app-modal>
