import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventListingService, EventService } from '../../services';
import { Event, EventDate } from '../../classes';

@Component({
  selector: 'app-add-ons',
  templateUrl: './add-ons.component.html',
  styles: []
})
export class AddOnsComponent implements OnInit {
  currentStep = 4;

  eventId: string;
  event: Event;
  discipline: number;
  eventDates: EventDate[];

  get loading(): boolean {
    return !this.discipline || !this.eventDates;
  }

  constructor(
    private eventService: EventService,
    private eventListing: EventListingService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.eventId = this.route.snapshot.paramMap.get('eventId');
    if (this.eventId) {
      this.eventService.getEventById(this.eventId).subscribe(event => {
        if (event) {
          this.event = new Event(event);
          if (!this.event.isPermit) {
            this.toOverview();
          }
          this.discipline = this.event.event_discipline;
          this.loadEventDates();
        } else {
          this.toQueue();
        }
      });
    } else {
      this.toQueue();
    }
  }

  loadEventDates(): void {
    const listingDates = this.eventListing.eventDates;
    if (!listingDates.length || !listingDates[0].isValid) {
      this.eventService.getEventDates(this.eventId).subscribe(dates => this.eventDates = dates);
    } else {
      this.eventDates = listingDates;
    }
  }

  toOverview() {
    this.router.navigate([`event/${this.eventId}/overview`]);
  }

  toQueue() {
    this.router.navigate(['queue']);
  }
}
