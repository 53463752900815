import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
export class RouteHistoryService {

    private routeHistory: string[] = [];

    public get previousUrl(): string {
      return this.routeHistory[this.routeHistory.length - 2];
    }

    constructor(private router: Router) {
      this.routeHistory.push(this.router.url);
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.routeHistory.push(event.url);
        }
      });
    }

}
