import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styles: []
})
export class ButtonComponent implements OnInit {

  @Input() type: string;
  @Input() link: string;
  @Input() mode: string;
  @Input() disabled: boolean;

  onClick() {

  }

  constructor() { }

  ngOnInit() {
    if (!this.type) {
      this.type = 'btn-primary';
    }
  }

}
