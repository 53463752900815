<!-- The 'selector' property in each component is what is used to render a particular
component as if it were an html element. The app-navbar component, in this case -->
<div class="h-100 app-container">
  <app-navbar></app-navbar>
  <app-admin-ghost-mode></app-admin-ghost-mode>
  <div class="container-fluid">
    <div *ngIf="cartAlerts.length > 0" class="mt-3">
      <app-alert [alerts]="cartAlerts"></app-alert>
    </div>
    <div *ngIf="otherAlerts.length > 0" class="mt-3">
      <app-alert [alerts]="otherAlerts"></app-alert>
    </div>
    <div *ngIf="displaySteps" class="steps-demo row d-none d-sm-block d-md-block d-lg-block d-xl-block">
      <div class="col d-flex flex-column justify-content-center align-items-center">
        <app-steps [steps]="appShell.steps" [currentStep]="currentStep"></app-steps>
      </div>
    </div>
    <div class="row">
      <div class="col" [ngClass]="{'Cart--open': displayCart, '': !displayCart}">
        <!-- Auth Loader -->
        <div *ngIf="authLoading" class="mt-3">
          <app-loader></app-loader>
        </div>

        <router-outlet (activate)="onRouteLoad($event)"></router-outlet>
      </div>
      <div
        id="cart-container"
        class="cart-container col-md-4 col-xl-3 close_me"
        [ngClass]="{'Cart--open': displayCart, 'close_me': displayCart, 'cart-fixed': displayFixedCart }"
      >
        <app-cart-shopify [checkoutDisabled]="checkoutDisabled" (loading)="onCheckoutLoad($event)"></app-cart-shopify>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
  <app-bottom-footer></app-bottom-footer>

  <!-- Checkout Overlay Loader -->
  <app-loader *ngIf="checkoutLoading" [overlay]=true></app-loader>
</div>
