import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventListingService, EventService } from '../../services';
import { Event } from '../../classes';
var AddOnsComponent = /** @class */ (function () {
    function AddOnsComponent(eventService, eventListing, router, route) {
        this.eventService = eventService;
        this.eventListing = eventListing;
        this.router = router;
        this.route = route;
        this.currentStep = 4;
    }
    Object.defineProperty(AddOnsComponent.prototype, "loading", {
        get: function () {
            return !this.discipline || !this.eventDates;
        },
        enumerable: true,
        configurable: true
    });
    AddOnsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.eventId = this.route.snapshot.paramMap.get('eventId');
        if (this.eventId) {
            this.eventService.getEventById(this.eventId).subscribe(function (event) {
                if (event) {
                    _this.event = new Event(event);
                    if (!_this.event.isPermit) {
                        _this.toOverview();
                    }
                    _this.discipline = _this.event.event_discipline;
                    _this.loadEventDates();
                }
                else {
                    _this.toQueue();
                }
            });
        }
        else {
            this.toQueue();
        }
    };
    AddOnsComponent.prototype.loadEventDates = function () {
        var _this = this;
        var listingDates = this.eventListing.eventDates;
        if (!listingDates.length || !listingDates[0].isValid) {
            this.eventService.getEventDates(this.eventId).subscribe(function (dates) { return _this.eventDates = dates; });
        }
        else {
            this.eventDates = listingDates;
        }
    };
    AddOnsComponent.prototype.toOverview = function () {
        this.router.navigate(["event/" + this.eventId + "/overview"]);
    };
    AddOnsComponent.prototype.toQueue = function () {
        this.router.navigate(['queue']);
    };
    return AddOnsComponent;
}());
export { AddOnsComponent };
