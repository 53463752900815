/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../atoms/loader/loader.component.ngfactory";
import * as i2 from "../../atoms/loader/loader.component";
import * as i3 from "../../organisms/permit-part1-form/permit-part1-form.component.ngfactory";
import * as i4 from "../../organisms/permit-part1-form/permit-part1-form.component";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/router";
import * as i7 from "../../shared/services/route-history.service";
import * as i8 from "ngx-cookie-service";
import * as i9 from "../../services/permit.service";
import * as i10 from "../../services/event-listing.service";
import * as i11 from "@angular/common";
import * as i12 from "./permit-application-part1.component";
import * as i13 from "../../services/events.service";
var styles_PermitApplicationPart1Component = [];
var RenderType_PermitApplicationPart1Component = i0.ɵcrt({ encapsulation: 2, styles: styles_PermitApplicationPart1Component, data: {} });
export { RenderType_PermitApplicationPart1Component as RenderType_PermitApplicationPart1Component };
function View_PermitApplicationPart1Component_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_PermitApplicationPart1Component_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-permit-part1-form", [], null, null, null, i3.View_PermitPart1FormComponent_0, i3.RenderType_PermitPart1FormComponent)), i0.ɵdid(1, 245760, null, 0, i4.PermitPart1FormComponent, [i5.FormBuilder, i6.Router, i7.RouteHistoryService, i8.CookieService, i9.PermitService, i10.EventListingService], { eventId: [0, "eventId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.eventId; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PermitApplicationPart1Component_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "main-container col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Permit Application"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h4", [["class", "space-below-lg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Part 1 of 2"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PermitApplicationPart1Component_1)), i0.ɵdid(8, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PermitApplicationPart1Component_2)), i0.ɵdid(10, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loaded; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.loaded; _ck(_v, 10, 0, currVal_1); }, null); }
export function View_PermitApplicationPart1Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-permit-application-part1", [], null, null, null, View_PermitApplicationPart1Component_0, RenderType_PermitApplicationPart1Component)), i0.ɵdid(1, 114688, null, 0, i12.PermitApplicationPart1Component, [i6.Router, i6.ActivatedRoute, i13.EventService, i10.EventListingService, i9.PermitService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PermitApplicationPart1ComponentNgFactory = i0.ɵccf("app-permit-application-part1", i12.PermitApplicationPart1Component, View_PermitApplicationPart1Component_Host_0, {}, {}, []);
export { PermitApplicationPart1ComponentNgFactory as PermitApplicationPart1ComponentNgFactory };
