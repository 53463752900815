import { OnInit, EventEmitter } from '@angular/core';
var noop = function () { };
var ɵ0 = noop;
var RadioSwitchItemComponent = /** @class */ (function () {
    function RadioSwitchItemComponent() {
        this.isChecked = false;
        this.inlineStyle = '';
        this.onTouchedCallback = noop;
        this.onChangeCallback = noop;
        this.change = new EventEmitter();
        this.checkedChange = new EventEmitter();
    }
    Object.defineProperty(RadioSwitchItemComponent.prototype, "checked", {
        get: function () {
            return this.isChecked;
        },
        set: function (val) {
            this.isChecked = val;
            this.change.emit(this.isChecked);
            this.checkedChange.emit(this.isChecked);
            this.onChangeCallback(this.isChecked);
        },
        enumerable: true,
        configurable: true
    });
    RadioSwitchItemComponent.prototype.writeValue = function (value) {
        this.isChecked = value;
        this.onChangeCallback(value);
    };
    // From ControlValueAccessor interface
    RadioSwitchItemComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    // From ControlValueAccessor interface
    RadioSwitchItemComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    RadioSwitchItemComponent.prototype.ngOnInit = function () {
        if (this.inline) {
            this.inlineStyle = 'custom-control-inline';
        }
    };
    return RadioSwitchItemComponent;
}());
export { RadioSwitchItemComponent };
export { ɵ0 };
