/// <reference types="googlemaps" />

import { Directive, ElementRef, EventEmitter, Output, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appSmartAddressSearch]'
})
export class SmartAddressSearchDirective implements AfterViewInit {
  @Output() addressChange: EventEmitter<any> = new EventEmitter();
  private element: HTMLInputElement;

  constructor(private elRef: ElementRef) {
    // elRef will get a reference to the element where
    // the directive is placed
    // this.element = elRef.nativeElement;
    this.element = elRef.nativeElement;
  }

  getFormattedAddress(place: any) {
    // @params: place - Google Autocomplete place object
    // @returns: location_obj - An address object in human readable format
    const location_obj = {
      formatted_address: '',
      locality: '',
      admin_area_l1: '',
      admin_area_l2: '',
      street_number: '',
      route: '',
      country: '',
      postal_code: ''
    };
    for (const i of Object.keys(place.address_components)) {
      const item = place.address_components[i];

      location_obj.formatted_address = place.formatted_address;
      if (item.types.indexOf('locality') > -1) {
        location_obj.locality = item.long_name;
      } else if (item.types.indexOf('administrative_area_level_1') > -1) {
        location_obj.admin_area_l1 = item.short_name;
      } else if (item.types.indexOf('administrative_area_level_2') > -1) {
        location_obj.admin_area_l2 = item.short_name;
      } else if (item.types.indexOf('street_number') > -1) {
        location_obj.street_number = item.short_name;
      } else if (item.types.indexOf('route') > -1) {
        location_obj.route = item.long_name;
      } else if (item.types.indexOf('country') > -1) {
        location_obj.country = item.long_name;
      } else if (item.types.indexOf('postal_code') > -1) {
        location_obj.postal_code = item.short_name;
      }
    }
    return location_obj;
  }
  ngAfterViewInit() {
    const input = this.elRef.nativeElement.querySelector('input');
    const autocomplete = new google.maps.places.Autocomplete(input, {types: ['address']});
    // Event listener to monitor place changes in the input
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      // Emit the new address object for the updated place
      this.addressChange.emit(this.getFormattedAddress(autocomplete.getPlace()));

      // TODO: Better method to ensure UI updates after address is selected?
      setTimeout(() => input.focus(), 100);
    });
  }
}
