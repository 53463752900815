import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Alert, Driver, DriverLicense } from '../../classes';
import { ProfileService, DriverVehicleService } from '../../services';
var AddDriverComponent = /** @class */ (function () {
    function AddDriverComponent(formBuilder, profileService, driverVehicleService) {
        this.formBuilder = formBuilder;
        this.profileService = profileService;
        this.driverVehicleService = driverVehicleService;
        this.alerts = [];
        this.searchDriverSubmitted = false;
        this.addDriverSubmitted = false;
        this.displaySearchResults = false;
        this.displayAddDriverForm = false;
        this.searching = false;
        this.disableSearchBtn = true;
        this.addedDrivers = [];
        this.quantityChange = new EventEmitter();
    }
    Object.defineProperty(AddDriverComponent.prototype, "sdf", {
        get: function () { return this.searchDriverForm.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddDriverComponent.prototype, "adf", {
        get: function () { return this.addDriverForm.controls; },
        enumerable: true,
        configurable: true
    });
    AddDriverComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchDriverForm = this.formBuilder.group({
            comp_id: [''],
            name: ['']
        });
        this.addDriverForm = this.formBuilder.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            license_number: [''],
            profile_id: [null],
            mvr_status: [null]
        });
        this.alerts = [
            new Alert('warning', 'This driver does not have a valid MVR.  Your auto insurance will not be issued until MVR is validated.', 'Warning', 'warning', null, null, null, false),
        ];
        this.searchDriverForm.valueChanges.subscribe(function (data) {
            _this.disableSearchBtn = (data.comp_id === '' && data.name === '');
        });
        // setTimeout(() => {
        //   this.updateQuantity();
        //   // console.log(`${this.type} drivers: `, this.drivers);
        // }, 500);
    };
    AddDriverComponent.prototype.ngOnChanges = function (changes) {
        if (changes.drivers) {
            this.updateQuantity();
        }
    };
    AddDriverComponent.prototype.showAddDriverForm = function () {
        this.displayAddDriverForm = true;
    };
    AddDriverComponent.prototype.hideAddDriverForm = function () {
        this.displayAddDriverForm = false;
    };
    AddDriverComponent.prototype.showSearchResults = function () {
        this.displaySearchResults = true;
    };
    AddDriverComponent.prototype.hideSearchResults = function () {
        this.displaySearchResults = false;
    };
    AddDriverComponent.prototype.searchDriver = function () {
        var _this = this;
        this.searchDriverSubmitted = true;
        this.hideAddDriverForm();
        // stop here if form is invalid
        if (this.searchDriverForm.invalid) {
            return;
        }
        this.hideSearchResults();
        this.searching = true;
        this.driverVehicleService.searchDrivers(this.sdf.comp_id.value, this.sdf.name.value).subscribe(function (results) {
            _this.searching = false;
            if (!Array.isArray(results) || !results.length) {
                _this.result = null;
                _this.showSearchResults();
                return;
            }
            var result = results[0];
            // TODO: Determine which statuses are valid/invalid
            var licenseNumber = result.license_number || null;
            // TODO: Uncomment once licenses are validated
            // const validLicense = new Date(result.license_expiration_date) > new Date();
            var age = Math.floor((Date.now() - Date.parse(result.birthdate)) / 31536000000);
            var profileId = result.profile_id || null;
            _this.result = new Driver(result.first_name, result.last_name, licenseNumber, 
            // TODO: Uncomment once licenses are validated
            // validLicense,
            result.mvr_status, result.mvr_expire, result.profile_comp_id, age, result.address_city, result.address_state, profileId);
            _this.showSearchResults();
        });
    };
    AddDriverComponent.prototype.addManually = function () {
        this.showAddDriverForm();
        this.hideSearchResults();
        this.searchDriverSubmitted = false;
        this.searchDriverForm.reset();
    };
    AddDriverComponent.prototype.saveDriver = function (result) {
        if (result) {
            var driver = {
                first_name: result.first_name,
                last_name: result.last_name,
                license_number: result.license_number,
                profile_id: result.profile_id,
                mvr_status: result.mvr_status,
                mvr_expire: result.mvr_expire
            };
            this.addDriver(driver);
        }
        else {
            this.addDriverSubmitted = true;
            // stop here if form is invalid
            if (this.addDriverForm.invalid) {
                return;
            }
            if (this.adf.first_name.value.trim() && this.adf.last_name.value.trim()) {
                this.addDriver(this.addDriverForm.value);
            }
        }
    };
    AddDriverComponent.prototype.addDriver = function (driver) {
        var _this = this;
        this.driverVehicleService.addDriver(this.permitId, driver).subscribe(function (newDriver) {
            _this.drivers.push(newDriver);
            _this.updateQuantity();
            _this.hideSearchResults();
            _this.addDriverSubmitted = false;
            _this.addDriverForm.reset();
            _this.adf.profile_id.setValue(null);
            _this.adf.mvr_status.setValue(null);
            _this.searchDriverSubmitted = false;
            _this.searchDriverForm.reset();
        });
    };
    AddDriverComponent.prototype.removeDriver = function (index) {
        var _this = this;
        var driver = this.drivers[index];
        this.driverVehicleService.deleteDriver(this.permitId, driver.id).subscribe(function () {
            _this.drivers.splice(index, 1);
            _this.updateQuantity();
        });
    };
    AddDriverComponent.prototype.updateQuantity = function () {
        this.quantityChange.emit({ type: this.type, model: 'driver', quantity: this.drivers.length });
    };
    AddDriverComponent.prototype.valid = function (driver) {
        return driver.mvr_status === 'pass' && !driver.expired;
    };
    AddDriverComponent.prototype.invalid = function (driver) {
        return driver.mvr_status === 'fail' || driver.expired;
    };
    AddDriverComponent.prototype.unknown = function (driver) {
        return driver.mvr_status === null || driver.mvr_expire === null;
    };
    return AddDriverComponent;
}());
export { AddDriverComponent };
