import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { phoneNumberValidator, nameCharactersValidator, emailValidator } from 'src/app/validators';
import { EventListingService, ProfileService } from '../../services';
import { Contact } from '../../classes';
var EventListingContactComponent = /** @class */ (function () {
    function EventListingContactComponent(formBuilder, eventListing, profiles) {
        this.formBuilder = formBuilder;
        this.eventListing = eventListing;
        this.profiles = profiles;
    }
    Object.defineProperty(EventListingContactComponent.prototype, "f", {
        get: function () { return this.eventContact.controls; },
        enumerable: true,
        configurable: true
    });
    EventListingContactComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.eventContact = this.formBuilder.group({
            contact_first_name: ['', [Validators.required, nameCharactersValidator()]],
            contact_last_name: ['', [Validators.required, nameCharactersValidator()]],
            contact_email_1: ['', [Validators.required, emailValidator()]],
            contact_phone_1: ['', [phoneNumberValidator()]],
        });
        if (this.eventListing.contacts.length !== 0) {
            this.recentContact = this.eventListing.contacts[(this.eventListing.contacts.length - 1)];
            this.assignFormValues(this.recentContact);
        }
        else {
            this.recentContact = new Contact('', '', '');
            this.assignFormValues(this.recentContact);
            this.eventListing.addContcat(this.recentContact);
        }
        // TODO: Is this needed, or can we remove/refactor this logic?
        this.eventContact.valueChanges.subscribe(function (data) {
            _this.recentContact.contact_first_name = data.contact_first_name;
            _this.recentContact.contact_last_name = data.contact_last_name;
            _this.recentContact.contact_email_1 = data.contact_email_1;
            _this.recentContact.contact_phone_1 = data.contact_phone_1;
            _this.recentContact.isValid = _this.eventContact.valid;
        });
    };
    EventListingContactComponent.prototype.assignFormValues = function (contact) {
        this.eventContact.setValue({
            contact_first_name: contact.contact_first_name,
            contact_last_name: contact.contact_last_name,
            contact_email_1: contact.contact_email_1,
            contact_phone_1: contact.contact_phone_1
        });
    };
    EventListingContactComponent.prototype.composeContact = function () {
        if (this.eventListing.contacts.length > this.eventListing.contactsLimit) {
            return;
        }
        if (this.eventContact.valid) {
            this.recentContact = new Contact();
            this.eventListing.addContcat(this.recentContact);
            this.eventContact.reset();
            this.eventListing.validateContacts = false;
        }
        else {
            this.eventListing.validateContacts = true;
        }
        console.log(this.eventListing.contacts);
    };
    EventListingContactComponent.prototype.removeContact = function (index) {
        this.eventListing.removeContact(index);
    };
    EventListingContactComponent.prototype.editContact = function (index) {
        if (this.eventContact.valid || this.eventListing.contacts.length > 3) {
            var selectedContcat = void 0;
            selectedContcat = this.eventListing.contacts.splice(index, 1);
            var recentContact = new Contact();
            Object.assign(recentContact, selectedContcat[0]);
            this.composeContact();
            this.f.contact_first_name.setValue(recentContact.contact_first_name);
            this.f.contact_last_name.setValue(recentContact.contact_last_name);
            this.f.contact_email_1.setValue(recentContact.contact_email_1);
            this.f.contact_phone_1.setValue(recentContact.contact_phone_1);
        }
        else {
            this.eventListing.validateContacts = true;
        }
    };
    return EventListingContactComponent;
}());
export { EventListingContactComponent };
