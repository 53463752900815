import { OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RadioOption, } from '../../classes';
import { yearValidator, vehicleYearValidator } from 'src/app/validators';
import { DriverVehicleService } from '../../services';
var AddVehicleComponent = /** @class */ (function () {
    function AddVehicleComponent(formBuilder, driverVehicleService) {
        this.formBuilder = formBuilder;
        this.driverVehicleService = driverVehicleService;
        this.insuranceRequirementsOptions = [];
        this.submitted = false;
        this.vehicles = [];
        this.quantityChange = new EventEmitter();
    }
    Object.defineProperty(AddVehicleComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.addVehicleForm.controls; },
        enumerable: true,
        configurable: true
    });
    AddVehicleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.addVehicleForm = this.formBuilder.group({
            vehicle_model: ['', Validators.required],
            vehicle_make: ['', Validators.required],
            vehicle_year: ['', [Validators.required, yearValidator(), vehicleYearValidator()]],
            vehicle_vin: ['', Validators.required],
            vehicle_insurance: ['N', Validators.required],
            vehicle_type: [this.type],
            vehicle_state: ['pending']
        });
        this.insuranceRequirementsOptions = [
            new RadioOption('yes', 'Y', 'Yes'),
            new RadioOption('no', 'N', 'No')
        ];
        setTimeout(function () {
            _this.updateQuantities();
            // console.log(`${this.type} vehicles: `, this.vehicles);
        }, 500);
    };
    AddVehicleComponent.prototype.updateQuantities = function () {
        this.complete = this.vehicles.length;
        this.incomplete = this.quantity - this.complete;
        this.quantityChange.emit({ type: this.type, model: 'vehicle', quantity: this.vehicles.length });
    };
    AddVehicleComponent.prototype.addVehicle = function () {
        var _this = this;
        this.driverVehicleService.addVehicle(this.permitId, this.addVehicleForm.value).subscribe(function (vehicle) {
            _this.vehicles.push(vehicle);
            _this.updateQuantities();
        });
    };
    AddVehicleComponent.prototype.removeVehicle = function (index) {
        var _this = this;
        var vehicle = this.vehicles[index];
        this.driverVehicleService.deleteVehicle(this.permitId, vehicle.id).subscribe(function () {
            _this.vehicles.splice(index, 1);
            _this.updateQuantities();
        });
        // this.resetForm();
    };
    AddVehicleComponent.prototype.resetForm = function () {
        this.submitted = false;
        this.addVehicleForm.reset();
        this.f.vehicle_insurance.setValue('N');
        this.f.vehicle_state.setValue('pending');
        this.f.vehicle_type.setValue(this.type);
    };
    AddVehicleComponent.prototype.onSubmit = function () {
        this.submitted = true;
        // stop here if form is invalid
        if (this.addVehicleForm.invalid) {
            return;
        }
        this.addVehicle();
        this.resetForm();
    };
    return AddVehicleComponent;
}());
export { AddVehicleComponent };
