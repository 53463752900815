import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-body',
  templateUrl: './modal-body.component.html',
  styles: []
})
export class ModalBodyComponent {
  @Input() class = '';
}
