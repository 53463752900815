<form *ngIf="formGroup else feeTotals" [formGroup]="formGroup">
  <ng-container *ngTemplateOutlet="titleContent"></ng-container>
  <div style="overflow-x: auto;">
    <table class="table table-striped table-post-event" role="table">
      <ng-container *ngTemplateOutlet="headerContent"></ng-container>

      <!-- Event Participants -->
      <tbody *ngIf="participants()" role="rowgroup">
        <tr *ngFor="let group of groupedControls | keyvalue: sortNull">
          <td *ngIf="!hideDate" class="{{alignByTitle('Date')}}">{{eventDateStart(group.key) | date:'shortDate':'UTC'}}</td>
          <!-- commented out for MBR-2992 -->
          <!-- <td class="{{alignByTitle('Sub-Discipline')}}">{{subDisciplineName(group.key)}}</td> -->
          <td *ngFor="let control of group.value | keyvalue; let i = index" class="{{alignByIndex(i + 2)}}">
            <span *ngIf="!showParticipantInput(control.key)">{{control.value.value || 0}}</span>
            <!-- Only allow form input for On-Site Registrations field -->
            <div *ngIf="showParticipantInput(control.key)" class="center-horizontal" style="width: 62px;">
              <app-text-input
                [formControlName]="control.key"
                [type]="'number'"
                [id]="control.key"
                [name]="control.key"
                [max]="maxQuantity"
                [formSubmitted]="submitted"
                [errors]="control.value.errors"
              ></app-text-input>
            </div>
          </td>
        </tr>
      </tbody>

      <!-- Rider Feees -->
      <tbody *ngIf="riders()" role="rowgroup">
        <tr *ngFor="let group of groupedControls | keyvalue: sortNull">
          <td *ngIf="!hideDate" class="{{alignByTitle('Date')}}">{{eventDateStart(group.key) | date:'shortDate':'UTC'}}</td>
          <!-- commented out for MBR-2992 -->
          <!-- <td class="{{alignByTitle('Sub-Discipline')}}">{{subDisciplineName(group.key)}}</td> -->
          <td *ngFor="let control of group.value | keyvalue: sortNull; let i = index" class="{{alignByIndex(i + 1)}}">
            <span *ngIf="!control.key.includes('unique')">
              <span *ngIf="!riderCurrencyCol(control.key)">{{control.value.value}}</span>
              <span *ngIf="riderCurrencyCol(control.key)">
                {{control.value.value | currency}}<span *ngIf="control.key.includes('cost')">/ea</span>
              </span>
            </span>
            <!-- Only allow form input for Duplicate Riders field -->
            <div *ngIf="control.key.includes('unique')" class="center-horizontal" style="width: 62px;">
              <app-text-input
                [formControlName]="control.key"
                [type]="'number'"
                [id]="control.key"
                [name]="control.key"
                [min]="minQuantity"
                [max]="maxQuantity"
                [formSubmitted]="true"
                [errors]="control.value.errors"
              ></app-text-input>
            </div>
          </td>
        </tr>
      </tbody>

      <!-- Licenses & Memberships -->
      <tbody *ngIf="licensesMemberships()" role="rowgroup">
        <tr *ngFor="let control of formGroup.controls | keyvalue: sortNull">
          <td *ngFor="let header of headers[section]" class="{{alignByTitle(header.text)}}">
            <!-- TODO: Replace fallback placeholders -->
            <span *ngIf="col(header, 'Item')">{{licenseMembershipTitle(control.key) || 'Product Name'}}</span>
            <span *ngIf="col(header, 'Cost') && chargable(control.key)">{{variantPrice(control.key) | currency}}/ea</span>
            <span *ngIf="col(header, 'Total') && chargable(control.key)">{{variantTotal(control.key) | currency}}</span>
            <div *ngIf="col(header, 'Quantity')" class="center-horizontal" style="width: 62px;">
              <app-text-input
                [formControlName]="control.key"
                [type]="'number'"
                [id]="control.key"
                [name]="control.key"
                [max]="maxQuantity"
                [formSubmitted]="submitted"
                [errors]="control.value.errors"
              ></app-text-input>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</form>

<ng-template #titleContent>
  <h4 class="space-below-sm">{{title}}</h4>
  <p *ngIf="instructions" class="small" style="margin-top: -.75rem;">{{instructions}}</p>
</ng-template>

<ng-template #headerContent>
    <thead role="rowgroup">
      <tr role="row">
        <th *ngFor="let header of selectHeaders" role="columnheader" class="text-{{header.align}}" style="white-space: normal;">{{header.text}}</th>
      </tr>
    </thead>
</ng-template>

<!-- Post Event Fees -->
<ng-template #feeTotals>
  <ng-container *ngTemplateOutlet="titleContent"></ng-container>

  <!-- Calendar Label & Discount Review -->
  <app-event-incentives-review *ngIf="isUsacAdmin && isRaceOrRide"
    [calendarLabels]="calendarLabels"
    [calendarLabelQuestions]="calendarLabelQuestions"
    [calendarLabelAnswers]="calendarLabelAnswers"
    [event]="event"
    [isPostEvent]="true"
    [eventIncentivesDiscountAmt]="eventIncentivesDiscountAmt"
    (eventIncentiveDiscountQualification)="onDiscountQualificationChange($event)"
  ></app-event-incentives-review>

  <form [formGroup]="feeTotalsForm">
    <div style="overflow-x: auto;">
      <table class="table table-striped table-post-event" role="table">
        <ng-container *ngTemplateOutlet="headerContent"></ng-container>
        <tbody *ngIf="fees()" role="rowgroup">
          <tr *ngFor="let row of feesFormRows; let i = index" [ngClass]="{'summary-row': !!totalsRows[i - 4]}">
            <td *ngFor="let header of headers[section]" class="{{alignByTitle(header.text)}}">
              <span *ngIf="col(header, 'Fees')">{{row}}</span>
              <div *ngIf="col(header, '')">
                <app-checkbox *ngIf="feesRow(i, 'Late Fee') && isUsacAdmin && lateFee"
                  [formControlName]="'post_event_late_fee_dismissed'"
                  [name]="'post_event_late_fee_dismissed'"
                  [id]="'post_event_late_fee_dismissed'"
                  [label]="'Dismiss'"
                  [toggle]="true"
                  [inline]="true"
                  [formSubmitted]="submitted"
                ></app-checkbox>
                <span *ngIf="row === ''">
                  {{totalsRows[i - 4]}}<span *ngIf="totalsRow(i, 'Note') && requireNote" class="required">*</span>
                </span>
              </div>
              <div *ngIf="col(header, 'To Pay')">
                <div *ngIf="!totalsRow(i, 'Order Discount') && !totalsRow(i, 'Note')">
                  <span *ngIf="!totalsRows[i - 4]">
                    <!-- Show notice if minimum rider surcharge applied -->
                    <strong *ngIf="feesRow(i, 'Rider Surcharge') && minimumRiderSurchargeApplied" class="text-info">
                      Minimum rider surcharge applied
                    </strong>
                    <span class="ml-2">
                      {{feesRowValue(i) | currency}}
                    </span>
                  </span>

                  <div *ngIf="!!totalsRows[i - 4]"
                    [ngClass]="{'text-primary': totalsRow(i, 'Total Due') && totalsRowValue(i) > 0}"
                  >
                    <div *ngIf="totalsRow(i, 'Amount Paid')">
                      <div *ngFor="let order of orders" class="row">
                        <div class="col-6 col-sm-7 col-md-8 col-lg-9">{{order.processed_at | date:'shortDate':'UTC'}}</div>
                        <div class="col-6 col-sm-5 col-md-4 col-lg-3 text-success">- {{order.total_price | currency}}</div>
                      </div>
                      <span *ngIf="!orders.length">{{0 | currency}}</span>
                    </div>
                    <span *ngIf="!totalsRow(i, 'Amount Paid') && !totalsRow(i, 'Other Discounts')">
                      <app-loader *ngIf="updating"
                        [small]="true"
                        [align]="'right'"
                        [text]="''"
                        [hasTopMargin]="false"
                      ></app-loader>
                      <span *ngIf="!updating">{{totalsRowValue(i) | currency}}</span>
                    </span>
                  </div>
                </div>

                <div *ngIf="totalsRow(i, 'Order Discount') && displayDiscount" style="width: 110px; margin-left: auto;">
                  <span>{{ftf.post_event_discount.value | currency}}</span>
                  <!-- NOTE: Left in case manual discount field ever required again -->
                  <!-- <app-text-input *ngIf="isUsacAdmin"
                    [formControlName]="'post_event_discount'"
                    [type]="'currency'"
                    [name]="'post_event_discount'"
                    [id]="'post_event_discount'"
                    [min]="0"
                    [max]="preDiscount"
                    [prepend]="true"
                    [prependContent]="'$'"
                    [formSubmitted]="submitted"
                    [errors]="ftf.post_event_discount.errors"
                  ></app-text-input> -->
                </div>
                <div *ngIf="totalsRow(i, 'Other Discounts')">
                  <app-loader *ngIf="updating"
                    [small]="true"
                    [align]="'right'"
                    [text]="''"
                    [hasTopMargin]="false"
                  ></app-loader>
                  <div *ngIf="!updating">
                    <span *ngIf="!lineItemDiscounts.length">{{0 | currency}}</span>
                    <div *ngFor="let discount of lineItemDiscounts" class="row">
                      <div class="col-6 col-sm-7 col-md-8 col-lg-9">{{discount.description}}</div>
                      <div class="col-6 col-sm-5 col-md-4 col-lg-3">- {{discount.amount | currency}}</div>
                    </div>
                  </div>
                </div>
                <div *ngIf="totalsRow(i, 'Note')">
                  <app-text-area
                    [formControlName]="'post_event_order_note'"
                    [name]="'post_event_order_note'"
                    [id]="'post_event_order_note'"
                    [placeholder]="'Add order note here...'"
                    [maxLength]="'200'"
                    [minHeight]="105"
                    [formSubmitted]="submitted"
                    [errors]="ftf.post_event_order_note.errors"
                  ></app-text-area>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</ng-template>
