<ngb-accordion (panelChange)="onPanelChange($event)" class="border-0 payment-accordion">
  <ngb-panel type="light">
    <ng-template ngbPanelHeader>
      <div class="row ml-0">
        <div class="col-11 row align-items-center">
          <div class="product-name col-md-3">
            <strong>{{type}}</strong>
          </div>
          <div class="col-md-4">
            <span>Name: {{certificate.name}}</span>
          </div>
          <div class="col-md-4" >Relationship: {{relationship | titlecase}}</div>
          <div *ngIf="isUsacAdmin" class="col-md-1">
            <app-button
              [mode]="'button'"
              [type]="'btn-link px-0'"
              (click)="deleteCertificate()"
            >
              <i class="icon icon-trash text-primary" title="Delete Certificate"></i>
            </app-button>
          </div>
        </div>
        <button ngbPanelToggle class="col-1 text-right btn icon-btn padding-right-zero"><i class="icon {{toggleIcon}}"></i></button>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="row pl-3 pr-3">
        <div class="col-12 pr-lg-5">
          <div class="row">
            <div class="col-md-6">
                <p><strong>Address:</strong> {{address}}, {{certificate.city}}, {{certificate.state}}, {{certificate.zip}}</p>
            </div>
            <div class="col-md-3">
              <p><strong>Email:</strong> {{certificate.email}}</p>
            </div>
            <div class="col-md-3">
              <p><strong>Phone:</strong> {{certificate.phone}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md">
              <p><strong>Specific Verbiage:</strong> {{certificate.insured_info}}</p>
            </div>
            <div *ngIf="certificate.endorsement.length > 0" class="col-md">
              <p><strong>Endorsements:</strong></p><span *ngFor="let endorsement of certificate.endorsement">{{endorsement['title']}}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
