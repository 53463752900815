import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styles: []
})
export class CardComponent implements OnInit {

  isLight = '';

  @Input() id: string;
  @Input() imageSrc: string;
  @Input() imageAlt: string;
  @Input() width: number;
  @Input() light: boolean;
  @Input() fullHeight = false;
  @Input() classes: string;


  constructor() { }

  ngOnInit() {
    if (this.classes === undefined) {
      this.classes = '';
    }
    if (this.light) {
      this.isLight = 'light';
    }
  }

}
