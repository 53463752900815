import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Certificate } from '../../classes';

@Component({
  selector: 'app-insurance-accordion',
  templateUrl: './insurance-accordion.component.html',
  styles: []
})
export class InsuranceAccordionComponent {
  open = false;
  toggleIcon = 'icon-chevron-down';

  @Input() certificate: Certificate;
  @Input() isUsacAdmin: boolean;
  @Output() delete: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  get c() { return this.certificate; }

  get type(): string {
    switch (this.c.type) {
      case 'named': return 'Named Insured';
      case 'additional': return 'Additional Insured';
      default: return 'Insured';
    }
  }

  get relationship(): string {
    return this.c.custom_relationship || this.c.relationship.split('_').join(' ');
  }

  get address(): string {
    return (this.c.unit && this.c.unit !== '') ? this.c.street + ' ' + this.c.unit : this.c.street;
  }

  onPanelChange(event) {
    this.open = event.nextState;
    this.toggleIcon = event.nextState ? 'icon-chevron-up' : 'icon-chevron-down';
  }

  deleteCertificate(): void {
    this.delete.emit(this.certificate.id);
  }

}
