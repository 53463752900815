export class CustomFile extends Blob {
    public lastModifiedDate: Date;
    public name: string;
    public lastModified: any;
    constructor(chunks: Array<any>, filename: string, opts: object) {
      super(chunks, opts);
      this.lastModifiedDate = new Date();
      this.lastModified = + this.lastModifiedDate;
      this.name = filename;
    }
  }

