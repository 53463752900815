<div [ngSwitch]="eventListing.mode" >
  <div *ngSwitchCase="'create'" >
    <div class="container first">
      <h2 class="title">Create Your Event Listing</h2>
      <p class="small">
        Thank you for choosing to register your event with USA Cycling!
        You will be able to list your event on the USA Cycling Calendar or permit your event to take advantage of USA Cycling's insurance products and promotional and logistical support at this time.
        Listing your event is free to all cycling events and you can come back to take advantage of permitting your event if you choose to at a later date.
        There are info buttons on areas where questions may arise to help you navigate the process.
        If you run into any issues or have questions then please contact us at <a href="mailto:eventservices@usacycling.org">eventservices@usacycling.org</a>.
      </p>
      <div class="text-right" >
        <app-copy-past-event></app-copy-past-event>
      </div>
      <app-event-listing-form *ngIf="newLoaded"></app-event-listing-form>
    </div>
  </div>
  <div class="container mt-4 mb-4"
    *ngSwitchCase="'update'">
    <h2 class="title">Edit Your Event Listing</h2>
    <app-loader *ngIf="!dataLoaded"></app-loader>
    <app-event-listing-form *ngIf="dataLoaded" [eventId]="eventId"></app-event-listing-form>
  </div>
</div>
