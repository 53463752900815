import { Notifier, Filter } from '@airbrake/browser';
import { environment } from '../environments/environment';

let airbrake: any|Notifier = {
  notify: (err) => {
    console.warn('HEY: Using Default Airbrake notify logger (Airbrake project not setup)');
    console.error('NOTIFY', err);
  }
};

if (environment.airbrake && environment.airbrake.id && environment.airbrake.key) {
  airbrake = new Notifier({
    projectId: environment.airbrake.id,
    projectKey: environment.airbrake.key,
    environment: environment.env
  });

  airbrake.addFilter((notice) => {
    notice.context.environment = environment.env || 'development';

    if ([ 'dev', 'local', 'development' ].includes(notice.context.environment)) {
      notice.context.severity = 'warning';
    }

    const { location } = window;
    const params = { location };

    Object.assign(notice, {
      params,
      environment
    });

    return notice;
  });
}

export default airbrake;
