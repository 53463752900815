/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../atoms/loader/loader.component.ngfactory";
import * as i2 from "../../atoms/loader/loader.component";
import * as i3 from "../../atoms/button/button.component.ngfactory";
import * as i4 from "../../atoms/button/button.component";
import * as i5 from "../../molecules/modal/modal.component.ngfactory";
import * as i6 from "../../molecules/modal/modal.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../atoms/modal-body/modal-body.component.ngfactory";
import * as i9 from "../../atoms/modal-body/modal-body.component";
import * as i10 from "@angular/common";
import * as i11 from "../../organisms/listing-body/listing-body.component.ngfactory";
import * as i12 from "../../organisms/listing-body/listing-body.component";
import * as i13 from "../../services/event-listing.service";
import * as i14 from "../../services/events.service";
import * as i15 from "./event-listing-review.component";
import * as i16 from "../../services/profile.service";
import * as i17 from "@angular/router";
var styles_EventListingReviewComponent = [];
var RenderType_EventListingReviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EventListingReviewComponent, data: {} });
export { RenderType_EventListingReviewComponent as RenderType_EventListingReviewComponent };
function View_EventListingReviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h2", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Review Your Event Listing"]))], null, null); }
function View_EventListingReviewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_EventListingReviewComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Please note that in order to permit a competitive event you need a valid USA Cycling Race Director License - $150"]))], null, null); }
function View_EventListingReviewComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPermitModal($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(1, 114688, null, 0, i4.ButtonComponent, [], { type: [0, "type"], disabled: [1, "disabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["Permit Your Event with USA Cycling"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn-event"; var currVal_1 = _co.disableListingPermitBtns; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_EventListingReviewComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["In order to permit a competitive event you need a valid USA Cycling Race Director License - $150"]))], null, null); }
function View_EventListingReviewComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 51, "div", [["class", "row text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 50, "div", [["class", "col-sm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Would you like to?"])), (_l()(), i0.ɵeld(5, 0, null, null, 42, "div", [["class", "form-row justify-content-center mt-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openSubmitModal($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(7, 114688, null, 0, i4.ButtonComponent, [], { type: [0, "type"], disabled: [1, "disabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["Submit Your Calendar listing"])), (_l()(), i0.ɵeld(9, 0, null, null, 16, "app-modal", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeSubmitModal($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ModalComponent_0, i5.RenderType_ModalComponent)), i0.ɵdid(10, 638976, null, 0, i6.ModalComponent, [i7.NgbModal], { id: [0, "id"], title: [1, "title"], isOpen: [2, "isOpen"], allowClose: [3, "allowClose"] }, { close: "close" }), (_l()(), i0.ɵeld(11, 0, null, 0, 14, "app-modal-body", [], null, null, null, i8.View_ModalBodyComponent_0, i8.RenderType_ModalBodyComponent)), i0.ɵdid(12, 49152, null, 0, i9.ModalBodyComponent, [], null, null), (_l()(), i0.ɵeld(13, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["You are submitting a listing only by declining to permit the event. An event listing allows your event to be listed on the USA Cycling event calendar, but it does not provide general liability insurance. You must submit a permit to obtain the general liability insurance, as well as other benefits such as upgrade points, results and rankings points and additional promotional opportunities for your event."])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_EventListingReviewComponent_5)), i0.ɵdid(16, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(17, 0, null, 0, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 3, "div", [["class", "col-sm-6 text-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 2, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exitToQueue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(20, 114688, null, 0, i4.ButtonComponent, [], { type: [0, "type"], mode: [1, "mode"], disabled: [2, "disabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["Exit and List Only"])), (_l()(), i0.ɵeld(22, 0, null, null, 3, "div", [["class", "col-sm-6 text-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 2, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toPermit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(24, 114688, null, 0, i4.ButtonComponent, [], { type: [0, "type"], mode: [1, "mode"], disabled: [2, "disabled"] }, null), (_l()(), i0.ɵted(25, 0, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventListingReviewComponent_6)), i0.ɵdid(27, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(28, 0, null, null, 19, "app-modal", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closePermitModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ModalComponent_0, i5.RenderType_ModalComponent)), i0.ɵdid(29, 638976, null, 0, i6.ModalComponent, [i7.NgbModal], { id: [0, "id"], title: [1, "title"], isOpen: [2, "isOpen"], allowClose: [3, "allowClose"] }, { close: "close" }), (_l()(), i0.ɵeld(30, 0, null, 0, 17, "app-modal-body", [], null, null, null, i8.View_ModalBodyComponent_0, i8.RenderType_ModalBodyComponent)), i0.ɵdid(31, 49152, null, 0, i9.ModalBodyComponent, [], null, null), (_l()(), i0.ɵeld(32, 0, null, 0, 1, "h5", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Your listing has been submitted for review."])), (_l()(), i0.ɵeld(34, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(35, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Your listing will be posted to the USA Cycling Event Calendar as \"Permit In Review\" once your permit application is completed."])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_EventListingReviewComponent_7)), i0.ɵdid(38, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(39, 0, null, 0, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(40, 0, null, null, 3, "div", [["class", "col-sm-6 text-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(41, 0, null, null, 2, "app-button", [["type", "btn-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exitToQueue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(42, 114688, null, 0, i4.ButtonComponent, [], { type: [0, "type"], mode: [1, "mode"], disabled: [2, "disabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["Exit and List Event Only"])), (_l()(), i0.ɵeld(44, 0, null, null, 3, "div", [["class", "col-sm-6 text-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(45, 0, null, null, 2, "app-button", [["type", "btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toPermit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(46, 114688, null, 0, i4.ButtonComponent, [], { type: [0, "type"], disabled: [1, "disabled"] }, null), (_l()(), i0.ɵted(47, 0, ["", ""])), (_l()(), i0.ɵeld(48, 0, null, null, 3, "div", [["class", "row justify-content-center space-above-sm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(49, 0, null, null, 2, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toListing() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(50, 114688, null, 0, i4.ButtonComponent, [], { type: [0, "type"], disabled: [1, "disabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["Go Back to Edit"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn-calendar"; var currVal_1 = _co.disableListingPermitBtns; _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_2 = "submit-listing"; var currVal_3 = "Thanks!"; var currVal_4 = _co.submitModalOpen; var currVal_5 = false; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.licenseNeeded; _ck(_v, 16, 0, currVal_6); var currVal_7 = "btn-secondary"; var currVal_8 = "button"; var currVal_9 = _co.disableModalBtns; _ck(_v, 20, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = "btn-primary"; var currVal_11 = "button"; var currVal_12 = _co.disableModalBtns; _ck(_v, 24, 0, currVal_10, currVal_11, currVal_12); var currVal_14 = !_co.eventListing.isVirtual; _ck(_v, 27, 0, currVal_14); var currVal_15 = "permitApplication"; var currVal_16 = "Thanks!"; var currVal_17 = _co.permitModalOpen; var currVal_18 = false; _ck(_v, 29, 0, currVal_15, currVal_16, currVal_17, currVal_18); var currVal_19 = _co.licenseNeeded; _ck(_v, 38, 0, currVal_19); var currVal_20 = "btn-secondary"; var currVal_21 = "button"; var currVal_22 = _co.disableModalBtns; _ck(_v, 42, 0, currVal_20, currVal_21, currVal_22); var currVal_23 = "btn-primary"; var currVal_24 = _co.disableModalBtns; _ck(_v, 46, 0, currVal_23, currVal_24); var currVal_26 = "btn-light"; var currVal_27 = _co.disableListingPermitBtns; _ck(_v, 50, 0, currVal_26, currVal_27); }, function (_ck, _v) { var _co = _v.component; var currVal_13 = _co.permitModalContinueText(); _ck(_v, 25, 0, currVal_13); var currVal_25 = _co.permitModalContinueText(); _ck(_v, 47, 0, currVal_25); }); }
function View_EventListingReviewComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-listing-body", [], null, null, null, i11.View_ListingBodyComponent_0, i11.RenderType_ListingBodyComponent)), i0.ɵdid(2, 114688, null, 0, i12.ListingBodyComponent, [i13.EventListingService, i14.EventService], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventListingReviewComponent_4)), i0.ɵdid(4, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.displayActions(); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_EventListingReviewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "container first"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventListingReviewComponent_1)), i0.ɵdid(3, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventListingReviewComponent_2)), i0.ɵdid(5, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventListingReviewComponent_3)), i0.ɵdid(7, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.eventListing.mode !== "view"); _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.dataLoaded; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.dataLoaded; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_EventListingReviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-event-listing-review", [], null, null, null, View_EventListingReviewComponent_0, RenderType_EventListingReviewComponent)), i0.ɵdid(1, 245760, null, 0, i15.EventListingReviewComponent, [i13.EventListingService, i14.EventService, i16.ProfileService, i17.Router, i17.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventListingReviewComponentNgFactory = i0.ɵccf("app-event-listing-review", i15.EventListingReviewComponent, View_EventListingReviewComponent_Host_0, {}, {}, []);
export { EventListingReviewComponentNgFactory as EventListingReviewComponentNgFactory };
