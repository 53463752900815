export class SelectOption {
  value: string;
  label: string;
  selected: boolean;

  constructor(value: string, label: string) {
    this.value = value;
    this.label = label;
  }

  select() {
    this.selected = true;
  }

  deSelect() {
    this.selected = undefined;
  }
}
