<table id="{{id}}" role="table" class="table table-striped table-default table-hover table-responsive-xl">
  <thead role="rowgroup">
    <tr role="row">
      <th *ngFor="let column of columns" role="columnheader" [ngClass]="{ 'sorted' : column.sortable !== false }">
        <span *ngIf="column.sortable !== false" (click)="setSort(column.field, !asc, $event)">
          {{column.label}}
          <span *ngIf="sortBy === column.field else disabledSort">
            <i class="icon" [ngClass]="{ 'icon-chevron-up': asc }"></i>
            <i class="icon" [ngClass]="{ 'icon-chevron-down': !asc }"></i>
          </span>
          <ng-template #disabledSort>
            <i class="icon icon-chevron-down disabled"></i>
          </ng-template>
        </span>
        <span *ngIf="column.sortable === false">
          {{column.label}}
        </span>
      </th>
      <th *ngFor="let action of actions" role="columnheader"></th>
    </tr>
  </thead>

  <tbody role="rowgroup">
    <tr *ngFor="let row of splitFirst(); let i = index" [ngClass]="[(row.color ? 'table-' + row.color : ''), (linkEach || linkProp ? 'cursor-pointer' : '')]" [routerLink]="getRouterLinkForRow(row, linkProp)">
      <td *ngFor="let column of columns" role="cell" [attr.data-label]="column.label">
        <span *ngIf="isAlertField(column.field) else nonAlert">
          <i *ngIf="row[column.field]" class="icon icon-bell text-danger" style="font-size: 20px;"></i>
        </span>
        <ng-template #nonAlert>
          <div *ngIf="selectActions && selectActions.includes(column.field) && includesSelectOption(row, column.field)" style="margin-top: -6px;">
            <app-select
              [name]="column.field"
              [id]="column.field + '-' + i"
              [options]="selectOptions[column.field]"
              [value]="actionValue(row, column.field)"
              [minWidth]="165"
              (click)="stopRedirect($event)"
              (valueChange)="handleValueChange(row, column.field, $event, i)"
            ></app-select>
            <p *ngIf="displayActionMessage(row, column.field)" class="action-message small {{actionMessageClass(row)}}">{{actionMessage(row)}}</p>
          </div>
          <span *ngIf="!isDateField(column.field) && (!selectActions || !selectActions.includes(column.field) || !includesSelectOption(row, column.field))"
            [innerHTML]="row[column.field]"
            style="white-space: pre-line;"
            [ngStyle]="{'white-space': disableTextWrap.includes(column.field) && 'pre'}"
          >
          </span>
          <span *ngIf="isDateField(column.field) && (!selectActions || !selectActions.includes(column.field) || !includesSelectOption(row, column.field))">
            <span *ngIf="utcDisplay(column.field)"
              [innerHTML]="row[column.field] | date:'longDate':'UTC'"
              style="white-space: pre-line;"
              [ngStyle]="{'white-space': disableTextWrap.includes(column.field) && 'pre'}"
            >
            </span>
            <span *ngIf="!utcDisplay(column.field)"
              [innerHTML]="row[column.field] | date:'longDate'"
              style="white-space: pre-line;"
              [ngStyle]="{'white-space': disableTextWrap.includes(column.field) && 'pre'}"
            >
            </span>
          </span>
        </ng-template>
      </td>
      <td *ngFor="let action of actions" role="columnheader">
        <app-button *ngIf="checkDisabledActions(action, row)"
          [type]="(action.color ? 'btn-' + action.color : 'btn-default')"
          (click)="handleAction(action, row, $event)"
        >{{action.label}}</app-button>
      </td>
    </tr>
    <tr *ngIf="split">
      <td (click)="toggleSplit($event)" class="split-row text-center" [attr.colspan]="columns.length + actions.length">
        <span>{{splitDiff()}} Hidden Results - View Results</span>
      </td>
    </tr>
    <tr *ngFor="let row of splitLast(); let i = index" [ngClass]="[(row.color ? 'table-' + row.color : ''), (linkEach ? 'cursor-pointer' : '')]" [routerLink]="linkEach && row[linkEachKey] ? [linkEach + row[linkEachKey] + linkEachSuffix] : []">
      <td *ngFor="let column of columns" role="cell" [attr.data-label]="column.label">
        <span *ngIf="isAlertField(column.field) else nonAlert">
          <i *ngIf="row[column.field]" class="icon icon-bell text-danger" style="font-size: 20px;"></i>
        </span>
        <ng-template #nonAlert>
          <div *ngIf="selectActions && selectActions.includes(column.field) && includesSelectOption(row, column.field)" style="margin-top: -6px;">
            <app-select
              [name]="column.field"
              [id]="column.field + '-' + i"
              [options]="selectOptions[column.field]"
              [value]="row[column.field]"
              [minWidth]="165"
              (click)="stopRedirect($event)"
              (valueChange)="handleValueChange(row, column.field, $event, i)"
            ></app-select>
            <p *ngIf="displayActionMessage(row, column.field)" class="action-message text-success small">{{actionMessage(row)}}</p>
          </div>
          <span *ngIf="!isDateField(column.field) && (!selectActions || !selectActions.includes(column.field) || !includesSelectOption(row, column.field))"
            [innerHTML]="row[column.field]"
            style="white-space: pre-line;"
            [ngStyle]="{'white-space': disableTextWrap.includes(column.field) && 'pre'}"
          >
          </span>
          <span *ngIf="isDateField(column.field) && (!selectActions || !selectActions.includes(column.field) || !includesSelectOption(row, column.field))"
            [innerHTML]="row[column.field] | date:'longDate'"
            style="white-space: pre-line;"
            [ngStyle]="{'white-space': disableTextWrap.includes(column.field) && 'pre'}"
          >
          </span>
        </ng-template>
      </td>
      <td *ngFor="let action of actions" role="columnheader">
        <app-button
          [type]="(action.color ? 'btn-' + action.color : 'btn-default')"
          (click)="handleAction(action, row, $event)"
        >{{action.label}}</app-button>
      </td>
    </tr>
  </tbody>
</table>
