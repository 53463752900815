import { Component, OnInit, Input } from '@angular/core';
import { EventDate } from '../../classes/event_date';

@Component({
  selector: 'app-event-dates-review',
  templateUrl: './event-dates-review.component.html',
  styles: []
})

export class EventDatesReviewComponent implements OnInit {

  @Input() index: number;
  @Input() eventDate: EventDate;
  @Input() originalEventDate: any;
  @Input() mode: string;

  editMode = false;
  classesList: string;

  constructor() { }

  ngOnInit() {
    if (this.originalEventDate === undefined) {
      if (this.mode === 'update') {
        this.classesList = 'border-success';
      }
    } else {
      this.editMode = true;
      this.classesList = '';
    }
    console.log(this.eventDate);
  }

  hasUpdateStartDate(): boolean {
    return (
      this.mode === 'update' &&
      this.eventDate.event_date_start !== this.originalEventDate.event_date_start
    );
  }

  hasUpdateAddress(): boolean {
    return (
      this.mode === 'update' &&
      (
        this.eventDate.event_date_street !== this.originalEventDate.event_date_street
        || this.eventDate.event_date_unit !== this.originalEventDate.event_date_unit
        || this.eventDate.event_date_city !== this.originalEventDate.event_date_city
        || this.eventDate.event_date_state !== this.originalEventDate.event_date_state
        || this.eventDate.event_date_postal_code !== this.originalEventDate.event_date_postal_code
      )
    );
  }

  getSubdisciplineState(discId: string): string {
    if (this.mode === 'update') {
      let state: string;
      state = 'text-success';
      this.originalEventDate.event_date_sub_disciplines.forEach(disc => {
        if (disc === discId) {
          state = '';
        }
      });
      return state;
    }
    return '';
  }
}
