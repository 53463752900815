/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../molecules/card/card.component.ngfactory";
import * as i2 from "../../molecules/card/card.component";
import * as i3 from "../../atoms/button/button.component.ngfactory";
import * as i4 from "../../atoms/button/button.component";
import * as i5 from "../../molecules/modal/modal.component.ngfactory";
import * as i6 from "../../molecules/modal/modal.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../atoms/modal-body/modal-body.component.ngfactory";
import * as i9 from "../../atoms/modal-body/modal-body.component";
import * as i10 from "@angular/common";
import * as i11 from "../../atoms/loader/loader.component.ngfactory";
import * as i12 from "../../atoms/loader/loader.component";
import * as i13 from "../../atoms/checkbox/checkbox.component.ngfactory";
import * as i14 from "@angular/forms";
import * as i15 from "../../atoms/checkbox/checkbox.component";
import * as i16 from "./event-incentives-review.component";
import * as i17 from "../../services/events.service";
import * as i18 from "@angular/router";
var styles_EventIncentivesReviewComponent = [];
var RenderType_EventIncentivesReviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EventIncentivesReviewComponent, data: {} });
export { RenderType_EventIncentivesReviewComponent as RenderType_EventIncentivesReviewComponent };
function View_EventIncentivesReviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "app-card", [], null, null, null, i1.View_CardComponent_0, i1.RenderType_CardComponent)), i0.ɵdid(1, 114688, null, 0, i2.CardComponent, [], { classes: [0, "classes"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 6, "div", [["class", "card-body d-flex justify-content-between text-info py-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "my-auto mr-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "my-auto"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(7, 114688, null, 0, i4.ButtonComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵted(-1, 0, ["View Details"]))], function (_ck, _v) { var currVal_0 = "border-info mb-3"; _ck(_v, 1, 0, currVal_0); var currVal_2 = "btn-info"; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.labelMessage; _ck(_v, 4, 0, currVal_1); }); }
function View_EventIncentivesReviewComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_EventIncentivesReviewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-modal", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ModalComponent_0, i5.RenderType_ModalComponent)), i0.ɵdid(1, 638976, null, 0, i6.ModalComponent, [i7.NgbModal], { id: [0, "id"], title: [1, "title"], isOpen: [2, "isOpen"] }, { close: "close" }), (_l()(), i0.ɵeld(2, 0, null, 0, 3, "app-modal-body", [["class", "event-incentives-review-modal"]], null, null, null, i8.View_ModalBodyComponent_0, i8.RenderType_ModalBodyComponent)), i0.ɵdid(3, 49152, null, 0, i9.ModalBodyComponent, [], { class: [0, "class"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_EventIncentivesReviewComponent_3)), i0.ɵdid(5, 540672, null, 0, i10.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "label-review-modal"; var currVal_1 = "Event Label Review"; var currVal_2 = _co.modalOpen; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "event-incentives-review-modal"; _ck(_v, 3, 0, currVal_3); var currVal_4 = i0.ɵnov(_v.parent, 6); _ck(_v, 5, 0, currVal_4); }, null); }
function View_EventIncentivesReviewComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "ml-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-loader", [], null, null, null, i11.View_LoaderComponent_0, i11.RenderType_LoaderComponent)), i0.ɵdid(2, 114688, null, 0, i12.LoaderComponent, [], { small: [0, "small"], align: [1, "align"] }, null)], function (_ck, _v) { var currVal_0 = true; var currVal_1 = "left"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_EventIncentivesReviewComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_EventIncentivesReviewComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventIncentivesReviewComponent_7)), i0.ɵdid(2, 540672, null, 0, i10.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent.parent, 6); _ck(_v, 2, 0, currVal_0); }, null); }
function View_EventIncentivesReviewComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "d-flex justify-content-between mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Event Calendar Labels"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toIncentives() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(5, 114688, null, 0, i4.ButtonComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵted(-1, 0, ["Edit Labels"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventIncentivesReviewComponent_5)), i0.ɵdid(8, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventIncentivesReviewComponent_6)), i0.ɵdid(10, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn-secondary"; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 8, 0, currVal_1); var currVal_2 = (!_co.loading && _co.hasCalendarLabelAnswers); _ck(_v, 10, 0, currVal_2); }, null); }
function View_EventIncentivesReviewComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["class", "calendar-label-badge mr-3 mb-3"]], [[8, "src", 4], [8, "alt", 0], [8, "title", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.badge_image; var currVal_1 = _v.context.$implicit.name; var currVal_2 = _v.context.$implicit.name; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_EventIncentivesReviewComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Applicable Event Calendar Labels:"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventIncentivesReviewComponent_10)), i0.ɵdid(4, 278528, null, 0, i10.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calendarLabels; _ck(_v, 4, 0, currVal_0); }, null); }
function View_EventIncentivesReviewComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-checkbox", [], null, null, null, i13.View_CheckboxComponent_0, i13.RenderType_CheckboxComponent)), i0.ɵprd(5120, null, i14.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i15.CheckboxComponent]), i0.ɵdid(2, 114688, null, 0, i15.CheckboxComponent, [], { id: [0, "id"], name: [1, "name"], label: [2, "label"], disabled: [3, "disabled"], tooltip: [4, "tooltip"], checked: [5, "checked"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.id; var currVal_1 = _v.parent.context.$implicit.id; var currVal_2 = _v.parent.context.$implicit.question; var currVal_3 = true; var currVal_4 = _v.parent.context.$implicit.tooltip; var currVal_5 = _co.answerByQuestion(_v.parent.context.$implicit).answer; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_EventIncentivesReviewComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "my-2"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventIncentivesReviewComponent_12)), i0.ɵdid(2, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "answer-details small text-info"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.answerByQuestion(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.answerDetails(_v.context.$implicit); _ck(_v, 4, 0, currVal_1); }); }
function View_EventIncentivesReviewComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "event-incentives-review"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventIncentivesReviewComponent_9)), i0.ɵdid(2, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "card card-body mt-0 mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventIncentivesReviewComponent_11)), i0.ɵdid(5, 278528, null, 0, i10.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calendarLabels.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.calendarLabelQuestions; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_EventIncentivesReviewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventIncentivesReviewComponent_1)), i0.ɵdid(1, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventIncentivesReviewComponent_2)), i0.ɵdid(3, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventIncentivesReviewComponent_4)), i0.ɵdid(5, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, [["reviewContent", 2]], null, 0, null, View_EventIncentivesReviewComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isPostEvent && _co.hasCalendarLabels); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isPostEvent; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.isPostEvent; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_EventIncentivesReviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-event-incentives-review", [], null, null, null, View_EventIncentivesReviewComponent_0, RenderType_EventIncentivesReviewComponent)), i0.ɵdid(1, 114688, null, 0, i16.EventIncentivesReviewComponent, [i17.EventService, i14.FormBuilder, i18.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventIncentivesReviewComponentNgFactory = i0.ɵccf("app-event-incentives-review", i16.EventIncentivesReviewComponent, View_EventIncentivesReviewComponent_Host_0, { calendarLabels: "calendarLabels", calendarLabelQuestions: "calendarLabelQuestions", calendarLabelAnswers: "calendarLabelAnswers", event: "event", isPostEvent: "isPostEvent", eventIncentivesDiscountAmt: "eventIncentivesDiscountAmt" }, { eventIncentiveDiscountQualification: "eventIncentiveDiscountQualification" }, []);
export { EventIncentivesReviewComponentNgFactory as EventIncentivesReviewComponentNgFactory };
