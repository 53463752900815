import { OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ShopifyService, GlobalService } from '../../shared';
import { EventDayService } from 'src/app/services';
import { SelectOption, RadioOption, Alert, EventDay, ShopifyDraftOrder, ShopifyProduct, ShopifyProductImage } from 'src/app/classes';
import { environment } from 'src/environments/environment';
// var observableFromPromise =  from(promiseSrc);
var ProductComponent = /** @class */ (function () {
    function ProductComponent(shopifyService, globalService, formBuilder, calendar, dateParser, eventDayService) {
        this.shopifyService = shopifyService;
        this.globalService = globalService;
        this.formBuilder = formBuilder;
        this.calendar = calendar;
        this.dateParser = dateParser;
        this.eventDayService = eventDayService;
        this.init = new EventEmitter();
        this.load = new EventEmitter();
        this.draftOrders = [];
        this.orders = [];
        this.modal1Open = false;
        this.modal2Open = false;
        this.submitted = false;
        this.dataForm = null;
        this.dateFormCreated = false;
        this.addToCartDisabled = true;
        this.selectDatesDisabled = true;
        this.radioOptions = [];
        this.setupOptions = [];
        this.parsedEventDates = [];
        this.selectedDates = [];
        this.cartAlerts = [];
        this.variantList = [];
        this.loaded = false;
        this.form = new FormGroup({
            variant: new FormControl([]),
            quantity: new FormControl(0),
            radio: new FormControl(null),
            setupTeardownDays: new FormGroup({}),
            practiceDays: new FormGroup({})
        });
    }
    Object.defineProperty(ProductComponent.prototype, "f", {
        // convenience getters for easy access to form fields
        get: function () { return this.form.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductComponent.prototype, "stdf", {
        get: function () { return this.form.controls.setupTeardownDays; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductComponent.prototype, "pdf", {
        get: function () { return this.form.controls.practiceDays; },
        enumerable: true,
        configurable: true
    });
    ProductComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.init.emit(1);
        this.subscribeToDraftsAndOrders();
        this.skus = environment.disciplineSkus[this.discipline];
        this.autoSku = this.skus.autoInsurance;
        this.motoSku = this.skus.motoInsurance;
        this.ancillarySku = this.skus.ancillaryInsurance;
        this.setupTeardownSku = this.skus.setupTeardownDays;
        this.practiceSku = this.skus.practiceDays;
        this.kidsPermitSku = this.skus.kidsPermit;
        // TODO: @Brennan - How is "this.variants" ever undefined here?
        //        - Related: https://usacycling.atlassian.net/browse/AIR-837
        this.variants.forEach(function (variant) {
            if (Object.values(_this.skus).some(function (sku) { return sku === variant.sku; })) {
                _this.variantList.push(variant);
                if (!_this.defaultVariant) {
                    _this.defaultVariant = variant;
                    _this.defaultSku = variant.sku;
                }
                else {
                    return;
                }
            }
        });
        this.f.variant.setValue(this.defaultVariant);
        if (this.defaultSku === this.kidsPermitSku) {
            this.form.get('radio').setValue('no');
        }
        this.loaded = true;
        this.load.emit(1);
        if (this.disableQuantity) {
            this.addToCartDisabled = false;
            this.form.controls.quantity.setValue(1);
        }
        this.radioOptions = [
            new RadioOption('null', null, 'Null'),
            new RadioOption('yes', 'yes', 'Yes'),
            new RadioOption('no', 'no', 'No')
        ];
        this.setupOptions = [
            new SelectOption('setup', 'Setup'),
            new SelectOption('teardown', 'Teardown')
        ];
        this.minDate = this.calendar.getToday();
        this.parsedEventDates = this.eventDates.map(function (date) { return _this.dateParser.parse(moment(date.event_date_start).format('M/D/Y')); });
        this.markDisabled = function (date) {
            var disabled = false;
            _this.parsedEventDates.forEach(function (d) {
                if (date.day === d.day && date.month === d.month && date.year === d.year) {
                    disabled = true;
                }
            });
            return disabled;
        };
    };
    ProductComponent.prototype.subscribeToDraftsAndOrders = function () {
        var _this = this;
        this.shopifyService.eventDraftOrders.subscribe(function (draftOrders) {
            _this.draftOrders = draftOrders.filter(function (draftOrder) { return draftOrder.status === "open" /* open */; });
        });
        this.shopifyService.eventOrders.subscribe(function (orders) { return _this.orders = orders; });
    };
    ProductComponent.prototype.addToCart = function () {
        var lineItem = { variant_id: this.form.value.variant.id, quantity: this.form.value.quantity };
        this.shopifyService.addToDraftOrder(lineItem, this.draftOrder);
        this.shopifyService.updateDraftOrderItems(this.draftOrder);
    };
    ProductComponent.prototype.removeFromCart = function () {
        var lineItem = { variant_id: this.form.value.variant.id, quantity: 1 };
        this.shopifyService.removeFromDraftOrder(lineItem, this.draftOrder);
        this.shopifyService.updateDraftOrderItems(this.draftOrder);
    };
    ProductComponent.prototype.resetCartAlerts = function () {
        this.cartAlerts = [];
    };
    ProductComponent.prototype.openModal = function (num) {
        switch (num) {
            case 1:
                this.modal1Open = true;
                break;
            case 2: this.modal2Open = true;
        }
    };
    ProductComponent.prototype.closeModal = function (num) {
        switch (num) {
            case 1:
                this.modal1Open = false;
                break;
            case 2: this.modal2Open = false;
        }
    };
    ProductComponent.prototype.onRadioChange = function () {
        if (this.defaultSku === this.ancillarySku) {
            if (this.form.value.radio === 'no') {
                this.onSubmit();
                this.openModal(1);
            }
            else if (this.form.value.radio === 'yes') {
                this.removeFromCart();
                this.openModal(2);
            }
            // Default radio behavior - 'yes' adds to cart
        }
        else {
            if (this.form.value.radio === 'yes') {
                this.onSubmit();
            }
            else if (this.form.value.radio === 'no') {
                this.removeFromCart();
            }
        }
    };
    ProductComponent.prototype.maxQuantity = function () {
        return (this.defaultSku === this.setupTeardownSku || this.defaultSku === this.practiceSku) ? 50 : 500;
    };
    ProductComponent.prototype.createForm = function (quantity, previousValues) {
        if (previousValues === void 0) { previousValues = {}; }
        var formDefaults = {};
        this.dateFormCreated = false;
        this.selectedDates = [];
        if (this.defaultSku === this.setupTeardownSku) {
            for (var i = 1; i <= quantity; i++) {
                // TODO: Add custom validators to ensure setup dates before event & teardown dates after
                formDefaults["date-" + i] = [previousValues["date-" + i] || null, Validators.required];
                formDefaults["date-" + i + "-type"] = [previousValues["date-" + i + "-type"] || 'setup', Validators.required];
                this.selectedDates.push("date-" + i);
            }
            this.form.controls.setupTeardownDays = this.formBuilder.group(formDefaults);
            this.dateFormCreated = true;
            this.dataForm = this.stdf;
            this.checkFormValidity(this.stdf);
        }
        else if (this.defaultSku === this.practiceSku) {
            for (var i = 1; i <= quantity; i++) {
                // TODO: Add custom validator to ensure practice dates before event
                formDefaults["date-" + i] = [previousValues["date-" + i] || null, Validators.required];
                this.selectedDates.push("date-" + i);
            }
            this.form.controls.practiceDays = this.formBuilder.group(formDefaults);
            this.dateFormCreated = true;
            this.dataForm = this.pdf;
            this.checkFormValidity(this.pdf);
        }
    };
    ProductComponent.prototype.onSelectDates = function () {
        var _this = this;
        this.submitted = false;
        var currentFormValue = {};
        if (this.defaultSku === this.setupTeardownSku) {
            currentFormValue = this.stdf.value;
        }
        else if (this.defaultSku === this.practiceSku) {
            currentFormValue = this.pdf.value;
        }
        // TODO: Find better way to handle changes to number of dates selected
        this.createForm(this.product, 0);
        setTimeout(function () { _this.createForm(_this.form.value.quantity, currentFormValue); }, 1);
    };
    ProductComponent.prototype.onQuantityChange = function () {
        this.selectDatesDisabled = this.form.value.quantity === 0 ? true : false;
        if (!this.setupPracticeSku) {
            this.addToCartDisabled = this.form.value.quantity === 0 ? true : false;
        }
    };
    ProductComponent.prototype.checkFormValidity = function (form) {
        var _this = this;
        setTimeout(function () { _this.addToCartDisabled = !form.valid; }, 5);
    };
    ProductComponent.prototype.postData = function (form) {
        if (this.defaultSku === this.practiceSku || this.defaultSku === this.setupTeardownSku) {
            var f = form.controls;
            var days = [];
            if (this.defaultSku === this.setupTeardownSku) {
                for (var i = 1; i <= (Object.keys(f).length / 2); i++) {
                    days.push(new EventDay({ date: f["date-" + i].value, type: f["date-" + i + "-type"].value }));
                }
                this.eventDayService.updateSelectedDays('setupTeardown', days);
            }
            else {
                for (var _i = 0, _a = Object.keys(f); _i < _a.length; _i++) {
                    var key = _a[_i];
                    days.push(new EventDay({ date: f[key].value, type: 'practice' }));
                }
                this.eventDayService.updateSelectedDays('practice', days);
            }
        }
    };
    ProductComponent.prototype.onSubmit = function () {
        this.submitted = true;
        console.log('Submitted', this.form);
        this.postData(this.dataForm);
        // const lineItem = new LineItem(this.form.value.variant, this.form.value.quantity, this.product);
        this.addToCart();
        if (this.hasDocs) {
            this.openModal(1);
        }
        this.addToCartDisabled = !this.disableQuantity;
        this.resetCartAlerts();
        this.cartAlerts.push(new Alert('success', this.form.value.quantity + " item(s) added to cart.", null, 'shopping-cart', null, null, 5000));
        this.globalService.cartOpen();
    };
    Object.defineProperty(ProductComponent.prototype, "setupPracticeSku", {
        get: function () {
            return this.defaultSku && this.defaultSku.includes('DAY');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductComponent.prototype, "disableQuantity", {
        get: function () {
            return this.product.tags.includes('disable-quantity');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductComponent.prototype, "hasDocs", {
        get: function () {
            return this.product.tags.includes('has-docs');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductComponent.prototype, "hasRadioOptions", {
        get: function () {
            return this.product.tags.includes('radio-options');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductComponent.prototype, "requiresPrepurchaseInfo", {
        get: function () {
            return this.product.tags.includes('requires-pre-purchase-info');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductComponent.prototype, "disableRepurchase", {
        get: function () {
            var _this = this;
            return this.product.tags.includes('one-time-purchase') && (this.inLineItems(this.draftOrder.line_items) ||
                this.orders.some(function (order) { return _this.inLineItems(order.line_items); }) ||
                this.draftOrders.some(function (draft) { return _this.inLineItems(draft.line_items); }));
        },
        enumerable: true,
        configurable: true
    });
    ProductComponent.prototype.inLineItems = function (lineItems) {
        var _this = this;
        return lineItems.some(function (item) { return item.title === _this.product.title; });
    };
    return ProductComponent;
}());
export { ProductComponent };
