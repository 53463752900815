import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../services';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: []
})
export class UnauthorizedComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.logout();
  }

  logout() {
    this.authService.doLogout().subscribe((resp) => {
      if (resp) {
        window.location.assign(environment.adminUrl + `/login?redirectUrl=${environment.publicUrl}`);
      }
    });
  }
}
