/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../atoms/loader/loader.component.ngfactory";
import * as i2 from "../../atoms/loader/loader.component";
import * as i3 from "../../atoms/button/button.component.ngfactory";
import * as i4 from "../../atoms/button/button.component";
import * as i5 from "@angular/common";
import * as i6 from "./event-docs.component";
import * as i7 from "../../shared/services/file-upload.service";
var styles_EventDocsComponent = [];
var RenderType_EventDocsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EventDocsComponent, data: {} });
export { RenderType_EventDocsComponent as RenderType_EventDocsComponent };
function View_EventDocsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], { small: [0, "small"], align: [1, "align"] }, null)], function (_ck, _v) { var currVal_0 = true; var currVal_1 = "left"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_EventDocsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["There are no documents for this event."]))], null, null); }
function View_EventDocsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "p", [["class", "col-sm-4 col-md-5 col-lg-4 notification-type"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "icon-file"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "p", [["class", "col-sm-4 col-md-4 col-lg-4 notification-status"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵppd(7, 1), (_l()(), i0.ɵeld(8, 0, null, null, 3, "app-button", [["class", "col-sm-4 col-md-3 col-lg-4"], ["style", "flex:2;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDoc(_v.context.$implicit.uri) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(9, 114688, null, 0, i4.ButtonComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵeld(10, 0, null, 0, 0, "i", [["class", "icon-download"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, ["Download"]))], function (_ck, _v) { var currVal_2 = "btn-light btn-sm"; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.file_name; _ck(_v, 4, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v.parent.parent, 0), _v.context.$implicit.createdon)); _ck(_v, 6, 0, currVal_1); }); }
function View_EventDocsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "mt-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "row add-on-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [["class", "col-md-5 col-lg-4"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Document"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [["class", "col-md-4 col-lg-4"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Date Created"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "p", [["class", "col-md-3 col-lg-4"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Link"])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "ul", [["class", "add-on-list notification-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventDocsComponent_4)), i0.ɵdid(11, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.eventDocs; _ck(_v, 11, 0, currVal_0); }, null); }
export function View_EventDocsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i5.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Documents"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventDocsComponent_1)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventDocsComponent_2)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventDocsComponent_3)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 4, 0, currVal_0); var currVal_1 = (!_co.loading && !_co.eventDocs.length); _ck(_v, 6, 0, currVal_1); var currVal_2 = (!_co.loading && _co.eventDocs.length); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_EventDocsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-event-docs", [], null, null, null, View_EventDocsComponent_0, RenderType_EventDocsComponent)), i0.ɵdid(1, 114688, null, 0, i6.EventDocsComponent, [i7.FileUploadService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventDocsComponentNgFactory = i0.ɵccf("app-event-docs", i6.EventDocsComponent, View_EventDocsComponent_Host_0, { event: "event" }, {}, []);
export { EventDocsComponentNgFactory as EventDocsComponentNgFactory };
