import { Component, OnInit, OnChanges, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styles: []
})
export class ModalComponent implements OnInit, OnChanges {

  formattedId = '#';

  @Input() id: string;
  @Input() title: string;
  @Input() isOpen: boolean;
  @Input() allowClose = true;

  modalOptions: NgbModalOptions = {};

  @ViewChild('content', { static: false }) content: ElementRef;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  constructor(private modalService: NgbModal) { }

  handleClose(event) {
    this.close.emit(event);
  }

  syncModal() {
    if (this.isOpen) {
      if (this.allowClose) {
        this.modalService.open(this.content, {
          beforeDismiss: this.handleClose.bind(this)
        });
      } else {
        this.modalService.open(this.content, {
          beforeDismiss: this.handleClose.bind(this), backdrop: 'static', keyboard: false
        });
      }
    } else {
      this.modalService.dismissAll();
    }
  }

  ngOnInit() {
    if (this.id) {
      this.formattedId = '#' + this.id;
    }

    this.syncModal();
  }

  ngOnChanges() {
    this.syncModal();
  }
}
