import { Component, OnInit, Input } from '@angular/core';
import { EventDate } from 'src/app/classes';

@Component({
  selector: 'app-ref-accordion',
  templateUrl: './ref-accordion.component.html',
  styles: []
})
export class RefAccordionComponent implements OnInit {

  @Input() eventDate: EventDate;
  @Input() isSeries: boolean;

  open = false;
  toggleIcon = 'icon-chevron-down';

  constructor() { }

  ngOnInit() { }

  onPanelChange(event) {
    this.open = event.nextState;
    this.toggleIcon = event.nextState ? 'icon-chevron-up' : 'icon-chevron-down';
  }

  assigned() {
    return !!this.eventDate.event_date_chief_ref;
  }

}
