import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Club, LegacyClub, SelectOption } from '../classes';
import { catchError, tap } from 'rxjs/operators';

import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class ClubService extends BaseService {
  public legacy = true;

  public clubs: Club[] = [];
  public clubList: SelectOption[];
  public clubsLoaded = false;
  public club: Club;

  searchClubs(criteria: any): Observable<Club[]> {
    return this.http.get<Club[]>(this.url('basic_clubs_search_with_filters', criteria, this.nextGenApiUrl), {})
      .pipe(
        tap(_ => this.log('searched clubs with criteria')),
        catchError(this.handleError<Club[]>('searchClubs', [], {}))
      );
  }

  getClub(clubId: number): Observable<Club> {
    return this.http.get<Club>(this.url('clubs/' + clubId, null, this.nextGenApiUrl))
      .pipe(
        tap(_ => this.log('fetched club')),
        catchError(this.handleError<Club>('getClub', null, { clubId }))
      );
  }

  // Legacy endpoints
  getLegacyClubs(): Observable<LegacyClub[]> {
    return this.http.get<LegacyClub[]>(this.url('clubs?limit=3000'))
      .pipe(
        tap(_ => this.log('fetched clubs')),
        catchError(this.handleError<LegacyClub[]>('getClubs', []))
      );
  }

  // TODO: Replace once club emails represented in Nextgen
  getLegacyClub(name: string): Observable<LegacyClub[]> {
    return this.http.get<LegacyClub[]>(this.url('clubs?club=' + name))
      .pipe(
        tap(_ => this.log('fetched clubs by name')),
        catchError(this.handleError<LegacyClub[]>('getClub', [], { name }))
      );
  }

  setClubListOptions() {
    this.searchClubs({ is_active: true }).subscribe((clubs: Club[]) => {
      if (clubs.length > 0) {
        this.clubList = [];
        const clubsList = clubs.sort((a, b) => a.club_name.trim().localeCompare(b.club_name.trim()));
        clubsList.forEach(club => {
          this.clubList.push(new SelectOption(club.club_id.toString(), club.club_name));
          this.clubs.push(club);
        });
        this.clubsLoaded = true;
      }
    });
  }
}
