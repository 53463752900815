/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../molecules/footer-link-group/footer-link-group.component.ngfactory";
import * as i2 from "../../molecules/footer-link-group/footer-link-group.component";
import * as i3 from "../../atoms/logo/logo.component.ngfactory";
import * as i4 from "../../atoms/logo/logo.component";
import * as i5 from "./footer.component";
var styles_FooterComponent = [];
var RenderType_FooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "footer d-flex align-items-center text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-footer-link-group", [["class", "nav-item nav-link justify-self-end"]], null, null, null, i1.View_FooterLinkGroupComponent_0, i1.RenderType_FooterLinkGroupComponent)), i0.ɵdid(3, 114688, null, 0, i2.FooterLinkGroupComponent, [], { links: [0, "links"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-logo", [["class", "navbar-brand"]], null, null, null, i3.View_LogoComponent_0, i3.RenderType_LogoComponent)), i0.ɵdid(5, 114688, null, 0, i4.LogoComponent, [], { width: [0, "width"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.links; _ck(_v, 3, 0, currVal_0); var currVal_1 = 60; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_FooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i0.ɵdid(1, 114688, null, 0, i5.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i0.ɵccf("app-footer", i5.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
