import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { EventService, PostEventService } from 'src/app/services';

import {
  Event,
  EventDate,
  PostEvent,
  Profile,
  ShopifyDraftOrder,
  ShopifyOrder
} from 'src/app/classes';

@Component({
  selector: 'app-overview-post-event',
  templateUrl: './overview-post-event.component.html',
  styles: []
})
export class OverviewPostEventComponent implements OnChanges {

  @Input() event: Event;
  @Input() eventDates: EventDate[];
  @Input() postEvents: PostEvent[];
  @Input() draftOrders: ShopifyDraftOrder[];
  @Input() orders: ShopifyOrder[];
  @Input() userRole: string;
  @Input() currentUser: Profile;

  @Output() refreshPostEvents: EventEmitter<boolean> = new EventEmitter<boolean>();

  loading = true;
  panelsGenerated = false;
  deleteModalOpen = false;
  deleting = false;
  postEventToDelete: PostEvent;

  postEventPanels: any[] = [];

  statuses = ['in-post-event', 'closed'];

  get eventId() { return this.event.event_id; }

  constructor(
    private router: Router,
    private eventService: EventService,
    private postEventService: PostEventService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.event && this.eventDates && this.postEvents && this.draftOrders && this.orders && !this.panelsGenerated) {
      if (this.inPostEvent) {
        this.loading = true;
        this.generatePostEventPanels();
      } else {
        this.loading = false;
      }
    }
  }

  generatePostEventPanels(): void {
    this.postEventPanels = [];

    this.postEvents.forEach(postEvent => {
      const eventDate = this.eventDates.find(date => date.event_date_id === postEvent.post_event_event_date_id);
      const eventDateId = eventDate ? eventDate.event_date_id : null;
      const text = eventDate ? eventDate.event_date_start : this.event.event_start_date;
      const url = eventDate
        ? `/event/${this.eventId}/date/${eventDate.event_date_id}/post-event/part-1`
        : `/event/${this.eventId}/post-event/part-1`;

      const panel = {
        eventDateId,
        text,
        url,
        eventDate,
        postEvent,
        orders: [],
        draftOrder: null,
        totalDue: null
      };

      this.assignPostEventOrdersAndDraftOrder(postEvent.post_event_id, panel);
      this.postEventPanels.push(panel);
    });

    this.panelsGenerated = true;
  }

  assignPostEventOrdersAndDraftOrder(postEventId: number, panel: any) {
    const postEventDraftOrder = this.draftOrders.find(draftOrder => draftOrder.tags.includes(`post_event_id-${postEventId}`));
    panel.draftOrder = postEventDraftOrder || null;
    panel.orders = this.orders.filter(order => order.tags.includes(`post_event_id-${postEventId}`));

    this.setTotalDue(panel);
  }

  groupTotals(group: object): number {
    let total = 0;
    Object.keys(group).forEach(key => total += group[key]);
    return total;
  }

  setTotalDue(panel: any) {
    // Draft order minus paid orders
    if (panel.draftOrder) {
      let paid = 0;
      panel.orders.forEach(order => {
        paid += parseFloat(order.total_price);
      });
      panel.totalDue = parseFloat(panel.draftOrder.total_price) - paid;
    } else {
      panel.totalDue = 0;
    }
    this.loading = false;
  }

  get isSeries(): boolean { return this.eventService.isSeries(this.event); }

  get isUsacAdmin(): boolean { return this.userRole === 'usac_admin'; }

  get isOfficial(): boolean {
    // return this.userRole === 'official';
    // TODO: Confirm if 'is_official' permission is actually assigned to every official account
    return (
      this.eventDates && this.currentUser &&
      this.eventDates.some(date => date.event_date_chief_ref && date.event_date_chief_ref.profile_id === this.currentUser.profile_id)
    );
  }

  get isEventOrganizer(): boolean {
    return this.currentUser && this.currentUser.profile_id === this.event.event_organizer;
  }

  get isOfficialNotOrganizerOrAdmin(): boolean {
    return this.isOfficial && (!this.isEventOrganizer || !this.isUsacAdmin);
  }

  get inPostEvent(): boolean {
    return (this.statuses.includes(this.event.event_status)) && !!this.postEvents.length;
  }

  get firstEventDate(): EventDate {
    return this.eventDates && this.eventDates.length && this.eventDates[0];
  }

  get postEventStart() {
    return this.isSeries ? (this.firstEventDate && this.firstEventDate.event_date_start) : this.event.event_start_date;
  }

  get isCompetitive(): boolean { return this.event.event_competitive === 'Y'; }
  get isNonCompetitive(): boolean { return [22,24,25,26,27,29,30].includes(this.event.event_discipline) }

  postEventComplete(postEvent: PostEvent): boolean {
    return postEvent.permit_post_event_status === 'payment-complete';
  }

  hasPostEvent(panel: any) {
    return !!panel.postEvent;
  }

  displayDue(postEvent: PostEvent): boolean {
    const statuses = ['payment-due', 'payment-late'];
    return postEvent && statuses.includes(postEvent.permit_post_event_status);
  }

  formDue(postEvent: PostEvent) {
    return postEvent && postEvent.permit_post_event_status === 'form-due';
  }

  disablePay(postEvent: PostEvent): boolean {
    // Only race director & admin can pay post event
    return !this.displayDue(postEvent) || !(this.isEventOrganizer || this.isUsacAdmin);
  }

  disableEdit(postEvent: PostEvent): boolean {
    if (this.isCompetitive) {
      // If competitive, only officials & admin can edit post event form
      return postEvent && (
        (this.isEventOrganizer && !this.isUsacAdmin) || (this.postEventComplete(postEvent) && !this.isUsacAdmin)
      );
    } else {
      // If non-competitive, only race director & admin can edit post event form
      return postEvent && (
        (!this.isEventOrganizer && !this.isUsacAdmin) || (this.postEventComplete(postEvent) && !this.isUsacAdmin)
      );
    }
  }

  allowDelete(postEvent: PostEvent): boolean {
    return this.isUsacAdmin && !this.postEventComplete(postEvent);
  }

  allowEdit(): boolean {
    return this.isUsacAdmin || this.isOfficial || ( this.isNonCompetitive && this.isEventOrganizer);
  }

  status(postEvent: PostEvent) {
    return postEvent ? postEvent.permit_post_event_status.split('-').join(' ') : '';
  }

  badgeClass(postEvent: PostEvent) {
    const base = 'badge badge-pill';
    let style = 'badge-warning';
    if (postEvent) {
      switch (postEvent.permit_post_event_status) {
        case 'form-due': style = 'badge-warning'; break;
        case 'payment-due': style = 'badge-warning'; break;
        case 'payment-late': style = 'badge-danger'; break;
        case 'payment-complete': style = 'badge-success'; break;
      }
    }
    return base + ' ' + style;
  }

  toggleIcon(i: number) {
    const id = `post-event-${i + 1}-toggle`;
    const expanded = document.getElementById(id).getAttribute('aria-expanded')  === 'true';
    return expanded ? 'icon icon-chevron-up' : 'icon icon-chevron-down';
  }

  openDeleteModal(postEvent: PostEvent): void {
    this.postEventToDelete = postEvent;
    this.deleteModalOpen = true;
  }

  closeDeleteModal(): void {
    this.postEventToDelete = null;
    this.deleteModalOpen = false;
  }

  deletePostEvent(): void {
    this.deleting = true;
    this.postEventService.deletePostEvent(this.postEventToDelete.post_event_id).subscribe(() => {
      this.panelsGenerated = false;
      this.refreshPostEvents.emit(true);
      this.closeDeleteModal();
      this.deleting = false;
    });
  }

  toPostEventForm(url?: string) {
    if (url) {
      this.router.navigate([url]);
    } else {
      const routeIds = this.isSeries ? `${this.eventId}/date/${this.eventDates[0].event_date_id}` : `${this.eventId}`;
      this.router.navigate([`/event/${routeIds}/post-event/part-1`]);
    }
  }

  toCheckout(draftOrder: ShopifyDraftOrder) {
    if (draftOrder) {
      window.open(draftOrder.invoice_url);
    }
  }

}
