import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileUploadService } from 'src/app/shared';
import { PostEventService } from 'src/app/services';
import { RadioOption, Alert, Event, EventDate, PostEvent } from '../../classes';
var PostEvent1FormComponent = /** @class */ (function () {
    function PostEvent1FormComponent(formBuilder, upload, postEventService, router) {
        this.formBuilder = formBuilder;
        this.upload = upload;
        this.postEventService = postEventService;
        this.router = router;
        this.submitted = false;
        this.sufficientStaffOptions = [];
        this.sufficientFacilitiesOptions = [];
        this.sufficientTimeOptions = [];
        this.licenseCheckOptions = [];
        this.startListOptions = [];
        this.eventFlyerOptions = [];
        this.courseChangesOptions = [];
        this.podiumOptions = [];
        this.brpOptions = [];
        this.neutralSupportOptions = [];
        this.licensedMechanicsOptions = [];
        this.timingCompanyOptions = [];
        this.medicalSupportOptions = [];
        this.equalPayOptions = [];
        this.prizeDistributionOptions = [];
        this.postingAreaOptions = [];
        this.protestPeriodOptions = [];
        this.disciplineRiderOptions = [];
        this.occurrenceReports = [];
        this.occurrenceReportAlert = [];
        this.displayOccurrenceReportUpload = false;
        this.disableOccurrencePaperwork = false;
        this.disciplineReports = [];
        this.disciplineReportAlert = [];
        this.displayDisciplineReportUpload = false;
        this.disableDisciplinePaperwork = false;
        this.loading = true;
    }
    Object.defineProperty(PostEvent1FormComponent.prototype, "eventId", {
        get: function () { return (this.event.event_id); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent1FormComponent.prototype, "eventDateId", {
        get: function () { return (this.eventDate && this.eventDate.event_date_id) || null; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent1FormComponent.prototype, "postEventId", {
        get: function () { return (this.postEvent && this.postEvent.post_event_id); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent1FormComponent.prototype, "formMode", {
        get: function () { return this.postEvent ? 'update' : 'create'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent1FormComponent.prototype, "isUsacAdmin", {
        get: function () { return this.userRole === 'usac_admin'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent1FormComponent.prototype, "f", {
        get: function () { return this.postEventForm.controls; },
        enumerable: true,
        configurable: true
    });
    PostEvent1FormComponent.prototype.ngOnInit = function () {
        this.buildForm();
        this.subscribeToForm();
        this.fetchPostEvent();
    };
    PostEvent1FormComponent.prototype.buildForm = function () {
        this.postEventForm = this.formBuilder.group({
            post_event_event_id: [this.event.event_id],
            post_event_permit_id: [this.event.event_permit_id],
            post_event_event_date_id: [this.eventDateId],
            post_event_sufficient_staff: ['', Validators.required],
            post_event_sufficient_facilities: ['', Validators.required],
            post_event_sufficient_time: ['', Validators.required],
            post_event_start_lists_adequate: ['', Validators.required],
            post_event_check_licenses: ['', Validators.required],
            post_event_registration_comments: ['', Validators.maxLength(500)],
            post_event_flyer_accurate: ['', Validators.required],
            post_event_course_changes_required: ['', Validators.required],
            post_event_podium: ['', Validators.required],
            // post_event_beginner_racer_program_event: ['', Validators.required],
            post_event_conduct_comments: ['', Validators.maxLength(500)],
            post_event_neutral_support_services: ['', Validators.required],
            post_event_licensed_mechanics: ['', Validators.required],
            post_event_timing_company: ['', Validators.required],
            post_event_technical_support_comments: ['', Validators.maxLength(500)],
            post_event_medical_support_verified: ['', Validators.required],
            post_event_medical_support_description: ['', [Validators.required, Validators.maxLength(500)]],
            post_event_number_of_occurrences: [0, Validators.required],
            post_event_accident_occurrence_reports: [this.occurrenceReports],
            post_event_paperwork_occurrence_reports: [false, Validators.required],
            post_event_rider_discipline: ['', Validators.required],
            post_event_rider_discipline_reports: [this.disciplineReports],
            post_event_paperwork_rider_discipline_reports: [false, Validators.required],
            post_event_largest_individual_prize: ['', Validators.required],
            post_event_prize_equal_pay: ['', Validators.required],
            post_event_prize_distributed_15_min: ['', Validators.required],
            post_event_posting_area_adequate: ['', Validators.required],
            post_event_protest_period_observed: ['', Validators.required],
            post_event_prize_comments: ['', Validators.maxLength(500)]
        });
        this.sufficientStaffOptions = [
            new RadioOption('staff-yes', 'Y', 'Yes'),
            new RadioOption('staff-no', 'N', 'No')
        ];
        this.sufficientTimeOptions = [
            new RadioOption('time-yes', 'Y', 'Yes'),
            new RadioOption('time-no', 'N', 'No')
        ];
        this.sufficientFacilitiesOptions = [
            new RadioOption('facilities-yes', 'Y', 'Yes'),
            new RadioOption('facilities-no', 'N', 'No')
        ];
        this.startListOptions = [
            new RadioOption('start-list-yes', 'Y', 'Yes'),
            new RadioOption('start-list-no', 'N', 'No')
        ];
        this.licenseCheckOptions = [
            new RadioOption('license-yes', 'Y', 'Yes'),
            new RadioOption('license-no', 'N', 'No')
        ];
        this.eventFlyerOptions = [
            new RadioOption('flyer-yes', 'Y', 'Yes'),
            new RadioOption('flyer-no', 'N', 'No')
        ];
        this.courseChangesOptions = [
            new RadioOption('course-changes-yes', 'Y', 'Yes'),
            new RadioOption('course-changes-no', 'N', 'No')
        ];
        this.podiumOptions = [
            new RadioOption('podium-yes', 'Y', 'Yes'),
            new RadioOption('podium-no', 'N', 'No')
        ];
        this.brpOptions = [
            new RadioOption('brp-yes', 'Y', 'Yes'),
            new RadioOption('brp-no', 'N', 'No')
        ];
        this.licensedMechanicsOptions = [
            new RadioOption('mechanics-yes', 'Y', 'Yes'),
            new RadioOption('mechanics-no', 'N', 'No')
        ];
        this.timingCompanyOptions = [
            new RadioOption('timing-yes', 'Y', 'Yes'),
            new RadioOption('timing-no', 'N', 'No')
        ];
        this.medicalSupportOptions = [
            new RadioOption('medical-support-yes', 'Y', 'Yes'),
            new RadioOption('medical-support-no', 'N', 'No')
        ];
        this.neutralSupportOptions = [
            new RadioOption('neutral-support-yes', 'Y', 'Yes'),
            new RadioOption('neutral-support-no', 'N', 'No')
        ];
        this.equalPayOptions = [
            new RadioOption('equal-pay-yes', 'Y', 'Yes'),
            new RadioOption('equal-pay-no', 'N', 'No')
        ];
        this.prizeDistributionOptions = [
            new RadioOption('prize-distributed-yes', 'Y', 'Yes'),
            new RadioOption('prize-distributed-no', 'N', 'No')
        ];
        this.postingAreaOptions = [
            new RadioOption('posting-area-yes', 'Y', 'Yes'),
            new RadioOption('posting-area-no', 'N', 'No')
        ];
        this.protestPeriodOptions = [
            new RadioOption('protest-period-yes', 'Y', 'Yes'),
            new RadioOption('protest-period-no', 'N', 'No')
        ];
        this.disciplineRiderOptions = [
            new RadioOption('discipline-rider-yes', 'Y', 'Yes'),
            new RadioOption('discipline-rider-no', 'N', 'No')
        ];
    };
    PostEvent1FormComponent.prototype.subscribeToForm = function () {
        var _this = this;
        this.postEventForm.get('post_event_number_of_occurrences').valueChanges.subscribe(function (value) {
            _this.displayOccurrenceReportUpload = value > 0;
            _this.disableOccurrencePaperwork = value === 0;
            if (value === 0) {
                _this.f.post_event_paperwork_occurrence_reports.setValue(false);
            }
        });
        this.postEventForm.get('post_event_accident_occurrence_reports').valueChanges.subscribe(function (value) {
            var present = !!value && value.length > 0;
            _this.disableOccurrencePaperwork = present;
            if (present) {
                _this.f.post_event_paperwork_occurrence_reports.setValue(true);
            }
        });
        this.postEventForm.get('post_event_rider_discipline').valueChanges.subscribe(function (value) {
            _this.displayDisciplineReportUpload = _this.yesNoBoolean(value);
            if (!_this.displayDisciplineReportUpload) {
                _this.disciplineReports = [];
                _this.setReportsValue('discipline', _this.disciplineReports);
                _this.f.post_event_paperwork_rider_discipline_reports.setValue(false);
            }
        });
        this.postEventForm.get('post_event_rider_discipline_reports').valueChanges.subscribe(function (value) {
            var present = !!value && value.length > 0;
            _this.disableDisciplinePaperwork = present;
            if (present) {
                _this.f.post_event_paperwork_rider_discipline_reports.setValue(true);
            }
        });
    };
    PostEvent1FormComponent.prototype.fetchPostEvent = function () {
        var _this = this;
        if (this.isSeries) {
            this.postEventService.getPostEventByEventDate(this.eventId, this.eventDateId).subscribe(function (postEvent) {
                _this.postEvent = postEvent;
                _this.assignPostEventToForm();
                _this.loading = false;
            });
        }
        else {
            this.postEventService.getPostEventsByEvent(this.eventId).subscribe(function (postEvents) {
                _this.postEvent = postEvents && postEvents.length && postEvents[0];
                _this.assignPostEventToForm();
                _this.loading = false;
            });
        }
    };
    PostEvent1FormComponent.prototype.assignPostEventToForm = function () {
        var _this = this;
        if (this.postEvent) {
            Object.keys(this.postEvent).forEach(function (key) {
                if (Object.keys(_this.f).includes(key)) {
                    _this.f[key].setValue(_this.booleanToYesNo(_this.postEvent[key]));
                }
            });
            this.f.post_event_number_of_occurrences.setValue(this.postEvent.post_event_number_of_occurrences || 0);
            this.occurrenceReports = this.postEvent.post_event_accident_occurrence_reports || [];
            this.disciplineReports = this.postEvent.post_event_rider_discipline_reports || [];
            this.setPaperworkDisableStates();
            this.f.post_event_paperwork_occurrence_reports.setValue(this.postEvent.post_event_paperwork_occurrence_reports || false);
            this.f.post_event_paperwork_rider_discipline_reports.setValue(this.postEvent.post_event_paperwork_rider_discipline_reports || false);
            // console.log(this.f);
        }
    };
    PostEvent1FormComponent.prototype.setPaperworkDisableStates = function () {
        this.disableOccurrencePaperwork = this.postEvent.post_event_number_of_occurrences === 0 || this.occurrenceReports.length > 0;
        this.disableDisciplinePaperwork = this.disciplineReports.length > 0;
    };
    PostEvent1FormComponent.prototype.onSubmit = function (event) {
        var _this = this;
        // console.log(`Submitted (${this.postEventForm.status}): `, this.f);
        this.submitted = true;
        // Stop here if form is invalid
        console.log(this.postEventForm.invalid);
        if (this.postEventForm.invalid) {
            this.handleValidationFeedback();
            return;
        }
        var pef = this.postEventForm.value;
        var data = new PostEvent(pef);
        // Parse form data to convert 'Y/N' values to booleans
        Object.keys(pef).forEach(function (key) {
            var value = pef[key];
            data[key] = _this.yesOrNo(value) ? _this.yesNoBoolean(value) : value;
        });
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
        if (this.formMode === 'create') {
            this.postEventService.createPostEvent(data).subscribe(function (resp) {
                if (resp) {
                    _this.redirect(event);
                }
            });
        }
        else if (this.formMode === 'update') {
            this.postEventService.updatePostEvent(this.postEventId, data).subscribe(function (resp) {
                if (resp) {
                    _this.redirect(event);
                }
            });
        }
    };
    PostEvent1FormComponent.prototype.handleValidationFeedback = function () {
        var fragment = '';
        var formControlSections = this.postEventService.part1FormControlSections;
        var invalidControls = Object.entries(this.f).filter(function (_a) {
            var key = _a[0], control = _a[1];
            return control.invalid;
        });
        for (var i = 0; i < formControlSections.length; i++) {
            fragment = this.searchInvalidControls(i, formControlSections[i], invalidControls);
            if (fragment) {
                break;
            }
        }
        if (fragment) {
            document.getElementById(fragment).scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        }
    };
    PostEvent1FormComponent.prototype.searchInvalidControls = function (sectionIndex, formControlSection, invalidControls) {
        var sections = ['registration', 'conduct', 'technical', 'medical', 'disciplinary', 'prizes'];
        var _loop_1 = function (controlName) {
            if (invalidControls.some(function (control) { return control[0] === controlName; })) {
                return { value: sections[sectionIndex] };
            }
        };
        for (var _i = 0, formControlSection_1 = formControlSection; _i < formControlSection_1.length; _i++) {
            var controlName = formControlSection_1[_i];
            var state_1 = _loop_1(controlName);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        return '';
    };
    PostEvent1FormComponent.prototype.yesOrNo = function (value) {
        return value === 'Y' || value === 'N';
    };
    PostEvent1FormComponent.prototype.yesNoBoolean = function (value) {
        return value === 'Y';
    };
    PostEvent1FormComponent.prototype.booleanToYesNo = function (value) {
        if (typeof (value) === 'boolean') {
            return value ? 'Y' : 'N';
        }
        else {
            return value;
        }
    };
    PostEvent1FormComponent.prototype.selectOccurrenceFile = function (event) {
        var _this = this;
        this.occurrenceReportAlert = [];
        if (event.target.files.length > 0) {
            var report_1 = event.target.files[0];
            var fileExtn = report_1.name.split('.').pop();
            var formData = new FormData();
            formData.append('image', report_1);
            if (this.upload.serviceType.OCCURRENCE_REPORT.extensions.indexOf(fileExtn.toLowerCase()) > -1) {
                this.upload.fileUpload(formData, this.eventId.toString()).subscribe(function (data) {
                    _this.occurrenceReportAlert = [
                        new Alert('success', report_1.name + ' was successfully uploaded.', null, null, null, null, 4000)
                    ];
                    _this.occurrenceReports.push({ filename: report_1.name, url: data.url });
                    _this.setReportsValue('occurrence', _this.occurrenceReports);
                }, function (err) {
                    _this.occurrenceReportAlert = [
                        new Alert('warning', 'There was an error uploading your file: ' + err.message)
                    ];
                });
            }
            else {
                this.occurrenceReportAlert = [
                    new Alert('warning', 'Invalid file type. Supported file extns are PDF, DOCX, or DOC.')
                ];
            }
        }
    };
    PostEvent1FormComponent.prototype.occurrenceReportMin = function () {
        return this.occurrenceReports.length ? 1 : 0;
    };
    PostEvent1FormComponent.prototype.selectDisciplineFile = function (event) {
        var _this = this;
        if (event.target.files.length > 0) {
            var report_2 = event.target.files[0];
            var fileExtn = report_2.name.split('.').pop();
            var formData = new FormData();
            formData.append('image', report_2);
            if (this.upload.serviceType.DISCIPLINE_REPORT.extensions.indexOf(fileExtn.toLowerCase()) > -1) {
                this.upload.fileUpload(formData, this.eventId.toString()).subscribe(function (data) {
                    _this.disciplineReportAlert = [
                        new Alert('success', report_2.name + ' was successfully uploaded.', null, null, null, null, 4000)
                    ];
                    _this.f.post_event_rider_discipline_reports.setValue(data.url);
                    _this.disciplineReports.push({ filename: report_2.name, url: data.url });
                    _this.setReportsValue('discipline', _this.disciplineReports);
                }, function (err) {
                    _this.disciplineReportAlert = [
                        new Alert('warning', 'There was an error uploading your file: ' + err.message)
                    ];
                });
            }
            else {
                this.disciplineReportAlert = [
                    new Alert('warning', 'Invalid file type. Supported file extns are PDF, DOCX, or DOC.')
                ];
            }
        }
    };
    PostEvent1FormComponent.prototype.removeReport = function (type, reports, index) {
        reports.splice(index, 1);
        this.setReportsValue(type, reports);
    };
    PostEvent1FormComponent.prototype.setReportsValue = function (type, reports) {
        if (type === 'occurrence') {
            this.f.post_event_accident_occurrence_reports.setValue(reports);
        }
        else if (type === 'discipline') {
            this.f.post_event_rider_discipline_reports.setValue(reports);
        }
    };
    PostEvent1FormComponent.prototype.label = function (key) {
        return this.postEventService.part1Labels[key];
    };
    PostEvent1FormComponent.prototype.redirect = function (event) {
        if (event === 'save-exit') {
            this.toOverview();
        }
        else if (event === 'save-continue') {
            this.toPart3();
        }
        else if (event === 'save-continue-ss') {
            this.toPart2();
        }
    };
    Object.defineProperty(PostEvent1FormComponent.prototype, "part2Url", {
        get: function () {
            var base = this.urlSegments.slice(0, -1);
            base.push('part-2');
            return base.join('/');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEvent1FormComponent.prototype, "part3Url", {
        get: function () {
            var base = this.urlSegments.slice(0, -1);
            base.push('part-3');
            return base.join('/');
        },
        enumerable: true,
        configurable: true
    });
    PostEvent1FormComponent.prototype.toOverview = function () {
        this.router.navigate(["event/" + this.event.event_id + "/overview"], { fragment: 'post-event' });
    };
    PostEvent1FormComponent.prototype.toPart2 = function () {
        this.router.navigate([this.part2Url]);
    };
    PostEvent1FormComponent.prototype.toPart3 = function () {
        this.router.navigate([this.part3Url]);
    };
    return PostEvent1FormComponent;
}());
export { PostEvent1FormComponent };
