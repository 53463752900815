import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BaseService } from './base-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
var AuthenticationService = /** @class */ (function (_super) {
    tslib_1.__extends(AuthenticationService, _super);
    function AuthenticationService(http, cookie) {
        var _this = _super.call(this, http, cookie) || this;
        _this.http = http;
        _this.cookie = cookie;
        _this.cookieName = 'USACLOGIN';
        return _this;
    }
    AuthenticationService.prototype.doLogin = function (redirectUrl) {
        window.location.assign(environment.adminUrl + ("/login?redirectUrl=" + redirectUrl));
    };
    AuthenticationService.prototype.doLogout = function () {
        var _this = this;
        return this.http.get(this.url('logout'), this.options).pipe(tap(function (_) {
            _this.log('attempting api logout');
        }), catchError(this.handleError('doLogout', {})));
    };
    AuthenticationService.prototype.isAuthenticated = function () {
        var _this = this;
        return new Observable(function (observer) {
            var hasCookie = _this.cookie.check(_this.cookieName);
            observer.next(hasCookie);
            observer.complete();
        });
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}(BaseService));
export { AuthenticationService };
