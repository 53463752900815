/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./card.component";
var styles_CardComponent = [];
var RenderType_CardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CardComponent, data: {} });
export { RenderType_CardComponent as RenderType_CardComponent };
function View_CardComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["class", "card-img-top"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.imageSrc, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.imageAlt, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_CardComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], [[8, "id", 0], [4, "width", "px"]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "full-height": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CardComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵinlineInterpolate(2, "card ", _co.isLight, " ", _co.classes, ""); var currVal_3 = _ck(_v, 3, 0, _co.fullHeight); _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _co.imageSrc; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.id, ""); var currVal_1 = _co.width; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_CardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-card", [], null, null, null, View_CardComponent_0, RenderType_CardComponent)), i0.ɵdid(1, 114688, null, 0, i2.CardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardComponentNgFactory = i0.ɵccf("app-card", i2.CardComponent, View_CardComponent_Host_0, { id: "id", imageSrc: "imageSrc", imageAlt: "imageAlt", width: "width", light: "light", fullHeight: "fullHeight", classes: "classes" }, {}, ["*"]);
export { CardComponentNgFactory as CardComponentNgFactory };
