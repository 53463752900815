<app-loader *ngIf="loading" [overlay]="true"></app-loader>

<nav *ngIf="showGhostModeBar" class="ghost-mode navbar navbar-expand-sm">
  <div class="container">
    <div class="w-100 d-flex align-items-center justify-content-between">
      <span class="text-uppercase pr-2">
        Logged In As:  {{ adminInfo }}
      </span>
      <div>
        <form [formGroup]="ghostModeForm">
          <app-checkbox
            class="col-12"
            formControlName="ghostMode"
            [id]="'ghost-mode-control'"
            [label]="'Ghost Mode'"
            [inline]="true"
            [toggle]="true"
            [disabled]="true"
            (click)="resetGhostMode()"
          ></app-checkbox>

          <span class="ghost-status ml-2">
            {{ status }}
            <span class="font-weight-bold">{{ activeProfileNameAndId }}</span>
            <a href="/profile/{{ currentUser.profile_id }}" class="ml-3" *ngIf="showCancelButton">
              <i class="icon-delete"></i>
            </a>
          </span>
        </form>
      </div>
    </div>
  </div>
</nav>
