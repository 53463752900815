import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService, EventListingService, PermitService } from 'src/app/services';
import { Event } from 'src/app/classes';
var PermitApplicationPart1Component = /** @class */ (function () {
    function PermitApplicationPart1Component(router, route, eventService, eventListing, permitService) {
        this.router = router;
        this.route = route;
        this.eventService = eventService;
        this.eventListing = eventListing;
        this.permitService = permitService;
        this.currentStep = 1;
        this.reset = false;
        this.dataLoaded = false;
    }
    PermitApplicationPart1Component.prototype.ngOnInit = function () {
        var _this = this;
        this.permitService.reset();
        this.eventListing.reset();
        this.reset = true;
        this.eventId = this.route.snapshot.paramMap.get('eventId');
        this.eventService.getEventById(this.eventId).subscribe(function (event) {
            if (event) {
                _this.event = new Event(event);
                // Don't allow virtual events to be permitted
                if (_this.event.isVirtual) {
                    _this.toOverview();
                }
                else {
                    _this.eventListing.fetchEventData(_this.eventId);
                    _this.permitService.permitId = event.event_permit_id;
                    _this.permitService.getQuestionConfig(event.event_discipline);
                    if (_this.permitService.permitId) {
                        _this.permitService.mode = 'update';
                        _this.loadAnswers();
                    }
                    else {
                        _this.dataLoaded = true;
                    }
                }
            }
            else {
                _this.toQueue();
            }
        });
    };
    PermitApplicationPart1Component.prototype.loadAnswers = function () {
        var _this = this;
        this.permitService.getPermitInfo(this.permitService.permitId).subscribe(function (data) {
            if (data === null || data === undefined) {
                console.log('events are not found , due to the api has down');
            }
            _this.permitService.answers = data.answers;
            console.log(_this.permitService.answers);
            if (!_this.permitService.answers.length) {
                _this.permitService.mode = 'create';
            }
            _this.permitService.fetchFromAnswers();
            _this.dataLoaded = true;
        }, function (error) {
            console.log(error);
        });
    };
    Object.defineProperty(PermitApplicationPart1Component.prototype, "loaded", {
        get: function () {
            return this.reset && this.eventListing.datesLoaded && this.eventListing.organizerLoaded
                && this.eventListing.event_discipline && this.permitService.availableQuestionsList.length
                && this.dataLoaded;
        },
        enumerable: true,
        configurable: true
    });
    PermitApplicationPart1Component.prototype.toOverview = function () {
        this.router.navigate(["event/" + this.eventId + "/overview"]);
    };
    PermitApplicationPart1Component.prototype.toQueue = function () {
        this.router.navigate(['queue']);
    };
    return PermitApplicationPart1Component;
}());
export { PermitApplicationPart1Component };
