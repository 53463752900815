import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { RadioOption } from '../../classes';
import { FormFieldErrors } from '../../classes';

const noop = () => {};

@Component({
  selector: 'app-radio-switch',
  templateUrl: './radio-switch.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioSwitchComponent),
      multi: true
    }
  ]
})
export class RadioSwitchComponent implements OnInit {
  currentValue: string;

  @Input() name: string;
  @Input() label: string;
  @Input() options: RadioOption[] = [];
  @Input() inline: boolean;
  @Input() required: boolean;
  @Input() formControlName: string;
  @Input() formSubmitted: boolean;
  @Input() errors: FormFieldErrors;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input()
  get value() {
    return this.currentValue;
  }

  set value(val) {
    this.currentValue = val;
    this.change.emit(this.currentValue);
    this.valueChange.emit(this.currentValue);
    this.onChangeCallback(this.currentValue);
  }

  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  writeValue(value: any) {
    this.currentValue = value;
    this.onChangeCallback(value);
    for (const option of this.options) {
      if (this.currentValue === option.value) {
        option.checked = true;
      }
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  constructor() { }

  ngOnInit() {
    for (const option of this.options) {
      if (this.value === option.value) {
        option.checked = true;
      }
    }
  }

  onRadioChange(event: any) {
    if (event && event.target) {
      for (const option of this.options) {
        if (option.value === event.target.value) {
          option.checked = event.target.checked ? true : undefined;
        }
      }

      this.value = event.target.value;
    }
  }
}
