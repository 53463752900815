import { OnInit, EventEmitter } from '@angular/core';
import { FormFieldErrors } from '../../classes';
var noop = function () { };
var ɵ0 = noop;
var CheckboxComponent = /** @class */ (function () {
    function CheckboxComponent() {
        this.isChecked = false;
        this.inlineStyle = '';
        this.typeClass = 'custom-checkbox';
        this.onTouchedCallback = noop;
        this.onChangeCallback = noop;
        this.change = new EventEmitter();
        this.checkedChange = new EventEmitter();
    }
    Object.defineProperty(CheckboxComponent.prototype, "checked", {
        get: function () {
            return this.isChecked;
        },
        set: function (val) {
            this.isChecked = val;
            this.change.emit(this.isChecked);
            this.checkedChange.emit(this.isChecked);
            this.onChangeCallback(this.isChecked);
        },
        enumerable: true,
        configurable: true
    });
    CheckboxComponent.prototype.writeValue = function (value) {
        this.isChecked = value;
        this.onChangeCallback(value);
    };
    // From ControlValueAccessor interface
    CheckboxComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    // From ControlValueAccessor interface
    CheckboxComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    CheckboxComponent.prototype.onCheckChange = function (event) {
        if (event && event.target) {
            // this.checked = event.target.checked ? true : undefined;
            this.checked = event.target.checked;
            // this.onChangeCallback(event.target.checked);
        }
    };
    CheckboxComponent.prototype.ngOnInit = function () {
        if (this.inline) {
            this.inlineStyle = 'custom-control-inline';
        }
        if (this.toggle) {
            this.typeClass = 'custom-switch';
        }
    };
    return CheckboxComponent;
}());
export { CheckboxComponent };
export { ɵ0 };
