/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../atoms/loader/loader.component.ngfactory";
import * as i2 from "../../atoms/loader/loader.component";
import * as i3 from "../product/product.component.ngfactory";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../../providers/dateFormatter";
import * as i6 from "../product/product.component";
import * as i7 from "../../shared/services/shopify.service";
import * as i8 from "../../shared/services/global.service";
import * as i9 from "@angular/forms";
import * as i10 from "../../services/event_day.service";
import * as i11 from "@angular/common";
import * as i12 from "../../molecules/card/card.component.ngfactory";
import * as i13 from "../../molecules/card/card.component";
import * as i14 from "../../atoms/button/button.component.ngfactory";
import * as i15 from "../../atoms/button/button.component";
import * as i16 from "./add-on-list.component";
import * as i17 from "@angular/router";
var styles_AddOnListComponent = [];
var RenderType_AddOnListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddOnListComponent, data: {} });
export { RenderType_AddOnListComponent as RenderType_AddOnListComponent };
function View_AddOnListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AddOnListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-product", [], null, [[null, "init"], [null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("init" === en)) {
        var pd_0 = (_co.setProductsInitialized($event) !== false);
        ad = (pd_0 && ad);
    } if (("load" === en)) {
        var pd_1 = (_co.setProductsLoaded($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_ProductComponent_0, i3.RenderType_ProductComponent)), i0.ɵprd(512, null, i4.NgbDateParserFormatter, i5.CustomDateFormatter, []), i0.ɵdid(2, 114688, null, 0, i6.ProductComponent, [i7.ShopifyService, i8.GlobalService, i9.FormBuilder, i4.NgbCalendar, i4.NgbDateParserFormatter, i10.EventDayService], { product: [0, "product"], productImage: [1, "productImage"], variants: [2, "variants"], draftOrder: [3, "draftOrder"], discipline: [4, "discipline"], eventDates: [5, "eventDates"] }, { init: "init", load: "load" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit; var currVal_1 = _co.productImages[_v.parent.context.$implicit.id]; var currVal_2 = _co.productVariants[_v.parent.context.$implicit.id]; var currVal_3 = _co.draftOrder; var currVal_4 = _co.discipline; var currVal_5 = _co.eventDates; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_AddOnListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "col-lg-6 mb-4"]], null, null, null, null, null)), i0.ɵprd(512, null, i11.ɵNgClassImpl, i11.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i11.NgClass, [i11.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "hidden": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 3, "app-card", [], null, null, null, i12.View_CardComponent_0, i12.RenderType_CardComponent)), i0.ɵdid(5, 114688, null, 0, i13.CardComponent, [], { fullHeight: [0, "fullHeight"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AddOnListComponent_3)), i0.ɵdid(7, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "col-lg-6 mb-4"; var currVal_1 = _ck(_v, 3, 0, _co.loading); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = true; _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.productVariantsLength && _co.productVariants[_v.context.$implicit.id]); _ck(_v, 7, 0, currVal_3); }, null); }
function View_AddOnListComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "row space-above-lg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toInsuredCerts() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_ButtonComponent_0, i14.RenderType_ButtonComponent)), i0.ɵdid(2, 114688, null, 0, i15.ButtonComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵted(-1, 0, ["Go Back"]))], function (_ck, _v) { var currVal_0 = "btn-light"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AddOnListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddOnListComponent_1)), i0.ɵdid(1, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddOnListComponent_2)), i0.ɵdid(4, 278528, null, 0, i11.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddOnListComponent_4)), i0.ɵdid(6, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.products; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.loading; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_AddOnListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-add-on-list", [], null, null, null, View_AddOnListComponent_0, RenderType_AddOnListComponent)), i0.ɵdid(1, 114688, null, 0, i16.AddOnListComponent, [i17.Router, i7.ShopifyService, i8.GlobalService, i10.EventDayService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddOnListComponentNgFactory = i0.ɵccf("app-add-on-list", i16.AddOnListComponent, View_AddOnListComponent_Host_0, { eventId: "eventId", discipline: "discipline", eventDates: "eventDates" }, {}, []);
export { AddOnListComponentNgFactory as AddOnListComponentNgFactory };
