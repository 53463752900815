import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import qs from 'qs';
import { BaseService } from './base-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
var ReportsService = /** @class */ (function (_super) {
    tslib_1.__extends(ReportsService, _super);
    function ReportsService(http, cookie) {
        var _this = _super.call(this, http, cookie) || this;
        _this.http = http;
        _this.cookie = cookie;
        return _this;
        // this.allowedQueueParams = [
        //   'no_paginate',
        //   'page',
        //   'city',
        //   'states',
        //   'disciplines',
        //   'event_start_date',
        //   'event_end_date',
        //   'listing_type',
        //   'status',
        //   'regions',
        //   'permit_date_range'
        // ];
    }
    ReportsService.prototype.getTransactions = function (params) {
        var _this = this;
        var route = 'reports/transactions';
        if (params) {
            route += '?' + qs.stringify(params);
        }
        return this.http.get(this.url(route), this.options)
            .pipe(tap(function (_) { return _this.log('fetched transaction reports'); }), catchError(this.handleError('getTransactions', [], { params: params })));
    };
    ReportsService.prototype.getOfficialAssignments = function (params) {
        var _this = this;
        var route = 'reports/official_assignments';
        if (params) {
            route += '?' + qs.stringify(params);
        }
        return this.http.get(this.url(route), this.options)
            .pipe(tap(function (_) { return _this.log('fetched official_assignments reports'); }), catchError(this.handleError('getOfficialAssignments', [], { params: params })));
    };
    ReportsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportsService_Factory() { return new ReportsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: ReportsService, providedIn: "root" });
    return ReportsService;
}(BaseService));
export { ReportsService };
