import * as tslib_1 from "tslib";
var CustomFile = /** @class */ (function (_super) {
    tslib_1.__extends(CustomFile, _super);
    function CustomFile(chunks, filename, opts) {
        var _this = _super.call(this, chunks, opts) || this;
        _this.lastModifiedDate = new Date();
        _this.lastModified = +_this.lastModifiedDate;
        _this.name = filename;
        return _this;
    }
    return CustomFile;
}(Blob));
export { CustomFile };
