export class ShopifyDiscount {
  description: string;
  value_type: string;
  value: number;
  amount: number;
  title: string;

  constructor(data) {
    Object.assign(this, data);
  }
}
