/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../atoms/radio-switch-item/radio-switch-item.component.ngfactory";
import * as i2 from "@angular/forms";
import * as i3 from "../../atoms/radio-switch-item/radio-switch-item.component";
import * as i4 from "@angular/common";
import * as i5 from "./radio-switch.component";
var styles_RadioSwitchComponent = [];
var RenderType_RadioSwitchComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RadioSwitchComponent, data: {} });
export { RenderType_RadioSwitchComponent as RenderType_RadioSwitchComponent };
function View_RadioSwitchComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-radio-switch-item", [["class", "switch-item"]], null, [[null, "checkedChange"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkedChange" === en)) {
        var pd_0 = ((_v.context.$implicit.checked = $event) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.onRadioChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_RadioSwitchItemComponent_0, i1.RenderType_RadioSwitchItemComponent)), i0.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.RadioSwitchItemComponent]), i0.ɵdid(2, 114688, null, 0, i3.RadioSwitchItemComponent, [], { id: [0, "id"], name: [1, "name"], value: [2, "value"], label: [3, "label"], disabled: [4, "disabled"], inline: [5, "inline"], checked: [6, "checked"] }, { change: "change", checkedChange: "checkedChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.id; var currVal_1 = _co.name; var currVal_2 = _v.context.$implicit.value; var currVal_3 = _v.context.$implicit.label; var currVal_4 = _v.context.$implicit.disabled; var currVal_5 = _co.inline; var currVal_6 = _v.context.$implicit.checked; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_RadioSwitchComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Required"]))], null, null); }
function View_RadioSwitchComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "invalid-feedback"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioSwitchComponent_3)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors.required; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RadioSwitchComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "switch-field"]], null, null, null, null, null)), i0.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "is-invalid": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioSwitchComponent_1)), i0.ɵdid(5, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioSwitchComponent_2)), i0.ɵdid(7, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "switch-field"; var currVal_1 = _ck(_v, 3, 0, (_co.formSubmitted && _co.errors)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.options; _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.formSubmitted && _co.errors); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_RadioSwitchComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-radio-switch", [], null, null, null, View_RadioSwitchComponent_0, RenderType_RadioSwitchComponent)), i0.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.RadioSwitchComponent]), i0.ɵdid(2, 114688, null, 0, i5.RadioSwitchComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var RadioSwitchComponentNgFactory = i0.ɵccf("app-radio-switch", i5.RadioSwitchComponent, View_RadioSwitchComponent_Host_0, { name: "name", label: "label", options: "options", inline: "inline", required: "required", formControlName: "formControlName", formSubmitted: "formSubmitted", errors: "errors", value: "value" }, { change: "change", valueChange: "valueChange" }, []);
export { RadioSwitchComponentNgFactory as RadioSwitchComponentNgFactory };
