import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileUploadService } from 'src/app/shared';

import { PostEventService } from 'src/app/services';

import {
  RadioOption,
  Alert,
  Event,
  EventDate,
  PostEvent
} from '../../classes';

@Component({
  selector: 'app-post-event-3-form',
  templateUrl: './post-event-3-form.component.html',
  styles: []
})
export class PostEvent3FormComponent implements OnInit {

  @Input() event: Event;
  @Input() eventDate: EventDate;
  @Input() isSeries: boolean;
  @Input() urlSegments: string[];
  @Input() userRole: string;

  postEvent: PostEvent;
  postEventForm: FormGroup;

  get eventId() { return (this.event.event_id); }
  get eventDateId() { return (this.eventDate && this.eventDate.event_date_id) || null; }
  get postEventId() { return (this.postEvent && this.postEvent.post_event_id); }
  get formMode() { return this.postEvent ? 'update' : 'create'; }
  get isUsacAdmin() { return this.userRole === 'usac_admin'; }
  submitted = false;

  ssQ1Options: RadioOption[] = [];
  ssQ2Options: RadioOption[] = [];
  ssQ3Options: RadioOption[] = [];
  ssQ4Options: RadioOption[] = [];
  ssQ5Options: RadioOption[] = [];
  ssQ6Options: RadioOption[] = [];
  ssQ7Options: RadioOption[] = [];
  ssQ8Options: RadioOption[] = [];

  ssq1show = false;
  ssq2show = false;
  ssq3show = false;
  ssq4show = false;
  ssq5show = false;
  ssq6show = false;
  ssq7show = false;
  ssq8show = false;

  sufficientStaffOptions: RadioOption[] = [];
  sufficientFacilitiesOptions: RadioOption[] = [];
  sufficientTimeOptions: RadioOption[] = [];
  licenseCheckOptions: RadioOption[] = [];
  startListOptions: RadioOption[] = [];
  eventFlyerOptions: RadioOption[] = [];
  courseChangesOptions: RadioOption[] = [];
  podiumOptions: RadioOption[] = [];
  brpOptions: RadioOption[] = [];
  neutralSupportOptions: RadioOption[] = [];
  licensedMechanicsOptions: RadioOption[] = [];
  timingCompanyOptions: RadioOption[] = [];
  medicalSupportOptions: RadioOption[] = [];
  equalPayOptions: RadioOption[] = [];
  prizeDistributionOptions: RadioOption[] = [];
  postingAreaOptions: RadioOption[] = [];
  protestPeriodOptions: RadioOption[] = [];
  disciplineRiderOptions: RadioOption[] = [];

  occurrenceReports: any[] = [];
  occurrenceReportAlert: Alert[] = [];
  displayOccurrenceReportUpload = false;
  disableOccurrencePaperwork = false;

  disciplineReports: any[] = [];
  disciplineReportAlert: Alert[] = [];
  displayDisciplineReportUpload = false;
  disableDisciplinePaperwork = false;

  loading = true;

  get f() { return this.postEventForm.controls; }

  constructor(
    private formBuilder: FormBuilder,
    private upload: FileUploadService,
    private postEventService: PostEventService,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildForm();
    this.subscribeToForm();
    this.fetchPostEvent();
  }

  buildForm() {
    this.postEventForm = this.formBuilder.group({

      post_event_event_id: [this.event.event_id],
      post_event_permit_id: [this.event.event_permit_id],
      post_event_event_date_id: [this.eventDateId],

      ss_q1: ['', Validators.required],
      ss_q2: ['', Validators.required],
      ss_q3: ['', Validators.required],
      ss_q4: ['', Validators.required],
      ss_q5: ['', Validators.required],
      ss_q6: ['', Validators.required],
      ss_q7: ['', Validators.required],
      ss_q8: ['', Validators.required],
      ss_q1_text: [''],
      ss_q2_text: [''],
      ss_q3_text: [''],
      ss_q4_text: [''],
      ss_q5_text: [''],
      ss_q6_text: [''],
      ss_q7_text: [''],
      ss_q8_text: [''],
    });

    this.ssQ1Options = [
      new RadioOption('ss-q1-yes', 'Y', 'Yes'),
      new RadioOption('ss-q1-no', 'N', 'No'),
      new RadioOption('ss-q1-idk', 'I', "I don't know"),
    ];
    this.ssQ2Options = [
      new RadioOption('ss-q2-yes', 'Y', 'Yes'),
      new RadioOption('ss-q2-no', 'N', 'No'),
      new RadioOption('ss-q2-idk', 'I', "I don't know"),
    ];
    this.ssQ3Options = [
      new RadioOption('ss-q3-yes', 'Y', 'Yes'),
      new RadioOption('ss-q3-no', 'N', 'No'),
      new RadioOption('ss-q3-idk', 'I', "I don't know"),
    ];
    this.ssQ4Options = [
      new RadioOption('ss-q4-yes', 'Y', 'Yes'),
      new RadioOption('ss-q4-no', 'N', 'No'),
      new RadioOption('ss-q4-idk', 'I', "I don't know"),
    ];
    this.ssQ5Options = [
      new RadioOption('ss-q5-yes', 'Y', 'Yes'),
      new RadioOption('ss-q5-no', 'N', 'No'),
      new RadioOption('ss-q5-idk', 'I', "I don't know"),
    ];
    this.ssQ6Options = [
      new RadioOption('ss-q6-yes', 'Y', 'Yes'),
      new RadioOption('ss-q6-no', 'N', 'No'),
      new RadioOption('ss-q6-idk', 'I', "I don't know"),
    ];
    this.ssQ7Options = [
      new RadioOption('ss-q7-yes', 'Y', 'Yes'),
      new RadioOption('ss-q7-no', 'N', 'No'),
      new RadioOption('ss-q7-idk', 'I', "I don't know"),
    ];
    this.ssQ8Options = [
      new RadioOption('ss-q8-yes', 'Y', 'Yes'),
      new RadioOption('ss-q8-no', 'N', 'No'),
      new RadioOption('ss-q8-idk', 'I', "I don't know"),
    ];
  }

  shouldShow(q){
    return ["N","I"].includes(q);
  }

  subscribeToForm() {
    this.postEventForm.get('ss_q1').valueChanges.subscribe(value => {
        this.ssq1show = this.shouldShow(value);
    });
    this.postEventForm.get('ss_q1').valueChanges.subscribe(value => {
        this.ssq1show = this.shouldShow(value);
    });
    this.postEventForm.get('ss_q2').valueChanges.subscribe(value => {
        this.ssq2show = this.shouldShow(value);
    });
    this.postEventForm.get('ss_q3').valueChanges.subscribe(value => {
        this.ssq3show = this.shouldShow(value);
    });
    this.postEventForm.get('ss_q4').valueChanges.subscribe(value => {
        this.ssq4show = this.shouldShow(value);
    });
    this.postEventForm.get('ss_q5').valueChanges.subscribe(value => {
        this.ssq5show = this.shouldShow(value);
    });
    this.postEventForm.get('ss_q6').valueChanges.subscribe(value => {
        this.ssq6show = this.shouldShow(value);
    });
    this.postEventForm.get('ss_q7').valueChanges.subscribe(value => {
        this.ssq7show = this.shouldShow(value);
    });
    this.postEventForm.get('ss_q8').valueChanges.subscribe(value => {
        this.ssq8show = this.shouldShow(value);
  });
  }

  fetchPostEvent() {
    if (this.isSeries) {
      this.postEventService.getPostEventByEventDate(this.eventId, this.eventDateId).subscribe(postEvent => {
        this.postEvent = postEvent;
        this.assignPostEventToForm();
        this.loading = false;
      });
    } else {
      this.postEventService.getPostEventsByEvent(this.eventId).subscribe(postEvents => {
        this.postEvent = postEvents && postEvents.length && postEvents[0];
        this.assignPostEventToForm();
        this.loading = false;
      });
    }
  }

  assignPostEventToForm() {
    if (this.postEvent) {
      Object.keys(this.postEvent).forEach(key => {
        if (Object.keys(this.f).includes(key)) {
          this.f[key].setValue(this.booleanToYesNo(this.postEvent[key]));
        }
      });
      console.log(this.f);
    }
  }

  setPaperworkDisableStates() {
    this.disableOccurrencePaperwork = this.postEvent.post_event_number_of_occurrences === 0 || this.occurrenceReports.length > 0;
    this.disableDisciplinePaperwork = this.disciplineReports.length > 0;
  }

  onSubmit(event: string) {
    // console.log(`Submitted (${this.postEventForm.status}): `, this.f);
    this.submitted = true;

    // Stop here if form is invalid
    console.log(this.postEventForm.invalid)
    if (this.postEventForm.invalid) {
      this.handleValidationFeedback();
      return;
    }

    const pef = this.postEventForm.value;
    const data = new PostEvent(pef);
    
    Object.keys(pef).forEach(key => {
      const value = pef[key];
      data[key] = value;
    });

    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
    if (this.formMode === 'update') {
      this.postEventService.updatePostEventSafeSport(this.postEventId, data).subscribe(resp => {
        if (resp) { this.redirect(event); }
      });
    }
  }

  private handleValidationFeedback(): void {
    let fragment = '';
    const formControlSections = this.postEventService.part2FormControlSections;
    const invalidControls = Object.entries(this.f).filter(([key, control]: [string, AbstractControl]) => control.invalid);
    for (let i = 0; i < formControlSections.length; i++) {
      fragment = this.searchInvalidControls(i, formControlSections[i], invalidControls);
      if (fragment) {
        break;
      }
    }

    if (fragment) {
      document.getElementById(fragment).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

  private searchInvalidControls(sectionIndex: number, formControlSection: string[], invalidControls: [string, AbstractControl][]): string {
    const sections = [ 'registration', 'conduct', 'technical', 'medical', 'disciplinary', 'prizes' ];
    for (const controlName of formControlSection) {
      if (invalidControls.some(control => control[0] === controlName)) {
        return sections[sectionIndex];
      }
    }
    return '';
  }

  yesOrNo(value) {
    return value === 'Y' || value === 'N';
  }

  yesNoBoolean(value) {
    return value === 'Y';
  }

  booleanToYesNo(value) {
    if (typeof(value) === 'boolean') {
      return value ? 'Y' : 'N';
    } else {
      return value;
    }
  }

  label(key: string) {
    return this.postEventService.part1Labels[key];
  }

  redirect(event: string) {
    if (event === 'save-exit') {
      this.toOverview();
    } else if (event === 'save-continue') {
      this.toPart3();
    } else if (event === 'save-continue-ss') {
      this.toPart2();
    }
  }

  get part1Url() {
    const base = this.urlSegments.slice(0, -1);
    base.push('part-1');
    return base.join('/');
  }

  get part2Url() {
    const base = this.urlSegments.slice(0, -1);
    base.push('part-2');
    return base.join('/');
  }

  get part3Url() {
    const base = this.urlSegments.slice(0, -1);
    base.push('part-3');
    return base.join('/');
  }

  toOverview() {
    this.router.navigate([`event/${this.event.event_id}/overview`], {fragment: 'post-event'});
  }

  toPart1() {
    this.router.navigate([this.part1Url]);
  }

  toPart2() {
    this.router.navigate([this.part2Url]);
  }

  toPart3() {
    this.router.navigate([this.part3Url]);
  }

}
