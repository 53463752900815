/* tslint:disable no-use-before-declare */
import { OnInit, EventEmitter } from '@angular/core';
import { FormFieldErrors } from '../../classes';
var noop = function () { };
var ɵ0 = noop;
var MultiSelectComponent = /** @class */ (function () {
    function MultiSelectComponent() {
        this.currentValue = [];
        this.dropDownSettings = {};
        this.defaultSettings = {
            singleSelection: false,
            idField: 'value',
            textField: 'label',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 10,
            allowSearchFilter: true
        };
        this.settings = {};
        this.onTouchedCallback = noop;
        this.onChangeCallback = noop;
        this.change = new EventEmitter();
        this.valueChange = new EventEmitter();
        this.filterChange = new EventEmitter();
        this.dropDownClose = new EventEmitter();
        this.select = new EventEmitter();
        this.deSelect = new EventEmitter();
        this.selectAll = new EventEmitter();
        this.deSelectAll = new EventEmitter();
    }
    Object.defineProperty(MultiSelectComponent.prototype, "value", {
        get: function () {
            return this.currentValue;
        },
        set: function (val) {
            this.currentValue = val;
            this.change.emit(this.currentValue);
            this.valueChange.emit(this.currentValue);
            this.onChangeCallback(this.currentValue);
        },
        enumerable: true,
        configurable: true
    });
    MultiSelectComponent.prototype.writeValue = function (value) {
        this.currentValue = value;
        this.onChangeCallback(value);
        // for (const option of this.options) {
        //   if (this.currentValue.includes(option.value)) {
        //     option.checked = true;
        //   }
        // }
    };
    // From ControlValueAccessor interface
    MultiSelectComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    // From ControlValueAccessor interface
    MultiSelectComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    MultiSelectComponent.prototype.ngOnInit = function () {
        this.dropDownSettings = Object.assign({}, this.defaultSettings, this.settings);
    };
    return MultiSelectComponent;
}());
export { MultiSelectComponent };
export { ɵ0 };
