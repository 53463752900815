<div class="container-fluid">
    <div *ngIf="alerts.length > 0" style="margin-left:-.75rem; margin-right:-.75rem;">
      <app-alert [alerts]="alerts"></app-alert>
    </div>
</div>
<div *ngIf="event" class="container event-overview">
  <div class="row justify-content-between align-items-center event-overview-bar">
    <div class="d-flex align-items-center">
      <a [routerLink]="'/queue'" class="primary-blue">
        <h2 class="icon icon-arrow-left"></h2>
      </a>
      <h3 class="ml-2 primary-blue">{{event.event_name}}</h3>
    </div>
    <h4 class="ml-3 primary-blue" style="font-weight: 100 !important;">Type: {{eventTypeText()}}</h4>
    <div class="row">
      <form [formGroup]="statusForm" (ngSubmit)="onSubmit()" style="display:flex;padding-right:0px;" class="offset-md-1 col-md-10 align-items-center">
        <h4 class="mr-2 p-0 mb-0 primary-blue">{{statusText}}</h4>
        <app-select
          formControlName="status"
          [name]="'status'"
          [blue]="true"
          [options]="statusOptions"
          [disabled]="f.status.disabled"
          [formSubmitted]="submitted"
          [errors]="f.status.errors"
          (valueChange)="onSubmit()"
          style="width:220px;"
        ></app-select>
        <p *ngIf="showStatusError" class="status-error small text-danger">Error occurred</p>
      </form>
    </div>
  </div>
  <!-- Event Close & Cancel Modals -->
  <div class="row cancel-btn-div">
    <div class="col-12">
      <app-close-cancel-event *ngIf="event && userRole && isUsacAdmin"
        [event]="event"
        [mode]="'close'"
        [open]="closeCancelModalOpen"
        [outstandingPaperwork]="outstandingPaperwork"
        [outstandingPayments]="outstandingPayments"
        [incompleteForms]="incompleteForms"
        (exit)="closeStatusModal($event)"
      ></app-close-cancel-event>
      <app-close-cancel-event *ngIf="event && userRole && allowCancel()"
        [event]="event"
        [mode]="'cancel'"
      ></app-close-cancel-event>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col-lg-8 info-card" id="summary-overview">
      <div class="line-bottom">
        <h4>Summary</h4>
      </div>
      <div class="row pt-3">
        <div class="col-sm-6 col-md-4 col-lg-3">
          <h5>Event Dates</h5>
          <p>{{event.event_start_date | date:'longDate':'UTC'}} - {{event.event_end_date | date:'longDate':'UTC'}}</p>
          <!-- <h5>Event Location</h5>
          <p style="white-space: pre-wrap;">{{event.location}}</p> -->
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <h5>Discipline</h5>
          <p *ngIf="event.event_discipline">{{disciplineName}}</p>
          <h5>Local Association</h5>
          <p>{{laText()}}</p>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <h5>Event ID</h5>
          <p>{{event.event_id}}</p>
          <h5>Permit ID</h5>
          <p>{{permitNumber}}</p>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <h5>Date Created</h5>
          <p>{{event.created_at | date:'longDate'}}</p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 info-card" id="contact-overview">
      <div class="line-bottom">
        <h4>Contacts</h4>
      </div>
      <div class="row">
        <div class="col-md-4 col-lg-6">
          <h5>Race Director</h5>
          <p>{{organizerName}}</p>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-12">
          <h5>Email</h5>
          <p style="overflow-wrap: break-word;">{{organizerEmail}}</p>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-6">
          <h5>Phone</h5>
          <p>{{organizerPhone}}</p>
        </div>
        <div *ngIf="isUsacAdmin" class="col-sm-6 col-md-4 col-lg-12 line-top">
          <h5>Admin Actions</h5>
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" [checked]="event.is_pended_outstanding_permit_fee" (change)="toggleOutstandingPermitFee()" id="outstanding-pending-fee-switch">
            <label class="custom-control-label" for="outstanding-pending-fee-switch">Suspend Race Director Access</label>
          </div>
        </div>
      </div>
      <div *ngFor="let contact of event.event_contacts" class="row mt-2">
        <div class="col-md-4 col-lg-6">
          <h5>Local Contact</h5>
          <p>{{contact.contact_first_name}} {{contact.contact_last_name}}</p>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-6">
          <h5>Phone</h5>
          <p>{{contact.contact_phone_1}}</p>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-12">
          <h5>Email</h5>
          <p style="overflow-wrap: break-word;">{{contact.contact_email_1}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3 mb-2">
    <div class="col-12 pl-1">
      <p class="font-weight-bold">Once the permit application is complete, please allow 7-14 business days for permit approval and insurance documents.</p>
    </div>
  </div>

  <!-- Tabbed Section -->
  <!-- Primary Tabs -->
  <div class="row mb-5">
    <ngb-tabset [justify]="'justified'" [activeId]="activeLevel1" class="tabs-primary col-12 px-0">
      <ngb-tab title="Overview" [id]="'overview'">
        <ng-template ngbTabContent>
          <ng-container *ngTemplateOutlet="overviewTabset"></ng-container>
        </ng-template>
      </ngb-tab>
      <ngb-tab title="Pre-Event" [id]="'pre-event'">
        <ng-template ngbTabContent>
          <ng-container *ngTemplateOutlet="preEventTabset"></ng-container>
        </ng-template>
      </ngb-tab>
      <ngb-tab *ngIf="isPermit" title="Post Event" [id]="'post-event'">
        <ng-template ngbTabContent>
          <div class="tab-content-container">
            <app-overview-post-event
              [event]="event"
              [eventDates]="eventDates"
              [postEvents]="postEvents"
              [draftOrders]="draftOrders"
              [orders]="orders"
              [userRole]="userRole"
              [currentUser]="currentUser"
              (refreshPostEvents)="getPostEvents()"
            ></app-overview-post-event>
          </div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>

    <!-- Overview Tabs -->
    <ng-template #overviewTabset>
      <ngb-tabset class="tabs-secondary" [activeId]="activeLevel2" [orientation]="tabOrientation" (window:resize)="onResize($event)">
        <ngb-tab title="Notifications" [id]="'notifications'">
          <ng-template ngbTabContent>
            <div class="tab-content-container">
              <app-overview-notifications
                [event]="event"
              ></app-overview-notifications>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab *ngIf="showComments" title="Comments" [id]="'comments'">
          <ng-template ngbTabContent>
            <div class="tab-content-container">
              <app-overview-comments
                [event]="event"
                [userRole]="userRole"
              ></app-overview-comments>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab *ngIf="showPayments" title="Payments" [id]="'payments'">
          <ng-template ngbTabContent>
            <div class="tab-content-container">
              <app-overview-payments
                [event]="event"
                [userRole]="userRole"
                [draftOrders]="draftOrders"
                [orders]="orders"
              ></app-overview-payments>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab title="Documents" [id]="'documents'">
          <ng-template ngbTabContent>
            <div class="tab-content-container">
              <app-event-docs
                [event]="event"
              ></app-event-docs>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab title="History" [id]="'history'">
          <ng-template ngbTabContent>
            <div class="tab-content-container">
              <app-overview-history
                [event]="event"
              ></app-overview-history>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </ng-template>

    <!-- Pre-Event Tabs -->
    <ng-template #preEventTabset>
      <ngb-tabset class="tabs-secondary" [activeId]="activeLevel2" [orientation]="tabOrientation" (window:resize)="onResize($event)">
        <ngb-tab title="Event Listing" [id]="'event-listing'">
          <ng-template ngbTabContent>
            <div class="tab-content-container">
              <div class="row align-items-center justify-content-between no-gutters space-above-lg bg-gray-1">
                <div class="col"><h3>Event Listing</h3></div>
                <div class="col">
                  <app-button
                    *ngIf="showListingEditBtn"
                    [mode]="'button'"
                    [type]="'btn-secondary'"
                    (click)="toListing()">Edit</app-button>
                </div>
              </div>

              <!-- Loader -->
              <app-loader *ngIf="eventLoading" [small]="true" [align]="'left'"></app-loader>

              <div class="row">
                <app-listing-body *ngIf="event && !eventLoading"
                  class="col-12"
                  [event]="event"
                  [qrUri]="qrUri"
                ></app-listing-body>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab *ngIf="!isVirtual" title="Permit Application" [id]="'permit-application'">
          <ng-template ngbTabContent>
            <div class="tab-content-container">

              <div *ngIf="!isPermit" class="card light border-1 mt-3">
                <div class="card-header">
                  <div class="row header-row">
                    <h4 class="col-12">Event has not been permitted.</h4>
                    <app-button
                      class="col-12"
                      [mode]="'button'"
                      (click)="toPermit()"
                    >Permit Your Event</app-button>
                  </div>
                </div>
              </div>

              <div *ngIf="isPermit" class="d-flex align-items-center justify-content-between">
                  <h4>Permit Application</h4>
                  <app-button
                    *ngIf="showPermitEditBtn"
                    [type]="'btn-secondary'"
                    [link]="''"
                    (click)="toPermit()"
                  >Edit Permit</app-button>
              </div>

              <!-- Loader -->
              <app-loader *ngIf="permitLoading" [small]="true" [align]="'left'"></app-loader>

              <app-pre-event-permit-application *ngIf="isPermit && !permitLoading && permit.answers.length"
                [event]="event"
                [userRole]="userRole"
                [retrievedAnswers]="permit.answers"
              ></app-pre-event-permit-application>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab *ngIf="showEventIncentiveTab" title="Calendar Labels" [id]="'event-incentives'">
          <ng-template ngbTabContent>
            <div class="tab-content-container">
              <app-event-incentives-review
                [calendarLabels]="calendarLabels"
                [calendarLabelQuestions]="calendarLabelQuestions"
                [calendarLabelAnswers]="calendarLabelAnswers"
                [event]="event"
              ></app-event-incentives-review>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab *ngIf="isPermit" title="Insured Persons" [id]="'insured-persons'">
          <ng-template ngbTabContent>
            <div class="tab-content-container">
              <app-pre-event-insurance
                [event]="event"
                [userRole]="userRole"
              ></app-pre-event-insurance>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab *ngIf="isPermit" title="Permit Add-Ons" [id]="'permit-add-ons'">
          <ng-template ngbTabContent>
            <div class="tab-content-container">
              <app-pre-event-add-ons
                [event]="event"
                [currentUser]="currentUser"
                [userRole]="userRole"
                [orders]="orders"
              ></app-pre-event-add-ons>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab *ngIf="isPermit" title="Referee Assignment" [id]="'referee-assignment'">
          <ng-template ngbTabContent>
            <div class="tab-content-container">
              <app-pre-event-ref-assignment
                [event]="event"
                [eventDates]="eventDates"
                [userRole]="userRole"
              ></app-pre-event-ref-assignment>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </ng-template>
  </div>
</div>
