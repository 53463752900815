import * as qs from 'qs';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ReportsService, TransactionsReportQueryParams, AssignmentsReportQueryParams,  } from '../../services/reports.service';
import { CsvDownloadService } from '../../shared/services';
import { ProfileService } from 'src/app/services';
import { Alert } from 'src/app/classes';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: []
})
export class ReportsComponent implements OnInit {
  activeLevel1: string;
  tabOrientation = 'horizontal';
  breakpoint = 768;
  transactionReport: any;
  assignmentReport: any;
  isLoadingTransactions = false;
  isLoadingAssignments = false;

  userRole: string;
  get isUsacAdmin() {
    return this.userRole === 'usac_admin';
  }

  alert = [
    new Alert('warning', 'No data found. Please adjust your search criteria.', null, null, null, null, null, false)
  ];

  get tr() { return this.transactionReport.controls; }
  get ar() { return this.assignmentReport.controls; }

  constructor(
    private fb: FormBuilder,
    private reports: ReportsService,
    private csvService: CsvDownloadService,
    private profileService: ProfileService
  ) {
    this.transactionReport = this.fb.group({
      event_start_date: [''],
      event_end_date: [''],
      organizer_comp_id: [''],
      organizer_first: [''],
      organizer_last: ['']
    });

    this.assignmentReport = this.fb.group({
      event_start_date: [''],
      event_end_date: [''],
      event_id : [''],
      event_zip: [''],
      states: [''],
      chief_ref_first: [''],
      chief_ref_last : [''],
      chief_ref_profile_id: ['']
    });
  }

  ngOnInit() {

    this.setTabOrientation(window.innerWidth);
    this.profileService.getCurrentUser().subscribe(profile => {
      this.profileService.getUserPermissions().subscribe(permissions => {
        this.userRole = this.profileService.setUserRole(permissions);
        // Scope requested data to current event organizer if non-admin
        if (!this.isUsacAdmin) {
          this.tr.organizer_first.setValue(profile.profile_first_name);
          this.tr.organizer_last.setValue(profile.profile_last_name);
          // Fallback for when Comp ID is phased out
          if (profile.profile_comp_id) { this.tr.organizer_comp_id.setValue(profile.profile_comp_id); }
        }
      });
    });

    // console.log('stuff', this.isLoadingAssignments, this.isLoadingTransactions);
  }

  onResize(event) {
    this.setTabOrientation(event.target.innerWidth);
  }

  setTabOrientation(width) {
    this.tabOrientation = width >= this.breakpoint ? 'horizontal' : 'vertical';
  }

  clearError() {
    this.csvService.displayError = false;
  }

  getTransactionsReportParams(): TransactionsReportQueryParams {
    console.log('form data', this.transactionReport.value);

    const {
      event_start_date,
      event_end_date,
      organizer_comp_id,
      organizer_first,
      organizer_last
    } = this.transactionReport.value;

    const params = {
      checkout_date_range: {
        start_date: event_start_date,
        end_date: event_end_date
      },
      no_paginate: true,
      organizer_comp_id,
      organizer_first,
      organizer_last
    };

    console.log('params', params);

    return params;
  }

  getAssignmentsReportParams(): AssignmentsReportQueryParams {
    const params = this.assignmentReport.value;

    Object.assign(params, {
      no_paginate: true,
    });

    return params;
  }

  onTransactionReportSubmit() {
    this.isLoadingTransactions = true;
    this.clearError();

    this.reports.getTransactions(this.getTransactionsReportParams()).subscribe(data => {
      console.log('loaded transactions reports', data);

      data = data.map(row => {
        if (row.event_organizer) {
          const {
            profile_email,
            profile_first_name,
            profile_last_name
          } = row.event_organizer;

          row.event_organizer = `${profile_first_name} ${profile_last_name} <${profile_email}>`;
        }

        return row;
      });

      console.log('translated organizers for transactions reports', data);

      this.csvService.downloadFile(data, 'USACycling-TransactionsReport.csv');

      this.isLoadingTransactions = false;
    });
  }

  onAssignmentReportSubmit() {
    this.isLoadingAssignments = true;
    this.clearError();

    this.reports.getOfficialAssignments(this.getAssignmentsReportParams()).subscribe(data => {
      console.log('loaded offical assignments reports', data);

      data = data.map(row => {
        if (row.event && row.event.event_name) {
          row.event = row.event.event_name;
        }

        if (row.chief_ref) {
          const {
            profile_email,
            profile_first_name,
            profile_last_name
          } = row.chief_ref;

          row.chief_ref = `${profile_first_name} ${profile_last_name} <${profile_email}>`;
        }

        return row;
      });

      console.log('translated chief ref & event for official assignments report', data);

      this.csvService.downloadFile(data, 'USACycling-OfficialAssignmentsReport.csv');

      this.isLoadingAssignments = false;
    });
  }

}
