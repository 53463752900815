export class EventDay {
  id: number|string;
  date: Date|string;
  type: string;
  event_id: number|string;
  created_by: string;

  constructor(data) {
    this.id = data.id;
    this.date = data.date;
    this.type = data.type;
    this.event_id = data.event_id;
    this.id = data.id;
  }
}
