import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationStart } from '@angular/router';
import { PermitService } from 'src/app/services';
import { Event, } from 'src/app/classes';
var EventIncentivesFormComponent = /** @class */ (function () {
    function EventIncentivesFormComponent(fb, permitService, router) {
        this.fb = fb;
        this.permitService = permitService;
        this.router = router;
        this.labelQuestionMap = {};
        this.labelsFormSubmitted = false;
        this.questionsFormSubmitted = false;
        this.submitting = false;
        this.saveAndExitSubmitting = false;
        this.showSaveExit = false;
        this.navUrl = '';
        this.disableSaveAndExitCatch = false;
        this.selectedLabelIds = [];
        this.answers = [];
    }
    Object.defineProperty(EventIncentivesFormComponent.prototype, "lf", {
        get: function () { return this.labelsForm.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventIncentivesFormComponent.prototype, "qf", {
        get: function () { return this.questionsForm.controls; },
        enumerable: true,
        configurable: true
    });
    EventIncentivesFormComponent.prototype.ngOnInit = function () {
        this.buildLabelsForm();
        this.lf.labels.setValue(this.selectedLabelIds);
        this.subscribeToLabelsForm();
        this.buildQuestionsForm();
        this.subscribeToQuestionsForm();
        this.subscribeToRouterEvents();
    };
    EventIncentivesFormComponent.prototype.ngOnDestroy = function () {
        this.routerEvents.unsubscribe();
        this.closeSaveExitModal(false);
    };
    EventIncentivesFormComponent.prototype.buildLabelsForm = function () {
        var _this = this;
        this.calendarLabels.forEach(function (label) { return _this.labelQuestionMap[label.id] = []; });
        this.labelsForm = this.fb.group({
            labels: []
        });
    };
    EventIncentivesFormComponent.prototype.subscribeToLabelsForm = function () {
        var _this = this;
        this.lf.labels.valueChanges.subscribe(function (labelIds) { return _this.selectedLabelIds = labelIds; });
    };
    EventIncentivesFormComponent.prototype.buildQuestionsForm = function () {
        var _this = this;
        // Build form based on active label questions (require answers before confirming selection)
        var formControls = {};
        this.calendarLabelQuestions.forEach(function (question) {
            var existingAnswer = _this.answers.find(function (answer) { return answer.question_id === question.id; });
            var controlName = _this.controlName(question);
            formControls[controlName] = existingAnswer ? existingAnswer.answer : false;
            // Generate details control (textarea), if applicable
            if (question.textbox) {
                formControls[_this.detailsControlName(question)] = existingAnswer ? existingAnswer.details : null;
            }
        });
        this.questionsForm = this.fb.group(formControls);
    };
    EventIncentivesFormComponent.prototype.subscribeToQuestionsForm = function () {
        var _this = this;
        // Subscribe to checkboxes for questions requiring textboxes, to update details control validators/values
        this.questionsWithTextbox.forEach(function (question) {
            var controlName = _this.controlName(question);
            _this.qf[controlName].valueChanges.subscribe(function (value) {
                var detailsControl = _this.qf[controlName + "-details"];
                if (detailsControl) {
                    detailsControl.setValidators(value ? [Validators.required] : null);
                    // Reset detailsControl value to null if question not checked
                    if (!value) {
                        detailsControl.setValue(null);
                    }
                }
            });
        });
        Object.entries(this.qf).forEach(function (_a) {
            var controlName = _a[0], control = _a[1];
            var question = _this.questionFromControlName(controlName);
            if (question && question.label_id) {
                control.valueChanges.subscribe(function (value) { return _this.handleLabelSelection(question, value); });
            }
        });
    };
    EventIncentivesFormComponent.prototype.handleLabelSelection = function (question, selected) {
        var labelId = question.label_id;
        var selectedQuestions = this.labelQuestionMap[labelId];
        if (selected) {
            selectedQuestions.push(question);
        }
        else {
            var questionIndex = selectedQuestions.findIndex(function (q) { return q.id === question.id; });
            if (questionIndex > -1) {
                selectedQuestions.splice(questionIndex, 1);
            }
        }
        var labelIds = this.lf.labels.value;
        var labelIndex = labelIds.findIndex(function (id) { return id === labelId; });
        if (selectedQuestions.length && labelIndex === -1) {
            this.lf.labels.setValue(labelIds.concat([labelId]));
        }
        else if (!selectedQuestions.length && labelIndex > -1) {
            labelIds.splice(labelIndex, 1);
            this.lf.labels.setValue(labelIds);
        }
    };
    EventIncentivesFormComponent.prototype.subscribeToRouterEvents = function () {
        var _this = this;
        this.routerEvents = this.router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                var routeExceptions = [_this.permitPart2Link, _this.insuredCertsLink, _this.overviewLink];
                if (!routeExceptions.includes(event.url) && _this.navUrl === '') {
                    if (!_this.disableSaveAndExitCatch) {
                        _this.router.navigateByUrl(_this.router.url, { replaceUrl: true });
                        _this.openSaveExitModal(event.url);
                    }
                }
            }
        });
    };
    EventIncentivesFormComponent.prototype.goBack = function () {
        this.router.navigate([this.permitPart2Link]);
    };
    EventIncentivesFormComponent.prototype.openSaveExitModal = function (url) {
        if (url) {
            this.navUrl = url;
        }
        this.showSaveExit = true;
    };
    EventIncentivesFormComponent.prototype.closeSaveExitModal = function (resetNavUrl) {
        this.showSaveExit = false;
        this.saveAndExitSubmitting = false;
        if (resetNavUrl) {
            this.navUrl = '';
        }
    };
    EventIncentivesFormComponent.prototype.continueNavigation = function (url) {
        var _this = this;
        if (url === void 0) { url = this.navUrl; }
        this.closeSaveExitModal(false);
        setTimeout(function () {
            _this.navUrl = url || _this.insuredCertsLink;
            _this.router.navigate([_this.navUrl]);
        }, 50);
    };
    EventIncentivesFormComponent.prototype.saveAndExit = function () {
        this.onSubmit(true);
    };
    EventIncentivesFormComponent.prototype.onSubmit = function (saveAndExit) {
        var _this = this;
        if (saveAndExit === void 0) { saveAndExit = false; }
        this.questionsFormSubmitted = true;
        // stop here if form is invalid
        if (this.questionsForm.invalid) {
            return;
        }
        if (saveAndExit) {
            this.saveAndExitSubmitting = true;
        }
        else {
            this.submitting = true;
        }
        var questionAnswers = this.questionsForm.value;
        // Display alert when answering "No" to required questions
        var newAnswers = [];
        for (var _i = 0, _a = this.calendarLabelQuestions; _i < _a.length; _i++) {
            var question = _a[_i];
            newAnswers.push({
                question_id: question.id,
                answer: questionAnswers["question-" + question.id],
                details: questionAnswers["question-" + question.id + "-details"] || null
            });
        }
        this.answers = newAnswers;
        // Submit calendar labels & answers
        this.permitService.postCalendarLabels(this.selectedLabelIds, this.eventId).subscribe(function (labels) {
            if (labels) {
                _this.permitService.postCalendarLabelAnswers(_this.answers, _this.eventId).subscribe(function (answers) {
                    if (answers) {
                        if (saveAndExit) {
                            _this.navUrl = _this.navUrl === '' ? _this.overviewLink : _this.navUrl;
                            setTimeout(function () {
                                _this.disableSaveAndExitCatch = true;
                                _this.closeSaveExitModal(false);
                                _this.continueNavigation(_this.navUrl);
                            }, 50);
                        }
                        else {
                            // Route to insured certs
                            _this.router.navigate([_this.insuredCertsLink]);
                        }
                    }
                    else {
                        // Handle error
                        _this.resetSubmitting();
                    }
                });
            }
            else {
                // Handle error
                _this.resetSubmitting();
            }
        });
    };
    EventIncentivesFormComponent.prototype.resetSubmitting = function () {
        this.submitting = false;
        this.saveAndExitSubmitting = false;
    };
    EventIncentivesFormComponent.prototype.labelById = function (id) {
        return this.calendarLabels.find(function (label) { return label.id === id; });
    };
    EventIncentivesFormComponent.prototype.controlName = function (question) {
        return 'question-' + question.id;
    };
    EventIncentivesFormComponent.prototype.detailsControlName = function (question) {
        return this.controlName(question) + '-details';
    };
    EventIncentivesFormComponent.prototype.questionFromControlName = function (controlName) {
        return this.calendarLabelQuestions.find(function (question) { return question.id.toString() === controlName.split('-')[1]; });
    };
    EventIncentivesFormComponent.prototype.displayTextbox = function (question) {
        var checkboxControl = this.qf[this.controlName(question)];
        return question.textbox && !!checkboxControl.value;
    };
    Object.defineProperty(EventIncentivesFormComponent.prototype, "isRace", {
        get: function () {
            return this.event.isRace;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventIncentivesFormComponent.prototype, "isRide", {
        get: function () {
            return this.event.isRide;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventIncentivesFormComponent.prototype, "questionsWithTextbox", {
        get: function () {
            return this.calendarLabelQuestions.filter(function (question) { return !!question.textbox; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventIncentivesFormComponent.prototype, "faqLink", {
        get: function () {
            return 'https://s3.amazonaws.com/usac-craft-uploads-production/documents/2021-Event-Incentive-FAQ.pdf';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventIncentivesFormComponent.prototype, "insuredCertsLink", {
        get: function () {
            return "/event/" + this.eventId + "/insured-certificates";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventIncentivesFormComponent.prototype, "permitPart2Link", {
        get: function () {
            return "/event/" + this.eventId + "/permit-application/part-2";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventIncentivesFormComponent.prototype, "overviewLink", {
        get: function () {
            return "/event/" + this.eventId + "/overview";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventIncentivesFormComponent.prototype, "disableBtns", {
        get: function () {
            return this.submitting || this.saveAndExitSubmitting;
        },
        enumerable: true,
        configurable: true
    });
    return EventIncentivesFormComponent;
}());
export { EventIncentivesFormComponent };
