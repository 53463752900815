<!-- Loader -->
<app-loader *ngIf="loading"></app-loader>

<div class="row">
  <div *ngFor="let product of products" class="col-lg-6 mb-4" [ngClass]="{'hidden': loading}">
      <app-card [fullHeight]=true>
        <app-product *ngIf="productVariantsLength && productVariants[product.id]"
          [draftOrder]="draftOrder"
          [product]="product"
          [productImage]="productImages[product.id]"
          [variants]="productVariants[product.id]"
          [discipline]="discipline"
          [eventDates]="eventDates"
          (init)="setProductsInitialized($event)"
          (load)="setProductsLoaded($event)"
        ></app-product>
      </app-card>
  </div>
</div>
<div *ngIf="!loading" class="row space-above-lg">
  <app-button
    [type]="'btn-light'"
    (click)="toInsuredCerts()"
  >Go Back</app-button>
</div>
