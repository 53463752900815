import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

import { BaseService } from './base-service';
import { Comment, SelectOption } from '../classes';

@Injectable({
  providedIn: 'root'
})
export class CommentService extends BaseService {
  public usacAdminRecipients: Array<SelectOption> = [];
  public laAdminRecipients: Array<SelectOption> = [];
  public raceDirectorRecipients: Array<SelectOption> = [];

  constructor(
    protected http: HttpClient,
    protected cookie: CookieService
  ) {
    super(http, cookie);

    this.usacAdminRecipients = [
      new SelectOption('all', 'All'),
      new SelectOption('usac_admin', 'USAC Admin Only'),
      new SelectOption('la_usac_admin', 'LA + USAC Admin'),
      new SelectOption('rd_usac_admin', 'Race Director + USAC Admin'),
    ];

    this.laAdminRecipients = [
      new SelectOption('all', 'All'),
      new SelectOption('la_usac_admin', 'LA + USAC Admin')
    ];

    this.raceDirectorRecipients = [
      new SelectOption('all', 'All'),
      new SelectOption('rd_usac_admin', 'Race Director + USAC Admin')
    ];
  }

  getCommentsForEvent(eventId: number|string): Observable<Comment[]> {
    return this.http.get<Comment[]>(this.url(`comment/event/${eventId}`), this.options)
      .pipe(
        tap(_ => this.log('fetched comments')),
        catchError(this.handleError<Comment[]>('getCommentsForEvent', []))
      );
  }

  addCommentForEvent(eventId: number|string, comment: Comment): Observable<Comment> {
    return this.http.post(this.url(`comment/event/${eventId}`), comment, this.options)
      .pipe(
        tap((newComment: Comment) => this.log(`added comment id=${newComment.comment_id}`)),
        catchError(this.handleError<Comment>('addCommentForEvent', new Comment('', ''), { eventId, comment }))
      );
  }
}
