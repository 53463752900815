import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Event } from 'src/app/classes';
import { EventService } from 'src/app/services';
var EventIncentivesReviewComponent = /** @class */ (function () {
    function EventIncentivesReviewComponent(eventService, fb, router) {
        this.eventService = eventService;
        this.fb = fb;
        this.router = router;
        this.modalOpen = false;
        this.isPostEvent = false;
        this.eventIncentiveDiscountQualification = new EventEmitter();
        this.labelDiscountForm = this.fb.group({
            event_label_discount: null
        });
    }
    Object.defineProperty(EventIncentivesReviewComponent.prototype, "discountControl", {
        get: function () { return this.labelDiscountForm.controls.event_label_discount; },
        enumerable: true,
        configurable: true
    });
    EventIncentivesReviewComponent.prototype.ngOnInit = function () {
        this.qualifiesForDiscount = false;
        this.prefillForm();
        // this.subscribeToForm();
    };
    EventIncentivesReviewComponent.prototype.prefillForm = function () {
        this.discountControl.setValue(this.qualifiesForDiscount);
        this.emitDisountQualification();
    };
    // change for PERM-210 
    // subscribeToForm(): void {
    //   this.discountControl.valueChanges.subscribe(value => {
    //     const discountBody = { event_label_discount: value };
    //     this.eventService.updateEventDiscounts(this.event.event_id, discountBody).subscribe(() => {
    //       this.qualifiesForDiscount = value;
    //       this.emitDisountQualification();
    //     });
    //   });
    // }
    EventIncentivesReviewComponent.prototype.emitDisountQualification = function () {
        this.eventIncentiveDiscountQualification.emit(this.qualifiesForDiscount);
    };
    EventIncentivesReviewComponent.prototype.openModal = function () {
        this.modalOpen = true;
    };
    EventIncentivesReviewComponent.prototype.closeModal = function () {
        this.modalOpen = false;
    };
    EventIncentivesReviewComponent.prototype.answerByQuestion = function (question) {
        return this.calendarLabelAnswers.find(function (answer) { return answer.question_id === question.id; });
    };
    EventIncentivesReviewComponent.prototype.answerDetails = function (question) {
        var answer = this.answerByQuestion(question);
        return answer ? answer.details : '';
    };
    EventIncentivesReviewComponent.prototype.answerClass = function (question) {
        return !!this.answerByQuestion(question) ? 'text-success' : 'text-danger';
    };
    EventIncentivesReviewComponent.prototype.toIncentives = function () {
        this.router.navigate(["/event/" + this.event.event_id + "/event-incentives"]);
    };
    Object.defineProperty(EventIncentivesReviewComponent.prototype, "loading", {
        get: function () {
            return !this.calendarLabels || !this.calendarLabelQuestions || !this.calendarLabelAnswers;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventIncentivesReviewComponent.prototype, "hasCalendarLabels", {
        get: function () {
            return !!this.calendarLabels && !!this.calendarLabels.length && !!this.calendarLabelAnswers;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventIncentivesReviewComponent.prototype, "hasCalendarLabelAnswers", {
        get: function () {
            return this.calendarLabelAnswers && !!this.calendarLabelAnswers.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventIncentivesReviewComponent.prototype, "labelQuantity", {
        get: function () {
            return this.calendarLabels.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventIncentivesReviewComponent.prototype, "labelNames", {
        get: function () {
            return this.calendarLabels.map(function (label) { return label.name; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventIncentivesReviewComponent.prototype, "labelMessage", {
        get: function () {
            var discountMessage = this.qualifiesForDiscount
                ? " and qualifies for a discount ($" + this.eventIncentivesDiscountAmt.toFixed(2) + " per rider fee),\n        provided the event minimum rider surcharge of $300 has been met"
                : '';
            var labels;
            if (this.labelQuantity > 2) {
                var lastIndex = this.labelQuantity - 1;
                labels = this.labelNames.slice(this.labelQuantity - 1).join(', ') + ', and ' + this.labelNames[lastIndex];
            }
            else {
                labels = this.labelNames.join(' and ');
            }
            return "This event was labeled " + labels + discountMessage + ".";
        },
        enumerable: true,
        configurable: true
    });
    return EventIncentivesReviewComponent;
}());
export { EventIncentivesReviewComponent };
