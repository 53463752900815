import { OnInit, EventEmitter } from '@angular/core';
import { MerchantAccountTag, ShopifyService } from '../../shared';
import { Event, ShopifyOrder, ShopifyDraftOrder, ShopifyDiscount } from 'src/app/classes';
import { EventDayService } from 'src/app/services';
var PaymentAccordionComponent = /** @class */ (function () {
    function PaymentAccordionComponent(eventDayService, shopifyService) {
        this.eventDayService = eventDayService;
        this.shopifyService = shopifyService;
        this.deleteDraftOrder = new EventEmitter();
        this.open = false;
        this.toggleIcon = 'icon-chevron-down';
        this.modalOpen = false;
        this.tosModalOpen = false;
        this.orderTransactions = [];
    }
    Object.defineProperty(PaymentAccordionComponent.prototype, "refunds", {
        get: function () { return this.orderTransactions.filter(function (transaction) { return transaction.kind === 'refund'; }); },
        enumerable: true,
        configurable: true
    });
    PaymentAccordionComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.order) {
            console.log("Order #" + this.order.id, this.order);
            this.shopifyService.getShopifyOrderTransactions({ order_id: this.order.id }).subscribe(function (transactions) {
                _this.orderTransactions = transactions;
            });
        }
    };
    Object.defineProperty(PaymentAccordionComponent.prototype, "isUsacAdmin", {
        get: function () { return this.userRole === 'usac_admin'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "paidOrFullRefund", {
        get: function () {
            return this.order && (this.order.financial_status === 'paid' ||
                this.order.financial_status === 'refunded' ||
                this.order.financial_status === 'authorized');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "pendingOrPartial", {
        get: function () {
            return this.order && (this.order.financial_status === 'pending' ||
                this.order.financial_status === 'partially_paid' ||
                this.order.financial_status === 'partially_refunded');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "voided", {
        get: function () { return this.order && this.order.financial_status === 'voided'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "refund", {
        get: function () {
            return this.refunds.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "paidStatus", {
        get: function () {
            if (this.order) {
                return this.order.financial_status;
            }
            else {
                return 'Payment Due';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "subtotalPrice", {
        get: function () {
            return this.order ? this.order.subtotal_price : this.draftOrder.subtotal_price;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "tax", {
        get: function () {
            return this.order ? this.order.total_tax : this.draftOrder.total_tax;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "totalPrice", {
        get: function () {
            return this.order ? this.order.total_price : this.draftOrder.total_price;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "lineItems", {
        get: function () {
            return this.order ? this.order.line_items : this.draftOrder.line_items;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "title", {
        get: function () { return this.order ? "Order " + this.order.name : "Pending " + this.draftOrder.name; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "displayDate", {
        get: function () {
            var date = this.order ? this.order.processed_at : this.draftOrder.created_at;
            return typeof date === 'string' ? new Date(date.replace(/-/g, '/')) : date;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "paidByCard", {
        get: function () { return this.order.gateway === 'orbital'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "paymentMethod", {
        get: function () { return this.paidByCard ? 'Credit/Debit' : this.order.gateway; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "cardDetails", {
        get: function () {
            if (this.orderTransactions.length) {
                var paymentDetails = this.orderTransactions[0].payment_details;
                return paymentDetails.credit_card_company + ' - ' + paymentDetails.credit_card_number.match(/\d{4}/)[0];
            }
            else {
                return 'N/A';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "totalRefunded", {
        get: function () {
            var total = 0;
            this.refunds.forEach(function (refund) {
                total += refund.amount;
            });
            return total;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "processedAt", {
        get: function () {
            return this.order.processed_at;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "totalDiscount", {
        get: function () {
            if (this.order) {
                return this.order.total_discounts;
            }
            else {
                var orderDiscount = this.draftOrder.applied_discount ? parseFloat(this.draftOrder.applied_discount.amount) : 0;
                var lineItemDiscounts_1 = 0;
                this.draftOrder.line_items.forEach(function (item) {
                    if (item.applied_discount) {
                        lineItemDiscounts_1 += parseFloat(item.applied_discount.amount);
                    }
                });
                return orderDiscount + lineItemDiscounts_1;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "hasDiscounts", {
        get: function () {
            if (this.order) {
                return !!this.order.discount_applications.length;
            }
            else {
                return !!this.draftOrder.applied_discount || this.draftOrder.line_items.some(function (item) { return !!item.applied_discount; });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentAccordionComponent.prototype, "appliedDiscounts", {
        get: function () {
            var _this = this;
            var discounts = [];
            if (this.order) {
                this.order.discount_applications.forEach(function (discount) {
                    if (discount.description) {
                        discounts.push(_this.discountDescription(discount));
                    }
                    else if (discount.code) {
                        discounts.push(discount.code);
                    }
                });
            }
            else {
                this.draftOrder.line_items.forEach(function (item) {
                    if (item.applied_discount) {
                        discounts.push(_this.discountDescription(item.applied_discount));
                    }
                });
                if (this.draftOrder.applied_discount) {
                    discounts.push(this.discountDescription(this.draftOrder.applied_discount));
                }
            }
            return discounts;
        },
        enumerable: true,
        configurable: true
    });
    PaymentAccordionComponent.prototype.discountDescription = function (discount) {
        return discount.title ? discount.title + " - " + discount.description : discount.description;
    };
    Object.defineProperty(PaymentAccordionComponent.prototype, "note", {
        get: function () {
            return this.order ? this.order.note : this.draftOrder.note;
        },
        enumerable: true,
        configurable: true
    });
    PaymentAccordionComponent.prototype.displayTitle = function (item) {
        return item.variant_title ? item.variant_title : item.title;
    };
    PaymentAccordionComponent.prototype.priceToInt = function (item) {
        return parseFloat(item.price);
    };
    PaymentAccordionComponent.prototype.onPanelChange = function (event) {
        this.open = event.nextState;
        this.toggleIcon = event.nextState ? 'icon-chevron-up' : 'icon-chevron-down';
    };
    PaymentAccordionComponent.prototype.openCheckout = function () {
        this.eventId = this.event.event_id;
        // if event is permit in progress open tos agreement modal before sending them to checkout
        if (this.event.event_status === 'permit-in-progress') {
            this.openTosModal();
        }
        else {
            window.open(this.draftOrder.invoice_url);
        }
    };
    PaymentAccordionComponent.prototype.openTosModal = function () {
        this.tosModalOpen = true;
    };
    PaymentAccordionComponent.prototype.closeTosModal = function () {
        this.tosModalOpen = false;
    };
    PaymentAccordionComponent.prototype.handleTosAccept = function (data) {
        // console.log ('data to save', data);
        this.postEventDays();
        // this.tosCheckoutDisabled = false;
    };
    PaymentAccordionComponent.prototype.postEventDays = function () {
        if (this.eventId) {
            this.eventDayService.addEventDays(this.eventId).subscribe();
        }
    };
    PaymentAccordionComponent.prototype.handleCompleteCheckout = function (data) {
        this.closeTosModal();
        this.updateOrCreateDraftOrder();
    };
    PaymentAccordionComponent.prototype.updateOrCreateDraftOrder = function () {
        var _this = this;
        var lineItems = [];
        // TODO: @Charlie - What other default tags should we assign here?
        var eventIdTag = "event_id-" + this.eventId;
        var tags = [eventIdTag, MerchantAccountTag.Permitting];
        // const discountAmnt = '';
        // const discount = new ShopifyDiscount({
        //   description: 'Post Event Admin Discount',
        //   value_type: 'fixed_amount',
        //   value: discountAmnt,
        //   amount: discountAmnt,
        //   title: 'Post Event'
        // });
        var discount = null;
        var note = null;
        if (!!this.draftOrder) {
            var draftTags = this.draftOrder.tags ? this.draftOrder.tags.split(', ') : [];
            if (!draftTags.some(function (tag) { return tag === eventIdTag; })) {
                draftTags.push(eventIdTag);
            }
            this.applyCalendarCode(draftTags).then(function (discountData) {
                if (discountData) {
                    window.open(_this.draftOrder.invoice_url);
                }
            });
        }
        else {
            this.shopifyService.createDraftOrder(lineItems, tags, discount, note).subscribe(function (resp) {
                if (resp) {
                    _this.applyCalendarCode().then(function (discountData) {
                        if (discountData) {
                            window.open(_this.draftOrder.invoice_url);
                        }
                    });
                }
            });
        }
    };
    PaymentAccordionComponent.prototype.applyCalendarCode = function (tagsArray) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var calendarCode = localStorage.getItem('calendarCode');
            if (calendarCode) {
                // Find permit fee line item & calculate total from price & quantity
                var permitFees = _this.draftOrder.line_items.filter(function (item) { return item.sku.includes('PERMIT-FEE-'); });
                if (permitFees.length) {
                    permitFees.forEach(function (feeItem) {
                        if (!feeItem.applied_discount) {
                            // Create discount
                            var discountAmnt = parseFloat(feeItem.price) * feeItem.quantity;
                            var discount = new ShopifyDiscount({
                                description: 'Permit Fee Calendar Code',
                                value_type: 'percentage',
                                value: 100,
                                amount: discountAmnt,
                                title: calendarCode
                            });
                            // Append discount to line item
                            Object.assign(feeItem, { applied_discount: discount });
                        }
                    });
                    // Update draft order
                    var draft = _this.draftOrder;
                    var draftTags = draft.tags ? draft.tags.split(', ') : null;
                    var tags = tagsArray ? tagsArray : draftTags;
                    _this.shopifyService.updateDraftOrder(draft.id, draft.line_items, tags, draft.applied_discount, draft.note).subscribe(function (resp) {
                        if (resp) {
                            localStorage.removeItem('calendarCode');
                            return resolve(resp);
                        }
                        else {
                            return reject();
                        }
                    });
                }
                else {
                    resolve({});
                }
            }
            else {
                return resolve({});
            }
        });
    };
    PaymentAccordionComponent.prototype.openOrder = function () {
        window.open(this.order.summary_url);
    };
    PaymentAccordionComponent.prototype.openModal = function () {
        this.modalOpen = true;
    };
    PaymentAccordionComponent.prototype.closeModal = function () {
        this.modalOpen = false;
    };
    PaymentAccordionComponent.prototype.cancelPayment = function () {
        var _this = this;
        this.shopifyService.deleteDraftOrder(this.draftOrder.id).subscribe(function (resp) {
            if (resp) {
                _this.closeModal();
                setTimeout(function () { return _this.deleteDraftOrder.emit(_this.draftOrder.id); }, 100);
            }
        });
    };
    return PaymentAccordionComponent;
}());
export { PaymentAccordionComponent };
