<div class="container first">
  <div class="row">
    <h2 class="col-sm-12" *ngIf ="eventListing.mode!=='view'">Review Your Event Listing</h2>
  </div>

  <!-- Loader -->
  <app-loader *ngIf="!dataLoaded"></app-loader>

  <div *ngIf="dataLoaded">
    <app-listing-body></app-listing-body>

    <div class="row text-center" *ngIf ="displayActions()">
      <div class="col-sm">
        <br>
        <h3>Would you like to?</h3>

        <div class="form-row justify-content-center mt-3">
          <app-button [type]="'btn-calendar'" (click)="openSubmitModal($event)" [disabled]="disableListingPermitBtns">Submit Your Calendar listing</app-button>
          <app-modal [id]="'submit-listing'" [title]="'Thanks!'" [isOpen]="submitModalOpen" [allowClose]="false"
            (close)="closeSubmitModal($event)">
            <app-modal-body>
              <p>You are submitting a listing only by declining to permit the event. An event listing allows your event to be listed on the USA Cycling event calendar,
                but it does not provide general liability insurance. You must submit a permit to obtain the general liability insurance, as well as other benefits such as upgrade points,
                results and rankings points and additional promotional opportunities for your event.</p>
              <!-- Make this copy conditional based on logic determining need for race director license -->
              <p *ngIf="licenseNeeded">Please note that in order to permit a competitive event you need a valid USA Cycling Race Director License - $150</p>
              <div class="row">
                <div class="col-sm-6 text-left">
                  <app-button [type]="'btn-secondary'" [mode]="'button'" [disabled]="disableModalBtns" (click)="exitToQueue()">Exit and List Only</app-button>
                </div>
                <div class="col-sm-6 text-right">
                  <app-button [type]="'btn-primary'" [mode]="'button'" [disabled]="disableModalBtns" (click)="toPermit()">{{permitModalContinueText()}}</app-button>
                </div>
              </div>
            </app-modal-body>
          </app-modal>
          <app-button *ngIf="!eventListing.isVirtual"
            [type]="'btn-event'"
            (click)="openPermitModal($event)"
            [disabled]="disableListingPermitBtns"
          >Permit Your Event with USA Cycling</app-button>
          <app-modal [id]="'permitApplication'" [title]="'Thanks!'" [isOpen]="permitModalOpen" [allowClose]="false"
            (close)="closePermitModal()">
            <app-modal-body>
              <h5>Your listing has been submitted for review.</h5>
              <br>
              <p>Your listing will be posted to the USA Cycling Event Calendar as "Permit In Review" once your permit application is completed.</p>
              <!-- Make this copy conditional based on logic determining need for race director license -->
              <p *ngIf="licenseNeeded">In order to permit a competitive event you need a valid USA Cycling Race Director License - $150</p>
              <div class="row">
                <div class="col-sm-6 text-left">
                  <app-button type="btn-secondary" [mode]="'button'" [disabled]="disableModalBtns" (click)="exitToQueue()">Exit and List Event Only</app-button>
                </div>
                <div class="col-sm-6 text-right">
                  <!-- Make this button text conditional based on logic determining need for race director license -->
                  <app-button type="btn-primary" (click)="toPermit()" [disabled]="disableModalBtns">{{permitModalContinueText()}}</app-button>
                </div>
              </div>
            </app-modal-body>
          </app-modal>
        </div>
        <div class="row justify-content-center space-above-sm">
          <app-button [type]="'btn-light'" (click)="toListing()" [disabled]="disableListingPermitBtns">Go Back to Edit</app-button>
        </div>
      </div>
    </div>

    <!-- commenting this feature we may reuse this in the future
    <div *ngIf="eventListing.mode==='update'">
      <div class="row my-3">
          <div class="col-lg-7">
            <p>Please include an updated Event Announcement that shows this update</p>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">Upload</span>
              </div>
              <div class="custom-file">
                <input type="file" class="custom-file-input" id="inputGroupFile01">
                <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
              </div>
            </div>
          </div>
        </div>
      <div class="row">
        <app-text-input
              class="col-lg-7"
              [name]="'comment'"
              [label]="'Please describe what you would like to change?'"
              [placeholder]="'Add a comment...'"
              [(ngModel)]="eventListing.updateInfo"
              [required]=true
        ></app-text-input>
      </div>
      <div class="row my-3">
        <div class="col-sm-6 text-left">
          <app-button type="btn-light">Cancel</app-button>
        </div>
        <div class="col-sm-6 text-right">
          <app-button *ngIf="!(eventListing.updateInfo === undefined || eventListing.updateInfo === '')"
            type="btn-secondary" [mode]="'button'" (click)="postChanges()">Submit</app-button>
        </div>
      </div>
    </div>
    <div *ngIf = "eventListing.mode==='view'">
      <div class="row my-3">
        <div class="col-sm">
          <h5>Comments</h5>
          <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Natus, itaque! Deleniti voluptate inventore impedit voluptates 
            nam amet quam nemo harum quaerat voluptas! Inventore, autem. Qui ea vitae ex ut laborum?</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6 text-left">
          <app-button type="btn-primary" (click)="denyChangeRequest()">Deny</app-button>
        </div>
        <div class="col-sm-6 text-right" *ngIf="!denyRequest">
          <app-button
            type="btn-secondary" [mode]="'button'" (click)="approveChanges()">Approve</app-button>
        </div>
      </div>
      <div *ngIf="denyRequest">
        <app-text-input
              class="col-lg-7"
              [name]="'comment'"
              [label]="'Reason for rejecting change request'"
              [placeholder]="'Add a comment...'"
              [(ngModel)]="eventListing.reasonInfo"
              [required]=true
        ></app-text-input>
        <div class="text-right mb-3">
          <app-button *ngIf="!(eventListing.reasonInfo === undefined || eventListing.reasonInfo === '')"
            type="btn-secondary"
            [mode]="'button'"
            (click)="sendReason()"
          >Send</app-button>
        </div>
      </div>
    </div> -->
  </div>
</div>
