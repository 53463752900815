<div class="form-row justify-content-between align-items-baseline">
  <h4>Vehicles</h4>
  <p class="no-margin">{{complete}} Complete / {{incomplete}} Incomplete</p>
</div>
<!-- Added Vehicle -->
<div *ngFor="let vehicle of vehicles; let i = index" class="row driver-row">
  <div class="col-5 col-sm-5" >{{vehicle.vehicle_make}} {{vehicle.vehicle_model}}</div>
  <div class="col-5 col-sm-5 driver-status">
    <span class="badge badge-pill badge-success" [ngClass]="{'badge-warning': vehicle.vehicle_state === 'pending'}"><p>{{vehicle.vehicle_state | titlecase}}</p>
    </span>
  </div>
  <div class="col-2 col-sm-2 align-self-center">
      <span (click)="removeVehicle(i)" class="cursor-pointer"><i class="icon icon-delete"></i></span>
  </div>
</div>
<br>
<!-- Vehicle Form -->
<form [formGroup]="addVehicleForm" *ngIf="complete < quantity else finished">
  <h5 class="space-below-sm">Add a vehicle</h5>
  <div class="form-row">
        <div class="col-sm-6">
          <app-text-input
            formControlName="vehicle_make"
            [type]="'text'"
            [name]="'vehicle_make'"
            [label]="'Make'"
            [placeholder]="'Toyota'"
            [formSubmitted]="submitted"
            [errors]="f.vehicle_make.errors"
            [required]=true
            [readonly]=""></app-text-input>
          </div>
        <div class="col-sm-6">
          <app-text-input
            formControlName="vehicle_model"
            [type]="'text'"
            [name]="'vehicle_model'"
            [label]="'Model'"
            [placeholder]="'Camry'"
            [formSubmitted]="submitted"
            [errors]="f.vehicle_model.errors"
            [required]=true
            [readonly]=""></app-text-input>
        </div>
        <div class="col-sm-4">
          <app-text-input
            formControlName="vehicle_year"
            [type]="'text'"
            [name]="'vehicle_year'"
            [label]="'Year'"
            [placeholder]="'Year'"
            [formSubmitted]="submitted"
            [errors]="f.vehicle_year.errors"
            [required]=true
            [readonly]=""></app-text-input>
          </div>
        <div class="col-sm-8">
          <app-text-input
            formControlName="vehicle_vin"
            [type]="'text'"
            [name]="'vehicle_vin'"
            [label]="'VIN'"
            [placeholder]="'VIN'"
            [formSubmitted]="submitted"
            [errors]="f.vehicle_vin.errors"
            [required]=true
            [readonly]=""></app-text-input>
        </div>
        <div class="col-sm-12">
        <app-radio-group
          formControlName="vehicle_insurance"
          [name]="'vehicle_insurance'"
          [inline]="true"
          [label]="'Does this vehicle carry insurance meeting state requirements?'"
          [options]="insuranceRequirementsOptions"
          [formSubmitted]="submitted"
          [errors]="f.vehicle_insurance.errors"
          [required]=true
          ></app-radio-group>
        </div>
    </div>
    <div class="row">
    <div class="col-sm-6">
      <app-button
        [type]="'btn-primary w-100'"
        [mode] = "'button'"
        (click)="onSubmit()">Save Vehicle</app-button>
    </div>
  </div>
</form>

<ng-template #finished>
  <h4>All vehicles have been added.</h4>
</ng-template>
