<div class="container">
  <div class="row">
    <div class="main-container col-12">
      <h2>Event Calendar Labels</h2>

      <!-- Loader -->
      <app-loader *ngIf="!loaded"></app-loader>

      <!-- Calendar label options -->
      <app-event-incentives-form *ngIf="loaded"
        [event]="event"
        [eventId]="eventId"
        [calendarLabels]="calendarLabels"
        [calendarLabelQuestions]="calendarLabelQuestions"
        [selectedLabelIds]="selectedLabelIds"
        [answers]="calendarLabelAnswers"
      ></app-event-incentives-form>

    </div>
  </div>
</div>
