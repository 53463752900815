<div class="container">
  <div class="row">
    <div class="main-container col-12">
      <div id="thank-you">
        <h1>Thank you!</h1>

        <p>Dear {{first_name}} {{last_name}},</p>

        <p>
          Thank you for your recent purchase through USA Cycling.
          Your request is being processed by the Event Services staff now.
          A member of our team will contact you if additional information is needed.
        </p>

        <p>
          The insurance certificate to reflect the addition of your recent purchase
          will be generated shortly after processing and will be emailed to you.
          If you do not receive the insurance certificate via email with 72 hours*,
          then please contact us at <a href="mailto:eventservices@usacycling.org">eventservices@usacycling.org</a>.
        </p>

        <p>
          *Please note that processing of all ancillary insurance requests usually take longer than 72 hours.
        </p>

        <div class="order-details mt-4">
          <h4>
            Order Details
          </h4>

          <ul>
            <li *ngFor="let lineItem of getLineItems()">
              {{lineItem.quantity}}x {{lineItem.variant_title}}
            </li>
          </ul>
        </div>

        <div class="auto-moto-thank-you" *ngIf="checkoutHasAutoMoto()">
          <p>
            It also looks like you’ve requested auto/moto coverage.
            48 hours prior to the event you’ll need to have updated
            your permit with auto/moto driver and vehicle information
            which can be done in the self-service event management tool.
            Once entered, a member of our team will confirm that the
            auto/moto driver has a valid Motor Vehicle Report for the
            current year. You will be contacted if the driver does not
            have the report on file and confirm that the details were
            entered correctly.
          </p>

          <p>
            All drivers will need to have a valid 2019 or 2020 MVR
            that is in good standing with First Advantage. It is important
            to go ahead and notify the driver about this process now,
            as waiting could lead to issues with being able to insure
            the driver and vehicle in the event. Please have the driver
            complete the MVR process if they have not done so before by
            using the following link: <a target="_blank" href="https://s3.amazonaws.com/USACWeb/forms/officials/Motoref-MVR-Self-Reg.pdf">https://s3.amazonaws.com/USACWeb/forms/officials/Motoref-MVR-Self-Reg.pdf</a>
          </p>

          <p>
            The insurance certificate to reflect the addition of your
            auto/moto insurance will be generated shortly after driver
            and vehicle information is confirmed and will be emailed
            to you after processing. If you do not receive the insurance
            certificate via email with 72 hours of entering your vehicle
            and driver information, then please contact us at <a href="mailto:eventservices@usacycling.org">eventservices@usacycling.org</a>.
          </p>
        </div>

        <p>
          Thank you again for working with USA Cycling,
          if you have questions please reach us at <a href="mailto:eventservices@usacycling.org">eventservices@usacycling.org</a>.
        </p>

        <p>Sincerely,</p>

        <p>USA Cycling Event Services Staff</p>
      </div>
    </div>
  </div>
</div>
