import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  implements CanActivate {
  loginKey = 'nonce';

  constructor(private authService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.authService.isAuthenticated().subscribe(isAuthenticated => {
        if (!isAuthenticated) {
          this.authService.doLogin(window.location.href);

          observer.next(false);
        } else {
          observer.next(true);
        }

        observer.complete();
      });
    });
  }

  expectedNonce() {
    return localStorage.getItem(this.loginKey);
  }

  removeNonce() {
    localStorage.removeItem(this.loginKey);
  }
}
