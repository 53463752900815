<div class="form-row align-items-baseline space-below-sm">
  <h4>Assign Referee</h4>
</div>

<h5 *ngIf="isSeries" class="pb-1">Select Event</h5>
<form [formGroup]="addRefereeForm">
  <app-select *ngIf="isSeries"
    [formControlName]="'event_date_id'"
    [label]="'Event Date'"
    [name]="'event_date_id'"
    [options]="eventDateOptions"
    [required]=true
  ></app-select>

  <!-- Referee Search -->
  <h5 class="pb-1" [ngClass]="{'pt-3': isSeries}">Search for Referee</h5>
  <form [formGroup]="searchRefereeForm" (ngSubmit)="searchReferee()">
    <div class="form-row">
      <div class="col-sm-6">
        <app-text-input
          formControlName="usacId"
          [type]="'text'"
          [name]="'usacId'"
          [label]="'Official\'s Member ID'"
          [placeholder]="'12345'"
          [formSubmitted]="searchRefereeSubmitted"
          [errors]=""
          [readonly]=""></app-text-input>
        </div>
      <div class="col-sm-6">
        <app-text-input
          formControlName="refereeName"
          [type]="'text'"
          [name]="'refereeName'"
          [label]="'Referee Name'"
          [placeholder]="'Jane Doe'"
          [formSubmitted]="searchRefereeSubmitted"
          [errors]=""
          [readonly]=""></app-text-input>
      </div>
    </div>
    <div class="row space-below-lg">
      <div class="col-sm-6">
        <app-button [type]="'btn-secondary w-100'" [disabled]="searchButtonDisabled">Search Referee</app-button>
      </div>
    </div>
  </form>
  <!-- Referee Search Result -->
  <p *ngIf="searching">Searching...</p>
  <div *ngIf="displaySearchResults" class="referee-search">
    <div *ngIf="result else noResults">
      <h5>Is this your referee?</h5>
      <div class="row referee-result">
        <div class="col-md-7" >
          <p>{{result.first_name}} {{result.last_name}}</p>
          <p>Age: {{result.age}}</p>
          <p *ngIf="result.city && result.state">{{result.city}}, {{result.state}}</p>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-sm-6">
          <app-button
            [type]="'btn-primary w-100'"
            [mode] = "'button'"
            (click)="saveReferee(result)">Save Referee</app-button>
        </div>
      </div>
    </div>
    <ng-template #noResults>
      <h4>Exact match not found.</h4>
    </ng-template>
  </div>
</form>
