import { OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventListingService, EventService } from '../../services';
import { Contact, Event } from 'src/app/classes';
var ListingBodyComponent = /** @class */ (function () {
    function ListingBodyComponent(eventListing, eventService) {
        this.eventListing = eventListing;
        this.eventService = eventService;
        this.validContacts = [];
        this.loading = true;
    }
    ListingBodyComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.eventListing.mode === 'update') {
            var eventId = this.event ? this.event.event_id : this.eventListing.event_id;
            this.eventService.getEventDates(eventId).subscribe(function (dates) { return _this.eventDates = dates; });
        }
        this.websiteUrl = this.setHrefUrl(this.eventListing.event_website);
        this.announcementUrl = this.setHrefUrl(this.eventListing.event_announcement);
        this.regUrl = this.setHrefUrl(this.eventListing.event_reg_url);
        this.facebookUrl = this.setHrefUrl(this.eventListing.event_facebook_url);
        this.twitterUrl = this.setHrefUrl(this.eventListing.event_twitter_url);
        this.instagramUrl = this.setHrefUrl(this.eventListing.event_instagram_url);
        this.youtubeUrl = this.setHrefUrl(this.eventListing.event_youtube_url);
        this.eventListing.contacts.forEach(function (contact) { if (contact.isValid) {
            _this.validContacts.push(contact);
        } });
        this.eventListing.getDisciplines().subscribe(function (data) {
            var discs = _this.eventListing.filterDisciplines(data);
            _this.disciplineName = _this.eventListing.getDisciplineName(discs, _this.eventListing.event_discipline);
        });
        this.eventListing.getEventTypes().subscribe(function (data) {
            if (_this.eventListing.mode === 'update') {
                var eventTypes = _this.event ? _this.event.event_types : _this.eventListing.event_types;
                _this.eventTypeNames = _this.eventListing.setTypeNames(data, eventTypes);
            }
            else {
                _this.eventTypeNames = _this.eventListing.setTypeNames(data, _this.eventListing.event_types);
            }
        });
        this.loading = false;
    };
    ListingBodyComponent.prototype.setHrefUrl = function (url) {
        return url && url.indexOf('http') === 0 ? url : "http://" + url;
    };
    ListingBodyComponent.prototype.getActualEventDate = function (reff) {
        var destiny;
        if (reff !== undefined
            && this.event !== undefined
            && this.event.hasOwnProperty('eventDates')) {
            this.eventDates.forEach(function (eventDate) {
                if (eventDate.event_date_event_id === reff) {
                    destiny = eventDate;
                }
            });
        }
        return destiny;
    };
    // getSponsoringClubState(clubId: string): string {
    //   if (this.eventListing.mode === 'update') {
    //     let state: string;
    //     state = 'text-success';
    //     this.event.event_sponsoring_clubs.forEach(club => {
    //       if (club.value === clubId) {
    //         state = '';
    //       }
    //     });
    //     return state;
    //   }
    //   return '';
    // }
    ListingBodyComponent.prototype.getContactsState = function (contactId) {
        if (this.eventListing.mode === 'update') {
            var state_1;
            state_1 = 'text-success';
            this.event.event_contacts.forEach(function (contact) {
                if (contact.id === contactId) {
                    state_1 = '';
                }
            });
            return state_1;
        }
        return '';
    };
    ListingBodyComponent.prototype.getActualContact = function (contactId) {
        var _this = this;
        console.log(contactId);
        if (this.eventListing.mode === 'update') {
            this.actualContact = new Contact();
            var state_2 = false;
            this.event.event_contacts.forEach(function (contact) {
                if (contact.id === contactId) {
                    Object.assign(_this.actualContact, contact);
                    state_2 = true;
                }
            });
            return state_2;
        }
        return false;
    };
    ListingBodyComponent.prototype.hasUpdatedStartDate = function () {
        if (this.eventListing.mode === 'update') {
            var originalStartDate = new Date(this.event.event_start_date);
            return (this.eventListing.event_start_date !== originalStartDate);
        }
        return false;
    };
    ListingBodyComponent.prototype.hasUpdatedEndDate = function () {
        if (this.eventListing.mode === 'update') {
            var originalEndDate = new Date(this.event.event_end_date);
            return (this.eventListing.event_end_date !== originalEndDate);
        }
        return false;
    };
    Object.defineProperty(ListingBodyComponent.prototype, "specialCalendarText", {
        get: function () {
            var calendars = this.eventListing.event_special_calendars;
            if (typeof calendars[0] === 'object') {
                var calendarText_1 = [];
                calendars.forEach(function (cal) {
                    if (!!cal.special_calendar_text) {
                        calendarText_1.push(cal.special_calendar_text.split('-').join(' '));
                    }
                });
                return calendarText_1.join(', ');
            }
            else {
                return calendars.map(function (cal) { return cal.split('-').join(' '); }).join(', ');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListingBodyComponent.prototype, "oneDayRegistrationUrl", {
        get: function () {
            return environment.membershipUrl + "/one-day/" + this.event.event_id;
        },
        enumerable: true,
        configurable: true
    });
    ListingBodyComponent.prototype.isExistsActualContactProp = function (key) {
        if (this.actualContact !== undefined) {
            return this.actualContact.hasOwnProperty(key);
        }
        return false;
    };
    return ListingBodyComponent;
}());
export { ListingBodyComponent };
