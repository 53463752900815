import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-nav-link',
  templateUrl: './nav-link.component.html',
  styles: []
})
export class NavLinkComponent implements OnInit {
  @Input() path: string;
  @Input() name: string;

  constructor() { }

  ngOnInit() {
  }
}
