import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import {
  EventService, EventListingService, ProfileService,
  PermitService, DriverVehicleService, PostEventService, QrCodeService
} from '../../services';
import {
  Alert,
  CalendarLabel,
  CalendarLabelAnswer,
  CalendarLabelQuestion,
  Event,
  EventDate,
  PostEvent,
  Profile,
  SelectOption,
  ShopifyOrder,
  ShopifyDraftOrder,
  ShopifyDraftOrderStatus
} from 'src/app/classes';
import { ShopifyService } from 'src/app/shared';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-event-overview',
  templateUrl: './event-overview.component.html',
  styles: []
})
export class EventOverviewComponent implements OnInit {

  alerts: Alert[] = [];
  statusForm: FormGroup;
  statusOptions: SelectOption[] = [];
  // TODO: Rename ... "listing"?
  event: Event;
  eventId: number|string;
  eventDates: EventDate[];
  submitted = false;
  tabOrientation = 'horizontal';
  breakpoint = 768;
  activeLevel1: string;
  activeLevel2: string;
  permit_id: number;
  statusText = 'Status';
  showStatusError = false;

  eventLoading = true;
  permitLoading: boolean;

  organizerName: string;
  organizerEmail: string;
  organizerPhone: string;
  disciplineName: string;

  autoSku: string;
  motoSku: string;
  autoMotoSkus: string[] = [];
  autoQuant = 0;
  motoQuant = 0;
  autoTotal: number = null;
  motoTotal: number = null;
  driverTotal: number = null;

  currentUser: Profile;
  userRole: string;

  orders: ShopifyOrder[];
  draftOrders: ShopifyDraftOrder[];

  calendarLabels: CalendarLabel[];
  calendarLabelQuestions: CalendarLabelQuestion[];
  calendarLabelAnswers: CalendarLabelAnswer[];

  outstandingPaperwork = false;
  incompleteForms = false;
  closeCancelModalOpen = false;

  postEvents: PostEvent[] = [];

  qrUri: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private eventService: EventService,
    public eventListing: EventListingService,
    public permit: PermitService,
    private profileService: ProfileService,
    private shopifyService: ShopifyService,
    private driverVehicleService: DriverVehicleService,
    private postEventService: PostEventService,
    private qrCodeService: QrCodeService
  ) { }

  // convenience getter for easy access to form fields
  get f() { return this.statusForm.controls; }

  async ngOnInit() {
    this.eventId = this.route.snapshot.paramMap.get('eventId');

    this.statusForm = this.formBuilder.group({
      status: new FormControl()
    });

    this.eventService.getEventById(this.eventId).subscribe(event => {
      if (event) {
        this.event = new Event(event);
        if (this.event.event_organizer) {
          this.profileService.getProfile(this.event.event_organizer).subscribe(profile => {
            if (profile) {
              this.organizerName = profile.profile_first_name + ' ' + profile.profile_last_name;
              this.organizerEmail = profile.profile_email;
              this.organizerPhone = profile.profile_contact.contact_phone_1;
            }
          });
        }

        this.eventListing.getDisciplines().subscribe(data => {
          const discs = this.eventListing.filterDisciplines(data);
          this.disciplineName = this.eventListing.getDisciplineName(discs, this.event.event_discipline);
        });

        this.eventService.getEventDates(event.event_id).subscribe(eventDates => {
          this.eventDates = this.eventService.sortEventDates(eventDates);
        });

        if (!this.isVirtual) {
          try{
          const skus = environment.disciplineSkus[event.event_discipline];
          this.autoSku = skus.autoInsurance;
          this.motoSku = skus.motoInsurance;
          this.autoMotoSkus = [this.autoSku, this.motoSku];
          this.permit_id = event.event_permit_id;
          }catch(e)
          {
            console.error(e);
          }
        }

        this.subscribeToOrdersAndDraftOrders();

        if (this.isPermit) {
          this.permitLoading = true;
          this.permit.getPermitInfo(this.permit_id).subscribe( (data: any) => {
            if (data === null || data === undefined) {
              console.log('events are not found , due to the api has down');
            }
            this.permit.answers = data.answers;
            this.permitLoading = false;
          }, (error) => {
            console.log(error);
            }
          );
          const pendingPermitStatuses = ['permit-in-progress', 'permit-in-review'];
          if (!pendingPermitStatuses.includes(this.event.event_status)) {
            this.qrCodeService.generateQrCode(`${environment.membershipUrl}/one-day/${this.eventId}`).subscribe(resp => {
              this.qrUri = resp.dataUri;
            });
          }

          this.permit.getCalendarLabelsForEvent(this.eventId).subscribe(labels => this.calendarLabels = labels);
          this.permit.getCalendarQuestions(this.event.event_discipline).subscribe(questions => {
            this.calendarLabelQuestions = questions;
            this.permit.getCalendarLabelAnswersForEvent(this.eventId).subscribe(answers => this.calendarLabelAnswers = answers);
          });
        }

        this.statusForm = this.formBuilder.group({
          status: new FormControl(this.event.event_status)
        });
        this.setStatusText();
        this.f.status.setValue(this.event.event_status);

        this.profileService.getCurrentUser().subscribe(() => {
          this.currentUser = this.profileService.currentUser;
          this.profileService.getUserPermissions().subscribe(permissions => {
            this.userRole = this.profileService.setUserRole(permissions);
            if (!this.isUsacAdmin) {
              this.statusForm.controls.status.disable();
            }

            this.statusOptions = this.isVirtual ? this.eventService.listingStatusOptions : this.eventService.statusOptions;
            // if (this.userRole === 'la_admin') {
            //   const limitedOptions = this.statusOptions.slice(0, 2);
            //   if (limitedOptions.some(option => option.value === this.event.status)) {
            //     this.statusOptions = limitedOptions;
            //   } else {
            //     this.statusForm.controls.status.disable();
            //   }
            // }
          });
          if (this.isPermit) { this.getPostEvents(); }
        });
      } else {
        this.toQueue();
      }
    });

    this.setTabOrientation(window.innerWidth);

    this.eventListing.mode = 'update';
    this.eventListing.fetchEventData(this.eventId);
    this.eventLoading = false;

    switch (this.route.snapshot.fragment) {
      case 'notifications': this.activeLevel1 = 'overview'; this.activeLevel2 = 'notifications'; break;
      case 'comments': this.activeLevel1 = 'overview'; this.activeLevel2 = 'comments'; break;
      case 'payments': this.activeLevel1 = 'overview'; this.activeLevel2 = 'payments'; break;
      case 'documents': this.activeLevel1 = 'overview'; this.activeLevel2 = 'documents'; break;
      case 'history': this.activeLevel1 = 'overview'; this.activeLevel2 = 'history'; break;
      case 'event-listing': this.activeLevel1 = 'pre-event'; this.activeLevel2 = 'event-listing'; break;
      case 'permit-application': this.activeLevel1 = 'pre-event'; this.activeLevel2 = 'permit-application'; break;
      case 'event-incentives': this.activeLevel1 = 'pre-event'; this.activeLevel2 = 'event-incentives'; break;
      case 'insured-persons': this.activeLevel1 = 'pre-event'; this.activeLevel2 = 'insured-persons'; break;
      case 'permit-add-ons': this.activeLevel1 = 'pre-event'; this.activeLevel2 = 'permit-add-ons'; break;
      case 'referee-assignment': this.activeLevel1 = 'pre-event'; this.activeLevel2 = 'referee-assignment'; break;
      case 'post-event': this.activeLevel1 = 'post-event';
    }
  }

  debug(event: any): void {
    console.log('debug form', this.f, event);
  }

  onSubmit() {
    this.submitted = true;

    // console.log('submitting form', this.f);

    // stop here if form is invalid
    if (this.statusForm.invalid) {
        return;
    }

    setTimeout(() => {
      const status = this.f.status.value.toLocaleLowerCase().split(' ').join('-');
      if (status === 'closed') {
        if (this.outstandingPaperwork || this.outstandingPayments || this.incompleteForms) {
          this.closeCancelModalOpen = true;
        } else {
          this.updateStatus(status);
        }
      } else {
        this.updateStatus(status);
      }
    }, 1);
  }

  updateStatus(status: string) {
    this.showStatusError = false;
    this.eventService.updateEventStatus(this.eventId, status).subscribe((resp) => {
      if (resp.message) {
        this.eventService.getEventById(this.eventId).subscribe(event => {
          this.event = new Event(event);
          this.eventListing.event_status = event.event_status;
          this.setStatusText();
        });
      } else {
        this.showStatusError = true;
      }
    });
  }

  closeStatusModal($event) {
    this.closeCancelModalOpen = false;
    // TODO: check for exit of 'closed' without updating status
    if (!$event.updated) { this.f.status.setValue(this.event.event_status); }
  }

  onResize(event) {
    this.setTabOrientation(event.target.innerWidth);
  }

  setTabOrientation(width) {
    this.tabOrientation = width >= this.breakpoint ? 'horizontal' : 'vertical';
  }

  setAlerts() {
    this.alerts = [];
    this.setInProgressAlert();
    this.setAutoMotoAlert();
  }

  setInProgressAlert() {
    switch (this.event.event_status) {
      case 'listing-in-progress':
        this.alerts.push(
          new Alert(
            'info', 'Your event listing needs to be completed before submitting it for approval.', 'Incomplete Listing',
            'edit', 'Continue', `/event/${this.eventId}/overview#event-listing`, null, false
          )
        );
        break;
      case 'permit-in-progress':
        this.alerts.push(
          new Alert(
            'info', 'Your permit needs to be completed before submitting it for approval.', 'Incomplete Permit',
            'edit', 'Continue', `/event/${this.eventId}/overview#permit-application`, null, false
          )
        );
    }
  }

  subscribeToOrdersAndDraftOrders() {
    this.shopifyService.eventDraftOrders.subscribe(draftOrders => {
      this.draftOrders = draftOrders.filter(draftOrder => {
        return draftOrder.status === ShopifyDraftOrderStatus.open || draftOrder.status === ShopifyDraftOrderStatus.invoice_sent;
      });
    });

    this.shopifyService.eventOrders.subscribe(orders => {
      this.orders = orders;
      if (this.orders) {
        this.setAlerts();
      }
    });
  }

  setAutoMotoAlert() {
    if (this.orders) {
      this.orders.forEach(order => {
        order.line_items.forEach(item => {
          if (this.autoMotoSkus.includes(item.sku)) {
            if (item.sku === this.autoSku) {
              this.autoQuant += item.quantity;
            } else {
              this.motoQuant += item.quantity;
            }
          }
        });
      });
      let stepsCompleted = 0;
      this.driverVehicleService.getVehicles(this.permit_id).subscribe(vehicles => {
        this.autoTotal = vehicles.filter(v => v.vehicle_type === 'auto').length;
        this.motoTotal = vehicles.filter(v => v.vehicle_type === 'moto').length;
        stepsCompleted += 1;
        if (stepsCompleted === 2) { this.compareVehiclesToDrivers(); }
      });
      this.driverVehicleService.getDrivers(this.permit_id).subscribe(drivers => {
        this.driverTotal = drivers.length;
        stepsCompleted += 1;
        if (stepsCompleted === 2) { this.compareVehiclesToDrivers(); }
      });
    }
  }

  compareVehiclesToDrivers() {
    if (this.autoQuant > this.autoTotal || this.motoQuant > this.motoTotal
      || this.driverTotal < this.autoTotal || this.driverTotal < this.motoTotal
    ) {
      if (!this.alerts.some(alert => alert.title === 'Auto/Moto Insurance')) {
        this.alerts.push(
          new Alert(
            'info', 'Please add details for your event\'s auto and motorcycle liability insurances.',
            'Auto/Moto Insurance', 'user-add', 'Update', `/event/${this.eventId}/overview#permit-add-ons`, null, true
          )
        );
      }
    }
  }

  getPostEvents() {
    this.postEventService.getPostEventsByEvent(this.eventId).subscribe(postEvents => {
      this.postEvents = postEvents;
      // Check for post event with outstanding paperwork
      this.outstandingPaperwork = !!postEvents.find(pe => this.postEventService.outstandingPaperwork(pe));
      // Check for incomplete post events
      this.incompleteForms = !!postEvents.find(pe => pe.permit_post_event_status === 'form-due');
    });
  }
  
  alertEventStatus() {
      let msg = 'The Race director has been pended';

      if(!this.event.is_pended_outstanding_permit_fee)
      {
        msg = 'The Race director has been unpended';
      }

      alert(msg);
  }

  toggleOutstandingPermitFee() {
    this.eventService.togglePendRaceDirector(this.eventId).subscribe( event => {
      this.event.is_pended_outstanding_permit_fee = event.is_pended_outstanding_permit_fee;
      this.alertEventStatus();
    });
  }


  toListing() {
    // Force listing reload before editing
    window.location.assign(`/event/${this.eventId}/listing`);
  }

  toQueue() {
    this.router.navigate(['/queue']);
  }

  get isPermit() {
    return !!this.permit_id;
  }

  get isVirtual() {
    return this.event.isVirtual;
  }

  get isRaceOrRide(): boolean {
    return this.event.isRaceOrRide;
  }

  get isAdmin() {
    return this.isUsacAdmin || this.isLaAdmin;
  }

  get isUsacAdmin() {
    return this.userRole === 'usac_admin';
  }

  get isLaAdmin() {
    return this.userRole === 'la_admin';
  }

  get isEventOrganizer() {
    return this.currentUser && this.currentUser.profile_id === this.event.event_organizer;
  }

  get isListingOwner() {
    const currentUser = this.profileService.currentUser;
    return currentUser && currentUser.profile_id === this.event.event_listing_owner;
  }

  get isOfficial() {
    return this.userRole === 'official' || (
      this.eventDates && this.currentUser &&
      this.eventDates.some(date => date.event_date_chief_ref && date.event_date_chief_ref.profile_id === this.currentUser.profile_id)
    );
  }

  get showComments(): boolean {
    return this.isAdmin || this.isEventOrganizer;
  }

  get showListingEditBtn() {
    return !this.eventLoading && (
      this.isUsacAdmin || (this.event.event_status === 'listing-in-progress' && (this.isListingOwner || this.isEventOrganizer))
    );
  }

  get showPermitEditBtn() {
    return !this.permitLoading && (
      this.isUsacAdmin || (this.event.event_status === 'permit-in-progress' && (this.isListingOwner || this.isEventOrganizer))
    );
  }

  get showPayments() {
    return this.isPermit && (!this.userRole || this.isUsacAdmin || this.isEventOrganizer || this.isListingOwner);
  }

  eventTypeText() {
    return this.isPermit ? 'Permit' : 'Listing';
  }

  laText() {
    return this.event.event_local_association ? this.event.event_local_association.association_name : 'N/A';
  }

  get permitNumber() {
    return this.event.event_permit_id ? this.event.event_permit_id : 'N/A';
  }

  editBtnText() {
    return this.isPermit ? 'Edit Permit' : 'Permit Your Event';
  }

  setStatusText() {
    this.statusText = this.cancelled() ? 'Event Cancelled' : 'Status';
  }

  beforeEvent() {
    const start = new Date(this.event.event_start_date);
    const today = new Date();
    return start > today;
  }

  get inPostEvent(): boolean {
    return this.event.event_status === 'in-post-event';
  }

  get outstandingPayments(): boolean {
    return this.draftOrders && !!this.draftOrders.length;
  }

  get showEventIncentiveTab(): boolean {
    return this.isPermit && this.isRaceOrRide;
  }

  cancelled() {
    return this.event.event_status === 'cancelled';
  }

  allowCancel() {
    return (
      !this.cancelled() && (
        this.isUsacAdmin ||
        ((this.isEventOrganizer || this.isListingOwner) && this.beforeEvent() && !this.inPostEvent)
      )
    );
  }

  toPermit() {
    if (this.event.event_status === 'listing-in-progress') {
      this.toListing();
    } else {
      this.router.navigate([`/event/${this.eventId}/permit-application/part-1`]);
    }
  }

}
