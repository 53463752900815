import { BehaviorSubject } from 'rxjs';
import { InsuranceCertificate } from '../classes/insurance-certificate';
import { ClubService } from './clubs.service';
import { ProfileService } from './profile.service';
import * as i0 from "@angular/core";
import * as i1 from "./clubs.service";
import * as i2 from "./profile.service";
var InsuranceService = /** @class */ (function () {
    function InsuranceService(clubService, profileService) {
        this.clubService = clubService;
        this.profileService = profileService;
        this.insuranceArrayIndex = 0;
        this.initComplete$ = new BehaviorSubject(false);
        this.certificates = [];
        this.includedCertificates = [];
    }
    InsuranceService.prototype.init = function (organizerId, sponsoringClubs) {
        var _this = this;
        this.includedCertificates = [];
        var initQuantity = (organizerId ? 1 : 0) + (sponsoringClubs ? sponsoringClubs.length : 0);
        var currentQuantity = 0;
        if (organizerId) {
            this.profileService.getProfile(organizerId).subscribe(function (organizer) {
                _this.addIncludedCert('Event Organizer', null, organizer);
                currentQuantity += 1;
                _this.checkInitComplete(initQuantity, currentQuantity);
            });
        }
        if (sponsoringClubs !== undefined && sponsoringClubs.length > 0) {
            sponsoringClubs.forEach(function (club) {
                _this.clubService.getClub(club.club_id).subscribe(function (club) {
                    if (club) {
                        _this.addIncludedCert('Sponsoring Club', club);
                    }
                    currentQuantity += 1;
                    _this.checkInitComplete(initQuantity, currentQuantity);
                });
            });
        }
        this.checkInitComplete(initQuantity, currentQuantity);
    };
    InsuranceService.prototype.checkInitComplete = function (initQuantity, currentQuantity) {
        if (currentQuantity === initQuantity) {
            this.initComplete$.next(true);
        }
    };
    InsuranceService.prototype.addIncludedCert = function (type, club, organizer) {
        var cert = new InsuranceCertificate();
        cert.type = type;
        cert.isIncluded = true;
        cert.price = 0;
        if (club) {
            var p = club.club_phone;
            cert.name = club.club_name;
            cert.clubId = club.club_id;
            cert.street = club.club_address1;
            cert.unit = club.club_address2;
            cert.city = club.city;
            cert.state = club.club_state;
            cert.zip = club.club_zip;
            cert.email = club.club_email;
            cert.phone = p && p.length === 10 ? "(" + p.substring(0, 3) + ") " + p.substring(3, 6) + "-" + p.substring(6) : p;
        }
        if (organizer) {
            cert.name = organizer.profile_first_name + ' ' + organizer.profile_last_name;
            cert.email = organizer.profile_email;
            var address = Array.isArray(organizer.address) && organizer.address.find(function (addr) { return addr.address_primary === 'Y'; });
            if (address) {
                cert.street = address.address_street;
                cert.city = address.address_city;
                cert.state = address.address_state;
                cert.zip = address.address_postal_code;
            }
        }
        this.includedCertificates.push(cert);
    };
    InsuranceService.prototype.addCert = function () {
        var cert = new InsuranceCertificate();
        this.certificates.push(cert);
    };
    InsuranceService.prototype.removeCertificate = function (index) {
        this.certificates.splice(index, 1);
    };
    InsuranceService.prototype.createFromCertificate = function (certificate) {
        var cert = new InsuranceCertificate();
        var p = certificate.phone ? certificate.phone.toString() : '';
        cert.certificateId = certificate.id;
        cert.relationship = certificate.relationship;
        if (cert.relationship === 'organizer') {
            cert.type = 'Event Organizer';
        }
        if (cert.relationship === 'sponsoring_club') {
            cert.type = 'Sponsoring Club';
        }
        cert.isIncluded = certificate.type === 'named';
        if (cert.isIncluded) {
            cert.price = 0;
        }
        cert.name = certificate.name;
        cert.street = certificate.street;
        cert.unit = certificate.unit;
        cert.city = certificate.city;
        cert.state = certificate.state;
        cert.zip = certificate.zip;
        cert.email = certificate.email;
        cert.phone = p.length === 10 ? "(" + p.substring(0, 3) + ") " + p.substring(3, 6) + "-" + p.substring(6) : p;
        cert.specificVerbiage = certificate.insured_info;
        cert.endorsement = certificate.endorsement;
        cert.customRelationship = certificate.custom_relationship;
        return cert;
    };
    InsuranceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InsuranceService_Factory() { return new InsuranceService(i0.ɵɵinject(i1.ClubService), i0.ɵɵinject(i2.ProfileService)); }, token: InsuranceService, providedIn: "root" });
    return InsuranceService;
}());
export { InsuranceService };
