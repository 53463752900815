import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { EventService, PostEventService } from 'src/app/services';
import { Event, Profile } from 'src/app/classes';
var OverviewPostEventComponent = /** @class */ (function () {
    function OverviewPostEventComponent(router, eventService, postEventService) {
        this.router = router;
        this.eventService = eventService;
        this.postEventService = postEventService;
        this.refreshPostEvents = new EventEmitter();
        this.loading = true;
        this.panelsGenerated = false;
        this.deleteModalOpen = false;
        this.deleting = false;
        this.postEventPanels = [];
        this.statuses = ['in-post-event', 'closed'];
    }
    Object.defineProperty(OverviewPostEventComponent.prototype, "eventId", {
        get: function () { return this.event.event_id; },
        enumerable: true,
        configurable: true
    });
    OverviewPostEventComponent.prototype.ngOnChanges = function (changes) {
        if (this.event && this.eventDates && this.postEvents && this.draftOrders && this.orders && !this.panelsGenerated) {
            if (this.inPostEvent) {
                this.loading = true;
                this.generatePostEventPanels();
            }
            else {
                this.loading = false;
            }
        }
    };
    OverviewPostEventComponent.prototype.generatePostEventPanels = function () {
        var _this = this;
        this.postEventPanels = [];
        this.postEvents.forEach(function (postEvent) {
            var eventDate = _this.eventDates.find(function (date) { return date.event_date_id === postEvent.post_event_event_date_id; });
            var eventDateId = eventDate ? eventDate.event_date_id : null;
            var text = eventDate ? eventDate.event_date_start : _this.event.event_start_date;
            var url = eventDate
                ? "/event/" + _this.eventId + "/date/" + eventDate.event_date_id + "/post-event/part-1"
                : "/event/" + _this.eventId + "/post-event/part-1";
            var panel = {
                eventDateId: eventDateId,
                text: text,
                url: url,
                eventDate: eventDate,
                postEvent: postEvent,
                orders: [],
                draftOrder: null,
                totalDue: null
            };
            _this.assignPostEventOrdersAndDraftOrder(postEvent.post_event_id, panel);
            _this.postEventPanels.push(panel);
        });
        this.panelsGenerated = true;
    };
    OverviewPostEventComponent.prototype.assignPostEventOrdersAndDraftOrder = function (postEventId, panel) {
        var postEventDraftOrder = this.draftOrders.find(function (draftOrder) { return draftOrder.tags.includes("post_event_id-" + postEventId); });
        panel.draftOrder = postEventDraftOrder || null;
        panel.orders = this.orders.filter(function (order) { return order.tags.includes("post_event_id-" + postEventId); });
        this.setTotalDue(panel);
    };
    OverviewPostEventComponent.prototype.groupTotals = function (group) {
        var total = 0;
        Object.keys(group).forEach(function (key) { return total += group[key]; });
        return total;
    };
    OverviewPostEventComponent.prototype.setTotalDue = function (panel) {
        // Draft order minus paid orders
        if (panel.draftOrder) {
            var paid_1 = 0;
            panel.orders.forEach(function (order) {
                paid_1 += parseFloat(order.total_price);
            });
            panel.totalDue = parseFloat(panel.draftOrder.total_price) - paid_1;
        }
        else {
            panel.totalDue = 0;
        }
        this.loading = false;
    };
    Object.defineProperty(OverviewPostEventComponent.prototype, "isSeries", {
        get: function () { return this.eventService.isSeries(this.event); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OverviewPostEventComponent.prototype, "isUsacAdmin", {
        get: function () { return this.userRole === 'usac_admin'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OverviewPostEventComponent.prototype, "isOfficial", {
        get: function () {
            var _this = this;
            // return this.userRole === 'official';
            // TODO: Confirm if 'is_official' permission is actually assigned to every official account
            return (this.eventDates && this.currentUser &&
                this.eventDates.some(function (date) { return date.event_date_chief_ref && date.event_date_chief_ref.profile_id === _this.currentUser.profile_id; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OverviewPostEventComponent.prototype, "isEventOrganizer", {
        get: function () {
            return this.currentUser && this.currentUser.profile_id === this.event.event_organizer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OverviewPostEventComponent.prototype, "isOfficialNotOrganizerOrAdmin", {
        get: function () {
            return this.isOfficial && (!this.isEventOrganizer || !this.isUsacAdmin);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OverviewPostEventComponent.prototype, "inPostEvent", {
        get: function () {
            return (this.statuses.includes(this.event.event_status)) && !!this.postEvents.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OverviewPostEventComponent.prototype, "firstEventDate", {
        get: function () {
            return this.eventDates && this.eventDates.length && this.eventDates[0];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OverviewPostEventComponent.prototype, "postEventStart", {
        get: function () {
            return this.isSeries ? (this.firstEventDate && this.firstEventDate.event_date_start) : this.event.event_start_date;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OverviewPostEventComponent.prototype, "isCompetitive", {
        get: function () { return this.event.event_competitive === 'Y'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OverviewPostEventComponent.prototype, "isNonCompetitive", {
        get: function () { return [22, 24, 25, 26, 27, 29, 30].includes(this.event.event_discipline); },
        enumerable: true,
        configurable: true
    });
    OverviewPostEventComponent.prototype.postEventComplete = function (postEvent) {
        return postEvent.permit_post_event_status === 'payment-complete';
    };
    OverviewPostEventComponent.prototype.hasPostEvent = function (panel) {
        return !!panel.postEvent;
    };
    OverviewPostEventComponent.prototype.displayDue = function (postEvent) {
        var statuses = ['payment-due', 'payment-late'];
        return postEvent && statuses.includes(postEvent.permit_post_event_status);
    };
    OverviewPostEventComponent.prototype.formDue = function (postEvent) {
        return postEvent && postEvent.permit_post_event_status === 'form-due';
    };
    OverviewPostEventComponent.prototype.disablePay = function (postEvent) {
        // Only race director & admin can pay post event
        return !this.displayDue(postEvent) || !(this.isEventOrganizer || this.isUsacAdmin);
    };
    OverviewPostEventComponent.prototype.disableEdit = function (postEvent) {
        if (this.isCompetitive) {
            // If competitive, only officials & admin can edit post event form
            return postEvent && ((this.isEventOrganizer && !this.isUsacAdmin) || (this.postEventComplete(postEvent) && !this.isUsacAdmin));
        }
        else {
            // If non-competitive, only race director & admin can edit post event form
            return postEvent && ((!this.isEventOrganizer && !this.isUsacAdmin) || (this.postEventComplete(postEvent) && !this.isUsacAdmin));
        }
    };
    OverviewPostEventComponent.prototype.allowDelete = function (postEvent) {
        return this.isUsacAdmin && !this.postEventComplete(postEvent);
    };
    OverviewPostEventComponent.prototype.allowEdit = function () {
        return this.isUsacAdmin || this.isOfficial || (this.isNonCompetitive && this.isEventOrganizer);
    };
    OverviewPostEventComponent.prototype.status = function (postEvent) {
        return postEvent ? postEvent.permit_post_event_status.split('-').join(' ') : '';
    };
    OverviewPostEventComponent.prototype.badgeClass = function (postEvent) {
        var base = 'badge badge-pill';
        var style = 'badge-warning';
        if (postEvent) {
            switch (postEvent.permit_post_event_status) {
                case 'form-due':
                    style = 'badge-warning';
                    break;
                case 'payment-due':
                    style = 'badge-warning';
                    break;
                case 'payment-late':
                    style = 'badge-danger';
                    break;
                case 'payment-complete':
                    style = 'badge-success';
                    break;
            }
        }
        return base + ' ' + style;
    };
    OverviewPostEventComponent.prototype.toggleIcon = function (i) {
        var id = "post-event-" + (i + 1) + "-toggle";
        var expanded = document.getElementById(id).getAttribute('aria-expanded') === 'true';
        return expanded ? 'icon icon-chevron-up' : 'icon icon-chevron-down';
    };
    OverviewPostEventComponent.prototype.openDeleteModal = function (postEvent) {
        this.postEventToDelete = postEvent;
        this.deleteModalOpen = true;
    };
    OverviewPostEventComponent.prototype.closeDeleteModal = function () {
        this.postEventToDelete = null;
        this.deleteModalOpen = false;
    };
    OverviewPostEventComponent.prototype.deletePostEvent = function () {
        var _this = this;
        this.deleting = true;
        this.postEventService.deletePostEvent(this.postEventToDelete.post_event_id).subscribe(function () {
            _this.panelsGenerated = false;
            _this.refreshPostEvents.emit(true);
            _this.closeDeleteModal();
            _this.deleting = false;
        });
    };
    OverviewPostEventComponent.prototype.toPostEventForm = function (url) {
        if (url) {
            this.router.navigate([url]);
        }
        else {
            var routeIds = this.isSeries ? this.eventId + "/date/" + this.eventDates[0].event_date_id : "" + this.eventId;
            this.router.navigate(["/event/" + routeIds + "/post-event/part-1"]);
        }
    };
    OverviewPostEventComponent.prototype.toCheckout = function (draftOrder) {
        if (draftOrder) {
            window.open(draftOrder.invoice_url);
        }
    };
    return OverviewPostEventComponent;
}());
export { OverviewPostEventComponent };
