
<!-- <div [ngClass]="{'Cart': true, 'Cart--open': cartOpen, 'Cart__close': !cartOpen}"> -->
  <button class="pull-right Cart__closer" (click)="closeCart()">
    ×
  </button>
  <header class="Cart__header">
    <div class="cart-header-inside">
      <!-- TODO: Add/show current draft order # or status? (#D123, etc) -->
      <h4>Order Summary</h4>
    </div>
  </header>

  <!-- Loader -->
  <app-loader *ngIf="(eventId && !draftOrder) || (lineItems && lineItems.length && (!products || !products.length))"></app-loader>

  <h5 *ngIf="!eventId" class="text-center">No Event Loaded</h5>

  <div *ngIf="draftOrder">
    <ul *ngIf="products && products.length" class="Cart__line-items">
      <li *ngFor="let lineItem of completeLineItems" class="Line-item">
        <div class="Line-item__img">
          <img style="max-width: 100%;" [src]="imageSrc(lineItem)">
        </div>
        <div class="Line-item__content">
          <div class="Line-item__content-row d-flex row">
            <span class="Line-item__title col-12" style="line-height:1.2;">
              {{ lineItem.title }}
            </span>
            <div class="Line-item__variant-title col-12" *ngIf="lineItem.variant_title !== 'Default Title' && lineItem.variant_title !== 'No'">
              {{ lineItem.variant_title }}
            </div>
          </div>
          <div class="Line-item__content-row">
            <div *ngIf="!disableCartQuantity(lineItem) else quantity" class="Line-item__quantity-container">
              <button class="Line-item__quantity-update" (click)="decreaseQuantity(lineItem)">-</button>
              <span ngClass="Line-item__quantity">{{lineItem.quantity}}</span>
              <button class="Line-item__quantity-update" (click)="increaseQuantity(lineItem)">+</button>
            </div>
            <ng-template #quantity style="float: left;">
                <span ngClass="Line-item__quantity" style="border: none;">{{lineItem.quantity}}</span>
            </ng-template>
            <span class="Line-item__price">
              $ {{lineItem.price}}
            </span>
            <!-- TODO: How will we connect product data? (not included in draft order line items) -->
            <button *ngIf="!disableCartRemoval(lineItem)" class="Line-item__remove" (click)="removeItem(lineItem)">×</button>
          </div>
        </div>
      </li>
    </ul>

    <footer class="Cart__footer">
      <div class="cart-footer-inside">

        <!-- Footer Loader -->
        <div *ngIf="cartUpdating" class="mt-3">
          <app-loader [text]="'Updating'"></app-loader>
        </div>

        <div *ngIf="!cartUpdating">
          <div class="Cart-info clearfix">
            <div class="Cart-info__total Cart-info__small">Subtotal</div>
            <div class="Cart-info__pricing">
              <span class="pricing">$ {{draftOrder.subtotal_price}}</span>
            </div>
          </div>
          <div class="Cart-info clearfix">
            <div class="Cart-info__total Cart-info__small">Taxes</div>
            <div class="Cart-info__pricing">
              <span class="pricing">$ {{draftOrder.total_tax}}</span>
            </div>
          </div>
          <div class="Cart-info clearfix">
            <div class="Cart-info__total Cart-info__small">Total</div>
            <div class="Cart-info__pricing">
              <span class="pricing">$ {{draftOrder.total_price}}</span>
            </div>
          </div>
        </div>
        <button class="Cart__checkout btn btn-secondary" (click)="handleCheckout()" [disabled]="checkoutDisabled || !lineItems.length">
          Checkout
        </button>
        <app-tos-accept
          [tosModalOpen]="tosModalOpen"
          [checkoutDisabled]="tosCheckoutDisabled"
          [eventId]="eventId"
          (close)="closeTosModal()"
          (accept)="handleTosAccept($event)"
          (complete)="handleCompleteCheckout($event)"
        ></app-tos-accept>
      </div>
    </footer>
  </div>
<!-- </div> -->
