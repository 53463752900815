import { OnInit } from '@angular/core';
import { EventListingService, PostEventService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { PostEvent, Event, EventDate, PostEventRiders, ShopifyDraftOrder, ShopifyOrder, ShopifyProductVariant } from 'src/app/classes';
import { ShopifyService } from 'src/app/shared';
var PostEventSummaryComponent = /** @class */ (function () {
    function PostEventSummaryComponent(postEventService, shopifyService, eventListing) {
        this.postEventService = postEventService;
        this.shopifyService = shopifyService;
        this.eventListing = eventListing;
        this.eventDates = [];
        this.orders = [];
        // commented out for MBR-2992
        // subDisciplines: SubDiscipline[] = [];
        this.registrationKeys = [
            'post_event_sufficient_staff',
            'post_event_sufficient_facilities',
            'post_event_sufficient_time',
            'post_event_start_lists_adequate',
            'post_event_check_licenses'
        ];
        this.conductKeys = [
            'post_event_flyer_accurate',
            'post_event_course_changes_required',
            'post_event_podium',
        ];
        this.supportKeys = [
            'post_event_neutral_support_services',
            'post_event_licensed_mechanics',
            'post_event_timing_company'
        ];
        this.prizeKeys = [
            'post_event_largest_individual_prize',
            'post_event_prize_equal_pay',
            'post_event_prize_distributed_15_min',
            'post_event_posting_area_adequate',
            'post_event_protest_period_observed'
        ];
        this.safesportKeys = [
            'ss_q1',
            'ss_q1_text',
            'ss_q2',
            'ss_q2_text',
            'ss_q3',
            'ss_q3_text',
            'ss_q4',
            'ss_q4_text',
            'ss_q5',
            'ss_q5_text',
            'ss_q6',
            'ss_q6_text',
            'ss_q7',
            'ss_q7_text',
            'ss_q8',
            'ss_q8_text',
        ];
        this.licenseMembershipKeys = [
            'post_event_one_day_on_site',
            'post_event_one_day_online',
            'post_event_membership_standard',
            'post_event_membership_premium',
            'post_event_membership_collegiate',
            'post_event_membership_junior'
        ];
        this.excludeFromCharges = [
            'post_event_one_day_online',
        ];
        this.riderSurchargeTotals = {};
        this.licenseTotals = {};
        this.membershipTotals = {};
        this.lateFee = false;
        // Product SKUs
        this.skus = [];
        this.riderSurchargeSkus = {};
        this.memberStandardSku = environment.postEventMembershipStandard;
        this.memberPremiumSku = environment.postEventMembershipPremium;
        this.memberCollegiateSku = environment.postEventMembershipCollegiate;
        this.memberJuniorSku = environment.postEventMembershipJunior;
        this.riderSurchargeVariants = {};
        this.licenseMembershipVariants = {};
        this.variantsLoaded = false;
    }
    Object.defineProperty(PostEventSummaryComponent.prototype, "eventId", {
        get: function () { return this.event.event_id; },
        enumerable: true,
        configurable: true
    });
    PostEventSummaryComponent.prototype.ngOnInit = function () {
        // commented out for MBR-2992
        // if (this.isSeries) {
        //   this.subDisciplines = this.eventDate.event_date_sub_disciplines;
        // } else {
        //   this.eventDates.forEach(eventDate => {
        //     eventDate.event_date_sub_disciplines.forEach(subDisc => this.subDisciplines.push(subDisc));
        //   });
        // }
        this.getSkus();
        this.getVariants();
        this.lateFee = this.postEventService.checkLateFee(this.isSeries, this.eventDates, this.eventDate);
    };
    PostEventSummaryComponent.prototype.getSkus = function () {
        var _this = this;
        this.skus = [
            this.memberStandardSku,
            this.memberPremiumSku,
            this.memberCollegiateSku,
            this.memberJuniorSku
        ];
        var skus = environment.disciplineSkus[this.event.event_discipline];
        Object.keys(skus).forEach(function (key) {
            if (key.includes('riderSurcharge')) {
                _this.riderSurchargeSkus[key] = skus[key];
                _this.skus.push(skus[key]);
            }
        });
        // Membership SKUs assigned earlier (not discipline-specific)
        this.oneDayOnSiteSku = skus.oneDayOnSiteLic;
        this.oneDayOnlineSku = skus.oneDayOnlineLic;
        this.lateFeeSku = skus.permitLateFee;
        this.minimumRiderSurchargeSku = skus.minimumRiderSurcharge;
        this.skus.push(this.oneDayOnSiteSku);
        this.skus.push(this.oneDayOnlineSku);
        this.skus.push(this.lateFeeSku);
        this.skus.push(this.minimumRiderSurchargeSku);
    };
    PostEventSummaryComponent.prototype.getVariants = function () {
        var _this = this;
        this.eventListing.getDisciplines().subscribe(function (data) {
            var discs = _this.eventListing.filterDisciplines(data);
            _this.disciplineName = _this.eventListing.getDisciplineName(discs, _this.event.event_discipline);
        });
        this.shopifyService.getShopifyProductVariants({ skus: this.skus }).subscribe(function (variants) {
            _this.variants = variants;
            Object.keys(_this.riderSurchargeSkus).forEach(function (key) {
                _this.riderSurchargeVariants[key] = _this.findVariant(variants, _this.riderSurchargeSkus[key]);
            });
            _this.oneDayOnSiteVariant = _this.findVariant(variants, _this.oneDayOnSiteSku);
            _this.oneDayOnlineVariant = _this.findVariant(variants, _this.oneDayOnlineSku);
            _this.lateFeeVariant = _this.findVariant(variants, _this.lateFeeSku);
            _this.minimumRiderSurchargeVariant = _this.findVariant(variants, _this.minimumRiderSurchargeSku);
            _this.memberStandardVariant = _this.findVariant(variants, _this.memberStandardSku);
            _this.memberPremiumVariant = _this.findVariant(variants, _this.memberPremiumSku);
            _this.memberCollegiateVariant = _this.findVariant(variants, _this.memberCollegiateSku);
            _this.memberJuniorVariant = _this.findVariant(variants, _this.memberJuniorSku);
            _this.licenseMembershipVariants = {
                post_event_one_day_on_site: _this.oneDayOnSiteVariant,
                post_event_one_day_online: _this.oneDayOnlineVariant,
                post_event_membership_standard: _this.memberStandardVariant,
                post_event_membership_premium: _this.memberPremiumVariant,
                post_event_membership_collegiate: _this.memberCollegiateVariant,
                post_event_membership_junior: _this.memberJuniorVariant
            };
            _this.variantsLoaded = true;
        });
    };
    PostEventSummaryComponent.prototype.findVariant = function (variants, sku) {
        return variants.find(function (variant) { return sku === variant.sku; });
    };
    PostEventSummaryComponent.prototype.label = function (key) {
        return this.postEventService.part1Labels[key];
    };
    PostEventSummaryComponent.prototype.value = function (key) {
        switch (this.postEvent[key]) {
            case 'Y':
            case true: return 'Yes';
            case 'N':
            case false: return 'No';
            case 'I': return 'I don\'t know';
            default: return this.postEvent[key];
        }
    };
    PostEventSummaryComponent.prototype.nullValue = function (key) {
        var val = this.postEvent[key];
        return val === null || (typeof (val) === 'object' && !val.length);
    };
    Object.defineProperty(PostEventSummaryComponent.prototype, "displayLicenseMemberships", {
        get: function () {
            var _this = this;
            return !this.licenseMembershipKeys.some(function (key) { return _this.nullValue(key); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventSummaryComponent.prototype, "displayFees", {
        get: function () {
            return !this.nullValue('post_event_participants') && !this.nullValue('post_event_riders');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventSummaryComponent.prototype, "displayPayBtn", {
        get: function () {
            return (this.draftOrder &&
                this.postEvent.permit_post_event_status === 'payment-due' || this.postEvent.permit_post_event_status === 'payment-late');
        },
        enumerable: true,
        configurable: true
    });
    PostEventSummaryComponent.prototype.displayLicenseMembershipRow = function (key) {
        if (key === 'post_event_membership_standard') {
            return this.postEvent[key] > 0;
        }
        return true;
    };
    // commented out for MBR-2992
    // subDisciplineName(id: number) {
    //   return this.subDisciplines.find(subDisc => subDisc.subdiscipline_id === id).subdiscipline_name;
    // }
    PostEventSummaryComponent.prototype.nonSeriesDate = function (id) {
        return this.eventDates.find(function (eventDate) { return eventDate.event_date_id === id; }).event_date_start;
    };
    PostEventSummaryComponent.prototype.chargable = function (key) {
        return !this.excludeFromCharges.includes(key);
    };
    PostEventSummaryComponent.prototype.riderSurchargeCost = function (riders) {
        var rsVariants = this.riderSurchargeVariants;
        var skuPartial = this.disciplineName.toUpperCase().replace(/ /g, '-');
        var rsKey = Object.keys(rsVariants).find(function (key) { return rsVariants[key].sku === "RIDER-SUR-" + skuPartial; });
        // TODO: @Brennan - Remove fallback price once variant subdiscipline_id working?
        return rsKey ? this.riderSurchargeVariants[rsKey].price : 5.05;
    };
    PostEventSummaryComponent.prototype.riderSurchargeTotal = function (riders) {
        var ridersTotal = typeof (riders.riders_unique) === 'string' ? parseFloat(riders.riders_unique) : riders.riders_unique;
        var total = this.riderSurchargeCost(riders) * ridersTotal;
        this.riderSurchargeTotals[riders.subdiscipline_id] = total;
        return total;
    };
    PostEventSummaryComponent.prototype.licenseMembershipCost = function (key) {
        return this.licenseMembershipVariants[key].price;
    };
    PostEventSummaryComponent.prototype.licenseMembershipTotal = function (key) {
        var quantity = this.postEvent[key];
        var total = this.licenseMembershipCost(key) * quantity;
        if (this.chargable(key)) {
            if (/one_day/.test(key)) {
                this.licenseTotals[key] = total;
            }
            else if (/membership/.test(key)) {
                this.membershipTotals[key] = total;
            }
        }
        return total;
    };
    PostEventSummaryComponent.prototype.licenseMembershipTitle = function (key) {
        switch (key) {
            case 'post_event_one_day_on_site': return 'One Day License: On-Site';
            case 'post_event_one_day_online': return 'One Day License: Online Bike Reg';
            // TODO: Consider pulling title from Shopify membership products?
            // ^^ One day license titles should remain static, as the requested form titles do not exactly match the product titles
            case 'post_event_membership_standard': return 'Post Event Membership: Standard';
            case 'post_event_membership_premium': return 'Post Event Race Membership';
            case 'post_event_membership_collegiate': return 'Post Event Race Membership: Collegiate';
            case 'post_event_membership_junior': return 'Post Event Race Membership: Junior';
        }
    };
    PostEventSummaryComponent.prototype.groupTotals = function (group) {
        var total = 0;
        Object.values(group).forEach(function (value) { return total += value; });
        return total;
    };
    Object.defineProperty(PostEventSummaryComponent.prototype, "applyLateFee", {
        get: function () {
            return (this.lateFee || !!this.postEvent.post_event_late_fee) && !this.postEvent.post_event_late_fee_dismissed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventSummaryComponent.prototype, "lateFeePrice", {
        get: function () {
            return this.applyLateFee ? parseFloat(this.lateFeeVariant.price) : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventSummaryComponent.prototype, "isSeriesFirstDay", {
        get: function () {
            return this.isSeries && this.eventDate.event_date_first;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventSummaryComponent.prototype, "finalRiderSurchargeTotal", {
        get: function () {
            var total = this.groupTotals(this.riderSurchargeTotals);
            var surchargeApplicable = !this.isSeries || this.isSeriesFirstDay;
            return (surchargeApplicable && total < this.minimumRiderSurchargePrice) ? this.minimumRiderSurchargePrice : total;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventSummaryComponent.prototype, "minimumRiderSurchargePrice", {
        get: function () {
            return this.minimumRiderSurchargeVariant ? parseInt(this.minimumRiderSurchargeVariant.price) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventSummaryComponent.prototype, "subtotal", {
        get: function () {
            if (this.draftOrder) {
                return this.draftOrder.subtotal_price;
            }
            else {
                var subtotal_1 = 0;
                this.orders.forEach(function (order) { return subtotal_1 += parseFloat(order.subtotal_price); });
                return subtotal_1;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventSummaryComponent.prototype, "tax", {
        get: function () {
            if (this.draftOrder) {
                return this.draftOrder.total_tax;
            }
            else {
                var tax_1 = 0;
                this.orders.forEach(function (order) { return tax_1 += parseFloat(order.total_tax); });
                return tax_1;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventSummaryComponent.prototype, "total", {
        get: function () {
            if (this.draftOrder) {
                return this.draftOrder.total_price;
            }
            else {
                var total_1 = 0;
                this.orders.forEach(function (order) { return total_1 += parseFloat(order.total_price); });
                return total_1;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventSummaryComponent.prototype, "totalPaid", {
        get: function () {
            var totalPaid = 0;
            this.orders.forEach(function (order) { return totalPaid += parseFloat(order.total_price); });
            return totalPaid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventSummaryComponent.prototype, "totalDue", {
        get: function () {
            return this.total - this.totalPaid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventSummaryComponent.prototype, "hasDiscounts", {
        get: function () {
            return (!!this.ordersWithDiscounts.length ||
                (this.draftOrder &&
                    (!!this.draftOrder.applied_discount || this.draftOrder.line_items.some(function (item) { return !!item.applied_discount; }))));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventSummaryComponent.prototype, "ordersWithDiscounts", {
        get: function () {
            return this.orders ? this.orders.filter(function (order) { return order.discount_applications.length; }) : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventSummaryComponent.prototype, "totalDiscount", {
        get: function () {
            var amount = 0;
            this.ordersWithDiscounts.forEach(function (order) { return amount += parseFloat(order.total_discounts); });
            if (this.draftOrder) {
                var draftOrderDiscount = this.draftOrder.applied_discount ? parseFloat(this.draftOrder.applied_discount.amount) : 0;
                amount += draftOrderDiscount;
                var lineItemDiscounts_1 = 0;
                this.draftOrder.line_items.forEach(function (item) {
                    if (item.applied_discount) {
                        lineItemDiscounts_1 += parseFloat(item.applied_discount.amount);
                    }
                    amount += lineItemDiscounts_1;
                });
            }
            return amount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostEventSummaryComponent.prototype, "appliedDiscounts", {
        get: function () {
            var _this = this;
            var discounts = [];
            if (this.orders) {
                this.ordersWithDiscounts.forEach(function (order) {
                    order.discount_applications.forEach(function (discount) {
                        if (discount.description) {
                            discounts.push(_this.discountDescription(discount));
                        }
                        else if (discount.code) {
                            discounts.push(discount.code);
                        }
                    });
                });
            }
            if (this.draftOrder) {
                this.draftOrder.line_items.forEach(function (item) {
                    if (item.applied_discount) {
                        discounts.push(_this.discountDescription(item.applied_discount));
                    }
                });
                if (this.draftOrder.applied_discount) {
                    discounts.push(this.discountDescription(this.draftOrder.applied_discount));
                }
            }
            return discounts;
        },
        enumerable: true,
        configurable: true
    });
    PostEventSummaryComponent.prototype.discountDescription = function (discount) {
        return discount.title ? discount.title + " - " + discount.description : discount.description;
    };
    PostEventSummaryComponent.prototype.toCheckout = function () {
        if (this.draftOrder) {
            window.open(this.draftOrder.invoice_url);
        }
    };
    return PostEventSummaryComponent;
}());
export { PostEventSummaryComponent };
