<h4>Payments</h4>

<!-- Loader -->
<app-loader *ngIf="loading" [small]="true" [align]="'left'"></app-loader>

<!-- Empty State -->
<div *ngIf="!loading && !draftOrders.length && !orders.length" class="row">
  <p class="col-12">There are no payments for this event.</p>
</div>

<div *ngIf="!loading && (draftOrders.length || orders.length)">
  <p class="mt-3 small">Payment statuses may take approximately 5 minutes to update after payments are made.</p>
  <br>
  <div *ngFor="let draftOrder of draftOrders" class="mb-3">
    <app-payment-accordion
      [event]="event"
      [draftOrder]="draftOrder"
      [userRole]="userRole"
      (deleteDraftOrder)="onDraftOrderDelete($event)"
    ></app-payment-accordion>
  </div>
  <div *ngFor="let order of orders" class="mb-3">
    <app-payment-accordion
      [event]="event"
      [order]="order"
      [userRole]="userRole"
    ></app-payment-accordion>
  </div>
</div>
