<div class="custom-radio {{inline}}">
  <input type="radio"
    class="custom-control-input"
    id="{{id}}"
    name="{{name}}"
    value="{{value}}"
    checked="{{checked}}"
    disabled="{{disabled}}"
  />
  <label for="{{id}}">{{label}}</label>
</div>
