export class Vehicle {
  id: number|string;
  vehicle_make: string;
  vehicle_model: string;
  vehicle_year: string;
  vehicle_vin: string;
  vehicle_type: string;
  vehicle_insurance: string;
  vehicle_state: string;
  vehicle_permit_id: number|string;

  constructor(data) {
    this.id = data.id;
    this.vehicle_make = data.vehicle_make;
    this.vehicle_model = data.vehicle_model;
    this.vehicle_year = data.vehicle_year;
    this.vehicle_vin = data.vehicle_vin;
    this.vehicle_insurance = data.vehicle_insurance;
    this.vehicle_type = data.vehicle_type;
    this.vehicle_state = data.vehicle_state;
    this.vehicle_permit_id = data.vehicle_permit_id;
  }
}
