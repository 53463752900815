import { Component, OnInit, Input, ContentChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { phoneNumberValidator, nameCharactersValidator, emailValidator } from 'src/app/validators';
import { EventListingService, ProfileService } from '../../services';
import { Contact } from '../../classes';

@Component({
  selector: 'app-event-listing-contact',
  templateUrl: './event-listing-contact.component.html',
  styleUrls: []
})
export class EventListingContactComponent implements OnInit {

  @Input() submitted: boolean;

  eventContact: FormGroup;
  recentContact: Contact;

  constructor(
    private formBuilder: FormBuilder,
    public eventListing: EventListingService,
    public profiles: ProfileService
  ) { }

  get f() { return this.eventContact.controls; }

  ngOnInit() {
    this.eventContact = this.formBuilder.group({
      contact_first_name: [ '', [ Validators.required, nameCharactersValidator() ]],
      contact_last_name: [ '', [ Validators.required, nameCharactersValidator() ] ],
      contact_email_1: [ '', [ Validators.required, emailValidator()] ],
      contact_phone_1: [ '', [ phoneNumberValidator() ] ],
    });
    if (this.eventListing.contacts.length !== 0) {
      this.recentContact = this.eventListing.contacts[(this.eventListing.contacts.length - 1)];
      this.assignFormValues(this.recentContact);
    } else {
      this.recentContact = new Contact('', '', '');
      this.assignFormValues(this.recentContact);
      this.eventListing.addContcat(this.recentContact);
    }
    // TODO: Is this needed, or can we remove/refactor this logic?
    this.eventContact.valueChanges.subscribe(data => {
      this.recentContact.contact_first_name = data.contact_first_name;
      this.recentContact.contact_last_name = data.contact_last_name;
      this.recentContact.contact_email_1 = data.contact_email_1;
      this.recentContact.contact_phone_1 = data.contact_phone_1;
      this.recentContact.isValid = this.eventContact.valid;
    });
  }

  assignFormValues(contact: Contact) {
    this.eventContact.setValue({
      contact_first_name: contact.contact_first_name,
      contact_last_name: contact.contact_last_name,
      contact_email_1: contact.contact_email_1,
      contact_phone_1: contact.contact_phone_1
    });
  }

  composeContact() {
    if (this.eventListing.contacts.length > this.eventListing.contactsLimit) {
      return;
    }
    if (this.eventContact.valid) {
      this.recentContact = new Contact();
      this.eventListing.addContcat(this.recentContact);
      this.eventContact.reset();
      this.eventListing.validateContacts = false;
    } else {
      this.eventListing.validateContacts = true;
    }
    console.log(this.eventListing.contacts);
  }
  removeContact(index: any) {
    this.eventListing.removeContact(index);
  }

  editContact(index: number) {
    if (this.eventContact.valid || this.eventListing.contacts.length > 3 ) {
      let selectedContcat: Array<Contact>;
      selectedContcat = this.eventListing.contacts.splice(index, 1);
      const recentContact = new Contact();
      Object.assign(recentContact, selectedContcat[0]);
      this.composeContact();
      this.f.contact_first_name.setValue(recentContact.contact_first_name);
      this.f.contact_last_name.setValue(recentContact.contact_last_name);
      this.f.contact_email_1.setValue(recentContact.contact_email_1);
      this.f.contact_phone_1.setValue(recentContact.contact_phone_1);
    } else {
      this.eventListing.validateContacts = true;
    }
  }
}
