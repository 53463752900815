import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Alert } from 'src/app/classes';
import { Event, Router, NavigationEnd } from '@angular/router';
import { AppShellService } from './services/app-shell.service';
import { AuthGuardService, ShopifyService } from './shared';
import { AuthenticationService, EventListingService, EventService } from './services';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent implements OnInit {
  title = 'USAC Permitting';
  env = environment.env;
  currentRoute: string;
  stepDisplayRoutes: Array<string>;
  currentStep: number;
  displaySteps = false;
  cartDisplayRoutes: Array<string>;
  displayCart: boolean;
  cartDisplayFixedRoutes: Array<string>;
  displayFixedCart: boolean;
  checkoutDisabled = false;
  eventPermitAnnouncmentRoutes: Array<string>;
  unauthorizedRoutes: Array<string>;
  cartAlerts: Alert[] = [];
  // cartAlertClass = 'info';
  otherAlerts: Alert[] = [];
  otherAlertClass = 'info';

  authLoading = false;
  checkoutLoading = false;

  constructor(
    public appShell: AppShellService,
    private cookie: CookieService,
    private authService: AuthenticationService,
    private authGuardService: AuthGuardService,
    private eventListingService: EventListingService,
    private eventService: EventService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private shopifyService: ShopifyService,
    private device: DeviceDetectorService
  ) { }

  ngOnInit() {
    if (this.authGuardService.expectedNonce()) {
      this.authGuardService.removeNonce();
      window.location.assign(
        environment.adminUrl + `/login?redirectUrl=${environment.publicUrl}`
      );
    }

    if (!this.cookie.check('USACLOGIN')) {
      return this.authService.doLogin(window.location.href);
    }

    // Include here any routes where the application steps should be displayed
    this.stepDisplayRoutes = [
      '/event/listing',
      '/review',
      '/permit-application',
      '/event-incentives',
      '/insured-certificates',
      '/add-ons'
    ];
    // Include here any routes where the cart should be displayed
    this.cartDisplayRoutes = [
      '/insured-certificates',
      '/add-ons'
    ];
    // Make cart fixed for specific routes
    this.cartDisplayFixedRoutes = [
      '/queue'
    ];

    this.eventPermitAnnouncmentRoutes = [
      // TODO: Determine if these need to be updated to the '/event/:eventId' pattern
      '/event/listing',
      '/review'
    ];

    this.unauthorizedRoutes = [
      '/unauthorized'
    ];

    this.router.events.forEach((event: Event) => {
      if (event instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: this.routeTitle || environment.publicUrl + event.url
        };

        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  get routeTitle(): string {
    const currentRoute = this.router.routerState.snapshot.root;
    let child = currentRoute.firstChild;
    // For nested routing modules, drill down to the destination child route

    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.data && child.data.title) {
        return child.data.title;
      } else {
        return null;
      }
    }

    return null;
  }

  onRouteLoad(event) {
    this.currentRoute = this.router.url;
    this.currentStep = event.currentStep;
    this.toggleSteps();
    this.toggleCart();
    this.setFixedCart();
    this.displayCartAlert();
    this.displayOtherAlerts();
    this.disableCheckout();
  }

  toggleSteps() {
    if (this.stepDisplayRoutes.some(route => this.currentRoute.includes(route))) {
      this.displaySteps = this.eventListingService.mode === 'create' ? true : false;
    } else {
      this.displaySteps = false;
    }
  }
    // Commented to allow open on desktop and close on mobile behavior. - NA
  toggleCart() {
    if (this.device.isMobile()) {
      // console.log('is mobile, toggle');
      this.displayCart = true;
    } else {
      // console.log('is desktop. no toggle');
      this.displayCart = !this.cartDisplayRoutes.some(route => this.currentRoute.includes(route));
      // this.displayCart = false;
    }
  }
  closeCart() {
    this.displayCart = false;
  }
  setFixedCart() {
    if (this.cartDisplayFixedRoutes.includes(this.currentRoute)) {
      this.displayFixedCart = true;
    } else {
      this.displayFixedCart = false;
    }
  }

  displayOtherAlerts() {
    this.otherAlerts = [];
    // if (this.eventPermitAnnouncmentRoutes.some(route => this.currentRoute.includes(route))) {
    //   this.otherAlerts.push(
    //     new Alert(
    //       this.otherAlertClass, 'Some general announcement here',
    //       'Notice', 'warning', null, null, null, true
    //     )
    //   );
    // }

    if (!this.cookie.check('USACLOGIN') && this.unauthorizedRoutes.includes(this.currentRoute)) {
      this.otherAlerts.push(
        new Alert(
          this.otherAlertClass, 'Your token has expired', 'Unauthorized', 'user', null, null, null, false
        )
      );
    }
  }

  displayCartAlert() {
    this.cartAlerts = [];
    if (this.cartDisplayFixedRoutes.includes(this.router.url)) {
      this.cartAlerts.push(
        new Alert(
          this.otherAlertClass, 'Welcome to the new event management system - events created before 4/16/2020 can be managed' +
          ' <a target="_blank" href="https://legacy.usacycling.org/myusac/index.php?pagename=eventpermit">here</a>.',
          null, 'flag', null, null, null, true
        )
      );
    }
    if (this.currentRoute.includes('overview')) {
      if (this.eventIdFromRoute) {
        const eventId = this.eventIdFromRoute;
        this.shopifyService.getShopifyDraftOrders({ event_id: eventId, incomplete: true }).subscribe(drafts => {
          if (drafts.length > 0) {
            this.eventService.getEventById(eventId).subscribe(event => {
              const start = Date.parse(event.event_start_date);
              const today = new Date().getTime();
              // If event starts within 2 weeks of today, change class from 'info' to 'warning'.
              const alertClass = ( ((start - today) / 24 / 60 / 60 / 1000) <= 14 ) ? 'warning' : 'info';
              const p = this.plural(drafts);
              const rp = this.reversePlural(drafts);
              this.cartAlerts.push(
                new Alert(
                  alertClass,
                  `Your recent order${p} require${rp} payment. Please complete your purchase${p}.`,
                  'Payment Notice', 'shopping-cart', 'View Payments', `/event/${eventId}/overview#payments`, null, false
                )
              );
            });
          }
        });
      }
    }
  }

  disableCheckout() {
    this.checkoutDisabled = this.currentRoute.includes('insured-certificates');
  }

  plural(drafts) {
    return drafts.length > 1 ? 's' : '';
  }

  reversePlural(drafts) {
    return drafts.length > 1 ? '' : 's';
  }

  get eventIdFromRoute() {
    return this.router.routerState.snapshot.root.firstChild.params.eventId;
  }

  onCheckoutLoad($event: boolean) {
    this.checkoutLoading = $event;
  }
}
