<!-- TODO: Add loader & disable form while submitting status update -->

<!-- Close Modal -->
<app-modal *ngIf="closeEvent" [id]="'close-event-modal'" [title]="'Close Event'" [isOpen]="open" [allowClose]="false" (close)="exitCloseModal()">
  <app-modal-body *ngIf="!closed">
    <p>This permitted event currently has {{outstandingItems}}. Are you sure you want to close this event?</p>
    <form [formGroup]="closeEventForm">
      <div class="row">
        <div class="col-12 mt-3">
          <app-checkbox
            formControlName="event_closed_without_paperwork"
            [id]="'event_closed_without_paperwork'"
            [name]="'event_closed_without_paperwork'"
            [label]="'I acknowledge that I am closing an event with open items.'"
            [checked]="true"
            [formSubmitted]="submitted"
            [required]="true"
            [errors]="f.event_closed_without_paperwork.errors"
          ></app-checkbox>
        </div>
        <div class="col-12">
          <app-text-area
            formControlName="event_closed_comments"
            [name]="'event_closed_comments'"
            [id]="'event_closed_comments'"
            [label]="'Note / Explanation'"
            [placeholder]="'Explain why this event is being closed with open items...'"
            [minHeight]="'100'"
            [maxLength]="'500'"
            [formSubmitted]="submitted"
            [required]="true"
            [errors]="f.event_closed_comments.errors"
          ></app-text-area>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 text-left">
          <app-button [mode]="'button'" [type]="'btn-secondary'" (click)="exitCloseModal()" [disabled]="disableBtns">Exit</app-button>
        </div>
        <div class="col-sm-6 text-right">
          <app-button (click)="onSubmit()" [disabled]="disableCloseBtn || disableBtns">Close Event</app-button>
        </div>
        <div *ngIf="showError" class="col-12 close-cancel-error small text-right text-danger">Error occurred</div>
      </div>
    </form>
  </app-modal-body>
  <app-modal-body *ngIf="closed">
    <p>This event has been closed. You will be redirected to your Event Queue in {{timer}} second{{sec}}.</p>
  </app-modal-body>
</app-modal>

<!-- Cancel Modal -->
<app-button *ngIf="cancelEvent"
  (click)="openModal()"
>Cancel Event</app-button>

<app-modal *ngIf="cancelEvent" [id]="'cancel-modal'" [title]="'Cancel Event'" [isOpen]="open" [allowClose]="false" (close)="exitCancelModal()">
  <app-modal-body *ngIf="!cancelled">
    <p *ngIf="listing">Are you sure you want to cancel your event?</p>
    <p *ngIf="permit">Cancelling your event will require a new permit and will remove your event from the calendar. Are you sure you want to cancel your event permit?</p>
    <div class="row">
      <div class="col-sm-6 text-left">
        <app-button [type]="'btn-secondary'" (click)="exitCancelModal()" [disabled]="disableBtns">Exit</app-button>
      </div>
      <div class="col-sm-6 text-right">
        <app-button (click)="updateStatus('cancelled')" [disabled]="disableBtns">Cancel Event</app-button>
      </div>
      <div *ngIf="showError" class="col-12 close-cancel-error small text-right text-danger">Error occurred</div>
    </div>
  </app-modal-body>
  <app-modal-body *ngIf="cancelled">
    <p>This event has been cancelled. You will be redirected to your Event Queue in {{timer}} second{{sec}}.</p>
  </app-modal-body>
</app-modal>
