<app-button
  [type]="'btn-sm btn-secondary'"
  style="flex:2;"
  (click)="openModal()"
  class="col-sm-4 col-md-3 col-lg-2 text-center add-veh-mng-btn"
>Manage</app-button>
<app-modal class="auto-notificaton" [id]="'auto-notificaton'" [title]="title" [isOpen]="modalOpen" (close)="closeModal()">
  <app-modal-body>
    <div class="row">
      <div class="col-lg-6 add-driver-component">
        <app-add-driver
          [type]="type"
          [permitId]="permitId"
          [drivers]="drivers"
          (quantityChange)="onQuantityChange($event)"
        ></app-add-driver>
      </div>
      <div class="col-lg-6 add-vehicle-component">
        <app-add-vehicle
        [type]="type"
        [quantity]="quantity"
        [permitId]="permitId"
        [vehicles]="vehicles"
        (quantityChange)="onQuantityChange($event)"
      ></app-add-vehicle>
      </div>
    </div>
<br>
<br>
<p class="px-2 small" [ngClass]="{'text-warning': !autoMotoComplete}">
  * Please ensure all driver and motor vehicle information (for both auto and motorcycle liability, if applicable) is complete prior to submitting to the insurance company.
</p>
<div class="d-flex justify-content-between">
  <app-button [type]="'btn-light'" (click)="closeModal()">Save & Exit</app-button>
  <app-button [type]="'btn-secondary'" [disabled]="!autoMotoComplete" (click)="onSubmit()">Submit to Insurance</app-button>
</div>
  </app-modal-body>
</app-modal>
