/* tslint:disable no-use-before-declare */

import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { SelectOption } from '../../classes';
import { FormFieldErrors } from '../../classes';

const noop = () => {};

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true
    }
  ]
})
export class MultiSelectComponent implements OnInit {
  currentValue: SelectOption[] = [];

  dropDownSettings = {};

  defaultSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 10,
    allowSearchFilter: true
  };

  @Input() id: string;
  @Input() label: string;
  @Input() name: string;
  @Input() options: SelectOption[];
  @Input() placeholder: string;
  @Input() settings: object = {};
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() formControlName: string;
  @Input() formSubmitted: boolean;
  @Input() errors: FormFieldErrors;
  @Input() icon: boolean;
  @Input() iconClass: string;
  @Input() tooltipContent: string;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input()
  get value() {
    return this.currentValue;
  }

  set value(val) {
    this.currentValue = val;
    this.change.emit(this.currentValue);
    this.valueChange.emit(this.currentValue);
    this.onChangeCallback(this.currentValue);
  }

  @Output()
  change: EventEmitter<Array<SelectOption>> = new EventEmitter<Array<SelectOption>>();

  @Output()
  valueChange: EventEmitter<Array<SelectOption>> = new EventEmitter<Array<SelectOption>>();

  @Output()
  filterChange: EventEmitter<object> = new EventEmitter<any>();

  @Output()
  dropDownClose: EventEmitter<object> = new EventEmitter<any>();

  @Output()
  select: EventEmitter<SelectOption> = new EventEmitter<any>();

  @Output()
  deSelect: EventEmitter<SelectOption> = new EventEmitter<any>();

  @Output()
  selectAll: EventEmitter<Array<SelectOption>> = new EventEmitter<Array<any>>();

  @Output()
  deSelectAll: EventEmitter<Array<SelectOption>> = new EventEmitter<Array<any>>();

  writeValue(value: any) {
    this.currentValue = value;
    this.onChangeCallback(value);
    // for (const option of this.options) {
    //   if (this.currentValue.includes(option.value)) {
    //     option.checked = true;
    //   }
    // }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  constructor() {}

  ngOnInit() {
    this.dropDownSettings = Object.assign({}, this.defaultSettings, this.settings);
  }
}
