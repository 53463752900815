import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ReportsService, } from '../../services/reports.service';
import { CsvDownloadService } from '../../shared/services';
import { ProfileService } from 'src/app/services';
import { Alert } from 'src/app/classes';
var ReportsComponent = /** @class */ (function () {
    function ReportsComponent(fb, reports, csvService, profileService) {
        this.fb = fb;
        this.reports = reports;
        this.csvService = csvService;
        this.profileService = profileService;
        this.tabOrientation = 'horizontal';
        this.breakpoint = 768;
        this.isLoadingTransactions = false;
        this.isLoadingAssignments = false;
        this.alert = [
            new Alert('warning', 'No data found. Please adjust your search criteria.', null, null, null, null, null, false)
        ];
        this.transactionReport = this.fb.group({
            event_start_date: [''],
            event_end_date: [''],
            organizer_comp_id: [''],
            organizer_first: [''],
            organizer_last: ['']
        });
        this.assignmentReport = this.fb.group({
            event_start_date: [''],
            event_end_date: [''],
            event_id: [''],
            event_zip: [''],
            states: [''],
            chief_ref_first: [''],
            chief_ref_last: [''],
            chief_ref_profile_id: ['']
        });
    }
    Object.defineProperty(ReportsComponent.prototype, "isUsacAdmin", {
        get: function () {
            return this.userRole === 'usac_admin';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsComponent.prototype, "tr", {
        get: function () { return this.transactionReport.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsComponent.prototype, "ar", {
        get: function () { return this.assignmentReport.controls; },
        enumerable: true,
        configurable: true
    });
    ReportsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setTabOrientation(window.innerWidth);
        this.profileService.getCurrentUser().subscribe(function (profile) {
            _this.profileService.getUserPermissions().subscribe(function (permissions) {
                _this.userRole = _this.profileService.setUserRole(permissions);
                // Scope requested data to current event organizer if non-admin
                if (!_this.isUsacAdmin) {
                    _this.tr.organizer_first.setValue(profile.profile_first_name);
                    _this.tr.organizer_last.setValue(profile.profile_last_name);
                    // Fallback for when Comp ID is phased out
                    if (profile.profile_comp_id) {
                        _this.tr.organizer_comp_id.setValue(profile.profile_comp_id);
                    }
                }
            });
        });
        // console.log('stuff', this.isLoadingAssignments, this.isLoadingTransactions);
    };
    ReportsComponent.prototype.onResize = function (event) {
        this.setTabOrientation(event.target.innerWidth);
    };
    ReportsComponent.prototype.setTabOrientation = function (width) {
        this.tabOrientation = width >= this.breakpoint ? 'horizontal' : 'vertical';
    };
    ReportsComponent.prototype.clearError = function () {
        this.csvService.displayError = false;
    };
    ReportsComponent.prototype.getTransactionsReportParams = function () {
        console.log('form data', this.transactionReport.value);
        var _a = this.transactionReport.value, event_start_date = _a.event_start_date, event_end_date = _a.event_end_date, organizer_comp_id = _a.organizer_comp_id, organizer_first = _a.organizer_first, organizer_last = _a.organizer_last;
        var params = {
            checkout_date_range: {
                start_date: event_start_date,
                end_date: event_end_date
            },
            no_paginate: true,
            organizer_comp_id: organizer_comp_id,
            organizer_first: organizer_first,
            organizer_last: organizer_last
        };
        console.log('params', params);
        return params;
    };
    ReportsComponent.prototype.getAssignmentsReportParams = function () {
        var params = this.assignmentReport.value;
        Object.assign(params, {
            no_paginate: true,
        });
        return params;
    };
    ReportsComponent.prototype.onTransactionReportSubmit = function () {
        var _this = this;
        this.isLoadingTransactions = true;
        this.clearError();
        this.reports.getTransactions(this.getTransactionsReportParams()).subscribe(function (data) {
            console.log('loaded transactions reports', data);
            data = data.map(function (row) {
                if (row.event_organizer) {
                    var _a = row.event_organizer, profile_email = _a.profile_email, profile_first_name = _a.profile_first_name, profile_last_name = _a.profile_last_name;
                    row.event_organizer = profile_first_name + " " + profile_last_name + " <" + profile_email + ">";
                }
                return row;
            });
            console.log('translated organizers for transactions reports', data);
            _this.csvService.downloadFile(data, 'USACycling-TransactionsReport.csv');
            _this.isLoadingTransactions = false;
        });
    };
    ReportsComponent.prototype.onAssignmentReportSubmit = function () {
        var _this = this;
        this.isLoadingAssignments = true;
        this.clearError();
        this.reports.getOfficialAssignments(this.getAssignmentsReportParams()).subscribe(function (data) {
            console.log('loaded offical assignments reports', data);
            data = data.map(function (row) {
                if (row.event && row.event.event_name) {
                    row.event = row.event.event_name;
                }
                if (row.chief_ref) {
                    var _a = row.chief_ref, profile_email = _a.profile_email, profile_first_name = _a.profile_first_name, profile_last_name = _a.profile_last_name;
                    row.chief_ref = profile_first_name + " " + profile_last_name + " <" + profile_email + ">";
                }
                return row;
            });
            console.log('translated chief ref & event for official assignments report', data);
            _this.csvService.downloadFile(data, 'USACycling-OfficialAssignmentsReport.csv');
            _this.isLoadingAssignments = false;
        });
    };
    return ReportsComponent;
}());
export { ReportsComponent };
