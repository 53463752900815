import { Component, OnInit, Input, Output, EventEmitter, forwardRef, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormFieldErrors } from 'src/app/classes';
import { NgbDatepickerConfig, NgbDateParserFormatter, NgbDateAdapter, NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateFormatter } from 'src/app/providers/dateFormatter';
import { CustomDateAdapter } from 'src/app/providers/dateAdapter';

const noop = () => {};

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    },
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateFormatter
    },
    {
      provide: NgbDateAdapter,
      useClass: CustomDateAdapter
    }
  ]
})
export class DatepickerComponent implements OnInit, OnChanges {
  currentValue = {};

  @Input() name: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() formControlName: string;
  @Input() formSubmitted: boolean;
  @Input() errors: FormFieldErrors;
  @Input() minDate: NgbDate;
  @Input() maxDate: NgbDate;
  @Input() markDisabled: NgbDate;
  @Input() oneYearLimit: boolean;
  @Input() disabled: boolean;
  @Input() iconClass: string;
  @Input() tooltipContent: string;
  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  min: NgbDate;
  max: NgbDate;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input()
  get value() {
    return this.currentValue;
  }

  set value(val: any) {
    this.currentValue = val;
    this.change.emit(this.currentValue);
    this.valueChange.emit(this.currentValue);
    this.onChangeCallback(this.currentValue);
  }

  @Output()
  change: EventEmitter<object> = new EventEmitter<object>();

  @Output()
  valueChange: EventEmitter<object> = new EventEmitter<object>();

  writeValue(value: any) {
    this.currentValue = value;
    this.onChangeCallback(value);
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  constructor(private calendar: NgbCalendar) { }

  ngOnInit() {
    if (!this.placeholder) {
      this.placeholder = 'mm/dd/yyyy';
    }

    if (this.oneYearLimit) {
      this.max = this.calendar.getToday();
      this.max.year = this.max.year + 1;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.minDate) { this.min = changes.minDate.currentValue; }
    if (changes.maxDate) { this.max = changes.maxDate.currentValue; }
  }

}
