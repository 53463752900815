import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { BaseService } from './base-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
var ActivityService = /** @class */ (function (_super) {
    tslib_1.__extends(ActivityService, _super);
    function ActivityService(http, cookie) {
        var _this = _super.call(this, http, cookie) || this;
        _this.http = http;
        _this.cookie = cookie;
        _this.history = [];
        _this.notifications = [];
        return _this;
    }
    ActivityService.prototype.getEventHistory = function (eventId) {
        var _this = this;
        return this.http.get(this.url("event/history/" + eventId), this.options)
            // return this.http.get<Activity[]>(`http://127.0.0.1:8000/api/v1/event/history/${eventId}`, this.options)
            .pipe(tap(function (_) { return _this.log('fetched event History'); }), catchError(this.handleError('getEventHistory', [])));
    };
    ActivityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActivityService_Factory() { return new ActivityService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: ActivityService, providedIn: "root" });
    return ActivityService;
}(BaseService));
export { ActivityService };
