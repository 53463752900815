import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { PermitService } from 'src/app/services';
import {
  CalendarLabel,
  CalendarLabelQuestion,
  Event,
  EventIncentivesFormAnswer,
} from 'src/app/classes';

@Component({
  selector: 'app-event-incentives-form',
  templateUrl: './event-incentives-form.component.html',
  styles: []
})
export class EventIncentivesFormComponent implements OnInit, OnDestroy {

  labelsForm: FormGroup;
  questionsForm: FormGroup;
  labelQuestionMap = {};
  labelsFormSubmitted = false;
  questionsFormSubmitted = false;
  submitting = false;
  saveAndExitSubmitting = false;

  showSaveExit = false;
  navUrl = '';
  routerEvents: Subscription;
  disableSaveAndExitCatch = false;

  get lf() { return this.labelsForm.controls; }
  get qf() { return this.questionsForm.controls; }

  @Input() event: Event;
  @Input() eventId: string;
  @Input() calendarLabels: CalendarLabel[];
  @Input() calendarLabelQuestions: CalendarLabelQuestion[];
  @Input() selectedLabelIds: number[] = [];
  @Input() answers: EventIncentivesFormAnswer[] = [];

  constructor(
    private fb: FormBuilder,
    private permitService: PermitService,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildLabelsForm();
    this.lf.labels.setValue(this.selectedLabelIds);
    this.subscribeToLabelsForm();
    this.buildQuestionsForm();
    this.subscribeToQuestionsForm();
    this.subscribeToRouterEvents();
  }

  ngOnDestroy() {
    this.routerEvents.unsubscribe();
    this.closeSaveExitModal(false);
  }

  buildLabelsForm(): void {
    this.calendarLabels.forEach(label => this.labelQuestionMap[label.id] = []);
    this.labelsForm = this.fb.group({
      labels: []
    });
  }

  subscribeToLabelsForm(): void {
    this.lf.labels.valueChanges.subscribe(labelIds => this.selectedLabelIds = labelIds);
  }

  buildQuestionsForm(): void {
    // Build form based on active label questions (require answers before confirming selection)
    const formControls = {};
    this.calendarLabelQuestions.forEach(question => {
      const existingAnswer = this.answers.find(answer => answer.question_id === question.id);
      const controlName = this.controlName(question);
      formControls[controlName] = existingAnswer ? existingAnswer.answer : false;
      // Generate details control (textarea), if applicable
      if (question.textbox) {
        formControls[this.detailsControlName(question)] = existingAnswer ? existingAnswer.details : null;
      }
    });

    this.questionsForm = this.fb.group(formControls);
  }

  subscribeToQuestionsForm(): void {
    // Subscribe to checkboxes for questions requiring textboxes, to update details control validators/values
    this.questionsWithTextbox.forEach(question => {
      const controlName = this.controlName(question);
      this.qf[controlName].valueChanges.subscribe(value => {
        const detailsControl = this.qf[`${controlName}-details`];
        if (detailsControl) {
          detailsControl.setValidators(value ? [ Validators.required ] : null);
          // Reset detailsControl value to null if question not checked
          if (!value) { detailsControl.setValue(null); }
        }
      });
    });
    Object.entries(this.qf).forEach(([controlName, control]: [string, FormControl]) => {
      const question = this.questionFromControlName(controlName);
      if (question && question.label_id) {
        control.valueChanges.subscribe((value: boolean) => this.handleLabelSelection(question, value));
      }
    });
  }

  handleLabelSelection(question: CalendarLabelQuestion, selected: boolean) {
    const labelId = question.label_id;
    const selectedQuestions = this.labelQuestionMap[labelId];

    if (selected) {
      selectedQuestions.push(question);
    } else {
      const questionIndex = selectedQuestions.findIndex((q: CalendarLabelQuestion) => q.id === question.id);
      if (questionIndex > -1) {
        selectedQuestions.splice(questionIndex, 1);
      }
    }

    const labelIds = this.lf.labels.value;
    const labelIndex = labelIds.findIndex((id: number) => id === labelId);
    if (selectedQuestions.length && labelIndex === -1) {
      this.lf.labels.setValue([ ...labelIds, labelId]);
    } else if (!selectedQuestions.length && labelIndex > -1) {
      labelIds.splice(labelIndex, 1);
      this.lf.labels.setValue(labelIds);
    }
  }

  subscribeToRouterEvents(): void {
    this.routerEvents = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const routeExceptions = [this.permitPart2Link, this.insuredCertsLink, this.overviewLink];
        if (!routeExceptions.includes(event.url) && this.navUrl === '') {
          if (!this.disableSaveAndExitCatch) {
            this.router.navigateByUrl(this.router.url, {replaceUrl: true});
            this.openSaveExitModal(event.url);
          }
        }
      }
    });
  }

  goBack(): void {
    this.router.navigate([this.permitPart2Link]);
  }

  openSaveExitModal(url?: string): void {
    if (url) { this.navUrl = url; }
    this.showSaveExit = true;
  }

  closeSaveExitModal(resetNavUrl: boolean): void {
    this.showSaveExit = false;
    this.saveAndExitSubmitting = false;
    if (resetNavUrl) { this.navUrl = ''; }
  }

  continueNavigation(url = this.navUrl) {
    this.closeSaveExitModal(false);
    setTimeout(() => {
      this.navUrl = url || this.insuredCertsLink;
      this.router.navigate([this.navUrl]);
    }, 50);
  }

  saveAndExit(): void {
    this.onSubmit(true);
  }

  onSubmit(saveAndExit = false): void {
    this.questionsFormSubmitted = true;

    // stop here if form is invalid
    if (this.questionsForm.invalid) {
      return;
    }

    if (saveAndExit) {
      this.saveAndExitSubmitting = true;
    } else {
      this.submitting = true;
    }
    const questionAnswers = this.questionsForm.value;

    // Display alert when answering "No" to required questions
    const newAnswers = [];
    for (const question of this.calendarLabelQuestions) {
      newAnswers.push({
        question_id: question.id,
        answer: questionAnswers[`question-${question.id}`],
        details: questionAnswers[`question-${question.id}-details`] || null
      });
    }

    this.answers = newAnswers;

    // Submit calendar labels & answers
    this.permitService.postCalendarLabels(this.selectedLabelIds, this.eventId).subscribe(labels => {
      if (labels) {
        this.permitService.postCalendarLabelAnswers(this.answers, this.eventId).subscribe(answers => {
          if (answers) {
            if (saveAndExit) {
              this.navUrl = this.navUrl === '' ? this.overviewLink : this.navUrl;
              setTimeout(() => {
                this.disableSaveAndExitCatch = true;
                this.closeSaveExitModal(false);
                this.continueNavigation(this.navUrl);
              }, 50);
            } else {
              // Route to insured certs
              this.router.navigate([this.insuredCertsLink]);
            }
          } else {
            // Handle error
            this.resetSubmitting();
          }
        });
      } else {
        // Handle error
        this.resetSubmitting();
      }
    });
  }

  resetSubmitting(): void {
    this.submitting = false;
    this.saveAndExitSubmitting = false;
  }

  labelById(id: number): CalendarLabel {
    return this.calendarLabels.find(label => label.id === id);
  }

  controlName(question: CalendarLabelQuestion): string {
    return 'question-' + question.id;
  }

  detailsControlName(question: CalendarLabelQuestion): string {
    return this.controlName(question) + '-details';
  }

  questionFromControlName(controlName: string): CalendarLabelQuestion {
    return this.calendarLabelQuestions.find(question => question.id.toString() === controlName.split('-')[1]);
  }

  displayTextbox(question: CalendarLabelQuestion): boolean {
    const checkboxControl = this.qf[this.controlName(question)];
    return question.textbox && !!checkboxControl.value;
  }

  get isRace(): boolean {
    return this.event.isRace;
  }

  get isRide(): boolean {
    return this.event.isRide;
  }

  get questionsWithTextbox(): CalendarLabelQuestion[] {
    return this.calendarLabelQuestions.filter(question => !!question.textbox);
  }

  get faqLink(): string {
    return 'https://s3.amazonaws.com/usac-craft-uploads-production/documents/2021-Event-Incentive-FAQ.pdf';
  }

  get insuredCertsLink(): string {
    return `/event/${this.eventId}/insured-certificates`;
  }

  get permitPart2Link(): string {
    return `/event/${this.eventId}/permit-application/part-2`;
  }

  get overviewLink(): string {
    return `/event/${this.eventId}/overview`;
  }

  get disableBtns(): boolean {
    return this.submitting || this.saveAndExitSubmitting;
  }
}
