import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

import { Alert } from '../../classes';
import { stringify } from 'querystring';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styles: []
})
export class AlertComponent implements OnInit, OnChanges {

  alertArray: Alert[];

  @Input() alerts: Alert[] = [];

  constructor(private router: Router) { }

  ngOnInit() {
    // this.reset();
  }

  ngOnChanges() {
    this.alerts.forEach( alert => {
      if (alert.autoDismissTime) {
        setTimeout(() => this.close(alert), alert.autoDismissTime);
      }
    });
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

  reset() {
    this.alertArray = Array.from(this.alerts);
  }

  btnClass(alert: Alert) {
    const buttonClass = (alert.type === 'warning' || alert.type === 'danger') ? 'primary' : 'secondary';
    return `btn-${buttonClass} btn-sm`;
  }

  toLink(link: string) {
    // External link
    if (link.includes('http')) {
      window.open(link);
    } else {
      const parts = link.split('#');
      // Routes with anchors
      if (parts.length > 1) {
        if (this.router.url.includes(parts[0])) {
          window.location.replace(link);
          window.location.reload();
        } else {
          this.router.navigate([parts[0]], {fragment: parts[1]});
        }
      // Normal routes
      } else {
        this.router.navigate([link]);
      }
    }
  }

}
