import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SelectOption } from '../classes';
import { BaseService } from './base-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
var LocationService = /** @class */ (function (_super) {
    tslib_1.__extends(LocationService, _super);
    function LocationService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.countryOptions$ = new BehaviorSubject([]);
        _this.stateOptions$ = new BehaviorSubject([]);
        return _this;
    }
    LocationService.prototype.getShopifyCountries = function () {
        var _this = this;
        return this.http.get(this.url('shopify/countries'), this.options)
            .pipe(tap(function (countries) {
            _this.log('fetched country list');
            _this.countryOptions = countries.map(function (country) { return new SelectOption(country.code, country.name); });
            _this.countryOptions$.next(_this.countryOptions);
            _this.countries = countries;
        }), catchError(this.handleError('getCountries', null, {})));
    };
    LocationService.prototype.getStates = function () {
        var _this = this;
        return this.http.get(this.url('locations'), this.options)
            .pipe(tap(function (resp) {
            var states = resp.states;
            _this.log('fetched states');
            _this.stateOptions = states.map(function (state) { return new SelectOption(state.code, state.name); });
            _this.stateOptions$.next(_this.stateOptions);
            _this.states = states;
        }), catchError(this.handleError('getStates', null)));
    };
    LocationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocationService_Factory() { return new LocationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: LocationService, providedIn: "root" });
    return LocationService;
}(BaseService));
export { LocationService };
