export class EventDate {
  public event_date_id: number;
  public event_date_event_id: number;
  public event_date_description: string;
  public event_date_sub_disciplines: Array<any>;
  public event_date_start = '';
  public event_date_street: string;
  public event_date_unit: string;
  public event_date_city: string;
  public event_date_state: string;
  public event_date_postal_code: number;
  public event_date_course_length: number;
  public event_date_closure_type: string;
  public event_date_first: boolean;
  public event_date_event_discipline: number;
  // TODO: Confirm chief ref ID is included in API response.
  public event_date_chief_ref: any;
  public isValid = false;
  private requiredFields = [
    'event_date_event_id',
    'event_date_description',
    'event_date_start',
    'event_date_street',
    'event_date_unit',
    'event_date_city',
    'event_date_state',
    'event_date_postal_code',
    'event_date_course_length',
    'event_date_closure_type'
  ];

  constructor(data?) {
    if (data) {
      this.event_date_id = data.event_date_id;
      this.event_date_event_id = data.event_date_event_id;
      this.event_date_description = data.event_date_description;
      this.event_date_sub_disciplines = data.event_date_sub_disciplines;
      this.event_date_start = data.event_date_start;
      this.event_date_street = data.event_date_street;
      this.event_date_unit = data.event_date_unit;
      this.event_date_city = data.event_date_city;
      this.event_date_state = data.event_date_state;
      this.event_date_postal_code = data.event_date_postal_code;
      this.event_date_course_length = data.event_date_course_length;
      this.event_date_closure_type = data.event_date_closure_type;
      this.event_date_first = data.event_date_first;
      this.event_date_chief_ref = data.event_date_chief_ref;
      this.event_date_event_discipline = data.event_date_event_discipline;

      this.requiredFields.forEach(field => {
        this.isValid = !!data[field];
      });
    }
  }
}
