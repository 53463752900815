import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ProfileService } from 'src/app/services';
import { environment } from 'src/environments/environment';
var AdminGhostModeComponent = /** @class */ (function () {
    function AdminGhostModeComponent(fb, profileService, cookie) {
        this.fb = fb;
        this.profileService = profileService;
        this.cookie = cookie;
        this.loading = false;
    }
    Object.defineProperty(AdminGhostModeComponent.prototype, "disable", {
        get: function () {
            return !this.currentUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdminGhostModeComponent.prototype, "ghosting", {
        get: function () {
            return !this.disable && this.ghostModeForm.value.ghostMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdminGhostModeComponent.prototype, "showGhostModeBar", {
        get: function () {
            return this.hasGhostModeCookie && !!this.currentUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdminGhostModeComponent.prototype, "self", {
        get: function () {
            return this.currentUser && this.activeProfile && this.currentUser.profile_id === this.activeProfile.profile_id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdminGhostModeComponent.prototype, "profileUrl", {
        get: function () {
            return this.activeProfile && "/profile/" + this.activeProfile.profile_id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdminGhostModeComponent.prototype, "status", {
        get: function () {
            return this.ghosting ? 'acting as:' : 'viewing:';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdminGhostModeComponent.prototype, "showCancelButton", {
        get: function () {
            return this.activeProfileNameAndId !== 'Self' && !this.hasGhostModeCookie;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdminGhostModeComponent.prototype, "activeProfileNameAndId", {
        get: function () {
            if (!this.currentUser.profile_id) {
                return '';
            }
            if (this.hasGhostModeCookie) {
                return this.currentUser.profile_first_name + " " + this.currentUser.profile_last_name + " " + this.currentUser.profile_id;
            }
            else {
                return this.self || this.self === null
                    ? 'Self'
                    : this.currentUser.profile_first_name + " " + this.currentUser.profile_last_name + " " + this.currentUser.profile_id;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdminGhostModeComponent.prototype, "hasGhostModeCookie", {
        get: function () {
            return this.cookie.check('USACGHOST');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdminGhostModeComponent.prototype, "adminInfo", {
        get: function () {
            if (this.hasGhostModeCookie) {
                var data = JSON.parse(this.cookie.get('USACGHOST'));
                return data.admin_name + " " + data.admin_id;
            }
            else {
                return this.currentUser.profile_first_name + " " + this.currentUser.profile_last_name + " " + this.currentUser.profile_id;
            }
        },
        enumerable: true,
        configurable: true
    });
    AdminGhostModeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.profileService.getCurrentUser().subscribe(function () {
            _this.currentUser = _this.profileService.currentUser;
        });
        this.ghostModeForm = this.fb.group({
            ghostMode: [this.hasGhostModeCookie]
        });
    };
    AdminGhostModeComponent.prototype.resetGhostMode = function () {
        var _this = this;
        if (confirm('Are you sure you want to logout as this ghosted user?')) {
            this.loading = true;
            this.profileService.ghostResetProfile().subscribe(function (profile) {
                if (profile) {
                    window.location.assign(environment.adminUrl + ("/profile/" + profile.profile_id));
                }
                _this.loading = false;
            });
        }
    };
    return AdminGhostModeComponent;
}());
export { AdminGhostModeComponent };
