/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./steps.component";
var styles_StepsComponent = [];
var RenderType_StepsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StepsComponent, data: {} });
export { RenderType_StepsComponent as RenderType_StepsComponent };
function View_StepsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [["class", "done"]], [[1, "data-index", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["class", "step-label"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stepClick(_co.currentStep, _v.parent.context.index, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.index; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_1); }); }
function View_StepsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [["class", "active"]], [[1, "data-index", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["class", "step-label"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stepClick(_co.currentStep, _v.parent.context.index, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.index; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_1); }); }
function View_StepsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [], [[1, "data-index", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["class", "step-label"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stepClick(_co.currentStep, _v.parent.context.index, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.index; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_1); }); }
function View_StepsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StepsComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StepsComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StepsComponent_4)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentStep > _v.context.index); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.currentStep == _v.context.index); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.currentStep < _v.context.index); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_StepsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "progressbar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "ul", [["class", "steps-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StepsComponent_1)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "behind"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "progress"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "progress-bar"], ["role", "progressbar"]], [[4, "width", "%"], [1, "aria-valuenow", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.steps; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.percentage; var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.percentage, ""); _ck(_v, 6, 0, currVal_1, currVal_2); }); }
export function View_StepsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-steps", [], null, null, null, View_StepsComponent_0, RenderType_StepsComponent)), i0.ɵdid(1, 114688, null, 0, i2.StepsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StepsComponentNgFactory = i0.ɵccf("app-steps", i2.StepsComponent, View_StepsComponent_Host_0, { steps: "steps", currentStep: "currentStep" }, { click: "click" }, []);
export { StepsComponentNgFactory as StepsComponentNgFactory };
