import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  AddOnsComponent,
  EventListingComponent,
  EventListingReviewComponent,
  EventOverviewComponent,
  EventsQueueComponent,
  InsuredCertificatesComponent,
  PermitApplicationPart1Component,
  PermitApplicationPart2Component,
  PostEventPart1Component,
  PostEventPart2Component,
  PostEventPart3Component,
  EventIncentivesComponent,
  ThankYouComponent,
  ReportsComponent,
  UnauthorizedComponent
} from './ecosystems';

import {
  AuthGuardService
} from './shared';
import { EventPaymentGuard } from './guard/event-payment.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/event/listing',
    pathMatch: 'full'
  },

  // Create new listing
  {
    path: 'event/listing',
    data: { title: 'Event Listing' },
    component: EventListingComponent,
    canActivate: [AuthGuardService, EventPaymentGuard]
  },

  // View listing
  {
    path: 'event/overview/:eventId',
    redirectTo: 'event/:eventId/overview'
  },
  {
    path: 'event/:eventId',
    redirectTo: 'event/:eventId/overview'
  },
  {
    path: 'event/:eventId/overview',
    data: { title: 'Event Overview' },
    component: EventOverviewComponent,
    canActivate: [AuthGuardService]
  },

  // Edit exisiting listing
  {
    path: 'event/:eventId/listing',
    data: { title: 'Edit Event Listing' },
    component: EventListingComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'event/:eventId/review',
    data: { title: 'Event Review' },
    component: EventListingReviewComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: 'queue',
    data: { title: 'Event Queue' },
    component: EventsQueueComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: 'event/:eventId/permit-application/part-1',
    data: { title: 'Event Permit App - Part 1' },
    component: PermitApplicationPart1Component,
    canActivate: [AuthGuardService]
  },
  {
    path: 'event/:eventId/permit-application/part-2',
    data: { title: 'Event Permit App - Part 2' },
    component: PermitApplicationPart2Component,
    canActivate: [AuthGuardService]
  },

  {
    path: 'event/:eventId/event-incentives',
    data: { title: 'Event Calendar Labels' },
    component: EventIncentivesComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'event/:eventId/insured-certificates',
    data: { title: 'Event Insured Certs' },
    component: InsuredCertificatesComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'event/:eventId/add-ons',
    data: { title: 'Event Add Ons' },
    component: AddOnsComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: 'event/:eventId/post-event/part-1',
    data: { title: 'Event Post Event - Part 1' },
    component: PostEventPart1Component,
    canActivate: [AuthGuardService]
  },
  {
    path: 'event/:eventId/post-event/part-3',
    data: { title: 'Event Post Event - Part 3' },
    component: PostEventPart2Component,
    canActivate: [AuthGuardService]
  },
  {
    path: 'event/:eventId/post-event/part-2',
    data: { title: 'Event Post Event - Part 2' },
    component: PostEventPart3Component,
    canActivate: [AuthGuardService]
  },
  {
    path: 'event/:eventId/date/:dateId/post-event/part-1',
    data: { title: 'EventDate Post Event - Part 1' },
    component: PostEventPart1Component,
    canActivate: [AuthGuardService]
  },
  {
    path: 'event/:eventId/date/:dateId/post-event/part-3',
    data: { title: 'EventDate Post Event - Part 3' },
    component: PostEventPart2Component,
    canActivate: [AuthGuardService]
  },
  {
    path: 'event/:eventId/date/:dateId/post-event/part-2',
    data: { title: 'EventDate Post Event - Part 2' },
    component: PostEventPart3Component,
    canActivate: [AuthGuardService]
  },

  {
    path: 'reports',
    data: { title: 'Reports' },
    component: ReportsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'thank-you',
    data: { title: 'Thank You' },
    pathMatch: 'full',
    component: ThankYouComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'unauthorized',
    data: { title: 'Unauthorized' },
    pathMatch: 'full',
    component: UnauthorizedComponent
  },

  // TODO: Consider adding 404 page
  { path: '**', redirectTo: '/queue' },


  // Backwards compatibility redirects for obsolete routes
  { path: 'event/queue', redirectTo: '/queue' },
  { path: 'event/review', redirectTo: '/queue' },
  { path: 'permitapplication/part1', redirectTo: '/queue' },
  { path: 'permitapplication/part2', redirectTo: '/queue' },
  { path: 'event/insuredcertificates', redirectTo: '/queue' },
  { path: 'event/add-ons', redirectTo: '/queue' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
