import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import qs from 'qs';
import { Event, EventDate, EventQueueRow, Discipline, EventType, SelectOption, SpecialCalendar } from '../classes';
import { BaseService, PaginatedApiResponse } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class EventService extends BaseService {
  public eventTypes: EventType[] = [];
  public disciplines: Discipline[] = [];
  public eventList: Event[];
  public event: Event;
  public statusOptions: SelectOption[];
  public queueStatusOptions: SelectOption[];
  public listingStatusOptions: SelectOption[];
  public allowedQueueParams: string[];
  public eventQueue: EventQueueRow[];

  public event$: BehaviorSubject<Event> = new BehaviorSubject<Event>(null);
  public eventQueue$: BehaviorSubject<EventQueueRow[]> = new BehaviorSubject<EventQueueRow[]>(null);

  constructor(
    protected http: HttpClient,
    protected cookie: CookieService
  ) {
    super(http, cookie);
    this._load();

    this.allowedQueueParams = [
      'no_paginate',
      'page',
      'city',
      'states',
      'disciplines',
      'name',
      'event_start_date',
      'event_end_date',
      'listing_type',
      'status',
      'regions',
      'permit_date_range',
      'order_number',
      'post_event_status',
      'calendar_label_ids'
    ];

    this.statusOptions = [
      new SelectOption('listing-in-progress', 'Listing In Progress'),
      new SelectOption('listing-in-review', 'Listing In Review'),
      new SelectOption('listing-approved', 'Listing Approved'),
      new SelectOption('permit-in-progress', 'Permit In Progress'),
      new SelectOption('permit-in-review', 'Permit In Review'),
      new SelectOption('permit-approved', 'Permit Approved'),
      new SelectOption('in-post-event', 'In Post Event'),
      new SelectOption('closed', 'Closed'),
      new SelectOption('unknown', 'Unknown')
    ];

    this.queueStatusOptions = [
      new SelectOption('listing-in-progress', 'Listing In Progress'),
      new SelectOption('listing-in-review', 'Listing In Review'),
      new SelectOption('listing-approved', 'Listing Approved'),
      new SelectOption('permit-in-progress', 'Permit In Progress'),
      new SelectOption('permit-in-review', 'Permit In Review'),
      new SelectOption('permit-approved', 'Permit Approved'),
      new SelectOption('in-post-event', 'In Post Event'),
    ];

    this.listingStatusOptions = [
      new SelectOption('listing-in-progress', 'Listing In Progress'),
      new SelectOption('listing-in-review', 'Listing In Review'),
      new SelectOption('listing-approved', 'Listing Approved'),
      new SelectOption('closed', 'Closed'),
      new SelectOption('unknown', 'Unknown')
    ];
  }

  getEventTypes(): Observable<EventType[]> {
    return this.http.get<EventType[]>(this.url('event/types'))
      .pipe(
        tap(_ => this.log('fetched event types')),
        catchError(this.handleError<EventType[]>('getEventTypes', []))
      );
  }

  getDisciplines(): Observable<Discipline[]> {
    return this.http.get<Discipline[]>(this.url('event/disciplines'))
      .pipe(
        tap(_ => this.log('fetched event disciplines')),
        catchError(this.handleError<Discipline[]>('getDisciplines', []))
      );
  }

  getSpecialCalendars(disciplineId: string|number): Observable<SpecialCalendar[]> {
    return this.http.get<SpecialCalendar[]>(this.url(`event/special_calendars/${disciplineId}`))
      .pipe(
        tap(_ => this.log('fetched special calendar options')),
        catchError(this.handleError<SpecialCalendar[]>('getSpecialCalendars', []))
      );
  }

  cleanQueueParams(params: any) {
    const eventQueueParams: any = {};

    if (params.event_discipline && typeof params.event_discipline === 'string' && params.event_discipline.trim() !== '') {
      Object.assign(params, {
        disciplines: [ params.event_discipline ]
      });
    }

    if (params.event_discipline && Array.isArray(params.event_discipline)) {
      Object.assign(params, {
        disciplines: params.event_discipline.map(discipline => discipline.value || discipline)
      });
    }

    if (params.regions && Array.isArray(params.regions)) {
      Object.assign(params, {
        regions: params.regions.map(region => region.value || region)
      });
    }

    if (params.calendar_label_ids && Array.isArray(params.calendar_label_ids)) {
      Object.assign(params, {
        calendar_label_ids: params.calendar_label_ids.map(labelId => labelId.value || labelId)
      });
    }

    if (params.permitCreateStartDate || params.permitCreateEndDate) {
      const permitDateRange = {};

      if (params.permitCreateStartDate.trim() !== '') {
        Object.assign(permitDateRange, {
          start_date: params.permitCreateStartDate
        });
      }

      if (params.permitCreateEndDate.trim() !== '') {
        Object.assign(permitDateRange, {
          end_date: params.permitCreateEndDate
        });
      }

      if (Object.keys(permitDateRange).length > 0) {
        Object.assign(params, {
          permit_date_range: permitDateRange
        });
      }
    }

    Object.keys(params).forEach(paramKey => {
      const paramValue = params[paramKey];

      const paramValid = (typeof paramValue !== 'undefined' && paramValue !== null && (typeof paramValue !== 'string' || paramValue.trim() !== '')); /* tslint:disable-line: max-line-length */

      if (this.allowedQueueParams.includes(paramKey) && paramValid) {
        console.log('include in api query', paramKey, paramValue);
        eventQueueParams[paramKey] = paramValue;
      } else {
        console.log('EXCLUDE in api query', paramKey, paramValue);
      }
    });

    return eventQueueParams;
  }

  getEventsQueue(page = 1): Observable<PaginatedApiResponse> {
    const queryParams = qs.parse(window.location.search.replace(/^\?/, ''));

    Object.assign(queryParams, { page });

    console.log('parse query', queryParams);

    const eventQueueParams = this.cleanQueueParams(queryParams);

    const eventQueueQueryString = qs.stringify(eventQueueParams, {
      addQueryPrefix: true,
      arrayFormat: 'brackets'
    });

    return this.http.get<PaginatedApiResponse>(this.url('events/queue' + eventQueueQueryString), this.options)
      .pipe(
        tap(_ => this.log('fetched events queue')),
        catchError(this.handleError<PaginatedApiResponse>('getEventsQueue', { data: [] }, { eventQueueParams }))
      );
  }

  getEventQueueById(id: number|string): Observable<EventQueueRow[]> {
    return this.http.get<EventQueueRow[]>(this.url(`events/queue/search/${id}`), this.options)
      .pipe(
        tap(_ => this.log('fetched event queue by id')),
        catchError(this.handleError<EventQueueRow[]>('getEventQueueById', null, { id }))
      );
  }

  getEventsQueueAll(): Observable<EventQueueRow[]> {
    const queryParams = qs.parse(window.location.search.replace(/^\?/, ''));

    Object.assign(queryParams, { no_paginate: '1' });

    console.log('parse query', queryParams);

    const eventQueueParams = this.cleanQueueParams(queryParams);

    const eventQueueQueryString = qs.stringify(eventQueueParams, {
      addQueryPrefix: true,
      arrayFormat: 'brackets'
    });

    return this.http.get<EventQueueRow[]>(this.url('events/queue' + eventQueueQueryString), this.options)
      .pipe(
        tap(_ => this.log('fetched events queue (all)')),
        catchError(this.handleError<EventQueueRow[]>('getEventsQueueAll', [], { eventQueueParams }))
      );
  }

  getEvents(): Observable<Event[]> {
    return this.http.get<Event[]>(this.url('event'))
      .pipe(
        tap(_ => this.log('fetched events')),
        catchError(this.handleError<Event[]>('getEvents', []))
      );
  }

  setEventList(data) {
    if (Object.keys(data).length > 0) {
      this.eventList = [];
      Object.values(data).forEach(event => {
        this.eventList.push(new Event(event));
      });
    }
  }

  getEventById(id: number|string): Observable<Event> {
    return this.http.get<Event>(this.url('event/' + id))
      .pipe(
        tap((event: Event) => {
          this.event$.next(new Event(event));
          this.log('fetched event by id');
          this.cookie.delete('event_id', '/');
          this.cookie.set('event_id', event.event_id.toString(), null, '/');
        }),
        catchError(this.handleError<Event>('getEventById', null, { id }))
      );
  }

  setEvent(data) {
    if (Object.keys(data).length > 0) {
      this.event = new Event(data[0]);
      // console.log('Event: ', this.event);
    }
  }

  postEvent(listing: object): Observable<any> {
    const event_sponsoring_clubs = 'event_sponsoring_clubs';
    const clubs = this.filterMultiSelectValues(listing[event_sponsoring_clubs]);
    const event_start_date = new Date(listing['event_start_date']); // tslint:disable-line: no-string-literal
    const event_end_date = new Date(listing['event_end_date']); // tslint:disable-line: no-string-literal
    const updatedListing = Object.assign({}, listing, {
      event_sponsoring_clubs: clubs,
      event_start_date,
      event_end_date
    });
    return this.http.post(this.url('event'), updatedListing, this.options)
      .pipe(
        tap((newEvent: Event) => {
          this.log(`created event id: ${newEvent.event_id}`);
          this.cookie.delete('event_id', '/');
          this.cookie.set('event_id', newEvent.event_id.toString(), null, '/');
        }),
        catchError(this.handleError<any>('postEvent', null, { listing, updatedListing }))
      );
  }

  updateEvent(listing: object, eventId: number|string): Observable<any> {
    const event_sponsoring_clubs = 'event_sponsoring_clubs';
    const clubs = this.filterMultiSelectValues(listing[event_sponsoring_clubs]);
    const event_start_date = new Date(listing['event_start_date']); // tslint:disable-line: no-string-literal
    const event_end_date = new Date(listing['event_end_date']); // tslint:disable-line: no-string-literal
    const updatedListing = Object.assign({}, listing, {
      event_sponsoring_clubs: clubs,
      event_start_date,
      event_end_date
    });
    return this.http.put(this.url(`event/${eventId}`), updatedListing, this.options)
      .pipe(
        tap((resp: Event) => {
          this.log(`updated event id: ${resp.event_id}`);
        }),
        catchError(this.handleError<any>('updateEvent', null, { eventId, listing, updatedListing }))
      );
  }

  updateEventDiscounts(eventId: number|string, discountBody = {}): Observable<any> {
    return this.http.put(this.url(`event/${eventId}/discounts`), discountBody, this.options)
      .pipe(
        tap(() => this.log('updated event discounts')),
        catchError(this.handleError<any>('updateEventDiscounts', null, { eventId, discountBody }))
      );
  }

  togglePendRaceDirector(eventId: number|string): Observable<any> {
    return this.http.post(this.url(`event/${eventId}/pend-rd`), [], this.options)
      .pipe(
        tap(() => this.log('toggle pend race director')),
        catchError(this.handleError<any>('togglePendRaceDirector', null, { eventId }))
      );
  }

  getEventDates(eventId: number|string): Observable<EventDate[]> {
    return this.http.get<EventDate[]>(this.url(`eventdate/event/${eventId}`), this.options)
      .pipe(
        tap(_ => this.log('fetched event dates')),
        catchError(this.handleError<EventDate[]>('getEventDates', [], { eventId }))
      );
  }

  getEventDate(eventDateId: number|string): Observable<EventDate> {
    return this.http.get<EventDate>(this.url(`eventdate/${eventDateId}`), this.options)
      .pipe(
        tap(_ => this.log('fetched event date')),
        catchError(this.handleError<EventDate>('getEventDate'))
      );
  }

  postEventDate(date: EventDate, eventId: number|string): Observable<any> {
    const event_date_id = 'event_date_id';
    const event_date_start = new Date(date.event_date_start);
    // commented out for MBR-2992
    // const subDisciplines = this.filterMultiSelectValues(date.event_date_sub_disciplines);
    const dateWithId = Object.assign({}, date, {
      event_date_event_id: eventId,
      event_date_start,
      // commented out for MBR-2992
      // event_date_sub_disciplines: subDisciplines
    });
    return this.http.post(this.url('eventdate'), dateWithId, this.options)
      .pipe(
        tap(_ => this.log(`created event date id: ${_[event_date_id]}`)),
        catchError(this.handleError<any>('postEventDate', null, { eventId, date, dateWithId }))
      );
  }

  // TODO: Ensure this is submitting correctly - we need API documentation
  editEventDate(date: EventDate, id: number|string): Observable<EventDate> {
    // const subDiscs = date.event_date_sub_disciplines;
    // const subDisciplines = subDiscs !== null ? this.filterMultiSelectValues(date.event_date_sub_disciplines) : subDiscs;
    const event_date_start = new Date(date.event_date_start);
    const dateWithId = Object.assign({}, date, {
      // commented out for MBR-2992
      // event_date_sub_disciplines: subDisciplines,
      event_date_start
    });
    return this.http.put<EventDate>(this.url(`eventdate/${id}`), dateWithId, this.options)
      .pipe(
        tap(_ => this.log(`Updated event date id: ${id}`)),
        catchError(this.handleError<EventDate>('editEventDate', new EventDate(), { id, date, dateWithId }))
      );
  }

  deleteEventDate(id: number|string): Observable<any> {
    return this.http.delete(this.url(`eventdate/delete/${id}`), this.options)
    .pipe(
      tap(_ => this.log(`Deleted event date id: ${id}`)),
      catchError(this.handleError<any>('deleteEventDate', null, { id }))
    );
  }

  sortEventDates(eventDates: EventDate[]) {
    return eventDates.sort(this.compareEventDates);
  }

  compareEventDates(a: EventDate, b: EventDate) {
    const startA = a.event_date_start;
    const startB = b.event_date_start;

    let comparison = 0;
    if (startA > startB) {
      comparison = 1;
    } else if (startA < startB) {
      comparison = -1;
    }
    return comparison;
  }

  isSeries(event: Event) {
    return event.event_types.some(type => type.et_name.toLowerCase() === 'series');
  }

  searchEvents(criteria: any): Observable<Event[]> {
    return this.http.get<Event[]>(this.url('event'), { params: criteria})
      .pipe(
        tap(_ => this.log('searched events with criteria')),
        catchError(this.handleError('searchEvents', []))
      );
  }

  updateEventStatus(eventId: number|string, event_status: string, closeFields = {}): Observable<any> {
    const data = Object.assign({}, {event_status}, closeFields);
    return this.http.put(this.url(`event/status/${eventId}`), data, this.options)
      .pipe(
        tap(_ => this.log(`Updated event id: ${eventId} status`)),
        catchError(this.handleError<any>('updateEventStatus', {}, { eventId, event_status, data }))
      );
  }

  submitInsuranceChanges(eventId: number|string, changes: string[]): Observable<any> {
    return this.http.post(this.url(`event/${eventId}/insurance_changes`), { changes }, this.options)
      .pipe(
        tap(_ => this.log(`Submitted changes to insurance`)),
        catchError(this.handleError<any>('submitInsuranceChanges', null, { eventId, changes }))
      );
  }

  getEventTypeById(eventTypeId: number|string): EventType {
    return this.eventTypes.find(et => et.et_id.toString() === eventTypeId.toString());
  }

  getDisciplineById(disciplineId: number|string): Discipline {
    return this.disciplines.find(disc => disc.cd_id.toString() === disciplineId.toString());
  }

  getDisciplineName(value) {
    let name = '';
    this.disciplines.forEach( disc => {
      if (disc.cd_id === value || disc.cd_id.toString() === value) {
        name = disc.cd_value;
      }
    });
    return name;
  }

  private _loadDisciplines(): Promise<Discipline[]> {
    return new Promise(
      (resolve, reject) => {
        if (this.disciplines && this.disciplines.length > 0) {
          return resolve(this.disciplines);
        } else {
          this.getDisciplines().subscribe(disciplines => {
            this.disciplines = disciplines;

            return resolve(disciplines);
          }, reject);
        }
      }
    );
  }

  private _loadEventTypes(): Promise<EventType[]> {
    return new Promise(
      (resolve, reject) => {
        if (this.eventTypes && this.eventTypes.length > 0) {
          return resolve(this.eventTypes);
        } else {
          this.getEventTypes().subscribe(eventTypes => {
            this.eventTypes = eventTypes;

            return resolve(eventTypes);
          }, reject);
        }
      }
    );
  }

  private async _load() {
    await this._loadDisciplines();
    await this._loadEventTypes();
  }
}
