/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "../../atoms/logo/logo.component.ngfactory";
import * as i3 from "../../atoms/logo/logo.component";
import * as i4 from "../../molecules/nav-link-group/nav-link-group.component.ngfactory";
import * as i5 from "../../molecules/nav-link-group/nav-link-group.component";
import * as i6 from "../../shared/services/global.service";
import * as i7 from "../../services/authentication.service";
import * as i8 from "./navbar.component";
var styles_NavbarComponent = [];
var RenderType_NavbarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
export function View_NavbarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "nav", [["class", "navbar navbar-expand-lg navbar-light d-flex align-items-center"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ɵm, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-logo", [["class", "navbar-brand justify-self-start"]], null, null, null, i2.View_LogoComponent_0, i2.RenderType_LogoComponent)), i0.ɵdid(4, 114688, null, 0, i3.LogoComponent, [], { width: [0, "width"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "button", [["aria-controls", "navbarMobile"], ["aria-expanded", "false"], ["aria-label", "Toggle navigation"], ["class", "navbar-toggler hidden-sm-up"], ["data-target", "#navbarMobile"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isNavbarCollapsed = !_co.isNavbarCollapsed) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "navbar-toggler-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "collapse navbar-collapse justify-content-end"], ["id", "navbarMobile"]], [[2, "collapse", null], [2, "show", null]], null, null, null, null)), i0.ɵdid(8, 16384, null, 0, i1.NgbCollapse, [], { collapsed: [0, "collapsed"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 1, "app-nav-link-group", [["class", "nav-item nav-link"]], null, null, null, i4.View_NavLinkGroupComponent_0, i4.RenderType_NavLinkGroupComponent)), i0.ɵdid(10, 114688, null, 0, i5.NavLinkGroupComponent, [i6.GlobalService, i7.AuthenticationService], { links: [0, "links"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = 50; _ck(_v, 4, 0, currVal_0); var currVal_3 = !_co.isNavbarCollapsed; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.links; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = true; var currVal_2 = !i0.ɵnov(_v, 8).collapsed; _ck(_v, 7, 0, currVal_1, currVal_2); }); }
export function View_NavbarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i0.ɵdid(1, 114688, null, 0, i8.NavbarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarComponentNgFactory = i0.ɵccf("app-navbar", i8.NavbarComponent, View_NavbarComponent_Host_0, {}, {}, []);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };
