import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService, EventListingService, PermitService } from 'src/app/services';
import { CalendarLabel, EventIncentivesFormAnswer, CalendarLabelQuestion, Event } from 'src/app/classes';

@Component({
  selector: 'app-event-incentives',
  templateUrl: './event-incentives.component.html',
  styles: []
})
export class EventIncentivesComponent implements OnInit {
  currentStep = 2;

  calendarLabels: CalendarLabel[] = [];
  calendarLabelQuestions: CalendarLabelQuestion[] = [];
  event: Event;
  eventId: string;
  reset = false;

  calendarLabelsLoaded = false;
  calendarLabelAnswersLoaded = false;
  calendarLabelAnswers: EventIncentivesFormAnswer[] = [];
  selectedLabelIds: number[] = [];


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventService,
    private eventListing: EventListingService,
    private permitService: PermitService
  ) { }

  ngOnInit(): void {
    this.permitService.reset();
    this.eventListing.reset();
    this.reset = true;

    this.eventId = this.route.snapshot.paramMap.get('eventId');
    this.eventService.getEventById(this.eventId).subscribe(event => {
      if (event) {
        this.event = new Event(event);
        if (this.event.isRaceOrRide) {
          this.eventListing.fetchEventData(this.eventId);
          this.permitService.permitId = event.event_permit_id;
          this.permitService.getCalendarLabels(this.event.event_discipline).subscribe(labels => this.calendarLabels = labels);
          this.permitService.getCalendarQuestions(this.event.event_discipline).subscribe(questions => {
            this.calendarLabelQuestions = questions;

            this.permitService.getCalendarLabelsForEvent(this.eventId).subscribe(labels => {
              this.selectedLabelIds = labels.map(label => label.id);
              this.calendarLabelsLoaded = true;
            });

            this.permitService.getCalendarLabelAnswersForEvent(this.eventId).subscribe(answers => {
              this.setCalendarLabelAnswers(answers);
              this.calendarLabelAnswersLoaded = true;
            });
          });
        } else {
          this.toInsuredCerts();
        }
      } else {
        this.toQueue();
      }
    });
  }

  get loaded(): boolean {
    return this.reset && this.eventListing.event_discipline && !!this.calendarLabels.length
      && this.calendarLabelsLoaded && this.calendarLabelAnswersLoaded;
  }

  toQueue(): void {
    this.router.navigate(['queue']);
  }

  toInsuredCerts(): void {
    this.router.navigate([`/event/${this.event.event_id}/insured-certificates`]);
  }

  setCalendarLabelAnswers(answers: EventIncentivesFormAnswer[]): void {
    this.calendarLabelAnswers = answers;
  }

}
