import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { BaseService } from './base-service';
import { Certificate } from '../classes';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
var CertificateService = /** @class */ (function (_super) {
    tslib_1.__extends(CertificateService, _super);
    function CertificateService(http, cookie) {
        var _this = _super.call(this, http, cookie) || this;
        _this.http = http;
        _this.cookie = cookie;
        _this.certificates = [];
        return _this;
    }
    CertificateService.prototype.getCertificates = function (eventId) {
        var _this = this;
        return this.http.get(this.url("event/" + eventId + "/certificates"), this.options)
            .pipe(tap(function (_) { return _this.log('fetched certificates'); }), catchError(this.handleError('getCertificates', [], { eventId: eventId })));
    };
    CertificateService.prototype.getCertificate = function (eventId, certificateId) {
        var _this = this;
        return this.http.get(this.url("event/" + eventId + "/certificates/" + certificateId), this.options)
            .pipe(tap(function (_) { return _this.log('fetched certificate'); }), catchError(this.handleError('getCertificate', null, { eventId: eventId, certificateId: certificateId })));
    };
    CertificateService.prototype.addCertificate = function (eventId, certificate) {
        var _this = this;
        return this.http.post(this.url("event/" + eventId + "/certificates"), certificate, this.options)
            .pipe(tap(function (newCertificate) { return _this.log("added certificate id: " + newCertificate.id); }), catchError(this.handleError('addCertificate', new Certificate({}), { eventId: eventId, certificate: certificate })));
    };
    CertificateService.prototype.updateCertificate = function (eventId, certificate) {
        var _this = this;
        return this.http.put(this.url("event/" + eventId + "/certificates/" + certificate.id), certificate, this.options)
            .pipe(tap(function (updatedCertificate) { return _this.log("updated certificate id: " + updatedCertificate.id); }), catchError(this.handleError('updateCertificate', new Certificate({}), { eventId: eventId, certificate: certificate })));
    };
    CertificateService.prototype.deleteCertificate = function (eventId, certificateId) {
        var _this = this;
        return this.http.delete(this.url("event/" + eventId + "/certificates/" + certificateId), this.options)
            .pipe(tap(function (_) { return _this.log('deleted certificate'); }), catchError(this.handleError('deleteCertificate', null, { eventId: eventId, certificateId: certificateId })));
    };
    CertificateService.prototype.addOrUpdateSelectedCert = function (index, certificate) {
        var existingCertIndex = this.certificates.findIndex(function (cert) { return cert.index === index || (!!cert.id && cert.id === certificate.id); });
        existingCertIndex !== -1 ? this.certificates[existingCertIndex] = certificate : this.certificates.push(certificate);
    };
    CertificateService.prototype.removeSelectedCert = function (index) {
        var certificate = this.certificates.find(function (cert) { return cert.index === index; });
        if (certificate) {
            var i = this.certificates.indexOf(certificate);
            this.certificates.splice(i, 1);
        }
    };
    CertificateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CertificateService_Factory() { return new CertificateService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: CertificateService, providedIn: "root" });
    return CertificateService;
}(BaseService));
export { CertificateService };
