<h4>Insured Certificates</h4>

<!-- Loader -->
<app-loader *ngIf="loading" [small]="true" [align]="'left'"></app-loader>

<!-- Empty State -->
<div *ngIf="!loading && !paidCertificates.length" class="row">
  <p class="col-12">Upon payment of insured persons the certificate information will populate here.</p>
</div>

<div *ngIf="!loading">
  <div *ngFor="let certificate of certificates" class="mb-3">
    <app-insurance-accordion
      [certificate]="certificate"
      [isUsacAdmin]="isUsacAdmin"
      (delete)="handleDelete($event)"
    ></app-insurance-accordion>
  </div>
  <div class="d-flex justify-content-center">
    <app-button *ngIf="allowAdd"
      [type]="'btn btn-light btn-md'"
      (click)="navigateToCerts()">
      <i class="icon icon-circle-plus"></i> Purchase Additional Certificates
    </app-button>

    <div *ngIf="allowInsuranceSubmit">
      <app-button
        [type]="'btn btn-secondary btn-md'"
        (click)="submitInsuranceChanges()">
        Submit Changes to Insurance
        <span *ngIf="insuranceSubmitting" class="spinner-border spinner-border-sm ml-1"></span>
      </app-button>
    </div>
  </div>
  <div *ngIf="insuranceMessage" class="mt-2 text-center small {{ insuranceMessageClass }}">
    {{ insuranceMessage }}
  </div>
</div>

<!-- Certificate Delete Confirmation Modal -->
<app-modal
  [id]="'delete-certificate'"
  [title]="'Delete Insured Certificate'"
  [isOpen]="deleteModalOpen"
  (close)="closeDeleteModal()"
>
  <app-modal-body>
    <p>
      Are you sure you want to delete this Insured Certificate?
      After you've completed your changes, please click "Submit Changes to Insurance" to
      notify the insurance company with an updated list of insured persons.
    </p>
    <div class="d-flex justify-content-between">
      <app-button
        [type]="'btn-light'"
        (click)="closeDeleteModal()"
        [disabled]="deleting"
      >No, Cancel</app-button>
      <app-button
        [type]="'btn-primary'"
        (click)="deleteCertificate()"
        [disabled]="deleting"
      >
        Yes, Delete
        <span *ngIf="deleting" class="spinner-border spinner-border-sm ml-1"></span>
      </app-button>
    </div>
    <div *ngIf="showDeleteError" class="mt-2 text-right text-danger small">
      Failed to delete insured certificate. Please try again or contact IT support.
    </div>
  </app-modal-body>
</app-modal>
