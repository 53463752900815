import { Injectable } from '@angular/core';

import { Activity } from '../classes/activity.class';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class ActivityService extends BaseService {

  public history: Array<Activity> = [];
  public notifications: Array<Activity> = [];

  constructor(  protected http: HttpClient,
                protected cookie: CookieService) {
      super(http, cookie);
  }

  getEventHistory(eventId: number|string): Observable<Activity[]> {
    return this.http.get<Activity[]>(this.url(`event/history/${eventId}`), this.options)
   // return this.http.get<Activity[]>(`http://127.0.0.1:8000/api/v1/event/history/${eventId}`, this.options)
      .pipe(
        tap(_ => this.log('fetched event History')),
        catchError(this.handleError<Activity[]>('getEventHistory', []))
      );
  }



}
