import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services';
import * as i0 from "@angular/core";
import * as i1 from "../../services/authentication.service";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(authService) {
        this.authService = authService;
        this.loginKey = 'nonce';
    }
    AuthGuardService.prototype.canActivate = function (route) {
        var _this = this;
        return new Observable(function (observer) {
            _this.authService.isAuthenticated().subscribe(function (isAuthenticated) {
                if (!isAuthenticated) {
                    _this.authService.doLogin(window.location.href);
                    observer.next(false);
                }
                else {
                    observer.next(true);
                }
                observer.complete();
            });
        });
    };
    AuthGuardService.prototype.expectedNonce = function () {
        return localStorage.getItem(this.loginKey);
    };
    AuthGuardService.prototype.removeNonce = function () {
        localStorage.removeItem(this.loginKey);
    };
    AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.AuthenticationService)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
