import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService, EventListingService, PermitService } from 'src/app/services';
import { Event } from 'src/app/classes';

@Component({
  selector: 'app-permit-application-part1',
  templateUrl: './permit-application-part1.component.html',
  styleUrls: []
})
export class PermitApplicationPart1Component implements OnInit {
  currentStep = 1;
  event: Event;
  eventId: string;
  reset = false;
  dataLoaded = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventService,
    private eventListing: EventListingService,
    private permitService: PermitService
  ) { }

  ngOnInit() {
    this.permitService.reset();
    this.eventListing.reset();
    this.reset = true;

    this.eventId = this.route.snapshot.paramMap.get('eventId');
    this.eventService.getEventById(this.eventId).subscribe(event => {
      if (event) {
        this.event = new Event(event);
        // Don't allow virtual events to be permitted
        if (this.event.isVirtual) {
          this.toOverview();
        } else {
          this.eventListing.fetchEventData(this.eventId);
          this.permitService.permitId = event.event_permit_id;
          this.permitService.getQuestionConfig(event.event_discipline);
          if (this.permitService.permitId) {
            this.permitService.mode = 'update';
            this.loadAnswers();
          } else {
            this.dataLoaded = true;
          }
        }
      } else {
        this.toQueue();
      }
    });
  }

  loadAnswers() {
    this.permitService.getPermitInfo(this.permitService.permitId).subscribe( (data: any) => {
      if (data === null || data === undefined) {
        console.log('events are not found , due to the api has down');
      }
      this.permitService.answers = data.answers;
      console.log(this.permitService.answers);
      if (!this.permitService.answers.length) { this.permitService.mode = 'create'; }
      this.permitService.fetchFromAnswers();
      this.dataLoaded = true;
    }, (error) => {
      console.log(error);
      }
    );
  }

  get loaded() {
    return this.reset && this.eventListing.datesLoaded && this.eventListing.organizerLoaded
      && this.eventListing.event_discipline && this.permitService.availableQuestionsList.length
      && this.dataLoaded;
  }

  toOverview() {
    this.router.navigate([`event/${this.eventId}/overview`]);
  }

  toQueue() {
    this.router.navigate(['queue']);
  }

}
