export class MailingAddress {
  address1 = '';
  address2 = '';
  city = '';
  company = '';
  country = '';
  firstName = '';
  lastName = '';
  phone = '';
  province = '';
  zip = '';
}
