import { OnInit, EventEmitter } from '@angular/core';
import { FormFieldErrors } from '../../classes';
var noop = function () { };
var ɵ0 = noop;
var CheckboxGroupComponent = /** @class */ (function () {
    function CheckboxGroupComponent() {
        this.currentValue = [];
        this.options = [];
        this.onTouchedCallback = noop;
        this.onChangeCallback = noop;
        this.change = new EventEmitter();
        this.valueChange = new EventEmitter();
    }
    Object.defineProperty(CheckboxGroupComponent.prototype, "value", {
        get: function () {
            return this.currentValue;
        },
        set: function (val) {
            this.currentValue = val;
            this.change.emit(this.currentValue);
            this.valueChange.emit(this.currentValue);
            this.updateChecksFromValue();
            this.onChangeCallback(this.currentValue);
        },
        enumerable: true,
        configurable: true
    });
    CheckboxGroupComponent.prototype.writeValue = function (value) {
        this.currentValue = value;
        this.onChangeCallback(value);
        this.updateChecksFromValue();
    };
    // From ControlValueAccessor interface
    CheckboxGroupComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    // From ControlValueAccessor interface
    CheckboxGroupComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    CheckboxGroupComponent.prototype.ngOnInit = function () {
        this.updateChecksFromValue();
    };
    CheckboxGroupComponent.prototype.updateChecksFromValue = function () {
        for (var _i = 0, _a = this.options; _i < _a.length; _i++) {
            var option = _a[_i];
            option.checked = this.currentValue && this.currentValue.includes(option.value);
        }
    };
    CheckboxGroupComponent.prototype.onCheckChange = function (event) {
        if (event && event.target) {
            for (var _i = 0, _a = this.options; _i < _a.length; _i++) {
                var option = _a[_i];
                if (option.value === event.target.value) {
                    option.checked = event.target.checked ? true : undefined;
                }
            }
            this.value = this.options.filter(function (opt) { return opt.checked; }).map(function (opt) { return opt.value; });
            // this.onChangeCallback(this.value);
        }
    };
    return CheckboxGroupComponent;
}());
export { CheckboxGroupComponent };
export { ɵ0 };
