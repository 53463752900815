import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { CheckboxOption, CourseMap } from '../classes';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { BaseService } from './base-service';
import { EventService } from './events.service';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
import * as i3 from "@angular/router";
import * as i4 from "./events.service";
var PermitService = /** @class */ (function (_super) {
    tslib_1.__extends(PermitService, _super);
    function PermitService(http, cookie, route, eventService) {
        var _this = _super.call(this, http, cookie) || this;
        _this.http = http;
        _this.cookie = cookie;
        _this.route = route;
        _this.eventService = eventService;
        // public contactName: string;
        // public contactEmail: string;
        // public contactPhone: number;
        // public medicalStaffName: Array<string> = [];
        // public medicalFacilities: object = {};
        _this.timingInformation = '';
        _this.communicationTypes = [];
        _this.courseMarking = [];
        _this.courseMaps = [];
        _this.stageArea = '';
        _this.mode = 'create';
        _this.status = 'In Progress';
        // public medicalFacilitiesOptionsList = {
        //   ambulance: 'Ambulance',
        //   firstAidTent: 'First Aid Tent',
        //   DoctorOrMd: 'Doctor/MD',
        //   nurse: 'Nurse',
        //   athleticTrainer: 'Athletic Trainer',
        //   other: 'Other (elaborate)'
        // };
        _this.communicationTypesOptionsList = {
            twoWayRadio: 'Two-way Radio',
            cellPhone: 'Cell Phone',
            amateurRadioOperators: 'Amateur Radio Operators'
        };
        _this.timingOptionsList = {
            entireCourse: 'Entire Course',
            segments: 'Segments',
            noTiming: 'No Timing'
        };
        _this.courseMarkingOptionsList = {
            directionalSignage: 'Directional Signage',
            distanceMarkers: 'Distance Markers',
            roadMarkings: 'Road Markings',
            hazardousSectionWarnings: 'Hazardous Section Warnings',
            feedZoneMarkers: 'Feed Zone Markers',
            fencing: 'Fencing',
            barricades: 'Barricades',
            directionArrows: 'Directional Arrows',
            courseTape: 'Course Tape',
            courseDiscs: 'Course Discs',
            artificialBarricades: 'Artificial Barricades',
            artificialSandPits: 'Artificial Sand Pits',
            bikeWash: 'Bike Wash',
            aidStations: 'Aid Stations',
            gpsRouteMaps: 'GPS Route Maps'
        };
        _this.courseMarkingOptions = {
            19: ['Directional Signage', 'Distance Markers', 'Road Markings', 'Hazardous Section Warnings', 'Feed Zone Markers',
                'Fencing', 'Barricades'],
            21: ['Direction Arrows', 'Course Tape', 'Hazardous Section Warnings', 'Fencing', 'Barricades'],
            22: [],
            23: ['Course Tape', 'Course Discs', 'Hazardous Section Warnings', 'Artificial Barricades', 'Artificial Sand Pits', 'Bike Wash'],
            20: [],
            24: ['Directional Signage', 'Distance Markers', 'Aid Stations', 'Fencing', 'Barricades', 'GPS Route Maps'],
            25: ['Directional Signage', 'Distance Markers', 'Aid Stations', 'Fencing', 'Barricades', 'GPS Route Maps'],
            26: [],
            27: [],
            28: [],
            29: [],
            30: []
        };
        _this.availableQuestionsList = [];
        return _this;
    }
    PermitService.prototype.checkBoxOptionsWithArray = function (options) {
        var checkBoxOptions;
        checkBoxOptions = [];
        if (options === undefined || options === null) {
            return checkBoxOptions;
        }
        options.forEach(function (option) {
            var index = option.toLowerCase();
            index = index.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, key) {
                if (+match === 0) {
                    return '';
                }
                return key === 0 ? match.toLowerCase() : match.toUpperCase();
            });
            checkBoxOptions.push(new CheckboxOption(index, index, index, option));
        });
        return checkBoxOptions;
    };
    PermitService.prototype.generateCourseMaps = function (length) {
        for (var loop = 0; loop < length; loop++) {
            this.courseMaps[loop] = [];
            this.courseMaps[loop][0] = new CourseMap();
        }
    };
    PermitService.prototype.getSubDisciplines = function (index) {
        var selectedDisciplines;
        selectedDisciplines = [];
        this.courseMaps[index].forEach(function (courseMap) {
            //  console.log(courseMap.courseSubdiscOptions);
            selectedDisciplines = selectedDisciplines.concat(courseMap.courseSubdiscOptions);
            //  console.log(selectedDisciplines);
        });
        this.courseMaps[index].forEach(function (courseMap) {
            courseMap.selectedSubDisciplines = selectedDisciplines;
        });
        return selectedDisciplines;
    };
    PermitService.prototype.getCalendarLabels = function (disciplineId) {
        var queryString = disciplineId ? "?discipline_ids[]=" + disciplineId : '';
        return this.http.get(this.url('calendar/labels' + queryString), this.options)
            .pipe(tap(function (_) { return 'fetched event calendar labels'; }), catchError(this.handleError('getCalendarLabels')));
    };
    PermitService.prototype.getCalendarLabelsForEvent = function (eventId) {
        return this.http.get(this.url("calendar/labels/" + eventId), this.options)
            .pipe(tap(function (_) { return 'fetched event calendar labels'; }), catchError(this.handleError('getCalendarLabelsForEvent')));
    };
    PermitService.prototype.getCalendarLabelAnswersForEvent = function (eventId) {
        return this.http.get(this.url("calendar/labels/" + eventId + "/answers"), this.options)
            .pipe(tap(function (_) { return 'fetched event calendar label answers'; }), catchError(this.handleError('getCalendarLabelAnswersForEvent')));
    };
    PermitService.prototype.getCalendarQuestions = function (disciplineId) {
        return this.http.get(this.url("calendar/questions?discipline_ids[]=" + disciplineId), this.options)
            .pipe(tap(function (_) { return 'fetched event calendar label questions'; }), catchError(this.handleError('getCalendarQuestions')));
    };
    PermitService.prototype.postCalendarLabels = function (label_ids, eventId) {
        return this.http.post(this.url("calendar/labels/" + eventId), { label_ids: label_ids }, this.options)
            .pipe(tap(function (_) { return 'saved event calendar labels'; }), catchError(this.handleError('postCalendarLabels')));
    };
    PermitService.prototype.postCalendarLabelAnswers = function (answers, eventId) {
        return this.http.post(this.url("calendar/labels/" + eventId + "/answers"), { answers: answers }, this.options)
            .pipe(tap(function (_) { return 'saved event calendar labels'; }), catchError(this.handleError('postCalendarLabelAnswers')));
    };
    PermitService.prototype.addAnotherCourseMap = function (index) {
        if (this.courseMaps[index] === undefined) {
            return;
        }
        var course = new CourseMap();
        var currentPos = this.courseMaps[index].length;
        this.courseMaps[index][currentPos] = course;
        return this.courseMaps[index][currentPos];
    };
    PermitService.prototype.getQuestionConfig = function (disciplineId) {
        var _this = this;
        // tslint:disable-next-line: radix
        var discipline = '';
        switch (disciplineId) {
            case 19:
                discipline = 'road';
                break;
            case 20:
                discipline = 'track';
                break;
            case 21:
                discipline = 'mtb';
                break;
            case 23:
                discipline = 'cx';
                break;
            case 24:
                discipline = 'granfondo';
                break;
            case 25:
                discipline = 'gravel';
                break;
            case 27:
                discipline = 'funrides';
                break;
            default: discipline = 'global';
        }
        this.http.get(environment.nextGenApiUrl + 'permit/questions/1', this.options).subscribe(function (questions) {
            questions.forEach(function (question) {
                var disciplines = question.optedfor.split(',');
                if (disciplines.includes('global') || disciplines.includes(discipline)) {
                    _this.availableQuestionsList.push(question.pq_id);
                }
            }, function () {
                console.log(_this.availableQuestionsList);
            });
        });
    };
    PermitService.prototype.isEnabledQuestion = function (question) {
        if (this.availableQuestionsList.length === 0) {
            return true;
        }
        return this.availableQuestionsList.includes(question);
    };
    PermitService.prototype.savePermit = function (id, saveAndExit, navUrl) {
        var _this = this;
        var answers = {
            // q1: { name: this.contactName, email : this.contactEmail, phone: this.contactPhone },
            // q2: this.medicalStaffName,
            // q3: this.medicalFacilities,
            //  q4: this.policeSecurity,
            //  q5: this.securityContacts,
            q6: this.communicationTypes,
            q7: { timingInformation: this.timingInformation, timingProvider: this.timingProvider },
            q8: this.timings,
            q9: this.registrationProvider,
            q10: this.stageArea,
            q11: [],
            q12: this.courseMarking,
            // q14: this.payOut,
            q15: this.maxPayOut,
            q16: this.localPermitApproval,
        };
        this.courseMaps.forEach(function (courseMap) {
            answers.q11.push(Object.assign({}, courseMap));
        });
        var finalAnswers = {};
        Object.entries(answers).forEach(function (answer) {
            console.log(answer);
            // tslint:disable-next-line: radix
            var index = parseInt(answer[0].replace('q', ''));
            if (_this.availableQuestionsList.includes(index)) {
                finalAnswers[answer[0]] = answer[1];
            }
        });
        if (this.mode === 'create') {
            return this.http.post(this.url('permit/answers'), {
                permit_id: id,
                answers: finalAnswers
            }, this.options);
        }
        else {
            return this.http.put(this.url('permit/answers'), {
                permit_id: id,
                answers: finalAnswers
            }, this.options);
        }
    };
    PermitService.prototype.getPermitInfo = function (id) {
        return this.http.get(this.url('permit/answers/' + id), this.options);
    };
    PermitService.prototype.fetchFromAnswers = function () {
        var _this = this;
        this.answers.forEach(function (answer) {
            switch (answer.qid) {
                // case 1:
                //   this.contactName = answer.description.name;
                //   this.contactEmail = answer.description.email;
                //   this.contactPhone = answer.description.phone; break;
                // case 2: this.medicalStaffName = (answer.answer === '' ? answer.description : answer.answer); break;
                // case 3: this.medicalFacilities = answer.description; break;
                //  case 4: this.policeSecurity = answer.answer === '' ? answer.description : answer.answer; break;
                //  case 5: this.securityContacts = answer.answer === '' ? answer.description : answer.answer; break;
                case 6:
                    _this.communicationTypes = answer.answer === '' ? answer.description : answer.answer;
                    break;
                case 7:
                    _this.timingInformation = answer.description.timingInformation;
                    _this.timingProvider = answer.description.timingProvider;
                    break;
                case 8:
                    _this.timings = answer.answer === '' ? answer.description : answer.answer;
                    break;
                case 9:
                    _this.registrationProvider = answer.answer === '' ? answer.description : answer.answer;
                    break;
                case 10:
                    _this.stageArea = answer.answer === '' ? answer.description : answer.answer;
                    break;
                case 11:
                    _this.courseMaps = [];
                    var courseMaps = void 0;
                    courseMaps = answer.answer === '' ? answer.description : answer.answer;
                    courseMaps.forEach(function (dayCourseMaps, index) {
                        _this.courseMaps[index] = [];
                        dayCourseMaps.forEach(function (courseMapInfo) {
                            var courseMap;
                            courseMap = new CourseMap();
                            //  console.log(courseMapInfo);
                            courseMap.courseDisplay = 'url';
                            courseMap.courseUrl = courseMapInfo.courseUrl;
                            courseMap.courseSubdiscOptions = courseMapInfo.courseSubdiscOptions;
                            _this.courseMaps[index].push(courseMap);
                        });
                    });
                    console.log(_this.courseMaps);
                    break;
                case 12:
                    _this.courseMarking = answer.answer === '' ? answer.description : answer.answer;
                    break;
                // case 14: this.payOut = answer.answer === '' ? answer.description : answer.answer; break;
                case 15:
                    _this.maxPayOut = answer.answer === '' ? answer.description : answer.answer;
                    break;
                case 16:
                    _this.localPermitApproval = answer.answer === '' ? answer.description : answer.answer;
                    break;
            }
        });
        return true;
    };
    PermitService.prototype.updateStatusAndNavigate = function (eventId, status, navUrl) {
        var _this = this;
        this.eventService.updateEventStatus(eventId, status).subscribe(function (resp) {
            if (resp.message) {
                _this.route.navigate([navUrl]);
            }
            else {
                // TODO: Consider more explicit error handling
            }
        });
    };
    PermitService.prototype.createPermit = function (eventId, part, navUrl, isRaceOrRide) {
        var _this = this;
        // Adding static in status
        var data = { status: this.status };
        var url = navUrl ? navUrl : "/event/" + eventId + "/permit-application/part-2";
        // Create if permit not found
        if (!this.permitId) {
            this.http.post(this.url(eventId + "/permit"), data, this.options).subscribe(function (resp) {
                console.log(resp);
                if (Object.keys(resp).includes('permit_id')) {
                    // tslint:disable-next-line: no-string-literal
                    _this.permitId = resp['permit_id'];
                }
            }, function (error) {
                console.log(error);
                if (error.status === 401) {
                    // TODO: Handle unauthorized
                    console.log('Unauthorized! Prompt user to login');
                    window.location.href = '/unauthorized';
                }
            }, function () {
                console.log('completed');
                if (part === 'part1') {
                    _this.savePermit(_this.permitId).subscribe(function (response) {
                        // tslint:disable-next-line: no-string-literal
                        if (response['status'] === true) {
                            // TODO: Is check for listing 'mode' (to fix Permitting I issue) still necessary?
                            if (_this.mode === 'create') {
                                _this.updateStatusAndNavigate(eventId, 'permit-in-progress', url);
                            }
                            else if (_this.mode === 'update') {
                                _this.route.navigate([url]);
                            }
                        }
                    });
                }
            });
        }
        else {
            // Update if permit exists
            this.savePermit(this.permitId).subscribe(function (response) {
                var status = 'status';
                if (response[status] === true && _this.mode === 'create' && part === 'part1') {
                    // Set event status to 'Permit in Progress' (partially complete)
                    _this.updateStatusAndNavigate(eventId, 'permit-in-progress', url);
                }
                else if (response[status] === true && _this.mode === 'create' && part === 'part2') {
                    if (isRaceOrRide) {
                        _this.route.navigate(["/event/" + eventId + "/event-incentives"]);
                    }
                    else {
                        _this.route.navigate(["/event/" + eventId + "/insured-certificates"]);
                    }
                }
                else if (response[status] === true && _this.mode === 'update' && part === 'part1') {
                    _this.route.navigate([url]);
                }
                else if (response[status] === true && _this.mode === 'update' && part === 'part2') {
                    _this.route.navigate(["/event/" + eventId + "/overview"]);
                }
            });
        }
    };
    PermitService.prototype.deleteCourseMaps = function (permitId) {
        var _this = this;
        return this.http.delete(this.url("permit/answers/" + permitId + "/courseMaps/delete"), this.options)
            .pipe(tap(function (_) { return _this.log("Deleted course maps for permit id: " + permitId); }), catchError(this.handleError('deleteCourseMaps', {}, { permitId: permitId })));
    };
    PermitService.prototype.removeSubCourseMap = function (pos, index) {
        this.courseMaps[pos].splice(index, 1);
    };
    PermitService.prototype.reset = function () {
        // this.contactName = undefined;
        // this.contactEmail = undefined;
        // this.contactPhone = undefined;
        // this.medicalStaffName = [];
        // this.medicalFacilities = {};
        this.timingInformation = undefined;
        this.timingProvider = undefined;
        this.registrationProvider = undefined;
        this.communicationTypes = [];
        this.courseMarking = [];
        //  this.policeSecurity = undefined;
        //  this.securityContacts = [];
        this.timings = undefined;
        this.courseMaps = [];
        this.stageArea = '';
        // this.payOut = undefined;
        this.maxPayOut = undefined;
        this.mode = 'create';
        this.answers = undefined;
        this.permitId = undefined;
        this.status = 'In Progress';
        this.localPermitApproval = undefined;
        this.availableQuestionsList = [];
    };
    PermitService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermitService_Factory() { return new PermitService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.EventService)); }, token: PermitService, providedIn: "root" });
    return PermitService;
}(BaseService));
export { PermitService };
