<div *ngIf="displayFormEventDate() || displayFormEvent()" class="main-container container">
  <h2>Post Event Form</h2>
  <h3>{{event.event_name}}</h3>
  <h4 *ngIf="eventDate">
    <span *ngIf="eventDate.event_date_description">{{eventDate.event_date_description}} - </span>
    {{eventDate.event_date_start | date: 'fullDate':'UTC'}}
  </h4>
  <h5>Discipline: {{disciplineName}}</h5>
  <p class="space-below-lg">Part 3 of 3</p>

  <h5 class="mb-4 font-weight-bold">
    USA Cycling has established a minimum insurance payment for all events. This minimum will apply
    to events with a rider count lower than the count required to meet the minimum total of $300 for
    the liability insurance. Anyone who has a rider count that totals out to more than $300 will simply
    pay the per rider fee upon checkout.
  </h5>

  <!-- Non-Series -->
  <app-post-event-2-form *ngIf="displayFormEvent()"
    [event]="event"
    [isSeries]="isSeries"
    [eventDates]="eventDates"
    [urlSegments]="urlSegments"
    [userRole]="userRole"
    [currentUser]="currentUser"
    [eventOrganizer]="eventOrganizer"
    [calendarLabels]="calendarLabels"
    [calendarLabelQuestions]="calendarLabelQuestions"
    [calendarLabelAnswers]="calendarLabelAnswers"
  ></app-post-event-2-form>
  <!-- Series (per event date) -->
  <app-post-event-2-form *ngIf="displayFormEventDate()"
    [event]="event"
    [isSeries]="isSeries"
    [eventDate]="eventDate"
    [urlSegments]="urlSegments"
    [userRole]="userRole"
    [currentUser]="currentUser"
    [eventOrganizer]="eventOrganizer"
    [calendarLabels]="calendarLabels"
    [calendarLabelQuestions]="calendarLabelQuestions"
    [calendarLabelAnswers]="calendarLabelAnswers"
  ></app-post-event-2-form>
</div>
