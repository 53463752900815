import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { BaseService } from './base-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
var EventDayService = /** @class */ (function (_super) {
    tslib_1.__extends(EventDayService, _super);
    function EventDayService(http, cookie) {
        var _this = _super.call(this, http, cookie) || this;
        _this.http = http;
        _this.cookie = cookie;
        _this.setupTeardownDays = [];
        _this.practiceDays = [];
        _this.existingDays = [];
        _this.eventDays = [];
        return _this;
    }
    EventDayService.prototype.getEventDaysForEvent = function (eventId) {
        var _this = this;
        return this.http.get(this.url("event/" + eventId + "/calendar"), this.options)
            .pipe(tap(function (_) { return _this.log('fetched event days'); }), catchError(this.handleError('getEventDaysForEvent', [], { eventId: eventId })));
    };
    EventDayService.prototype.getEventDaysForEventByType = function (eventId, type) {
        var _this = this;
        return this.http.get(this.url("event/" + eventId + "/calendar/" + type), this.options)
            .pipe(tap(function (_) { return _this.log('fetched event days by type'); }), catchError(this.handleError('getEventDaysForEventByType', [], { eventId: eventId, type: type })));
    };
    EventDayService.prototype.getEventDay = function (eventId, eventDayId) {
        var _this = this;
        return this.http.get(this.url("event/" + eventId + "/calendar/" + eventDayId), this.options)
            .pipe(tap(function (_) { return _this.log('fetched event day'); }), catchError(this.handleError('getEventDay', null, { eventId: eventId, eventDayId: eventDayId })));
    };
    EventDayService.prototype.addEventDays = function (eventId) {
        var _this = this;
        this.eventDays = this.existingDays.concat(this.setupTeardownDays, this.practiceDays);
        return this.http.post(this.url("event/" + eventId + "/calendar"), { days: this.eventDays }, this.options)
            .pipe(tap(function (newEventDays) { return _this.log("added event days for event id: " + eventId); }), catchError(this.handleError('addEventDay', [], { eventId: eventId, eventDays: this.eventDays })));
    };
    EventDayService.prototype.clearEventDays = function (eventId) {
        var _this = this;
        return this.http.post(this.url("event/" + eventId + "/calendar"), { days: null }, this.options)
            .pipe(tap(function (clearedEventDays) { return _this.log("cleared event days for event id: " + eventId); }), catchError(this.handleError('clearEventDays', [], { eventId: eventId })));
    };
    // Use if delete route added. Otherwise, use clearEventDays()
    // deleteEventDay(eventId: number|string, eventDayId: number|string): Observable<any> {
    //   return this.http.delete(this.url(`event/${eventId}/calendar/${eventDayId}`), this.options)
    //     .pipe(
    //       tap(_ => this.log(`deleted event day id: ${eventDayId}`)),
    //       catchError(this.handleError('deleteEventDay'))
    //     );
    // }
    EventDayService.prototype.setExistingDays = function (eventId) {
        var _this = this;
        this.getEventDaysForEvent(eventId).subscribe(function (days) {
            _this.existingDays = days;
        });
    };
    EventDayService.prototype.updateSelectedDays = function (collection, days) {
        switch (collection) {
            case 'setupTeardown':
                this.setupTeardownDays = days;
                break;
            case 'practice': this.practiceDays = days;
        }
    };
    EventDayService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventDayService_Factory() { return new EventDayService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: EventDayService, providedIn: "root" });
    return EventDayService;
}(BaseService));
export { EventDayService };
