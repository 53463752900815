import { Club } from '.';

export class Event {
  event_id: number;
  event_name: string;
  event_description: string;
  event_start_date: string;
  event_end_date: string;
  event_permit_id: number;  // ID
  event_discipline: number; // ID
  event_organizer: number;  // ID
  event_types: any[]; // ID
  event_largest_purse: number;
  event_equal_pay: string;
  event_bike_reg: string; // 'Y' or 'N'
  event_reg_url: string;
  event_website: string;
  event_announcement: string;
  event_logo: string;
  event_facebook_url: string;
  event_twitter_url: string;
  event_youtube_url: string;
  event_instagram_url: string;
  event_pre_reg: number;
  event_on_site_reg: number;
  event_total_reg: number;
  event_competitive: string;
  event_listing_owner: number;  // ID
  event_status: string;
  event_contacts: any[];  // ID
  event_sponsoring_clubs: Club[];
  event_special_calendars: any[];
  event_local_association: any;
  event_closed_comments: string;
  event_closed_without_paperwork: boolean;
  event_label_discount: boolean;
  created_at: Date;
  is_pended_outstanding_permit_fee: boolean;

  constructor(data) {
    this.event_id = data.event_id;
    this.event_name = data.event_name;
    this.event_description = data.event_description;
    this.event_start_date = data.event_start_date;
    this.event_end_date = data.event_end_date;
    this.event_permit_id = data.event_permit_id;
    this.event_discipline = data.event_discipline;
    this.event_organizer = data.event_organizer;
    this.event_types = data.event_types;
    this.event_largest_purse = data.event_largest_purse;
    this.event_equal_pay = data.event_equal_pay;
    this.event_bike_reg = data.event_bike_reg;
    this.event_reg_url = data.event_reg_url;
    this.event_website = data.event_website;
    this.event_announcement = data.event_announcement;
    this.event_logo = data.event_logo;
    this.event_facebook_url = data.event_facebook_url;
    this.event_twitter_url = data.event_twitter_url;
    this.event_youtube_url = data.event_youtube_url;
    this.event_instagram_url = data.event_instagram_url;
    this.event_pre_reg = data.event_pre_reg;
    this.event_on_site_reg = data.event_on_site_reg;
    this.event_total_reg = data.event_total_reg;
    this.event_competitive = data.event_competitive;
    this.event_listing_owner = data.event_listing_owner;
    this.event_status = data.event_status;
    this.event_contacts = data.event_contacts;
    this.event_sponsoring_clubs = data.event_sponsoring_clubs;
    this.event_special_calendars = data.event_special_calendars;
    this.event_local_association = data.event_local_association;
    this.event_closed_comments = data.event_closed_comments;
    this.event_closed_without_paperwork = data.event_closed_without_paperwork;
    this.event_label_discount = data.event_label_discount;
    this.created_at = data.created_at;
    this.is_pended_outstanding_permit_fee = data.is_pended_outstanding_permit_fee;
  }

  // Race & Ride disciplines, as they pertain to Smart Calendar (event incentives)
  private raceDisciplines = [ 19, 20, 21, 23 ];
  private rideDisciplines = [ 24, 25, 27 ];

  public get isPermit(): boolean {
    return !!this.event_permit_id;
  }

  public get isVirtual(): boolean {
    if (this.event_discipline) {
      return this.event_discipline === 38;
    }
    return false;
  }


  get isRace(): boolean {
    if (this.event_discipline) {
      return this.raceDisciplines.includes(this.event_discipline);
    }
  }

  get isRide(): boolean {
    if (this.event_discipline) {
      return this.rideDisciplines.includes(this.event_discipline);
    }
  }

  get isRaceOrRide(): boolean {
    return this.isRace || this.isRide;
  }

  get overviewLink(): string {
      return `/event/${this.event_id}/overview`;
  }
}
