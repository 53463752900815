export class CourseMap {
   public courseDisplay: string;
   public courseUrl: string;
   public courseSubdiscOptions: Array<string>;
   public selectedSubDisciplines: Array<string>;

   constructor() {
      this.courseDisplay = '';
      this.courseUrl = '';
      this.courseSubdiscOptions = [];
      this.selectedSubDisciplines = [];
   }

   getAvailaleSubDisciplines(allSubDisciplines: Array<string>): Array<string> {
      let subDisciplines: Array<string>;
      subDisciplines = [];
      allSubDisciplines.forEach(subDiscipline => {
         if (!this.selectedSubDisciplines.includes(subDiscipline) ) {
            subDisciplines.push(subDiscipline);
         }
      });
      subDisciplines = subDisciplines.concat(this.courseSubdiscOptions);
      return subDisciplines;
   }
}
