import { OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Alert } from 'src/app/classes';
import { Router, NavigationEnd } from '@angular/router';
import { AppShellService } from './services/app-shell.service';
import { AuthGuardService, ShopifyService } from './shared';
import { AuthenticationService, EventListingService, EventService } from './services';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CookieService } from 'ngx-cookie-service';
var AppComponent = /** @class */ (function () {
    function AppComponent(appShell, cookie, authService, authGuardService, eventListingService, eventService, router, gtmService, shopifyService, device) {
        this.appShell = appShell;
        this.cookie = cookie;
        this.authService = authService;
        this.authGuardService = authGuardService;
        this.eventListingService = eventListingService;
        this.eventService = eventService;
        this.router = router;
        this.gtmService = gtmService;
        this.shopifyService = shopifyService;
        this.device = device;
        this.title = 'USAC Permitting';
        this.env = environment.env;
        this.displaySteps = false;
        this.checkoutDisabled = false;
        this.cartAlerts = [];
        // cartAlertClass = 'info';
        this.otherAlerts = [];
        this.otherAlertClass = 'info';
        this.authLoading = false;
        this.checkoutLoading = false;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.authGuardService.expectedNonce()) {
            this.authGuardService.removeNonce();
            window.location.assign(environment.adminUrl + ("/login?redirectUrl=" + environment.publicUrl));
        }
        if (!this.cookie.check('USACLOGIN')) {
            return this.authService.doLogin(window.location.href);
        }
        // Include here any routes where the application steps should be displayed
        this.stepDisplayRoutes = [
            '/event/listing',
            '/review',
            '/permit-application',
            '/event-incentives',
            '/insured-certificates',
            '/add-ons'
        ];
        // Include here any routes where the cart should be displayed
        this.cartDisplayRoutes = [
            '/insured-certificates',
            '/add-ons'
        ];
        // Make cart fixed for specific routes
        this.cartDisplayFixedRoutes = [
            '/queue'
        ];
        this.eventPermitAnnouncmentRoutes = [
            // TODO: Determine if these need to be updated to the '/event/:eventId' pattern
            '/event/listing',
            '/review'
        ];
        this.unauthorizedRoutes = [
            '/unauthorized'
        ];
        this.router.events.forEach(function (event) {
            if (event instanceof NavigationEnd) {
                var gtmTag = {
                    event: 'page',
                    pageName: _this.routeTitle || environment.publicUrl + event.url
                };
                _this.gtmService.pushTag(gtmTag);
            }
        });
    };
    Object.defineProperty(AppComponent.prototype, "routeTitle", {
        get: function () {
            var currentRoute = this.router.routerState.snapshot.root;
            var child = currentRoute.firstChild;
            // For nested routing modules, drill down to the destination child route
            while (child) {
                if (child.firstChild) {
                    child = child.firstChild;
                }
                else if (child.data && child.data.title) {
                    return child.data.title;
                }
                else {
                    return null;
                }
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.onRouteLoad = function (event) {
        this.currentRoute = this.router.url;
        this.currentStep = event.currentStep;
        this.toggleSteps();
        this.toggleCart();
        this.setFixedCart();
        this.displayCartAlert();
        this.displayOtherAlerts();
        this.disableCheckout();
    };
    AppComponent.prototype.toggleSteps = function () {
        var _this = this;
        if (this.stepDisplayRoutes.some(function (route) { return _this.currentRoute.includes(route); })) {
            this.displaySteps = this.eventListingService.mode === 'create' ? true : false;
        }
        else {
            this.displaySteps = false;
        }
    };
    // Commented to allow open on desktop and close on mobile behavior. - NA
    AppComponent.prototype.toggleCart = function () {
        var _this = this;
        if (this.device.isMobile()) {
            // console.log('is mobile, toggle');
            this.displayCart = true;
        }
        else {
            // console.log('is desktop. no toggle');
            this.displayCart = !this.cartDisplayRoutes.some(function (route) { return _this.currentRoute.includes(route); });
            // this.displayCart = false;
        }
    };
    AppComponent.prototype.closeCart = function () {
        this.displayCart = false;
    };
    AppComponent.prototype.setFixedCart = function () {
        if (this.cartDisplayFixedRoutes.includes(this.currentRoute)) {
            this.displayFixedCart = true;
        }
        else {
            this.displayFixedCart = false;
        }
    };
    AppComponent.prototype.displayOtherAlerts = function () {
        this.otherAlerts = [];
        // if (this.eventPermitAnnouncmentRoutes.some(route => this.currentRoute.includes(route))) {
        //   this.otherAlerts.push(
        //     new Alert(
        //       this.otherAlertClass, 'Some general announcement here',
        //       'Notice', 'warning', null, null, null, true
        //     )
        //   );
        // }
        if (!this.cookie.check('USACLOGIN') && this.unauthorizedRoutes.includes(this.currentRoute)) {
            this.otherAlerts.push(new Alert(this.otherAlertClass, 'Your token has expired', 'Unauthorized', 'user', null, null, null, false));
        }
    };
    AppComponent.prototype.displayCartAlert = function () {
        var _this = this;
        this.cartAlerts = [];
        if (this.cartDisplayFixedRoutes.includes(this.router.url)) {
            this.cartAlerts.push(new Alert(this.otherAlertClass, 'Welcome to the new event management system - events created before 4/16/2020 can be managed' +
                ' <a target="_blank" href="https://legacy.usacycling.org/myusac/index.php?pagename=eventpermit">here</a>.', null, 'flag', null, null, null, true));
        }
        if (this.currentRoute.includes('overview')) {
            if (this.eventIdFromRoute) {
                var eventId_1 = this.eventIdFromRoute;
                this.shopifyService.getShopifyDraftOrders({ event_id: eventId_1, incomplete: true }).subscribe(function (drafts) {
                    if (drafts.length > 0) {
                        _this.eventService.getEventById(eventId_1).subscribe(function (event) {
                            var start = Date.parse(event.event_start_date);
                            var today = new Date().getTime();
                            // If event starts within 2 weeks of today, change class from 'info' to 'warning'.
                            var alertClass = (((start - today) / 24 / 60 / 60 / 1000) <= 14) ? 'warning' : 'info';
                            var p = _this.plural(drafts);
                            var rp = _this.reversePlural(drafts);
                            _this.cartAlerts.push(new Alert(alertClass, "Your recent order" + p + " require" + rp + " payment. Please complete your purchase" + p + ".", 'Payment Notice', 'shopping-cart', 'View Payments', "/event/" + eventId_1 + "/overview#payments", null, false));
                        });
                    }
                });
            }
        }
    };
    AppComponent.prototype.disableCheckout = function () {
        this.checkoutDisabled = this.currentRoute.includes('insured-certificates');
    };
    AppComponent.prototype.plural = function (drafts) {
        return drafts.length > 1 ? 's' : '';
    };
    AppComponent.prototype.reversePlural = function (drafts) {
        return drafts.length > 1 ? '' : 's';
    };
    Object.defineProperty(AppComponent.prototype, "eventIdFromRoute", {
        get: function () {
            return this.router.routerState.snapshot.root.firstChild.params.eventId;
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.onCheckoutLoad = function ($event) {
        this.checkoutLoading = $event;
    };
    return AppComponent;
}());
export { AppComponent };
