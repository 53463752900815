import { AuthenticationService } from '../services/authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
var AppShellService = /** @class */ (function () {
    function AppShellService(authService) {
        this.authService = authService;
        this.steps = [
            'Event Listing',
            'Application',
            'Incentives',
            'Insurance',
            'Add-ons',
            'Checkout'
        ];
    }
    AppShellService.prototype.setCurrentStep = function (currentStep) {
        this.currentStep = currentStep;
    };
    AppShellService.prototype.getCurrentStep = function () {
        return this.currentStep;
    };
    AppShellService.prototype.tempLogin = function () {
        this.authService.doLogin(window.location.href);
    };
    AppShellService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppShellService_Factory() { return new AppShellService(i0.ɵɵinject(i1.AuthenticationService)); }, token: AppShellService, providedIn: "root" });
    return AppShellService;
}());
export { AppShellService };
