import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import qs from 'qs';
import { Event, EventDate, EventQueueRow, Discipline, EventType, SelectOption } from '../classes';
import { BaseService, PaginatedApiResponse } from './base-service';

export interface TransactionsReportQueryParams {
  checkout_date_range?: { [key: string]: string };
  organizer_comp_id?: string|number;
  organizer_first?: string;
  organizer_last?: string;
  no_paginate?: boolean;
}

export interface AssignmentsReportQueryParams {
  event_start_date?: string;
  event_end_date?: string;
  event_id ?: string|number;
  event_zip?: string|number;
  states?: string[];
  chief_ref_first?: string;
  chief_ref_last ?: string;
  chief_ref_profile_id?: string|number;
  no_paginate?: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class ReportsService extends BaseService {
  public allowedQueueParams: string[];

  constructor(
    protected http: HttpClient,
    protected cookie: CookieService
  ) {
    super(http, cookie);

    // this.allowedQueueParams = [
    //   'no_paginate',
    //   'page',
    //   'city',
    //   'states',
    //   'disciplines',
    //   'event_start_date',
    //   'event_end_date',
    //   'listing_type',
    //   'status',
    //   'regions',
    //   'permit_date_range'
    // ];
  }

  getTransactions(params?: TransactionsReportQueryParams): Observable<any[]> {
    let route = 'reports/transactions';

    if (params) {
      route += '?' + qs.stringify(params);
    }

    return this.http.get<any[]>(this.url(route), this.options)
      .pipe(
        tap(_ => this.log('fetched transaction reports')),
        catchError(this.handleError<any[]>('getTransactions', [], { params }))
      );
  }

  getOfficialAssignments(params?: AssignmentsReportQueryParams): Observable<any[]> {
    let route = 'reports/official_assignments';

    if (params) {
      route += '?' + qs.stringify(params);
    }

    return this.http.get<any[]>(this.url(route), this.options)
      .pipe(
        tap(_ => this.log('fetched official_assignments reports')),
        catchError(this.handleError<any[]>('getOfficialAssignments', [], { params }))
      );
  }
}
