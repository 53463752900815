import { Component, OnInit, Input, ContentChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { EventDate, Referee, Profile } from '../../classes';
import { SelectOption } from '../../classes/select_option.class';

import { ProfileService } from 'src/app/services';

@Component({
  selector: 'app-add-referee',
  templateUrl: './add-referee.component.html',
  styles: []
})
export class AddRefereeComponent implements OnInit {

  @Input() eventDates: EventDate[];
  @Input() isSeries: boolean;
  @Input() submitted: boolean;

  @Output() addReferee: EventEmitter<any> = new EventEmitter<any>();

  eventDateOptions: SelectOption[] = [];
  addRefereeForm: FormGroup;
  searchRefereeForm: FormGroup;
  searchRefereeSubmitted = false;
  displaySearchResults  = false;
  searching = false;
  searchButtonDisabled = true;

  result: Referee;

  constructor(
    private formBuilder: FormBuilder,
    private profileService: ProfileService
  ) { }

  get srf() { return this.searchRefereeForm.controls; }
  get arf() { return this.addRefereeForm.controls; }

  ngOnInit() {
    const eventDateRequired = this.isSeries ? Validators.required : null;
    this.addRefereeForm = this.formBuilder.group({
      event_date_id: [ '', eventDateRequired ]
    });

    this.searchRefereeForm = this.formBuilder.group({
      usacId: [ '' ],
      refereeName: [ '' ]
    });

    // TODO: Is there a way to set these values without a timeout?
    setTimeout(() => {
      this.eventDates.forEach(date => {
        const start = new Date(date.event_date_start);
        const startDate = `${start.getUTCMonth() + 1}/${start.getUTCDate()}/${start.getUTCFullYear()}`;
        this.eventDateOptions.push(new SelectOption(date.event_date_id.toString(), startDate));
      });
      this.arf.event_date_id.setValue(this.eventDateOptions[0].value);
    }, 500);

    this.searchRefereeForm.valueChanges.subscribe(data => {
      if (this.srf.refereeName.value === '' && this.srf.usacId.value === '') {
        this.searchButtonDisabled = true;
      } else {
        this.searchButtonDisabled = false;
      }
    });
  }

  showSearchResults() {
    this.displaySearchResults = true;
  }

  hideSearchResults() {
    this.displaySearchResults = false;
  }

  searchReferee() {
    this.searchRefereeSubmitted = true;

    // stop here if form is invalid
    if (this.searchRefereeForm.invalid) {
      return;
    }

    this.hideSearchResults();
    this.searching = true;

    // GET request to Profile API
    this.profileService.searchReferees(this.srf.usacId.value, this.srf.refereeName.value).subscribe(results => {
      this.searching = false;

      if (!results || results.length === 0) {
        this.result = null;
        this.showSearchResults();
        return;
      }

      // Show only first result
      for (let i = 0; i < 1; i++) {
        const result = results[i];
        const profile = new Profile(result);
        const address = result.address;
        let city;
        let state;

        if (address) {
          city = address.address_city || '';
          state = address.address_state || '';
        } else {
          city = '';
          state = '';
        }

        this.result = new Referee(
          profile.profile_first_name,
          profile.profile_last_name,
          profile.profile_id,
          profile.age,
          city,
          state
        );
        this.showSearchResults();
      }
    });

    // this.profileService.getProfiles().subscribe(profiles => {
    //   const searchData = this.searchRefereeForm.value;
    //   const result: Profile = profiles.find(p =>
    //     p.profile_comp_id.toString() === searchData.usacId ||
    //     p.profile_first_name + ' ' + p.profile_last_name === searchData.refereeName.trim()
    //   );

    //   if (!result) {
    //     this.result = null;
    //     this.showSearchResults();
    //     return;
    //   }

    //   const profile = new Profile(result);

    //   const address = profile.address.find(addr => addr.address_primary === 'Y');
    //   const city = address.address_city || '';
    //   const state = address.address_state || '';

    //   this.result = new Referee(
    //     profile.profile_first_name,
    //     profile.profile_last_name,
    //     profile.profile_comp_id,
    //     profile.age,
    //     city,
    //     state
    //   );
    //   this.showSearchResults();
    // });



    // this.result = this.profile.searchReferees(this.searchRefereeForm.value);
    // this.showSearchResults();
  }

  saveReferee(referee?: Referee) {
    if (referee) {
      this.addReferee.emit({ event_date_id: this.arf.event_date_id.value, chief_ref: referee });
      this.searchRefereeForm.reset();
      this.searchRefereeSubmitted = false;
      this.hideSearchResults();
    }
  }
}
