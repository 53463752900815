export class DriverLicense {
  id: number|string;
  first_name: string;
  last_name: string;
  profile_id: number;
  license_number: string;
  permit_id: number;

  constructor(data) {
    this.id = data.id;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.profile_id = data.profile_id;
    this.license_number = data.license_number;
    this.permit_id = data.permit_id;
  }
}
