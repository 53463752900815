<ngb-accordion (panelChange)="onPanelChange($event)" class="border-0 payment-accordion">
  <ngb-panel type="light">
    <ng-template ngbPanelHeader>
      <div class="row ml-0">
        <app-tos-accept [tosModalOpen]="tosModalOpen" [checkoutDisabled]="tosCheckoutDisabled" [eventId]="eventId"
          (close)="closeTosModal()" (accept)="handleTosAccept($event)"
          (complete)="handleCompleteCheckout($event)"></app-tos-accept>
        <div class="col-11 row align-items-center">
          <span class="date col-md-4 col-lg-3"><span>{{displayDate | date}}</span></span>
          <span class="product-name col-md-5 col-lg-2">
            <span>{{title}}</span>
          </span>
          <span class="price col-md-3 col-lg-2">
            <span>{{totalPrice | currency}}</span>
          </span>
          <div class="col-md-12 col-lg-5 py-2">
            <div class="payment-status d-flex">
              <span
                class="col badge badge-pill"
                [ngClass]="{'badge-danger': (!!draftOrder || voided), 'badge-success': paidOrFullRefund, 'badge-warning': pendingOrPartial}"
              >{{paidStatus | titlecase}}</span>
              <app-button *ngIf="!!draftOrder"
                [type]="'btn btn-light btn-sm'"
                (click)="openCheckout()"
              >Make Payment</app-button>
              <app-button *ngIf="!!order && !!order.summary_url"
                [type]="'btn btn-light btn-sm'"
                (click)="openOrder()"
              >View Receipt</app-button>
              <app-button *ngIf="!!draftOrder && isUsacAdmin"
                [type]="'btn btn-primary btn-sm icon-btn-sm'"
                (click)="openModal()"
              ><i class="icon icon-trash"></i></app-button>
            </div>
          </div>
        </div>
        <button ngbPanelToggle class="col-1 text-right btn icon-btn padding-right-zero"><i class="icon {{toggleIcon}}"></i></button>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="row pl-1 pr-1">
        <div class="col-lg-8 pr-lg-5">
            <h5 class="mb-4">Order Summary</h5>
            <div *ngFor="let item of lineItems" class="row mt-3">
              <span class="col-6">{{displayTitle(item)}}</span>
              <span class="col-3 text-right">Qty: {{item.quantity}}</span>
              <span class="col-3 text-right">{{priceToInt(item) * item.quantity | currency}}</span>
            </div>
          <hr>
          <div *ngIf="hasDiscounts" class="row mt-1">
            <span class="offset-5 col-3 text-right">Discount</span>
            <span class="col-4 text-right text-success">- {{totalDiscount | currency}}</span>
          </div>
          <div class="row mt-1">
            <span class="offset-5 col-3 text-right">Subtotal</span>
            <span class="col-4 text-right">{{subtotalPrice | currency}}</span>
          </div>
          <div *ngIf="tax" class="row mt-1">
            <span class="offset-5 col-3 text-right">Tax</span>
            <span class="col-4 text-right">{{tax | currency}}</span>
          </div>
          <div class="row mt-1">
            <span class="offset-5 col-3 text-right"><strong>Total</strong></span>
            <span class="col-4 text-right"><strong>{{totalPrice | currency}}</strong></span>
          </div>

          <div *ngIf="refund" class="row mt-3">
            <span class="offset-5 col-3 text-right"><strong>Refunded</strong></span>
            <span class="col-4 text-right text-success"><strong>({{totalRefunded | currency}})</strong></span>
          </div>

        </div>
        <div class="col-lg-4 mt-4 mt-lg-0">
          <div  *ngIf="!!order else draft" >
            <h5 class="mb-4">Payment Details</h5>
            <div class="d-flex justify-content-between">
              <span>Date</span>
              <span class="text-right">{{processedAt | date}}</span>
            </div>
            <div class="d-flex justify-content-between mt-3">
              <span>Order Number</span>
              <span class="text-right">{{order.name}}</span>
            </div>
            <div *ngIf="isUsacAdmin">
              <div class="d-flex justify-content-between mt-3">
                <span>Payment Method</span>
                <div class="text-right">
                  <span>{{paymentMethod | titlecase}}<br></span>
                  <span *ngIf="paidByCard">{{cardDetails}}</span>
                </div>
              </div>
              <!-- For manual payments w/o billing address -->
              <div *ngIf="!order.billing_address" class="row mt-3">
                <span class="col-12">Customer Email</span><br>
                <span class="col-12 mt-1 ml-1">{{order.email}}</span>
              </div>
              <!-- Orders w/ billing address-->
              <div *ngIf="order.billing_address" class="d-flex justify-content-between mt-3">
                <span>Customer Name</span>
                <span class="text-right">{{order.billing_address.first_name}} {{order.billing_address.last_name}}</span>
              </div>
              <div *ngIf="order.billing_address" class="d-flex justify-content-between mt-3">
                <span>Billing Address</span>
                <div class="text-right">
                    <span>{{order.billing_address.address1}}<br></span>
                    <span *ngIf="order.billing_address.address2">{{order.billing_address.address2}}<br></span>
                    <span>{{order.billing_address.city}}, {{order.billing_address.province_code}} {{order.billing_address.zip}}</span>
                </div>
              </div>
              <div *ngIf="!!note" class="row mt-3">
                <span class="col-12">Order Note</span>
                <span class="col-12 mt-1 ml-1 small">{{note}}</span>
              </div>
              <div *ngIf="hasDiscounts" class="row mt-3">
                <span class="col-12">Applied Discounts</span>
                <span *ngFor="let discount of appliedDiscounts" class="col-12 mt-1 ml-1 small">{{discount}}</span>
              </div>
            </div>
          </div>
          <ng-template #draft>
              <h5 class="text-danger"><em>Payment has not been completed.</em></h5>
              <div *ngIf="hasDiscounts" class="row mt-3">
                <span class="col-12">Applied Discounts</span>
                <span *ngFor="let discount of appliedDiscounts" class="col-12 mt-1 ml-1 small">{{discount}}</span>
              </div>
            </ng-template>
        </div>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>



<app-modal [id]="'cancel-payment-modal'" [title]="'Cancel Order'" [isOpen]="modalOpen" (close)="closeModal()">
  <app-modal-body>
    <p>Are you sure you want to cancel this order?</p>
    <div class="row">
      <div class="col-sm-6 text-left">
        <app-button [type]="'btn-secondary'" (click)="closeModal()">No, Exit</app-button>
      </div>
      <div class="col-sm-6 text-right">
        <app-button (click)="cancelPayment()">Yes, Cancel</app-button>
      </div>
    </div>
  </app-modal-body>
</app-modal>
