import { OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationStart } from '@angular/router';
import { NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ClubService, EventListingService, EventService, PermitService, ProfileService } from '../../services';
import { ShopifyService, GlobalService } from '../../shared';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { urlValidator } from 'src/app/validators';
import { Alert, EventDate } from 'src/app/classes';
import { CheckboxOption, RadioOption, SelectOption } from '../../classes';
import { environment } from 'src/environments/environment';
var EventListingFormComponent = /** @class */ (function () {
    function EventListingFormComponent(formBuilder, globalService, shopifyService, eventListing, event, clubs, router, upload, calendar, parser, permitService, profile) {
        this.formBuilder = formBuilder;
        this.globalService = globalService;
        this.shopifyService = shopifyService;
        this.eventListing = eventListing;
        this.event = event;
        this.clubs = clubs;
        this.router = router;
        this.upload = upload;
        this.calendar = calendar;
        this.parser = parser;
        this.permitService = permitService;
        this.profile = profile;
        this.submitting = false;
        // Wait for 1 second after changes stop to search for organizer
        this.searchOrganizerDelay = 1000;
        this.submitted = false;
        this.regState = false;
        this.bikeRegCheckboxState = true;
        this.otherRegCheckboxState = false;
        this.submitModalOpen = false;
        this.disciplineOptions = [
            new SelectOption(null, 'Select...')
        ];
        this.roadOptions = [];
        this.cyclocrossOptions = [];
        this.trackOptions = [];
        this.mtbOptions = [];
        this.bmxAndCxOptions = [];
        this.bmxFreeStyleOptions = [];
        this.granFondoOptions = [];
        this.gravelOptions = [];
        this.typesOptions = [];
        this.nonCompetitiveOptions = [];
        this.trainingRidesOptions = [];
        this.calendarOptions = [];
        this.specialCalendars = [];
        this.calendarLoading = false;
        this.disciplines = [];
        this.eventTypes = [];
        this.announcementAlert = [];
        this.logoAlert = [];
        this.calendarCodeState = false;
        this.displayDiscountMsg = false;
        this.displayFailedDiscountMsg = false;
        this.setEventDates = [];
        this.eventDatesChanged = false;
        this.eventAnnouncementOptions = [];
        this.announcementType = 'url';
        this.initialEventDates = [];
        this.announcementLabel = 'Choose file';
        this.logoLabel = 'Choose file';
        // commented out for MBR-2992
        // public subDisciplinesOptions: Array<SelectOption>;
        this.eventListingCardsLimit = 30;
        this.limitSelection = { limitSelection: 5 };
        this.showSaveExit = false;
        this.navUrl = '';
        this.queueRoute = '/queue';
        this.skipNavCatch = false;
        this.showDateConfirmation = false;
        this.permitSkus = [];
        this.permitStatuses = [
            'permit-in-progress', 'permit-in-review'
        ];
    }
    Object.defineProperty(EventListingFormComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.eventForm.controls; },
        enumerable: true,
        configurable: true
    });
    EventListingFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user = this.profile.currentUser;
        // Subscribe to draft order in cart component
        this.shopifyService.cartDraftOrder.subscribe(function (draftOrder) { return _this.draftOrder = draftOrder; });
        this.today = this.calendar.getToday();
        this.eventForm = this.formBuilder.group({
            event_organizer: [this.eventListing.event_organizer, Validators.required],
            event_organizer_comp_id: [this.eventListing.event_organizer_comp_id],
            organizerName: [this.eventListing.organizerName, Validators.required],
            organizerEmail: [this.eventListing.organizerEmail, Validators.required],
            event_sponsoring_clubs: [''],
            event_name: ['', Validators.required],
            event_start_date: [{ year: 2019, month: 1, day: 1 }, Validators.required],
            event_end_date: [{ year: 2019, month: 12, day: 31 }, Validators.required],
            event_discipline: ['', Validators.required],
            event_competitive: [''],
            event_types: [''],
            event_special_calendars: [''],
            calendarCode: ['', Validators.required],
            event_bike_reg: [''],
            bike_reg_check: [''],
            // Only used for checkbox functionality - no equivalent API field
            other_reg_check: [''],
            // tslint:disable-next-line: max-line-length
            event_reg_url: ['', [Validators.required, urlValidator()]],
            event_website: ['', [Validators.required, urlValidator()]],
            event_facebook_url: ['', urlValidator()],
            event_twitter_url: ['', urlValidator()],
            event_youtube_url: ['', urlValidator()],
            event_instagram_url: ['', urlValidator()],
            event_announcement_switch: ['url'],
            event_announcement: [''],
            event_logo: ['']
        });
        this.eventAnnouncementOptions = [
            new RadioOption('url', 'url', 'Enter URL'),
            new RadioOption('upload', 'upload', 'Upload File')
        ];
        var disc = parseInt(this.eventListing.event_discipline);
        if (disc && !this.eventListing.isVirtual) {
            this.permitFeeSku = environment.disciplineSkus[disc].permitFee;
            this.permitFeeSeriesSku = environment.disciplineSkus[disc].permitFeeSeries;
            this.permitFeeSeries10PlusSku = environment.disciplineSkus[disc].permitFeeSeries10Plus;
            this.permitSkus.push(this.permitFeeSku);
            this.permitSkus.push(this.permitFeeSeriesSku);
            this.permitSkus.push(this.permitFeeSeries10PlusSku);
        }
        this.setDateCards();
        this.eventListing.getEventTypes().subscribe(function (data) {
            _this.roadOptions = [
                new CheckboxOption('stage-control', 'stage', data[0].et_id.toString(), data[0].et_name),
                new CheckboxOption('omnium-control', 'omnium', data[1].et_id.toString(), data[1].et_name),
            ];
            _this.cyclocrossOptions = [
            // new CheckboxOption('series', 'series', data[3].et_id.toString(), data[3].et_name)
            ];
            _this.mtbOptions = [
                new CheckboxOption('stage-control', 'stage', data[0].et_id.toString(), data[0].et_name),
            ];
            _this.cyclocrossOptions = [
            // new CheckboxOption('series', 'series', data[3].et_id.toString(), data[3].et_name)
            ];
            _this.trackOptions = [
                new CheckboxOption('omnium-control', 'omnium', data[1].et_id.toString(), data[1].et_name),
            ];
            _this.cyclocrossOptions = [
            // new CheckboxOption('series', 'series', data[3].et_id.toString(), data[3].et_name)
            ];
            _this.bmxAndCxOptions = [
                new CheckboxOption('training-race', 'training-race', data[2].et_id.toString(), data[2].et_name),
            ];
            _this.bmxFreeStyleOptions = [
            // new CheckboxOption('series', 'series', data[3].et_id.toString(), data[3].et_name)
            ];
            _this.nonCompetitiveOptions = [
            // new CheckboxOption('series', 'series', data[3].et_id.toString(), data[3].et_name)
            ];
            _this.trainingRidesOptions = [
                new CheckboxOption('training-series', 'training-series', data[4].et_id.toString(), data[4].et_name)
            ];
        });
        this.eventListing.getDisciplines().subscribe(function (data) {
            _this.disciplines = _this.eventListing.filterDisciplines(data);
            _this.disciplines.forEach(function (discipline) {
                _this.disciplineOptions.push(new SelectOption(discipline.cd_id.toString(), discipline.cd_value));
            });
            _this.onChangeDiscipline();
        });
        this.f.event_name.setValue(this.eventListing.event_name);
        if (this.updateMode) {
            this.announcementLabel = this.eventListing.event_announcement;
            this.logoLabel = this.eventListing.event_logo;
            // Set initial date values to check for later changes
            this.initialStartDate = this.eventListing.event_start_date;
            this.initialEndDate = this.eventListing.event_end_date;
            this.event.getEventDates(this.eventListing.event_id).subscribe(function (dates) { return _this.initialEventDates = _this.event.sortEventDates(dates); });
        }
        this.profile.getCurrentUser().subscribe(function (user) {
            _this.profile.getUserPermissions().subscribe(function (permissions) {
                _this.userRole = _this.profile.setUserRole(permissions);
            });
            // Set organizer info from current user if not already set
            if (!_this.eventListing.event_organizer) {
                _this.f.event_organizer.setValue(user.profile_id);
                _this.f.event_organizer_comp_id.setValue(user.profile_comp_id);
                _this.f.organizerName.setValue(user.profile_first_name + ' ' + user.profile_last_name);
                _this.f.organizerEmail.setValue(user.profile_email);
            }
        });
        this.f.event_discipline.setValue(this.eventListing.event_discipline || null);
        if (typeof this.eventListing.event_start_date === 'string') {
            this.eventListing.event_start_date = new Date(this.eventListing.event_start_date);
        }
        if (this.eventListing.event_start_date instanceof Date) {
            var date = this.eventListing.event_start_date;
            this.f.event_start_date.setValue(date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear());
        }
        if (typeof this.eventListing.event_end_date === 'string') {
            this.eventListing.event_end_date = new Date(this.eventListing.event_end_date);
        }
        if (this.eventListing.event_end_date instanceof Date) {
            var date = this.eventListing.event_end_date;
            this.f.event_end_date.setValue(date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear());
        }
        this.f.event_types.setValue(this.eventListing.event_types);
        this.f.event_sponsoring_clubs.setValue(this.setSponsoringClubValues());
        // Mark clubs control as pristine after setting intial value
        setTimeout(function () { return _this.f.event_sponsoring_clubs.markAsPristine(); }, 1000);
        this.f.event_special_calendars.setValue(this.eventListing.event_special_calendars);
        this.f.calendarCode.setValue(this.eventListing.calendarCode);
        this.f.event_bike_reg.setValue(this.eventListing.event_bike_reg);
        this.setRegCheckValues();
        this.f.event_reg_url.setValue(this.eventListing.event_reg_url);
        this.f.event_website.setValue(this.eventListing.event_website);
        this.f.event_facebook_url.setValue(this.eventListing.event_facebook_url);
        this.f.event_twitter_url.setValue(this.eventListing.event_twitter_url);
        this.f.event_youtube_url.setValue(this.eventListing.event_youtube_url);
        this.f.event_instagram_url.setValue(this.eventListing.event_instagram_url);
        if (this.eventListing.event_announcement) {
            this.announcementType = this.eventListing.event_announcement.includes('laravel-api') ? 'upload' : 'url';
        }
        this.f.event_announcement_switch.setValue(this.announcementType);
        this.f.event_announcement.setValue(this.eventListing.event_announcement);
        this.f.event_logo.setValue(this.eventListing.event_logo);
        this.eventForm.valueChanges.subscribe(function (data) {
            _this.searchOrganizer(data.event_organizer_comp_id);
            _this.eventListing.event_organizer = data.event_organizer;
            _this.eventListing.event_organizer_comp_id = data.event_organizer_comp_id;
            _this.eventListing.organizerName = data.organizerName;
            _this.eventListing.organizerEmail = data.organizerEmail;
            _this.eventListing.event_name = data.event_name;
            _this.eventListing.event_start_date = data.event_start_date;
            _this.eventListing.event_end_date = data.event_end_date;
            _this.eventListing.event_discipline = data.event_discipline;
            _this.eventListing.event_competitive = data.event_competitive;
            _this.eventListing.event_types = data.event_types;
            _this.eventListing.event_sponsoring_clubs = data.event_sponsoring_clubs;
            _this.eventListing.event_special_calendars = data.event_special_calendars;
            _this.eventListing.calendarCode = data.calendarCode;
            _this.eventListing.event_bike_reg = data.event_bike_reg;
            _this.eventListing.event_reg_url = data.event_reg_url;
            _this.eventListing.event_website = data.event_website;
            _this.eventListing.event_facebook_url = data.event_facebook_url;
            _this.eventListing.event_twitter_url = data.event_twitter_url;
            _this.eventListing.event_youtube_url = data.event_youtube_url;
            _this.eventListing.event_instagram_url = data.event_instagram_url;
            _this.eventListing.event_announcement = data.event_announcement;
            _this.announcementType = data.event_announcement_switch;
            _this.eventListing.event_logo = data.event_logo;
            _this.requireCalendarCode();
            _this.setEndDateLimits();
            _this.setDateCards();
        });
        this.f.event_discipline.valueChanges.subscribe(function () {
            _this.onChangeSetTypesOptions();
            _this.onChangeSetCalendarOptions();
        });
        this.routerEvents = this.router.events.subscribe(function (event) {
            if (event instanceof NavigationStart && _this.minRequiredFields() && !_this.skipNavCatch) {
                if (event.url !== '/event/review' && _this.navUrl === '') {
                    _this.router.navigateByUrl(_this.router.url, { replaceUrl: true });
                    _this.openSaveExitModal(event.url);
                }
            }
        });
        if (this.updateMode) {
            this.shopifyService.getProducts()
                .then(function (products) {
                products.forEach(function (product) {
                    var permitId = _this.shopifyService.setProductIdFromVariantSku(product, _this.permitFeeSku);
                    if (permitId) {
                        _this.permitProductId = permitId;
                    }
                });
            });
            // Search orders & draft orders
            var ordersAndDrafts_1 = [];
            var filters_1 = { event_id: this.eventId, incomplete: true };
            this.shopifyService.getShopifyDraftOrders(filters_1).subscribe(function (drafts) {
                drafts.forEach(function (draft) { return ordersAndDrafts_1.push(draft); });
                _this.shopifyService.getShopifyOrders(filters_1).subscribe(function (orders) {
                    orders.forEach(function (order) { return ordersAndDrafts_1.push(order); });
                    _this.searchPermitFees(ordersAndDrafts_1);
                });
            });
        }
    };
    EventListingFormComponent.prototype.ngOnDestroy = function () {
        this.routerEvents.unsubscribe();
        this.closeSaveExitModal();
    };
    EventListingFormComponent.prototype.onChangeSetTypesOptions = function () {
        switch (this.f.event_discipline.value) {
            case '19':
                this.typesOptions = this.roadOptions;
                break;
            case '20':
                this.typesOptions = this.trackOptions;
                break;
            case '21':
                this.typesOptions = this.mtbOptions;
                break;
            case '22':
                this.typesOptions = this.bmxAndCxOptions;
                break;
            case '23':
                this.typesOptions = this.cyclocrossOptions;
                break;
            case '24':
                this.typesOptions = [];
                break;
            case '25':
            case '26':
            case '28':
                this.typesOptions = this.nonCompetitiveOptions;
                break;
            case '27':
                this.typesOptions = [];
                break;
            case '29':
                this.typesOptions = this.trainingRidesOptions;
                break;
            case '30':
                this.typesOptions = this.bmxFreeStyleOptions;
                break;
            default: this.typesOptions = [];
        }
    };
    EventListingFormComponent.prototype.onChangeSetCalendarOptions = function () {
        var _this = this;
        if (this.f.event_discipline.value) {
            this.calendarOptions = [];
            this.calendarLoading = true;
            this.event.getSpecialCalendars(this.f.event_discipline.value).subscribe(function (calendars) {
                _this.specialCalendars = calendars;
                calendars.forEach(function (cal) {
                    var val = cal.sc_name.toLowerCase().split(' ').join('-');
                    _this.calendarOptions.push(new CheckboxOption(cal.sc_id.toString(), cal.sc_name, cal.sc_id.toString(), cal.sc_name));
                });
                _this.calendarLoading = false;
            });
        }
    };
    EventListingFormComponent.prototype.requireCalendarCode = function () {
        if (this.eventListing.event_special_calendars === undefined
            || this.eventListing.event_special_calendars.length === 0) {
            this.calendarCodeState = false;
            return;
        }
        var codeCalendars = ['UCI', 'National Championship', 'National Calendar', 'National Team'];
        var codeCalendarIds = [];
        this.specialCalendars.filter(function (cal) { return codeCalendars.includes(cal.sc_name); }).forEach(function (cal) {
            codeCalendarIds.push(cal.sc_id.toString());
        });
        this.calendarCodeState = this.eventListing.event_special_calendars.some(function (calId) { return codeCalendarIds.includes(calId); });
    };
    EventListingFormComponent.prototype.searchOrganizer = function (organizerId) {
        var _this = this;
        if (organizerId && organizerId !== this.eventListing.event_organizer_comp_id) {
            if (this.searchOrganizerTimeout) {
                clearTimeout(this.searchOrganizerTimeout);
            }
            this.searchOrganizerTimeout = setTimeout(function () {
                _this.profile.getMemberByCompId(_this.f.event_organizer_comp_id.value).subscribe(function (member) {
                    var name = member ? member.profile_first_name + " " + member.profile_last_name : '';
                    var email = member ? member.profile_email : '';
                    if (member) {
                        _this.f.event_organizer.setValue(member.profile_id);
                    }
                    _this.f.organizerName.setValue(name);
                    _this.f.organizerEmail.setValue(email);
                });
            }, this.searchOrganizerDelay);
        }
    };
    EventListingFormComponent.prototype.setSponsoringClubValues = function () {
        if (this.updateMode) {
            var clubs_1 = [];
            this.eventListing.event_sponsoring_clubs.forEach(function (club) {
                clubs_1.push(new SelectOption(club.club_id, club.club_name));
            });
            return clubs_1;
        }
        else {
            return this.eventListing.event_sponsoring_clubs;
        }
    };
    EventListingFormComponent.prototype.setRegCheckValues = function () {
        if (this.eventListing.event_bike_reg && this.eventListing.event_bike_reg !== '') {
            this.f.bike_reg_check.setValue(this.eventListing.event_bike_reg === 'Y');
            this.f.other_reg_check.setValue(this.eventListing.event_bike_reg === 'N');
            this.regState = true;
            this.setRegLabelAndPlaceholder(this.eventListing.event_bike_reg);
            this.setRegValidationStatus();
        }
    };
    EventListingFormComponent.prototype.setEndDateLimits = function () {
        var start = this.f.event_start_date.value;
        var end = this.f.event_end_date.value;
        this.startDateTimestamp = new Date(start).getTime() / 1000;
        this.endDateTimestamp = new Date(end).getTime() / 1000;
        this.endDateMin = this.parser.parse(start);
        if (this.startDateTimestamp > this.endDateTimestamp) {
            this.f.event_end_date.setValue(start);
        }
        this.eventListing.diffDays = ((this.endDateTimestamp - this.startDateTimestamp) / (24 * 60 * 60)) + 1;
        var eventDateQuantity = this.eventListing.eventDates.length;
        if (eventDateQuantity && eventDateQuantity > this.eventListing.diffDays) {
            for (var i = eventDateQuantity - 1; i >= this.eventListing.diffDays; i--) {
                this.eventListing.removeEventDate(i);
            }
        }
        if (!this.eventListing.eventDates.length) {
            this.addEventListingDate();
        }
    };
    EventListingFormComponent.prototype.validateCalendarCode = function () {
        var _this = this;
        setTimeout(function () {
            var calendarCode = _this.f.calendarCode.value;
            // TODO: @Charlie, @Brennan - Should we use localStorage or add a column to the Event table?
            if (_this.globalService.discountCodes.includes(calendarCode)) {
                _this.displayDiscountMsg = true;
                _this.displayFailedDiscountMsg = false;
                localStorage.setItem('calendarCode', calendarCode);
            }
            else {
                _this.displayFailedDiscountMsg = true;
                _this.displayDiscountMsg = false;
                localStorage.removeItem('calendarCode');
            }
        }, 5);
    };
    EventListingFormComponent.prototype.debug = function (event) {
        console.log('debug form', this.f, event);
    };
    EventListingFormComponent.prototype.onSubmit = function () {
        this.postOrUpdateEvent();
    };
    EventListingFormComponent.prototype.onReset = function () {
        this.submitted = false;
        this.eventForm.reset();
    };
    EventListingFormComponent.prototype.addEventListingDate = function () {
        this.eventListing.createEventDate();
    };
    EventListingFormComponent.prototype.onChangeDiscipline = function () {
        var _this = this;
        // commented out for MBR-2992
        // this.subDisciplinesOptions = [];
        // this.disciplines.forEach( disc => {
        //   if (disc.cd_id === parseInt(this.f.event_discipline.value)) {
        //     disc.sub_disciplines.forEach( sub => {
        //       this.subDisciplinesOptions.push(new SelectOption(sub.subdiscipline_id.toString(), sub.subdiscipline_name));
        //     });
        //   }
        // });
        var selected = this.disciplineOptions.find(function (option) { return option.value === _this.f.event_discipline.value; });
        if (selected) {
            var competitive = this.eventListing.isEventCompetitive ? 'Y' : 'N';
            this.f.event_competitive.setValue(competitive);
        }
    };
    EventListingFormComponent.prototype.updateEventType = function ($event) {
        this.eventListing.event_types = $event;
    };
    EventListingFormComponent.prototype.updateCalendars = function ($event) {
        this.eventListing.event_special_calendars = $event;
    };
    EventListingFormComponent.prototype.clearMultiSelect = function () {
        this.f.event_sponsoring_clubs.reset([]);
    };
    EventListingFormComponent.prototype.setRegValidationStatus = function () {
        var event_bike_reg = this.eventForm.get('event_bike_reg');
        var event_reg_url = this.eventForm.get('event_reg_url');
        var calendarCode = this.eventForm.get('calendarCode');
        if (this.regState === true) {
            event_reg_url.setValidators([Validators.required, urlValidator()]);
        }
        else {
            event_reg_url.setValidators(null);
        }
        if (this.calendarCodeState === false) {
            calendarCode.setValidators(null);
        }
        else {
            calendarCode.setValidators([Validators.required]);
        }
        event_bike_reg.updateValueAndValidity();
        event_reg_url.updateValueAndValidity();
        calendarCode.updateValueAndValidity();
    };
    EventListingFormComponent.prototype.triggerUpdateListing = function () {
        if (this.isPermit
            && this.previousFeeDays > 0
            && this.eventListing.eventDates.length !== this.previousFeeDays
            && this.permitStatuses.includes(this.eventListing.event_status)) {
            this.openFeeConfirmationModal();
        }
        else if (this.inPostEvent && (this.startOrEndDateChanged || this.eventDatesChanged)) {
            this.openDateConfirmationModal();
        }
        else {
            this.postOrUpdateEvent();
        }
    };
    EventListingFormComponent.prototype.assignFormData = function () {
        var event_id = this.eventListing.event_id;
        var event_contacts = this.eventListing.contacts.filter(function (contact) { return contact.isValid; });
        var calendars = this.eventListing.event_special_calendars;
        var event_special_calendars = this.eventId ? calendars.map(function (cal) { return cal.calendar_id; }) : calendars;
        this.formData = Object.assign({}, this.eventForm.value, { event_id: event_id, event_contacts: event_contacts, event_special_calendars: event_special_calendars });
    };
    EventListingFormComponent.prototype.postOrUpdateEvent = function () {
        var _this = this;
        this.closeDateConfirmationModal();
        this.submitted = true;
        this.setRegValidationStatus();
        var eventCardsState = true;
        this.eventListing.eventDates.forEach(function (eventDate) {
            if (!eventDate.isValid) {
                eventCardsState = false;
            }
        });
        // stop here if form is invalid
        if (this.eventForm.invalid || !eventCardsState) {
            this.handleValidationFeedback(eventCardsState);
            return;
        }
        this.submitting = true;
        this.skipNavCatch = true;
        this.assignFormData();
        // Update if event exists
        if (this.eventId) {
            this.event.updateEvent(this.formData, this.eventId).subscribe(function (event) {
                if (event) {
                    if (_this.isPermit) {
                        _this.checkDatesChanged();
                    }
                    else {
                        _this.postOrUpdateEventDates(_this.eventId);
                    }
                }
            });
        }
        else {
            // Post new event
            this.event.postEvent(this.formData).subscribe(function (event) {
                if (event) {
                    _this.event.updateEventStatus(event.event_id, 'listing-in-progress').subscribe(function (resp) {
                        if (resp.message) {
                            _this.postOrUpdateEventDates(event.event_id);
                        }
                        else {
                            _this.submitting = false;
                        }
                    });
                }
                else {
                    _this.submitting = false;
                }
            });
        }
    };
    EventListingFormComponent.prototype.handleValidationFeedback = function (eventCardsState) {
        var fragment;
        if (this.eventForm.invalid) {
            var formControlSections = this.eventListing.formControlSections;
            var invalidControls = Object.entries(this.f).filter(function (_a) {
                var key = _a[0], control = _a[1];
                return control.invalid;
            });
            for (var i = 0; i < formControlSections.length; i++) {
                if (i === 2) {
                    fragment = !eventCardsState ? 'event-dates' : '';
                }
                else {
                    fragment = this.searchInvalidControls(i, formControlSections[i], invalidControls);
                }
                if (fragment) {
                    break;
                }
            }
        }
        else if (!eventCardsState) {
            fragment = 'event-dates';
        }
        if (fragment) {
            document.getElementById(fragment).scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        }
    };
    EventListingFormComponent.prototype.searchInvalidControls = function (sectionIndex, formControlSection, invalidControls) {
        var sections = ['general-information', 'event-details', 'event-dates', 'marketing'];
        var _loop_1 = function (controlName) {
            if (invalidControls.some(function (control) { return control[0] === controlName; })) {
                return { value: sections[sectionIndex] };
            }
        };
        for (var _i = 0, formControlSection_1 = formControlSection; _i < formControlSection_1.length; _i++) {
            var controlName = formControlSection_1[_i];
            var state_1 = _loop_1(controlName);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        return '';
    };
    EventListingFormComponent.prototype.postOrUpdateEventDates = function (eventId) {
        var _this = this;
        var count = 0;
        // Create new event dates & update existing ones
        this.eventListing.eventDates.forEach(function (date) {
            if (!date.event_date_id) {
                _this.event.postEventDate(date, eventId).subscribe(function (resp) {
                    if (resp) {
                        count += 1;
                        if (count === _this.eventListing.eventDates.length) {
                            _this.deleteRemovedEventDates(eventId);
                            _this.toReview(eventId);
                        }
                    }
                    else {
                        _this.submitting = false;
                    }
                });
            }
            else {
                var eventDate = Object.assign({}, date, { event_date_chief_ref: null });
                _this.event.editEventDate(eventDate, date.event_date_id).subscribe(function (resp) {
                    if (resp) {
                        count += 1;
                        if (count === _this.eventListing.eventDates.length) {
                            _this.deleteRemovedEventDates(eventId);
                        }
                    }
                    else {
                        _this.submitting = false;
                    }
                });
            }
        });
    };
    EventListingFormComponent.prototype.toReview = function (eventId) {
        this.router.navigate(["/event/" + eventId + "/review"]);
    };
    EventListingFormComponent.prototype.updateEventStatusAndDates = function (eventId) {
        var _this = this;
        var statuses = ['permit-approved', 'in-post-event'];
        if (statuses.includes(this.eventListing.event_status)) {
            this.event.updateEventStatus(eventId, 'permit-in-review').subscribe(function (resp) {
                if (resp.message) {
                    _this.postOrUpdateEventDates(eventId);
                }
                else {
                    _this.submitting = false;
                }
            });
        }
        else {
            this.postOrUpdateEventDates(eventId);
        }
    };
    EventListingFormComponent.prototype.clearCourseMapAnswer = function () {
        // Clear course map upload permit answer, if applicable per discipline
        this.permitService.deleteCourseMaps(this.eventListing.event_permit_id).subscribe();
    };
    EventListingFormComponent.prototype.searchPermitFees = function (orders) {
        var _this = this;
        var feeDays = 0;
        orders.forEach(function (order) {
            order.line_items.forEach(function (item) {
                if (_this.permitSkus.includes(item.sku)) {
                    feeDays += item.quantity;
                }
            });
        });
        this.previousFeeDays = feeDays;
    };
    EventListingFormComponent.prototype.deleteRemovedEventDates = function (eventId) {
        var _this = this;
        var currentEventDateIds = [];
        var eventDatesToRemove = [];
        this.eventListing.eventDates.forEach(function (date) {
            if (!!date.event_date_id) {
                currentEventDateIds.push(date.event_date_id);
            }
        });
        this.eventListing.initialEventDateIds.forEach(function (id) {
            if (!currentEventDateIds.includes(id)) {
                eventDatesToRemove.push(id);
            }
        });
        if (eventDatesToRemove.length) {
            var count_1 = 0;
            eventDatesToRemove.forEach(function (id) {
                _this.event.deleteEventDate(id).subscribe(function (resp) {
                    if (resp) {
                        count_1 += 1;
                        if (count_1 === eventDatesToRemove.length) {
                            _this.updateFeesOrNavigate(eventId);
                        }
                    }
                    else {
                        _this.submitting = false;
                    }
                });
            });
        }
        else {
            this.updateFeesOrNavigate(eventId);
        }
    };
    EventListingFormComponent.prototype.updateFeesOrNavigate = function (eventId) {
        if (this.eventListing.event_permit_id) {
            this.updatePermitFees();
        }
        else {
            if (this.createMode || this.eventListing.event_status === 'listing-in-progress') {
                this.toReview(eventId);
            }
            else {
                this.continueNavigation(this.overviewRoute);
            }
        }
    };
    EventListingFormComponent.prototype.updatePermitFees = function () {
        var _this = this;
        var previous = this.previousFeeDays;
        var current = this.eventListing.eventDates.length;
        if (current > previous) {
            var addedDays_1 = current - previous;
            var skus = [this.permitFeeSku, this.permitFeeSeriesSku, this.permitFeeSeries10PlusSku];
            this.shopifyService.getShopifyProductVariants({ skus: skus }).subscribe(function (variants) {
                _this.permitFee = _this.findVariant(variants, _this.permitFeeSku);
                _this.permitFeeSeries = _this.findVariant(variants, _this.permitFeeSeriesSku);
                _this.permitFeeSeries10Plus = _this.findVariant(variants, _this.permitFeeSeries10PlusSku);
                if (_this.isSeries) {
                    if (!!_this.eventListing.event_start_date && !!_this.eventListing.event_end_date) {
                        if (current <= 10) {
                            var seriesItem = { variant_id: _this.permitFeeSeries.id, quantity: addedDays_1 };
                            _this.shopifyService.addToDraftOrder(seriesItem, _this.draftOrder);
                        }
                        else if (current > 10 && previous <= 10) {
                            var seriesItem = { variant_id: _this.permitFeeSeries.id, quantity: addedDays_1 };
                            var seriesPlusItem = { variant_id: _this.permitFeeSeries10Plus.id, quantity: 1 };
                            _this.shopifyService.addToDraftOrder(seriesItem, _this.draftOrder);
                            _this.shopifyService.addToDraftOrder(seriesPlusItem, _this.draftOrder);
                        }
                    }
                }
                else {
                    var feeItem = { variant_id: _this.permitFee.id, quantity: addedDays_1 };
                    _this.shopifyService.addToDraftOrder(feeItem, _this.draftOrder);
                }
                _this.shopifyService.updateDraftOrderItems(_this.draftOrder, "/event/" + _this.eventId + "/add-ons");
            });
        }
        else {
            this.continueNavigation(this.overviewRoute);
        }
    };
    EventListingFormComponent.prototype.findVariant = function (variants, sku) {
        return variants.find(function (variant) { return sku === variant.sku; });
    };
    EventListingFormComponent.prototype.openSaveExitModal = function (url) {
        if (url) {
            this.navUrl = url;
        }
        this.showSaveExit = true;
    };
    EventListingFormComponent.prototype.closeSaveExitModal = function (resetNavUrl) {
        var _this = this;
        if (resetNavUrl === void 0) { resetNavUrl = false; }
        this.showSaveExit = false;
        setTimeout(function () {
            if (!!resetNavUrl) {
                _this.navUrl = '';
            }
        }, 1000);
    };
    EventListingFormComponent.prototype.toggleModal1 = function (event) {
        this.submitModalOpen = !this.submitModalOpen;
    };
    EventListingFormComponent.prototype.closeSubmitModal = function (event) {
        this.submitModalOpen = false;
    };
    EventListingFormComponent.prototype.openFeeConfirmationModal = function () {
        this.dateConfirmationMessage = "You have updated the number of event dates for your listing.\n      If additional permit fees apply, they will be added to your cart, and you will be redirected\n      to the final checkout page.";
        this.showDateConfirmation = true;
    };
    EventListingFormComponent.prototype.openDateConfirmationModal = function () {
        this.dateConfirmationMessage = "You have updated the dates for this permit. Because the\n      post event period has started, please be aware that date changes may affect or even remove\n      existing post event forms if they have not yet been completed and paid.";
        this.showDateConfirmation = true;
    };
    EventListingFormComponent.prototype.closeDateConfirmationModal = function () {
        this.showDateConfirmation = false;
    };
    EventListingFormComponent.prototype.continueNavigation = function (url) {
        var _this = this;
        if (url === void 0) { url = this.queueRoute; }
        this.closeSaveExitModal();
        setTimeout(function () {
            _this.navUrl = _this.navUrl === '' ? url : _this.navUrl;
            _this.router.navigate([_this.navUrl]);
        }, 50);
    };
    EventListingFormComponent.prototype.returnNavigation = function () {
        var url;
        url = this.createMode ? this.queueRoute : this.overviewRoute;
        this.router.navigate([url]);
    };
    EventListingFormComponent.prototype.postChanges = function () {
        this.submitModalOpen = false;
    };
    EventListingFormComponent.prototype.selectAnnouncementFile = function (event) {
        var _this = this;
        this.announcementAlert = [];
        if (event.target.files.length > 0) {
            var image_1 = event.target.files[0];
            var fileExtn = image_1.name.split('.').pop();
            var formData = new FormData();
            formData.append('image', image_1);
            if (this.upload.serviceType.EVENT_ANNONCEMENT.extensions.indexOf(fileExtn.toLowerCase()) > -1) {
                this.upload.fileUpload(formData, this.eventId).subscribe(function (data) {
                    _this.announcementAlert = [
                        new Alert('success', image_1.name + ' was successfully uploaded.')
                    ];
                    _this.announcementLabel = image_1.name;
                    _this.f.event_announcement.setValue(data['url']); /* tslint:disable-line:no-string-literal */
                }, function (err) {
                    _this.announcementAlert = [
                        new Alert('warning', 'There was an error uploading your file: ' + err.message)
                    ];
                });
            }
            else {
                this.announcementAlert = [
                    new Alert('warning', 'Invalid file type. Supported file extns are PDF, JPG or PNG ')
                ];
            }
        }
    };
    EventListingFormComponent.prototype.selectLogoFile = function (event) {
        var _this = this;
        this.logoAlert = [];
        if (event.target.files.length > 0) {
            var image_2 = event.target.files[0];
            var fileExtn = image_2.name.split('.').pop();
            if (this.upload.serviceType.EVENT_LOGO.extensions.indexOf(fileExtn.toLowerCase()) > -1) {
                var formData = new FormData();
                formData.append('image', image_2);
                this.upload.fileUpload(formData, this.eventId).subscribe(function (data) {
                    _this.logoAlert = [
                        new Alert('success', image_2.name + ' was successfully uploaded.')
                    ];
                    _this.logoLabel = image_2.name;
                    _this.f.event_logo.setValue(data['url']); /* tslint:disable-line:no-string-literal */
                    // tslint:disable-next-line: align
                }, function (err) {
                    _this.logoAlert = [
                        new Alert('warning', 'There was an error uploading your file: ' + err.message)
                    ];
                });
            }
            else {
                this.logoAlert = [
                    new Alert('warning', 'Invalid file type. Supported file extns are SVG, JPG or PNG ')
                ];
            }
        }
    };
    EventListingFormComponent.prototype.toggleBikeRegState = function (event) {
        if (event && event.target) {
            if (event.target.checked) {
                var idKey = 'id';
                if (event.target[idKey] === 'event_bike_reg-form-control') {
                    this.bikeRegCheckboxState = true;
                    this.otherRegCheckboxState = false;
                    this.regState = true;
                    this.f.event_bike_reg.setValue('Y');
                }
                else if (event.target[idKey] === 'otherRegCheckbox-form-control') {
                    this.bikeRegCheckboxState = false;
                    this.otherRegCheckboxState = true;
                    this.regState = true;
                    this.f.event_bike_reg.setValue('N');
                }
                this.setRegLabelAndPlaceholder(this.f.event_bike_reg.value);
            }
            else {
                this.bikeRegCheckboxState = false;
                this.otherRegCheckboxState = false;
                this.regState = false;
                this.f.event_bike_reg.setValue(null);
                this.f.event_reg_url.setValue(null);
            }
            this.setRegValidationStatus();
        }
    };
    EventListingFormComponent.prototype.setRegLabelAndPlaceholder = function (bikeReg) {
        if (bikeReg === 'Y') {
            this.regUrlLabel = 'BikeReg URL';
            this.regUrlPlaceholder = 'bikereg.com/yourpage';
        }
        else {
            this.regUrlLabel = 'Registration URL';
            this.regUrlPlaceholder = '';
        }
    };
    EventListingFormComponent.prototype.minRequiredFields = function () {
        var form = this.eventForm.value;
        return !!(form.event_name && form.event_discipline && form.event_start_date && form.event_end_date && form.event_website);
    };
    EventListingFormComponent.prototype.displayReviewBtn = function () {
        return this.createMode || this.eventListing.event_status === 'listing-in-progress';
    };
    EventListingFormComponent.prototype.saveAndExit = function () {
        var _this = this;
        if (this.updateMode) {
            this.postOrUpdateEvent();
        }
        else {
            if (this.minRequiredFields()) {
                var event_contacts = this.eventListing.contacts.filter(function (contact) { return contact.isValid; });
                var data = Object.assign({}, this.eventForm.value, { event_contacts: event_contacts });
                this.event.postEvent(data).subscribe(function (event) {
                    var dates = 0;
                    var validDates = _this.eventListing.eventDates.filter(function (date) { return date.isValid; });
                    var totalDates = validDates.length;
                    if (totalDates > 0) {
                        validDates.forEach(function (date) {
                            _this.event.postEventDate(date, event.event_id).subscribe(function (ed) {
                                if (Object.keys(ed).includes('event_date_id')) {
                                    dates += 1;
                                }
                                if (dates === totalDates) {
                                    // Set event status to 'Listing in Progress'
                                    _this.event.updateEventStatus(event.event_id, 'listing-in-progress').subscribe(function (resp) {
                                        if (resp.message) {
                                            _this.continueNavigation();
                                        }
                                        else {
                                            // TODO: Consider more explicit error handling
                                        }
                                    });
                                }
                            });
                        });
                    }
                    else {
                        // Set event status to 'Listing in Progress'
                        _this.event.updateEventStatus(event.event_id, 'listing-in-progress').subscribe(function (resp) {
                            if (resp.message) {
                                _this.continueNavigation();
                            }
                            else {
                                // TODO: Consider more explicit error handling
                            }
                        });
                    }
                });
            }
        }
    };
    EventListingFormComponent.prototype.setDateCards = function () {
        if (this.createMode && !this.eventListing.eventDates.length) {
            this.eventListing.eventDates.push(new EventDate());
        }
    };
    Object.defineProperty(EventListingFormComponent.prototype, "existingEvent", {
        // TODO: Update logic in Permitting II (will know if editing by event_id in the listing form route)
        get: function () {
            return !!this.eventListing.event_id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListingFormComponent.prototype, "overviewRoute", {
        get: function () {
            var eventId = this.eventListing.event_id;
            return eventId ? "/event/" + eventId + "/overview" : this.queueRoute;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListingFormComponent.prototype, "isPermit", {
        get: function () {
            return !!this.eventListing.event_permit_id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListingFormComponent.prototype, "isSeries", {
        get: function () {
            return this.eventListing.event_types.includes('4');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListingFormComponent.prototype, "isVirtual", {
        get: function () {
            return this.eventListing.event_discipline === '38';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListingFormComponent.prototype, "createMode", {
        get: function () {
            return this.eventListing.mode === 'create';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListingFormComponent.prototype, "updateMode", {
        get: function () {
            return this.eventListing.mode === 'update';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListingFormComponent.prototype, "isUsacAdmin", {
        get: function () {
            return this.userRole === 'usac_admin';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListingFormComponent.prototype, "disableDateEdit", {
        get: function () {
            return this.existingEvent && !this.isUsacAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListingFormComponent.prototype, "showEventDateAddBtn", {
        get: function () {
            return (this.eventListing.eventDates.length < 30 &&
                (this.eventListing.event_start_date !== this.eventListing.event_end_date) &&
                (this.eventListing.eventDates.length < this.eventListing.diffDays) &&
                !this.disableDateEdit);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListingFormComponent.prototype, "eventDates", {
        get: function () {
            return this.eventListing.eventDates.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListingFormComponent.prototype, "eventStarted", {
        get: function () {
            if (this.initialEventDates.length) {
                var startDate = moment(this.initialEventDates[0].event_date_start).startOf('day');
                var today = moment().startOf('day');
                return startDate <= today;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListingFormComponent.prototype, "inPostEvent", {
        get: function () {
            return this.eventListing.event_status === 'in-post-event';
        },
        enumerable: true,
        configurable: true
    });
    EventListingFormComponent.prototype.eventDateStarted = function (eventDate) {
        if (this.isPermit) {
            var startDate = moment(eventDate.event_date_start).startOf('day');
            var today = moment().startOf('day');
            return startDate <= today;
        }
        else {
            return false;
        }
    };
    Object.defineProperty(EventListingFormComponent.prototype, "startOrEndDateChanged", {
        get: function () {
            return (!this.datesMatch(this.initialStartDate, this.f.event_start_date.value)
                || !this.datesMatch(this.initialEndDate, this.f.event_end_date.value));
        },
        enumerable: true,
        configurable: true
    });
    // Triggers available dates calculation inside event date cards
    EventListingFormComponent.prototype.handleEventDateChange = function () {
        var _this = this;
        this.eventDatesChanged = true;
        setTimeout(function () { return _this.eventDatesChanged = false; }, 500);
    };
    Object.defineProperty(EventListingFormComponent.prototype, "datesChanged", {
        get: function () {
            var _this = this;
            if (this.eventListing.eventDates.some(function (date) { return !date.event_date_id; }) ||
                this.eventListing.eventDates.length < this.initialEventDates.length) {
                return true;
            }
            var changedDates = 0;
            this.initialEventDates.forEach(function (initDate) {
                _this.eventListing.eventDates.forEach(function (date) {
                    if (initDate.event_date_id === date.event_date_id &&
                        (!_this.datesMatch(new Date(initDate.event_date_start), date.event_date_start) ||
                            !_this.subDisciplinesMatch(initDate, date))) {
                        changedDates += 1;
                    }
                });
            });
            return changedDates > 0;
        },
        enumerable: true,
        configurable: true
    });
    EventListingFormComponent.prototype.checkDatesChanged = function () {
        // Check for new or removed event dates
        if (this.eventDatesChanged) {
            this.clearCourseMapAnswer();
            this.updateEventStatusAndDates(this.eventId);
            // Check if start or end date changed
        }
        else if (this.startOrEndDateChanged) {
            this.updateEventStatusAndDates(this.eventId);
        }
        else {
            this.postOrUpdateEventDates(this.eventId);
        }
    };
    EventListingFormComponent.prototype.datesMatch = function (initialDate, currentDateString) {
        return moment(initialDate).format('M/D/Y') === currentDateString;
    };
    EventListingFormComponent.prototype.subDisciplinesMatch = function (initialEventDate, currentEventDate) {
        var initialSubdiscs = [];
        var currentSubdiscs = [];
        initialEventDate.event_date_sub_disciplines.forEach(function (subdisc) { return initialSubdiscs.push(subdisc.subdiscipline_id); });
        currentEventDate.event_date_sub_disciplines.forEach(function (subdisc) { return currentSubdiscs.push(parseInt(subdisc.value)); });
        return JSON.stringify(initialSubdiscs.sort()) === JSON.stringify(currentSubdiscs.sort());
    };
    return EventListingFormComponent;
}());
export { EventListingFormComponent };
