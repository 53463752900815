import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileUploadService } from 'src/app/shared';

import { PostEventService } from 'src/app/services';

import {
  RadioOption,
  Alert,
  Event,
  EventDate,
  PostEvent
} from '../../classes';

@Component({
  selector: 'app-post-event-1-form',
  templateUrl: './post-event-1-form.component.html',
  styles: []
})
export class PostEvent1FormComponent implements OnInit {

  @Input() event: Event;
  @Input() eventDate: EventDate;
  @Input() isSeries: boolean;
  @Input() urlSegments: string[];
  @Input() userRole: string;

  postEvent: PostEvent;
  postEventForm: FormGroup;

  get eventId() { return (this.event.event_id); }
  get eventDateId() { return (this.eventDate && this.eventDate.event_date_id) || null; }
  get postEventId() { return (this.postEvent && this.postEvent.post_event_id); }
  get formMode() { return this.postEvent ? 'update' : 'create'; }
  get isUsacAdmin() { return this.userRole === 'usac_admin'; }
  submitted = false;

  sufficientStaffOptions: RadioOption[] = [];
  sufficientFacilitiesOptions: RadioOption[] = [];
  sufficientTimeOptions: RadioOption[] = [];
  licenseCheckOptions: RadioOption[] = [];
  startListOptions: RadioOption[] = [];
  eventFlyerOptions: RadioOption[] = [];
  courseChangesOptions: RadioOption[] = [];
  podiumOptions: RadioOption[] = [];
  brpOptions: RadioOption[] = [];
  neutralSupportOptions: RadioOption[] = [];
  licensedMechanicsOptions: RadioOption[] = [];
  timingCompanyOptions: RadioOption[] = [];
  medicalSupportOptions: RadioOption[] = [];
  equalPayOptions: RadioOption[] = [];
  prizeDistributionOptions: RadioOption[] = [];
  postingAreaOptions: RadioOption[] = [];
  protestPeriodOptions: RadioOption[] = [];
  disciplineRiderOptions: RadioOption[] = [];

  occurrenceReports: any[] = [];
  occurrenceReportAlert: Alert[] = [];
  displayOccurrenceReportUpload = false;
  disableOccurrencePaperwork = false;

  disciplineReports: any[] = [];
  disciplineReportAlert: Alert[] = [];
  displayDisciplineReportUpload = false;
  disableDisciplinePaperwork = false;

  loading = true;

  get f() { return this.postEventForm.controls; }

  constructor(
    private formBuilder: FormBuilder,
    private upload: FileUploadService,
    private postEventService: PostEventService,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildForm();
    this.subscribeToForm();
    this.fetchPostEvent();
  }

  buildForm() {
    this.postEventForm = this.formBuilder.group({

      post_event_event_id: [this.event.event_id],
      post_event_permit_id: [this.event.event_permit_id],
      post_event_event_date_id: [this.eventDateId],

      post_event_sufficient_staff: ['', Validators.required],
      post_event_sufficient_facilities: ['', Validators.required],
      post_event_sufficient_time: ['', Validators.required],
      post_event_start_lists_adequate: ['', Validators.required],
      post_event_check_licenses: ['', Validators.required],
      post_event_registration_comments: ['', Validators.maxLength(500)],

      post_event_flyer_accurate: ['', Validators.required],
      post_event_course_changes_required: ['', Validators.required],
      post_event_podium: ['', Validators.required],
      // post_event_beginner_racer_program_event: ['', Validators.required],
      post_event_conduct_comments: ['', Validators.maxLength(500)],

      post_event_neutral_support_services: ['', Validators.required],
      post_event_licensed_mechanics: ['', Validators.required],
      post_event_timing_company: ['', Validators.required],
      post_event_technical_support_comments: ['', Validators.maxLength(500)],

      post_event_medical_support_verified: ['', Validators.required],
      post_event_medical_support_description: ['', [Validators.required, Validators.maxLength(500)]],

      post_event_number_of_occurrences: [0, Validators.required],
      post_event_accident_occurrence_reports: [this.occurrenceReports],
      post_event_paperwork_occurrence_reports: [false, Validators.required],

      post_event_rider_discipline: ['', Validators.required],
      post_event_rider_discipline_reports: [this.disciplineReports],
      post_event_paperwork_rider_discipline_reports: [false, Validators.required],

      post_event_largest_individual_prize: ['', Validators.required],
      post_event_prize_equal_pay: ['', Validators.required],
      post_event_prize_distributed_15_min: ['', Validators.required],
      post_event_posting_area_adequate: ['', Validators.required],
      post_event_protest_period_observed: ['', Validators.required],
      post_event_prize_comments: ['', Validators.maxLength(500)]
    });

    this.sufficientStaffOptions = [
      new RadioOption('staff-yes', 'Y', 'Yes'),
      new RadioOption('staff-no', 'N', 'No')
    ];
    this.sufficientTimeOptions = [
      new RadioOption('time-yes', 'Y', 'Yes'),
      new RadioOption('time-no', 'N', 'No')
    ];
    this.sufficientFacilitiesOptions = [
      new RadioOption('facilities-yes', 'Y', 'Yes'),
      new RadioOption('facilities-no', 'N', 'No')
    ];
    this.startListOptions = [
      new RadioOption('start-list-yes', 'Y', 'Yes'),
      new RadioOption('start-list-no', 'N', 'No')
    ];
    this.licenseCheckOptions = [
      new RadioOption('license-yes', 'Y', 'Yes'),
      new RadioOption('license-no', 'N', 'No')
    ];
    this.eventFlyerOptions = [
      new RadioOption('flyer-yes', 'Y', 'Yes'),
      new RadioOption('flyer-no', 'N', 'No')
    ];
    this.courseChangesOptions = [
      new RadioOption('course-changes-yes', 'Y', 'Yes'),
      new RadioOption('course-changes-no', 'N', 'No')
    ];
    this.podiumOptions = [
      new RadioOption('podium-yes', 'Y', 'Yes'),
      new RadioOption('podium-no', 'N', 'No')
    ];
    this.brpOptions = [
      new RadioOption('brp-yes', 'Y', 'Yes'),
      new RadioOption('brp-no', 'N', 'No')
    ];
    this.licensedMechanicsOptions = [
      new RadioOption('mechanics-yes', 'Y', 'Yes'),
      new RadioOption('mechanics-no', 'N', 'No')
    ];
    this.timingCompanyOptions = [
      new RadioOption('timing-yes', 'Y', 'Yes'),
      new RadioOption('timing-no', 'N', 'No')
    ];
    this.medicalSupportOptions = [
      new RadioOption('medical-support-yes', 'Y', 'Yes'),
      new RadioOption('medical-support-no', 'N', 'No')
    ];
    this.neutralSupportOptions = [
      new RadioOption('neutral-support-yes', 'Y', 'Yes'),
      new RadioOption('neutral-support-no', 'N', 'No')
    ];
    this.equalPayOptions = [
      new RadioOption('equal-pay-yes', 'Y', 'Yes'),
      new RadioOption('equal-pay-no', 'N', 'No')
    ];
    this.prizeDistributionOptions = [
      new RadioOption('prize-distributed-yes', 'Y', 'Yes'),
      new RadioOption('prize-distributed-no', 'N', 'No')
    ];
    this.postingAreaOptions = [
      new RadioOption('posting-area-yes', 'Y', 'Yes'),
      new RadioOption('posting-area-no', 'N', 'No')
    ];
    this.protestPeriodOptions = [
      new RadioOption('protest-period-yes', 'Y', 'Yes'),
      new RadioOption('protest-period-no', 'N', 'No')
    ];
    this.disciplineRiderOptions = [
      new RadioOption('discipline-rider-yes', 'Y', 'Yes'),
      new RadioOption('discipline-rider-no', 'N', 'No')
    ];
  }

  subscribeToForm() {
    this.postEventForm.get('post_event_number_of_occurrences').valueChanges.subscribe(value => {
      this.displayOccurrenceReportUpload = value > 0;
      this.disableOccurrencePaperwork = value === 0;
      if (value === 0) {
        this.f.post_event_paperwork_occurrence_reports.setValue(false);
      }
    });

    this.postEventForm.get('post_event_accident_occurrence_reports').valueChanges.subscribe(value => {
      const present = !!value && value.length > 0;
      this.disableOccurrencePaperwork = present;
      if (present) {
        this.f.post_event_paperwork_occurrence_reports.setValue(true);
      }
    });

    this.postEventForm.get('post_event_rider_discipline').valueChanges.subscribe(value => {
      this.displayDisciplineReportUpload = this.yesNoBoolean(value);
      if (!this.displayDisciplineReportUpload) {
        this.disciplineReports = [];
        this.setReportsValue('discipline', this.disciplineReports);
        this.f.post_event_paperwork_rider_discipline_reports.setValue(false);
      }
    });

    this.postEventForm.get('post_event_rider_discipline_reports').valueChanges.subscribe(value => {
      const present = !!value && value.length > 0;
      this.disableDisciplinePaperwork = present;
      if (present) {
        this.f.post_event_paperwork_rider_discipline_reports.setValue(true);
      }
    });
  }

  fetchPostEvent() {
    if (this.isSeries) {
      this.postEventService.getPostEventByEventDate(this.eventId, this.eventDateId).subscribe(postEvent => {
        this.postEvent = postEvent;
        this.assignPostEventToForm();
        this.loading = false;
      });
    } else {
      this.postEventService.getPostEventsByEvent(this.eventId).subscribe(postEvents => {
        this.postEvent = postEvents && postEvents.length && postEvents[0];
        this.assignPostEventToForm();
        this.loading = false;
      });
    }
  }

  assignPostEventToForm() {
    if (this.postEvent) {
      Object.keys(this.postEvent).forEach(key => {
        if (Object.keys(this.f).includes(key)) {
          this.f[key].setValue(this.booleanToYesNo(this.postEvent[key]));
        }
      });
      this.f.post_event_number_of_occurrences.setValue(this.postEvent.post_event_number_of_occurrences || 0);
      this.occurrenceReports = this.postEvent.post_event_accident_occurrence_reports || [];
      this.disciplineReports = this.postEvent.post_event_rider_discipline_reports || [];
      this.setPaperworkDisableStates();
      this.f.post_event_paperwork_occurrence_reports.setValue(this.postEvent.post_event_paperwork_occurrence_reports || false);
      this.f.post_event_paperwork_rider_discipline_reports.setValue(this.postEvent.post_event_paperwork_rider_discipline_reports || false);
      // console.log(this.f);
    }
  }

  setPaperworkDisableStates() {
    this.disableOccurrencePaperwork = this.postEvent.post_event_number_of_occurrences === 0 || this.occurrenceReports.length > 0;
    this.disableDisciplinePaperwork = this.disciplineReports.length > 0;
  }

  onSubmit(event: string) {
    // console.log(`Submitted (${this.postEventForm.status}): `, this.f);
    this.submitted = true;

    // Stop here if form is invalid
    console.log(this.postEventForm.invalid)
    if (this.postEventForm.invalid) {
      this.handleValidationFeedback();
      return;
    }

    const pef = this.postEventForm.value;
    const data = new PostEvent(pef);
    // Parse form data to convert 'Y/N' values to booleans
    Object.keys(pef).forEach(key => {
      const value = pef[key];
      data[key] = this.yesOrNo(value) ? this.yesNoBoolean(value) : value;
    });

    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
    if (this.formMode === 'create') {
      this.postEventService.createPostEvent(data).subscribe(resp => {
        if (resp) { this.redirect(event); }
      });
    } else if (this.formMode === 'update') {
      this.postEventService.updatePostEvent(this.postEventId, data).subscribe(resp => {
        if (resp) { this.redirect(event); }
      });
    }
  }

  private handleValidationFeedback(): void {
    let fragment = '';
    const formControlSections = this.postEventService.part1FormControlSections;
    const invalidControls = Object.entries(this.f).filter(([key, control]: [string, AbstractControl]) => control.invalid);
    for (let i = 0; i < formControlSections.length; i++) {
      fragment = this.searchInvalidControls(i, formControlSections[i], invalidControls);
      if (fragment) {
        break;
      }
    }

    if (fragment) {
      document.getElementById(fragment).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

  private searchInvalidControls(sectionIndex: number, formControlSection: string[], invalidControls: [string, AbstractControl][]): string {
    const sections = [ 'registration', 'conduct', 'technical', 'medical', 'disciplinary', 'prizes' ];
    for (const controlName of formControlSection) {
      if (invalidControls.some(control => control[0] === controlName)) {
        return sections[sectionIndex];
      }
    }
    return '';
  }

  yesOrNo(value) {
    return value === 'Y' || value === 'N';
  }

  yesNoBoolean(value) {
    return value === 'Y';
  }

  booleanToYesNo(value) {
    if (typeof(value) === 'boolean') {
      return value ? 'Y' : 'N';
    } else {
      return value;
    }
  }

  selectOccurrenceFile(event) {
    this.occurrenceReportAlert = [];
    if (event.target.files.length > 0) {
      const report = event.target.files[0];
      const fileExtn: string = report.name.split('.').pop();
      const formData: FormData = new FormData();
      formData.append('image', report);
      if (this.upload.serviceType.OCCURRENCE_REPORT.extensions.indexOf(fileExtn.toLowerCase()) > -1) {
        this.upload.fileUpload(formData, this.eventId.toString()).subscribe(data => {
          this.occurrenceReportAlert =  [
            new Alert('success', report.name + ' was successfully uploaded.', null, null, null, null, 4000)
          ];
          this.occurrenceReports.push({ filename: report.name, url: data.url });
          this.setReportsValue('occurrence', this.occurrenceReports);
        }, err => {
          this.occurrenceReportAlert =  [
            new Alert('warning', 'There was an error uploading your file: ' + err.message)
          ];
        });
      } else {
        this.occurrenceReportAlert =  [
          new Alert('warning', 'Invalid file type. Supported file extns are PDF, DOCX, or DOC.')
        ];
      }
    }
  }

  occurrenceReportMin() {
    return this.occurrenceReports.length ? 1 : 0;
  }

  selectDisciplineFile(event) {
    if (event.target.files.length > 0) {
      const report = event.target.files[0];
      const fileExtn: string = report.name.split('.').pop();
      const formData: FormData = new FormData();
      formData.append('image', report);
      if (this.upload.serviceType.DISCIPLINE_REPORT.extensions.indexOf(fileExtn.toLowerCase()) > -1) {
        this.upload.fileUpload(formData, this.eventId.toString()).subscribe(data => {
          this.disciplineReportAlert =  [
            new Alert('success', report.name + ' was successfully uploaded.', null, null, null, null, 4000)
          ];
          this.f.post_event_rider_discipline_reports.setValue(data.url);
          this.disciplineReports.push({ filename: report.name, url: data.url });
          this.setReportsValue('discipline', this.disciplineReports);
        }, err => {
          this.disciplineReportAlert =  [
            new Alert('warning', 'There was an error uploading your file: ' + err.message)
          ];
        });
      } else {
        this.disciplineReportAlert =  [
          new Alert('warning', 'Invalid file type. Supported file extns are PDF, DOCX, or DOC.')
        ];
      }
    }
  }

  removeReport(type, reports, index) {
    reports.splice(index, 1);
    this.setReportsValue(type, reports);
  }

  setReportsValue(type, reports) {
    if (type === 'occurrence') {
      this.f.post_event_accident_occurrence_reports.setValue(reports);
    } else if (type === 'discipline') {
      this.f.post_event_rider_discipline_reports.setValue(reports);
    }
  }

  label(key: string) {
    return this.postEventService.part1Labels[key];
  }

  redirect(event: string) {
    if (event === 'save-exit') {
      this.toOverview();
    } else if (event === 'save-continue') {
      this.toPart3();
    } else if (event === 'save-continue-ss') {
      this.toPart2();
    }
  }

  get part2Url() {
    const base = this.urlSegments.slice(0, -1);
    base.push('part-2');
    return base.join('/');
  }

  get part3Url() {
    const base = this.urlSegments.slice(0, -1);
    base.push('part-3');
    return base.join('/');
  }

  toOverview() {
    this.router.navigate([`event/${this.event.event_id}/overview`], {fragment: 'post-event'});
  }

  toPart2() {
    this.router.navigate([this.part2Url]);
  }

  toPart3() {
    this.router.navigate([this.part3Url]);
  }

}
