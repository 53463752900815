<div *ngIf="displayFormEventDate() || displayFormEvent()" class="main-container container">
  <h2>Post Event Form</h2>
  <h4>This is a requirement for USAC to comply with Safe Sport for 2024</h4>
  <h3>{{event.event_name}}</h3>
  <h4 *ngIf="eventDate">
    <span *ngIf="eventDate.event_date_description">{{eventDate.event_date_description}} - </span>
    {{eventDate.event_date_start | date: 'fullDate':'UTC'}}
  </h4>
  <p class="space-below-lg">Part 2 of 3</p>
  <!-- Non-Series -->
  <app-post-event-3-form *ngIf="displayFormEvent()"
    [event]="event"
    [isSeries]="isSeries"
    [urlSegments]="urlSegments"
    [userRole]="userRole"
  ></app-post-event-3-form>
  <!-- Series (per event date) -->
  <app-post-event-3-form *ngIf="displayFormEventDate()"
    [event]="event"
    [isSeries]="isSeries"
    [eventDate]="eventDate"
    [urlSegments]="urlSegments"
    [userRole]="userRole"
  ></app-post-event-3-form>
</div>
