/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./footer-link-group.component";
var styles_FooterLinkGroupComponent = [];
var RenderType_FooterLinkGroupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FooterLinkGroupComponent, data: {} });
export { RenderType_FooterLinkGroupComponent as RenderType_FooterLinkGroupComponent };
function View_FooterLinkGroupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "nav-link small"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.path, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_1); }); }
export function View_FooterLinkGroupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", "nav justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FooterLinkGroupComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.links; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FooterLinkGroupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-footer-link-group", [], null, null, null, View_FooterLinkGroupComponent_0, RenderType_FooterLinkGroupComponent)), i0.ɵdid(1, 114688, null, 0, i2.FooterLinkGroupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterLinkGroupComponentNgFactory = i0.ɵccf("app-footer-link-group", i2.FooterLinkGroupComponent, View_FooterLinkGroupComponent_Host_0, { links: "links" }, {}, []);
export { FooterLinkGroupComponentNgFactory as FooterLinkGroupComponentNgFactory };
