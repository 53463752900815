import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: []
})
export class TableComponent implements OnInit, OnChanges {

  sortedData: any[];
  asc = true;
  selectOptionValues: string[] = [];

  @Input() id: string;
  @Input() columns: any[] = [];
  @Input() data: any[] = [];
  @Input() split: boolean;
  @Input() splitFrom: number;
  @Input() splitUntil: number;
  @Input() autoSort: boolean;
  @Input() sortBy: string;
  @Input() sortOrder = 'asc';
  @Input() actions: any[];
  @Input() selectActions: any[];
  @Input() selectOptions: object;
  @Input() disabledActions: object = {};
  @Input() disableTextWrap: any[] = [];
  @Input() linkEach: string;
  @Input() linkEachKey = 'id';
  @Input() linkEachSuffix = '';
  @Input() linkProp: string = null;
  @Input() actionMessages: any = {};
  @Input() utcDisplayDates: string[] = [];
  @Output() action = new EventEmitter<any>();
  @Output() sort = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.asc = this.sortOrder === 'asc';
    this.setSort(this.sortBy, this.asc);
    for (const key of Object.keys(this.selectOptions)) {
      if (this.selectOptions[key] !== null) {
        this.selectOptions[key].forEach(option => {
          this.selectOptionValues.push(option.value);
        });
      }
    }
  }

  toggleSplit(event: any) {
    this.split = !this.split;
  }

  isSplit() {
    return this.split && this.splitFrom && this.splitUntil;
  }

  splitDiff() {
    if (this.isSplit()) {
      return (this.splitUntil - this.splitFrom);
    } else {
      return 0;
    }
  }

  splitFirst() {
    if (this.isSplit()) {
      return this.data.slice(0, this.splitFrom);
    } else {
      return this.data;
    }
  }

  splitLast() {
    if (this.isSplit()) {
      return this.data.slice(this.splitUntil);
    } else {
      return [];
    }
  }

  isAlertField(field_name: string): boolean {
    return [
      'event_unread_notifications'
    ].includes(field_name);
  }

  isDateField(field_name: string): boolean {
    return /_(date|at)$/ig.test(field_name);
  }

  utcDisplay(field_name: string): boolean {
    return this.utcDisplayDates.includes(field_name);
  }

  checkDisabledActions(action, row) {
    return !(Object.keys(this.disabledActions).length > 0 && Object.keys(this.disabledActions).includes(action.name) && this.disabledActions[action.name].includes(row.event_status)); /* tslint:disable-line */
  }

  handleAction(action: any, row: any, event: any): void {
    event.stopPropagation();

    this.action.emit({
      action,
      row,
      event
    });
  }

  includesSelectOption(row: string, field: string) {
    return this.selectOptionValues.includes(this.actionValue(row, field));
  }

  actionValue(row: string, field: string) {
    return row[field].toLocaleLowerCase().split(' ').join('-');
  }

  displayActionMessage(row: any, field: string) {
    if (this.actionMessages.event_status.length > 0) {
      return this.actionMessages.event_status.some(r => r.event_id === row.event_id);
    }
    return false;
  }

  actionMessage(row: any) {
    const obj = this.actionMessages.event_status.find(r => r.event_id === row.event_id);
    return obj && obj.message;
  }

  actionMessageClass(row: any) {
    const obj = this.actionMessages.event_status.find(r => r.event_id === row.event_id);
    return obj && obj.textClass || '';
  }

  stopRedirect(event: any): void {
    event.stopPropagation();
  }

  handleValueChange(row: any, field: any, event: any, index?: number): void {
    row[field] = event;
  }

  handleSort(sortBy: string, sortOrder: string, event: any): void {
    if (event) {
      event.stopPropagation();
    }

    this.sort.emit({
      sortBy,
      sortOrder,
      event
    });
  }

  setSort(key: string, asc: boolean, event?: any) {
    this.asc = asc;
    this.sortBy = key || (this.columns.length > 0 ? this.columns[0].field : undefined);
    this.sortOrder = this.asc ? 'asc' : 'desc';

    this.handleSort(this.sortBy, this.sortOrder, event);

    if (this.autoSort) {
      // logic for autoSort data
      this.sortedData = this.data.sort(this.sortData(this.sortBy, asc));
    }
  }

  sortData(key, asc) {
    return (a, b) => {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = this.comparableFields(a, key);
      const varB = this.comparableFields(b, key);

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (asc) ? comparison : (comparison * -1)
      );
    };
  }

  comparableFields(field, key) {
    if (typeof field[key] === 'boolean') {
      return field[key] === true ? 0 : 1;
    } else if (typeof field[key] === 'string') {
      return field[key].toUpperCase();
    } else {
      return field[key];
    }
  }

  getRouterLinkForRow(row){
    if(this.linkProp && row[this.linkProp]){
      return row[this.linkProp];
    }
    return this.linkEach && row[this.linkEachKey] ? [this.linkEach + row[this.linkEachKey] + this.linkEachSuffix] : [];
  }
}
