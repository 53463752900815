var Driver = /** @class */ (function () {
    function Driver(first_name, last_name, license_number, 
    // TODO: Uncomment once licenses are validated
    // valid_license: boolean,
    mvr_status, mvr_expire, usac_id, age, city, state, profile_id) {
        this.usac_id = usac_id;
        this.first_name = first_name;
        this.last_name = last_name;
        this.license_number = license_number;
        // TODO: Uncomment once licenses are validated
        // this.valid_license = valid_license;
        this.mvr_status = mvr_status;
        this.mvr_expire = mvr_expire;
        this.age = age;
        this.city = city;
        this.state = state;
        this.profile_id = profile_id;
    }
    Object.defineProperty(Driver.prototype, "expired", {
        get: function () {
            var expiration = this.mvr_expire ? Date.parse(this.mvr_expire) : null;
            return expiration ? Date.now() > expiration : true;
        },
        enumerable: true,
        configurable: true
    });
    return Driver;
}());
export { Driver };
