import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormFieldErrors } from 'src/app/classes';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
var noop = function () { };
var ɵ0 = noop;
var DatepickerComponent = /** @class */ (function () {
    function DatepickerComponent(calendar) {
        this.calendar = calendar;
        this.currentValue = {};
        this.select = new EventEmitter();
        this.onTouchedCallback = noop;
        this.onChangeCallback = noop;
        this.change = new EventEmitter();
        this.valueChange = new EventEmitter();
    }
    Object.defineProperty(DatepickerComponent.prototype, "value", {
        get: function () {
            return this.currentValue;
        },
        set: function (val) {
            this.currentValue = val;
            this.change.emit(this.currentValue);
            this.valueChange.emit(this.currentValue);
            this.onChangeCallback(this.currentValue);
        },
        enumerable: true,
        configurable: true
    });
    DatepickerComponent.prototype.writeValue = function (value) {
        this.currentValue = value;
        this.onChangeCallback(value);
    };
    // From ControlValueAccessor interface
    DatepickerComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    // From ControlValueAccessor interface
    DatepickerComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    DatepickerComponent.prototype.ngOnInit = function () {
        if (!this.placeholder) {
            this.placeholder = 'mm/dd/yyyy';
        }
        if (this.oneYearLimit) {
            this.max = this.calendar.getToday();
            this.max.year = this.max.year + 1;
        }
    };
    DatepickerComponent.prototype.ngOnChanges = function (changes) {
        if (changes.minDate) {
            this.min = changes.minDate.currentValue;
        }
        if (changes.maxDate) {
            this.max = changes.maxDate.currentValue;
        }
    };
    return DatepickerComponent;
}());
export { DatepickerComponent };
export { ɵ0 };
