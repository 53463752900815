import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CalendarLabel, CalendarLabelAnswer, CalendarLabelQuestion, Event } from 'src/app/classes';
import { EventService } from 'src/app/services';

@Component({
  selector: 'app-event-incentives-review',
  templateUrl: './event-incentives-review.component.html',
  styles: []
})
export class EventIncentivesReviewComponent implements OnInit {
  modalOpen = false;
  qualifiesForDiscount: boolean;
  labelDiscountForm: FormGroup;

  get discountControl(): AbstractControl { return this.labelDiscountForm.controls.event_label_discount; }

  @Input() calendarLabels: CalendarLabel[];
  @Input() calendarLabelQuestions: CalendarLabelQuestion[];
  @Input() calendarLabelAnswers: CalendarLabelAnswer[];
  @Input() event: Event;
  @Input() isPostEvent = false;
  @Input() eventIncentivesDiscountAmt: number;
  @Output() eventIncentiveDiscountQualification = new EventEmitter<boolean>();

  constructor(
    private eventService: EventService,
    private fb: FormBuilder,
    private router: Router,
  ) {
    this.labelDiscountForm = this.fb.group({
      event_label_discount: null
    });
  }

  ngOnInit() {
    this.qualifiesForDiscount = false;
    this.prefillForm();
    // this.subscribeToForm();
  }

  prefillForm(): void {
    this.discountControl.setValue(this.qualifiesForDiscount);
    this.emitDisountQualification();
  }
  // change for PERM-210 
  // subscribeToForm(): void {
  //   this.discountControl.valueChanges.subscribe(value => {
  //     const discountBody = { event_label_discount: value };
  //     this.eventService.updateEventDiscounts(this.event.event_id, discountBody).subscribe(() => {
  //       this.qualifiesForDiscount = value;
  //       this.emitDisountQualification();
  //     });
  //   });
  // }

  private emitDisountQualification(): void {
    this.eventIncentiveDiscountQualification.emit(this.qualifiesForDiscount);
  }

  openModal(): void {
    this.modalOpen = true;
  }

  closeModal(): void {
    this.modalOpen = false;
  }

  answerByQuestion(question: CalendarLabelQuestion): CalendarLabelAnswer {
    return this.calendarLabelAnswers.find(answer => answer.question_id === question.id);
  }

  answerDetails(question: CalendarLabelQuestion): string {
    const answer = this.answerByQuestion(question);
    return answer ? answer.details : '';
  }

  answerClass(question: CalendarLabelQuestion): string {
    return !!this.answerByQuestion(question) ? 'text-success' : 'text-danger';
  }

  toIncentives(): void {
    this.router.navigate([`/event/${this.event.event_id}/event-incentives`]);
  }

  get loading(): boolean {
    return !this.calendarLabels || !this.calendarLabelQuestions || !this.calendarLabelAnswers;
  }

  get hasCalendarLabels(): boolean {
    return !!this.calendarLabels && !!this.calendarLabels.length && !!this.calendarLabelAnswers;
  }

  get hasCalendarLabelAnswers(): boolean {
    return this.calendarLabelAnswers && !!this.calendarLabelAnswers.length;
  }

  get labelQuantity(): number {
    return this.calendarLabels.length;
  }

  get labelNames(): string[] {
    return this.calendarLabels.map(label => label.name);
  }

  get labelMessage(): string {
    const discountMessage = this.qualifiesForDiscount
      ? ` and qualifies for a discount ($${this.eventIncentivesDiscountAmt.toFixed(2)} per rider fee),
        provided the event minimum rider surcharge of $300 has been met`
      : '';
    let labels: string;
    if (this.labelQuantity > 2) {
      const lastIndex = this.labelQuantity - 1;
      labels = this.labelNames.slice(this.labelQuantity - 1).join(', ') + ', and ' + this.labelNames[lastIndex];
    } else {
      labels = this.labelNames.join(' and ');
    }
    return `This event was labeled ${labels}${discountMessage}.`;
  }
}
