import * as tslib_1 from "tslib";
import { HttpClient, } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from './base-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
var QrCodeService = /** @class */ (function (_super) {
    tslib_1.__extends(QrCodeService, _super);
    function QrCodeService(http, cookie) {
        var _this = _super.call(this, http, cookie) || this;
        _this.http = http;
        _this.cookie = cookie;
        return _this;
    }
    QrCodeService.prototype.generateQrCode = function (text, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var data = { text: text, options: options };
        return this.http.post(this.url('qr/generate'), data, this.options)
            .pipe(tap(function (_) { return _this.log('generated QR code'); }), catchError(this.handleError('generateQrCode', {})));
    };
    QrCodeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QrCodeService_Factory() { return new QrCodeService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: QrCodeService, providedIn: "root" });
    return QrCodeService;
}(BaseService));
export { QrCodeService };
