import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { EventDate, SelectOption } from '../../classes';
import { EventListingService, LocationService } from '../../services';
import { dateRangeValidator, noWhitespaceValidator, zipCodeValidator } from '../../validators';
var EventDateCardComponent = /** @class */ (function () {
    function EventDateCardComponent(formBuilder, eventListing, locationService, dateParser) {
        this.formBuilder = formBuilder;
        this.eventListing = eventListing;
        this.locationService = locationService;
        this.dateParser = dateParser;
        this.dateChange = new EventEmitter();
        this.eventDates = [];
        this.stateOptions = [new SelectOption('', 'Select...')].concat(this.locationService.stateOptions);
    }
    Object.defineProperty(EventDateCardComponent.prototype, "f", {
        get: function () { return this.eventDate.controls; },
        enumerable: true,
        configurable: true
    });
    EventDateCardComponent.prototype.ngOnInit = function () {
        this.buildForm();
        this.setFormValues();
        this.updateAvailableDates();
        this.subscribeToForm();
    };
    EventDateCardComponent.prototype.ngOnChanges = function (changes) {
        if (changes.startDate || changes.endDate || (changes.eventDatesChanged && changes.eventDatesChanged.currentValue)) {
            this.updateAvailableDates();
        }
        // commented out for MBR-2992
        // if (this.eventDate && this.f.event_date_sub_disciplines.value && changes.subDisciplines && changes.subDisciplines.currentValue !== '') {
        //   this.f.event_date_sub_disciplines.value.forEach(subDisc => {
        //     if (!changes.subDisciplines.currentValue.find(sd => sd.value === subDisc.value)) {
        //       this.f.event_date_sub_disciplines.setValue('');
        //     }
        //   });
        // }
    };
    EventDateCardComponent.prototype.buildForm = function () {
        // commented out for MBR-2992
        this.eventDate = this.formBuilder.group({
            event_date_description: [''],
            // event_date_sub_disciplines: ['', Validators.required],
            event_date_start: [{ year: 2019, month: 1, day: 1 }, dateRangeValidator(this.startDate, this.endDate)],
            event_date_street: ['', [Validators.required, noWhitespaceValidator()]],
            event_date_unit: [''],
            event_date_city: ['', [Validators.required, noWhitespaceValidator()]],
            event_date_state: ['', [Validators.required, noWhitespaceValidator()]],
            event_date_postal_code: ['', [Validators.required, zipCodeValidator()]],
        });
    };
    EventDateCardComponent.prototype.setFormValues = function () {
        this.f.event_date_description.setValue(this.eventListingDate.event_date_description);
        // commented out for MBR-2992
        // this.f.event_date_sub_disciplines.setValue(this.setSubdisciplineValues());
        this.f.event_date_street.setValue(this.eventListingDate.event_date_street);
        this.f.event_date_unit.setValue(this.eventListingDate.event_date_unit);
        this.f.event_date_city.setValue(this.eventListingDate.event_date_city);
        this.f.event_date_state.setValue(this.eventListingDate.event_date_state || '');
        this.f.event_date_postal_code.setValue(this.eventListingDate.event_date_postal_code);
        this.f.event_date_start.setValue(this.setStartDateValue());
        // if (typeof this.eventListingDate.event_date_start === 'string') {
        //   this.eventListingDate.event_date_start = new Date(this.eventListingDate.event_date_start);
        // }
        // if (this.eventListingDate.event_date_start instanceof Date) {
        //   this.f.event_date_start.setValue({
        //     year: this.eventListingDate.event_date_start.getFullYear(),
        //     month: this.eventListingDate.event_date_start.getMonth() + 1,
        //     day: this.eventListingDate.event_date_start.getDate()
        //   });
        // }
        // this.eventDate.controls.event_date_start.valueChanges.subscribe((startDate) => {
        //   if (startDate.year !== undefined) {
        //     this.eventListingDate.event_date_start.setFullYear(startDate.year);
        //   }
        //   if (startDate.month !== undefined) {
        //     this.eventListingDate.event_date_start.setMonth(startDate.month - 1);
        //   }
        //   if (startDate.day !== undefined) {
        //     this.eventListingDate.event_date_start.setDate(startDate.day);
        //   }
        // });
    };
    EventDateCardComponent.prototype.subscribeToForm = function () {
        var _this = this;
        this.eventDate.valueChanges.subscribe(function (data) {
            _this.eventListingDate.event_date_description = data.event_date_description;
            _this.eventListingDate.event_date_start = data.event_date_start;
            // commented out for MBR-2992
            // this.eventListingDate.event_date_sub_disciplines = data.event_date_sub_disciplines;
            _this.eventListingDate.event_date_street = data.event_date_street;
            _this.eventListingDate.event_date_unit = data.event_date_unit;
            _this.eventListingDate.event_date_city = data.event_date_city;
            _this.eventListingDate.event_date_state = data.event_date_state;
            _this.eventListingDate.event_date_postal_code = data.event_date_postal_code;
            _this.setDateValidity();
        });
        this.f.event_date_start.valueChanges.subscribe(function (val) {
            if (val) {
                _this.dateChange.emit(true);
            }
        });
    };
    EventDateCardComponent.prototype.setDateValidity = function () {
        this.eventListingDate.isValid = this.eventDate.valid;
    };
    EventDateCardComponent.prototype.setStartDateValue = function () {
        var start = this.eventListingDate.event_date_start;
        return (this.eventListing.mode === 'update' && start !== '') ? new Date(start).toLocaleDateString() : start;
    };
    // commented out for MBR-2992
    // setSubdisciplineValues() {
    //   return this.eventListingDate.event_date_sub_disciplines;
    // }
    EventDateCardComponent.prototype.updateAvailableDates = function () {
        var _this = this;
        if (this.eventDate) {
            this.f.event_date_start.setValidators(dateRangeValidator(this.startDate, this.endDate));
            this.f.event_date_start.updateValueAndValidity({ emitEvent: false });
            this.setDateValidity();
            var start = moment(this.startDate).format('M/D/Y');
            var end = moment(this.endDate).format('M/D/Y');
            this.minDate = this.dateParser.parse(start);
            this.maxDate = this.dateParser.parse(end);
            this.eventDates = [];
            if (this.eventListing.eventDates.length > 0) {
                this.eventListing.eventDates.forEach(function (date) {
                    if (date.event_date_start !== '') {
                        _this.eventDates.push(_this.dateParser.parse(date.event_date_start));
                    }
                });
            }
            this.markDisabled = function (date) {
                var disabled = false;
                var currentDate = _this.dateParser.parse(_this.f.event_date_start.value);
                _this.eventDates.forEach(function (d) {
                    if ((!currentDate || !(d.day === currentDate.day && d.month === currentDate.month && d.year === currentDate.year))
                        && (date.day === d.day && date.month === d.month && date.year === d.year)) {
                        disabled = true;
                    }
                });
                return disabled;
            };
        }
    };
    EventDateCardComponent.prototype.setAddress = function (addrObj) {
        if (Object.keys(addrObj).includes('formatted_address')) {
            this.f.event_date_street.setValue(this.streetAddress(addrObj));
            this.f.event_date_city.setValue(addrObj.locality);
            this.f.event_date_state.setValue(addrObj.admin_area_l1);
            this.f.event_date_postal_code.setValue(addrObj.postal_code);
        }
    };
    EventDateCardComponent.prototype.streetAddress = function (addrObj) {
        return !!addrObj.street_number ? addrObj.street_number + ' ' + addrObj.route : addrObj.route;
    };
    EventDateCardComponent.prototype.removeDate = function (index) {
        this.dateChange.emit(true);
        this.eventListing.removeEventDate(index);
    };
    return EventDateCardComponent;
}());
export { EventDateCardComponent };
