import { OnInit } from '@angular/core';
import { ClubService, EventListingService, LocationService } from '../../services';
import { ActivatedRoute } from '@angular/router';
var EventListingComponent = /** @class */ (function () {
    function EventListingComponent(clubService, eventListing, locationService, route) {
        this.clubService = clubService;
        this.eventListing = eventListing;
        this.locationService = locationService;
        this.route = route;
        this.currentStep = 0;
        this.newLoaded = false;
    }
    Object.defineProperty(EventListingComponent.prototype, "dataLoaded", {
        get: function () {
            return this.eventListing.datesLoaded && this.eventListing.organizerLoaded
                && this.clubService.clubsLoaded && !!this.locationService.stateOptions;
        },
        enumerable: true,
        configurable: true
    });
    EventListingComponent.prototype.ngOnInit = function () {
        this.eventId = this.route.snapshot.paramMap.get('eventId');
        this.eventListing.mode = this.eventId ? 'update' : 'create';
        if (this.eventListing.mode === 'update') {
            this.eventListing.fetchEventData(this.eventId);
        }
        else {
            this.eventListing.reset();
            this.newLoaded = true;
        }
        this.clubService.setClubListOptions();
        this.locationService.getStates().subscribe();
    };
    return EventListingComponent;
}());
export { EventListingComponent };
