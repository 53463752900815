/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../atoms/button/button.component.ngfactory";
import * as i2 from "../../atoms/button/button.component";
import * as i3 from "./unauthorized.component";
import * as i4 from "../../services/authentication.service";
var styles_UnauthorizedComponent = [];
var RenderType_UnauthorizedComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UnauthorizedComponent, data: {} });
export { RenderType_UnauthorizedComponent as RenderType_UnauthorizedComponent };
export function View_UnauthorizedComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "main-container col-12 text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h3", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Your session token has expired. Please logout and login again to renew your session."])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(6, 114688, null, 0, i2.ButtonComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵted(-1, 0, ["Logout Now"]))], function (_ck, _v) { var currVal_0 = "btn-primary btn-lg"; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_UnauthorizedComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-unauthorized", [], null, null, null, View_UnauthorizedComponent_0, RenderType_UnauthorizedComponent)), i0.ɵdid(1, 114688, null, 0, i3.UnauthorizedComponent, [i4.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnauthorizedComponentNgFactory = i0.ɵccf("app-unauthorized", i3.UnauthorizedComponent, View_UnauthorizedComponent_Host_0, {}, {}, []);
export { UnauthorizedComponentNgFactory as UnauthorizedComponentNgFactory };
