import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EventListingService, PostEventService } from 'src/app/services';
import * as moment from 'moment';

import {
  Event,
  EventDate,
  SubDiscipline,
  PostEventParticipants,
  PostEventRiders,
  PostEvent,
  Profile,
  Alert,
  ShopifyDraftOrder,
  ShopifyOrder,
  ShopifyProductVariant,
  CalendarLabel,
  CalendarLabelAnswer,
  CalendarLabelQuestion
} from '../../classes';
import { MerchantAccountTag, Product, ShopifyService } from 'src/app/shared';
import { ShopifyDiscount } from 'src/app/classes';

@Component({
  selector: 'app-post-event-2-form',
  templateUrl: './post-event-2-form.component.html',
  styles: []
})
export class PostEvent2FormComponent implements OnInit {

  @Input() event: Event;
  @Input() isSeries: boolean;
  @Input() eventDate: EventDate;
  @Input() eventDates: EventDate[];
  @Input() urlSegments: string[];
  @Input() userRole: string;
  @Input() currentUser: Profile;
  @Input() eventOrganizer: Profile;
  @Input() calendarLabels: CalendarLabel[];
  @Input() calendarLabelQuestions: CalendarLabelQuestion[];
  @Input() calendarLabelAnswers: CalendarLabelAnswer[];

  get eventId() { return (this.event.event_id); }
  get eventDateId() { return (this.eventDate && this.eventDate.event_date_id) || null; }
  postEvent: PostEvent;
  get postEventId() { return this.postEvent.post_event_id; }
  get isSeriesFirstDay(): boolean { return this.isSeries && this.eventDate.event_date_first; }

  participantForm: FormGroup;
  riderForm: FormGroup;
  licenseMembershipForm: FormGroup;
  paperworkForm: FormGroup;
  feeTotalsForm: FormGroup;
  submitted = false;
  trigger: string;

  products: Product[] = [];

  alerts: Alert[] = [];
  displayAlert = false;

  uniqueRiderTotal = 0;
  uniqueRiders = {};
  riderSurchargeTotal = 0;
  riderSurcharges = {};
  licenseTotal = 0;
  membershipTotal = 0;
  draftOrder: ShopifyDraftOrder;
  draftOrders: ShopifyDraftOrder[];
  orders: ShopifyOrder[] = [];

  lateFee = false;
  get minimumRiderSurchargeApplicable(): boolean { return !this.isSeries || this.isSeriesFirstDay; }
  minimumRiderSurchargeApplied: boolean;
  // 20¢ per rider fee
  eventIncentivesDiscountAmt = 0.20;
  qualifiesForEventIncentivesDiscount: boolean;

  loading = true;
  updating = false;
  disableBtns = false;

  // Wait for 2 seconds after changes stop to update draft order
  updateDraftDelay = 2000;
  updateDraftTimeout: any;

  // Product SKUs
  riderSurchargeSkus = {};

  oneDayOnSiteSku: string;
  oneDayOnlineSku: string;
  lateFeeSku: string;
  minimumRiderSurchargeSku: string;

  memberPremiumSku = environment.postEventMembershipPremium;
  memberCollegiateSku = environment.postEventMembershipCollegiate;
  memberJuniorSku = environment.postEventMembershipJunior;

  skus: string[] = [];

  // Product Variants
  variants: ShopifyProductVariant[];
  riderSurchargeVariants = {};

  oneDayOnSiteVariant: ShopifyProductVariant;
  oneDayOnlineVariant: ShopifyProductVariant;
  lateFeeVariant: ShopifyProductVariant;
  minimumRiderSurchargeVariant: ShopifyProductVariant;

  memberPremiumVariant: ShopifyProductVariant;
  memberCollegiateVariant: ShopifyProductVariant;
  memberJuniorVariant: ShopifyProductVariant;

  licenseMembershipVariants = {};

  variantTotals = {};
  excludeFromCharges: string[] = [
    'post_event_one_day_online',
  ];

  // TODO: Determine max ceiling for registrations, riders, products, etc.
  minQuantity = 1;
  maxQuantity = 9999;

  // commented out for MBR-2992
  // subDisciplines: SubDiscipline[] = [];
  // tslint:disable-next-line: max-line-length
  riderInstructions = 'This section is where you will enter the unique riders for each sub-discipline you had, per day. For riders that have raced in more than one discipline per day, please ONLY count that rider for their first event of the day.';

  participantControls = {};
  riderControls = {};

  groupedParticipantControls = {};
  groupedRiderControls = {};
  disciplineName: string;

  get pf() { return this.participantForm.controls; }
  get rf() { return this.riderForm.controls; }
  get lmf() { return this.licenseMembershipForm.controls; }
  get pwf() { return this.paperworkForm.controls; }
  get ftf() { return this.feeTotalsForm.controls; }

  constructor(
    private formBuilder: FormBuilder,
    private postEventService: PostEventService,
    private router: Router,
    private shopifyService: ShopifyService,
    private eventListing: EventListingService
  ) { }

  ngOnInit() {
    // commented out for MBR-2992
    // if (this.isSeries) {
    //   this.subDisciplines = this.eventDate.event_date_sub_disciplines.sort(this.compareSubDisciplines);
    // }
    this.minimumRiderSurchargeApplied = this.minimumRiderSurchargeApplicable;
    this.setAlerts();
    this.getSkus();
    this.loadProductVariantsAndBuildForms();
    this.lateFee = this.postEventService.checkLateFee(this.isSeries, this.eventDates, this.eventDate);
  }

  setAlerts() {
    this.alerts.push(
      new Alert(
        'info', 'You must complete Part 1 of the Post Event form before preceding to Part 2.', 'Part 1 Incomplete',
        'clipboard', 'Go Back', this.part1Url, null, false
      )
    );
  }

  getSkus() {
    this.skus = [
      this.memberPremiumSku,
      this.memberCollegiateSku,
      this.memberJuniorSku,
    ];
    const skus = environment.disciplineSkus[this.event.event_discipline];
    Object.keys(skus).forEach(key => {
      if (key.includes('riderSurcharge')) {
        this.riderSurchargeSkus[key] = skus[key];
        this.skus.push(skus[key]);
      }
    });
    // Membership SKUs assigned earlier (not discipline-specific)
    this.oneDayOnSiteSku = skus.oneDayOnSiteLic;
    this.oneDayOnlineSku = skus.oneDayOnlineLic;
    this.lateFeeSku = skus.permitLateFee;
    this.minimumRiderSurchargeSku = skus.minimumRiderSurcharge;
    this.skus.push(this.oneDayOnSiteSku);
    this.skus.push(this.oneDayOnlineSku);
    this.skus.push(this.lateFeeSku);
    this.skus.push(this.minimumRiderSurchargeSku);
  }

  loadProductVariantsAndBuildForms() {
    this.eventListing.getDisciplines().subscribe(data => {
      const discs = this.eventListing.filterDisciplines(data);
      this.disciplineName = this.eventListing.getDisciplineName(discs, this.event.event_discipline);
    });
    this.shopifyService.getShopifyProductVariants({skus: this.skus}).subscribe(variants => {
      this.variants = variants;
      Object.keys(this.riderSurchargeSkus).forEach(key => {
        this.riderSurchargeVariants[key] = this.findVariant(variants, this.riderSurchargeSkus[key]);
      });

      this.oneDayOnSiteVariant = this.findVariant(variants, this.oneDayOnSiteSku);
      this.oneDayOnlineVariant = this.findVariant(variants, this.oneDayOnlineSku);
      this.lateFeeVariant = this.findVariant(variants, this.lateFeeSku);
      this.minimumRiderSurchargeVariant = this.findVariant(variants, this.minimumRiderSurchargeSku);
      this.riderSurchargeTotal = this.minimumRiderSurchargeApplied ? this.minimumRiderSurchargePrice : 0;

      this.memberPremiumVariant = this.findVariant(variants, this.memberPremiumSku);
      this.memberCollegiateVariant = this.findVariant(variants, this.memberCollegiateSku);
      this.memberJuniorVariant = this.findVariant(variants, this.memberJuniorSku);

      this.licenseMembershipVariants = {
        post_event_one_day_on_site: this.oneDayOnSiteVariant,
        post_event_one_day_online: this.oneDayOnlineVariant,
        post_event_membership_premium: this.memberPremiumVariant,
        post_event_membership_collegiate: this.memberCollegiateVariant,
        post_event_membership_junior: this.memberJuniorVariant
      };
      this.buildFormsAndFetchPostEvent();
    });
  }

  findVariant(variants: ShopifyProductVariant[], sku: string) {
    return variants.find(variant => sku === variant.sku);
  }

  get lateFeePrice() {
    if (this.lateFee) {
      return this.lateFeeVariant.price;
    } else {
      return 0;
    }
  }

  getOrdersAndDraftOrderAndSubscribeToForms() {
    this.getDraftOrderAndSubscribeToForms();
    this.getOrders();
  }

  getDraftOrderAndSubscribeToForms() {
    this.shopifyService.getShopifyDraftOrders({ post_event_id: this.postEventId }).subscribe(draftOrders => {
      this.draftOrders = draftOrders;
      // Only assign original draft order
      if (draftOrders.length) { this.draftOrder = draftOrders[0]; }
      this.loading = false;
      this.subscribeToFormsAndAssignPostEvent();
    });
  }

  getOrders() {
    this.shopifyService.getShopifyOrders({post_event_id: this.postEventId}).subscribe(orders => {
      this.orders = orders;
    });
  }

  buildFormsAndFetchPostEvent() {
    this.buildForms();
    this.fetchPostEvent();
  }

  buildForms() {
    this.buildParticipantAndRiderForms();
    this.buildLicenseMembershipForm();
    this.groupedParticipantControls = this.setControlGroups(this.pf);
    this.groupedRiderControls = this.setControlGroups(this.rf);
    this.paperworkForm = this.formBuilder.group({ post_event_paperwork_license_memberships: false });

    // console.log('Grouped participant controls:', this.groupedParticipantControls);
    // console.log('Grouped rider controls: ', this.groupedRiderControls);
  }

  buildParticipantAndRiderForms() {
    // commented out for MBR-2992
    // if (this.isSeries) {
    //   this.subDisciplines.forEach(subDisc => {
    //     this.buildEventDateSubDisciplineControl('participants', this.participantControls, this.eventDate, subDisc);
    //     this.buildEventDateSubDisciplineControl('riders', this.riderControls, this.eventDate, subDisc);
    //   });
    // } else {
      this.eventDates.forEach(date => {
        this.buildEventDateDisciplineControl('participants', this.participantControls, date, date.event_date_event_discipline);
          this.buildEventDateDisciplineControl('riders', this.riderControls, date, date.event_date_event_discipline);
      });
    // }
    this.participantForm = this.formBuilder.group(this.participantControls);
    // console.log('Event participants form generated: ', this.pf);
    this.riderForm = this.formBuilder.group(this.riderControls);
    // console.log('Rider fees form generated: ', this.rf);
  }

  buildEventDateDisciplineControl(type: string, controls: any, date: EventDate, disc: number) {
    const prefix = `post_event_${type}_`;
    let keyBase;
    if (date.event_date_sub_disciplines && date.event_date_sub_disciplines.length) {
      keyBase = `event_date_${date.event_date_id}_subdisc_${date.event_date_sub_disciplines[0].subdiscipline_id}_`;
    } else {
      keyBase = `event_date_${date.event_date_id}_disc_${disc}_`;
    }
    if (type === 'participants') {
      ['pre_registered', 'registered_on_site', 'total'].forEach(suffix => {
        controls[prefix + keyBase + suffix] = ['0', Validators.required];
      });
    } else {
      const validators = [Validators.required];
      const startDate = moment(date.event_date_start).startOf('day');
      const today = moment().startOf('day');

      ['unique', 'cost', 'fee_total'].forEach(suffix => {
        const skuPartial = this.disciplineName.toUpperCase().replace(/ /g, '-');
        const variant = this.variants.find(v => v.sku === `RIDER-SUR-${skuPartial}`);
        const value = suffix === 'cost' && variant ? variant.price : '0';
        // Require minimum of 1 unique rider if event date has passed
        if (suffix === 'unique' && startDate.isBefore(today)) {
          validators.push(Validators.min(1));
        }
        const name = prefix + keyBase + suffix;
        controls[name] = [value, validators];
      });
    }
  }

  buildLicenseMembershipForm() {
    this.licenseMembershipForm = this.formBuilder.group({
      post_event_one_day_on_site: [0, Validators.required],
      post_event_one_day_online: [0, Validators.required],
      post_event_membership_premium: [0, Validators.required],
      post_event_membership_collegiate: [0, Validators.required],
      post_event_membership_junior: [0, Validators.required]
    });
    // console.log('License/Membership form generated: ', this.lmf);
    Object.keys(this.lmf).forEach(key => this.variantTotals[key] = 0);
  }

  subscribeToFormsAndAssignPostEvent() {
    this.subscribeToParticipantForm();
    this.subscribeToRiderForm();
    this.subscribeToLicenseMembershipForm();
    this.assignPostEventToForms();
  }

  subscribeToParticipantForm() {
    Object.keys(this.groupedParticipantControls).forEach(group => {
      const controls = Object.keys(this.groupedParticipantControls[group]);
      const preRegistered = controls.find(control => control.includes('pre_registered'));
      const onSite = controls.find(control => control.includes('on_site'));
      const total = controls.find(control => control.includes('total'));
      // Update 'Total Registrations' on either 'Pre-Registered' or 'On-Site Registrations' changes
      this.participantForm.get(preRegistered).valueChanges.subscribe(value => {
        const sum = value ? (parseInt(value) + parseInt(this.pf[onSite].value)).toString() : '0';
        this.pf[total].setValue(sum);
      });
      this.participantForm.get(onSite).valueChanges.subscribe(value => {
        const sum = value ? (parseInt(value) + parseInt(this.pf[preRegistered].value)).toString() : '0';
        this.pf[total].setValue(sum);
      });
    });
  }

  subscribeToRiderForm() {
    Object.keys(this.groupedRiderControls).forEach(group => {
      const controls = Object.keys(this.groupedRiderControls[group]);
      const uniqueRiders = controls.find(control => control.includes('unique') && !control.includes('fee'));
      const cost = controls.find(control => control.includes('cost'));
      const totalFees = controls.find(control => control.includes('fee_total'));

      this.uniqueRiders[group] = 0;
      this.riderSurcharges[group] = 0;

      // Update 'Total' & riderSurchargeTotal on 'Riders' changes (using product price)
      this.riderForm.get(uniqueRiders).valueChanges.subscribe(value => {
        const riders = parseInt(value);
        const total = riders * parseFloat(this.rf[cost].value);
        let uniqueRiderTotal = 0;
        let surchargeTotal = 0;

        this.uniqueRiders[group] = riders;
        this.riderSurcharges[group] = total;
        this.rf[totalFees].setValue(total.toString());

        Object.keys(this.riderSurcharges).forEach(key => {
          uniqueRiderTotal += this.uniqueRiders[key];
          surchargeTotal += this.riderSurcharges[key];
        });

        this.uniqueRiderTotal = uniqueRiderTotal;
        this.applyMinimumRiderSurcharge(surchargeTotal);
        this.applyEventIncentivesDiscount();
        this.updatePostEventAndDraftOrder();
      });
    });
  }

  subscribeToLicenseMembershipForm() {
    this.licenseMembershipForm.valueChanges.subscribe(controls => {
      // Calcuate total license/Membership item costs (using product price)
      Object.keys(controls).forEach(control => {
        if (!this.excludeFromCharges.includes(control)) {
          this.variantTotals[control] = (parseInt(controls[control]) * this.variantPrice(this.licenseMembershipVariants, control)) || 0;
        }
      });
      // Update 'One Day Licenses' & 'Memberships' totals
      this.licenseTotal = this.sumVariantTotals(this.licenses);
      this.membershipTotal = this.sumVariantTotals(this.memberships);
      this.updatePostEventAndDraftOrder();
    });
  }

  updateFeeTotalsForm(feeTotalsForm: FormGroup) {
    this.feeTotalsForm = feeTotalsForm;
    this.updatePostEventAndDraftOrder();
  }

  fetchPostEvent() {
    if (this.isSeries) {
      this.postEventService.getPostEventByEventDate(this.eventId, this.eventDateId).subscribe(postEvent => {
        if (postEvent) {
          // Check required field from part 1 to see if completed
          if (postEvent.post_event_sufficient_staff === null) {
            this.displayAlert = true;
          } else {
            this.postEvent = postEvent;
            this.getOrdersAndDraftOrderAndSubscribeToForms();
          }
        } else {
          this.displayAlert = true;
        }
      });
    } else {
      this.postEventService.getPostEventsByEvent(this.eventId).subscribe(postEvents => {
        this.postEvent = postEvents && postEvents.length && postEvents[0];
        if (this.postEvent) {
          // Check required field from part 1 to see if completed
          if (this.postEvent.post_event_sufficient_staff === null) {
            this.displayAlert = true;
          } else {
            this.getOrdersAndDraftOrderAndSubscribeToForms();
          }
        } else {
          this.displayAlert = true;
        }
      });
    }
  }

  assignPostEventToForms() {
    // Assign license/membership product quantities
    Object.keys(this.postEvent).forEach(key => {
      if (Object.keys(this.lmf).includes(key)) {
        this.lmf[key].setValue(this.postEvent[key] || 0);
      }
    });
    // Assign participant & rider form values
    this.assignNestedPostEventData(this.postEvent.post_event_participants, 'participants', this.pf);
    this.assignNestedPostEventData(this.postEvent.post_event_riders, 'riders', this.rf);
    this.pwf.post_event_paperwork_license_memberships.setValue(this.postEvent.post_event_paperwork_license_memberships || false);
  }

  assignNestedPostEventData(source: any[], type: string, formControls: any) {
    source.forEach(data => {
      let prefix;
      if (data.subdiscipline_id) {
        prefix = `post_event_${type}_event_date_${data.event_date_id}_subdisc_${data.subdiscipline_id}_`;
      } else {
        prefix = `post_event_${type}_event_date_${data.event_date_id}_disc_${this.event.event_discipline}_`;
      }
      Object.keys(data).forEach(key => {
        const matches = type === 'participants' ? key.match(/(?:participants_)(.*)/g) : key.match(/(?:riders_)(.*)/g);
        if (matches) {
          const control = type === 'participants' ? prefix + matches[0].substring(13) : prefix + matches[0].substring(7);
          if (Object.keys(formControls).includes(control)) {
            formControls[control].setValue(data[key]);
          }
        }
      });
    });
  }

  // On form change, update post event & update/create draft order
  updatePostEventAndDraftOrder() {
    if (this.postEvent && !this.invalidForm) {
      if (this.updateDraftTimeout) {
        clearTimeout(this.updateDraftTimeout);
      }

      this.updating = true;

      this.updateDraftTimeout = setTimeout(() => {
        this.postEventService.updatePostEventPart2(this.postEventId, this.postEventData).subscribe(resp => {
          if (resp) {
            this.updateOrCreateDraftOrder();
          }
          // Delay to allow totals to calculate before displaying
          setTimeout(() => this.updating = false, 1200);
        });
      }, this.updateDraftDelay);
    }
  }

  get invalidForm(): boolean {
    return (
      (!this.participantForm || !this.riderForm || !this.licenseMembershipForm ||
      !this.paperworkForm || !this.feeTotalsForm) ||
      (this.participantForm.invalid || this.riderForm.invalid || this.licenseMembershipForm.invalid ||
      this.paperworkForm.invalid)
    );
  }

  get postEventData() {
    const data = Object.assign({}, this.licenseMembershipForm.value, this.paperworkForm.value, this.feeTotalsForm.value,
      {
        post_event_id: this.postEventId,
        post_event_membership_standard: 0,
        post_event_participants: null,
        // post_event_participants: new PostEventParticipants(this.parseDynamicControlValues(this.pf, 'participants')),
        post_event_riders: new PostEventRiders(this.parseDynamicControlValues(this.rf, 'riders'))
      }
    );
    return data;
  }

  onSubmit(trigger: string) {
    this.trigger = trigger;
    this.disableBtns = true;

    this.submitted = true;

    // Stop here if form is invalid
    if (this.invalidForm || this.feeTotalsForm.invalid) {
      this.disableBtns = false;
      this.handleValidationFeedback();
      return;
    }

    this.postEventService.updatePostEventPart2(this.postEventId, this.postEventData).subscribe(resp => {
      if (resp) {
        if (this.isOfficialNotOrganizerOrAdmin) {
          if (this.trigger === 'pay-now' && this.postEvent.permit_post_event_status === 'form-due') {
            // Overwrite trigger to 'pay-later' (official should not be sent to payment)
            this.trigger = 'pay-later';
            this.postEventService.updatePostEventStatus(this.postEventId, 'payment-due').subscribe(response => {
              if (response) { this.updateOrCreateDraftOrder(true); }
            });
          } else {
            // Overwrite trigger to 'pay-later' (official should not be sent to payment)
            this.trigger = 'pay-later';
            this.updateOrCreateDraftOrder(true);
          }
        } else if (this.postEvent.permit_post_event_status === 'form-due') {
          this.postEventService.updatePostEventStatus(this.postEventId, 'payment-due').subscribe(response => {
            if (response) { this.updateOrCreateDraftOrder(true); }
          });
        } else {
          this.updateOrCreateDraftOrder(true);
        }
      }
    });
  }

  continue() {
    if (this.trigger === 'pay-later') {
      this.toOverview();
    } else if (this.trigger === 'pay-now') {
      this.toCheckout();
    }
  }

  parseDynamicControlValues(controls: any, prefix: string) {
    const values = [];
    Object.keys(controls).forEach(key => {
      const control = controls[key];
      const eventDateMatch = key.match(/(?:date_)(\d*)/g)[0];
      const disciplineMatch = key.match(/(?:disc_)(\d*)/g)[0];
      const suffix = key.split('_').slice(8).join('_');
      if (eventDateMatch && disciplineMatch) {
        const eventDateId = eventDateMatch.substring(5);
        const disciplineId = disciplineMatch.substring(5);
        const peId = 'post_event_id';
        const edId = 'event_date_id';
        const discId = 'discipline_id';
        const existingObj = values.find(value => value[edId] === eventDateId && value[discId] === disciplineId);
        if (existingObj) {
          existingObj[prefix + '_' + suffix] = control.value;
        } else {
          const obj = {};
          obj[peId] = this.postEventId;
          obj[edId] = eventDateId;
          obj[discId] = disciplineId;
          obj[prefix + '_' + suffix] = control.value;
          values.push(obj);
        }
      }
    });
    return values;
  }

  setControlGroups(controls: any) {
    const grouped = {};
    // commented out for MBR-2992
    // if (this.isSeries) {
    //   this.subDisciplines.forEach(subDisc => {
    //     const key = `event_date_${this.eventDateId}_subdisc_${subDisc.subdiscipline_id}`;
    //     grouped[key] = this.groupControls(controls, this.eventDate, subDisc);
    //   });
    // } else {
      this.eventDates.forEach(eventDate => {
        // commented out for MBR-2992
        // eventDate.event_date_sub_disciplines.forEach(subDisc => {
        let key;
        if (eventDate.event_date_sub_disciplines && eventDate.event_date_sub_disciplines.length) {
          key = `event_date_${eventDate.event_date_id}_subdisc_${eventDate.event_date_sub_disciplines[0].subdiscipline_id}`;
          grouped[key] = this.groupControls(controls, eventDate, eventDate.event_date_sub_disciplines[0].subdiscipline_id, true);
        } else {
          key = `event_date_${eventDate.event_date_id}_disc_${eventDate.event_date_event_discipline}`;
          grouped[key] = this.groupControls(controls, eventDate, eventDate.event_date_event_discipline, false);
        }
      });
    //   });
    // }
      return grouped;
  }

  groupControls(controls: any, eventDate: EventDate, eventDiscipline: number, isSubDiscipline: boolean) {
    const filtered = {};
    Object.keys(controls).forEach(key => {
      if (isSubDiscipline && key.includes(`event_date_${eventDate.event_date_id}_subdisc_${eventDiscipline}`)) {
        filtered[key] = controls[key];
      }
      if (!isSubDiscipline && key.includes(`event_date_${eventDate.event_date_id}_disc_${eventDiscipline}`)) {
        filtered[key] = controls[key];
      }
    });
    return filtered;
  }

  private handleValidationFeedback(): void {
    let fragment: string;
    const formGroups = {
      rider: this.riderForm,
      licenseMembership: this.licenseMembershipForm,
      paperwork: this.paperworkForm,
      feeTotals: this.feeTotalsForm
    };

    for (const [key, value] of Object.entries(formGroups)) {
      if (value.invalid) {
        fragment = key;
        break;
      }
    }

    if (fragment) {
      document.getElementById(fragment).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

  get isUsacAdmin(): boolean {
    return this.userRole === 'usac_admin';
  }

  get isOfficial(): boolean {
    // return this.userRole === 'official';
    // TODO: Confirm if 'is_official' permission is actually assigned to every official account
    if (this.isSeries) {
      return (
        this.eventDate && this.currentUser &&
        this.eventDate.event_date_chief_ref && this.eventDate.event_date_chief_ref.profile_id === this.currentUser.profile_id
      );
    } else {
      return (
        this.eventDates && this.currentUser &&
        this.eventDates.some(date => date.event_date_chief_ref && date.event_date_chief_ref.profile_id === this.currentUser.profile_id)
      );
    }
  }

  get isEventOrganizer(): boolean {
    return this.currentUser.profile_id === this.eventOrganizer.profile_id;
  }

  get isOfficialNotOrganizerOrAdmin(): boolean {
    return this.isOfficial && !this.isEventOrganizer && !this.isUsacAdmin;
  }

  get payLaterBtnText(): string {
    return this.isOfficialNotOrganizerOrAdmin ? 'Save & Exit' : 'Save & Pay Later';
  }

  get payNowBtnText(): string {
    return this.isOfficialNotOrganizerOrAdmin ? 'Submit Form' : 'Continue to Payment';
  }

  get openDraftOrder(): boolean {
    return this.draftOrder && this.draftOrder.id && !this.draftOrder.order_id;
  }

  eventDateFromControl(name: string) {
    const matches = name.match(/(?:date_)(\d*)(?=_subdisc)/g);
    if (matches.length) {
      const id = parseInt(matches[0].substring(5));
      return this.eventDates.find(eventDate => eventDate.event_date_id === id);
    } else {
      throw new Error(`Unable to find ID match in string: ${name}`);
    }
  }

  // commented out for MBR-2992
  // subDisciplineFromControl(name: string, subDisciplines: SubDiscipline[]) {
  //   const matches = name.match(/(?:subdisc_)(\d*)/g);
  //   if (matches.length) {
  //     const id = parseInt(matches[0].substring(8));
  //     return subDisciplines.find(subDisc => subDisc.subdiscipline_id === id);
  //   } else {
  //     throw new Error(`Unable to find ID match in string: ${name}`);
  //   }
  // }

  get licenses() {
    const keys = [];
    Object.keys(this.variantTotals).forEach(key => { if (key.includes('one_day')) { keys.push(key); } });
    return keys;
  }

  get memberships() {
    const keys = [];
    Object.keys(this.variantTotals).forEach(key => { if (key.includes('membership')) { keys.push(key); } });
    return keys;
  }

  sumVariantTotals(variantList: string[]) {
    let total = 0;
    variantList.forEach(item => total += (this.variantTotals[item] || 0));
    return total;
  }

  variantPrice(variants: any, key: string) {
    return variants[key] ? parseFloat(variants[key].price) : 0;
  }

  private parseIfString(amount: number|string) {
    return typeof amount === 'string' ? parseFloat(amount) : amount;
  }

  get minimumRiderSurchargePrice(): number {
    return this.minimumRiderSurchargeVariant ? parseFloat(this.minimumRiderSurchargeVariant.price) : null;
  }

  private applyMinimumRiderSurcharge(surchargeTotal: number): void {
    this.minimumRiderSurchargeApplied = this.minimumRiderSurchargeApplicable && surchargeTotal <= this.minimumRiderSurchargePrice;
    this.riderSurchargeTotal = this.minimumRiderSurchargeApplied ? this.minimumRiderSurchargePrice : surchargeTotal;
  }


  onDiscountQualificationChange($event: boolean): void {
    this.qualifiesForEventIncentivesDiscount = $event;
    this.applyEventIncentivesDiscount();
  }

  private applyEventIncentivesDiscount(): void {
    if (this.feeTotalsForm) {
      let applyDiscount = false;
      let discountAmnt: number;

      const surchargeTotal = this.parseIfString(this.riderSurchargeTotal);

      if (this.qualifiesForEventIncentivesDiscount) {
        discountAmnt = this.uniqueRiderTotal * this.eventIncentivesDiscountAmt;
        const postDiscountTotal = surchargeTotal - discountAmnt;

        // Apply discount if min surcharge not applicable or post-discount fees over threshold
        if (!this.minimumRiderSurchargeApplicable || postDiscountTotal >= this.minimumRiderSurchargePrice) {
          applyDiscount = true;
        }

        // Charge min surcharge if post-discount fees below threshold (instead of higher non-discounted amount)
        if (this.minimumRiderSurchargeApplicable && postDiscountTotal <= this.minimumRiderSurchargePrice) {
          this.minimumRiderSurchargeApplied = true;
          this.riderSurchargeTotal = this.minimumRiderSurchargePrice;
        }
      } else {
        this.applyMinimumRiderSurcharge(surchargeTotal);
      }

      this.ftf.post_event_discount.setValue(applyDiscount ? discountAmnt : 0);
    }
  }

  updateOrCreateDraftOrder(continueAfter = false) {
    const lineItems = [];

    Object.keys(this.groupedRiderControls).forEach(key => {
      const disciplineMatch = key.match(/(?:disc_)(\d*)/g)[0];
      if (disciplineMatch) {
        const disciplineId = parseInt(disciplineMatch.substring(5));

        const quantity = parseInt(this.rf['post_event_riders_' + key + '_unique'].value);
        const skuPartial = this.disciplineName.toUpperCase().replace(/ /g, '-');
        const surchargeVariant = this.variants.find(variant => variant.sku === `RIDER-SUR-${skuPartial}`);

        if (!this.minimumRiderSurchargeApplied && quantity > 0 && !!surchargeVariant) {
          const existingLineItem = lineItems.find(item => item.variant_id === surchargeVariant.id);
          if (existingLineItem) {
            existingLineItem.quantity += quantity;
          } else {
            this.addLineItem(surchargeVariant, quantity, lineItems);
          }
        }
      }
    });

    Object.keys(this.licenseMembershipVariants).forEach(key => {
      if (!this.excludeFromCharges.includes(key)) {
        const lmVariant = this.licenseMembershipVariants[key];
        const quantity = parseInt(this.lmf[key].value);
        if (quantity > 0 && !!lmVariant) {
          this.addLineItem(lmVariant, quantity, lineItems);
        }
      }
    });

    if (this.minimumRiderSurchargeApplied) {
      this.addLineItem(this.minimumRiderSurchargeVariant, 1, lineItems);
    }

    if (this.ftf.post_event_late_fee.value && !this.ftf.post_event_late_fee_dismissed.value) {
      this.addLineItem(this.lateFeeVariant, 1, lineItems);
    }

    if (lineItems.length) {
      // TODO: @Brennan - Lets sync a central definition for "default data" on a draft order, to be the same everywhere
      const tags = [`event_id-${this.eventId}`, `post_event_id-${this.postEventId}`, MerchantAccountTag.Permitting];
      const discountAmnt = parseFloat(this.ftf.post_event_discount.value);
      const discount = new ShopifyDiscount({
        description: 'Post Event Incentive Discount',
        value_type: 'fixed_amount',
        value: discountAmnt,
        amount: discountAmnt,
        title: 'Post Event'
      });
      const note = this.ftf.post_event_order_note.value;

      // Update original draft order if still open
      if (this.openDraftOrder) {
        this.shopifyService.updateDraftOrder(this.draftOrder.id, lineItems, tags, discount, note).subscribe(resp => {
          if (resp) {
            this.draftOrder = resp.draft_order;
            if (continueAfter) {
              this.continue();
            }
          }
        });
      // Create new draft order if one doesn't already exist
      } else if (!this.draftOrder) {
        let customer = {};

        if (this.eventOrganizer && this.eventOrganizer.shopify_customer_id) {
          customer = {
            id: this.eventOrganizer.shopify_customer_id,
          };
        }
        this.shopifyService.createDraftOrder(lineItems, tags, discount, note, customer).subscribe(resp => {
          if (resp) {
            this.draftOrder = resp.draft_order;
            if (continueAfter) {
              this.continue();
            }
          }
        });
      } else {
        if (continueAfter) {
          this.continue();
        }
      }
    }
  }

  addLineItem(variant: ShopifyProductVariant, quantity: number, lineItems: any[]) {
    lineItems.push({ variant_id: variant.id, quantity });
  }

  toOverview() {
    this.router.navigate([`event/${this.event.event_id}/overview`], {fragment: 'post-event'});
  }

  toCheckout() {
    window.location.assign(this.draftOrder.invoice_url);
  }

  get part1Url() {
    const base = this.urlSegments.slice(0, -1);
    base.push('part-1');
    return base.join('/');
  }

  toPart1() {
    this.router.navigate([this.part1Url]);
  }

  compareSubDisciplines(a: SubDiscipline, b: SubDiscipline) {
    const subDiscA = a.subdiscipline_name;
    const subDiscB = b.subdiscipline_name;

    let comparison = 0;
    if (subDiscA > subDiscB) {
      comparison = 1;
    } else if (subDiscA < subDiscB) {
      comparison = -1;
    }
    return comparison;
  }
}
