<h4 *ngIf="!notificationForm || !notifications.length">
  <span><i class="icon icon-bell text-secondary"></i></span>Notifications
</h4>

<!-- Loader -->
<div class="ml-1">
  <app-loader *ngIf="loading" [small]="true" [align]="'left'"></app-loader>
</div>

<!-- Empty State -->
<div *ngIf="!loading && !notifications.length" class="row">
  <p class="col-12 ml-1">There are no notifications for this event.</p>
</div>

<form *ngIf="notificationForm && notifications.length" [formGroup]="notificationForm" (change)="onChange()">
  <div class="row" style="align-items: baseline;">
    <h4 class="col-sm-5 col-md-4 col-lg-3 col-xl-2"><span><i class="icon icon-bell text-secondary"></i></span>Notifications</h4>
    <div *ngIf="notifications.length" class="col-sm-7">
      <app-button
        [type]="'btn btn-light'"
        id="notification-read"
        [disabled]="disabled"
        (click)="onSubmit('read')"
      >
        <i class="icon icon-check"></i>
      </app-button>
      <!-- TODO: Re-enable delete functionality once ready on the API side -->
      <!-- <app-button
        [type]="'btn btn-light'"
        id="notification-delete"
        [disabled]="disabled"
        (click)="onSubmit('delete')"
      >
        <i class="icon icon-trash"></i>
      </app-button> -->
    </div>
  </div>

  <br>
  <h5 *ngIf="!notifications.length">There are no notifications for this event.</h5>

  <div *ngIf="notifications.length">
    <ul class="notification-list small">
      <li *ngFor="let notification of notifications">
        <div class="row" [ngClass]="{'unread': !notification.read}">
          <div class="col-sm-6 col-md-5 col-lg-3 d-flex">
            <app-checkbox
              class="mt-2 mr-3"
              [formControlName]="'notification-' + notification.formControl"
              [id]="'notification-' + notification.formControl"
            ></app-checkbox>
            <p class="notification-type">
              <i
                class="icon"
                [ngClass]="{'icon-edit': notification.type === 'change-request', 'icon-comment': notification.type === 'comment', 'icon-license': notification.type === 'payment'}"
              ></i>
              {{notification.type.replace('-', ' ') | titlecase}}
            </p>
          </div>
          <p class="col-sm-4 col-md-7 col-lg-2 notification-status">
            <!-- TODO: Un-comment notification status once change request logic is complete -->
            <!-- <span *ngIf="notification.status"
              class="badge badge-pill"
              [ngClass]="{'badge-success': notification.status === 'Approved', 'badge-warning': notification.status === 'Pending Approval', 'badge-danger': notification.status === 'Denied'}"
            >
              <p>{{notification.status}}</p>
            </span> -->
            <span *ngIf="!notification.status && notification.type === 'comment'"><p>{{notification.user}} made a new comment</p></span>
            <span *ngIf="notification.type === 'payment'">
              <p *ngIf="notification.status === 'unpaid'">{{notification.user}} has an outstanding payment</p>
              <p *ngIf="notification.status === 'paid'">{{notification.user}} made a payment</p>
            </span>
          </p>
          <p class="col-md-8 col-lg-5">{{notification.content}}</p>
          <app-button *ngIf="notification.type != 'change-request'"
            [type]="'btn-light btn-sm btn-block'"
            (click)="onActionClick(notification.actionUrl)"
            style="flex:2;"
            class="mr-2 col-sm-6 col-md-3 col-lg-2"
          >View {{notification.type | titlecase}}</app-button>
          <app-button *ngIf="notification.type === 'change-request'"
            [type]="'btn-light btn-sm btn-block'"
            [mode]="'button'"
            style="flex:2;"
            class="mr-2 col-sm-6 col-md-3 col-lg-2"
            (click)="viewRequest()"
          >View Request</app-button>
        </div>
      </li>
    </ul>
  </div>
</form>
