import { CartItem, } from '../classes';
import { InsuranceService } from '../services/insurance.service';
import * as i0 from "@angular/core";
import * as i1 from "./insurance.service";
var CartService = /** @class */ (function () {
    function CartService(insurance) {
        this.insurance = insurance;
        this.refIndex = 0;
        this.itemsArrayIndex = 0;
        this.total = 0;
        this.items = [];
    }
    CartService.prototype.getItems = function () {
        return Object.values(this.items);
    };
    CartService.prototype.syncCertificates = function (reffIndex, isEdit) {
        var _this = this;
        this.insurance.certificates.forEach(function (cert) {
            if (cert.reffIndex === reffIndex) {
                if (!isEdit) {
                    _this.items.push(new CartItem(cert.name, 1, cert.price, 'Certificate', cert.reffIndex));
                }
                else {
                    //  let hasCartItem = false;
                    _this.items.forEach(function (item) {
                        if (item.reff === reffIndex) {
                            item.title = cert.name,
                                item.price = cert.price;
                            //    hasCartItem = true;
                        }
                    });
                    /*  if ( hasCartItem === false ) {
                        this.items.push(new CartItem(cert.name, 1, cert.price, 'Certificate', cert.reffIndex));
                      } */
                }
            }
        });
        this.calculateTotalAmount();
    };
    CartService.prototype.removeCartItem = function (reffIndex) {
        var _this = this;
        this.itemsArrayIndex = 0;
        this.items.forEach(function (item) {
            if (item.reff === reffIndex) {
                _this.items.splice(_this.itemsArrayIndex, 1);
            }
            _this.itemsArrayIndex++;
        });
        this.calculateTotalAmount();
    };
    CartService.prototype.addLicenseTocart = function (licenseref) {
        console.log(this.items);
        if (this.isCartItemExist('Race Director License')) {
            return false;
        }
        this.items.push(new CartItem('Race Director License', 1, 150, 'license', licenseref));
        console.log(this.items);
        this.calculateTotalAmount();
    };
    CartService.prototype.isCartItemExist = function (name) {
        this.items.forEach(function (item) {
            console.log(item.title, name, item.title === name);
            if (item.title === name) {
                return true;
            }
        });
        return false;
    };
    CartService.prototype.calculateTotalAmount = function () {
        var total = 0;
        Object.values(this.items).forEach(function (item) {
            total += (item.price * item.quantity);
        });
        this.total = total;
    };
    CartService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CartService_Factory() { return new CartService(i0.ɵɵinject(i1.InsuranceService)); }, token: CartService, providedIn: "root" });
    return CartService;
}());
export { CartService };
