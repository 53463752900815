/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./nav-link.component";
var styles_NavLinkComponent = [];
var RenderType_NavLinkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NavLinkComponent, data: {} });
export { RenderType_NavLinkComponent as RenderType_NavLinkComponent };
export function View_NavLinkComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "text-decoration-none"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(2, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.path, ""); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.name; _ck(_v, 2, 0, currVal_3); }); }
export function View_NavLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-nav-link", [], null, null, null, View_NavLinkComponent_0, RenderType_NavLinkComponent)), i0.ɵdid(1, 114688, null, 0, i3.NavLinkComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavLinkComponentNgFactory = i0.ɵccf("app-nav-link", i3.NavLinkComponent, View_NavLinkComponent_Host_0, { path: "path", name: "name" }, {}, []);
export { NavLinkComponentNgFactory as NavLinkComponentNgFactory };
