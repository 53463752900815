import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: []
})
export class FooterComponent implements OnInit {

  links = [
    { path: 'https://www.usacycling.org/about-us', name: 'ABOUT US' },
    { path: 'https://www.usacycling.org/about-us/contact-us', name: 'CONTACT US' },
    { path: 'https://www.usacycling.org/about-us/jobs', name: 'CAREERS'},
    { path: 'https://www.facebook.com/usacycling', name: 'FACEBOOK'},
    { path: 'https://twitter.com/usacycling', name: 'TWITTER'},
    { path: 'https://www.instagram.com/usacycling/', name: 'INSTAGRAM'},
    { path: 'https://www.usacycling.org/blog', name: 'ARTICLES' },
    { path: 'https://www.usacycling.org/foundation', name: 'FOUNDATION'},
    { path: 'https://www.usacycling.org/resources/rulebook', name: 'RULES & POLICIES'}
  ];

  constructor() { }

  ngOnInit() {
  }

}
