import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateAdapter {

  fromModel(value: string): NgbDateStruct {
    if (!value) {
      return null;
    } else if (typeof(value) === 'string') {
      const parts = value.split('/');
      return { year: +parts[2], month: +parts[0], day: +parts[1] };
    }
  }

  toModel(date: NgbDateStruct): string {
    return date ? ('0' + date.month).slice(-2) + '/' + ('0' + date.day).slice(-2) + '/' + date.year : null;
  }
}
