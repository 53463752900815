import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import airbrake from '../airbrake';
import qs from 'qs';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
var apiUrl = environment.apiUrl, nextGenApiUrl = environment.nextGenApiUrl;
var BaseService = /** @class */ (function () {
    // protected profile_id = this.cookie.get('profile_id') || 'self';
    // TODO: Better handling for saved/pending event id context (multiple tabs, etc)
    // protected event_id  = this.cookie.get('event_id');
    function BaseService(http, cookie) {
        this.http = http;
        this.cookie = cookie;
        this.legacy = false;
        this.apiUrl = apiUrl;
        this.nextGenApiUrl = nextGenApiUrl;
        // Constructor stuff!
    }
    Object.defineProperty(BaseService.prototype, "options", {
        get: function () {
            return {
                withCredentials: true,
                headers: new HttpHeaders({
                    // Authorization: this.cookie.get('user_token'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseService.prototype, "profile_id", {
        get: function () {
            return this.cookie.get('USACLOGIN');
        },
        enumerable: true,
        configurable: true
    });
    BaseService.prototype.baseUrl = function () {
        return this.legacy ? apiUrl : nextGenApiUrl;
    };
    BaseService.prototype.url = function (path, filters, baseUrlOverride) {
        var queryFilters = this.stringifyFilters(filters);
        var baseUrl = baseUrlOverride || this.baseUrl();
        return baseUrl + path.replace(/^\//, '').replace(/\/+/g, '/') + queryFilters;
    };
    BaseService.prototype.log = function (message) {
        console.log(this.constructor.name + ": " + message);
    };
    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    BaseService.prototype.handleError = function (operation, result, data) {
        var _this = this;
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            var severity = 'error';
            if (error.status && error.status < 500) {
                severity = 'info';
            }
            console.error(error); // log to console instead
            airbrake.notify({
                error: error,
                context: {
                    severity: severity,
                    result: result,
                    data: data
                }
            });
            // TODO: better job of transforming error for user consumption
            _this.log(operation + " failed: " + error.message);
            if (error.status === 401) {
                console.log('Unauthorized! Prompt user to login');
                window.location.href = '/unauthorized';
            }
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    BaseService.prototype.filterMultiSelectValues = function (selected) {
        return selected.length > 0 ? selected.map(function (option) { return option.value; }) : [];
    };
    BaseService.prototype.parseFilters = function (query) {
        return qs.parse(query);
    };
    BaseService.prototype.stringifyFilters = function (params) {
        return qs.stringify(params, {
            addQueryPrefix: true,
            arrayFormat: 'brackets'
        });
    };
    BaseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BaseService_Factory() { return new BaseService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: BaseService, providedIn: "root" });
    return BaseService;
}());
export { BaseService };
