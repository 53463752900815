<!-- Loader -->
<app-loader *ngIf="loading"></app-loader>

<form *ngIf="!loading" [formGroup]="postEventForm">
  <h3 class="space-below-sm" id="registration">Registration</h3>
  <div class="row">
    <div class="col-md-6">
      <app-radio-group
        formControlName="post_event_sufficient_staff"
        [inline]="true"
        [name]="'post_event_sufficient_staff'"
        [label]="label('post_event_sufficient_staff')"
        [options]="sufficientStaffOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_sufficient_staff.errors"
        [required]=true
      ></app-radio-group>
      <app-radio-group
        formControlName="post_event_sufficient_facilities"
        [inline]="true"
        [name]="'post_event_sufficient_facilities'"
        [label]="label('post_event_sufficient_facilities')"
        [options]="sufficientFacilitiesOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_sufficient_facilities.errors"
        [required]=true
      ></app-radio-group>
      <app-radio-group
        formControlName="post_event_sufficient_time"
        [inline]="true"
        [name]="'post_event_sufficient_time'"
        [label]="label('post_event_sufficient_time')"
        [options]="sufficientTimeOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_sufficient_time.errors"
        [required]=true
      ></app-radio-group>
      <app-radio-group
        formControlName="post_event_start_lists_adequate"
        [inline]="true"
        [name]="'post_event_start_lists_adequate'"
        [label]="label('post_event_start_lists_adequate')"
        [options]="startListOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_start_lists_adequate.errors"
        [required]=true
      ></app-radio-group>
      <app-radio-group
        formControlName="post_event_check_licenses"
        [inline]="true"
        [name]="'post_event_check_licenses'"
        [label]="label('post_event_check_licenses')"
        [options]="licenseCheckOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_check_licenses.errors"
        [required]=true
      ></app-radio-group>
    </div>
    <div class="col-md-6">
      <app-text-area
        formControlName="post_event_registration_comments"
        [name]="'post_event_registration_comments'"
        [id]="'post_event_registration_comments'"
        [label]="label('post_event_registration_comments')"
        [placeholder]="'Write comments here...'"
        [maxLength]="'500'"
        [formSubmitted]="submitted"
        [errors]="f.post_event_registration_comments.errors"
      ></app-text-area>
    </div>
  </div>
  <br>
  <h3 class="space-below-sm" id="conduct">Event Conduct</h3>
  <div class="row">
    <div class="col-md-6">
      <app-radio-group
        formControlName="post_event_flyer_accurate"
        [inline]="true"
        [name]="'post_event_flyer_accurate'"
        [label]="label('post_event_flyer_accurate')"
        [options]="eventFlyerOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_flyer_accurate.errors"
        [required]=true
      ></app-radio-group>
      <app-radio-group
        formControlName="post_event_course_changes_required"
        [inline]="true"
        [name]="'post_event_course_changes_required'"
        [label]="label('post_event_course_changes_required')"
        [options]="courseChangesOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_course_changes_required.errors"
        [required]=true
      ></app-radio-group>
      <app-radio-group
        formControlName="post_event_podium"
        [inline]="true"
        [name]="'post_event_podium'"
        [label]="label('post_event_podium')"
        [options]="podiumOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_podium.errors"
        [required]=true
      ></app-radio-group>
      <!-- <app-radio-group
        formControlName="post_event_beginner_racer_program_event"
        [inline]="true"
        [name]="'post_event_beginner_racer_program_event'"
        [label]="label('post_event_beginner_racer_program_event')"
        [options]="brpOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_beginner_racer_program_event.errors"
        [required]=true
      ></app-radio-group> -->
    </div>
    <div class="col-md-6">
      <app-text-area
        formControlName="post_event_conduct_comments"
        [name]="'post_event_conduct_comments'"
        [id]="'post_event_conduct_comments'"
        [label]="label('post_event_conduct_comments')"
        [placeholder]="'Write comments here...'"
        [maxLength]="'500'"
        [formSubmitted]="submitted"
        [errors]="f.post_event_conduct_comments.errors"
      ></app-text-area>
    </div>
  </div>
  <br>
  <h3 class="space-below-sm" id="technical">Race Technical Support</h3>
  <div class="row">
    <div class="col-md-6">
      <app-radio-group
        formControlName="post_event_neutral_support_services"
        [inline]="true"
        [name]="'post_event_neutral_support_services'"
        [label]="label('post_event_neutral_support_services')"
        [options]="neutralSupportOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_neutral_support_services.errors"
        [required]=true
      ></app-radio-group>
      <app-radio-group
        formControlName="post_event_licensed_mechanics"
        [inline]="true"
        [name]="'post_event_licensed_mechanics'"
        [label]="label('post_event_licensed_mechanics')"
        [options]="licensedMechanicsOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_licensed_mechanics.errors"
        [required]=true
      ></app-radio-group>
      <app-radio-group
        formControlName="post_event_timing_company"
        [inline]="true"
        [name]="'post_event_timing_company'"
        [label]="label('post_event_timing_company')"
        [options]="timingCompanyOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_timing_company.errors"
        [required]=true
      ></app-radio-group>
    </div>
    <div class="col-md-6">
      <app-text-area
        formControlName="post_event_technical_support_comments"
        [name]="'post_event_technical_support_comments'"
        [id]="'post_event_technical_support_comments'"
        [label]="label('post_event_technical_support_comments')"
        [placeholder]="'Write comments here...'"
        [maxLength]="'500'"
        [formSubmitted]="submitted"
        [errors]="f.post_event_technical_support_comments.errors"
      ></app-text-area>
    </div>
  </div>
  <br>
  <h3 class="space-below-sm" id="medical">First Aid/Medical Support</h3>
  <div class="row space-below-lg">
    <div class="col-lg-6">
      <app-radio-group
        formControlName="post_event_medical_support_verified"
        [inline]="true"
        [name]="'post_event_medical_support_verified'"
        [label]="label('post_event_medical_support_verified')"
        [options]="medicalSupportOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_medical_support_verified.errors"
        [required]=true
      ></app-radio-group>
      <app-text-area
        formControlName="post_event_medical_support_description"
        [name]="'post_event_medical_support_description'"
        [id]="'post_event_medical_support_description'"
        [label]="label('post_event_medical_support_description')"
        [placeholder]="'Write comments here...'"
        [maxLength]="'500'"
        [formSubmitted]="submitted"
        [errors]="f.post_event_medical_support_description.errors"
        [required]=true
      ></app-text-area>
      <p>{{label('post_event_number_of_occurrences')}} <span class="required">*</span></p>
      <div class="space-below-lg">
        <app-quantity-select
          formControlName="post_event_number_of_occurrences"
          [name]="'post_event_number_of_occurrences'"
          [required]=true
          [min]="occurrenceReportMin()"
        ></app-quantity-select>
      </div>
      <div *ngIf="displayOccurrenceReportUpload">
        <div class="mb-3">
          <p>{{label('post_event_accident_occurrence_reports')}}
            <span class="icon icon-information" [ngbTooltip]="tipContent">
              <ng-template #tipContent>At least one report is required. Acceptable file types are PDF, DOCX, and DOC.</ng-template>
            </span>
          </p>
          <!-- Added Reports -->
          <div class="small">
            <app-card *ngFor="let report of occurrenceReports; let i = index;" [light]="false">
              <div class="container d-flex justify-content-between">
                <p class="space-above-sm">{{report.filename}}</p>
                <span (click)="removeReport('occurrence', occurrenceReports, i)" class="space-above-sm align-middle ml-2 cursor-pointer">
                  <i class="icon icon-delete"></i>
                </span>
              </div>
            </app-card>
          </div>
          <app-alert [alerts]="occurrenceReportAlert"></app-alert>
          <div class="input-group mt-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Upload</span>
            </div>
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="post_event_accident_occurrence_reports"
                (change)="selectOccurrenceFile($event)">
              <label class="custom-file-label" for="post_event_accident_occurrence_reports">Choose file</label>
            </div>
          </div>
        </div>
        <app-checkbox *ngIf="isUsacAdmin"
          formControlName="post_event_paperwork_occurrence_reports"
          [id]="'post_event_paperwork_occurrence_reports'"
          [name]="'post_event_paperwork_occurrence_reports'"
          [label]="'Occurence report paperwork received'"
          [checked]="true"
          [disabled]="disableOccurrencePaperwork"
          [formSubmitted]="submitted"
        ></app-checkbox>
      </div>
    </div>
  </div>
  <br>
  <h3 class="space-below-sm" id="disciplinary">Disciplinary Action</h3>
  <div class="row space-below-lg">
    <div class="col-lg-6">
      <app-radio-group
        formControlName="post_event_rider_discipline"
        [inline]="true"
        [name]="'post_event_rider_discipline'"
        [label]="label('post_event_rider_discipline')"
        [options]="disciplineRiderOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_rider_discipline.errors"
        [required]=true
      ></app-radio-group>
      <div *ngIf="displayDisciplineReportUpload">
        <div class="mb-3">
          <p>{{label('post_event_rider_discipline_reports')}}
            <span class="icon icon-information" [ngbTooltip]="tipContent">
              <ng-template #tipContent>At least one report is required. Acceptable file types are PDF, DOCX, and DOC.</ng-template>
            </span>
          </p>
          <!-- Added Reports -->
          <div class="small">
            <app-card *ngFor="let report of disciplineReports; let i = index;" [light]="false">
              <div class="container d-flex justify-content-between">
                <p class="space-above-sm">{{report.filename}}</p>
                <span (click)="removeReport('discipline', disciplineReports, i)" class="space-above-sm align-middle ml-2 cursor-pointer">
                  <i class="icon icon-delete"></i>
                </span>
              </div>
            </app-card>
          </div>
          <app-alert [alerts]="disciplineReportAlert"></app-alert>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Upload</span>
            </div>
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="post_event_rider_discipline_reports"
                (change)="selectDisciplineFile($event)"
              >
              <label class="custom-file-label" for="post_event_rider_discipline_reports">Choose file</label>
            </div>
          </div>
        </div>
        <app-checkbox *ngIf="isUsacAdmin"
          formControlName="post_event_paperwork_rider_discipline_reports"
          [id]="'post_event_paperwork_rider_discipline_reports'"
          [name]="'post_event_paperwork_rider_discipline_reports'"
          [label]="'Discipline report paperwork received'"
          [checked]="true"
          [disabled]="disableDisciplinePaperwork"
          [formSubmitted]="submitted"
        ></app-checkbox>
      </div>
    </div>
  </div>
  <br>
  <h3 class="space-below-sm" id="prizes">Prizes & Awards</h3>
  <div class="row">
    <div class="col-md-6">
      <app-text-input
        formControlName="post_event_largest_individual_prize"
        [type]="'text'"
        [name]="'post_event_largest_individual_prize'"
        [label]="label('post_event_largest_individual_prize')"
        [placeholder]="''"
        [formSubmitted]="submitted"
        [errors]="f.post_event_largest_individual_prize.errors"
        [required]=true
      ></app-text-input>
      <app-radio-group
        formControlName="post_event_prize_equal_pay"
        [inline]="true"
        [name]="'post_event_prize_equal_pay'"
        [label]="label('post_event_prize_equal_pay')"
        [options]="equalPayOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_prize_equal_pay.errors"
        [required]=true
      ></app-radio-group>
      <app-radio-group
        formControlName="post_event_prize_distributed_15_min"
        [inline]="true"
        [name]="'post_event_prize_distributed_15_min'"
        [label]="label('post_event_prize_distributed_15_min')"
        [options]="prizeDistributionOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_prize_distributed_15_min.errors"
        [required]=true
      ></app-radio-group>
      <app-radio-group
        formControlName="post_event_posting_area_adequate"
        [inline]="true"
        [name]="'post_event_posting_area_adequate'"
        [label]="label('post_event_posting_area_adequate')"
        [options]="postingAreaOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_posting_area_adequate.errors"
        [required]=true
      ></app-radio-group>
      <app-radio-group
        formControlName="post_event_protest_period_observed"
        [inline]="true"
        [name]="'post_event_protest_period_observed'"
        [label]="label('post_event_protest_period_observed')"
        [options]="protestPeriodOptions"
        [formSubmitted]="submitted"
        [errors]="f.post_event_protest_period_observed.errors"
        [required]=true
      ></app-radio-group>
    </div>
    <div class="col-md-6">
      <app-text-area
        formControlName="post_event_prize_comments"
        [name]="'post_event_prize_comments'"
        [id]="'post_event_prize_comments'"
        [label]="label('post_event_prize_comments')"
        [placeholder]="'Write comments here...'"
        [maxLength]="'500'"
        [formSubmitted]="submitted"
        [errors]="f.post_event_prize_comments.errors"
      ></app-text-area>
    </div>
  </div>

  <div class="row">
    <div class="col-sm">
      <app-button [mode]="'button'" [type]="'btn-light'" (click)="onSubmit('save-exit')">Save & Exit</app-button>
    </div>
    <div class="col-sm text-right">
      <app-button [type]="'btn-primary'" (click)="onSubmit('save-continue-ss')">Save & Continue</app-button>
    </div>
  </div>
</form>
