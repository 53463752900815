import { ValidatorFn, AbstractControl } from '@angular/forms';
import * as moment from 'moment';

export function dateRangeValidator(minDate: any, maxDate: any): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const min = moment(minDate);
    const max = moment(maxDate);
    const date = moment(control.value);

    return date && min && max && date >= min && date <= max
      ? null
      : {dateRange: {value: control.value}};
  };
}
