import { OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Referee, Profile } from '../../classes';
import { SelectOption } from '../../classes/select_option.class';
import { ProfileService } from 'src/app/services';
var AddRefereeComponent = /** @class */ (function () {
    function AddRefereeComponent(formBuilder, profileService) {
        this.formBuilder = formBuilder;
        this.profileService = profileService;
        this.addReferee = new EventEmitter();
        this.eventDateOptions = [];
        this.searchRefereeSubmitted = false;
        this.displaySearchResults = false;
        this.searching = false;
        this.searchButtonDisabled = true;
    }
    Object.defineProperty(AddRefereeComponent.prototype, "srf", {
        get: function () { return this.searchRefereeForm.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddRefereeComponent.prototype, "arf", {
        get: function () { return this.addRefereeForm.controls; },
        enumerable: true,
        configurable: true
    });
    AddRefereeComponent.prototype.ngOnInit = function () {
        var _this = this;
        var eventDateRequired = this.isSeries ? Validators.required : null;
        this.addRefereeForm = this.formBuilder.group({
            event_date_id: ['', eventDateRequired]
        });
        this.searchRefereeForm = this.formBuilder.group({
            usacId: [''],
            refereeName: ['']
        });
        // TODO: Is there a way to set these values without a timeout?
        setTimeout(function () {
            _this.eventDates.forEach(function (date) {
                var start = new Date(date.event_date_start);
                var startDate = start.getUTCMonth() + 1 + "/" + start.getUTCDate() + "/" + start.getUTCFullYear();
                _this.eventDateOptions.push(new SelectOption(date.event_date_id.toString(), startDate));
            });
            _this.arf.event_date_id.setValue(_this.eventDateOptions[0].value);
        }, 500);
        this.searchRefereeForm.valueChanges.subscribe(function (data) {
            if (_this.srf.refereeName.value === '' && _this.srf.usacId.value === '') {
                _this.searchButtonDisabled = true;
            }
            else {
                _this.searchButtonDisabled = false;
            }
        });
    };
    AddRefereeComponent.prototype.showSearchResults = function () {
        this.displaySearchResults = true;
    };
    AddRefereeComponent.prototype.hideSearchResults = function () {
        this.displaySearchResults = false;
    };
    AddRefereeComponent.prototype.searchReferee = function () {
        var _this = this;
        this.searchRefereeSubmitted = true;
        // stop here if form is invalid
        if (this.searchRefereeForm.invalid) {
            return;
        }
        this.hideSearchResults();
        this.searching = true;
        // GET request to Profile API
        this.profileService.searchReferees(this.srf.usacId.value, this.srf.refereeName.value).subscribe(function (results) {
            _this.searching = false;
            if (!results || results.length === 0) {
                _this.result = null;
                _this.showSearchResults();
                return;
            }
            // Show only first result
            for (var i = 0; i < 1; i++) {
                var result = results[i];
                var profile = new Profile(result);
                var address = result.address;
                var city = void 0;
                var state = void 0;
                if (address) {
                    city = address.address_city || '';
                    state = address.address_state || '';
                }
                else {
                    city = '';
                    state = '';
                }
                _this.result = new Referee(profile.profile_first_name, profile.profile_last_name, profile.profile_id, profile.age, city, state);
                _this.showSearchResults();
            }
        });
        // this.profileService.getProfiles().subscribe(profiles => {
        //   const searchData = this.searchRefereeForm.value;
        //   const result: Profile = profiles.find(p =>
        //     p.profile_comp_id.toString() === searchData.usacId ||
        //     p.profile_first_name + ' ' + p.profile_last_name === searchData.refereeName.trim()
        //   );
        //   if (!result) {
        //     this.result = null;
        //     this.showSearchResults();
        //     return;
        //   }
        //   const profile = new Profile(result);
        //   const address = profile.address.find(addr => addr.address_primary === 'Y');
        //   const city = address.address_city || '';
        //   const state = address.address_state || '';
        //   this.result = new Referee(
        //     profile.profile_first_name,
        //     profile.profile_last_name,
        //     profile.profile_comp_id,
        //     profile.age,
        //     city,
        //     state
        //   );
        //   this.showSearchResults();
        // });
        // this.result = this.profile.searchReferees(this.searchRefereeForm.value);
        // this.showSearchResults();
    };
    AddRefereeComponent.prototype.saveReferee = function (referee) {
        if (referee) {
            this.addReferee.emit({ event_date_id: this.arf.event_date_id.value, chief_ref: referee });
            this.searchRefereeForm.reset();
            this.searchRefereeSubmitted = false;
            this.hideSearchResults();
        }
    };
    return AddRefereeComponent;
}());
export { AddRefereeComponent };
