<h4>Chief Referee Assignment</h4>

<!-- Loader -->
<app-loader *ngIf="loading" [small]="true" [align]="'left'"></app-loader>

<!-- Empty State -->
<div *ngIf="!loading">
  <div *ngIf="!eventDates.length" class="row">
    <p class="col-12">There are no available event dates for managing chief referees.</p>
  </div>

  <div *ngIf="eventDates.length" class="row space-above-lg">
    <div [ngClass]="{'ref-left col-md-6 pr-4': isAdmin, 'col-12': !isAdmin}">
      <div *ngFor="let eventDate of eventDates" class="mb-3">
        <app-ref-accordion [isSeries]="isSeries" [eventDate]="eventDate"></app-ref-accordion>
      </div>
    </div>
    <div *ngIf="isAdmin" class="col-md-6 pl-4">
        <app-add-referee [isSeries]="isSeries" [eventDates]="eventDates" (addReferee)="onRefereeAdd($event)"></app-add-referee>
    </div>
  </div>
</div>
