import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ShopifyService } from '../../shared';
import { CartService, CertificateService, InsuranceService, LocationService, ProfileService } from '../../services';
import { SelectOption, InsuranceCertificate, Certificate, ShopifyDraftOrder, ShopifyProductVariant } from '../../classes';
import { phoneNumberValidator, emailValidator, noWhitespaceValidator, zipCodeValidator } from 'src/app/validators';
var CertificateComponent = /** @class */ (function () {
    function CertificateComponent(shopifyService, locationService, formBuilder, insurance, certificateService, profileService, cart) {
        this.shopifyService = shopifyService;
        this.locationService = locationService;
        this.formBuilder = formBuilder;
        this.insurance = insurance;
        this.certificateService = certificateService;
        this.profileService = profileService;
        this.cart = cart;
        this.includedCert = false;
        this.updateEndorsements = new EventEmitter();
        this.addToCart = new EventEmitter();
        this.saveDeleteCert = new EventEmitter();
        this.validateForm = new EventEmitter();
        this.submitted = false;
        this.stepOptions = [];
        this.formPrice = '0';
        this.autoSetFields = [];
        this.lineItems = [];
        this.previous = [];
        this.isSponsoringClub = false;
        this.displaycustom_relationship = false;
        this.title = 'Additional Certificate';
        this.saved = false;
        this.variantIds = [];
        this.endorsementOptions = [
            // TODO: At some point, should we consider using the Endorsement table IDs as values, instead of Shopify product IDs?
            new SelectOption('0', 'Additional Insured'),
            new SelectOption('1', 'Waiver of Subrogation'),
            new SelectOption('2', 'Primary and Non-Contributory'),
            new SelectOption('3', 'Notice of Cancellation'),
        ];
        this.initialEndorsementOptions = [];
        this.relationshipOptions = [
            new SelectOption(null, 'Select...'),
            new SelectOption('organizer', 'Organizer'),
            new SelectOption('owner_or_venue', 'Property Owner/Venue'),
            new SelectOption('sponsor', 'Sponsor'),
            new SelectOption('sponsoring_club', 'Sponsoring Club'),
            new SelectOption('municipality_or_government', 'Muncipality/Government'),
            new SelectOption('other', 'Other'),
        ];
        this.stateOptions = [
            new SelectOption('', 'Select...')
        ].concat(this.locationService.stateOptions);
    }
    Object.defineProperty(CertificateComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.insuredPersonForm.controls; },
        enumerable: true,
        configurable: true
    });
    CertificateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.profileService.getCurrentUser().subscribe(function () {
            _this.profileService.getUserPermissions().subscribe(function (permissions) {
                _this.userRole = _this.profileService.setUserRole(permissions);
            });
        });
        this.buildForm();
        var titles = [];
        this.endorsementVariants.forEach(function (variant) {
            _this.variantIds.push(variant.id);
            titles.push(variant.title);
        });
        this.autoSetFields = ['name', 'street', 'city', 'state', 'zip', 'email', 'phone', 'unit'];
        if (this.includedCert) {
            this.title = 'Included Certificate';
            this.isSponsoringClub = this.certificate.type === 'Sponsoring Club';
            this.autoSetFields.forEach(function (field) {
                _this.insuredPersonForm.get(field).setValue(_this.certificate[field]);
            });
        }
        this.setFormValues();
        this.setInitialPrice();
        if (this.certificate.endorsement) {
            this.initialEndorsementOptions.forEach(function (endorsement) {
                _this.previous.push({ variant_id: _this.endorsementVariants[endorsement.value].id, quantity: 1 });
            });
        }
        this.subscribeToForm();
        if (this.includedCert) {
            if (this.certificate.type === 'Event Organizer') {
                this.lineItem = { variant_id: this.namedInsuredOrganizerVariant.id, quantity: 1 };
            }
            else {
                this.lineItem = { variant_id: this.namedInsuredClubVariant.id, quantity: 1 };
            }
        }
        else if (!this.includedCert) {
            this.lineItem = { variant_id: this.additionalInsuredVariant.id, quantity: 1 };
            if (!this.certificate.certificateId) {
                // Only add additional certs that haven't already been saved
                this.shopifyService.increaseItemQuantity(this.lineItem, 1, this.draftOrder);
            }
        }
        this.openPanel();
    };
    CertificateComponent.prototype.ngOnChanges = function (changes) {
        this.submitted = changes.pageSubmitted ? changes.pageSubmitted.currentValue : false;
        if (this.pageSubmitted) {
            this.validateForm.emit(this.insuredPersonForm.valid);
        }
    };
    CertificateComponent.prototype.buildForm = function () {
        this.insuredPersonForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', emailValidator()],
            phone: ['', phoneNumberValidator()],
            insured_info: [''],
            street: ['', [Validators.required, noWhitespaceValidator()]],
            unit: [''],
            city: ['', [Validators.required, noWhitespaceValidator()]],
            state: ['', [Validators.required, noWhitespaceValidator()]],
            zip: ['', [Validators.required, zipCodeValidator()]],
            relationship: ['', Validators.required],
            custom_relationship: [''],
            endorsement: [false]
        });
    };
    Object.defineProperty(CertificateComponent.prototype, "isAdmin", {
        get: function () {
            return this.userRole === 'usac_admin';
        },
        enumerable: true,
        configurable: true
    });
    CertificateComponent.prototype.setFormValues = function () {
        // Assign form values
        if (this.certificate.relationship) {
            this.f.relationship.setValue(this.certificate.relationship);
        }
        else {
            if (this.certificate.type === 'Event Organizer') {
                this.f.relationship.setValue('organizer');
            }
            if (this.certificate.type === 'Sponsoring Club') {
                this.f.relationship.setValue('sponsoring_club');
            }
        }
        this.f.name.setValue(this.certificate.name);
        this.f.phone.setValue(this.certificate.phone);
        this.f.email.setValue(this.certificate.email);
        this.f.insured_info.setValue(this.certificate.specificVerbiage);
        this.f.street.setValue(this.certificate.street);
        this.f.unit.setValue(this.certificate.unit);
        this.f.city.setValue(this.certificate.city);
        this.f.state.setValue(this.certificate.state || '');
        this.f.zip.setValue(this.certificate.zip);
        this.f.custom_relationship.setValue(this.certificate.customRelationship);
        this.initialEndorsementOptions = this.endorsementsToSelectOptions(this.certificate.endorsement);
        // this.f.endorsement.setValue(this.initialEndorsementOptions);
        this.f.endorsement.setValue(this.endorsementsToSelectOptions(this.certificate.endorsement));
    };
    CertificateComponent.prototype.subscribeToForm = function () {
        var _this = this;
        // Subscribe to endorsement value changes after intial endorsements assigned from certificate
        this.f.endorsement.valueChanges.subscribe(function (selectedEndorsements) {
            _this.changeEndorsementStateShopify(selectedEndorsements);
        });
    };
    CertificateComponent.prototype.disableAddress = function (key) {
        return this.isSponsoringClub && !!this.certificate[key];
    };
    CertificateComponent.prototype.setAddress = function (addrObj) {
        if (Object.keys(addrObj).includes('formatted_address')) {
            this.f.street.setValue(addrObj.street_number + ' ' + addrObj.route);
            this.f.city.setValue(addrObj.locality);
            this.f.state.setValue(addrObj.admin_area_l1);
            this.f.zip.setValue(addrObj.postal_code);
        }
    };
    CertificateComponent.prototype.endorsementsToSelectOptions = function (endorsements) {
        var _this = this;
        if (endorsements === void 0) { endorsements = []; }
        var selected = [];
        endorsements.forEach(function (endorsement) {
            var index;
            if (endorsement.title.toLowerCase().includes('additional')) {
                index = 0;
            }
            else if (endorsement.title.toLowerCase().includes('subrogation')) {
                index = 1;
            }
            else if (endorsement.title.toLowerCase().includes('primary')) {
                index = 2;
            }
            else if (endorsement.title.toLowerCase().includes('cancellation')) {
                index = 3;
            }
            selected.push(_this.endorsementOptions[index]);
        });
        return selected;
    };
    CertificateComponent.prototype.debug = function (event) {
        console.log('debug form', this.f, event);
    };
    CertificateComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        // stop here if form is invalid
        if (this.insuredPersonForm.invalid) {
            this.saveDeleteCert.emit({ index: this.reffIndex, saved: false });
            return;
        }
        // display form values on success
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.insuredPersonForm.value, null, 4));
        this.certificate.name = this.f.name.value;
        this.certificate.relationship = this.f.relationship.value;
        this.certificate.street = this.f.street.value;
        this.certificate.unit = this.f.unit.value;
        this.certificate.city = this.f.city.value;
        this.certificate.state = this.f.state.value;
        this.certificate.zip = this.f.zip.value;
        this.certificate.email = this.f.email.value;
        this.certificate.phone = this.f.phone.value;
        this.certificate.specificVerbiage = this.f.insured_info.value;
        this.certificate.hasEndorsement = this.f.endorsement.value.length > 0;
        this.certificate.addToCart = true;
        if (this.certificate.reffIndex > 0) {
            this.certificate.isEdit = true;
        }
        else {
            this.cart.refIndex++;
            this.certificate.reffIndex = this.cart.refIndex;
            this.certificate.isEdit = false;
        }
        this.addToCart.emit();
        if (this.eventId !== null) {
            var endorsements_1 = [];
            if (this.f.endorsement.value.length) {
                this.f.endorsement.value.forEach(function (end) {
                    var variantId = _this.variantIds[end.value];
                    endorsements_1.push({ product_variant_id: variantId });
                });
            }
            var cert = new Certificate({
                id: this.certificate.certificateId,
                name: this.f.name.value,
                relationship: this.f.relationship.value,
                custom_relationship: this.f.custom_relationship.value,
                street: this.f.street.value,
                unit: this.f.unit.value,
                city: this.f.city.value,
                state: this.f.state.value,
                zip: this.f.zip.value,
                email: this.f.email.value,
                phone: this.f.phone.value,
                insured_info: this.f.insured_info.value,
                endorsement: endorsements_1,
                event_id: this.eventId,
                type: this.certificateType(),
                index: this.certificate.reffIndex
            });
            this.certificateService.addOrUpdateSelectedCert(this.certificate.reffIndex, cert);
        }
        this.saveDeleteCert.emit({ index: this.reffIndex, saved: true });
        this.saved = true;
        this.closePanel();
    };
    CertificateComponent.prototype.openPanel = function () {
        this.isOpen = 'ngb-panel-0';
    };
    CertificateComponent.prototype.closePanel = function () {
        this.isOpen = '';
    };
    CertificateComponent.prototype.togglePanel = function () {
        this.isOpen === '' ? this.openPanel() : this.closePanel();
    };
    CertificateComponent.prototype.cancel = function () {
        this.includedCert ? this.closePanel() : this.removeFromCart();
    };
    CertificateComponent.prototype.setInitialPrice = function () {
        this.formPrice = !this.includedCert ? this.certificate.price.toString() : '0';
    };
    CertificateComponent.prototype.certificateType = function () {
        if (this.f.relationship.value === 'organizer') {
            return 'named';
        }
        var includedTypes = ['Event Organizer', 'Sponsoring Club'];
        return (includedTypes.includes(this.certificate.type)) ? 'named' : 'additional';
    };
    CertificateComponent.prototype.onRelationshipChange = function () {
        var custom_relationship = this.insuredPersonForm.get('custom_relationship');
        if (this.f.relationship.value === 'other') {
            this.displaycustom_relationship = true;
            custom_relationship.setValidators(Validators.required);
        }
        else {
            this.displaycustom_relationship = false;
            custom_relationship.setValue('');
            custom_relationship.setValidators(null);
        }
        custom_relationship.updateValueAndValidity();
    };
    CertificateComponent.prototype.changeEndorsementStateShopify = function (endorsements) {
        var _this = this;
        this.certificate.hasEndorsement = endorsements.length > 0;
        this.lineItems = [];
        if (endorsements) {
            if (endorsements.length > 0) {
                this.formPrice = this.updatePrice(endorsements);
                endorsements.forEach(function (endorsement) {
                    var item = { variant_id: _this.endorsementVariants[endorsement.value].id, quantity: 1 };
                    _this.lineItems.push(item);
                });
            }
            else {
                this.setInitialPrice();
            }
            this.updateEndorsements.emit({ endorsements: this.lineItems, previous: this.previous });
            this.previous = this.lineItems;
        }
    };
    CertificateComponent.prototype.updatePrice = function (endorsements) {
        var _this = this;
        this.setInitialPrice();
        var total = parseFloat(this.formPrice);
        endorsements.forEach(function (endorsement) { return total += parseFloat(_this.endorsementVariants[endorsement.value].price); });
        return total.toString();
    };
    CertificateComponent.prototype.removeFromCart = function () {
        var _this = this;
        this.certificate.addToCart = false;
        this.insurance.removeCertificate(this.certificateId);
        this.cart.removeCartItem(this.reffIndex);
        this.certificateService.removeSelectedCert(this.reffIndex);
        this.saveDeleteCert.emit({ index: this.reffIndex, saved: false });
        this.shopifyService.decreaseItemQuantity(this.lineItem, 1, this.draftOrder);
        this.previous.forEach(function (item) {
            _this.shopifyService.decreaseItemQuantity(item, 1, _this.draftOrder);
        });
        if (this.certificate.certificateId && this.draftOrder.tags) {
            var certId_1 = this.certificate.certificateId;
            this.certificateService.deleteCertificate(this.eventId, certId_1).subscribe(function (resp) {
                if (resp) {
                    var certTag_1 = "certificate_id-" + certId_1;
                    var tagArray = _this.draftOrder.tags.split(', ');
                    var tagIndex = tagArray.findIndex(function (tag) { return tag === certTag_1; });
                    if (tagIndex) {
                        tagArray.splice(tagIndex, 1);
                        _this.draftOrder.tags = tagArray.join(', ');
                        _this.shopifyService.updateDraftOrderItems(_this.draftOrder);
                    }
                }
            });
        }
    };
    CertificateComponent.prototype.onReset = function () {
        this.submitted = false;
        this.insuredPersonForm.reset();
    };
    return CertificateComponent;
}());
export { CertificateComponent };
