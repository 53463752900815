import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { DriverVehicleService, EventDayService } from '../../services';
import { environment } from 'src/environments/environment.prod';
import { Vehicle, DriverLicense, Event, EventDay, Profile, ShopifyOrder } from 'src/app/classes';

@Component({
  selector: 'app-pre-event-add-ons',
  templateUrl: './pre-event-add-ons.component.html',
  styles: []
})
export class PreEventAddOnsComponent implements OnInit, OnChanges {

  @Input() event: Event;
  @Input() currentUser: Profile;
  @Input() userRole: string;
  @Input() orders: ShopifyOrder[];

  // loading = true;

  addOns: Array<any> = [];
  autoQuant = 0;
  motoQuant = 0;
  umbrellaQuant = 0;
  kidsPermitQuant = 0;
  ancillaryQuant = 0;
  practiceQuant = 0;
  setupTeardownQuant = 0;
  hasAuto = false;
  hasMoto = false;
  autoAddOns: any;
  motoAddOns: any;
  umbrellaAddOns: any;
  kidsPermitAddOns: any;
  ancillaryAddOns: any;
  practiceAddOns: any;
  setupTeardownAddOns: any;
  autoVehicles: Vehicle[] = [];
  motoVehicles: Vehicle[] = [];
  drivers: DriverLicense[] = [];
  fetchingDriversAndVehicles = false;
  driversLoaded = false;
  vehiclesLoaded = false;
  addOnsLoaded = false;
  allowManage = false;

  eventDays = [];
  eventDaysLoaded = false;

  autoSku: string;
  motoSku: string;
  autoMotoSkus: string[] = [];

  ancillarySku: string;
  kidsPermitSku: string;
  setupTeardownSku: string;
  practiceSku: string;

  // TODO: use this to display date details later
  // setupPracticeSkus: string[] = [];
  umbrellaSkus: string[] = [];

  get isUsacAdmin() { return this.userRole === 'usac_admin'; }
  get isEventOrganizer() { return this.currentUser && this.currentUser.profile_id === this.event.event_organizer; }

  get autoComplete(): boolean { return this.hasAuto && this.autoAddOns.status === 'Complete'; }
  get motoComplete(): boolean { return this.hasMoto && this.motoAddOns.status === 'Complete'; }

  get loaded(): boolean {
    return this.addOnsLoaded && (
      (this.autoQuant > 0 || this.motoQuant > 0) ? (this.vehiclesLoaded && this.driversLoaded) : true
    );
  }

  constructor(
    private router: Router,
    private driverVehicleService: DriverVehicleService,
    private eventDayService: EventDayService
  ) { }

  ngOnInit(): void {
    this.assignSkus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.allowManage = this.isUsacAdmin || this.isEventOrganizer;
    if (changes.orders) {
      if (!this.eventDaysLoaded) {
        this.getEventDays();
      // } else {
      //   this.searchAddOnLineItems();
      }
    }
  }

  private assignSkus(): void {
    const disc = this.event.event_discipline;
    // Auto & Moto Liability
    this.autoSku = environment.disciplineSkus[disc].autoInsurance;
    this.motoSku = environment.disciplineSkus[disc].motoInsurance;
    this.autoMotoSkus.push(this.autoSku);
    this.autoMotoSkus.push(this.motoSku);
    // Umbrella Insurance
    this.umbrellaSkus.push(environment.disciplineSkus[disc].umbrella99);
    this.umbrellaSkus.push(environment.disciplineSkus[disc].umbrella249);
    this.umbrellaSkus.push(environment.disciplineSkus[disc].umbrella999);
    this.umbrellaSkus.push(environment.disciplineSkus[disc].umbrella4999);
    this.umbrellaSkus.push(environment.disciplineSkus[disc].umbrella5000);
    this.umbrellaSkus.push(environment.disciplineSkus[disc].umbrella1000);
    // Ancillary Insurance
    this.ancillarySku = environment.disciplineSkus[disc].ancillaryInsurance;
    // Kids Permit
    this.kidsPermitSku = environment.disciplineSkus[disc].kidsPermit;
    // Setup/Teardown Days
    this.setupTeardownSku = environment.disciplineSkus[disc].setupTeardownDays;
    // Practice Days
    this.practiceSku = environment.disciplineSkus[disc].practiceDays;
    if (this.orders) {
      this.searchAddOnLineItems();
    }
  }

  private getEventDays(): void {
    this.eventDayService.getEventDaysForEvent(this.event.event_id).subscribe(days => {
      this.eventDays = days;
      this.eventDaysLoaded = true;
      this.searchAddOnLineItems();
    });
  }

  private searchAddOnLineItems(): void {
    this.addOns = [];
    this.resetQuantities();
    this.orders.forEach(order => {
      order.line_items.forEach(item => {
        const datePurchased = order.created_at;
        // Auto & Moto Liability
        if (this.autoMotoSkus.includes(item.sku)) {
          if (item.sku === this.autoSku) {
            this.autoQuant += item.quantity;
            this.autoAddOns = Object.assign({}, {
              title: item.title,
              src: '../assets/images/car.svg',
              quantity: this.autoQuant,
              date_purchased: datePurchased,
              type: 'auto',
              selected_dates: []
            });
            this.hasAuto = true;
          } else {
            this.motoQuant += item.quantity;
            this.motoAddOns = Object.assign({}, {
              title: item.title,
              src: '../assets/images/moto.svg',
              quantity: this.motoQuant,
              date_purchased: datePurchased,
              type: 'moto',
              selected_dates: []
            });
            this.hasMoto = true;
          }
        // Umbrella Insurance
        } else if (this.umbrellaSkus.includes(item.sku)) {
          this.umbrellaQuant += item.quantity;
          this.umbrellaAddOns = Object.assign({}, {
            title: item.title,
            src: '../assets/images/umbrella.svg',
            quantity: this.umbrellaQuant,
            date_purchased: datePurchased,
            selected_dates: []
          });
        // Ancillary Insurance
        } else if (this.ancillarySku === item.sku) {
          this.ancillaryQuant += item.quantity;
          this.ancillaryAddOns = Object.assign({}, {
            title: item.title,
            src: '../assets/images/ancillary.svg',
            quantity: this.ancillaryQuant,
            date_purchased: datePurchased,
            selected_dates: []
          });
        // Kids Permit
        } else if (this.kidsPermitSku === item.sku) {
          this.kidsPermitQuant += item.quantity;
          this.kidsPermitAddOns = Object.assign({}, {
            title: item.title,
            src: '../assets/images/child.svg',
            quantity: this.kidsPermitQuant,
            date_purchased: datePurchased,
            selected_dates: []
          });
        // Practice Days
        } else if (this.practiceSku === item.sku) {
          this.practiceQuant += item.quantity;
          this.practiceAddOns = Object.assign({}, {
            title: item.title,
            src: '../assets/images/calendar-day.svg',
            quantity: this.practiceQuant,
            date_purchased: datePurchased,
            selected_dates: this.eventDays
          });
        // Setup/Teardown Days
        } else if (this.setupTeardownSku === item.sku) {
          this.setupTeardownQuant += item.quantity;
          this.setupTeardownAddOns = Object.assign({}, {
            title: item.title,
            src: '../assets/images/calendar-day.svg',
            quantity: this.setupTeardownQuant,
            date_purchased: datePurchased,
            selected_dates: this.eventDays
          });
        }
      });
    });
    if (this.autoQuant > 0) { this.addOns.push(this.autoAddOns); }
    if (this.motoQuant > 0) { this.addOns.push(this.motoAddOns); }
    if (this.umbrellaQuant > 0) { this.addOns.push(this.umbrellaAddOns); }
    if (this.kidsPermitQuant > 0) { this.addOns.push(this.kidsPermitAddOns); }
    if (this.ancillaryQuant > 0) { this.addOns.push(this.ancillaryAddOns); }
    if (this.setupTeardownQuant > 0) { this.addOns.push(this.setupTeardownAddOns); }
    if (this.practiceQuant > 0) { this.addOns.push(this.practiceAddOns); }
    if ((this.autoQuant > 0 || this.motoQuant > 0) && !this.fetchingDriversAndVehicles) {
      this.fetchingDriversAndVehicles = true;
      this.driverVehicleService.getVehicles(this.event.event_permit_id).subscribe(vehicles => {
        this.autoVehicles = vehicles.filter(vehicle => vehicle.vehicle_type === 'auto');
        this.motoVehicles = vehicles.filter(vehicle => vehicle.vehicle_type === 'moto');
        this.vehiclesLoaded = true;
      });
      this.driverVehicleService.getDrivers(this.event.event_permit_id).subscribe(drivers => {
        this.drivers = drivers;
        this.driversLoaded = true;
      });
    }
    this.addOnsLoaded = true;
  }

  onQuantityChange(event: any): void {
    switch (event.type) {
      case 'auto': this.autoAddOns.status = event.status; break;
      case 'moto': this.motoAddOns.status = event.status;
    }
  }

  private resetQuantities(): void {
    this.autoQuant = 0;
    this.motoQuant = 0;
    this.umbrellaQuant = 0;
    this.kidsPermitQuant = 0;
    this.ancillaryQuant = 0;
    this.practiceQuant = 0;
    this.setupTeardownQuant = 0;
  }

  toAddOns(): void {
    this.router.navigate([`event/${this.event.event_id}/add-ons`]);
  }

}
