import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShopifyService } from '../../shared';

import { CartService, CertificateService, InsuranceService, LocationService, ProfileService } from '../../services';

import {
  SelectOption,
  InsuranceCertificate,
  Certificate,
  ShopifyLineItemBase,
  ShopifyDraftOrder,
  ShopifyProductVariant
} from '../../classes';
import { phoneNumberValidator, emailValidator, noWhitespaceValidator, zipCodeValidator } from 'src/app/validators';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styles: []
})
export class CertificateComponent implements OnInit, OnChanges {
  @Input() eventId: string;
  @Input() pageSubmitted: boolean;
  @Input() includedCert = false;
  @Input() certificate: InsuranceCertificate;
  @Input() certificateId: number;
  @Input() reffIndex: number;
  @Input() additionalInsuredVariant: ShopifyProductVariant;
  @Input() namedInsuredOrganizerVariant: ShopifyProductVariant;
  @Input() namedInsuredClubVariant: ShopifyProductVariant;
  @Input() endorsementVariants: ShopifyProductVariant[];
  @Input() draftOrder: ShopifyDraftOrder;

  @Output() updateEndorsements = new EventEmitter();
  @Output() addToCart = new EventEmitter();
  @Output() saveDeleteCert = new EventEmitter();
  @Output() validateForm = new EventEmitter();

  insuredPersonForm: FormGroup;
  submitted = false;

  lineItem: ShopifyLineItemBase;
  stepOptions: string[] = [];
  formPrice = '0';
  isOpen: string;
  autoSetFields: string[] = [];
  lineItems: ShopifyLineItemBase[] = [];
  previous: ShopifyLineItemBase[] = [];
  isSponsoringClub = false;
  displaycustom_relationship = false;
  title = 'Additional Certificate';
  saved = false;
  userRole: string;

  variantIds: number[] = [];

  endorsementOptions: SelectOption[] = [
    // TODO: At some point, should we consider using the Endorsement table IDs as values, instead of Shopify product IDs?
    new SelectOption('0', 'Additional Insured'),
    new SelectOption('1', 'Waiver of Subrogation'),
    new SelectOption('2', 'Primary and Non-Contributory'),
    new SelectOption('3', 'Notice of Cancellation'),
  ];
  initialEndorsementOptions: SelectOption[] = [];
  relationshipOptions: SelectOption[] = [
    new SelectOption(null, 'Select...'),
    new SelectOption('organizer', 'Organizer'),
    new SelectOption('owner_or_venue', 'Property Owner/Venue'),
    new SelectOption('sponsor', 'Sponsor'),
    new SelectOption('sponsoring_club', 'Sponsoring Club'),
    new SelectOption('municipality_or_government', 'Muncipality/Government'),
    new SelectOption('other', 'Other'),
  ];
  stateOptions: SelectOption[] = [
    new SelectOption('', 'Select...'),
    ...this.locationService.stateOptions
  ];

  constructor(
    private shopifyService: ShopifyService,
    private locationService: LocationService,
    private formBuilder: FormBuilder,
    private insurance: InsuranceService,
    private certificateService: CertificateService,
    private profileService: ProfileService,
    private cart: CartService
  ) {}

  // convenience getter for easy access to form fields
  get f() { return this.insuredPersonForm.controls; }


  ngOnInit() {
    this.profileService.getCurrentUser().subscribe(() => {
      this.profileService.getUserPermissions().subscribe(permissions => {
        this.userRole = this.profileService.setUserRole(permissions);
      });
    });
    this.buildForm();

    const titles = [];
    this.endorsementVariants.forEach(variant => {
      this.variantIds.push(variant.id);
      titles.push(variant.title);
    });

    this.autoSetFields = ['name', 'street', 'city', 'state', 'zip', 'email', 'phone', 'unit'];

    if (this.includedCert) {
      this.title = 'Included Certificate';
      this.isSponsoringClub = this.certificate.type === 'Sponsoring Club';
      this.autoSetFields.forEach(field => {
        this.insuredPersonForm.get(field).setValue(this.certificate[field]);
      });
    }

    this.setFormValues();

    this.setInitialPrice();

    if (this.certificate.endorsement) {
      this.initialEndorsementOptions.forEach(endorsement => {
        this.previous.push({ variant_id: this.endorsementVariants[endorsement.value].id, quantity: 1 });
      });
    }

    this.subscribeToForm();

    if (this.includedCert) {
      if (this.certificate.type === 'Event Organizer') {
        this.lineItem = { variant_id: this.namedInsuredOrganizerVariant.id, quantity: 1};
      } else {
        this.lineItem = { variant_id: this.namedInsuredClubVariant.id, quantity: 1};
      }
    } else if (!this.includedCert) {
      this.lineItem = { variant_id: this.additionalInsuredVariant.id, quantity: 1 };
      if (!this.certificate.certificateId) {
        // Only add additional certs that haven't already been saved
        this.shopifyService.increaseItemQuantity(this.lineItem, 1, this.draftOrder);
      }
    }

    this.openPanel();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.submitted = changes.pageSubmitted ? changes.pageSubmitted.currentValue : false;
    if (this.pageSubmitted) {
      this.validateForm.emit(this.insuredPersonForm.valid);
    }
  }

  buildForm(): void {
    this.insuredPersonForm = this.formBuilder.group({
      name: [ '', Validators.required ],
      email: [ '', emailValidator() ],
      phone: [ '', phoneNumberValidator() ],
      insured_info: [''],
      street: [ '', [Validators.required, noWhitespaceValidator()]],
      unit: [''],
      city: [ '', [Validators.required, noWhitespaceValidator()]],
      state: [ '', [Validators.required, noWhitespaceValidator()]],
      zip: [ '', [Validators.required, zipCodeValidator()]],
      relationship: [ '', Validators.required ],
      custom_relationship: [ '' ],
      endorsement: [ false ]
    });
  }

  get isAdmin(): boolean {
    return this.userRole === 'usac_admin';
  }

  setFormValues(): void {
    // Assign form values
    if (this.certificate.relationship) {
      this.f.relationship.setValue(this.certificate.relationship);
    } else {
      if (this.certificate.type === 'Event Organizer') {
        this.f.relationship.setValue('organizer');
      }
      if (this.certificate.type === 'Sponsoring Club') {
        this.f.relationship.setValue('sponsoring_club');
      }
    }
    this.f.name.setValue(this.certificate.name);
    this.f.phone.setValue(this.certificate.phone);
    this.f.email.setValue(this.certificate.email);
    this.f.insured_info.setValue(this.certificate.specificVerbiage);
    this.f.street.setValue(this.certificate.street);
    this.f.unit.setValue(this.certificate.unit);
    this.f.city.setValue(this.certificate.city);
    this.f.state.setValue(this.certificate.state || '');
    this.f.zip.setValue(this.certificate.zip);
    this.f.custom_relationship.setValue(this.certificate.customRelationship);
    this.initialEndorsementOptions = this.endorsementsToSelectOptions(this.certificate.endorsement);
    // this.f.endorsement.setValue(this.initialEndorsementOptions);
    this.f.endorsement.setValue(this.endorsementsToSelectOptions(this.certificate.endorsement));
  }

  subscribeToForm(): void {
    // Subscribe to endorsement value changes after intial endorsements assigned from certificate
    this.f.endorsement.valueChanges.subscribe(selectedEndorsements => {
      this.changeEndorsementStateShopify(selectedEndorsements);
    });
  }

  disableAddress(key: string): boolean {
    return this.isSponsoringClub && !!this.certificate[key];
  }

  setAddress(addrObj: any) {
    if (Object.keys(addrObj).includes('formatted_address')) {
      this.f.street.setValue(addrObj.street_number + ' ' + addrObj.route);
      this.f.city.setValue(addrObj.locality);
      this.f.state.setValue(addrObj.admin_area_l1);
      this.f.zip.setValue(addrObj.postal_code);
    }
  }

  endorsementsToSelectOptions(endorsements: any[] = []) {
    const selected = [];
    endorsements.forEach(endorsement => {
      let index;
      if (endorsement.title.toLowerCase().includes('additional')) {
        index = 0;
      } else if (endorsement.title.toLowerCase().includes('subrogation')) {
        index = 1;
      } else if (endorsement.title.toLowerCase().includes('primary')) {
        index = 2;
      } else if (endorsement.title.toLowerCase().includes('cancellation')) {
        index = 3;
      }
      selected.push(this.endorsementOptions[index]);
    });
    return selected;
  }

  debug(event: any): void {
    console.log('debug form', this.f, event);
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.insuredPersonForm.invalid) {
      this.saveDeleteCert.emit({index: this.reffIndex, saved: false});
      return;
    }

    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.insuredPersonForm.value, null, 4));
    this.certificate.name = this.f.name.value;
    this.certificate.relationship = this.f.relationship.value;
    this.certificate.street = this.f.street.value;
    this.certificate.unit = this.f.unit.value;
    this.certificate.city = this.f.city.value;
    this.certificate.state = this.f.state.value;
    this.certificate.zip = this.f.zip.value;
    this.certificate.email = this.f.email.value;
    this.certificate.phone = this.f.phone.value;
    this.certificate.specificVerbiage = this.f.insured_info.value;
    this.certificate.hasEndorsement = this.f.endorsement.value.length > 0;
    this.certificate.addToCart = true;
    if (this.certificate.reffIndex > 0) {
      this.certificate.isEdit = true;
    } else {
      this.cart.refIndex++;
      this.certificate.reffIndex = this.cart.refIndex;
      this.certificate.isEdit = false;
    }
    this.addToCart.emit();

    if (this.eventId !== null) {
      const endorsements = [];
      if (this.f.endorsement.value.length) {
        this.f.endorsement.value.forEach(end => {
          const variantId = this.variantIds[end.value];
          endorsements.push({product_variant_id: variantId});
        });
      }
      const cert = new Certificate({
        id: this.certificate.certificateId,
        name: this.f.name.value,
        relationship: this.f.relationship.value,
        custom_relationship: this.f.custom_relationship.value,
        street: this.f.street.value,
        unit: this.f.unit.value,
        city: this.f.city.value,
        state: this.f.state.value,
        zip: this.f.zip.value,
        email: this.f.email.value,
        phone: this.f.phone.value,
        insured_info: this.f.insured_info.value,
        endorsement: endorsements,
        event_id: this.eventId,
        type: this.certificateType(),
        index: this.certificate.reffIndex
      });
      this.certificateService.addOrUpdateSelectedCert(this.certificate.reffIndex, cert);
    }
    this.saveDeleteCert.emit({index: this.reffIndex, saved: true});
    this.saved = true;
    this.closePanel();
  }

  openPanel() {
    this.isOpen = 'ngb-panel-0';
  }
  closePanel() {
    this.isOpen = '';
  }
  togglePanel() {
    this.isOpen === '' ? this.openPanel() : this.closePanel();
  }

  cancel() {
    this.includedCert ? this.closePanel() : this.removeFromCart();
  }

  setInitialPrice() {
    this.formPrice = !this.includedCert ? this.certificate.price.toString() : '0';
  }

  certificateType() {
    if (this.f.relationship.value === 'organizer') {
      return 'named';
    }
    const includedTypes = ['Event Organizer', 'Sponsoring Club'];
    return (includedTypes.includes(this.certificate.type)) ? 'named' : 'additional';
  }

  onRelationshipChange() {
    const custom_relationship = this.insuredPersonForm.get('custom_relationship');
    if (this.f.relationship.value === 'other') {
      this.displaycustom_relationship = true;
      custom_relationship.setValidators(Validators.required);
    } else {
      this.displaycustom_relationship = false;
      custom_relationship.setValue('');
      custom_relationship.setValidators(null);
    }
    custom_relationship.updateValueAndValidity();
  }

  changeEndorsementStateShopify(endorsements: SelectOption[]) {
    this.certificate.hasEndorsement = endorsements.length > 0;
    this.lineItems = [];
    if (endorsements) {
      if (endorsements.length > 0) {
        this.formPrice = this.updatePrice(endorsements);
        endorsements.forEach(endorsement => {
          const item = { variant_id: this.endorsementVariants[endorsement.value].id, quantity: 1 };
          this.lineItems.push(item);
        });
      } else {
        this.setInitialPrice();
      }
      this.updateEndorsements.emit({endorsements: this.lineItems, previous: this.previous});
      this.previous = this.lineItems;
    }
  }
  updatePrice(endorsements: SelectOption[]) {
    this.setInitialPrice();
    let total = parseFloat(this.formPrice);
    endorsements.forEach(endorsement => total += parseFloat(this.endorsementVariants[endorsement.value].price));
    return total.toString();
  }
  removeFromCart() {
    this.certificate.addToCart = false;

    this.insurance.removeCertificate(this.certificateId);
    this.cart.removeCartItem(this.reffIndex);
    this.certificateService.removeSelectedCert(this.reffIndex);
    this.saveDeleteCert.emit({index: this.reffIndex, saved: false});

    this.shopifyService.decreaseItemQuantity(this.lineItem, 1, this.draftOrder);
    this.previous.forEach(item => {
      this.shopifyService.decreaseItemQuantity(item, 1, this.draftOrder);
    });
    if (this.certificate.certificateId && this.draftOrder.tags) {
      const certId = this.certificate.certificateId;
      this.certificateService.deleteCertificate(this.eventId, certId).subscribe(resp => {
        if (resp) {
          const certTag = `certificate_id-${certId}`;
          const tagArray = this.draftOrder.tags.split(', ');
          const tagIndex = tagArray.findIndex(tag => tag === certTag);
          if (tagIndex) {
            tagArray.splice(tagIndex, 1);
            this.draftOrder.tags = tagArray.join(', ');
            this.shopifyService.updateDraftOrderItems(this.draftOrder);
          }
        }
      });
    }
  }
  onReset() {
      this.submitted = false;
      this.insuredPersonForm.reset();
  }
}
