<nav class="navbar navbar-expand-lg navbar-light d-flex align-items-center">
  <div class="container">
  <app-logo class="navbar-brand justify-self-start" [width]="50"></app-logo>
  <button class="navbar-toggler hidden-sm-up"
          type="button"
          (click)="isNavbarCollapsed = !isNavbarCollapsed"
          data-target="#navbarMobile"
          aria-controls="navbarMobile"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
  </button>
  <div [ngbCollapse]="!isNavbarCollapsed" class="collapse navbar-collapse justify-content-end" id="navbarMobile">
    <app-nav-link-group class="nav-item nav-link" [links]="links"></app-nav-link-group>
  </div>
</div>
</nav>
