/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../atoms/loader/loader.component.ngfactory";
import * as i2 from "../../atoms/loader/loader.component";
import * as i3 from "@angular/common";
import * as i4 from "./overview-history.component";
import * as i5 from "../../services/activity.service";
var styles_OverviewHistoryComponent = [];
var RenderType_OverviewHistoryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OverviewHistoryComponent, data: {} });
export { RenderType_OverviewHistoryComponent as RenderType_OverviewHistoryComponent };
function View_OverviewHistoryComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], { small: [0, "small"], align: [1, "align"] }, null)], function (_ck, _v) { var currVal_0 = true; var currVal_1 = "left"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_OverviewHistoryComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["There is no change history for this event."]))], null, null); }
function View_OverviewHistoryComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " - "])), i0.ɵppd(2, 2), (_l()(), i0.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵted(5, null, [" ", ""]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), _v.context.$implicit.time, "MMMM d, y, h:mm a")); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.user; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.action; _ck(_v, 5, 0, currVal_2); }); }
function View_OverviewHistoryComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "mt-3"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverviewHistoryComponent_4)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entries; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_OverviewHistoryComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["History"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverviewHistoryComponent_1)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverviewHistoryComponent_2)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverviewHistoryComponent_3)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 4, 0, currVal_0); var currVal_1 = (!_co.loading && !_co.entries.length); _ck(_v, 6, 0, currVal_1); var currVal_2 = (!_co.loading && _co.entries.length); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_OverviewHistoryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-overview-history", [], null, null, null, View_OverviewHistoryComponent_0, RenderType_OverviewHistoryComponent)), i0.ɵdid(1, 114688, null, 0, i4.OverviewHistoryComponent, [i5.ActivityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OverviewHistoryComponentNgFactory = i0.ɵccf("app-overview-history", i4.OverviewHistoryComponent, View_OverviewHistoryComponent_Host_0, { event: "event" }, {}, []);
export { OverviewHistoryComponentNgFactory as OverviewHistoryComponentNgFactory };
