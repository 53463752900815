import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventListingService, EventService } from '../../services';
import { Contact, Event, EventDate } from 'src/app/classes';


@Component({
  selector: 'app-listing-body',
  templateUrl: './listing-body.component.html',
  styles: []
})
export class ListingBodyComponent implements OnInit {

  @Input() event: Event;
  @Input() qrUri: string;

  // event: Event;
  eventDates: EventDate[];

  validContacts: Contact[] = [];
  actualContact: Contact;
  disciplineName: string;
  eventTypeNames: string;
  websiteUrl: string;
  announcementUrl: string;
  regUrl: string;
  facebookUrl: string;
  twitterUrl: string;
  instagramUrl: string;
  youtubeUrl: string;

  loading = true;

  constructor(
    public eventListing: EventListingService,
    private eventService: EventService
  ) { }

  ngOnInit() {
    if (this.eventListing.mode === 'update') {
      const eventId = this.event ? this.event.event_id : this.eventListing.event_id;
      this.eventService.getEventDates(eventId).subscribe(dates => this.eventDates = dates);
    }

    this.websiteUrl = this.setHrefUrl(this.eventListing.event_website);
    this.announcementUrl = this.setHrefUrl(this.eventListing.event_announcement);
    this.regUrl = this.setHrefUrl(this.eventListing.event_reg_url);
    this.facebookUrl = this.setHrefUrl(this.eventListing.event_facebook_url);
    this.twitterUrl = this.setHrefUrl(this.eventListing.event_twitter_url);
    this.instagramUrl = this.setHrefUrl(this.eventListing.event_instagram_url);
    this.youtubeUrl = this.setHrefUrl(this.eventListing.event_youtube_url);

    this.eventListing.contacts.forEach(contact => { if (contact.isValid) { this.validContacts.push(contact); } });

    this.eventListing.getDisciplines().subscribe( data => {
      const discs = this.eventListing.filterDisciplines(data);
      this.disciplineName = this.eventListing.getDisciplineName(discs, this.eventListing.event_discipline);
    });

    this.eventListing.getEventTypes().subscribe( data => {
      if (this.eventListing.mode === 'update') {
        const eventTypes = this.event ? this.event.event_types : this.eventListing.event_types;
        this.eventTypeNames = this.eventListing.setTypeNames(data, eventTypes);
      } else {
        this.eventTypeNames = this.eventListing.setTypeNames(data, this.eventListing.event_types);
      }
    });

    this.loading = false;
  }

  setHrefUrl(url: string) {
    return url && url.indexOf('http') === 0 ? url : `http://${url}`;
  }

  getActualEventDate(reff: number) {
    let destiny: object;
    if (reff !== undefined
      && this.event !== undefined
      && this.event.hasOwnProperty('eventDates')) {
      this.eventDates.forEach(eventDate => {
        if (eventDate.event_date_event_id === reff) {
          destiny = eventDate;
        }
      });
    }
    return destiny;
  }

  // getSponsoringClubState(clubId: string): string {
  //   if (this.eventListing.mode === 'update') {
  //     let state: string;
  //     state = 'text-success';
  //     this.event.event_sponsoring_clubs.forEach(club => {
  //       if (club.value === clubId) {
  //         state = '';
  //       }
  //     });
  //     return state;
  //   }
  //   return '';
  // }

  getContactsState(contactId: number): string {
    if (this.eventListing.mode === 'update') {
      let state: string;
      state = 'text-success';
      this.event.event_contacts.forEach(contact => {
        if (contact.id === contactId) {
          state = '';
        }
      });
      return state;
    }
    return '';
  }

  getActualContact(contactId: number): boolean {
    console.log(contactId);
    if (this.eventListing.mode === 'update') {
      this.actualContact = new Contact();
      let state = false;
      this.event.event_contacts.forEach(contact => {
        if (contact.id === contactId) {
          Object.assign(this.actualContact, contact);
          state = true;
        }
      });
      return state;
    }
    return false;
  }

  hasUpdatedStartDate(): boolean {
    if (this.eventListing.mode === 'update' ) {
      const originalStartDate = new Date(this.event.event_start_date);
      return (
        this.eventListing.event_start_date !== originalStartDate
      );
    }
    return false;
  }

  hasUpdatedEndDate(): boolean {
    if (this.eventListing.mode === 'update' ) {
      const originalEndDate = new Date(this.event.event_end_date);
      return (
        this.eventListing.event_end_date !== originalEndDate
      );
    }
    return false;
  }

  get specialCalendarText(): string {
    const calendars = this.eventListing.event_special_calendars;
    if (typeof calendars[0] === 'object') {
      const calendarText = [];
      calendars.forEach(cal => {
        if (!!cal.special_calendar_text) {
          calendarText.push(cal.special_calendar_text.split('-').join(' '));
        }
      });
      return calendarText.join(', ');
    } else {
      return calendars.map(cal => cal.split('-').join(' ')).join(', ');
    }
  }

  get oneDayRegistrationUrl(): string {
    return `${environment.membershipUrl}/one-day/${this.event.event_id}`;
  }

  isExistsActualContactProp(key: string): boolean {
    if (this.actualContact !== undefined) {
      return this.actualContact.hasOwnProperty(key);
    }
    return false;
  }

}
