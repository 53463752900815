import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Event } from 'src/app/classes';
import { EventService } from 'src/app/services';
var PreEventRefAssignmentComponent = /** @class */ (function () {
    function PreEventRefAssignmentComponent(eventService) {
        this.eventService = eventService;
        this.assignedRefs = [];
        this.isSeries = false;
        this.adminRoles = ['usac_admin', 'la_admin'];
    }
    Object.defineProperty(PreEventRefAssignmentComponent.prototype, "loading", {
        get: function () {
            return !this.userRole || !this.eventDates;
        },
        enumerable: true,
        configurable: true
    });
    PreEventRefAssignmentComponent.prototype.ngOnInit = function () {
        this.setIsSeries();
    };
    PreEventRefAssignmentComponent.prototype.ngOnChanges = function (changes) {
        if (changes.userRole) {
            this.setAssignmentAccess();
        }
    };
    PreEventRefAssignmentComponent.prototype.setAssignmentAccess = function () {
        this.isAdmin = this.adminRoles.includes(this.userRole);
    };
    PreEventRefAssignmentComponent.prototype.setIsSeries = function () {
        this.isSeries = this.eventService.isSeries(this.event);
    };
    PreEventRefAssignmentComponent.prototype.updateEventDate = function (id, referee) {
        var _this = this;
        if (this.isSeries) {
            var eventDate = this.eventDates.find(function (date) { return date.event_date_id.toString() === id; });
            this.setChiefRef(eventDate, referee);
        }
        else {
            this.eventDates.forEach(function (eventDate) {
                _this.setChiefRef(eventDate, referee);
            });
        }
    };
    PreEventRefAssignmentComponent.prototype.setChiefRef = function (date, referee) {
        var data = Object.assign({}, date, {
            event_date_chief_ref: referee.usac_id,
            // To prevent overriding subdisciplines
            event_date_sub_disciplines: null
        });
        this.eventService.editEventDate(data, date.event_date_id).subscribe(function () {
            date.event_date_chief_ref = {
                profile_first_name: referee.first_name,
                profile_last_name: referee.last_name
            };
        });
    };
    PreEventRefAssignmentComponent.prototype.onRefereeAdd = function ($event) {
        this.updateEventDate($event.event_date_id, $event.chief_ref);
    };
    return PreEventRefAssignmentComponent;
}());
export { PreEventRefAssignmentComponent };
