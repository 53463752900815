import * as tslib_1 from "tslib";
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Profile, PermissionSet, DriverLicense, Vehicle } from '../classes';
import { BaseService } from './base-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
var DriverVehicleService = /** @class */ (function (_super) {
    tslib_1.__extends(DriverVehicleService, _super);
    function DriverVehicleService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DriverVehicleService.prototype.getDrivers = function (permitId) {
        var _this = this;
        return this.http.get(this.url("permit/" + permitId + "/addons/drivers"), this.options)
            .pipe(tap(function (_) { return _this.log('fetched drivers'); }), catchError(this.handleError('getDrivers', [], { permitId: permitId })));
    };
    DriverVehicleService.prototype.addDriver = function (permitId, driver) {
        var _this = this;
        return this.http.post(this.url("permit/" + permitId + "/addons/drivers"), driver, this.options)
            .pipe(tap(function (newDriver) { return _this.log("added driver id: " + newDriver.id); }), catchError(this.handleError('addDriver', new DriverLicense({}), { permitId: permitId, driver: driver })));
    };
    DriverVehicleService.prototype.deleteDriver = function (permitId, driverId) {
        var _this = this;
        return this.http.delete(this.url("permit/" + permitId + "/addons/drivers/" + driverId), this.options)
            .pipe(tap(function (_) { return _this.log('deleted driver'); }), catchError(this.handleError('deleteDriver', null, { permitId: permitId, driverId: driverId })));
    };
    DriverVehicleService.prototype.searchDrivers = function (compId, name) {
        var _this = this;
        var params = [];
        if (compId && compId !== '') {
            params.push("comp_id=" + compId);
        }
        if (name && name !== '') {
            params.push("name=" + name);
        }
        var queryParams = '?' + params.join('&');
        return this.http.get(this.url('/license/searchdrivers' + queryParams), this.options)
            .pipe(tap(function (_) { return _this.log('fetched driver(s)'); }), catchError(this.handleError('searchDrivers', [], { compId: compId, name: name })));
    };
    DriverVehicleService.prototype.getVehicles = function (permitId) {
        var _this = this;
        return this.http.get(this.url("permit/" + permitId + "/addons/vehicles"), this.options)
            .pipe(tap(function (_) { return _this.log('fetched vehicles'); }), catchError(this.handleError('getVehicles', [], { permitId: permitId })));
    };
    DriverVehicleService.prototype.addVehicle = function (permitId, vehicle) {
        var _this = this;
        return this.http.post(this.url("permit/" + permitId + "/addons/vehicles"), vehicle, this.options)
            .pipe(tap(function (newVehicle) { return _this.log("added vehicle id: " + newVehicle.id); }), catchError(this.handleError('addVehicle', new Vehicle({}), { permitId: permitId, vehicle: vehicle })));
    };
    DriverVehicleService.prototype.deleteVehicle = function (permitId, vehicleId) {
        var _this = this;
        return this.http.delete(this.url("permit/" + permitId + "/addons/vehicles/" + vehicleId), this.options)
            .pipe(tap(function (_) { return _this.log('deleted vehicle'); }), catchError(this.handleError('deleteVehicle', null, { permitId: permitId, vehicleId: vehicleId })));
    };
    DriverVehicleService.prototype.submitDriversAndVehicles = function (permitId) {
        var _this = this;
        return this.http.post(this.url("permit/" + permitId + "/addons/driver_vehicle_submit"), {}, this.options)
            .pipe(tap(function (_) { return _this.log('submitted completed drivers & vehicle information'); }), catchError(this.handleError('addDriver', null, { permitId: permitId })));
    };
    DriverVehicleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DriverVehicleService_Factory() { return new DriverVehicleService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: DriverVehicleService, providedIn: "root" });
    return DriverVehicleService;
}(BaseService));
export { DriverVehicleService };
