import { EventEmitter } from '@angular/core';
import { Certificate } from '../../classes';
var InsuranceAccordionComponent = /** @class */ (function () {
    function InsuranceAccordionComponent() {
        this.open = false;
        this.toggleIcon = 'icon-chevron-down';
        this.delete = new EventEmitter();
    }
    Object.defineProperty(InsuranceAccordionComponent.prototype, "c", {
        get: function () { return this.certificate; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InsuranceAccordionComponent.prototype, "type", {
        get: function () {
            switch (this.c.type) {
                case 'named': return 'Named Insured';
                case 'additional': return 'Additional Insured';
                default: return 'Insured';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InsuranceAccordionComponent.prototype, "relationship", {
        get: function () {
            return this.c.custom_relationship || this.c.relationship.split('_').join(' ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InsuranceAccordionComponent.prototype, "address", {
        get: function () {
            return (this.c.unit && this.c.unit !== '') ? this.c.street + ' ' + this.c.unit : this.c.street;
        },
        enumerable: true,
        configurable: true
    });
    InsuranceAccordionComponent.prototype.onPanelChange = function (event) {
        this.open = event.nextState;
        this.toggleIcon = event.nextState ? 'icon-chevron-up' : 'icon-chevron-down';
    };
    InsuranceAccordionComponent.prototype.deleteCertificate = function () {
        this.delete.emit(this.certificate.id);
    };
    return InsuranceAccordionComponent;
}());
export { InsuranceAccordionComponent };
