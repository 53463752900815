<form *ngIf="loaded" [formGroup]="form">
  <fieldset [disabled]="disableRepurchase">
    <div class="product-card card-body text-center" [ngClass]="{'disabled': disableRepurchase}">
      <img class="add-on-icon" *ngIf="form.value.variant && productImage;" [src]="productImage.src">
      <h4 class="space-above-sm">{{product.title}}</h4>
      <h4 *ngIf="form.value.variant && form.value.variant.price;" >${{form.value.variant.price}}</h4>
      <p *ngIf="product && defaultSku === autoSku" style="margin-top: -10px;"><strong>per vehicle</strong></p>
      <p *ngIf="product && defaultSku === motoSku" style="margin-top: -10px;"><strong>per motorcycle</strong></p>
      <p *ngIf="setupPracticeSku" style="margin-top: -10px;"><strong>per day</strong></p>
      <div class="text-left space-below-sm" [innerHTML]="product.body_html | sanitizeHtml"></div>
      <div class="space-below-lg variant-radios row" *ngIf="hasRadioOptions else variantSelect">
        <div class="offset-md-2 col-md-8 form-group radio-group">
          <div *ngFor="let option of radioOptions"
            class="form-group custom-control custom-radio custom-control-inline"
            [ngClass]="{'hidden': option.value === null}"
          >
            <input class="custom-control-input"
              type="radio"
              [value]="option.value"
              [formControlName]="'radio'"
              [id]="option.id"
              (change)="onRadioChange()"
            >
            <label class="custom-control-label" for="radio">{{ option.label }}</label>
          </div>
        </div>
      </div>
      <ng-template #variantSelect>
        <div class="space-below-lg form-group" *ngIf="variantList.length > 1">
          <select formControlName="variant" class="form-control custom-select">
            <option *ngFor="let variant of variantList" [ngValue]="variant">
              {{ variant.title }}
            </option>
          </select>
        </div>
      </ng-template>
      <div *ngIf="!disableQuantity">
        <div class="space-below-lg text-center d-flex flex-column align-items-center">
          <app-quantity-select
            formControlName="quantity"
            [name]="'quantity'"
            [max]="maxQuantity()"
            [readOnly]="true"
            (change)="onQuantityChange()"
          ></app-quantity-select>
        </div>
      </div>
      <app-button *ngIf="setupPracticeSku"
        [type]="'space-below-lg Product__buy btn btn-secondary'"
        [mode]="'button'"
        [disabled]="selectDatesDisabled"
        (click)="onSelectDates()"
      >Select Dates</app-button>

      <!-- Prepurchase Info Forms -->
      <div *ngIf="defaultSku === setupTeardownSku && dateFormCreated" formGroupName="setupTeardownDays">
        <div *ngFor="let date of selectedDates; let i = index;" class="row text-left">
          <app-select
            formControlName="{{date}}-type"
            name="{{date}}-type"
            label="Day {{i+1}}"
            [options]="setupOptions"
            [required]="true"
            [formSubmitted]="submitted"
            (valueChange)="checkFormValidity(stdf)"
            class="col-xl-6"
          ></app-select>
          <app-datepicker
            [formControlName]="date"
            [name]="date"
            [minDate]="minDate"
            [markDisabled]="markDisabled"
            [oneYearLimit]=true
            [formSubmitted]="submitted"
            (valueChange)="checkFormValidity(stdf)"
            class="col-xl-6"
          ></app-datepicker>
        </div>
      </div>

      <div *ngIf="defaultSku === practiceSku && dateFormCreated" formGroupName="practiceDays">
        <div *ngFor="let date of selectedDates; let i = index" class="row text-left">
          <app-datepicker
            [formControlName]="date"
            [name]="date"
            label="Day {{i+1}}"
            [minDate]="minDate"
            [markDisabled]="markDisabled"
            [oneYearLimit]=true
            [formSubmitted]="submitted"
            (valueChange)="checkFormValidity(pdf)"
            class="col-12"
          ></app-datepicker>
        </div>
      </div>

      <app-button *ngIf="!hasRadioOptions && (!requiresPrepurchaseInfo || (requiresPrepurchaseInfo && dateFormCreated))"
        [type]="'space-below-lg Product__buy btn btn-primary'"
        [disabled]="addToCartDisabled"
        (click)="onSubmit()"
      >Add to Cart</app-button>

      <app-alert [alerts]="cartAlerts"></app-alert>

      <p *ngIf="disableRepurchase" class="purchase-message text-success">This product can only be purchased once per permit.</p>
    </div>
  </fieldset>
</form>

<!-- Info/Reminder Modals -->
<app-modal *ngIf="defaultSku === ancillarySku"
  [id]="'ancillary-insurance-modal-1'"
  [title]="product.title"
  [isOpen]="modal1Open"
  (close)="closeModal(1)"
>
  <app-modal-body>
    <p>Your Ancillary Event Insurance application will be submitted to the carrier for processing.
      Your coverage is NOT automatically bound at the time of application. No coverages are bound until your Certificate of Insurance has been issued by the carrier.
    </p>
    <div class="pre-scrollable modal-scroll">
      <h4>USA Cycling Ancillary Event Liability Insurance Program</h4>
      <p><strong>Liability insurance coverage for ancillary, non-race related activities* taking place at USA Cycling sanctioned events.</strong> </p>
      <p><em>*Sanctioned race and participant coverage is provided under the USA Cycling sanction event insurance program. This
      ancillary coverage excludes participants and the race portion of the event, as well as all exclusions listed below.</em></p>
      <p><br>The Liability Coverage $1,000,000.00 Coverage (Protects you in the event of a lawsuit or property damage) Who Is Covered
      This $1,000,000.00 occurrence form general liability program provides protection for your organization and your
      organization’s directors, officers, and board members against claims of bodily injury liability, property damage
      liability, personal and advertising injury liability, and the litigation costs to defend against such claims. There is
      no deductible amount for this coverage. Coverage is offered through the Sports and Recreation Providers Purchasing
      Group, pursuant to the Federal Risk Retention Act of 1986.
      <br><br>
      Coverage includes suits arising out of:</p>
      <ul class="ak-ul">
        <li>
          <p>Injury or death of spectators</p>
        </li>
        <li>
          <p>Injury or death of volunteers</p>
        </li>
        <li>
          <p>Host liquor liability (nonprofit)</p>
        </li>
        <li>
          <p>General negligence claims</p>
        </li>
        <li>
          <p>All activities necessary or incidental to conduct of activities</p>
        </li>
        <li>
          <p>Cost of investigation and defense of claims, even if groundless</p>
        </li>
        <li>
          <p>Ownership, use, or maintenance of facilities</p>
        </li>
      </ul>
      <p>Standard Additional Insureds, such as venues and landlords or municipalities, are included at no additional charge. 
        <br><br>Exclusions: Aircraft, all acts of terrorism, asbestos liability, claims made by athletic participants, employment
        related practices, fungi and bacteria, hepatitis, HIV, HTVL, AIDS, transmissible spongiform encephalopathy, lead
        poisoning, nuclear energy liability, pyrotechnics activity, total pollution, violation of the CAN-SPAM act, war
        liability and liability for occurrences prior to the effective date of coverage. All of the above are subject to the
        terms and conditions of the policy. <br><br>
        The Optional Coverages
        <br><br>
        <strong><u>Liquor Liability Coverage Options</u></strong>
      </p>
      <p>
        Liquor Liability Insurance Not Available in IA or MI.
        <br>
        Only State Mandated Liquor Limits available in CT, IL, MN and UT.
      </p>
      <br>
      <p>
        $1,000,000/$2,000,000 Liquor Liability coverage with United States Fire Insurance Co can be added for
        an additional premium <strong>$400.00 (fully earned at inception)</strong> for events with up to $70,000 in Gross Liquor 
        Sales Only (please submit to underwriting for any events with more than $70,000 in Gross Liquor Sales). A copy of the liquor 
        permit is required with every purhase of liquor liability insurance. Liquor Liability insurance will be flat canceled without 
        receipt and approval of liquor permit PRIOR to binding.
      </p>
      <p>
        Annual Premises/Office Space Liability Insurance Option - This liability coverage option extends annual liability
        insurance
        coverage to your office space location for premises liability: <strong>$225.00 (fully earned)</strong>
      </p>
      <br>
      <p>
        PLEASE NOTE: This is a program highlight page only. Your coverage may include additional
        exclusions and limitations. Please refer to the certificate of coverage you receive following enrollment in this
        program for additional coverage details. If you would like to receive a full copy of the policy, please email your
        request to <a href="mailto:info@nicholashillgroup.com"><strong>info@nicholashillgroup.com</strong></a>.
      </p>
      <p>
        PLEASE NOTE: Additional insured certificates will only be provided under the ancillary event liability program if they
        are specifically required of the named insured. If additional insured certificates are required (by the venue,
        municipality or other entity), please email <a href="mailto:info@nicholashillgroup.com"><strong>info@nicholashillgroup.com</strong></a> 
        for all certificate fulfillment requests.
      </p>
    </div>
  </app-modal-body>
  <app-modal-footer>
    <app-button
      [type]="'btn-secondary'"
      (click)="closeModal(1)"
    >Ok, Got it</app-button>
  </app-modal-footer>
</app-modal>

<app-modal *ngIf="product.title === 'Ancillary Insurance'"
  [id]="'ancillary-insurance-modal-2'"
  [title]="product.title"
  [isOpen]="modal2Open"
  (close)="closeModal(2)"
>
  <app-modal-body>
    <p>
      We are unable to process your application without further review from the broker and the program underwriter.
      Please email the name of your current liability insurance provider along with an insurance company produced claim loss history for the past 5 years, or provide details regarding the past cancellation of your event as well.
      If your insurance was cancelled, then please include the reason for the cancellation, whether the event was rescheduled and whether refunds were provided.
      Please email this information to <a href="mailto:info@nicholashillgroup.com">info@nicholashillgroup.com</a>.
    </p>
  </app-modal-body>
  <app-modal-footer>
    <app-button
      [type]="'btn-secondary'"
      (click)="closeModal(2)"
    >Ok, Got it</app-button>
  </app-modal-footer>
</app-modal>

<app-modal *ngIf="defaultSku === autoSku || defaultSku === motoSku"
  [id]="'auto-insurance-modal'"
  [title]="product.title"
  [isOpen]="modal1Open"
  (close)="closeModal(1)"
>
  <app-modal-body>
    <p>After submitting your permit, you must enter vehicles and drivers in the dashboard before any insurance is valid.</p>
  </app-modal-body>
  <app-modal-footer>
    <app-button
      [type]="'btn-secondary'"
      (click)="closeModal(1)"
    >Ok, Got it</app-button>
  </app-modal-footer>
</app-modal>
