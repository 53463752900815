<!-- Loader -->
<app-loader *ngIf="!variantsLoaded" [small]="true" [align]="'left'"></app-loader>

<div *ngIf="variantsLoaded"  class="row">

  <!-- Part 1 -->
  <div class="col-lg-6">
    <br>
    <h5>Registration</h5>
    <div *ngFor="let key of registrationKeys" class="row questions">
      <p class="col-8 col-md-9" [ngClass]="{'text-warning strong': nullValue(key)}">{{label(key)}}</p>
      <p class="col-4 col-md-3">{{value(key)}}</p>
    </div>
    <div class="row">
      <p class="col-8 col-md-9">{{label('post_event_registration_comments')}}</p>
      <p *ngIf="!value('post_event_registration_comments')" class="col-4 col-md-3 text-right">N/A</p>
      <p *ngIf="!!value('post_event_registration_comments')" class="col-12 ml-2">
        {{value('post_event_registration_comments')}}
      </p>
    </div>
    <hr>
    <h5>Event Conduct</h5>
    <div *ngFor="let key of conductKeys" class="row questions">
      <p class="col-8 col-md-9" [ngClass]="{'text-warning strong': nullValue(key)}">{{label(key)}}</p>
      <p class="col-4 col-md-3">{{value(key)}}</p>
    </div>
    <div class="row">
      <p class="col-8 col-md-9">{{label('post_event_conduct_comments')}}</p>
      <p *ngIf="!value('post_event_conduct_comments')" class="col-4 col-md-3 text-right">N/A</p>
      <p *ngIf="!!value('post_event_conduct_comments')" class="col-12 ml-2">
        {{value('post_event_conduct_comments')}}
      </p>
    </div>
    <hr>
    <h5>Race Technical Support</h5>
    <div *ngFor="let key of supportKeys" class="row questions">
      <p class="col-8 col-md-9" [ngClass]="{'text-warning strong': nullValue(key)}">{{label(key)}}</p>
      <p class="col-4 col-md-3">{{value(key)}}</p>
    </div>
    <div class="row">
      <p class="col-8 col-md-9">{{label('post_event_technical_support_comments')}}</p>
      <p *ngIf="!value('post_event_technical_support_comments')" class="col-4 col-md-3 text-right">N/A</p>
      <p *ngIf="!!value('post_event_technical_support_comments')" class="col-12 ml-2">
        {{value('post_event_technical_support_comments')}}
      </p>
    </div>
    <hr>
    <h5>First Aid/Medical Support</h5>
    <div class="row questions">
      <p class="col-8 col-md-9" [ngClass]="{'text-warning strong': nullValue('post_event_medical_support_verified')}">{{label('post_event_medical_support_verified')}}</p>
      <p class="col-4 col-md-3">{{value('post_event_medical_support_verified')}}</p>
    </div>
    <div class="row">
      <p class="col-8 col-md-9" [ngClass]="{'text-warning strong': nullValue('post_event_medical_support_description')}">{{label('post_event_medical_support_description')}}</p>
      <p *ngIf="!!value('post_event_medical_support_description')" class="col-12 ml-2">
        {{value('post_event_medical_support_description')}}
      </p>
    </div>
    <div class="row questions">
      <p class="col-8 col-md-9" [ngClass]="{'text-warning strong': nullValue('post_event_number_of_occurrences')}">{{label('post_event_number_of_occurrences')}}</p>
      <p class="col-4 col-md-3">{{value('post_event_number_of_occurrences')}}</p>
    </div>
    <div *ngIf="value('post_event_number_of_occurrences') > 0" class="row">
      <p class="col-12" [ngClass]="{'text-warning strong': (nullValue('post_event_accident_occurrence_reports') || !value('post_event_accident_occurrence_reports').length)}">
        {{label('post_event_accident_occurrence_reports')}}
      </p>
      <div *ngIf="!nullValue('post_event_accident_occurrence_reports') && value('post_event_accident_occurrence_reports').length" class="col-12">
        <ul>
          <li *ngFor="let report of value('post_event_accident_occurrence_reports'); let i = index;">
            {{i+1}}) <a href="{{report.url}}" target="_blank">{{report.filename}}</a>
          </li>
        </ul>
      </div>
    </div>
    <hr>
    <h5>Disciplinary Action</h5>
    <div class="row questions">
      <p class="col-8 col-md-9" [ngClass]="{'text-warning strong': nullValue('post_event_rider_discipline')}">{{label('post_event_rider_discipline')}}</p>
      <p class="col-4 col-md-3">{{value('post_event_rider_discipline')}}</p>
    </div>
    <div *ngIf="!!postEvent.post_event_rider_discipline" class="row">
      <p class="col-12" [ngClass]="{'text-warning strong': (nullValue('post_event_rider_discipline_reports') || !value('post_event_rider_discipline_reports').length)}">
        {{label('post_event_rider_discipline_reports')}}
      </p>
      <div *ngIf="!nullValue('post_event_rider_discipline_reports') && value('post_event_rider_discipline_reports').length" class="col-12">
        <ul>
          <li *ngFor="let report of value('post_event_rider_discipline_reports'); let i = index;">
            {{i+1}}) <a href="{{report.url}}" target="_blank">{{report.filename}}</a>
          </li>
        </ul>
      </div>
    </div>
    <hr>
    <h5>Prizes & Awards</h5>
    <div *ngFor="let key of prizeKeys" class="row questions">
      <p class="col-8 col-md-9" [ngClass]="{'text-warning strong': nullValue(key)}">{{label(key)}}</p>
      <p class="col-4 col-md-3">{{value(key)}}</p>
    </div>
    <div class="row">
      <p class="col-8 col-md-9">{{label('post_event_prize_comments')}}</p>
      <p *ngIf="!value('post_event_prize_comments')" class="col-4 col-md-3 text-right">N/A</p>
      <p *ngIf="!!value('post_event_prize_comments')" class="col-12 ml-2">
        {{value('post_event_prize_comments')}}
      </p>
    </div>
    <hr>
    <h5>Safe Sport Check</h5>
    <div *ngFor="let key of safesportKeys" class="row questions">
      <p class="col-8 col-md-9" [ngClass]="{'text-warning strong': nullValue(key)}">{{label(key)}}</p>
      <p [ngClass]="{'col-4 col-md-3': !key.includes('_text'), 'font-italic pl-4': key.includes('_text')}">{{value(key)}}</p>
      <hr>
    </div>

  </div>

  <!-- Part 2 -->
  <div class="col-lg-6">
    <br class="hidden-below-lg">
    <hr class="hidden-above-lg">
    <!-- <h5 [ngClass]="{'text-warning': nullValue('post_event_participants')}">Event participants</h5>
    <div *ngIf="!nullValue('post_event_participants')">
      <div class="row data title">
        <p *ngIf="!isSeries" class="col-2">Date</p>
        <p [ngClass]="{'col-3 col-xl-5': isSeries, 'col-4 text-left': !isSeries}">Sub-Disc</p>
        <p [ngClass]="{'col-3 col-xl-2': isSeries, 'col-2': !isSeries}">Pre-Reg</p>
        <p [ngClass]="{'col-3 col-xl-2': isSeries, 'col-2': !isSeries}">On-Site</p>
        <p [ngClass]="{'col-3 col-xl-3': isSeries, 'col-2': !isSeries}">Total</p>
      </div>
      <div *ngFor="let row of postEvent.post_event_participants" class="row data">
        <p *ngIf="!isSeries" class="col-2">{{nonSeriesDate(row.event_date_id) | date:'shortDate':'UTC'}}</p>
        <p [ngClass]="{'col-3 col-xl-5': isSeries, 'col-4 text-left': !isSeries}">{{subDisciplineName(row.subdiscipline_id)}}</p>
        <p [ngClass]="{'col-3 col-xl-2': isSeries, 'col-2': !isSeries}">{{row.participants_pre_registered}}</p>
        <p [ngClass]="{'col-3 col-xl-2': isSeries, 'col-2': !isSeries}">{{row.participants_registered_on_site}}</p>
        <p [ngClass]="{'col-3 col-xl-3': isSeries, 'col-2': !isSeries}">{{row.participants_total}}</p>
      </div>
    </div>

    <hr> -->
    <h5 [ngClass]="{'text-warning': nullValue('post_event_riders')}">Rider Fees</h5>
    <div *ngIf="!nullValue('post_event_riders')">
      <div class="row data title">
        <p *ngIf="!isSeries" class="col-3">Date</p>
        <p [ngClass]="{'col-4 col-xl-2': isSeries, 'col-2': !isSeries}">Unique Riders</p>
        <!-- commented out for MBR-2992 -->
        <!-- <p class="col-3" [ngClass]="{'col-xl-5': isSeries, 'text-left': !isSeries}">Sub-Disc</p> -->
        <p class="text-right" [ngClass]="{'col-4 col-xl-2': isSeries, 'col-3': !isSeries}">Cost</p>
        <p class="text-right col-4">Total</p>
      </div>
      <div *ngFor="let row of postEvent.post_event_riders" class="row data">
        <p *ngIf="!isSeries" class="col-3">{{nonSeriesDate(row.event_date_id) | date:'shortDate':'UTC'}}</p>
        <!-- commented out for MBR-2992 -->
        <!-- <p class="col-3" [ngClass]="{'col-xl-5': isSeries, 'text-left': !isSeries}">{{subDisciplineName(row.subdiscipline_id)}}</p> -->
        <p [ngClass]="{'col-4 col-xl-2': isSeries, 'col-2': !isSeries}">{{row.riders_unique}}</p>
        <p class="text-right" [ngClass]="{'col-4 col-xl-2': isSeries, 'col-3': !isSeries}">{{riderSurchargeCost(row) | currency}}</p>
        <p class="text-right col-4">{{riderSurchargeTotal(row) | currency}}</p>
      </div>
    </div>

    <hr>
    <h5 [ngClass]="{'text-warning': !displayLicenseMemberships}">Licenses & Memberships</h5>
    <div *ngIf="displayLicenseMemberships">
      <div class="row data title">
        <p class="col-3 col-md-5">Item</p>
        <p class="col-3 col-md-2">Quant</p>
        <p class="text-right col-3 col-md-2">Cost</p>
        <p class="text-right col-3">Total</p>
      </div>

      <div *ngFor="let key of licenseMembershipKeys" class="row data">
        <ng-container *ngIf="displayLicenseMembershipRow(key)">
          <p class="col-3 col-md-5">{{licenseMembershipTitle(key)}}</p>
          <p class="col-3 col-md-2">{{postEvent[key]}}</p>
          <p class="text-right col-3 col-md-2">{{chargable(key) ? licenseMembershipCost(key) : null | currency}}</p>
          <p class="text-right col-3">{{chargable(key) ? licenseMembershipTotal(key) : null | currency}}</p>
        </ng-container>
      </div>
    </div>

    <hr>
    <h5 [ngClass]="{'text-warning': !displayFees}">Post Event Fees</h5>
    <div *ngIf="displayFees">
      <div class="row data title">
        <p class="col-9">Fees</p>
        <p class="text-right col-3">Total</p>
      </div>

      <div class="row data">
        <p class="col-9">Rider Surcharge</p>
        <p class="text-right col-3">{{finalRiderSurchargeTotal | currency}}</p>
      </div>
      <div class="row data">
        <p class="col-9">One Day Licenses</p>
        <p class="text-right col-3">{{groupTotals(licenseTotals) | currency}}</p>
      </div>
      <div class="row data">
        <p class="col-9">Memberships</p>
        <p class="text-right col-3">{{groupTotals(membershipTotals) | currency}}</p>
      </div>
      <div *ngIf="applyLateFee" class="row data">
        <p class="col-9">Late Fee</p>
        <p class="text-right col-3">{{lateFeePrice | currency}}</p>
      </div>
      <div *ngIf="hasDiscounts" class="row data">
        <span class="col-12">Applied Discounts</span>
        <span *ngFor="let discount of appliedDiscounts" class="text-left col-12 ml-1 small">{{discount}}</span>
      </div>

      <div *ngIf="hasDiscounts" class="row data totals mt-2">
        <p class="col-4 col-xl-5"></p>
        <p class="col-5 col-xl-4">Total Discounts</p>
        <p class="col-3 col-xl-3">- {{totalDiscount | currency}}</p>
      </div>
      <div class="row data totals">
        <p class="col-4 col-xl-5"></p>
        <p class="col-5 col-xl-4">Subtotal</p>
        <p class="col-3 col-xl-3">{{subtotal | currency}}</p>
      </div>
      <div class="row data totals">
        <p class="col-4 col-xl-5"></p>
        <p class="col-5 col-xl-4">Tax</p>
        <p class="col-3 col-xl-3">{{tax | currency}}</p>
      </div>
      <div *ngFor="let order of orders" class="row data totals">
        <p class="col-4 col-xl-5"></p>
        <p class="col-5 col-xl-4">Amt Paid - {{order.processed_at | date:'shortDate':'UTC'}}</p>
        <p class="col-3 col-xl-3 text-success">- {{order.total_price | currency}}</p>
      </div>
      <div class="row data totals">
        <p class="col-4 col-xl-5"></p>
        <p class="col-5 col-xl-4">Total Due</p>
        <p class="col-3 col-xl-3 text-danger">{{totalDue | currency}}</p>
      </div>
      <div class="text-right mt-3">
        <app-button *ngIf="displayPayBtn" [mode]="'button'" [type]="'btn-primary'" (click)="toCheckout()">Pay Now</app-button>
      </div>
    </div>
  </div>
</div>
