import { License, Mvr, Address, Contact } from '.';

export class Profile {
  profile_id: number;
  profile_email: string;
  profile_username: string;
  profile_first_name: string;
  profile_last_name: string;
  profile_birthdate: string;
  shopify_customer_id: string;
  age: number;
  profile_sex: string;
  profile_comp_id: number;
  licenses: License[];
  mvr: Mvr[];
  address: Address[];
  is_pended_outstanding_permit_fee: boolean;
  profile_contact?: Contact;

  constructor(data) {
    this.profile_id = data.profile_id;
    this.profile_email = data.profile_email;
    this.profile_username = data.profile_username;
    this.profile_first_name = data.profile_first_name;
    this.profile_last_name = data.profile_last_name;
    this.profile_birthdate = data.profile_birthdate;
    this.profile_sex = data.profile_sex;
    this.profile_comp_id = data.profile_comp_id;
    this.shopify_customer_id = data.shopify_customer_id;
    this.licenses = data.licenses;
    this.mvr = data.mvr;
    this.address = data.address;

    this.age = Math.floor((Date.now() - Date.parse(this.profile_birthdate)) / 31536000000);
    this.is_pended_outstanding_permit_fee = data.is_pended_outstanding_permit_fee;
  }
}
