import { NgModule, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { AirbrakeErrorHandler } from './airbrake-error-handler';
import { environment } from 'src/environments/environment';

import {
  ShopifyService,
  ProductService,
  GlobalService,
  CsvDownloadService,
  FileUploadService,
  RouteHistoryService
} from './shared';
import { CookieService } from 'ngx-cookie-service';
import { imports } from './app.imports';
import { declarations } from './app.declarations';

@NgModule({
  declarations,
  imports,
  providers: [
    { provide: ErrorHandler, useClass: AirbrakeErrorHandler },
    { provide: 'googleTagManagerId', useValue: environment.gtmId },
    ShopifyService,
    ProductService,
    GlobalService,
    CookieService,
    CsvDownloadService,
    FileUploadService,
    RouteHistoryService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
