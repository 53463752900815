<ngb-accordion (panelChange)="onPanelChange($event)" class="border-0 ref-accordion">
  <ngb-panel type="light">
    <ng-template ngbPanelHeader>
      <div class="row ml-0">
        <div class="col-11 row align-items-center">
          <span class="date col-md-6 col-lg-4" >{{eventDate.event_date_start | date: 'mediumDate':'UTC'}}</span>
          <span class="col-md-6 col-lg-4">
            <span *ngIf="eventDate.event_date_chief_ref">
              {{eventDate.event_date_chief_ref.profile_first_name}} {{eventDate.event_date_chief_ref.profile_last_name}}
            </span>
          </span>
          <div class="col-md-6 col-lg-4 py-2">
            <div class="ref-status d-flex">
              <span *ngIf="assigned()" class="col badge badge-pill badge-success">Assigned</span>
              <span *ngIf="!assigned()" class="col badge badge-pill badge-danger">Unassigned</span>
          </div>
          </div>
        </div>
        <button ngbPanelToggle class="col-1 text-right btn icon-btn"><i class="icon {{toggleIcon}}"></i></button>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="row pl-2 pr-2">
        <div class="col-lg-12 d-flex justify-content-between">
        <span class="ref-title mb-4">Description</span>
        <span class="mb-4">{{eventDate.event_date_description}}</span>
        </div>
        <div class="col-lg-12 d-flex justify-content-between">
        <span class="ref-title mb-4">Date</span>
        <span class="mb-4">{{eventDate.event_date_start | date: 'mediumDate':'UTC'}}</span>
        </div>
        <div class="col-lg-12 d-flex justify-content-between">
        <span class="ref-title mb-4">Address</span>
        <span class="mb-4 text-right">
          {{eventDate.event_date_street}}<br>
          <span *ngIf="eventDate.event_date_unit">{{eventDate.event_date_unit}}</span><br>
          {{eventDate.event_date_city}}, {{eventDate.event_date_state}} {{eventDate.event_date_postal_code}}</span>
        </div>
        <!-- TODO: Unhide course length and closure type if set somewhere else in the app -->
        <!-- <div class="col-lg-12 d-flex justify-content-between">
        <span class="ref-title mb-4">Course Length</span>
        <span class="mb-4">{{eventDate.event_date_course_length}} km</span>
        </div>
        <div class="col-lg-12 d-flex justify-content-between">
        <span class="ref-title mb-4">Course Closure Type</span>
        <span class="mb-4">{{eventDate.event_date_closure_type}}</span>
        </div> -->
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
