import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Profile } from 'src/app/classes';
import { ProfileService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-ghost-mode',
  templateUrl: './admin-ghost-mode.component.html',
  styleUrls: []
})
export class AdminGhostModeComponent implements OnInit {
  currentUser: Profile;
  activeProfile: Profile;
  ghostModeForm: FormGroup;
  loading = false;

  get disable(): boolean {
    return !this.currentUser;
  }

  get ghosting(): boolean {
    return !this.disable && this.ghostModeForm.value.ghostMode;
  }

  get showGhostModeBar(): boolean {
    return this.hasGhostModeCookie && !!this.currentUser;
  }

  get self(): boolean {
    return this.currentUser && this.activeProfile && this.currentUser.profile_id === this.activeProfile.profile_id;
  }

  get profileUrl(): string {
    return this.activeProfile && `/profile/${this.activeProfile.profile_id}`;
  }

  get status(): string {
    return this.ghosting ? 'acting as:' : 'viewing:';
  }

  get showCancelButton(): boolean {
    return this.activeProfileNameAndId !== 'Self' && !this.hasGhostModeCookie;
  }

  get activeProfileNameAndId(): string {
    if (!this.currentUser.profile_id) {
      return '';
    }

    if (this.hasGhostModeCookie) {
      return `${this.currentUser.profile_first_name} ${this.currentUser.profile_last_name} ${this.currentUser.profile_id}`;
    } else {
      return this.self || this.self === null
        ? 'Self'
        : `${this.currentUser.profile_first_name} ${this.currentUser.profile_last_name} ${this.currentUser.profile_id}`;
    }
  }

  get hasGhostModeCookie(): boolean {
    return this.cookie.check('USACGHOST');
  }

  get adminInfo(): string {
    if (this.hasGhostModeCookie) {
      const data = JSON.parse(this.cookie.get('USACGHOST'));
      return `${data.admin_name} ${data.admin_id}`;
    } else {
      return `${this.currentUser.profile_first_name} ${this.currentUser.profile_last_name} ${this.currentUser.profile_id}`;
    }
  }

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    protected cookie: CookieService
  ) { }

  ngOnInit(): void {
    this.profileService.getCurrentUser().subscribe(() => {
      this.currentUser = this.profileService.currentUser;
    });

    this.ghostModeForm = this.fb.group({
      ghostMode: [this.hasGhostModeCookie]
    });
  }

  resetGhostMode(): void {
    if (confirm('Are you sure you want to logout as this ghosted user?')) {
      this.loading = true;

      this.profileService.ghostResetProfile().subscribe((profile: Profile) => {
        if (profile) {
          window.location.assign(environment.adminUrl + `/profile/${profile.profile_id}`);
        }
        this.loading = false;
      });
    }
  }
}
